import { format, subMonths, subYears } from "date-fns";
import Button from "../../MaterialUI/Button";
import ButtonGroup from "../../MaterialUI/ButtonGroup";
import { IndicatorWidgetButtonTypes } from "./indicatorWidgetTypes";
import { useState } from "react";
import { useTheme } from "@mui/material";

const BUTTONS: {
  type: IndicatorWidgetButtonTypes;
  label: string;
}[] = [
  { type: "lastMonth", label: format(subMonths(new Date(), 1), "MMM") },
  { type: "thisMonth", label: format(new Date(), "MMM") },
  { type: "lastYear", label: format(subYears(new Date(), 1), "yyyy") },
  { type: "thisYear", label: new Date().getFullYear().toString() },
];

interface IndicatorButtonsProps {
  handleButtonClick: (type: IndicatorWidgetButtonTypes) => Promise<void>;
}

const IndicatorButtons: React.FC<IndicatorButtonsProps> = ({ handleButtonClick }) => {
  const theme = useTheme();
  const [selectedButton, setSelectedButton] =
    useState<IndicatorWidgetButtonTypes>("thisMonth");

  const onButtonClick = async (type: IndicatorWidgetButtonTypes) => {
    setSelectedButton(type);
    await handleButtonClick(type);
  };

  return (
    <ButtonGroup>
      {BUTTONS.map(({ type, label }) => (
        <Button
          key={`${label}-${type}`}
          variant="text"
          size="small"
          onClick={() => onButtonClick(type)}
          sx={{
            backgroundColor: selectedButton === type ? theme.palette.action.hover : null,
          }}
        >
          {label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default IndicatorButtons;
