import { Box } from "@mui/material";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import useTheme from "@mui/material/styles/useTheme";
import { SerializedStyles } from "@emotion/react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { TextWidgetConfiguration } from "./textWidgetTypes";

interface TextWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: TextWidgetConfiguration;
  data: string;
  isStatic?: boolean;
}
const TextWidget: React.FC<TextWidgetProps> = ({ className, data, isStatic }) => {
  // const { alignment, smallText } = configuration;
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  return (
    <Box
      component="div"
      css={[
        styles.fullPadding2,
        styles.height100,
        isStatic ? undefined : styles.overflowYAuto,
      ]}
      className={className}
    >
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Box>
  );
};

export default TextWidget;
