import { IconButton, Stack, Typography } from "@mui/material";
import { GetQueryOEEMappedCategoriesWithSubcategoriesSnippet } from "../../../Api/OEE/apiOEESnippets";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import CellActionButton, {
  ModalLayoutData,
} from "../../SmallComponents/TableGrid/CellActionButton";
import { useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../MaterialUI/Button";
import Modal from "../../MaterialUI/Modal";
import { capitalizeFirstLetterOfEachWord } from "../../../Global/Utils/commonFunctions";
import cssLayoutStyles from "../../../Global/Styles/layout";
import callApi from "../../../Api/callApi";
import { postQuerySubcategoryDelete } from "../../../Api/OEE/apiOEEPostQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import OEEAddSubcategoryForm from "./OEEAddSubcategoryForm";
import { AutocompleteOption } from "../../../Global/Types/commonTypes";
import { ModalTitle } from "./oEEUtils";

const colSchema: TableGridColumnSchema[] = [
  { id: "subcategory", label: "Subcategory", type: "string" },
  { id: "maincategory", label: "Main Category", type: "string" },
  { id: "type", label: "Type", type: "string" },
  { id: "valid_from", label: "Valid from", type: "date" },
  { id: "description", label: "Description", type: "button" },
  { id: "actions", label: "Actions", type: "button" },
];

interface OEESubcategoriesTableProps {
  oEEMappedCategoriesWithSubcategories: GetQueryOEEMappedCategoriesWithSubcategoriesSnippet;
  fetchOEEData: () => void;
  oEEMainCategoriesOptions: AutocompleteOption[];
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle>>;
}

const OEESubcategoriesTable: React.FC<OEESubcategoriesTableProps> = ({
  oEEMappedCategoriesWithSubcategories,
  fetchOEEData,
  oEEMainCategoriesOptions,
  setModalTitle,
}) => {
  const styles = { ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const handleDeleteSubcategory = async (subcategoryId: string) => {
    try {
      const deletedSubcategory = await callApi({
        query: postQuerySubcategoryDelete(subcategoryId),
        auth: { setAuthedUser },
      });

      if (deletedSubcategory) {
        fetchOEEData();
        setOpenInfo(false);
      }
    } catch (err) {
      console.log("OEESubcategoryDelete err: ", err.message);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      {oEEMappedCategoriesWithSubcategories ? (
        <ResponsiveTableGrid
          rows={handleFilterData(
            setOpenInfo,
            setModalData,
            oEEMappedCategoriesWithSubcategories
          )}
          colSchema={colSchema}
          editMode={false}
          responsive="responsive"
          configuration={{
            density: "compact",
          }}
        />
      ) : (
        <Typography component="p" variant="body1">
          Loading...
        </Typography>
      )}

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={getModalLabel(modalData)}
          onClose={() => setOpenInfo(false)}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        >
          {modalData.type === "description" ? modalData.data : null}
          {modalData.type === "subcategoriesForm" ? (
            <OEEAddSubcategoryForm
              oEEMainCategoriesOptions={oEEMainCategoriesOptions}
              fetchOEEData={fetchOEEData}
              setModalTitle={setModalTitle}
              setOpenInfo={setOpenInfo}
              subcategoryData={modalData.data.data}
              subcategoryId={modalData.data.id}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {modalData.type === "deleteForm" ? (
            <Stack spacing={2}>
              <Typography variant="body1">
                Are you sure you want to delete this subcategory?
              </Typography>

              <Stack css={styles.width100} alignItems="center" justifyContent="center">
                <Button
                  css={styles.widthLimit20}
                  color="error"
                  onClick={() => handleDeleteSubcategory(modalData.data)}
                >
                  Confirm Deletion
                </Button>
              </Stack>
            </Stack>
          ) : null}
        </Modal>
      ) : null}
    </>
  );
};

export default OEESubcategoriesTable;

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  oEEMappedCategoriesWithSubcategories:
    | GetQueryOEEMappedCategoriesWithSubcategoriesSnippet
    | undefined
) => {
  if (!oEEMappedCategoriesWithSubcategories) return [];

  return oEEMappedCategoriesWithSubcategories.flatMap((categoryWithSubcategory) => {
    return categoryWithSubcategory.subcategories.map((subcategory) => ({
      type: capitalizeFirstLetterOfEachWord(categoryWithSubcategory.type),
      maincategory: categoryWithSubcategory.name,
      subcategory: subcategory.name,
      valid_from: subcategory.valid_from,
      description: (
        <CellActionButton
          text="View Description"
          icon={<VisibilityIcon />}
          handleOnClick={() => {
            setOpenInfo(true);
            setModalData({ type: "description", data: subcategory.description });
          }}
        />
      ),
      actions: (
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({
                type: "subcategoriesForm",
                data: {
                  data: {
                    main_category: categoryWithSubcategory.name,
                    name: subcategory.name,
                    valid_from: subcategory.valid_from,
                    description: subcategory.description,
                  },
                  id: subcategory.id,
                },
              });
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setOpenInfo(true);
              setModalData({ type: "deleteForm", data: subcategory.id });
            }}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ),
    }));
  });
};

const getModalLabel = (modalData: ModalLayoutData | null): string => {
  if (!modalData) return "";

  switch (modalData.type) {
    case "description":
      return "Subcategory Description";
    case "subcategoriesForm":
      return "Edit Subcategory";
    case "deleteForm":
      return "Delete Subcategory";
    default:
      return "";
  }
};
