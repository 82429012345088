import { SunburstData } from "../Sunburst/sunburstTypes";

const sunburstDefaultData: SunburstData = {
  name: "Factory",
  color: "hsl(59, 70%, 50%)",
  children: [
    {
      name: "Production",
      color: "hsl(76, 70%, 50%)",
      children: [
        {
          name: "Machine A",
          color: "hsl(211, 70%, 50%)",
          children: [
            {
              name: "Machine B",
              color: "hsl(50, 70%, 50%)",
              loc: 139197,
            },
            {
              name: "Machine C",
              color: "hsl(326, 70%, 50%)",
              loc: 104962,
            },
            {
              name: "Machine D",
              color: "hsl(331, 70%, 50%)",
              loc: 151942,
            },
            {
              name: "Machine F",
              color: "hsl(160, 70%, 50%)",
              loc: 109108,
            },
          ],
        },
        {
          name: "Maintenance",
          color: "hsl(226, 70%, 50%)",
          children: [
            {
              name: "Lines",
              color: "hsl(244, 70%, 50%)",
              children: [
                {
                  name: "Line 1",
                  color: "hsl(313, 70%, 50%)",
                  children: [
                    {
                      name: "Path A",
                      color: "hsl(194, 70%, 50%)",
                      loc: 70751,
                    },
                    {
                      name: "Path B",
                      color: "hsl(72, 70%, 50%)",
                      loc: 170108,
                    },
                    {
                      name: "Path C",
                      color: "hsl(117, 70%, 50%)",
                      loc: 64189,
                    },
                  ],
                },
                {
                  name: "Line 2",
                  color: "hsl(325, 70%, 50%)",
                  loc: 49236,
                },
                {
                  name: "Line 3",
                  color: "hsl(231, 70%, 50%)",
                  loc: 168312,
                },
              ],
            },
            {
              name: "Other",
              color: "hsl(307, 70%, 50%)",
              loc: 170499,
            },
          ],
        },
      ],
    },
    {
      name: "Packaging",
      color: "hsl(83, 70%, 50%)",
      children: [
        {
          name: "Station A",
          color: "hsl(318, 70%, 50%)",
          loc: 37204,
        },
        {
          name: "Station B",
          color: "hsl(98, 70%, 50%)",
          loc: 172430,
        },
      ],
    },
    {
      name: "Maintenance",
      color: "hsl(237, 70%, 50%)",
      children: [
        {
          name: "Department A",
          color: "hsl(223, 70%, 50%)",
          loc: 164071,
        },
        {
          name: "Department B",
          color: "hsl(291, 70%, 50%)",
          loc: 73423,
        },
        {
          name: "Department C",
          color: "hsl(5, 70%, 50%)",
          loc: 68957,
        },
        {
          name: "Department D",
          color: "hsl(125, 70%, 50%)",
          loc: 4705,
        },
        {
          name: "Department F",
          color: "hsl(89, 70%, 50%)",
          loc: 157865,
        },
        {
          name: "Department G",
          color: "hsl(139, 70%, 50%)",
          loc: 62804,
        },
        {
          name: "Department J",
          color: "hsl(222, 70%, 50%)",
          loc: 21103,
        },
      ],
    },
    {
      name: "Generators",
      color: "hsl(111, 70%, 50%)",
      children: [
        {
          name: "Building 1",
          color: "hsl(152, 70%, 50%)",
          loc: 41544,
        },
        {
          name: "Building 2",
          color: "hsl(125, 70%, 50%)",
          loc: 59306,
        },
        {
          name: "Building 3",
          color: "hsl(143, 70%, 50%)",
          loc: 199004,
        },
        {
          name: "Building 4",
          color: "hsl(126, 70%, 50%)",
          loc: 89278,
        },
        {
          name: "Building 5",
          color: "hsl(211, 70%, 50%)",
          loc: 166682,
        },
      ],
    },
    {
      name: "Forming",
      color: "hsl(157, 70%, 50%)",
      children: [
        {
          name: "Tube beading",
          color: "hsl(237, 70%, 50%)",
          loc: 85421,
        },
        {
          name: "Smith",
          color: "hsl(188, 70%, 50%)",
          loc: 13032,
        },
        {
          name: "Drop forge",
          color: "hsl(282, 70%, 50%)",
          loc: 68445,
        },
        {
          name: "Press",
          color: "hsl(310, 70%, 50%)",
          loc: 34920,
        },
        {
          name: "Cold rolling",
          color: "hsl(244, 70%, 50%)",
          loc: 57793,
        },
        {
          name: "Sheet metal",
          color: "hsl(241, 70%, 50%)",
          loc: 192216,
        },
        {
          name: "Hot rolling",
          color: "hsl(210, 70%, 50%)",
          loc: 20609,
        },
        {
          name: "Slice",
          color: "hsl(123, 70%, 50%)",
          loc: 23732,
        },
        {
          name: "Cryorolling",
          color: "hsl(120, 70%, 50%)",
          loc: 91324,
        },
        {
          name: "Impact extrusion",
          color: "hsl(175, 70%, 50%)",
          loc: 149432,
        },
        {
          name: "Embossing",
          color: "hsl(70, 70%, 50%)",
          loc: 123727,
        },
        {
          name: "Stretch forming",
          color: "hsl(241, 70%, 50%)",
          loc: 168051,
        },
        {
          name: "Bulging",
          color: "hsl(287, 70%, 50%)",
          loc: 145687,
        },
      ],
    },
    {
      name: "Casting",
      color: "hsl(39, 70%, 50%)",
      children: [
        {
          name: "Bellfounding",
          color: "hsl(127, 70%, 50%)",
          loc: 195065,
        },
        {
          name: "Die casting",
          color: "hsl(315, 70%, 50%)",
          loc: 48075,
        },
        {
          name: "Full-mold casting",
          color: "hsl(181, 70%, 50%)",
          loc: 44064,
        },
        {
          name: "Lost-foam casting",
          color: "hsl(96, 70%, 50%)",
          loc: 178484,
        },
        {
          name: "Low pressure die casting",
          color: "hsl(105, 70%, 50%)",
          loc: 70838,
        },
        {
          name: "Permanent mold casting",
          color: "hsl(159, 70%, 50%)",
          loc: 153753,
        },
        {
          name: "Plastic mold casting",
          color: "hsl(64, 70%, 50%)",
          loc: 137007,
        },
        {
          name: "Resin casting",
          color: "hsl(191, 70%, 50%)",
          loc: 160444,
        },
        {
          name: "Sand casting",
          color: "hsl(132, 70%, 50%)",
          loc: 124967,
        },
      ],
    },
    {
      name: "Misc",
      color: "hsl(294, 70%, 50%)",
      children: [
        {
          name: "AI",
          color: "hsl(66, 70%, 50%)",
          children: [
            {
              name: "Machine learning",
              color: "hsl(100, 70%, 50%)",
              loc: 130093,
            },
            {
              name: "Generative",
              color: "hsl(221, 70%, 50%)",
              loc: 160545,
            },
            {
              name: "Theory of mind",
              color: "hsl(298, 70%, 50%)",
              loc: 6097,
            },
            {
              name: "Self-aware",
              color: "hsl(99, 70%, 50%)",
              loc: 14441,
            },
          ],
        },
        {
          name: "Other",
          color: "hsl(276, 70%, 50%)",
          loc: 38582,
        },
        {
          name: "path",
          color: "hsl(78, 70%, 50%)",
          children: [
            {
              name: "pathA",
              color: "hsl(274, 70%, 50%)",
              loc: 21160,
            },
            {
              name: "pathB",
              color: "hsl(83, 70%, 50%)",
              children: [
                {
                  name: "pathB1",
                  color: "hsl(218, 70%, 50%)",
                  loc: 78707,
                },
                {
                  name: "pathB2",
                  color: "hsl(48, 70%, 50%)",
                  loc: 36413,
                },
                {
                  name: "pathB3",
                  color: "hsl(11, 70%, 50%)",
                  loc: 163917,
                },
                {
                  name: "pathB4",
                  color: "hsl(49, 70%, 50%)",
                  loc: 70980,
                },
              ],
            },
            {
              name: "pathC",
              color: "hsl(327, 70%, 50%)",
              children: [
                {
                  name: "pathC1",
                  color: "hsl(284, 70%, 50%)",
                  loc: 72465,
                },
                {
                  name: "pathC2",
                  color: "hsl(38, 70%, 50%)",
                  loc: 150418,
                },
                {
                  name: "pathC3",
                  color: "hsl(235, 70%, 50%)",
                  loc: 176112,
                },
                {
                  name: "pathC4",
                  color: "hsl(28, 70%, 50%)",
                  loc: 42768,
                },
                {
                  name: "pathC5",
                  color: "hsl(1, 70%, 50%)",
                  loc: 17482,
                },
                {
                  name: "pathC6",
                  color: "hsl(162, 70%, 50%)",
                  loc: 171064,
                },
                {
                  name: "pathC7",
                  color: "hsl(263, 70%, 50%)",
                  loc: 701,
                },
                {
                  name: "pathC8",
                  color: "hsl(73, 70%, 50%)",
                  loc: 35711,
                },
                {
                  name: "pathC9",
                  color: "hsl(284, 70%, 50%)",
                  loc: 33359,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default sunburstDefaultData;
