import { UaSubscription } from "../../../../../Api/DataSources/apiDSDataTypes";
import { PostQueryUAConnectInput } from "../../../../../Api/DataSources/apiDSInputs";
import { postQueryUAConnect } from "../../../../../Api/DataSources/apiDSPostQueries";
import { PostQueryUAConnectSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import callApi from "../../../../../Api/callApi";
import { AuthedUser } from "../../../../../context/authContextTypes";
import { UaSecurity } from "./uaConnectionTypes";

export const uaChecksConnectToServer = (
  sub: UaSubscription,
  security: UaSecurity | null
): string | null => {
  if (!sub.update_rate) {
    return "You must enter an update rate";
  }
  if (!sub.server_url) {
    return "You must enter a server URL";
  }
  if (!sub.name) {
    return "You must enter a connection name";
  }
  if (sub.name.length < 3) {
    return "Connection name must be at least 3 characters long";
  }

  // With Security
  if (security) {
    const { mode, policy, userAuth } = security;
    if (!mode) {
      return "You must select a security mode";
    }
    if (!policy && mode !== "None") {
      return "If security mode is different from 'None', you must select a security policy";
    }
    if (!userAuth) {
      return "You must select a user authentication method";
    }
    if (userAuth !== "None" && userAuth !== "Username and password") {
      return "Please contact your admin for this authentication method.";
    }
  }

  return null;
};

export const uaHandleConnectToServer = async (
  sub: UaSubscription,
  setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>
) => {
  const input: PostQueryUAConnectInput = {
    url: sub.server_url,
    depth: 100,
  };

  const data = await callApi<PostQueryUAConnectSnippet>({
    query: postQueryUAConnect(input),
    auth: { setAuthedUser },
  });

  return data;
};
