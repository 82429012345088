import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import AuthContext from "./context/AuthContext";
import GraphQlProvider from "./GraphQL/GraphQLProvider";
import ThemeProvider from "./context/ThemeContext";
import "./i18n";
import LanguageProvider from "./context/LanguageContext";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider>
    <CssBaseline />
    <AuthContext>
      <GraphQlProvider>
        <LanguageProvider>
          <App />
        </LanguageProvider>
      </GraphQlProvider>
    </AuthContext>
  </ThemeProvider>
);
