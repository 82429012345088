import { Box } from "@mui/material";
import { memo, useEffect } from "react";
import { CollapsibleTreeItemData } from "../../../../SmallComponents/CollapsibleSelectTree/collapsibleTreeTypes";
import CollapsibleSelectTree from "../../../../SmallComponents/CollapsibleSelectTree/CollapsibleSelectTree";

interface DaItemsProps {
  itemsList: string[];
  selectedItems: string[];
  setSelectedItems: React.Dispatch<React.SetStateAction<string[]>>;
  setLastSelectedItem: React.Dispatch<React.SetStateAction<string | null>>;
}

const DaItems: React.FC<DaItemsProps> = ({
  itemsList,
  selectedItems,
  setSelectedItems,
  setLastSelectedItem,
}) => {
  const treeData = getTreeData(itemsList);

  useEffect(() => {
    if (selectedItems.length) {
      setLastSelectedItem((prev) => {
        const last = selectedItems[selectedItems.length - 1];
        if (prev !== last) {
          return last;
        }
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  return (
    <Box component="div">
      <CollapsibleSelectTree
        data={treeData}
        selected={selectedItems}
        setSelected={setSelectedItems}
      />
    </Box>
  );
};

export default memo(DaItems);

const getTreeData = (nodes: string[]): CollapsibleTreeItemData[] => {
  const transformedNodes: CollapsibleTreeItemData[] = [];
  const nodeMap: { [key: string]: CollapsibleTreeItemData[] } = {};

  for (const node of nodes) {
    const [parent, child] = node.split(".");
    if (!(parent in nodeMap)) {
      nodeMap[parent] = [];
    }
    nodeMap[parent].push({
      id: `${parent}.${child}`,
      uniqueID: `${parent}.${child}`,
      label: child,
    });
  }

  for (const parent in nodeMap) {
    transformedNodes.push({
      id: parent,
      uniqueID: parent,
      label: parent,
      children: nodeMap[parent],
    });
  }

  return transformedNodes;
};
