import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { SunburstData } from "./sunburstTypes";
import { ResponsiveSunburst } from "@nivo/sunburst";
import {
  DynamicGridChartType,
  getChartColors,
  getCurrentColorScheme,
} from "../../nivoTheme";

interface SunburstProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: SunburstData;
  isStatic?: boolean;
}

const Sunburst: React.FC<SunburstProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 10 : 0,
    right: isStatic ? 10 : 0,
    bottom: isStatic ? 10 : 0,
    left: isStatic ? 10 : 0,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveSunburst
        data={data}
        margin={chartMargin}
        id="name"
        value="loc"
        cornerRadius={2}
        borderColor={{ theme: "background" }}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        colors={getChartColors()}
        childColor={{
          from: "color",
          modifiers: [["brighter", 0.1]],
        }}
        isInteractive={isStatic ? false : true}
        enableArcLabels={isStatic ? false : true}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
            ? {
                from: "color",
                modifiers: [["darker", 10]],
              }
            : undefined
        }
      />
    </Box>
  );
};

export default Sunburst;
