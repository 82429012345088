import { useState } from "react";
import {
  CHART_COLOR_SCHEMES,
  CHART_THEME_LOCAL_STORAGE_KEY,
} from "../../ExcellenceWidgets/nivoTheme";
import SelectDynamic from "../../MaterialUI/FormFields/SelectDynamic";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { Stack, Box } from "@mui/material";

const CHART_THEME_OPTIONS = {
  GIANT_THEME_ONE: "giantPaletteOne",
  GIANT_THEME_TWO: "giantPaletteTwo",
  BOSCH_THEME_ONE: "boschPaletteOne",
  BOSCH_THEME_TWO: "boschPaletteTwo",
  MISUMI_THEME_ONE: "misumiPaletteOne",
  OK_THEME_ONE: "oKPaletteOne",
  OK_THEME_TWO: "oKPaletteTwo",
};

type ChartThemeOptions = keyof typeof CHART_THEME_OPTIONS;

interface ChartColorPaletteProps {
  paletteColors: string[];
}

const SelectChartColorPalette = () => {
  const styles = { ...cssLayoutStyles };
  const [chartTheme, setChartTheme] = useState<ChartThemeOptions>(
    (localStorage.getItem(CHART_THEME_LOCAL_STORAGE_KEY) as ChartThemeOptions) ||
      CHART_THEME_OPTIONS.GIANT_THEME_ONE
  );

  const selectedOptionsData = [
    {
      value: CHART_THEME_OPTIONS.GIANT_THEME_ONE,
      component: (
        <ChartColorPalette paletteColors={CHART_COLOR_SCHEMES.giantPaletteOne} />
      ),
    },
    {
      value: CHART_THEME_OPTIONS.GIANT_THEME_TWO,
      component: (
        <ChartColorPalette paletteColors={CHART_COLOR_SCHEMES.giantPaletteTwo} />
      ),
    },
    {
      value: CHART_THEME_OPTIONS.BOSCH_THEME_ONE,
      component: (
        <ChartColorPalette paletteColors={CHART_COLOR_SCHEMES.boschPaletteOne} />
      ),
    },
    {
      value: CHART_THEME_OPTIONS.BOSCH_THEME_TWO,
      component: (
        <ChartColorPalette paletteColors={CHART_COLOR_SCHEMES.boschPaletteTwo} />
      ),
    },
    {
      value: CHART_THEME_OPTIONS.MISUMI_THEME_ONE,
      component: (
        <ChartColorPalette paletteColors={CHART_COLOR_SCHEMES.misumiPaletteOne} />
      ),
    },
    {
      value: CHART_THEME_OPTIONS.OK_THEME_ONE,
      component: <ChartColorPalette paletteColors={CHART_COLOR_SCHEMES.oKPaletteOne} />,
    },
    {
      value: CHART_THEME_OPTIONS.OK_THEME_TWO,
      component: <ChartColorPalette paletteColors={CHART_COLOR_SCHEMES.oKPaletteTwo} />,
    },
  ];

  const handleChartsPalette = (palette: ChartThemeOptions) => {
    setChartTheme(palette);
    return localStorage.setItem("chartTheme", palette);
  };

  return (
    <SelectDynamic
      css={styles.widthLimit20}
      selectOptions={selectedOptionsData}
      value={chartTheme}
      onChange={(e) => handleChartsPalette(e.target.value as ChartThemeOptions)}
      fullWidth
    />
  );
};

const ChartColorPalette: React.FC<ChartColorPaletteProps> = ({ paletteColors }) => {
  return (
    <Stack direction="row">
      {paletteColors.map((color: string, index: number) => (
        <Box
          component="div"
          key={index}
          style={{
            width: 20,
            height: 20,
            backgroundColor: color,
            marginRight: index < paletteColors.length - 1 ? 2 : 0,
          }}
        />
      ))}
    </Stack>
  );
};

export default SelectChartColorPalette;
