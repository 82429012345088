import { alpha, styled } from "@mui/material/styles";
import CustomTreeItem from "./SelectTreeItem";
import { treeItemClasses } from "@mui/x-tree-view";
import {
  CollapsibleTreeItemData,
  CollapsibleTreeItemExtraProps,
} from "./collapsibleTreeTypes";

interface RecursiveTreeItemProps extends CollapsibleTreeItemExtraProps {
  data: CollapsibleTreeItemData | null;
}
/** Renders the tree items */
const RecursiveTreeItem: React.FC<RecursiveTreeItemProps> = ({
  data,
  disabled,
  uiValues,
}) => {
  if (!data?.uniqueID) {
    return null;
  }

  if (data.children) {
    return (
      <StyledTreeItem
        nodeId={data.uniqueID}
        label={data.label}
        disabled={disabled}
        uiValues={uiValues}
      >
        {data.children.map((child, index) => (
          <RecursiveTreeItem
            key={`${child?.uniqueID}-${index}`}
            data={child}
            disabled={disabled}
            uiValues={uiValues}
          />
        ))}
      </StyledTreeItem>
    );
  }

  return (
    <StyledTreeItem
      nodeId={data.uniqueID}
      label={data.label}
      disabled={disabled}
      uiValues={uiValues}
    />
  );
};

export default RecursiveTreeItem;

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));
