import { useEffect, useRef, useState } from "react";

type UnsavedChangesProps<ConfigType, SchemaType> = {
  initialConfig: ConfigType;
  initialSchema: SchemaType;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
};

const useExcellenceChartUnsavedChanges = <ConfigType, SchemaType>({
  initialConfig,
  initialSchema,
  handleSetUnsavedChanges,
}: UnsavedChangesProps<ConfigType, SchemaType>) => {
  const initialConfigRef = useRef<ConfigType>(initialConfig);
  const initialSchemaRef = useRef<SchemaType>(initialSchema);

  const [updatedConfig, setUpdatedConfig] = useState<ConfigType>(initialConfig);
  const [updatedSchema, setUpdatedSchema] = useState<SchemaType>(initialSchema);

  const hasUnsavedChanges = useRef(false);

  useEffect(() => {
    if (hasUnsavedChanges.current) {
      return;
    }
    const unsavedChangesDetected =
      JSON.stringify(updatedConfig) !== JSON.stringify(initialConfigRef.current) ||
      JSON.stringify(updatedSchema) !== JSON.stringify(initialSchemaRef.current);

    if (unsavedChangesDetected) {
      handleSetUnsavedChanges(true);
      hasUnsavedChanges.current = true;
    }
  }, [updatedConfig, updatedSchema, handleSetUnsavedChanges]);

  return {
    updatedConfig,
    setUpdatedConfig,
    updatedSchema,
    setUpdatedSchema,
  };
};

export default useExcellenceChartUnsavedChanges;
