import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import Button from "../../../../MaterialUI/Button";
import BasicTable from "../../../../MaterialUI/BasicTable/BasicTable";
import {
  BasicTableColumnCell,
  BasicTableRow,
} from "../../../../MaterialUI/BasicTable/basicTableUtils";
import { useEffect, useState } from "react";
import Modal from "../../../../MaterialUI/Modal";
import { GetQueryMQTTTopicMessageSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import { MQTTSubMapping } from "../../../../../Api/DataSources/apiDSDataTypes";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { handleGetSelectOption } from "../../../../../Global/Utils/commonFunctions";
import MQTTConfigTopicMapping from "./MQTTConfigTopicMapping";
import callApi from "../../../../../Api/callApi";
import { getQueryMQTTTopicMessage } from "../../../../../Api/DataSources/apiDSGetQueries";
import DeleteIcon from "@mui/icons-material/Delete";

const COLUMNS: BasicTableColumnCell[] = [
  { id: "topic", label: "Topic" },
  { id: "timestampKey", label: "Timestamp key" },
  { id: "parameters", label: "Parameters" },
  { id: "remove", label: "Remove topic" },
];

const ADD_TOPIC_TEXT = "Configure new topic mapping";

interface MqttMappingConfigurationProps {
  selectedTopics: string[];
  subMapping: MQTTSubMapping[];
  setSubMapping: React.Dispatch<React.SetStateAction<MQTTSubMapping[]>>;
  broker: string;
  port: string | number;
}

const MqttMappingConfiguration: React.FC<MqttMappingConfigurationProps> = ({
  selectedTopics,
  subMapping,
  setSubMapping,
  broker,
  port,
}) => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [rows, setRows] = useState<BasicTableRow[]>([]);
  const [topicModal, setTopicModal] = useState<string | null>(null);
  const [newTopicToAdd, setNewTopicToAdd] = useState<string>("");
  const [selectedMapping, setSelectedMapping] = useState<MQTTSubMapping | null>(null);
  const [selectedData, setSelectedData] =
    useState<GetQueryMQTTTopicMessageSnippet | null>(null);

  const { setAuthedUser } = useAuthedContext();

  const addTopicOptions = handleGetSelectOption(
    selectedTopics.filter((item) => !subMapping.some((mapping) => mapping.topic === item))
  );

  useEffect(() => {
    setRows(() =>
      subMapping.map((item) => ({
        id: item.topic,
        topic: item.topic,
        timestampKey: item.timestamp_mapping.key,
        parameters: item.parameter_mappings.map((param) => param.key).join(", "),
        remove: (
          <IconButton
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              // remove topic from rows
              setRows((prev) => {
                return prev.filter((prevItem) => prevItem.id !== item.topic);
              });
              // remove topic from subMapping
              setSubMapping((prev) =>
                prev.filter((prevItem) => prevItem.topic !== item.topic)
              );
            }}
          >
            <DeleteIcon color={"error"} />
          </IconButton>
        ),
      }))
    );

    // reset newTopicToAdd
    setNewTopicToAdd("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subMapping]);

  /** Sets the topic which user want to add */
  useEffect(() => {
    (async () => {
      if (topicModal && topicModal !== ADD_TOPIC_TEXT) {
        const found = subMapping.find((item) => item.topic === topicModal);
        setSelectedMapping(() => found || null);
        await handleFetchTopicData(topicModal);
      } else {
        setSelectedMapping(null);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subMapping, topicModal]);

  const handleFetchTopicData = async (topic: string) => {
    try {
      const data = await callApi<GetQueryMQTTTopicMessageSnippet>({
        query: getQueryMQTTTopicMessage(broker, port, topic),
        auth: {
          setAuthedUser,
        },
      });
      setSelectedData(data);

      return data;
    } catch (err) {
      console.log("err - handleFetchTopicData()", err);
      return null;
    }
  };

  const handleCloseModal = () => {
    setTopicModal(null);
    setNewTopicToAdd("");
    setSelectedMapping(null);
    setSelectedData(null);
  };

  return (
    <Stack spacing={3} alignItems="center" justifyContent="center">
      <Typography variant="body2" align="center">
        Your connection is initially created without any topic configuration. In order for
        us to collect data from your connection and propagate it to other services, such
        as Excellence and Advanced, you need to specify mapping configuration for your
        topics.
      </Typography>

      <Box component="div">
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setTopicModal(ADD_TOPIC_TEXT)}
        >
          Configure New Topic Mapping
        </Button>
      </Box>

      <BasicTable
        dense
        data={{ rows: rows, columns: COLUMNS }}
        defaultOrderBy="topic"
        emptyTableMessage="You haven't configured any topic mappings"
        handleOnClick={{
          handleRowButton: (id?: string) => {
            setTopicModal(id || null);
          },
        }}
      />

      <Modal open={!!topicModal} onClose={handleCloseModal} label={topicModal} fullWidth>
        <Box component="div" css={styles.contentBreak}>
          {topicModal === ADD_TOPIC_TEXT ? (
            <MQTTConfigTopicMapping
              setSubMapping={setSubMapping}
              handleCloseModal={handleCloseModal}
              selectedData={selectedData}
              newTopicSelect={{
                topicLabel: newTopicToAdd,
                topicOptions: addTopicOptions,
                setNewTopicToAdd: setNewTopicToAdd,
                handleFetchTopicData: handleFetchTopicData,
              }}
            />
          ) : null}

          {selectedMapping ? (
            <MQTTConfigTopicMapping
              setSubMapping={setSubMapping}
              handleCloseModal={handleCloseModal}
              selectedData={selectedData}
              topicMapping={selectedMapping}
            />
          ) : null}
        </Box>
      </Modal>
    </Stack>
  );
};

export default MqttMappingConfiguration;
