import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";

export type AnomalyDetectionTableColumnsAndRowsShema = {
  id: string;
  label: string;
  type: string;
  columns: TableGridColumnSchema[];
};

export interface AnomalyDetectionTableRow {
  id: string;
  tagNumber: string;
  description: string;
  system: string;
  unit: string;
  startDate: Date;
  endDate: Date;
  asIsAverage: number;
  asIsDeviation: number;
  asIsMin: number;
  asIsMax: number;
  toBeAverage: number;
  toBeDeviation: number;
  toBeMin: number;
  toBeMax: number;
}

export const colSchema: AnomalyDetectionTableColumnsAndRowsShema[] = [
  {
    id: "meta",
    label: "Meta",
    type: "group",
    columns: [
      { id: "tagNumber", label: "Tag No.", type: "string" },
      { id: "description", label: "Description", type: "string" },
      { id: "system", label: "System", type: "string" },
      { id: "unit", label: "Unit", type: "string" },
    ],
  },
  {
    id: "asIs",
    label: "As-Is",
    type: "group",
    columns: [
      {
        id: "asIsAverage",
        label: "Avg",
        type: "number",
        marked: true,
        markedId: "asIsAverage",
      },
      {
        id: "asIsDeviation",
        label: "σ",
        type: "number",
        marked: true,
        markedId: "asIsDeviation",
      },
      { id: "asIsMin", label: "Min", type: "number", marked: true, markedId: "asIsMin" },
      { id: "asIsMax", label: "Max", type: "number", marked: true, markedId: "asIsMax" },
    ],
  },
  {
    id: "toBe",
    label: "To-Be",
    type: "group",
    columns: [
      {
        id: "toBeAverage",
        label: "Avg",
        type: "number",
        marked: true,
        markedId: "toBeAverage",
      },
      {
        id: "toBeDeviation",
        label: "σ",
        type: "number",
        marked: true,
        markedId: "toBeDeviation",
      },
      { id: "toBeMin", label: "Min", type: "number", marked: true, markedId: "toBeMin" },
      { id: "toBeMax", label: "Max", type: "number", marked: true, markedId: "toBeMax" },
    ],
  },
];
