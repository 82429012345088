import React from "react";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { Alert } from "../../../Api/Rules/apiRulesSnippets";

export type KanbanTaskStatus = "planned" | "active" | "finished";
export type KanbanTemplateFile = {
  id: string;
  file_name: string;
  task_id: string;
  url: string;
};
export type KanbanTemplateFieldType =
  | "date"
  | "string"
  | "datetime"
  | "time"
  | "float"
  | "int"
  | "dropdown"
  | "boolean";
export type KanbanTemplateField = {
  data_field_id: string;
  id: string;
  template_version_id: string;
  order: number;
  data_fields: {
    type: KanbanTemplateFieldType;
    name: string;
    id: string;
  };
};

export type KanbanTaskTemplate = {
  id: string;
  name: string;
  description: string;
  steps: string;
  data_fields: KanbanTemplateField[];
  created_at: string;
  updated_at: string;
  files: KanbanTemplateFile[];
  image_mapping?: Record<string, string> | null;
  status: "active" | "draft";
  template_id: string;
  version: string;
};
export type KanbanTaskTemplateRow = {
  id: string;
  name: string;
  description: string;
  created_at: string;
  updated_at: string;
  steps: string;
  actions: React.ReactNode;
};

export type KanbanTaskField = {
  data_id: string;
  data_value: any;
  id: string;
  task_id: string;
  data_field: {
    name: string;
    id: string;
    type: KanbanTemplateFieldType;
    url?: string | null;
    readonly?: boolean;
  };
};
export type KanbanTask = {
  allowed_nodes: string[];
  created_at: string;
  updated_at: string;
  id: string;
  node_id: string;
  order_id: string;
 order_name: string;
  tasks_data: KanbanTaskField[];
  status: KanbanTaskStatus;
  template_id: string;
  template_version: string;
  workflow_id: string;
  workflow_version: string;
  template: KanbanTaskTemplate;
  workflow_name: string;
};

export type KanbanTaskRow = {
  id: string;
  name: string;
  workflow: string;
  order: string;
  status: React.ReactNode;
  description: string;
  created_at: string;
  updated_at: string;
  actions: React.ReactNode;
};

export const KAN_TASK_STATUS_MAP: Record<KanbanTaskStatus, string> = {
  planned: "Planned",
  active: "Active",
  finished: "Finished",
};

export const KAN_TASK_STATUS_OPTIONS: SelectOption[] = [
  {
    value: "planned",
    description: KAN_TASK_STATUS_MAP.planned,
  },
  {
    value: "active",
    description: KAN_TASK_STATUS_MAP.active,
  },
  {
    value: "finished",
    description: KAN_TASK_STATUS_MAP.finished,
  },
];

export const EMPTY_KANBAN_TASK_TEMPLATE: KanbanTaskTemplate = {
  id: "",
  name: "",
  description: "",
  created_at: "",
  updated_at: "",
  steps: "",
  files: [],
  data_fields: [],
  status: "active",
  template_id: "",
  version: "",
};

export const generateKanAlerts = (alerts: Alert[]) => {
  let oldAlerts: Alert[] = [];
  try {
    const kanAlerts = localStorage.getItem("unreadKanAlerts");
    if (kanAlerts) {
      const parsed = JSON.parse(kanAlerts);
      oldAlerts.push(...parsed);
    }
  } catch (err) {}

  const alertsString = JSON.stringify([...alerts, ...oldAlerts]);
  localStorage.setItem("unreadKanAlerts", alertsString);
};
