export const CHART_THEME_LOCAL_STORAGE_KEY = "chartTheme";

export enum DynamicGridChartType {
  GIANT_PALETTE_ONE = "giantPaletteOne",
  GIANT_PALETTE_TWO = "giantPaletteTwo",
  BOSCH_PALETTE_ONE = "boschPaletteOne",
  BOSCH_PALETTE_TWO = "boschPaletteTwo",
}

export interface ChartColorSchemes {
  giantPaletteOne: string[];
  giantPaletteTwo: string[];
  boschPaletteOne: string[];
  boschPaletteTwo: string[];
  misumiPaletteOne: string[];
  oKPaletteOne: string[];
  oKPaletteTwo: string[];
}

export const CHART_COLOR_SCHEMES: ChartColorSchemes = {
  giantPaletteOne: ["#30AFB8", "#E2F6F8", "#C1ECEF", "#7ED7DE", "#5CCDD5", "#3BC3CC"],
  giantPaletteTwo: ["#1E88E5", "#D8EAFA", "#A7D1F5", "#8AC1F1", "#6DB2EE", "#50A2EA"],
  boschPaletteOne: [
    "#89bc6e",
    "#0a9d5c",
    "#39a3cb",
    "#255ea1",
    "#273c81",
    "#5b3b8e",
    "#d42027",
    "#b12739",
  ],
  boschPaletteTwo: [
    "#b12739",
    "#d42027",
    "#5b3b8e",
    "#273c81",
    "#255ea1",
    "#39a3cb",
    "#0a9d5c",
    "#89bc6e",
  ],
  misumiPaletteOne: [
    "#183890",
    "#ffd22b",
    "#F77411",
    "#e60012",
    "#999999",
    "#2e5dbb",
    "#8DA0C8",
    "#749dcf",
  ],
  oKPaletteOne: ["#006400", "#228B22", "#FFD700", "#FFA500", "#DC143C"],
  oKPaletteTwo: ["#DC143C", "#FFA500", "#FFD700", "#228B22", "#006400"],
};

export const getChartColors = (): ChartColorSchemes[keyof ChartColorSchemes] => {
  const storedChartTheme = localStorage.getItem(
    CHART_THEME_LOCAL_STORAGE_KEY
  ) as DynamicGridChartType;

  const defaultChartTheme: DynamicGridChartType = storedChartTheme;

  const currentColorScheme: ChartColorSchemes[keyof ChartColorSchemes] =
    CHART_COLOR_SCHEMES[defaultChartTheme] ||
    CHART_COLOR_SCHEMES[DynamicGridChartType.GIANT_PALETTE_ONE];

  return currentColorScheme;
};

export const getCurrentColorScheme = (): DynamicGridChartType => {
  const storedChartTheme = localStorage.getItem(
    CHART_THEME_LOCAL_STORAGE_KEY
  ) as DynamicGridChartType;

  const currentChartTheme: DynamicGridChartType =
    storedChartTheme || DynamicGridChartType.GIANT_PALETTE_ONE;

  return currentChartTheme;
};
