import { Box, IconButton, Stack, Typography } from "@mui/material";
import Modal from "../../MaterialUI/Modal";
import { useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import cssComponentsStyles from "../../../Global/Styles/components";

const ChartScopeMode: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [openModal, setOpenModal] = useState<boolean>(false);

  return (
    <Box component="div">
      <Stack spacing={1.5} alignItems="center" direction="row">
        <Typography color="textSecondary" variant="h4">
          Scope Mode
        </Typography>
        <IconButton size="small" onClick={() => setOpenModal(true)}>
          <InfoOutlinedIcon
            style={{ marginRight: "3px" }}
            css={[styles.greyIcon, styles.infoIcon]}
          />
        </IconButton>
      </Stack>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth={"sm"}
        label={"Scope Mode"}
      >
        <Box component="div">
          <Typography css={styles.sectionBreak} variant="body1">
            Scope mode allows you to select two points on the chart - start time and end
            time. When both points are selected, a new chart will be plotted with the
            selected scope.
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChartScopeMode;
