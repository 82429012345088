import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import Login from "../../authPages/Login";
import Register from "../../authPages/Register";
import ForgottenPassword from "../../authPages/ForgottenPassword";
import TermsConditions from "../../authPages/TermsConditions";
import CookiePolicy from "../../authPages/CookiePolicy";
import PrivacyPolicy from "../../authPages/PrivacyPolicy";

export default createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Login />} />
      <Route path="/sign-up" element={<Register />} />
      <Route path="/forgotten-password" element={<ForgottenPassword />} />
      <Route path="/terms-and-conditions" element={<TermsConditions />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<Navigate to="/" replace={true} />} />
    </>
  )
);
