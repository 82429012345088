import { Query } from "../callApi";

export const getQueryLocations: Query = {
  endpoint: `supply_chain/location/locations`,
  method: "GET",
};

export const getQueryLocationsTypes: Query = {
  endpoint: `supply_chain/location/locations/types`,
  method: "GET",
};

export const getQueryLocationTypeParents = (location_type: string): Query => ({
  endpoint: `supply_chain/location/locations/types/${location_type}/parents`,
  method: "GET",
});
