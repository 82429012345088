import { memo, useEffect, useState } from "react";
import {
  PostQueryDaNodesSnippet,
  GetQueryDAAttributesSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import { SelectOption } from "../../../../../Global/Types/commonTypes";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Button from "../../../../MaterialUI/Button";
import RefreshIcon from "@mui/icons-material/Refresh";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import callApi from "../../../../../Api/callApi";
import { postQueryDaNodes } from "../../../../../Api/DataSources/apiDSPostQueries";
import { DaFormattedCanonical } from "./daUtils";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { AuthedUser } from "../../../../../context/authContextTypes";
import { PostQueryDaNodesInput } from "../../../../../Api/DataSources/apiDSInputs";

const cssStyles = {
  tableCell: css({
    border: "none",
    verticalAlign: "baseline",
  }),
};

interface DaLastItemProps {
  lastSelectedItem: string | null;
  selectedGroupProperties: GetQueryDAAttributesSnippet;
  serverName: string | null;
  canonicalDetails: DaFormattedCanonical | null;
}

const DaLastItem: React.FC<DaLastItemProps> = ({
  lastSelectedItem,
  selectedGroupProperties,
  serverName,
  canonicalDetails,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles, ...cssSpacingStyles(theme) };
  const [itemProps, setItemProps] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupProperties, lastSelectedItem]);

  const handleFetchData = async () => {
    try {
      if (lastSelectedItem) {
        setLoading(true);
        const formattedItemProps = await getItemProps(
          selectedGroupProperties,
          serverName,
          canonicalDetails,
          lastSelectedItem,
          setAuthedUser
        );
        setItemProps(() => formattedItemProps);
      }
      setLoading(false);
    } catch (err) {
      console.log("DaItem useEffect err ", err);
      setLoading(false);
    }
  };

  return (
    <Box component="div">
      {itemProps.length ? (
        <>
          <Button
            css={styles.labelBreak}
            onClick={handleFetchData}
            variant="outlined"
            endIcon={<RefreshIcon />}
            disabled={loading}
          >
            Get new data
          </Button>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell css={styles.tableCell}>
                    <Typography fontWeight={600}>Name:</Typography>
                  </TableCell>
                  <TableCell css={styles.tableCell}>
                    <Typography fontWeight={600}>Value:</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {itemProps.map((item, index) => (
                  <TableRow key={`${item.description}-${index}`}>
                    <TableCell css={styles.tableCell}>{item.description}</TableCell>
                    <TableCell css={styles.tableCell}>{item.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <Typography css={styles.sectionBreak} variant="h4">
          Select a node to see details about its attributes
        </Typography>
      )}
    </Box>
  );
};

export default memo(DaLastItem);

const getItemProps = async (
  selectedGroupProperties: GetQueryDAAttributesSnippet,
  serverName: string | null,
  canonicalDetails: DaFormattedCanonical | null,
  item: string,
  setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>
): Promise<SelectOption[]> => {
  // set name pair
  const result = [{ description: "Item name", value: item || "" }];

  if (!serverName) {
    throw new Error("Server not found");
  }

  if (item) {
    // if item exists - fetch details for it
    const input: PostQueryDaNodesInput = {
      server_name: serverName,
      nodes: [item],
    };
    const itemDetails = await callApi<PostQueryDaNodesSnippet>({
      query: postQueryDaNodes(input),
      auth: { setAuthedUser },
    });
    // format the first item prop
    const formattedDetails: DaFormattedCanonical = itemDetails[0].attributes.reduce(
      (acc: DaFormattedCanonical, curr) => {
        const field = curr.id;
        const value = curr.value;
        return {
          ...acc,
          [field]: value,
        };
      },
      {}
    );

    for (const prop of selectedGroupProperties) {
      const propID = prop.opcda_attribute_id;
      let propValue = formattedDetails?.[propID];
      let canonicalValue = null;
      if (propID === 1) {
        // it's canonical - use it's data type value
        canonicalValue = canonicalDetails?.[propValue];
      }

      result.push({
        description: prop.name,
        value: canonicalValue || propValue || "",
      });
    }
  } else {
    // no item - just show attributes
    for (const prop of selectedGroupProperties) {
      result.push({
        description: prop.name,
        value: "",
      });
    }
  }

  return result;
};
