import { OperationsWidget } from "../../../../Api/Operations/apiOperationsSnippets";
import { WidgetGridItem } from "../../../LargeComponents/WidgetsGrid/widgetsGridUtils";

export type DynamicFormGridItemData = {
  widget: {
    formField?: DynamicFormGridItemFormField;
  };
  loading?: boolean;
  title: string;
  id?: string | number;
};

export type DynamicFormGridItemFormField = {
  button?: boolean;
  textField?: boolean;
  textArea?: boolean;
  radioButton?: boolean;
  checkbox?: boolean;
  dropdown?: boolean;
  switch?: boolean;
  dateTime?: boolean;
};

export type DynamicFormGridLayoutSettings = {
  name: string;
  id: string | number;
};

export const dynamicFromHandlePrepareWidgetsData = (
  data: OperationsWidget[]
): WidgetGridItem<DynamicFormGridItemData>[] => {
  const result = data.map((widget) => {
    const { layout, ...rest } = widget.content;
    return {
      layout: widget.content.layout,
      id: widget.id,
      ...rest,
    };
  }) as WidgetGridItem<DynamicFormGridItemData>[];

  return result;
};
