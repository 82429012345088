import { UaNode } from "../../../../../Api/DataSources/apiDSDataTypes";
import { TableGridColumnSchema } from "../../../../SmallComponents/TableGrid/constructTableGrid";

export type UaAuthUsernamePass = {
  username: string;
  password: string;
} | null;

export const prepareUaSubColumns = (): TableGridColumnSchema[] => {
  return [
    {
      id: "DisplayName",
      label: "Display Name",
      type: "string",
    },
    {
      id: "NodeId",
      label: "Node Id",
      type: "string",
    },
    {
      id: "DataType",
      label: "Data Type",
      type: "string",
    },
    {
      id: "StatusCode",
      label: "Status Code",
      type: "string",
    },
    {
      id: "Source Timestamp",
      label: "Source Timestamp",
      type: "string",
    },
    {
      id: "Sever Timestamp",
      label: "Sever Timestamp",
      type: "string",
    },
    {
      id: "Value",
      label: "Value",
      type: "string",
    },
  ];
};

export const prepareUaSubRows = (nodes: UaNode[]): Record<string, any>[] => {
  const emptyValue = {
    "Sever Timestamp": "",
    "Source Timestamp": "",
    StatusCode: "",
    Value: "",
  };

  const result = nodes.map((node, index) => {
    const nodeValue = Object.entries(node.Value || emptyValue);
    const cellsValue = nodeValue.reduce((acc, curr) => {
      const field = curr[0];
      const value = curr[1];
      return {
        ...acc,
        [field]: value,
      };
    }, {});

    const cellsNonValue = {
      NodeId: node.NodeId,
      DisplayName: node.DisplayName,
      DataType: node.DataType,
    };

    return {
      id: index,
      ...cellsNonValue,
      ...cellsValue,
    };
  });

  return result;
};
