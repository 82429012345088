import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import { object } from "yup";
import {
  YUP_DATE,
  YUP_DATE_REQUIRED,
  YUP_NUMBER_REQUIRED_NUMBER,
  YUP_REQUIRED_STRING,
  YUP_STRING,
} from "../../../../Global/Constants/yupConstants";
import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../../../Global/Types/commonTypes";
import { Formik } from "formik";
import {
  QualityParametersFormValues,
  measurementUnitsByTest,
  parameterTypeScheme,
  parameterTypeSchemeOptions,
  qualityControlTestsCheckboxes,
  temperatureControlScheme,
  temperatureControlSchemeOptions,
} from "./staticFormsUtils";
import { Grid, Stack, Typography } from "@mui/material";
import ContentBox from "../../../MaterialUI/ContentBox";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import DatePicker from "../../../MaterialUI/DateTimePickers/DatePicker";
import RadioGroup from "../../../MaterialUI/FormFields/RadioGroup";
import Select from "../../../MaterialUI/FormFields/Select";
import Checkbox from "../../../MaterialUI/FormFields/Checkbox";
import Button from "../../../MaterialUI/Button";
import Alert from "../../../MaterialUI/Alert";
import ParametersInfo from "./ParametersInfo";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";

const fieldValidation = object({
  productName: YUP_REQUIRED_STRING,
  manufacturingingDate: YUP_DATE_REQUIRED,
  parameterType: YUP_REQUIRED_STRING,
  temperatureControl: YUP_REQUIRED_STRING,
  measurementUnit: YUP_REQUIRED_STRING,
  parameterValue: YUP_NUMBER_REQUIRED_NUMBER,
  confirmationDate: YUP_DATE,
  recommededActions: YUP_STRING,
});

const initialValues: QualityParametersFormValues = {
  productName: "",
  manufacturingingDate: null,
  parameterType: "Physical",
  isPhTestingChecked: false,
  isMicrobialAnalysisChecked: false,
  isStabilityTestingChecked: false,
  temperatureControl: "",
  measurementUnit: "",
  parameterValue: null,
  confirmationDate: null,
  recommededActions: "",
};

const QualityParametersStaticForm: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [parameterType, setParameterType] = useState<parameterTypeScheme | null>(null);
  const [temperatureControl, setTemperatureControl] =
    useState<temperatureControlScheme | null>(null);
  const [isParameterInRange, setIsParameterInRange] = useState<boolean | null>(null);
  const [measurementUnitOptions, setMeasurementUnitOptions] = useState<SelectOption[]>(
    []
  );

  const handleFormSubmit = (values: QualityParametersFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      if (
        !values.productName ||
        !values.manufacturingingDate ||
        !values.measurementUnit ||
        values.parameterValue === null
      ) {
        setFormStatus("warning");
        setAlertMessage("All required fields must be filled out");
        return;
      }

      setFormStatus("success");
      setAlertMessage("Submission Successful!");
    } catch (err) {
      console.error("QualityParametersStaticForm handleFormSubmit error:", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong during submission.");
    }
  };

  const updateMeasurementUnitOptions = (values: QualityParametersFormValues) => {
    const selectedTests = (
      Object.keys(values) as Array<keyof QualityParametersFormValues>
    ).filter((key) => key.startsWith("is") && values[key]);

    const newOptions = selectedTests.reduce((acc: string[], testKey) => {
      const units =
        measurementUnitsByTest[testKey as keyof typeof measurementUnitsByTest];
      return acc.concat(units);
    }, []);

    setMeasurementUnitOptions(handleGetSelectOption(newOptions, true));
  };

  useEffect(() => {
    updateMeasurementUnitOptions(initialValues);
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => {
        useEffect(() => {
          updateMeasurementUnitOptions(values);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [
          values.isPhTestingChecked,
          values.isMicrobialAnalysisChecked,
          values.isStabilityTestingChecked,
        ]);

        const isAnyQualityControlTestSelected =
          values.isPhTestingChecked ||
          values.isMicrobialAnalysisChecked ||
          values.isStabilityTestingChecked;

        return (
          <form onSubmit={handleSubmit}>
            <Typography align="center" mb={4} mt={2} variant="h2">
              Collecting and Processing Quality Parameters Form
            </Typography>

            <ContentBox css={styles.fullPadding3}>
              <Grid container spacing={8} css={styles.contentBreak}>
                <Grid item xs={12} sm={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        name="productName"
                        label="Product Name"
                        error={touched["productName"] && !!errors["productName"]}
                        helperText={touched["productName"] && errors["productName"]}
                        onChange={handleChange}
                        value={values.productName}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <DatePicker
                        name="manufacturingingDate"
                        label="Manufacturing Date"
                        error={
                          touched["manufacturingingDate"] &&
                          !!errors["manufacturingingDate"]
                        }
                        helperText={
                          touched["manufacturingingDate"] &&
                          errors["manufacturingingDate"]
                        }
                        onChange={(value) => setFieldValue("manufacturingingDate", value)}
                        value={values.manufacturingingDate}
                        css={styles.width100}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <RadioGroup
                        radioOptions={parameterTypeSchemeOptions}
                        label="Parameter Type"
                        value={parameterType}
                        onChange={(e) =>
                          setParameterType(e.target.value as parameterTypeScheme)
                        }
                        row
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        mb={0.5}
                        variant="h4"
                        style={theme.customizedTextStyles.labelStyles}
                      >
                        Quality Control Tests
                      </Typography>

                      <Stack direction={"row"}>
                        {qualityControlTestsCheckboxes.map((checkbox, index) => (
                          <Checkbox
                            key={index}
                            label={`${checkbox.label}`}
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue(checkbox.name, e.target.checked);
                            }}
                            name={checkbox.name}
                            size="small"
                          />
                        ))}
                      </Stack>
                    </Grid>

                    <Grid item xs={12}>
                      <RadioGroup
                        radioOptions={temperatureControlSchemeOptions}
                        label="Temperature Control"
                        value={temperatureControl}
                        onChange={(e) =>
                          setTemperatureControl(
                            e.target.value as temperatureControlScheme
                          )
                        }
                        row
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        name="parameterValue"
                        label="Parameter Value"
                        numberField={true}
                        error={touched["parameterValue"] && !!errors["parameterValue"]}
                        helperText={touched["parameterValue"] && errors["parameterValue"]}
                        onChange={(e) => {
                          const value =
                            e.target.value === "" ? null : Number(e.target.value);
                          setFieldValue("parameterValue", value);
                        }}
                        value={values.parameterValue}
                        inputProps={{
                          autoComplete: "off", 
                        }}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Select
                        selectOptions={
                          measurementUnitOptions.length > 0
                            ? measurementUnitOptions
                            : [
                                {
                                  description: "Please select a quality control test",
                                  value: "",
                                },
                              ]
                        }
                        name="measurementUnit"
                        label="Units of Measure"
                        error={touched["measurementUnit"] && !!errors["measurementUnit"]}
                        helperText={
                          touched["measurementUnit"] && errors["measurementUnit"]
                        }
                        onChange={handleChange}
                        value={values.measurementUnit}
                        disabled={!isAnyQualityControlTestSelected}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <ParametersInfo
                    measurementUnit={values.measurementUnit}
                    parameterValue={values.parameterValue}
                    isParameterInRange={isParameterInRange}
                    setIsParameterInRange={setIsParameterInRange}
                  />

                  {values.measurementUnit &&
                    values.parameterValue &&
                    (isParameterInRange ? (
                      <Grid item xs={12} mt={1}>
                        <DatePicker
                          name="confirmationDate"
                          label="Confirmation Date"
                          onChange={(value) => setFieldValue("confirmationDate", value)}
                          value={values.confirmationDate}
                          css={styles.width100}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} mt={1}>
                        <TextField
                          name="recommededActions"
                          label="Recomended Actions"
                          onChange={handleChange}
                          value={values.recommededActions}
                        />
                      </Grid>
                    ))}
                </Grid>
              </Grid>

              <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
                <Button
                  type="submit"
                  onClick={() => handleFormSubmit(values)}
                  loading={formStatus === "loading"}
                  css={[styles.width100, styles.widthLimit10]}
                >
                  Submit
                </Button>

                <Alert
                  message={alertMessage || ""}
                  showAlert={!!alertMessage}
                  severity={formStatus}
                />
              </Stack>
            </ContentBox>
          </form>
        );
      }}
    </Formik>
  );
};

export default QualityParametersStaticForm;
