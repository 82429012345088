import { Query } from "../callApi";

export const getQueryLabelLanguages: Query = {
  endpoint: "label_recognition/languages",
  method: "GET",
};
export const getQueryLabelImage = (bucket: string, filename: string): Query => ({
  endpoint: `label_recognition/image/${bucket}`,
  method: "GET",
  variables: { filename },
});
export const getQueryAllLabelImages = (bucket: string): Query => ({
  endpoint: `label_recognition/images/${bucket}`,
  method: "GET",
});
