import React, { memo } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Typography } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FlowInitialNodeData } from "../flowNodesTypes";
import FlowNode from "./FlowNode";
import cssLayoutStyles from "../../../../../Global/Styles/layout";

interface FlowInitialNodeProps {
  id: string;
  data: FlowInitialNodeData;
}

const FlowInitialNode: React.FC<FlowInitialNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };

  return (
    <FlowNode id={id} nodeType={"initial"}>
      <Box
        component="div"
        style={{ height: "calc(100% - 45px)" }}
        css={[styles.width100, styles.flexCenter]}
      >
        <Typography variant="h2" align="center">
          {data.name}
        </Typography>
      </Box>
    </FlowNode>
  );
};

export default memo(FlowInitialNode);
