import { Box, Card, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import DownloadIcon from "@mui/icons-material/Download";
import { KanbanTask } from "../taskManagerUtils";
import Alert from "../../../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import Button from "../../../../Components/MaterialUI/Button";
interface KanTaskFilesProps {
  task: KanbanTask;
}

const KanTaskFiles: React.FC<KanTaskFilesProps> = ({ task }) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);

  const handleDownloadFile = async (link: string) => {
    try {
      if (!link) {
        return;
      }
      const downloadLink = document.createElement("a");
      downloadLink.href = link;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log("err: ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };

  return (
    <Box component="div">
      <Typography css={styles.contentBreak} variant="body1">
        Task Files From Template
      </Typography>

      {task.template.files.length ? (
        <Stack css={[styles.sectionBreak, styles.leftRightPadding1]} spacing={3}>
          {task.template.files.map((item) => (
            <Card key={item.id}>
              <Box component="div" css={styles.fullPadding1}>
                <Stack direction={"row"} spacing={3} alignItems="center">
                  <Typography style={{ flex: 1 }} variant="body1">
                    {item.file_name}
                  </Typography>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <IconButton
                      aria-label="download file"
                      onClick={() => handleDownloadFile(item.url)}
                    >
                      <DownloadIcon color="primary" />
                    </IconButton>
                  </Stack>
                </Stack>
              </Box>
            </Card>
          ))}
        </Stack>
      ) : (
        // <Typography variant="body2">No files found</Typography>
        <Stack mb={1} alignItems="center" justifyContent="center">
          <Box component="div">
            <Button
              disabled={alertStatus === "loading"}
              color="secondary"
              loading={alertStatus === "loading"}
            >
              Upload File
            </Button>
          </Box>
        </Stack>
      )}

      <Alert
        css={[styles.reverseContentBreak, styles.widthLimit20]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={alertStatus}
      />
    </Box>
  );
};

export default KanTaskFiles;
