import {
  AppBar,
  Divider,
  IconButton,
  Stack,
  Theme,
  Toolbar,
  Typography,
  MenuItem,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { SerializedStyles, css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Logout from "@mui/icons-material/Logout";
import {
  TOP_NAV_HEIGHT,
  TOP_NAV_PADDING,
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
} from "../layoutVariables";
import cssLayoutStyles from "../../Global/Styles/layout";
import Logo from "./Logo";
import AccountMenu from "./AccountMenu";
import { handleUserSignOut } from "../../context/authContextUtils";
import { Link } from "react-router-dom";
import HelpIcon from "@mui/icons-material/Help";
import AlertsMenu from "./AlertsMenu";
import cssComponentsStyles from "../../Global/Styles/components";
import LanguagesMenu from "./LanguagesMenu";
import { useLanguageContext } from "../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  appBar: css({
    height: TOP_NAV_HEIGHT,
    boxShadow: "none",
    zIndex: theme.zIndex.drawer + 1,
    background: theme.palette.common.white,
    paddingBottom: TOP_NAV_SPACING_WITH_SITE_CONTENT,
  }),
  toolbar: css({
    background: theme.palette.common.white,
    padding: TOP_NAV_PADDING,
    paddingTop: theme.spacing(4.7),
    minHeight: `${TOP_NAV_HEIGHT} !important`,
  }),
  userMenu: css({ padding: "1rem 3rem" }),
  userMenuLink: css({
    textDecoration: "none",
    color: theme.palette.text.primary,
  }),
});

interface TopNavigationProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  setOpenLeftNav: React.Dispatch<React.SetStateAction<boolean>>;
  extraMenu: React.ReactNode | null;
  handleHelpClick: () => void;
}

const TopNavigation: React.FC<TopNavigationProps> = ({
  className,
  setOpenLeftNav,
  extraMenu,
  handleHelpClick,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const { t } = useLanguageContext();

  return (
    <AppBar className={className} css={styles.appBar} position="fixed">
      <Toolbar css={styles.toolbar} disableGutters>
        <Stack
          css={styles.width100}
          spacing={4}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <Stack spacing={4} alignItems="center" direction="row">
            <IconButton
              size="large"
              aria-label="site menu"
              onClick={() => setOpenLeftNav((prev) => !prev)}
            >
              <MenuIcon css={styles.iconHover} />
            </IconButton>
            <Logo />
          </Stack>

          <Stack spacing={2} alignItems="center" direction="row">
            {extraMenu ? (
              <>
                {extraMenu}
                <Divider flexItem orientation="vertical" />
              </>
            ) : null}
            <AlertsMenu />
            <LanguagesMenu />
            <Stack alignItems="center">
              <IconButton aria-label="open help menu" onClick={handleHelpClick}>
                <HelpIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textSecondary">
                {t("Help")}
              </Typography>
            </Stack>
            <AccountMenu>
              <Link to={"/account/profile"} css={styles.userMenuLink}>
                <MenuItem css={[styles.userMenu, styles.hoverItem]}>
                  <ListItemIcon>
                    <AccountCircleIcon />
                  </ListItemIcon>
                  {t("Profile")}
                </MenuItem>
              </Link>
              <Divider />
              <MenuItem
                onClick={handleUserSignOut}
                css={[styles.userMenu, styles.hoverItem]}
              >
                <ListItemIcon>
                  <Logout />
                </ListItemIcon>
                {t("Logout")}
              </MenuItem>
            </AccountMenu>
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default TopNavigation;
