import Fuse from "fuse.js";
import {
  titleCaseToCamelCase,
  transformParsedStringToDate,
  transformParsedStringToTime,
} from "../../../../Global/Utils/commonFunctions";
import isValid from "date-fns/isValid";
import isBefore from "date-fns/isBefore";
import isWithinInterval from "date-fns/isWithinInterval";
import { MobileTableGridFormattedRow } from "./mobileTableGridUtils";
import set from "date-fns/set";
import { TableGridDateType } from "../tableGridUtils";

export type MobileTableGridFilterMode =
  | "Fuzzy"
  | "Contains"
  | "Starts with"
  | "Ends with"
  | "Empty"
  | "Not empty"
  | "Equals"
  | "Not equals"
  | "Between"
  | "Between inclusive"
  | "Greater than"
  | "Greater than or equal to"
  | "Less than"
  | "Less than or equal to"
  | "Between dates"
  | "Before"
  | "After"
  | "Yes value"
  | "No value";

export type MobileTableGridSearchQuery = {
  first: string;
  second?: string;
};
export type MobileTableGridDateQuery = {
  firstDate: Date | null;
  secondDate: Date | null;
  type: TableGridDateType;
};

export const mobileTableGridFilterFunctions = (
  mode: MobileTableGridFilterMode,
  searchableColumns: string[],
  query: MobileTableGridSearchQuery,
  records: MobileTableGridFormattedRow[],
  dateQuery: MobileTableGridDateQuery
): MobileTableGridFormattedRow[] => {
  if (!query.first || query.first.trim() === "") {
    // If the query.first is empty, consider it a match for all records
    if (
      mode !== "Before" &&
      mode !== "After" &&
      mode !== "Between dates" &&
      mode !== "Yes value" &&
      mode !== "No value"
    ) {
      return records;
    }
  }
  if (
    (mode === "Between" || mode === "Between inclusive") &&
    (!query.second || query.second.trim() === "")
  ) {
    // If the query.second is empty, consider it a match for all records
    return records;
  }

  const isDateMode = mode === "Before" || mode === "After" || mode === "Between dates";

  // if dateQuery and null, return all records
  if (isDateMode) {
    if (!dateQuery.firstDate || !isValid(dateQuery.firstDate)) {
      return records;
    }
  }

  const keysToSearch = searchableColumns.map((key) => titleCaseToCamelCase(key));
  let returnedRefIndices: number[] = [];

  if (mode === "Fuzzy") {
    returnedRefIndices = [...filterFuzzySearch(query.first, records, keysToSearch)];
  } else if (isDateMode) {
    const formattedQuery: MobileTableGridSearchQuery = {
      first:
        dateQuery.firstDate && isValid(dateQuery.firstDate)
          ? getCorrectFilterDate(
              dateQuery.firstDate,
              dateQuery.type === "time"
            ).toISOString()
          : "",
      second:
        dateQuery.secondDate && isValid(dateQuery.secondDate)
          ? getCorrectFilterDate(
              dateQuery.secondDate,
              dateQuery.type === "time"
            ).toISOString()
          : "",
    };

    returnedRefIndices = [
      ...filterStringSearch(
        formattedQuery,
        records,
        keysToSearch,
        mode,
        dateQuery.type === "time"
      ),
    ];
  } else {
    returnedRefIndices = [
      ...filterStringSearch(query, records, keysToSearch, mode, false),
    ];
  }

  // return a new array containing the desired elements
  // based on the specified indexes.
  const filteredElements = returnedRefIndices.map((index) => records[index]);
  return filteredElements;
};

const filterStringSearch = (
  query: MobileTableGridSearchQuery,
  records: MobileTableGridFormattedRow[],
  keysToSearch: string[],
  operation: MobileTableGridFilterMode,
  isTimeFilter: boolean
): number[] => {
  const matchingIndexes: number[] = [];

  records.forEach((record, index) => {
    for (const key of keysToSearch) {
      const valueToSearch = record[key];
      const formattedValue = `${valueToSearch.formattedValue}`.toLowerCase();
      const lowercaseFirstQuery = query.first.toLowerCase();
      const lowercaseSecondQuery = query.second ? query.second.toLowerCase() : null;

      const algorithmResult = handleAlgorithmLogic(
        operation,
        formattedValue,
        lowercaseFirstQuery,
        lowercaseSecondQuery,
        isTimeFilter
      );

      if (algorithmResult) {
        matchingIndexes.push(index);
        // Break the loop to avoid adding the same index multiple times
        break;
      }
    }
  });

  return matchingIndexes;
};

const handleAlgorithmLogic = (
  operation: MobileTableGridFilterMode,
  valueToSearch: string,
  firstSearchQuery: string,
  secondSearchQuery: string | null,
  isTimeFilter: boolean
) => {
  const valueToSearchNumb = +valueToSearch;
  const firstSearchQueryNumb = +firstSearchQuery;

  switch (operation) {
    case "Contains": {
      return valueToSearch.includes(firstSearchQuery);
    }
    case "Starts with": {
      return valueToSearch.startsWith(firstSearchQuery);
    }
    case "Ends with": {
      return valueToSearch.endsWith(firstSearchQuery);
    }
    case "Empty": {
      return Boolean(!valueToSearch.length);
    }
    case "Not empty": {
      return Boolean(valueToSearch.length);
    }
    case "Equals": {
      return valueToSearch === firstSearchQuery;
    }
    case "Not equals": {
      return valueToSearch !== firstSearchQuery;
    }
    case "Between": {
      if (secondSearchQuery === null) {
        break;
      }
      const secondSearchQueryNumb = +secondSearchQuery;
      return (
        firstSearchQueryNumb < valueToSearchNumb &&
        secondSearchQueryNumb > valueToSearchNumb
      );
    }
    case "Between inclusive": {
      if (secondSearchQuery === null) {
        break;
      }
      const secondSearchQueryNumb = +secondSearchQuery;
      return (
        firstSearchQueryNumb <= valueToSearchNumb &&
        secondSearchQueryNumb >= valueToSearchNumb
      );
    }
    case "Greater than": {
      return valueToSearchNumb > firstSearchQueryNumb;
    }
    case "Greater than or equal to": {
      return valueToSearchNumb >= firstSearchQueryNumb;
    }
    case "Less than": {
      return valueToSearchNumb < firstSearchQueryNumb;
    }
    case "Less than or equal to": {
      return valueToSearchNumb <= firstSearchQueryNumb;
    }
    case "Before": {
      const parsedDateToSearch = isTimeFilter
        ? transformParsedStringToTime(valueToSearch)
        : transformParsedStringToDate(valueToSearch);
      const parsedFirstDate = firstSearchQuery ? new Date(firstSearchQuery) : null;
      if (parsedFirstDate && parsedDateToSearch) {
        const correctDateToSearch = getCorrectFilterDate(parsedFirstDate, isTimeFilter);
        return isBefore(parsedDateToSearch, correctDateToSearch);
      } else {
        break;
      }
    }
    case "After": {
      const parsedDateToSearch = isTimeFilter
        ? transformParsedStringToTime(valueToSearch)
        : transformParsedStringToDate(valueToSearch);

      const parsedFirstDate = firstSearchQuery ? new Date(firstSearchQuery) : null;
      if (parsedFirstDate && parsedDateToSearch) {
        const correctDateToSearch = getCorrectFilterDate(parsedFirstDate, isTimeFilter);
        return isBefore(correctDateToSearch, parsedDateToSearch);
      } else {
        break;
      }
    }
    case "Between dates": {
      const parsedDateToSearch = isTimeFilter
        ? transformParsedStringToTime(valueToSearch)
        : transformParsedStringToDate(valueToSearch);
      const parsedFirstDate = firstSearchQuery ? new Date(firstSearchQuery) : null;
      const parsedSecondDate = secondSearchQuery ? new Date(secondSearchQuery) : null;
      if (parsedFirstDate && parsedDateToSearch && parsedSecondDate) {
        const correctDate = getCorrectFilterDate(parsedDateToSearch, isTimeFilter);

        // try-catch needed as isWithinInterval func can throw an error,
        // if the "start" date is after the "end"
        try {
          return isWithinInterval(correctDate, {
            start: parsedFirstDate,
            end: parsedSecondDate,
          });
        } catch {
          return false;
        }
      } else {
        break;
      }
    }
    case "Yes value": {
      return valueToSearch === "true";
    }
    case "No value": {
      return valueToSearch === "false" || Boolean(!valueToSearch.length);
    }
    default: {
      return true;
    }
  }
};

export const filterFuzzySearch = (
  query: string,
  records: MobileTableGridFormattedRow[],
  keysToSearch: string[]
): number[] => {
  const options = {
    keys: keysToSearch,
  };
  // remove booleans and use formattedValue for dates and times
  const formattedRecords: MobileTableGridFormattedRow[] = [];
  for (const record of records) {
    const recordEntries = Object.entries(record);
    const newRecord = {} as { [key: string]: any };
    for (const [key, val] of recordEntries) {
      const type = val.type;
      if (type !== "boolean") {
        newRecord[key] = val.formattedValue;
      }
    }
    formattedRecords.push(newRecord);
  }

  const fuse = new Fuse(formattedRecords, options);
  const result = fuse.search(query);
  return result.map((val) => val.refIndex);
};

const getCorrectFilterDate = (originalDate: Date, isTimeFilter: boolean): Date => {
  if (!isTimeFilter) {
    return originalDate;
  }

  const newDate = new Date();
  const resultDate = set(newDate, {
    hours: originalDate.getHours(),
    minutes: originalDate.getMinutes(),
    seconds: originalDate.getSeconds(),
    milliseconds: originalDate.getMilliseconds(),
  });
  return resultDate;
};
