import { RadarChartConfiguration } from "../RadarChart/radarChartTypes";

const radarChartDefaultConfiguration: RadarChartConfiguration = {
  colors: "paired",
  interpolate: "linearClosed",
  enableDots: false,
  enableDotLabel: true,
};

export default radarChartDefaultConfiguration;
