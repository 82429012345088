import { Box, Stack } from "@mui/material";
import LabelWithBoldedPart from "../../../Components/MaterialUI/LabelWithBoldedPart";
import { LongPlanningMachineRow, LongPlanningProductRow } from "./longTermPlanningUtils";
import { formatNumber } from "../../../Global/Utils/commonFunctions";

interface LongTermProductViewProps {
  product: LongPlanningProductRow | null;
}

export const LongTermProductView: React.FC<LongTermProductViewProps> = ({ product }) => {
  if (!product) {
    return null;
  }

  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text="ID" bolded={product.product_id} />
        <LabelWithBoldedPart text="Name" bolded={product.product_name} />
        <LabelWithBoldedPart
          text="Forecast Quantity for 2025"
          bolded={`${formatNumber(product.quantity)}`}
        />
        <LabelWithBoldedPart
          text="Planned Percentage"
          bolded={`${formatNumber(Math.round(product.planned_percentage))} %`}
        />
        <LabelWithBoldedPart
          text="Produced on Full Capacity"
          bolded={`${formatNumber(product.produced_on_full_capacity)}`}
        />
      </Stack>
    </Box>
  );
};

interface LongTermMachineViewProps {
  machine: LongPlanningMachineRow | null;
}

export const LongTermMachineView: React.FC<LongTermMachineViewProps> = ({ machine }) => {
  if (!machine) {
    return null;
  }

  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text="ID" bolded={machine.machine_id} />
        <LabelWithBoldedPart text="Type" bolded={machine.machine_type} />
        <LabelWithBoldedPart text="Production Plant" bolded={machine.production_plant} />
        <LabelWithBoldedPart
          text="Products"
          bolded={machine.products.map((ele) => ele).join(", ")}
        />
        <LabelWithBoldedPart
          text="Maximum Speed"
          bolded={`${formatNumber(machine.maximum_speed)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production Per Hour"
          bolded={`${formatNumber(machine.average_output_production_per_hour)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production Per One Shift"
          bolded={`${formatNumber(machine.average_output_production_one_shift)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production Two Shifts"
          bolded={`${formatNumber(machine.average_output_production_two_shift)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production Three Shifts"
          bolded={`${formatNumber(machine.average_output_production_three_shift)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production One Week"
          bolded={`${formatNumber(machine.average_output_production_one_week)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production One Month"
          bolded={`${formatNumber(machine.average_output_production_one_month)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production Three Months"
          bolded={`${formatNumber(machine.average_output_production_three_month)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production Six Months"
          bolded={`${formatNumber(machine.average_output_production_six_month)}`}
        />
        <LabelWithBoldedPart
          text="Average Output Production One Year"
          bolded={`${formatNumber(machine.average_output_production_one_year)}`}
        />
        <LabelWithBoldedPart
          text="Utilization"
          bolded={`${formatNumber(machine.utilization)}%`}
        />
        <LabelWithBoldedPart
          text="Free Capacity"
          bolded={`${formatNumber(machine.free_capacity)}%`}
        />
      </Stack>
    </Box>
  );
};
