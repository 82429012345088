import {
  PID_FLOW_NODE_TYPE,
  PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  PidSharedNodeData,
} from "../pidNodesUtils";
import { Node } from "reactflow";

export type PidWorkflowInitialNodeData = PidSharedNodeData & {
  name: string;
};
export type PidWorkflowsInitialNodeType = Node & {
  data: PidWorkflowInitialNodeData;
};
export const PID_WORKFLOW_INITIAL_NODE_CONFIG = {
  ...PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  style: {
    ...PID_WORKFLOW_DEFAULT_NODE_CONFIG.style,
    border: "3px solid #30AFB8 !important",
    zIndex: 2,
  },
  type: PID_FLOW_NODE_TYPE.initial,
};
