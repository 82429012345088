import { BarChartConfiguration } from "../BarChart/barChartTypes";

const barChartDefaultConfiguration = (isColumnChart: boolean): BarChartConfiguration => ({
  bottomLegend: isColumnChart ? "Date" : "Occurences",
  leftLegend: isColumnChart ? "Material Consumption kg" : "",
  groupMode: "stacked",
  valueScale: "linear",
  minScale: "auto",
  maxScale: "auto",
  enableLabel: true,
  enableGridX: true,
  enableGridY: true,
  isColumn: isColumnChart,
  colors: "paired",
});

export default barChartDefaultConfiguration;
