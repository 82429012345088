import { useEffect, useRef } from "react";
import _ from "lodash";

export const useDetectFormsUnsavedChanges = <T extends object>(
  initialValues: T,
  currentValues: T,
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void
) => {
  const initialValuesRef = useRef<T>(_.cloneDeep(initialValues));

  const hasUnsavedChanges = useRef(false);

  useEffect(() => {
    if (hasUnsavedChanges.current) return;
    const hasChanges = !_.isEqual(initialValuesRef.current, currentValues);

    if (hasChanges) {
      handleSetUnsavedChanges(true);
      hasUnsavedChanges.current = true;
    }
  }, [currentValues, handleSetUnsavedChanges]);
};
