import { Formik } from "formik";
import { Stack } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Button from "../../MaterialUI/Button";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../Global/Constants/yupConstants";
import Alert from "../../MaterialUI/Alert";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { SerializedStyles } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { DynamicGridCategory } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { useLanguageContext } from "../../../context/LanguageContext";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
};

interface CreateDynamicGridLayoutFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  category: DynamicGridCategory;
  handleOnSubmit: (name: string) => Promise<string | undefined>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateDynamicGridLayoutForm: React.FC<CreateDynamicGridLayoutFormProps> = ({
  className,
  category,
  handleOnSubmit,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t, language } = useLanguageContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const label = category === "excellence" ? t("Dashboard") : t("Form");

  const initialValues: FormValues = {
    name: "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const id = await handleOnSubmit(values.name);
      const url = category === "excellence" ? "/GIANT-OpEx/dashboards" : "/my-forms";

      setFormStatus("success");
      setUnsavedChanges(false);
      navigate({
        pathname: `${url}/${id}`,
      });
    } catch (err) {
      console.log("CreateDynamicGridLayoutForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              className={className}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                name="name"
                label={
                  language === "bg" ? `${t("Name")} ${label}` : `${label} ${t("Name")}`
                }
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />
              <Button type="submit" loading={formStatus === "loading"}>
                {t("Create New")} {label}
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateDynamicGridLayoutForm;
