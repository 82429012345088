const columnChartDefaultData = [
  {
    date: "2023-07-01",
    "Area 1": 6800,
    "Area 2": 6526,
    "Area 3": 4856,
  },
  {
    date: "2023-07-02",
    "Area 1": 6485,
    "Area 2": 5862,
    "Area 3": 5123,
  },
  {
    date: "2023-07-03",
    "Area 1": 7256,
    "Area 2": 6884,
    "Area 3": 5612,
  },
  {
    date: "2023-07-04",
    "Area 1": 5965,
    "Area 2": 5321,
    "Area 3": 4678,
  },
  {
    date: "2023-07-05",
    "Area 1": 6755,
    "Area 2": 6356,
    "Area 3": 5487,
  },
  {
    date: "2023-07-06",
    "Area 1": 6114,
    "Area 2": 5336,
    "Area 3": 4865,
  },
  {
    date: "2023-07-07",
    "Area 1": 7356,
    "Area 2": 6951,
    "Area 3": 6347,
  },
];
export default columnChartDefaultData;
