import { Stack } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { object } from "yup";
import { PutQueryExcellenceDashboardInput } from "../../../../Api/Excellence/apiExcellenceInputs";
import { putQueryExcellenceDashboard } from "../../../../Api/Excellence/apiExcellencePostQueries";
import callApi from "../../../../Api/callApi";
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../context/AuthContext";
import { ExcellenceGridLayoutSettings } from "../excellenceUtils";
import Button from "../../../MaterialUI/Button";
import Alert from "../../../MaterialUI/Alert";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import { useLanguageContext } from "../../../../context/LanguageContext";
import { useDetectFormsUnsavedChanges } from "../../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
};

interface UpdateExcellenceDashboardProps {
  settings: ExcellenceGridLayoutSettings;
  handleCloseModal: () => void;
  setSettings: React.Dispatch<React.SetStateAction<ExcellenceGridLayoutSettings | null>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateExcellenceDashboard: React.FC<UpdateExcellenceDashboardProps> = ({
  settings,
  setSettings,
  handleCloseModal,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { t } = useLanguageContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { setAuthedUser } = useAuthedContext();
  const initialValues: FormValues = {
    name: settings.name || "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const excellenceInput: PutQueryExcellenceDashboardInput = {
        id: settings.id,
        name: values.name,
      };
      await callApi<string>({
        query: putQueryExcellenceDashboard(excellenceInput),
        auth: { setAuthedUser },
      });

      handleCloseModal();
      setSettings((prev) => {
        if (prev) {
          return {
            ...prev,
            name: values.name,
          };
        }
        return prev;
      });
      setUnsavedChanges(false);
      setFormStatus("success");
    } catch (err) {
      console.log("UpdateDynamicFormLayout err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <TextField
                name="name"
                label={t("Form Name")}
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />
              <Button type="submit" loading={formStatus === "loading"}>
                {t("Update")}
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default UpdateExcellenceDashboard;
