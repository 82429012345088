import { Query } from "../callApi";

export const getQueryExcellenceDashboards = (breakpoint: string): Query => ({
  endpoint: `/excellence/dashboards/${breakpoint}`,
  method: "GET",
});

export const getQueryExcellenceFullDashboard = (layoutID: string): Query => ({
  endpoint: `/excellence/dashboard/${layoutID}`,
  method: "GET",
});
export const getUnitsOfMeasure: Query = {
  endpoint: `/data_manager/units`,
  method: "GET",
};

export const getExcellenceFile = (dashboardID: string): Query => ({
  endpoint: `/excellence/dashboard/${dashboardID}/files`,
  method: "GET",
});
