import { Box, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../../MaterialUI/LabelWithBoldedPart";
import { formatDateAndTime } from "../../../../../../Global/Utils/commonFunctions";
import BasicTable from "../../../../../MaterialUI/BasicTable/BasicTable";
import { BasicTableColumnCell } from "../../../../../MaterialUI/BasicTable/basicTableUtils";
import { BoschNodeCommonData } from "../../boschNodesUtils";

const MATERIAL_COLUMNS: BasicTableColumnCell[] = [
  { id: "material", label: "Material" },
  { id: "quantity", label: "Quantity" },
];
const PRE_CONDITIONS_COLUMNS: BasicTableColumnCell[] = [
  { id: "key", label: "Pre-condition" },
  { id: "value", label: "Required Value" },
];
const POST_CONDITIONS_COLUMNS: BasicTableColumnCell[] = [
  { id: "key", label: "Post-condition" },
  { id: "value", label: "Required Value" },
];

interface BoschNodeDataModalProps {
  data: BoschNodeCommonData;
}

const BoschNodeDataModal: React.FC<BoschNodeDataModalProps> = ({ data }) => {
  return (
    <Stack spacing={1}>
      {data?.preConditions?.length ? (
        <Box component="div">
          <Box component="div" sx={{ marginBottom: "4px" }}>
            <Typography mb={1} variant="h4" align="center" color="textSecondary">
              Pre-conditions
            </Typography>
            <BasicTable
              dense
              data={{ rows: data.preConditions, columns: PRE_CONDITIONS_COLUMNS }}
              defaultOrderBy="key"
              emptyTableMessage="No pre-conditions found"
              noPagination={data.preConditions.length < 10}
            />
          </Box>
        </Box>
      ) : (
        <LabelWithBoldedPart text="Pre-conditions" bolded={""} />
      )}

      <LabelWithBoldedPart text="ID" bolded={data.id} />
      <LabelWithBoldedPart text="Name" bolded={data.name || ""} />
      <LabelWithBoldedPart text="Cycles" bolded={data.cycles || ""} />
      <LabelWithBoldedPart
        text="Duration"
        bolded={data.duration && data.timeUnit ? `${data.duration} ${data.timeUnit}` : ""}
      />
      <LabelWithBoldedPart
        text="Planned Start Date"
        bolded={data.plannedStartDate ? formatDateAndTime(data.plannedStartDate) : ""}
      />
      <LabelWithBoldedPart
        text="Planned End Date"
        bolded={data.plannedEndDate ? formatDateAndTime(data.plannedEndDate) : ""}
      />
      <LabelWithBoldedPart
        text="Committed Start Date"
        bolded={data.committedStartDate ? formatDateAndTime(data.committedStartDate) : ""}
      />
      <LabelWithBoldedPart
        text="Committed End Date"
        bolded={data.committedEndDate ? formatDateAndTime(data.committedEndDate) : ""}
      />
      <LabelWithBoldedPart
        text="Real Start Date"
        bolded={data.realStartDate ? formatDateAndTime(data.realStartDate) : ""}
      />
      <LabelWithBoldedPart
        text="Real End Date"
        bolded={data.realEndDate ? formatDateAndTime(data.realEndDate) : ""}
      />
      <LabelWithBoldedPart text="Machine Type" bolded={data.machineType || ""} />
      <LabelWithBoldedPart
        text="Machine Allocation"
        bolded={data.machineAllocation || ""}
      />
      <LabelWithBoldedPart text="Machine" bolded={data.machine || ""} />
      <LabelWithBoldedPart text="HR Role" bolded={data.hrRole || ""} />
      <LabelWithBoldedPart text="HR Quantity" bolded={data.hrQuantity || ""} />
      <LabelWithBoldedPart text="HR Allocation" bolded={data.hrAllocation || ""} />
      <LabelWithBoldedPart text="HR" bolded={data.humanResources || ""} />
      <LabelWithBoldedPart
        text="Materials Allocation"
        bolded={data.materialsAllocation || ""}
      />

      {data?.materials?.length ? (
        <Box component="div">
          <Box component="div" sx={{ marginTop: "12px" }}>
            <Typography mb={1} variant="h4" align="center" color="textSecondary">
              Materials
            </Typography>
            <BasicTable
              dense
              data={{ rows: data.materials, columns: MATERIAL_COLUMNS }}
              defaultOrderBy="material"
              emptyTableMessage="No materials found"
              noPagination={data.materials.length < 10}
            />
          </Box>
        </Box>
      ) : (
        <LabelWithBoldedPart text="Materials" bolded={""} />
      )}

      {data?.postConditions?.length ? (
        <Box component="div">
          <Box component="div" sx={{ marginTop: "12px" }}>
            <Typography mb={1} variant="h4" align="center" color="textSecondary">
              Post-conditions
            </Typography>
            <BasicTable
              dense
              data={{ rows: data.postConditions, columns: POST_CONDITIONS_COLUMNS }}
              defaultOrderBy="key"
              emptyTableMessage="No post-conditions found"
              noPagination={data.postConditions.length < 10}
            />
          </Box>
        </Box>
      ) : (
        <LabelWithBoldedPart text="Post-conditions" bolded={""} />
      )}
    </Stack>
  );
};

export default BoschNodeDataModal;
