import { useState } from "react";
import { Grid, InputAdornment, Stack } from "@mui/material";
import Select from "../../MaterialUI/FormFields/Select";
import {
  OpExProject,
  OpExProjectTask,
} from "../../../Api/ProjectManagement/apiProjectDataTypes";
import { object } from "yup";
import {
  YUP_NUMBER_REQUIRED_BETWEEN_0_100,
  YUP_REQUIRED_STRING,
  YUP_STRING_ARRAY,
} from "../../../Global/Constants/yupConstants";
import { Formik } from "formik";
import { COMMON_GRID_SPACING } from "../../../Global/Constants/uiConstants";
import TextField from "../../MaterialUI/FormFields/TextFields";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import { addDays, endOfDay, isAfter, isBefore, isValid } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { putQueryProject } from "../../../Api/ProjectManagement/apiProjectPostQueries";
import { Task } from "gantt-task-react";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
  type: YUP_REQUIRED_STRING,
  progress: YUP_NUMBER_REQUIRED_BETWEEN_0_100,
  ancestors: YUP_STRING_ARRAY,
});

type FormValues = {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  duration: number | null;
  progress: number;
  ancestors: string[];
  predecessors: string[];
  children: string[];
  parentID: string | null;
};

interface PlanningTaskFormProps {
  projectsData: OpExProject[];
  modalType: "task" | "milestone" | "package" | undefined;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setProjectsData: React.Dispatch<React.SetStateAction<OpExProject[]>>;
  setGanttChartData: React.Dispatch<React.SetStateAction<Task[] | undefined>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlanningTaskForm: React.FC<PlanningTaskFormProps> = ({
  projectsData,
  modalType,
  setOpenModal,
  setProjectsData,
  setGanttChartData,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const styles = { ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();
  const [selectedProject, setSelectedProject] = useState<OpExProject | null>();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [daysInsteadOfEndDate, setDaysInsteadOfEndDate] = useState<boolean>(false);
  const [isCheckedWeek, setIsCheckedWeek] = useState<boolean>(false);
  const [isCheckedWrokweek, setIsCheckedWorkweek] = useState<boolean>(false);
  const initialValues: FormValues = {
    name: "",
    startDate: null,
    endDate: null,
    duration: null,
    progress: 0,
    ancestors: [],
    predecessors: [],
    children: [],
    parentID: "",
  };

  const parentOptions: SelectOption[] = selectedProject
    ? selectedProject.tasks
        .filter((item) => {
          // cannot select project
          const noProjectTask = item.id !== selectedProject.id;
          // cannot select children
          let noChildrenTask = true;
          // cannot select predecessors
          let noPredecessorTasks = true;
          // cannot select ancestors
          let noAncestorTasks = true;

          return noProjectTask && noChildrenTask && noPredecessorTasks && noAncestorTasks;
        })
        .map((task) => ({
          value: task.id,
          description: task.name,
        }))
    : [];

  const handleFormSubmit = async (values: FormValues) => {
    setFormStatus("loading");
    setAlertMessage(null);

    if (!selectedProject) return;
    if (!values.name && !values.startDate && !values.endDate && !values.progress) {
      setFormStatus("warning");
      setAlertMessage("Please fill in the form");
      return;
    }

    const parentTask = selectedProject.tasks.find((task) => task.id === values.parentID);

    // Start date cannot be before the project's Start date
    if (values.startDate && isBefore(values.startDate, new Date(selectedProject.start))) {
      setFormStatus("warning");
      setAlertMessage("Start date cannot be before the project's Start date");
      return;
    }
    // End date cannot be after the project's End date
    if (values.endDate && isBefore(new Date(selectedProject.end), values.endDate)) {
      setFormStatus("warning");
      setAlertMessage("End date cannot be after the project's End date");
      return;
    }
    // Duration must be at least 1 day
    if (
      values.startDate &&
      values.endDate &&
      isBefore(values.endDate, values.startDate)
    ) {
      setFormStatus("warning");
      setAlertMessage("Duration must be at least 1 day");
      return;
    }
    // End date cannot be before Start date
    if (
      values.startDate &&
      values.endDate &&
      isBefore(values.endDate, values.startDate)
    ) {
      setFormStatus("warning");
      setAlertMessage("End date cannot be before Start date");
      return;
    }
    // if task has parent, Start date must be after or on the same day as the latest parent's end date
    if (values.parentID) {
      if (
        parentTask &&
        values.startDate &&
        values.endDate &&
        (isBefore(values.startDate, parentTask.start) ||
          (parentTask.end && isAfter(values.endDate, parentTask.end)))
      ) {
        setFormStatus("warning");
        setAlertMessage("Task must be within the parent task's start and end dates");
        return;
      }
    }
    // if task has parent, task must be within the parent start and end dates
    if (values.parentID) {
      if (
        parentTask &&
        values.startDate &&
        parentTask.start &&
        isBefore(values.startDate, parentTask.start)
      ) {
        setFormStatus("warning");
        setAlertMessage("Start date must be within the parent start and end dates");
        return;
      }
      if (
        parentTask &&
        parentTask.end &&
        values.endDate &&
        isBefore(parentTask.end as Date, values.endDate)
      ) {
        setFormStatus("warning");
        setAlertMessage("End date must be within the parent start and end dates");
        return;
      }
    }
    // Duration must be at least 1 day
    if (daysInsteadOfEndDate && !values.duration) {
      setFormStatus("warning");
      setAlertMessage("Duration must be at least 1 day");
      return;
    }

    let calculatedEndDate =
      values.endDate ||
      addDays(values.startDate as Date, (values.duration as number) - 1);

    if (isAfter(calculatedEndDate, new Date(selectedProject.end))) {
      setFormStatus("warning");
      setAlertMessage("End date from duration cannot be after the project's End date");
      return;
    }

    if (values.parentID) {
      if (
        parentTask &&
        parentTask.end &&
        isBefore(parentTask.end as Date, calculatedEndDate)
      ) {
        setFormStatus("warning");
        setAlertMessage(
          "End date from duration must be within the parent start and end dates"
        );
        return;
      }
    }

    try {
      const newTask: OpExProjectTask = {
        id: uuidv4().split("-")[0],
        name: values.name,
        type: modalType ? modalType : "task",
        start: values.startDate ? values.startDate : new Date(),
        end: calculatedEndDate,
        progress: values.progress,
        parentID: values.parentID || "",
      };

      selectedProject.tasks.push(newTask);

      const updateProject = await callApi({
        query: putQueryProject({ data: selectedProject }, selectedProject.id),
        auth: { setAuthedUser },
      });

      if (updateProject) {
        setProjectsData(
          projectsData.map((project) =>
            project.id === selectedProject.id ? selectedProject : project
          )
        );

        const ganttTask: Task = {
          id: newTask.id,
          type:
            newTask.type === "package"
              ? "project"
              : (newTask.type as "task" | "milestone"),
          name: newTask.name,
          start: newTask.start,
          end: newTask.end || new Date(),
          progress: newTask.progress,
          dependencies: newTask.parentID ? [newTask.parentID] : [],
          project: selectedProject.name,
        };

        setGanttChartData((prev) => {
          const updatedPrev = prev || [];
          return [...updatedPrev, ganttTask];
        });
        setFormStatus("success");
        setUnsavedChanges(false);
        setAlertMessage("Task created successfully");
        setOpenModal(false);
      }
    } catch (err) {
      console.log("handleFormSubmit err ", err);
      setFormStatus("error");
      setAlertMessage("Error submitting form");
    }
  };

  return (
    <Stack>
      <Select
        selectOptions={projectsData.map((project) => ({
          label: project.name,
          value: project.id,
          description: project.name,
        }))}
        value={selectedProject}
        label="Select Project"
        onChange={(e) =>
          setSelectedProject(
            projectsData.find((project) => project.id === e.target.value)
          )
        }
      />

      <Stack mt={1} mb={2}>
        <Formik
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
          validationSchema={fieldValidation}
        >
          {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => {
            useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={COMMON_GRID_SPACING}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      label="Name"
                      error={touched["name"] && !!errors["name"]}
                      helperText={touched["name"] && errors["name"]}
                      onChange={handleChange}
                      value={values.name}
                      disabled={!selectedProject}
                    />
                  </Grid>

                  {modalType !== "package" ? (
                    <Grid item xs={12}>
                      <TextField
                        name="progress"
                        label="Progress"
                        error={touched["progress"] && !!errors["progress"]}
                        helperText={touched["progress"] && errors["progress"]}
                        onChange={handleChange}
                        value={values.progress}
                        numberField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">%</InputAdornment>
                          ),
                        }}
                        disabled={!selectedProject}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      name="startDate"
                      label="Start Date"
                      error={touched["startDate"] && !!errors["startDate"]}
                      helperText={touched["startDate"] && errors["startDate"]}
                      onChange={(value) => setFieldValue("startDate", value)}
                      value={values.startDate}
                      minDate={endOfDay(
                        new Date(selectedProject ? selectedProject.start : new Date())
                      )}
                      disabled={!selectedProject}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    {daysInsteadOfEndDate ? (
                      <TextField
                        name="duration"
                        label="Duration"
                        error={touched["duration"] && !!errors["duration"]}
                        helperText={touched["duration"] && errors["duration"]}
                        onChange={handleChange}
                        value={values.duration}
                        numberField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">days</InputAdornment>
                          ),
                        }}
                      />
                    ) : (
                      <DatePicker
                        name="endDate"
                        label="End Date"
                        error={touched["endDate"] && !!errors["endDate"]}
                        helperText={touched["endDate"] && errors["endDate"]}
                        onChange={(value) => setFieldValue("endDate", value)}
                        value={values.endDate}
                        minDate={
                          values.startDate && isValid(values.startDate)
                            ? endOfDay(values.startDate)
                            : endOfDay(
                                new Date(
                                  selectedProject ? selectedProject.start : new Date()
                                )
                              )
                        }
                        disabled={!selectedProject}
                      />
                    )}
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    <Checkbox
                      label="Days instead of end date"
                      checked={daysInsteadOfEndDate}
                      onChange={() => setDaysInsteadOfEndDate((prev) => !prev)}
                    />
                  </Grid>

                  {daysInsteadOfEndDate ? (
                    <Grid item xs={8} sm={12}>
                      <Checkbox
                        label="Duration in normal week"
                        checked={isCheckedWeek}
                        onChange={() => {
                          if (isCheckedWrokweek) {
                            setIsCheckedWorkweek(false);
                          }
                          setIsCheckedWeek((prev) => !prev);
                        }}
                      />
                      <Checkbox
                        label="Duration in work week"
                        checked={isCheckedWrokweek}
                        onChange={() => {
                          if (isCheckedWeek) {
                            setIsCheckedWeek(false);
                          }
                          setIsCheckedWorkweek((prev) => !prev);
                        }}
                      />
                    </Grid>
                  ) : null}

                  <Grid item xs={12} sm={12}>
                    <Select
                      name="parentID"
                      label="Parent Task"
                      error={touched["parentID"] && !!errors["parentID"]}
                      helperText={touched["parentID"] && errors["parentID"]}
                      onChange={handleChange}
                      value={values.parentID}
                      selectOptions={[
                        { value: "none", description: "No parent" },
                        ...parentOptions,
                      ]}
                      disabled={!selectedProject}
                    />
                  </Grid>

                  <Grid item xs={12} mt={2}>
                    <Stack spacing={1} justifyContent="center" alignItems="center">
                      <Button
                        type="submit"
                        onClick={() => handleFormSubmit(values)}
                        loading={formStatus === "loading"}
                        css={[styles.width100, styles.widthLimit10]}
                      >
                        Create
                      </Button>
                      <Alert
                        message={alertMessage || ""}
                        showAlert={!!alertMessage}
                        severity={formStatus}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Formik>
      </Stack>
    </Stack>
  );
};

export default PlanningTaskForm;
