import { LineAreaHeatMapScatterPlotData } from "../EditExcellenceChartForms/excellenceChartFormUtils";

const areaChartDefaultData: LineAreaHeatMapScatterPlotData = [
  {
    id: "Machine 1",
    name: "Machine 1",
    data: [
      {
        x: 2000,
        y: 15,
      },
      {
        x: 2001,
        y: 22,
      },
      {
        x: 2002,
        y: 20,
      },
      {
        x: 2003,
        y: 20,
      },
      {
        x: 2004,
        y: 25,
      },
      {
        x: 2005,
        y: 28,
      },
    ],
  },
  {
    id: "Machine 2",
    name: "Machine 2",
    data: [
      {
        x: 2000,
        y: 30,
      },
      {
        x: 2001,
        y: 15,
      },
      {
        x: 2002,
        y: 15,
      },
      {
        x: 2003,
        y: 12,
      },
      {
        x: 2004,
        y: 27,
      },
      {
        x: 2005,
        y: 20,
      },
    ],
  },
  {
    id: "Machine 3",
    name: "Machine 3",
    data: [
      {
        x: 2000,
        y: 14,
      },
      {
        x: 2001,
        y: 11,
      },
      {
        x: 2002,
        y: 14,
      },
      {
        x: 2003,
        y: 18,
      },
      {
        x: 2004,
        y: 12,
      },
      {
        x: 2005,
        y: 24,
      },
    ],
  },
  {
    id: "Machine 4",
    name: "Machine 4",
    data: [
      {
        x: 2000,
        y: 29,
      },
      {
        x: 2001,
        y: 23,
      },
      {
        x: 2002,
        y: 17,
      },
      {
        x: 2003,
        y: 26,
      },
      {
        x: 2004,
        y: 19,
      },
      {
        x: 2005,
        y: 27,
      },
    ],
  },
  {
    id: "Machine 5",
    name: "Machine 5",
    data: [
      {
        x: 2000,
        y: 28,
      },
      {
        x: 2001,
        y: 12,
      },
      {
        x: 2002,
        y: 20,
      },
      {
        x: 2003,
        y: 19,
      },
      {
        x: 2004,
        y: 13,
      },
      {
        x: 2005,
        y: 10,
      },
    ],
  },
];

export default areaChartDefaultData;
