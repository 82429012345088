import useTheme from "@mui/material/styles/useTheme";
import { Box, Tab, Tabs } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import React from "react";
import { useLanguageContext } from "../../../context/LanguageContext";

interface ExcellenceChartFormWrapperProps {
  alertMessage: string | null;
  handleSubmit: () => void;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
  tabValue: number;
  children: React.ReactNode;
  disabledDataTab: boolean;
  noTabs?: boolean;
}

const ExcellenceChartFormWrapper: React.FC<ExcellenceChartFormWrapperProps> = ({
  alertMessage,
  handleSubmit,
  setTabValue,
  tabValue,
  children,
  disabledDataTab,
  noTabs,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();

  return (
    <Box component="div" css={styles.sectionBreak}>
      {noTabs ? null : (
        <Tabs
          css={styles.sectionBreak}
          value={tabValue}
          onChange={(_, val) => setTabValue(val)}
          aria-label="configure chart tabs"
        >
          <Tab label={t("Configuration")} />
          <Tab label={t("Data")} disabled={disabledDataTab} />
        </Tabs>
      )}

      {children}

      <Alert
        css={[styles.labelBreak, styles.widthLimit25]}
        message={alertMessage}
        showAlert={!!alertMessage}
      />

      <Button onClick={handleSubmit}>{t("Save Changes")}</Button>
    </Box>
  );
};

export default ExcellenceChartFormWrapper;
