import {
  Box,
  IconButton,
  Stack,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { ExcellenceParameter } from "../../../../GraphQL/Excellence/graphQLTypesExcellence";
import { BoxPlotDataSchema, BoxPlotParameter } from "./boxPlotTypes";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";
import DateAndTimePicker from "../../../MaterialUI/DateTimePickers/DateAndTimePicker";
import Autocomplete from "../../../MaterialUI/FormFields/Autocomplete";
import { isValid } from "date-fns";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../../Global/Types/commonTypes";
import { ExcellenceParamMapping } from "../../EditExcellenceChartForms/excellenceChartFormUtils";
import Select from "../../../MaterialUI/FormFields/Select";
import AddIcon from "@mui/icons-material/Add";
import AutocompleteGrouped from "../../../MaterialUI/FormFields/AutocompleteGrouped";
import CloseIcon from "@mui/icons-material/Close";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});
type ParamRow = AutocompleteGroupedOption | null;
const EMPTY_PARAM_ROW: ParamRow = null;

interface EditBoxPlotDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: BoxPlotDataSchema | null;
  handleUpdateDataSchema: (schema: BoxPlotDataSchema | null) => void;
}

const EditBoxPlotDataSchema: React.FC<EditBoxPlotDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // user-selected
  const [dateFrom, setDateFrom] = useState<Date | null>(
    dataSchema?.startTime ? new Date(dataSchema.startTime) : null
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    dataSchema?.endTime ? new Date(dataSchema.endTime) : null
  );
  // options
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [nodeNumbOptions, setNodeNumbOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);
  // selected values
  const [selectedConnections, setSelectedConnections] = useState<AutocompleteOption[]>(
    []
  );
  const [selectedLegend, setSelectedLegend] = useState<string>("");
  const [paramRows, setParamRows] = useState<ParamRow[]>([]);

  // system-used
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const selectedConnectionsSet: Set<string> = new Set();
      const selectedNodesArr: AutocompleteGroupedOption[] = [];

      for (const item of dataSchema.parameters) {
        selectedConnectionsSet.add(item.container);
        selectedNodesArr.push({
          value: item.parameterId,
          description: item.parameterId,
          groupName: item.container,
        });
      }
      const selectedConnectionsArr: SelectOption[] = Array.from(
        selectedConnectionsSet
      ).map((item) => {
        const conn = Object.values(paramMapping).find(
          (param) => param.connection === item
        );
        const connName = conn?.connectionName || item;

        return {
          value: item,
          description: connName,
        };
      });

      const nodeParams: ParamRow[] = dataSchema.parameters.map((item) => ({
        value: item.parameterId,
        groupName: item.container,
        description: paramMapping?.[item.parameterId].paramName || item.parameterId,
      }));

      setSelectedConnections(selectedConnectionsArr);
      setSelectedLegend(dataSchema.legend.parameterId);
      setParamRows(nodeParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);

    const mapping: ExcellenceParamMapping = {};
    parameters.forEach((param) => {
      if (param.id && !mapping?.[param.id]) {
        mapping[param.id] = {
          connection: param.container,
          paramID: param.id,
          paramName: param.name,
          connectionName: param.containerName,
          type: param.type,
        };
      }
    });

    setConnectionOptions(allConnectionOptions);
    setParamMapping(mapping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    if (paramMapping) {
      const paramOptions = handleGetParamOptions(parameters, selectedConnections);
      const numbOnlyOptions = paramOptions.filter(
        (node) =>
          paramMapping?.[node.value]?.type && paramMapping[node.value].type === "number"
      );

      setNodeOptions(paramOptions);
      setNodeNumbOptions(numbOnlyOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnections, paramMapping]);

  useEffect(() => {
    const startTime = dateFrom && isValid(dateFrom) ? dateFrom.toISOString() : null;
    const endTime = dateTo && isValid(dateTo) ? dateTo.toISOString() : null;
    const groupParams: BoxPlotParameter[] = [];

    for (const row of paramRows) {
      if (paramMapping?.[selectedLegend]?.paramID && row?.value) {
        groupParams.push({
          parameterId: row.value,
          container: paramMapping[row.value].connection,
        });
      }
    }

    if (selectedLegend && groupParams.length && paramMapping?.[selectedLegend]?.paramID) {
      const updatedSchema: BoxPlotDataSchema = {
        startTime,
        endTime,
        legend: {
          parameterId: paramMapping[selectedLegend].paramID,
          container: paramMapping[selectedLegend].connection,
        },
        parameters: groupParams,
      };
      handleUpdateDataSchema(updatedSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUpdateSchema]);

  const onDateRangeChange = async (val: Date | null, type: "dateTo" | "dateFrom") => {
    try {
      let fullDataRow: boolean = false;

      if (type === "dateTo") {
        setDateTo(val);
        fullDataRow = !!dateFrom && isValid(dateFrom);
      } else {
        setDateFrom(val);
        fullDataRow = !!dateTo && isValid(dateTo);
      }

      if (val && isValid(val) && fullDataRow) {
        setReUpdateSchema((prev) => !prev);
      }
    } catch (err) {
      console.log("err onDateRangeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleOnConnectionChange = (val: SelectOption[]) => {
    setSelectedConnections(val || []);
    setReUpdateSchema((prev) => !prev);
    if (!paramRows.length) {
      setParamRows([EMPTY_PARAM_ROW]);
    }
  };

  const handleOnLegendChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLegend(e.target.value);
    setReUpdateSchema((prev) => !prev);
    if (!paramRows.length) {
      setParamRows([EMPTY_PARAM_ROW]);
    }
  };

  const handleAddNewParam = () => {
    setParamRows((prev) => [...prev, EMPTY_PARAM_ROW]);
  };

  const handleOnNodeChange = (val: AutocompleteGroupedOption, index: number) => {
    setParamRows((prev) => {
      const result = prev.map((item, itemIndex) => {
        if (itemIndex === index && val?.value) {
          return val;
        }

        return item;
      });

      return result;
    });

    setReUpdateSchema((prev) => !prev);
  };

  const handleRemoveParamRow = (index: number) => {
    setParamRows((prev) => prev.filter((_, rowIndex) => index !== rowIndex));
    setReUpdateSchema((prev) => !prev);
  };

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  if (formStatus === "error") {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("Something went wrong")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date from")}
          value={dateFrom}
          onChange={(val) => onDateRangeChange(val, "dateFrom")}
        />
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date to")}
          value={dateTo}
          onChange={(val) => onDateRangeChange(val, "dateTo")}
        />
      </Stack>

      <Autocomplete
        css={styles.width100}
        label={t("Selected Connections")}
        multiple
        options={connectionOptions}
        value={selectedConnections}
        handleOnChange={handleOnConnectionChange}
        disabled={formStatus === "loading"}
      />

      {selectedConnections.length ? (
        <Stack css={styles.width100} direction="row" spacing={3} alignItems="center">
          <Box component="div" style={{ flex: 1 }}>
            <Select
              fullWidth
              selectOptions={nodeOptions}
              label={t("Legend")}
              value={selectedLegend}
              onChange={handleOnLegendChange}
            />
          </Box>
          <Tooltip title={t("Add New Parameter")}>
            <IconButton css={styles.marginLeftAuto} onClick={handleAddNewParam}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      ) : null}

      {paramRows.length ? (
        <Stack
          css={[styles.width100, styles.overflowYAuto]}
          sx={{ maxHeight: "250px" }}
          gap={3}
        >
          {paramRows.map((item, index) => (
            <Stack
              style={{ width: "99%" }}
              key={`item-index-${index}`}
              direction="row"
              gap={3}
              alignItems="center"
            >
              <AutocompleteGrouped
                css={styles.width100}
                label={`${t("Parameter")} ${index + 1}`}
                options={nodeNumbOptions}
                value={item}
                handleOnChange={(e: AutocompleteGroupedOption) =>
                  handleOnNodeChange(e, index)
                }
                disabled={formStatus === "loading"}
              />
              {index === 0 ? null : (
                <Tooltip title={t("Remove Parameter")}>
                  <IconButton
                    css={styles.marginLeftAuto}
                    onClick={() => handleRemoveParamRow(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default EditBoxPlotDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnectionsArr: SelectOption[]
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        selectedConnectionsArr.some((sub) => sub.value === node.groupName)
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};
