import { Box, Typography } from "@mui/material";
import Select from "../../Components/MaterialUI/FormFields/Select";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import { handleGetSelectOption } from "../../Global/Utils/commonFunctions";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import useTheme from "@mui/material/styles/useTheme";
import { useState } from "react";
import UaConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/UaConnection";
import MqttConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/MqttConnection";
import ModbusConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/ModbusConnection";
import DaConnection from "../../Components/PageComponents/Integrator/CreateOrEditConnection/DaConnection";

// const CONNECTIONS_LIST = [
//   "OPC UA",
//   "OPC DA",
//   "MQTT",
//   "SQL Database",
//   "NoSQL Database",
//   "RestAPI / OData",
//   "SAP R/3",
//   "SAP S4 HANA",
//   "File upload",
//   "Stream (Data, Audio, Video)",
//   "Modbus",
//   "Profibus",
//   "Profinet",
// ];

type SubscriptionType = "OPC UA" | "OPC DA" | "MQTT" | "Modbus";
const CONNECTION_OPTIONS: SubscriptionType[] = ["OPC UA", "OPC DA", "MQTT", "Modbus"];
const CONNECTION_PAGE_URL = "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections";
const TITLE = "Configure Connection";

const CreateConnection: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [selectedType, setSelectedType] = useState<SubscriptionType | "">("");

  const handleStartOver = () => {
    setSelectedType("");
  };

  return (
    <Box component="div">
      <ContentBox>
        {!selectedType ? (
          <Box component="div">
            <Typography css={styles.labelBreak} variant="h4" align="center">
              {TITLE}
            </Typography>
            <Select
              css={[styles.width50, styles.textBreak]}
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value as SubscriptionType)}
              selectOptions={handleGetSelectOption(CONNECTION_OPTIONS)}
              fullWidth
              label="Select connection type"
            />
          </Box>
        ) : null}

        {selectedType === "OPC UA" ? (
          <UaConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={TITLE}
          />
        ) : null}

        {selectedType === "MQTT" ? (
          <MqttConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={TITLE}
          />
        ) : null}

        {selectedType === "Modbus" ? (
          <ModbusConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={TITLE}
          />
        ) : null}
        {selectedType === "OPC DA" ? (
          <DaConnection
            handleStartOver={handleStartOver}
            onSuccessUrl={CONNECTION_PAGE_URL}
            title={TITLE}
          />
        ) : null}
      </ContentBox>
    </Box>
  );
};

export default CreateConnection;
