import {
  Box,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Theme,
  Typography,
} from "@mui/material";
import {
  BasicTableData,
  BasicTableOrder,
  BasicTableRow,
  BasicTableRowDatum,
} from "./basicTableUtils";
import BasicTableHeader from "./BasicTableHeader";
import parseISO from "date-fns/parseISO";
import isValid from "date-fns/isValid";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";
import React, { useState, useMemo, ChangeEvent, isValidElement } from "react";
import { SerializedStyles, css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useNavigate } from "react-router-dom";

const cssStyles = (theme: Theme) => ({
  basicTable: css({
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  }),
  linkRow: css({
    cursor: "pointer",
    "&:hover": {
      background:
        theme.palette.mode === "light"
          ? theme.palette.primary.light
          : theme.palette.grey[900],
    },
  }),
  tableRow: css({
    background:
      theme.palette.mode === "light"
        ? theme.palette.grey[50]
        : theme.palette.customColors.darkBackgroundColor,
  }),
});

interface BasicTableProps<T extends BasicTableRow> {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: BasicTableData<T>;
  dense?: boolean;
  defaultOrderBy: keyof T;
  pageRows?: 10 | 25 | 50;
  defaultOrder?: BasicTableOrder;
  minWidth?: number;
  isLoading?: boolean;
  emptyTableMessage: string;
  handleOnClick?: {
    handleRowLink?: (id?: string) => string;
    handleRowButton?: (id?: string) => void;
  };
  noPagination?: boolean;
}

const BasicTable = <T extends BasicTableRow>({
  className,
  dense,
  data,
  defaultOrderBy,
  pageRows = 25,
  defaultOrder = "asc",
  minWidth = 500,
  isLoading,
  emptyTableMessage,
  handleOnClick,
  noPagination,
}: BasicTableProps<T>) => {
  const { rows, columns } = data;
  const handleRowLink = handleOnClick?.handleRowLink;
  const handleRowButton = handleOnClick?.handleRowButton;
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssLayoutStyles, ...cssSpacingStyles(theme) };

  const [order, setOrder] = useState<BasicTableOrder>(defaultOrder);
  const [orderBy, setOrderBy] = useState<keyof T>(defaultOrderBy);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(pageRows);

  const navigate = useNavigate();

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .sort(getComparator(order, orderBy)),
    [order, orderBy, page, rowsPerPage, rows]
  );

  const keysFromFirstRow: string[] = rows.length ? Object.keys(rows[0]) : [];
  const colKeys: string[] = columns.map((col) => col.id);
  const rowKeys: (keyof T)[] = handleSortedRowKeys(keysFromFirstRow, colKeys);

  const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnRowClick = (id?: string) => {
    if (handleRowLink) {
      navigate({
        pathname: handleRowLink(id),
      });
    } else if (handleRowButton) {
      handleRowButton(id);
    }
  };

  return (
    <Box component="div" css={[styles.width100, styles.basicTable]} className={className}>
      <Paper css={styles.width100}>
        <TableContainer>
          <Table sx={{ minWidth: minWidth }} size={dense ? "small" : "medium"}>
            <BasicTableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              columns={columns}
            />

            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography variant="body1" align="center">
                      Loading...
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : !rows.length ? (
                <TableRow>
                  <TableCell colSpan={columns.length}>
                    <Typography variant="body1" align="center">
                      {emptyTableMessage}
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {visibleRows.map((row, index) => {
                    return (
                      <TableRow
                        css={[
                          handleOnClick ? styles.linkRow : undefined,
                          styles.tableRow,
                        ]}
                        tabIndex={-1}
                        key={`${row.name}-${index}`}
                        onClick={
                          handleOnClick
                            ? () => handleOnRowClick(row?.id ? row.id.toString() : "")
                            : undefined
                        }
                      >
                        {rowKeys.map((rowKey, keyIndex) =>
                          rowKey === "id" ? null : (
                            <TableCell key={`${rowKey.toString()}-${keyIndex}`}>
                              <Typography variant="body1">
                                {handleRowDatum(row[rowKey])}
                              </Typography>
                            </TableCell>
                          )
                        )}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={columns.length} />
                    </TableRow>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {noPagination ? null : (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Box>
  );
};

export default BasicTable;

const handleRowDatum = (
  datum: BasicTableRowDatum
): string | number | React.ReactElement => {
  try {
    // if react element -> return it
    if (isValidElement(datum)) {
      return datum;
    }

    // if number -> return it
    if (typeof datum === "number" || !isNaN(+datum)) {
      return +datum;
    }

    // Regular expression to validate ISO date format
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
    const stringDatum = datum.toString();

    // Check if the string is a valid ISO date
    if (isoDatePattern.test(stringDatum) && isValid(parseISO(stringDatum))) {
      return formatDateAndTime(stringDatum, "date");
    }

    throw new Error("Not a valid date");
  } catch (err) {
    // it's a string -> return it
    return datum.toString();
  }
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<RowType, Key extends keyof RowType>(
  order: BasicTableOrder,
  orderBy: Key
): (a: RowType, b: RowType) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const handleSortedRowKeys = (keysFromRow: string[], colKeys: string[]): string[] => {
  const indexMap: { [key: string]: number } = {};

  // Create a map of indices for elements in colKeys
  colKeys.forEach((element, index) => {
    indexMap[element] = index;
  });

  // Sort keysFromRow based on the order in secondArr
  keysFromRow.sort((a, b) => {
    const indexA = indexMap[a];
    const indexB = indexMap[b];

    if (indexA !== undefined && indexB !== undefined) {
      return indexA - indexB;
    } else if (indexA !== undefined) {
      return -1;
    } else if (indexB !== undefined) {
      return 1;
    } else {
      return 0;
    }
  });

  return keysFromRow;
};
