import useTheme from "@mui/material/styles/useTheme";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import HeightIcon from "@mui/icons-material/Height";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import FilterListIcon from "@mui/icons-material/FilterList";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import {
  TableGridDateTimeFilterModeOptions,
  TableGridDateTimeFilterValue,
  TableGridDateType,
} from "./tableGridUtils";
import TimePicker from "../../MaterialUI/DateTimePickers/TimePicker";
import { MRT_Header } from "material-react-table";

export const TABLE_GRID_DEFAULT_DATE_FILTER_VALUE: TableGridDateTimeFilterValue = {
  filterMode: "Before",
  firstDate: null,
  secondDate: null,
};

interface TableGridDateTimeFilterProps<T extends Record<string, any>> {
  header: MRT_Header<T>;
  type: TableGridDateType;
}

const TableGridDateTimeFilter = <T extends Record<string, any>>({
  header,
  type,
}: TableGridDateTimeFilterProps<T>) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [filterValue, setFilterValue] = useState<TableGridDateTimeFilterValue>(
    (header.column.getFilterValue() as TableGridDateTimeFilterValue) ||
      TABLE_GRID_DEFAULT_DATE_FILTER_VALUE
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterOption, setFilterOption] =
    useState<TableGridDateTimeFilterModeOptions>("Before");
  const open = Boolean(anchorEl);

  useEffect(() => {
    const updatedFilterValue = {
      ...filterValue,
      filterMode: filterOption,
    };
    setFilterValue(updatedFilterValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOption]);

  const handleChangeFilter = (option: TableGridDateTimeFilterModeOptions) => {
    setFilterOption(option);
    setAnchorEl(null);
  };

  return (
    <Box component="div">
      <Stack spacing={1.5} direction="row" alignItems="center">
        <Tooltip title="Change filter mode" arrow>
          <IconButton
            size="small"
            sx={{ padding: 0 }}
            onClick={(e) => setAnchorEl(e.currentTarget)}
            aria-label="open filter menu"
          >
            <FilterListIcon sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
          </IconButton>
        </Tooltip>

        <PickerType
          onChange={(e) =>
            setFilterValue({
              ...filterValue,
              firstDate: e,
              secondDate: null,
            })
          }
          value={filterValue.firstDate}
          type={type}
        />
        {filterOption === "Between dates" ? (
          <PickerType
            onChange={(e) =>
              setFilterValue({
                ...filterValue,
                secondDate: e,
              })
            }
            value={filterValue.secondDate}
            minDate={filterValue.firstDate || undefined}
            type={type}
          />
        ) : null}

        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <MenuItem
            css={[styles.widthLimit15, styles.width100]}
            onClick={() => handleChangeFilter("Before")}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <ListItemIcon>
                <VerticalAlignBottomIcon
                  sx={{ transform: "rotate(90deg)" }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText>Before</ListItemText>
            </Stack>
          </MenuItem>
          <MenuItem
            css={[styles.widthLimit15, styles.width100]}
            onClick={() => handleChangeFilter("After")}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <ListItemIcon>
                <VerticalAlignBottomIcon
                  sx={{ transform: "rotate(-90deg)" }}
                  fontSize="small"
                />
              </ListItemIcon>
              <ListItemText>After</ListItemText>
            </Stack>
          </MenuItem>
          <MenuItem
            css={[styles.widthLimit15, styles.width100]}
            onClick={() => handleChangeFilter("Between dates")}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <ListItemIcon>
                <HeightIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
              </ListItemIcon>
              <ListItemText>Between dates</ListItemText>
            </Stack>
          </MenuItem>
        </Menu>
      </Stack>
      <Typography css={styles.leftSpacer05} variant="caption" color="textSecondary">
        Filter Mode: {filterOption}
      </Typography>
    </Box>
  );
};

export default TableGridDateTimeFilter;

interface PickerTypeProps {
  type: TableGridDateType;
  value: Date | null;
  onChange: (value: Date | null) => void;
  minDate?: Date | undefined;
}

const PickerType: React.FC<PickerTypeProps> = ({ type, value, onChange, minDate }) => {
  switch (type) {
    case "date": {
      return <DatePicker onChange={onChange} value={value} minDate={minDate} />;
    }
    case "dateTime": {
      return null;
    }
    case "time": {
      return <TimePicker onChange={onChange} value={value} minTime={minDate} />;
    }
    default: {
      return null;
    }
  }
};
