import { SerializedStyles } from "@emotion/react";
import Network from "../../../../ExcellenceWidgets/AdvancedWidgets/Network/Network";
import { NetworkData } from "../../../../ExcellenceWidgets/AdvancedWidgets/Network/networkTypes";
import networkDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/networkDefaultData";

interface ExcellenceNetworkProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: NetworkData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceNetwork: React.FC<ExcellenceNetworkProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <Network
      className={className}
      configuration={configuration}
      data={data || networkDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceNetwork;
