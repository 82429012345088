import jwtDecode from "jwt-decode";
import { postQueryTokenRefresh } from "../Api/Auth/apiAuthPostQueries";
import {
  GetQueryUserGetProfilePictureSnippet,
  PostQueryTokenRefreshSnippet,
} from "../Api/Auth/apiAuthSnippets";
import callApi from "../Api/callApi";
import {
  COOKIE_ACCESS_TOKEN,
  COOKIE_REFRESH_TOKEN,
} from "../Global/Constants/commonConstants";
import {
  SetCookieParams,
  deleteCookie,
  setCookie,
} from "../Global/Utils/commonFunctions";
import {
  AlertData,
  AuthedUser,
  DecodedJWTToken,
  ProfilePicture,
} from "./authContextTypes";
import { getQueryUserGetProfilePicture } from "../Api/Auth/apiAuthGetQueries";
import { Alert, GetQueryAlertsSnippet } from "../Api/Rules/apiRulesSnippets";
import { getQueryAlerts } from "../Api/Rules/apiRulesGetQueries";

// signs out the user
export const handleUserSignOut = () => {
  deleteCookie(COOKIE_ACCESS_TOKEN);
  deleteCookie(COOKIE_REFRESH_TOKEN);
  window.location.reload();
};

// if refreshToken, fetch and save the accessToken
export const handleFetchUserAccessToken = async (refreshToken: string | null) => {
  if (refreshToken) {
    const accessCookieResponse = await callApi<PostQueryTokenRefreshSnippet>({
      query: postQueryTokenRefresh(),
      auth: null,
    });
    const accessToken = accessCookieResponse.response.access_token;
    const decodedToken: DecodedJWTToken = jwtDecode(accessToken);
    const cookie: SetCookieParams = {
      name: COOKIE_ACCESS_TOKEN,
      value: accessToken,
      exp: decodedToken.exp,
      sameSite: "strict",
      secure: true,
    };
    setCookie(cookie);
    return accessToken;
  }
};

export const fetchProfilePic = async (
  setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>,
  setProfilePicture: (value: React.SetStateAction<ProfilePicture>) => void
) => {
  try {
    const result = await callApi<GetQueryUserGetProfilePictureSnippet>({
      query: getQueryUserGetProfilePicture(),
      auth: { setAuthedUser },
    });
    setProfilePicture(result);
  } catch (err) {}
};

export const fetchAlerts = async (
  setAlertsData: (value: React.SetStateAction<AlertData>) => void,
  setAuthedUser: React.Dispatch<React.SetStateAction<AuthedUser | null>>
) => {
  try {
    const alertsData = await callApi<GetQueryAlertsSnippet>({
      query: getQueryAlerts,
      auth: { setAuthedUser },
    });

    const filteredAlertsData = {
      read: [] as Alert[],
      unread: [] as Alert[],
    };

    alertsData.reverse().forEach((alert) => {
      if (alert.status === "unread") {
        filteredAlertsData.unread.push(alert);
      } else {
        filteredAlertsData.read.push(alert);
      }
    });

    setAlertsData(filteredAlertsData);
  } catch (err) {
    console.error(err);
  }
};
