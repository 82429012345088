import { LineAreaHeatMapScatterPlotData } from "../../EditExcellenceChartForms/excellenceChartFormUtils";

const heatMapDefaultData: LineAreaHeatMapScatterPlotData = [
  {
    id: "Machine 1",
    name: "Machine 1",
    data: [
      {
        x: "08:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
  {
    id: "Machine 2",
    name: "Machine 2",
    data: [
      {
        x: "08:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
  {
    id: "Machine 3",
    name: "Machine 3",
    data: [
      {
        x: "08:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
  {
    id: "Machine 4",
    name: "Machine 4",
    data: [
      {
        x: "08:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
  {
    id: "Machine 5",
    name: "Machine 5",
    data: [
      {
        x: "08:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
  {
    id: "Machine 6",
    name: "Machine 6",
    data: [
      {
        x: "08:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
  {
    id: "Machine 7",
    name: "Machine 7",
    data: [
      {
        x: "08:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
  {
    id: "Machine 8",
    name: "Machine 8",
    data: [
      {
        x: "08:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "09:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "10:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "11:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "12:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "13:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "14:00",
        y: -Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "15:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
      {
        x: "16:00",
        y: Math.floor(Math.random() * 200) - 100,
      },
    ],
  },
];

export default heatMapDefaultData;
