import { Box, Theme, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SerializedStyles, css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../Global/Styles/layout";
import {
  AUTH_LAYOUT_BACKGROUND_COLOR,
  AUTH_LAYOUT_PADDING,
  LEFT_NAV_WIDTH,
  TOP_NAV_SPACING_WITH_SITE_CONTENT,
  AUTH_LAYOUT_DARK_BACKGROUND_COLOR,
} from "./layoutVariables";
import TopNavigation from "./AppNavigation/TopNavigation";
import LeftNavigation from "./AppNavigation/LeftNavigation";
import { ErrorBoundary } from "react-error-boundary";
import RouteErrorBoundary from "./RouteErrorBoundary";
import { Outlet, useLocation } from "react-router-dom";
import NavigationBlocker from "./NavigationBlocker";
import RightNavigation from "./AppNavigation/RightNavigation";

const cssStyles = (theme: Theme, leftNavIsOpen: boolean, mobileLeftNav: boolean) => ({
  contentContainer: css({
    marginTop: TOP_NAV_SPACING_WITH_SITE_CONTENT,
    flexGrow: 1,
    position: "relative",
    padding: AUTH_LAYOUT_PADDING,
    backgroundColor:
      theme.palette.mode === "light"
        ? AUTH_LAYOUT_BACKGROUND_COLOR
        : AUTH_LAYOUT_DARK_BACKGROUND_COLOR,
    minHeight: `calc(100vh - ${TOP_NAV_SPACING_WITH_SITE_CONTENT})`,

    // if mobile view -> don't have transition
    ...(!mobileLeftNav && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: 0,
      ...(leftNavIsOpen && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: LEFT_NAV_WIDTH,
      }),
    }),
  }),
});

interface AuthLayoutProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ className }) => {
  const theme = useTheme();
  const lgMediaQuery = useMediaQuery("(max-width:1199px)");
  const [openLeftNav, setOpenLeftNav] = useState<boolean>(!lgMediaQuery);
  const [openRightNav, setOpenRightNav] = useState<boolean>(false);
  const [extraTopNavMenu, setExtraTopNavMenu] = useState<React.ReactNode | null>(null);
  const styles = {
    ...cssStyles(theme, openLeftNav, lgMediaQuery),
    ...cssLayoutStyles,
  };
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const location = useLocation();
  const smMediaQuery = useMediaQuery("(max-width:599px)");

  useEffect(() => {
    if (lgMediaQuery && openLeftNav) {
      setOpenLeftNav(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, lgMediaQuery]);

  const handleHelpClick = () => {
    setOpenRightNav((state) => !state);
  };

  return (
    <Box component="div" className={className} css={styles.flexColumn}>
      <NavigationBlocker
        message="You have unsaved changes which will be lost if you leave the page"
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
      <TopNavigation
        setOpenLeftNav={setOpenLeftNav}
        extraMenu={extraTopNavMenu}
        handleHelpClick={handleHelpClick}
      />
      <LeftNavigation
        openLeftNav={openLeftNav}
        setOpenLeftNav={setOpenLeftNav}
        mobileLeftNav={lgMediaQuery}
      />
      <RightNavigation openRightNav={openRightNav} setOpenRightNav={setOpenRightNav} />
      <Box css={styles.contentContainer} component="main">
        <ErrorBoundary FallbackComponent={RouteErrorBoundary}>
          <Outlet
            context={{
              openLeftNav,
              setExtraTopNavMenu,
              smMediaQuery,
              unsavedChanges,
              setUnsavedChanges,
            }}
          />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default AuthLayout;
