import { SerializedStyles } from "@emotion/react";
import { SwarmPlotData } from "../../../../ExcellenceWidgets/AdvancedWidgets/SwarmPlot/swarmPlotTypes";
import SwarmPlot from "../../../../ExcellenceWidgets/AdvancedWidgets/SwarmPlot/SwarmPlot";
import swarmPlotDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/swarmPlotDefaultData";

interface ExcellenceSwarmPlotProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: SwarmPlotData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceSwarmPlot: React.FC<ExcellenceSwarmPlotProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <SwarmPlot
      className={className}
      configuration={configuration}
      data={data || swarmPlotDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceSwarmPlot;
