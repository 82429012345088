import { Query } from "../callApi";

export const getQueryAssetsFunctionalLocations: Query = {
  endpoint: "assets/functional_locations",
  method: "GET",
};

export const getQueryOEEMainCategories: Query = {
  endpoint: "oee/maincategories",
  method: "GET",
};

export const getQueryOEEMappedCategoriesWithSubcategories: Query = {
  endpoint: "oee/categories",
  method: "GET",
};

export const getQueryOEEAddedCategoriesWithSubcategories: Query = {
  endpoint: "oee/assets/categories",
  method: "GET",
};

export const getQueryOEELosses: Query = {
  endpoint: "oee/losses",
  method: "GET",
};

export const getQueryMappedAssetToCategory = (assetId: string): Query => ({
  endpoint: `oee/asset/${assetId}/categories`,
  method: "GET",
});

export const getQueryAssetCycleRateData = (assetId: string): Query => ({
  endpoint: `oee/asset/${assetId}/cycle_rate`,
  method: "GET",
});
