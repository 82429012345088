import {
  ExcellenceDataSchemaForm,
  GaugeWidgetData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";

interface EditGaugeWidgetDataSchemaProps extends ExcellenceDataSchemaForm {
  setUpdatedData: React.Dispatch<React.SetStateAction<GaugeWidgetData>>;
}

const EditGaugeWidgetDataSchema: React.FC<EditGaugeWidgetDataSchemaProps> = () => {
  return null;
};

export default EditGaugeWidgetDataSchema;
