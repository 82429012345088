import { Grid, Stack } from "@mui/material";
import Select from "../../MaterialUI/FormFields/Select";
import {
  AutocompleteGroupedOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { useEffect, useState } from "react";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import cssLayoutStyles from "../../../Global/Styles/layout";
import Collapse from "../../MaterialUI/Collapse";
import {
  TimeChartConfiguration,
  TimeChartThreshold,
  TimeChartThresholdMode,
  TimeChartThresholdValues,
} from "./timeChartTypes";
import TextField from "../../MaterialUI/FormFields/TextFields";
import {
  ChartConfigFormValuesWithTitle,
  ExcellenceDataSchemaForm,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  ExcellenceChartDataSchema,
  ExcellenceChartDataSchemaParameter,
} from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import LabelWithBoldedPart from "../../MaterialUI/LabelWithBoldedPart";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import { useLanguageContext } from "../../../context/LanguageContext";

const INITIAL_THRESHOLD: TimeChartThreshold = {
  mode: "manualInput",
  values: {
    lowLowThreshold: {
      value: "",
    },
    lowThreshold: {
      value: "",
    },
    highThreshold: {
      value: "",
    },
    highHighThreshold: {
      value: "",
    },
  },
};

type HandleFetchDataInput = {
  input: ExcellenceChartDataSchema;
};

interface EditTimeChartThresholdsProps extends ExcellenceDataSchemaForm {
  config: ChartConfigFormValuesWithTitle<TimeChartConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<TimeChartConfiguration>>
  >;
  handleFetchData: (input: HandleFetchDataInput, noDataUpdate?: boolean) => Promise<any>;
  nodeBoundaryOptions: AutocompleteGroupedOption[];
  nodeDynamicThresholdOptions: AutocompleteGroupedOption[];
  selectedNodes: AutocompleteGroupedOption[];
  updatedSchema: ExcellenceChartDataSchema | null;
}

const EditTimeChartThresholds: React.FC<EditTimeChartThresholdsProps> = ({
  config,
  setUpdatedConfig,
  nodeBoundaryOptions,
  handleFetchData,
  nodeDynamicThresholdOptions,
  selectedNodes,
  updatedSchema,
}) => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const { t } = useLanguageContext();
  const [configureThreshold, setConfigureThreshold] = useState<boolean>(
    !!config.threshold?.mode
  );
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [minMaxThresholdNode, setMinMaxThresholdNode] =
    useState<AutocompleteGroupedOption | null>(
      config.threshold?.minMaxThresholdNode
        ? {
            value: config.threshold?.minMaxThresholdNode.node,
            groupName: config.threshold?.minMaxThresholdNode.container,
            description: config.threshold?.minMaxThresholdNode.node,
          }
        : null
    );
  const [dynamicDisabledOptions, setDynamicDisabledOptions] = useState<string[]>(() =>
    getDynamicDisabledOptions(
      nodeDynamicThresholdOptions,
      selectedNodes,
      config.threshold
    )
  );

  const thresholdOptions: SelectOption[] = [
    { description: t("Input data manually"), value: "manualInput" },
    { description: t("Get values from nodes"), value: "valuesFromNodes" },
    { description: t("Use a node's boundary values"), value: "nodeMinMax" },
  ];

  useEffect(() => {
    setDynamicDisabledOptions(() =>
      getDynamicDisabledOptions(
        nodeDynamicThresholdOptions,
        selectedNodes,
        config.threshold
      )
    );
  }, [nodeDynamicThresholdOptions, selectedNodes, config.threshold]);

  const handleConfigureThresholdChange = (val: boolean) => {
    if (!val) {
      setUpdatedConfig((prev) => ({ ...prev, threshold: undefined }));
    }
    setConfigureThreshold(val);
  };

  const handleUpdateMode = (value: TimeChartThresholdMode) => {
    setUpdatedConfig((prev) => {
      const threshold: TimeChartThreshold = prev.threshold || INITIAL_THRESHOLD;
      return {
        ...prev,
        threshold: {
          ...threshold,
          mode: value,
          values: INITIAL_THRESHOLD.values,
        },
      };
    });
  };

  const handleUpdateManualValues = (
    field: keyof TimeChartThresholdValues,
    value: number | string
  ) => {
    setUpdatedConfig((prev) => {
      const threshold: TimeChartThreshold = prev.threshold || INITIAL_THRESHOLD;
      return {
        ...prev,
        threshold: {
          ...threshold,
          values: {
            ...threshold.values,
            [field]: {
              value: value,
            },
          },
        },
      };
    });
  };

  const handleUpdateValuesFromNode = async (
    field: keyof TimeChartThresholdValues,
    node: AutocompleteGroupedOption | null
  ) => {
    try {
      setFormStatus("loading");
      if (node) {
        const newNodeParams: ExcellenceChartDataSchemaParameter = {
          container: node.groupName,
          parameterId: node.value,
        };
        const existingParams: ExcellenceChartDataSchemaParameter[] = updatedSchema
          ?.parameters.length
          ? updatedSchema.parameters
          : [];

        const input: ExcellenceChartDataSchema = {
          parameters: [...existingParams, newNodeParams],
          startTime: updatedSchema?.startTime || null,
          endTime: updatedSchema?.endTime || null,
        };

        await handleFetchData({ input });

        setUpdatedConfig((prev) => {
          const threshold: TimeChartThreshold = prev.threshold || INITIAL_THRESHOLD;
          return {
            ...prev,
            threshold: {
              ...threshold,
              values: {
                ...threshold.values,
                [field]: {
                  value: "",
                  node: node.value,
                  container: node.groupName,
                },
              },
            },
          };
        });
      } else {
        // node has been de-selected
        const existingParams: ExcellenceChartDataSchemaParameter[] = updatedSchema
          ?.parameters.length
          ? updatedSchema.parameters
          : [];
        const removedNode = config?.threshold?.values[field]?.node;
        const paramsToUse = existingParams.filter(
          (param) => param.parameterId !== removedNode
        );

        const input: ExcellenceChartDataSchema = {
          parameters: paramsToUse,
          startTime: updatedSchema?.startTime || null,
          endTime: updatedSchema?.endTime || null,
        };
        await handleFetchData({ input });

        setUpdatedConfig((prev) => {
          const threshold: TimeChartThreshold = prev.threshold || INITIAL_THRESHOLD;
          return {
            ...prev,
            threshold: {
              ...threshold,
              values: {
                ...threshold.values,
                [field]: null,
              },
            },
          };
        });
      }
      setFormStatus("success");
    } catch (err) {
      console.log("err handleUpdateValuesFromNode() ", err);
      setFormStatus("error");
    }
  };

  const handleUpdateMinMaxFromNode = async (node: AutocompleteGroupedOption | null) => {
    if (node) {
      try {
        setFormStatus("loading");
        const params: ExcellenceChartDataSchemaParameter[] = [
          { container: node.groupName, parameterId: node.value },
        ];
        const input: ExcellenceChartDataSchema = {
          parameters: params,
          startTime: null,
          endTime: null,
        };

        const result = await handleFetchData({ input }, true);

        if (!result) {
          throw new Error("timeSeries data not found");
        }

        const thresholdValue = result?.[0]?.data?.[0];
        const lowWarningLimit = thresholdValue?.lowWarningLimit || "";
        const lowAlarmLimit = thresholdValue?.lowAlarmLimit || "";
        const highWarningLimit = thresholdValue?.highWarningLimit || "";
        const highAlarmLimit = thresholdValue?.highAlarmLimit || "";

        const updatedValues: TimeChartThresholdValues = {
          lowLowThreshold: {
            value: lowAlarmLimit,
          },
          lowThreshold: {
            value: lowWarningLimit,
          },
          highThreshold: {
            value: highWarningLimit,
          },
          highHighThreshold: {
            value: highAlarmLimit,
          },
        };

        setUpdatedConfig((prev) => {
          const threshold: TimeChartThreshold = prev.threshold || INITIAL_THRESHOLD;
          return {
            ...prev,
            threshold: {
              ...threshold,
              minMaxThresholdNode: {
                container: node.groupName,
                node: node.value,
              },
              values: {
                ...threshold.values,
                ...updatedValues,
              },
            },
          };
        });

        setMinMaxThresholdNode(node);
        setFormStatus("success");
      } catch (err) {
        console.log("err handleUpdateMinMaxFromNode() ", err);
        setFormStatus("error");
      }
    }
  };

  return (
    <Stack css={styles.sectionBreak} spacing={3}>
      <Stack spacing={2} direction="row" alignItems="center">
        <Checkbox
          css={styles.width100}
          label={t("Configure threshold values")}
          onChange={(e) => handleConfigureThresholdChange(e.target.checked)}
          checked={configureThreshold}
        />
        <Collapse css={styles.width100} in={configureThreshold}>
          <Select
            css={styles.width100}
            label={t("Threshold mode")}
            selectOptions={thresholdOptions}
            value={config?.threshold?.mode || ""}
            onChange={(e) => handleUpdateMode(e.target.value as TimeChartThresholdMode)}
          />
        </Collapse>
      </Stack>

      <Collapse css={styles.width100} in={!!config?.threshold?.mode}>
        {config?.threshold?.mode === "manualInput" ? (
          <ManualValues
            thresholdValues={config?.threshold?.values || INITIAL_THRESHOLD.values}
            handleUpdateValues={handleUpdateManualValues}
            t={t}
          />
        ) : null}
        {config?.threshold?.mode === "valuesFromNodes" ? (
          <ValuesFromNode
            nodeOptions={nodeDynamicThresholdOptions}
            thresholdValues={config?.threshold?.values || INITIAL_THRESHOLD.values}
            handleOnNodeChange={handleUpdateValuesFromNode}
            disabled={formStatus === "loading"}
            disabledOptions={dynamicDisabledOptions}
            t={t}
          />
        ) : null}
        {config?.threshold?.mode === "nodeMinMax" ? (
          <MinMaxValuesFromNode
            nodeOptions={nodeBoundaryOptions}
            thresholdValues={config?.threshold?.values || INITIAL_THRESHOLD.values}
            disabled={formStatus === "loading"}
            handleOnChange={handleUpdateMinMaxFromNode}
            minMaxThresholdNode={minMaxThresholdNode}
            t={t}
          />
        ) : null}
      </Collapse>

      <LoadingBackdrop loading={formStatus === "loading"} />
    </Stack>
  );
};

export default EditTimeChartThresholds;

interface ManualThresholdValues {
  thresholdValues: TimeChartThresholdValues;
  handleUpdateValues: (
    field: keyof TimeChartThresholdValues,
    value: number | string
  ) => void;
  t: (key: string) => string;
}

const ManualValues: React.FC<ManualThresholdValues> = ({
  thresholdValues,
  handleUpdateValues,
  t,
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label={t("Low-low threshold")}
          numberField
          allowNegatives
          value={thresholdValues.lowLowThreshold?.value || ""}
          onChange={(e) => handleUpdateValues("lowLowThreshold", e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t("Low threshold")}
          numberField
          allowNegatives
          value={thresholdValues.lowThreshold?.value || ""}
          onChange={(e) => handleUpdateValues("lowThreshold", e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t("High threshold")}
          numberField
          allowNegatives
          value={thresholdValues.highThreshold?.value || ""}
          onChange={(e) => handleUpdateValues("highThreshold", e.target.value)}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={t("High-high threshold")}
          numberField
          allowNegatives
          value={thresholdValues.highHighThreshold?.value || ""}
          onChange={(e) => handleUpdateValues("highHighThreshold", e.target.value)}
        />
      </Grid>
    </Grid>
  );
};

interface ValuesFromNodeProps {
  thresholdValues: TimeChartThresholdValues;
  handleOnNodeChange: (
    field: keyof TimeChartThresholdValues,
    node: AutocompleteGroupedOption | null
  ) => Promise<void>;
  nodeOptions: AutocompleteGroupedOption[];
  disabled: boolean;
  disabledOptions: string[];
  t: (key: string) => string;
}

const ValuesFromNode: React.FC<ValuesFromNodeProps> = ({
  thresholdValues,
  handleOnNodeChange,
  nodeOptions,
  disabled,
  disabledOptions,
  t,
}) => {
  const getDisabled = (field: keyof TimeChartThresholdValues) =>
    disabledOptions.filter((nodeID) => {
      const foundNode = handleGetThresholdNode(
        nodeOptions,
        thresholdValues?.[field]?.node
      );
      return nodeID !== foundNode?.value;
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <AutocompleteGrouped
          label={t("Low-low threshold")}
          options={nodeOptions}
          handleOnChange={(val: AutocompleteGroupedOption) =>
            handleOnNodeChange("lowLowThreshold", val)
          }
          value={handleGetThresholdNode(
            nodeOptions,
            thresholdValues.lowLowThreshold?.node
          )}
          disabled={disabled}
          disabledOptions={getDisabled("lowLowThreshold")}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteGrouped
          label={t("Low threshold")}
          options={nodeOptions}
          handleOnChange={(val: AutocompleteGroupedOption) =>
            handleOnNodeChange("lowThreshold", val)
          }
          value={handleGetThresholdNode(nodeOptions, thresholdValues.lowThreshold?.node)}
          disabled={disabled}
          disabledOptions={getDisabled("lowThreshold")}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteGrouped
          label={t("High threshold")}
          options={nodeOptions}
          handleOnChange={(val: AutocompleteGroupedOption) =>
            handleOnNodeChange("highThreshold", val)
          }
          value={handleGetThresholdNode(nodeOptions, thresholdValues.highThreshold?.node)}
          disabled={disabled}
          disabledOptions={getDisabled("highThreshold")}
        />
      </Grid>
      <Grid item xs={6}>
        <AutocompleteGrouped
          label={t("High-high threshold")}
          options={nodeOptions}
          handleOnChange={(val: AutocompleteGroupedOption) =>
            handleOnNodeChange("highHighThreshold", val)
          }
          value={handleGetThresholdNode(
            nodeOptions,
            thresholdValues.highHighThreshold?.node
          )}
          disabled={disabled}
          disabledOptions={getDisabled("highHighThreshold")}
        />
      </Grid>
    </Grid>
  );
};

const handleGetThresholdNode = (
  allNodeOptions: AutocompleteGroupedOption[],
  val: string | number | null | undefined
): AutocompleteGroupedOption | null => {
  const foundNode = allNodeOptions.find((node) => node.value === val);

  if (foundNode) {
    return {
      groupName: foundNode.groupName,
      value: foundNode.value,
      description: foundNode.description,
    };
  }

  return null;
};

interface MinMaxValuesFromNodeProps {
  handleOnChange: (val: AutocompleteGroupedOption) => any;
  nodeOptions: AutocompleteGroupedOption[];
  minMaxThresholdNode: AutocompleteGroupedOption | null;
  disabled: boolean;
  thresholdValues: TimeChartThresholdValues;
  t: (key: string) => string;
}

const MinMaxValuesFromNode: React.FC<MinMaxValuesFromNodeProps> = ({
  nodeOptions,
  handleOnChange,
  minMaxThresholdNode,
  disabled,
  thresholdValues,
  t,
}) => {
  const boldedValue = minMaxThresholdNode?.value ? true : false;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <AutocompleteGrouped
          label={t("Threshold node")}
          options={nodeOptions}
          handleOnChange={handleOnChange}
          value={minMaxThresholdNode}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelWithBoldedPart
          text={t("Low-low threshold")}
          bolded={boldedValue ? thresholdValues.lowLowThreshold?.value || "N/A" : ""}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelWithBoldedPart
          text={t("Low threshold")}
          bolded={boldedValue ? thresholdValues.lowThreshold?.value || "N/A" : ""}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelWithBoldedPart
          text={t("High threshold")}
          bolded={boldedValue ? thresholdValues.highThreshold?.value || "N/A" : ""}
        />
      </Grid>
      <Grid item xs={6}>
        <LabelWithBoldedPart
          text={t("High-high threshold")}
          bolded={boldedValue ? thresholdValues.highHighThreshold?.value || "N/A" : ""}
        />
      </Grid>
    </Grid>
  );
};

const getDynamicDisabledOptions = (
  nodeDynamicThresholdOptions: AutocompleteGroupedOption[],
  selectedNodes: AutocompleteGroupedOption[],
  threshold: TimeChartThreshold | undefined
): string[] => {
  const selectedDynamicValues: string[] = [];
  Object.values(threshold?.values || {})?.forEach((value) => {
    if (value?.node) {
      selectedDynamicValues.push(value.node);
    }
  });

  const result: string[] = [];

  nodeDynamicThresholdOptions.forEach((node) => {
    // node is selected for data tracking
    const firstCheck = selectedNodes.some(
      (selectedNode) => selectedNode.value === node.value
    );
    // node is selected as another dynamic threshold value
    const secondCheck = selectedDynamicValues.includes(node.value);

    if (firstCheck || secondCheck) {
      result.push(node.value);
    }
  });

  return result;
};
