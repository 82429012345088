import {
  BarColumnRadarData,
  ExcellenceChartProps,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { graphQlQueryExcellenceBarData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Alert from "../../../../MaterialUI/Alert";
import { useTheme } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import BarChart from "../../../../ExcellenceWidgets/BarChart/BarChart";
import {
  BarChartConfiguration,
  BarChartDataSchema,
} from "../../../../ExcellenceWidgets/BarChart/barChartTypes";
import barChartDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/barChartDefaultData";
import { useLanguageContext } from "../../../../../context/LanguageContext";

interface ExcellenceBarChartProps extends ExcellenceChartProps {
  data: BarColumnRadarData | null;
  configuration: BarChartConfiguration;
  schema: BarChartDataSchema | null;
}

const ExcellenceBarChart: React.FC<ExcellenceBarChartProps> = ({
  className,
  data,
  configuration,
  isStatic,
  schema,
  resetChartFetch,
  dataFetchFromParent,
  parameters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [updatedData, setUpdatedData] = useState<BarColumnRadarData | null>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [chartToUse, setChartToUse] = useState<boolean>(true);

  const [getBarData, { loading: loadingData }] = useLazyQuery(
    graphQlQueryExcellenceBarData
  );

  const paramMapping: Record<string, string> | undefined = parameters?.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.id]: curr.name,
      };
    },
    {}
  );

  useEffect(() => {
    setChartToUse((prev) => !prev);
  }, [updatedData]);

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent && schema) {
        try {
          setFetchStatus("loading");
          const result = await getBarData({
            variables: {
              input: schema,
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.barchartData.chartData;

          setUpdatedData(resultData);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchFromParent, resetChartFetch]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  return (
    <>
      {chartToUse ? (
        <BarChart
          className={className}
          configuration={configuration}
          data={schema && updatedData ? updatedData : barChartDefaultData}
          isStatic={isStatic}
          indexBy={schema ? "group" : undefined}
          noAnimation
          schema={schema}
          paramMapping={paramMapping}
        />
      ) : null}

      {!chartToUse ? (
        <BarChart
          className={className}
          configuration={configuration}
          data={schema && updatedData ? updatedData : barChartDefaultData}
          isStatic={isStatic}
          indexBy={schema ? "group" : undefined}
          noAnimation
          schema={schema}
          paramMapping={paramMapping}
        />
      ) : null}

      {isStatic ? null : <LoadingBackdrop loading={loadingData} />}

      <Alert
        css={styles.reverseContentBreak}
        message={t("Something went wrong")}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </>
  );
};

export default ExcellenceBarChart;
