import { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import FileDropzone from "../../SmallComponents/Dropzone/FileDropzone";
import Button from "../../MaterialUI/Button";
import LabelWithBoldedPart from "../../MaterialUI/LabelWithBoldedPart";
import Alert from "../../MaterialUI/Alert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoIcon from "@mui/icons-material/Photo";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileWithPath } from "react-dropzone";
import callApi from "../../../Api/callApi";
import {
  GetQueryMaintenanceFilesSnippet,
  PostQueryDeleteFileSnippet,
  PostQueryUploadFileSnippet,
} from "../../../Api/Maintenance/apiMaintenanceSnippets";
import {
  getQueryMaintenanceFileDisplay,
  getQueryMaintenanceFileDownload,
  getQueryMaintenanceFiles,
} from "../../../Api/Maintenance/apiMaintenanceGetQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import useTheme from "@mui/material/styles/useTheme";
import { PostQueryUploadFileInput } from "../../../Api/Maintenance/apiMaintenanceInputs";
import {
  getQueryTTDisplayFile,
  getQueryTTDownloadFile,
  getQueryTTObjectFilesData,
} from "../../../Api/TrackAndTrace/apiTTGetQueries";
import { GetQueryTTObjectFilesDataSnippet } from "../../../Api/TrackAndTrace/apiTTSnippets";
import {
  postQueryMaintenanceDeleteFile,
  postQueryMaintenanceUploadFile,
} from "../../../Api/Maintenance/apiMaintenancePostQueries";
import {
  postQueryTTDeleteFile,
  postQueryTTUploadFile,
} from "../../../Api/TrackAndTrace/apiTTPostQueries";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { MaintenanceFile } from "../../../Api/Maintenance/apiMaintenanceDataTypes";

interface DocumentsModalLayoutProps {
  modalType: "setup" | "object";
  setupId: string;
}

const DocumentsModalLayout: React.FC<DocumentsModalLayoutProps> = ({
  modalType,
  setupId,
}) => {
  const theme = useTheme();
  const { authedUser, setAuthedUser } = useAuthedContext();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [filesData, setFilesData] = useState<MaintenanceFile[]>();
  const [file, setFile] = useState<FileWithPath | null>(null);

  useEffect(() => {
    updateFilesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authedUser]);

  const updateFilesData = async () => {
    try {
      if (modalType === "setup") {
        const data = await callApi<GetQueryMaintenanceFilesSnippet>({
          query: getQueryMaintenanceFiles(setupId),
          auth: { setAuthedUser },
        });
        setFilesData(data as GetQueryMaintenanceFilesSnippet);
      } else {
        const data = await callApi<GetQueryTTObjectFilesDataSnippet>({
          query: getQueryTTObjectFilesData(setupId),
          auth: { setAuthedUser },
        });
        setFilesData(data as GetQueryMaintenanceFilesSnippet);
      }
    } catch (error) {
      console.log("There was an error fetching files ", error);
    }
  };

  const handleFileUpload = async () => {
    try {
      setAlertMessage(null);
      setAlertStatus(null);

      if (!file) {
        setAlertMessage("You must upload a document");
        setAlertStatus("warning");
        return;
      }

      const input: PostQueryUploadFileInput = {
        file: file,
      };

      const response = await callApi<PostQueryUploadFileSnippet>({
        query:
          modalType === "setup"
            ? postQueryMaintenanceUploadFile(setupId, input)
            : postQueryTTUploadFile(setupId, input),
        auth: { setAuthedUser },
      });

      if (response) {
        updateFilesData();
        setAlertMessage("Document uploaded successfully");
        setAlertStatus("success");
        setFile(null);
      }
    } catch (error) {
      console.log("There was an error uploading the file ", error);
      setAlertMessage("There was an error uploading the file");
      setAlertStatus("error");
    }
  };

  const handleFileDelete = async (fileId: string) => {
    try {
      setAlertMessage(null);
      setAlertStatus(null);

      const response = await callApi<PostQueryDeleteFileSnippet>({
        query:
          modalType === "setup"
            ? postQueryMaintenanceDeleteFile(fileId)
            : postQueryTTDeleteFile(fileId),
        auth: { setAuthedUser },
      });

      if (response) {
        setFilesData((prevData) => {
          if (prevData) {
            return prevData.filter((fileData) => fileData.file_id !== fileId);
          }
          return prevData;
        });

        setAlertMessage("Document deleted successfully");
        setAlertStatus("success");
      }
    } catch (error) {
      console.log("There was an error deleting the file ", error);
      setAlertMessage("There was an error deleting the file");
      setAlertStatus("error");
    }
  };

  const handleFileDownload = async (fileId: string, filename: string) => {
    try {
      setAlertMessage(null);
      setAlertStatus(null);

      const response: Response = await callApi({
        query:
          modalType === "setup"
            ? getQueryMaintenanceFileDownload(fileId)
            : getQueryTTDownloadFile(fileId),
        auth: { setAuthedUser },
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (e) {
      console.log("There was an error downloading the file ", e);
      setAlertMessage("There was an error downloading the file");
      setAlertStatus("error");
    }
  };

  const handleDisplayFile = async (fileId: string) => {
    try {
      setAlertMessage(null);
      setAlertStatus(null);

      const response: Response = await callApi({
        query:
          modalType === "setup"
            ? getQueryMaintenanceFileDisplay(fileId)
            : getQueryTTDisplayFile(fileId),
        auth: { setAuthedUser },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (e) {
      console.log("There was an error displaying the file ", e);
      setAlertMessage("There was an error displaying the file");
      setAlertStatus("error");
    }
  };

  return (
    <Box component="div">
      <Stack justifyContent="center" alignItems="center" width="100%" my={6}>
        {!file ? (
          <FileDropzone
            setFiles={(files) => setFile(files[0])}
            setErrorMessage={setAlertMessage}
            accept={{ "application/pdf": [], "image/*": [] }}
          >
            <Button>Select Document</Button>
          </FileDropzone>
        ) : (
          <Button onClick={handleFileUpload}>Upload Document</Button>
        )}

        <Box component="div" mt={2}>
          {file?.name ? (
            <LabelWithBoldedPart text={"Uploaded file"} bolded={file.name} />
          ) : (
            <Typography component="span" variant="body2" fontStyle="italic">
              * Supported file formats: JPG, JPEG, PNG, PDF
            </Typography>
          )}
        </Box>
      </Stack>

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={alertStatus}
      />

      <Box component="div" mt={4}>
        <List>
          {filesData ? (
            filesData.length > 0 ? (
              filesData.map((fileData) => (
                <ListItem key={fileData.file_id}>
                  <ListItemIcon>
                    {fileData.filename.endsWith(".pdf") ? (
                      <PictureAsPdfIcon fontSize="large" />
                    ) : (
                      <PhotoIcon fontSize="large" />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <ListItemButton
                        css={{ padding: 0 }}
                        onClick={() => handleDisplayFile(fileData.file_id)}
                      >
                        <Typography
                          css={{
                            ":hover": { textDecoration: "underline", cursor: "pointer" },
                          }}
                        >
                          {fileData.filename}
                        </Typography>
                      </ListItemButton>
                    }
                    secondary={`${fileData.size} kB`}
                  />
                  <Box component="div">
                    <IconButton
                      onClick={() =>
                        handleFileDownload(fileData.file_id, fileData.filename)
                      }
                    >
                      <DownloadIcon css={{ color: theme.palette.info.main }} />
                    </IconButton>

                    <IconButton onClick={() => handleFileDelete(fileData.file_id)}>
                      <DeleteIcon css={{ color: theme.palette.error.main }} />
                    </IconButton>
                  </Box>
                </ListItem>
              ))
            ) : (
              <Stack justifyContent="center" alignItems="center">
                <Typography>No uploaded documents found</Typography>
              </Stack>
            )
          ) : (
            <Stack justifyContent="center" alignItems="center">
              <Typography>Loading...</Typography>
            </Stack>
          )}
        </List>
      </Box>
    </Box>
  );
};

export default DocumentsModalLayout;
