import { Stack, Typography, Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import { getQueryLabelImage } from "../../../../Api/VisionControl/apiVisionControlGetQueries";
import { deleteQueryLabelImage } from "../../../../Api/VisionControl/apiVisionControlPostQueries";
import {
  LabelImage,
  GetQueryLabelImageSnippet,
} from "../../../../Api/VisionControl/apiVisionControlSnippets";
import callApi from "../../../../Api/callApi";
import cssFontStyles from "../../../../Global/Styles/font";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { FormStatuses, SelectOption } from "../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../context/AuthContext";
import ContentBox from "../../../MaterialUI/ContentBox";
import LabelWithBoldedPart from "../../../MaterialUI/LabelWithBoldedPart";
import {
  AllLabelImages,
  LabelRecognitionTestedModalData,
  LabelRecognitionTestedModalDataNoMaster,
  labelsSortByValue,
} from "./labelRecognitionUtils";
import useTheme from "@mui/material/styles/useTheme";
import Button from "../../../MaterialUI/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "../../../MaterialUI/Modal";
import Alert from "../../../MaterialUI/Alert";
import { SerializedStyles } from "@emotion/react";
import LabelImageListFilters from "./LabelImageListFilters";
import cssComponentsStyles from "../../../../Global/Styles/components";

type LabelToDeleteOrView = {
  name: string;
  bucket: string;
  url: string;
};

interface LabelImageListProps {
  allLabelImages: AllLabelImages | null;
  fetchAllLabels: (type: "all" | "master" | "tested") => Promise<void>;
  setTestedModalData: React.Dispatch<
    React.SetStateAction<LabelRecognitionTestedModalData | null>
  >;
  stylesImageContainer: SerializedStyles;
  stylesImageText: SerializedStyles;
  setNewImageModal: React.Dispatch<React.SetStateAction<boolean>>;
  setTestedModalDataNoMaster: React.Dispatch<
    React.SetStateAction<LabelRecognitionTestedModalDataNoMaster | null>
  >;
  allLanguages: SelectOption[];
}

const LabelImageList: React.FC<LabelImageListProps> = ({
  allLabelImages,
  fetchAllLabels,
  setTestedModalData,
  stylesImageContainer,
  stylesImageText,
  setNewImageModal,
  setTestedModalDataNoMaster,
  allLanguages,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssFontStyles,
    ...cssComponentsStyles(theme),
  };
  const initialAllLabels = allLabelImages
    ? [...allLabelImages.tested, ...allLabelImages.master]
    : [];
  const [displayedLabels, setDisplayedLabels] = useState<LabelImage[]>(initialAllLabels);
  const [labelToDelete, setLabelToDelete] = useState<LabelToDeleteOrView | null>(null);
  const [labelToView, setLabelToView] = useState<LabelToDeleteOrView | null>(null);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    setDisplayedLabels(() =>
      allLabelImages
        ? labelsSortByValue(
            [...allLabelImages.tested, ...allLabelImages.master],
            "Newest"
          )
        : []
    );
  }, [allLabelImages]);

  const handleDeleteLabel = async () => {
    try {
      if (labelToDelete) {
        setFormStatus("loading");
        setAlertMessage("Loading...");

        await callApi({
          query: deleteQueryLabelImage(labelToDelete.bucket, labelToDelete.name),
          auth: { setAuthedUser },
        });

        await fetchAllLabels(labelToDelete.bucket as "master" | "tested");

        setFormStatus(null);
        setAlertMessage(null);
        setLabelToDelete(null);
      }
    } catch (err) {
      console.log("handleDeleteLabel err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const handleOpenViewInfoModal = async (label: LabelToDeleteOrView) => {
    const { bucket, name } = label;
    if (bucket === "master") {
      setLabelToView(label);
      return;
    }

    try {
      setFormStatus("loading");

      const sampleImage = await callApi<GetQueryLabelImageSnippet>({
        query: getQueryLabelImage(bucket, name),
        auth: { setAuthedUser },
      });
      const masterName = sampleImage.metadata._metadata["x-amz-meta-master-label-name"];
      let masterImage: GetQueryLabelImageSnippet | null = null;

      try {
        masterImage = await callApi<GetQueryLabelImageSnippet>({
          query: getQueryLabelImage("master", masterName),
          auth: { setAuthedUser },
        });
      } catch (err) {
        setTestedModalDataNoMaster({
          testedLabelName: name,
          testedLabelUrl: sampleImage.url,
        });
        setFormStatus(null);
        return;
      }

      setTestedModalData({
        masterLabelName: masterName,
        masterLabelUrl: masterImage.url,
        testedLabelName: name,
        testedLabelUrl: sampleImage.url,
        confidence: sampleImage.confidence || 0,
      });

      setFormStatus(null);
    } catch (err) {
      console.log("handleOpenViewInfoModal err", err);
      setFormStatus("error");
    }
  };

  return (
    <>
      <ContentBox>
        <Stack
          css={styles.labelBreak}
          direction="row"
          spacing={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h3" color="textSecondary">
            Check your Labels
          </Typography>
          <Button onClick={() => setNewImageModal(true)}>Upload Image</Button>
        </Stack>

        <LabelImageListFilters
          allLabels={initialAllLabels}
          setDisplayedLabels={setDisplayedLabels}
          allLanguages={allLanguages}
        />

        {!displayedLabels.length ? (
          <Typography variant="h2" align="center">
            {allLabelImages
              ? "No images match these filters"
              : "You haven't uploaded any images yet"}
          </Typography>
        ) : (
          <Stack spacing={0} direction="row" flexWrap="wrap" justifyContent="center">
            {displayedLabels.map((item, index) => (
              <Box
                component="div"
                css={[stylesImageContainer, styles.widthLimit25]}
                key={`${item.image_name}-${index}`}
              >
                <Stack
                  css={stylesImageText}
                  spacing={2}
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h4" noWrap>
                    {item.image_name}
                  </Typography>
                  <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <IconButton
                      size="small"
                      onClick={() =>
                        setLabelToDelete({
                          bucket: item.bucket,
                          name: item.image_name,
                          url: item.url,
                        })
                      }
                    >
                      <DeleteOutlinedIcon
                        color={formStatus === "loading" ? "disabled" : "error"}
                      />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={() =>
                        handleOpenViewInfoModal({
                          bucket: item.bucket,
                          name: item.image_name,
                          url: item.url,
                        })
                      }
                    >
                      <InfoOutlinedIcon
                        css={
                          formStatus === "loading"
                            ? { color: "disabled" }
                            : styles.infoIcon
                        }
                      />
                    </IconButton>
                  </Stack>
                </Stack>
                <img
                  css={styles.widthLimit25}
                  src={item.url}
                  alt="label image"
                  loading="lazy"
                />
              </Box>
            ))}
          </Stack>
        )}
      </ContentBox>

      <Modal
        open={Boolean(labelToDelete || labelToView)}
        onClose={() => {
          setLabelToDelete(null);
          setLabelToView(null);
        }}
        fullWidth={!!labelToDelete}
        maxWidth={labelToDelete ? "sm" : "xl"}
        label={labelToDelete ? "Confirm deletion" : "Image info"}
      >
        {labelToDelete ? (
          <Stack spacing={2}>
            <Typography variant="body1">
              Are you sure you want to delete this label?
            </Typography>
            <Box component="div" css={styles.flexCenter}>
              <Button onClick={handleDeleteLabel} loading={formStatus === "loading"}>
                Confirm Deletion
              </Button>
            </Box>

            <Alert
              message={alertMessage}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
          </Stack>
        ) : null}

        {labelToView ? (
          <Stack spacing={4}>
            <Box component="div">
              <LabelWithBoldedPart
                css={styles.textBreak}
                text={"Filename"}
                bolded={labelToView.name}
              />
              <LabelWithBoldedPart text={"Type"} bolded="Original label" />
            </Box>
            <Box component="div" css={stylesImageContainer}>
              <img src={labelToView.url} alt="label image" loading="lazy" />
            </Box>
          </Stack>
        ) : null}
      </Modal>
    </>
  );
};

export default LabelImageList;
