import { FileWithPath } from "react-dropzone";
import { Query } from "../../../Api/callApi";
import { KanbanTaskStatus } from "../../TaskManager/Components/taskManagerUtils";
import { KanbanWorkflowType } from "../kanbanWorkflowUtils";

export const postQueryKanbanWorkflowCreate = (
  input: PostQueryKanbanWorkflowCreateInput
): Query => ({
  endpoint: "workflows/workflow",
  method: "POST",
  variables: input,
});
export type PostQueryKanbanWorkflowCreateInput = {
  data: KanbanWorkflowType;
};
export type PostQueryKanbanWorkflowCreateSnippet = {
  data: KanbanWorkflowType;
  id: string;
};

export const putQueryKanbanWorkflow = (
  input: PostQueryKanbanWorkflowCreateInput,
  id: string
): Query => ({
  endpoint: `workflows/workflow/${id}`,
  method: "PUT",
  variables: input,
});

export const deleteQueryKanbanWorkflow = (id: string): Query => ({
  endpoint: `workflows/workflow/${id}`,
  method: "DELETE",
});

export const putQueryKanbanTaskStatus = (
  taskID: string,
  status: KanbanTaskStatus
): Query => ({
  endpoint: `workflows/task/${taskID}/status`,
  method: "PUT",
  variables: {
    status,
  },
});

export const putQueryKanbanTaskTemplate = (
  templateID: string,
  input: PutQueryKanbanTaskTemplateInput
): Query => ({
  endpoint: `workflows/task/template/${templateID}`,
  method: "PUT",
  variables: {
    ...input,
  },
});
export type PutQueryKanbanTaskTemplateInput = {
  description: string;
  steps: string;
  data_fields: string[];
};

export const putQueryKanbanTaskTemplateName = (
  templateID: string,
  name: string
): Query => ({
  endpoint: `workflows/task/template/${templateID}/name`,
  method: "PUT",
  variables: {
    name,
  },
});

export const postQueryKanbanTaskTemplate = (
  input: PostQueryKanbanTaskTemplateInput
): Query => ({
  endpoint: "workflows/task/template",
  method: "POST",
  variables: {
    ...input,
  },
});
export type PostQueryKanbanTaskTemplateInput = {
  name: string;
  description: string;
  steps: string;
  data_fields: string[];
};

export const postQueryKanbanTaskTemplateUploadFile = (
  templateID: string,
  versionID: string,
  file: FileWithPath
): Query => ({
  endpoint: `workflows/task/template/${templateID}/version/${versionID}/file`,
  method: "POST",
  variables: {
    file,
  },
  multipartForm: true,
});

export const deleteQueryKanbanTaskTemplateFile = (fileID: string): Query => ({
  endpoint: `/workflows/task/template/file/${fileID}`,
  method: "DELETE",
});

export const postQueryKanbanTaskCheckMove = (taskID: string, nodeID: string): Query => ({
  endpoint: `workflows/task/${taskID}/move/node/${nodeID}`,
  method: "POST",
});
export type PostQueryKanbanTaskCheckMoveSnippet = {
  message: string;
  status: boolean;
};

export const postQueryKanbanTaskUpdateFields = (
  taskID: string,
  input: PostQueryKanbanTaskUpdateFieldsInput
): Query => ({
  endpoint: `workflows/task/${taskID}/data`,
  method: "PUT",
  variables: [...input],
});
export type PostQueryKanbanTaskUpdateFieldsInput = {
  data_id: string;
  value: string;
}[];

export const deleteQueryKanbanTemplate = (templateID: string): Query => ({
  endpoint: `/workflows/task/template/${templateID}`,
  method: "DELETE",
});
