import { Box } from "@mui/material";
import { KanbanTaskTemplate } from "../taskManagerUtils";
import EditKanTaskTemplate from "./EditKanTaskTemplate";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import {
  postQueryKanbanTaskTemplate,
  PostQueryKanbanTaskTemplateInput,
} from "../../../KanbanWorkflows/api/mutations";

interface AddKanTaskTemplateProps {
  emptyTemplate: KanbanTaskTemplate;
  onCreateNew: () => Promise<void>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddKanTaskTemplate: React.FC<AddKanTaskTemplateProps> = ({
  emptyTemplate,
  onCreateNew,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { setAuthedUser } = useAuthedContext();

  const handleCreateNew = async (data: PostQueryKanbanTaskTemplateInput) => {
    try {
      const input: PostQueryKanbanTaskTemplateInput = {
        name: data.name,
        description: data.description,
        steps: data.steps,
        data_fields: data.data_fields,
      };

      await callApi({
        query: postQueryKanbanTaskTemplate(input),
        auth: { setAuthedUser },
      });
      await onCreateNew();
      setUnsavedChanges(false);
      return "success";
    } catch (err) {
      console.log("handleSave() err", err);
      return "error";
    }
  };

  return (
    <Box component="div">
      <EditKanTaskTemplate
        template={emptyTemplate}
        onSave={handleCreateNew}
        isCreateNew
        handleSetUnsavedChanges={handleSetUnsavedChanges}
      />
    </Box>
  );
};

export default AddKanTaskTemplate;
