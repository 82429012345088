export const INTEGRATOR_EDIT_INFO_TEXT =
  "When you save your changes, they will be propagated to other services, which rely on the connection, such as Excellence.";

type OldNew = {
  old: string;
  new: string;
};

export const handleConnectionOldNewString = (
  newStr: string | number,
  oldStr: string | number
): OldNew | undefined => {
  if (newStr === oldStr) {
    return undefined;
  }

  return {
    new: `${newStr}`,
    old: `${oldStr}`,
  };
};
