import { SerializedStyles } from "@emotion/react";
import TreeMap from "../../../../ExcellenceWidgets/AdvancedWidgets/TreeMap/TreeMap";
import { TreeMapData } from "../../../../ExcellenceWidgets/AdvancedWidgets/TreeMap/treeMapTypes";
import treeMapDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/treeMapDefaultData";

interface ExcellenceTreeMapProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: TreeMapData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceTreeMap: React.FC<ExcellenceTreeMapProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <TreeMap
      className={className}
      configuration={configuration}
      data={data || treeMapDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceTreeMap;
