import { SerializedStyles } from "@emotion/react";
import { IconButton, Stack, Typography } from "@mui/material";
import RadioButtonCheckedOutlinedIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import { DynamicGridFormFieldOptions } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import Crop169OutlinedIcon from "@mui/icons-material/Crop169Outlined";
import FontDownloadOutlinedIcon from "@mui/icons-material/FontDownloadOutlined";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import TextsmsOutlinedIcon from "@mui/icons-material/TextsmsOutlined";
import ToggleOnOutlinedIcon from "@mui/icons-material/ToggleOnOutlined";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

interface AddNewDynamicFormFieldProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  handleAddNewFormField: (formField: DynamicGridFormFieldOptions, title: string) => void;
}

const AddNewDynamicFormField: React.FC<AddNewDynamicFormFieldProps> = ({
  className,
  handleAddNewFormField,
}) => {
  return (
    <Stack className={className} spacing={2}>
      <Stack spacing={2} direction="row" justifyContent="center">
        <Stack alignItems="center">
          <IconButton
            aria-label="add new button"
            onClick={() => handleAddNewFormField({ button: true }, "Button")}
          >
            <Crop169OutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" whiteSpace="pre-line">
            Button
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <IconButton
            aria-label="add new text field"
            onClick={() => handleAddNewFormField({ textField: true }, "Text field")}
          >
            <FontDownloadOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" whiteSpace="pre-line">
            {`Text
            Field`}
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <IconButton
            aria-label="add new text area"
            onClick={() => handleAddNewFormField({ textArea: true }, "Text area")}
            disabled
          >
            <TextsmsOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color="textSecondary"
          >
            {`Text
            Area`}
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <IconButton
            aria-label="add new radio button"
            onClick={() => handleAddNewFormField({ radioButton: true }, "Radio button")}
          >
            <RadioButtonCheckedOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" whiteSpace="pre-line">
            {`Radio
            Button`}
          </Typography>
        </Stack>
      </Stack>

      <Stack spacing={2} direction="row" justifyContent="center">
        <Stack alignItems="center">
          <IconButton
            aria-label="add new checkbox"
            onClick={() => handleAddNewFormField({ checkbox: true }, "Checkbox")}
          >
            <CheckBoxOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" whiteSpace="pre-line">
            Checkbox
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <IconButton
            aria-label="add new dropdown"
            onClick={() => handleAddNewFormField({ dropdown: true }, "Dropdown")}
            disabled
          >
            <ArrowDropDownCircleOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color="textSecondary"
          >
            {`Drop-
            down`}
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <IconButton
            aria-label="add new switch"
            onClick={() => handleAddNewFormField({ switch: true }, "Switch")}
            disabled
          >
            <ToggleOnOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color="textSecondary"
          >
            Switch
          </Typography>
        </Stack>

        <Stack alignItems="center">
          <IconButton
            aria-label="add new date time field"
            onClick={() => handleAddNewFormField({ dateTime: true }, "Date-time field")}
            disabled
          >
            <CalendarMonthIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color="textSecondary"
          >
            {`Date-time
            Field`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AddNewDynamicFormField;
