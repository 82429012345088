import { Box, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../Components/MaterialUI/LabelWithBoldedPart";
import { formatDateAndTime } from "../../../../Global/Utils/commonFunctions";
import { KanbanTask, KanbanTaskField } from "../taskManagerUtils";

interface ViewKanbanTaskProps {
  task: KanbanTask;
}

const ViewKanbanTask: React.FC<ViewKanbanTaskProps> = ({ task }) => {
  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text="ID" bolded={task.id} />
        <LabelWithBoldedPart text="Workflow" bolded={task.workflow_name} />
        <LabelWithBoldedPart text="Order" bolded={task.order_name} />
        <LabelWithBoldedPart text="Status" bolded={task.status} />
        <LabelWithBoldedPart
          text="Created At"
          bolded={formatDateAndTime(task.created_at)}
        />
        <LabelWithBoldedPart
          text="Updated At"
          bolded={formatDateAndTime(task.updated_at)}
        />
        <LabelWithBoldedPart text="Description" bolded={task.template.description} />

        <Typography mb={1} variant="h4" align="center" color="textSecondary">
          Attributes
        </Typography>
        <Stack spacing={1}>
          {task.tasks_data.map((item) => (
            <LabelWithBoldedPart
              text={item.data_field.name}
              bolded={getFieldValue(item)}
            />
          ))}
        </Stack>
      </Stack>

      <Typography variant="h3" mb={1}>
        Steps:
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: task.template.steps }} />
    </Box>
  );
};

export default ViewKanbanTask;

const getFieldValue = (item: KanbanTaskField): string => {
  if (!item.data_value && item.data_field.type !== "boolean") {
    return "";
  }
  if (item.data_field.type === "boolean") {
    return item.data_value ? "Yes" : "No";
  }
  if (item.data_field.type === "date" || item.data_field.type === "datetime") {
    return formatDateAndTime(
      new Date(item.data_value),
      item.data_field.type ? "date" : undefined
    );
  }

  return item.data_value;
};
