import { useEffect, useState } from "react";
import {
  GetQueryDASubscriptionHistorySnippet,
  GetQueryMQTTSubscriptionHistorySnippet,
  GetQueryModbusHistorySnippet,
  GetQueryUASubscriptionHistorySnippet,
} from "../../../../Api/DataSources/apiDSSnippets";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import callApi from "../../../../Api/callApi";
import {
  getQueryDASubscriptionHistory,
  getQueryMQTTSubscriptionHistory,
  getQueryModbusHistory,
  getQueryUASubscriptionHistory,
} from "../../../../Api/DataSources/apiDSGetQueries";
import { Grid, Stack, Typography } from "@mui/material";
import ContentBox from "../../../MaterialUI/ContentBox";
import Alert from "../../../MaterialUI/Alert";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import Button from "../../../MaterialUI/Button";
import Collapse from "../../../MaterialUI/Collapse";
import OpcHistoryCard from "./OpcHistoryCard";
import { useAuthedContext } from "../../../../context/AuthContext";

type SetDaHistory = React.Dispatch<
  React.SetStateAction<GetQueryDASubscriptionHistorySnippet>
>;
type SetUaHistory = React.Dispatch<
  React.SetStateAction<GetQueryUASubscriptionHistorySnippet>
>;
type SetMqttHistory = React.Dispatch<
  React.SetStateAction<GetQueryMQTTSubscriptionHistorySnippet>
>;
type SetModbusHistory = React.Dispatch<
  React.SetStateAction<GetQueryModbusHistorySnippet>
>;

interface OpcConnectionHistoryProps {
  subID: string;
  hideHistory: boolean;
  setHideHistory: React.Dispatch<React.SetStateAction<boolean>>;
  connection: {
    da?: {
      history: GetQueryDASubscriptionHistorySnippet;
      setHistory: SetDaHistory;
    };
    ua?: {
      history: GetQueryUASubscriptionHistorySnippet;
      setHistory: SetUaHistory;
    };
    mqtt?: {
      history: GetQueryMQTTSubscriptionHistorySnippet;
      setHistory: SetMqttHistory;
    };
    modbus?: {
      history: GetQueryModbusHistorySnippet;
      setHistory: SetModbusHistory;
    };
  };
}

const OpcConnectionHistory: React.FC<OpcConnectionHistoryProps> = ({
  subID,
  hideHistory,
  setHideHistory,
  connection,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const cardGridProps = {
    xs: 12,
    sm: 6,
    lg: 4,
    xl: 3,
    item: true,
  };

  /**
   * If hideHistory prop set to true from parent,
   * refresh this component
   */
  useEffect(() => {
    if (hideHistory) {
      setAlertMessage(null);
      setFetchStatus(null);
      setHideHistory(false);
      if (connection.da) {
        connection.da.setHistory([]);
      }
      if (connection.ua) {
        connection.ua.setHistory([]);
      }
      if (connection.mqtt) {
        connection.mqtt.setHistory([]);
      }
      if (connection.modbus) {
        connection.modbus.setHistory([]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideHistory, connection]);

  const handleOnViewHistory = async () => {
    try {
      setFetchStatus("loading");
      setAlertMessage("Loading...");
      if (connection.da) {
        await handleDaHistory(connection.da.setHistory);
      } else if (connection.ua) {
        await handleUaHistory(connection.ua.setHistory);
      } else if (connection.mqtt) {
        await handleMqttHistory(connection.mqtt.setHistory);
      } else if (connection.modbus) {
        await handleModbusHistory(connection.modbus.setHistory);
      }
      setFetchStatus("success");
      setAlertMessage(null);
    } catch (err) {
      console.log("handleOnViewHistory err ", err);
      setFetchStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  const handleHideHistory = () => {
    setFetchStatus(null);
    setAlertMessage(null);
  };

  const handleDaHistory = async (setHistory: SetDaHistory) => {
    const data = await callApi<GetQueryDASubscriptionHistorySnippet>({
      query: getQueryDASubscriptionHistory(subID),
      auth: { setAuthedUser },
    });
    setHistory(data);
  };
  const handleUaHistory = async (setHistory: SetUaHistory) => {
    const data = await callApi<GetQueryUASubscriptionHistorySnippet>({
      query: getQueryUASubscriptionHistory(subID),
      auth: { setAuthedUser },
    });
    setHistory(data);
  };
  const handleMqttHistory = async (setHistory: SetMqttHistory) => {
    const data = await callApi<GetQueryMQTTSubscriptionHistorySnippet>({
      query: getQueryMQTTSubscriptionHistory(subID),
      auth: { setAuthedUser },
    });
    setHistory(data);
  };
  const handleModbusHistory = async (setHistory: SetModbusHistory) => {
    const data = await callApi<GetQueryModbusHistorySnippet>({
      query: getQueryModbusHistory(subID),
      auth: { setAuthedUser },
    });
    setHistory(data);
  };

  return (
    <>
      <ContentBox css={styles.labelBreak} label="Connection History">
        <Stack spacing={1}>
          <Typography css={styles.widthLimit50} variant="body1">
            Below, you can view the historical changes of a connection. Changes are
            displayed in a descending order - latest changes first, oldest last. So, the
            first card shows the current connection data.
          </Typography>

          <Stack justifyContent="center" alignItems="center">
            {fetchStatus === "success" ? (
              <Button
                onClick={handleHideHistory}
                variant="outlined"
                color="primary"
                css={[styles.widthLimit15]}
              >
                Hide History
              </Button>
            ) : (
              <Button
                onClick={handleOnViewHistory}
                variant="contained"
                loading={fetchStatus === "loading"}
                css={[styles.widthLimit15]}
              >
                View Historical Changes
              </Button>
            )}
            <Alert
              css={[styles.marginHorizontalAuto, styles.widthLimit25, styles.reverseTextBreak]}
              message={alertMessage}
              showAlert={!!alertMessage}
              severity={fetchStatus}
            />
          </Stack>
        </Stack>
      </ContentBox>

      <Collapse in={fetchStatus === "success"}>
        <Grid container spacing={2}>
          {connection.da?.history ? (
            <>
              {connection.da.history.map((sub, index, array) => (
                <Grid key={`${sub.start_time}-${index}`} {...cardGridProps}>
                  <ContentBox css={styles.height100}>
                    <OpcHistoryCard
                      connection={{
                        da: {
                          sub: sub,
                          previousSub:
                            index === array.length - 1 ? null : array[index + 1],
                        },
                      }}
                      version={array.length - index}
                      isLatest={index === 0}
                      startTime={sub.start_time}
                      endTime={sub.end_time}
                      hasPreviousSub={index !== array.length - 1}
                    />
                  </ContentBox>
                </Grid>
              ))}
            </>
          ) : null}

          {connection.ua?.history ? (
            <>
              {connection.ua.history.map((sub, index, array) => (
                <Grid key={`${sub.start_time}-${index}`} {...cardGridProps}>
                  <ContentBox css={styles.height100}>
                    <OpcHistoryCard
                      connection={{
                        ua: {
                          sub: sub,
                          previousSub:
                            index === array.length - 1 ? null : array[index + 1],
                        },
                      }}
                      version={array.length - index}
                      isLatest={index === 0}
                      startTime={sub.start_time}
                      endTime={sub.end_time}
                      hasPreviousSub={index !== array.length - 1}
                    />
                  </ContentBox>
                </Grid>
              ))}
            </>
          ) : null}

          {connection.mqtt?.history ? (
            <>
              {connection.mqtt.history.map((sub, index, array) => (
                <Grid key={`${sub.start_time}-${index}`} {...cardGridProps}>
                  <ContentBox css={styles.height100}>
                    <OpcHistoryCard
                      connection={{
                        mqtt: {
                          sub: sub,
                          previousSub:
                            index === array.length - 1 ? null : array[index + 1],
                        },
                      }}
                      version={array.length - index}
                      isLatest={index === 0}
                      startTime={sub.start_time}
                      endTime={sub.end_time}
                      hasPreviousSub={index !== array.length - 1}
                    />
                  </ContentBox>
                </Grid>
              ))}
            </>
          ) : null}

          {connection.modbus?.history ? (
            <>
              {connection.modbus.history.map((sub, index, array) => (
                <Grid key={`${sub.start_time}-${index}`} {...cardGridProps}>
                  <ContentBox css={styles.height100}>
                    <OpcHistoryCard
                      connection={{
                        modbus: {
                          sub: sub,
                          previousSub:
                            index === array.length - 1 ? null : array[index + 1],
                        },
                      }}
                      version={array.length - index}
                      isLatest={index === 0}
                      startTime={sub.start_time}
                      endTime={sub.end_time}
                      hasPreviousSub={index !== array.length - 1}
                    />
                  </ContentBox>
                </Grid>
              ))}
            </>
          ) : null}
        </Grid>
      </Collapse>
    </>
  );
};

export default OpcConnectionHistory;
