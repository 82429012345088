import { ComputedDatum, ResponsivePie } from "@nivo/pie";
import { SerializedStyles, css } from "@emotion/react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useEffect, useRef, useState } from "react";
import {
  GaugeDatum,
  GaugeWidgetConfiguration,
  SectionRange,
  getColorSet,
  groupAndAverageData,
} from "./gaugeWidgetUtils";
import { GaugeWidgetData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { DynamicGridChartType, getCurrentColorScheme } from "../nivoTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import GaugeArrow from "./GaugeArrow";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import cssComponentsStyles from "../../../Global/Styles/components";
import { useLanguageContext } from "../../../context/LanguageContext";
import { extractUnitSymbol } from "../../SmallComponents/UnitsOfMeasureDropdown/unitsOfMeasureDropdownUtils";

const cssStyles = () => ({
  gaugeTextContainer: css({
    position: "absolute",
    top: "60%",
    left: "50%",
    transform: "translate(-50%, 150%)",
  }),
  gaugeText: css({
    fontSize: "1.5rem",
  }),
  warningText: css({
    width: "100%",
    textAlign: "center",
  }),
});

interface GaugeWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: GaugeWidgetConfiguration;
  data: GaugeWidgetData;
  isStatic?: boolean;
}

const GaugeWidget: React.FC<GaugeWidgetProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssStyles(),
    ...cssComponentsStyles(theme),
  };
  const { t } = useLanguageContext();
  const {
    enableArcLabels,
    enableArcLinkLabels,
    colors,
    colorsSequence,
    unitOfMeasure,
    sections,
    gaugeMinValue,
    gaugeMaxValue,
    showDataInPercentage,
    sectionRanges,
  } = configuration;
  const [gaugeChartColors, setGaugeChartColors] = useState<string[]>([]);
  const [modifiedData, setModifiedData] = useState<GaugeWidgetData>(data);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [currentValueInPercentage, setCurrentValueInPercentage] = useState<number>(
    data.currentValue
  );
  const [isCurrentValueInRange, setIsCurrentValueInRange] = useState<boolean>(true);
  const unitSymbol = extractUnitSymbol(unitOfMeasure, showDataInPercentage);
  const [arrowFontSize, setArrowFontSize] = useState<number>(70);
  const gaugeContainerRef = useRef<HTMLDivElement>(null);
  const { width: chartWidth } = useContainerDimensions(gaugeContainerRef);

  useEffect(() => {
    const baseFactor = 0.33; 
    const maxWidthThreshold = 600;  
    const minFontSize = 50; 
    const maxFontSize = 300;  
    
    let scaling = baseFactor;
  
    if (chartWidth > maxWidthThreshold) {
      const excessWidth = chartWidth - maxWidthThreshold;
      scaling = baseFactor - (excessWidth / 3000); 
    }
  
    scaling = Math.max(0.30, scaling);
  
    const calculatedFontSize = Math.max(
      minFontSize,
      Math.min(chartWidth * scaling, maxFontSize)
    );
  
    setArrowFontSize(calculatedFontSize);
  }, [chartWidth, arrowFontSize]);
  

  useEffect(() => {
    if (
      data.currentValue < configuration.gaugeMinValue ||
      data.currentValue > configuration.gaugeMaxValue
    ) {
      setIsCurrentValueInRange(false);
    } else {
      setIsCurrentValueInRange(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modifiedData.currentValue,
    configuration.gaugeMinValue,
    configuration.gaugeMaxValue,
  ]);

  useEffect(() => {
    const newColors = getColorSet(theme, colors, colorsSequence, sections);
    setGaugeChartColors(newColors);

    const { data: newData, warning } = groupAndAverageData(
      data,
      configuration,
      setCurrentValueInPercentage,
      gaugeMinValue,
      gaugeMaxValue
    );

    setModifiedData(newData);
    setShowWarning(warning);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, showDataInPercentage, configuration]);

  const getArcLabel = (
    datum: ComputedDatum<{
      id: string;
      label: string;
      value: number;
      averageValue: number;
    }>
  ) => {
    let arcLabel = showDataInPercentage ? datum.value : datum.data.averageValue;
    return `${arcLabel} ${unitSymbol}`;
  };

  const chartMargin = {
    top: isStatic ? 10 : 0,
    right: isStatic ? 10 : 70,
    bottom: isStatic ? 10 : 110,
    left: isStatic ? 10 : 70,
  };

  return (
    <Stack css={styles.relative} className={className} ref={gaugeContainerRef}>
      <ResponsivePie
        data={modifiedData.rangeData}
        enableArcLabels={isStatic ? false : enableArcLabels}
        enableArcLinkLabels={isStatic ? false : enableArcLinkLabels}
        colors={gaugeChartColors}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
        }}
        margin={chartMargin}
        startAngle={-90}
        endAngle={90}
        innerRadius={0.5}
        padAngle={0}
        cornerRadius={6}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabel={getArcLabel}
        arcLabelsTextColor={
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
            ? {
                from: "color",
                modifiers: [["darker", 10]],
              }
            : undefined
        }
        arcLinkLabelsOffset={-10}
        arcLinkLabelsDiagonalLength={20}
        arcLinkLabelsStraightLength={14}
        arcLinkLabelsTextOffset={0}
        isInteractive={isStatic ? false : true}
        legends={
          isStatic
            ? undefined
            : [
                {
                  anchor: "bottom",
                  direction: "row",
                  justify: false,
                  translateX: 0,
                  translateY: 70,
                  itemsSpacing: 0,
                  itemWidth: 80,
                  itemHeight: 18,
                  itemDirection: "left-to-right",
                  itemOpacity: 1,
                  symbolSize: 18,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]
        }
        tooltip={({ datum }) => (
          <SectionTooltip datum={datum} sectionRanges={sectionRanges} t={t} />
        )}
      />

      {isCurrentValueInRange ? (
        <Box component="div" css={styles.gaugeTextContainer}>
          <Typography css={styles.gaugeText} variant="body1">
            {`${modifiedData.currentValue} ${unitSymbol}`}
          </Typography>
        </Box>
      ) : null}

      {isCurrentValueInRange ? (
        <GaugeArrow
          currentValueInPercentage={currentValueInPercentage}
          arrowFontSize={arrowFontSize}
        />
      ) : (
        <Typography
          css={[styles.warningText, styles.reverseLabelBreak]}
          variant="body2"
          style={theme.customizedTextStyles.labelStyles}
        >
          {t("The current value is outside the gauge range.")}
        </Typography>
      )}

      {showWarning ? (
        <Typography
          css={[styles.warningText, styles.reverseLabelBreak]}
          variant="body2"
          style={theme.customizedTextStyles.labelStyles}
        >
          {t("Number of sections reduced due to insufficient data records.")}
        </Typography>
      ) : null}
    </Stack>
  );
};

export default GaugeWidget;

interface SectionTooltipProps {
  datum: ComputedDatum<GaugeDatum>;
  sectionRanges: SectionRange[];
  t: (key: string) => string;
}

const SectionTooltip: React.FC<SectionTooltipProps> = ({ datum, sectionRanges, t }) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const sectionRange = sectionRanges[datum.data.index];
  let tooltipText = "";

  if (sectionRange) {
    const minText = sectionRange.min !== null ? `${t("Min")}: ${sectionRange.min}` : "";
    const maxText = sectionRange.max !== null ? `${t("Max")}: ${sectionRange.max}` : "";

    tooltipText = [minText, maxText].filter((text) => text).join(", ");
  }

  return (
    <Box
      component="div"
      css={[
        styles.card,
        styles.flexCenter,
        styles.leftRightPadding2,
        styles.tooltipStyle,
      ]}
      gap={1}
    >
      <Box
        component="div"
        sx={{
          width: 14,
          height: 14,
          backgroundColor: datum.color,
          borderRadius: "50%",
        }}
      />
      <Typography variant="body2">{tooltipText}</Typography>
    </Box>
  );
};
