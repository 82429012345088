import { SerializedStyles } from "@emotion/react";
import { Box } from "@mui/material";
import { NetworkData } from "./networkTypes";
import { ResponsiveNetwork } from "@nivo/network";

interface NetworkProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: NetworkData;
  isStatic?: boolean;
}

const Network: React.FC<NetworkProps> = ({ className, data, isStatic = true }) => {
  const chartMargin = {
    top: isStatic ? 15 : 0,
    right: isStatic ? 5 : 0,
    bottom: isStatic ? 5 : 0,
    left: isStatic ? 5 : 0,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveNetwork
        data={data}
        margin={chartMargin}
        linkDistance={(e) => e.distance}
        centeringStrength={0.3}
        repulsivity={6}
        nodeSize={(n) => n.size}
        activeNodeSize={(n) => 1.5 * n.size}
        nodeColor={(e) => e.color}
        nodeBorderWidth={1}
        nodeBorderColor={{
          from: "color",
          modifiers: [["darker", 0.8]],
        }}
        isInteractive={isStatic ? false : true}
        linkThickness={(n) => 2 + 2 * n.target.data.height}
        linkBlendMode="multiply"
        motionConfig="wobbly"
      />
    </Box>
  );
};

export default Network;
