import { ResponsiveBar } from "@nivo/bar";
import { SerializedStyles } from "@emotion/react";
import { Box, Stack, Tooltip, useTheme } from "@mui/material";
import { BarColumnRadarData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { BarChartConfiguration, BarChartDataSchema } from "./barChartTypes";
import { getChartColors } from "../nivoTheme";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import { memo, useEffect, useRef, useState } from "react";
import { formatNumber } from "../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../context/LanguageContext";
import { getBarKeysAndLegend } from "../excellenceUtils";
import { BarLegend } from "../ExcellenceSmallComponents";

interface BarChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: BarChartConfiguration;
  data: BarColumnRadarData;
  isStatic?: boolean;
  indexBy?: string;
  noAnimation?: boolean;
  schema: BarChartDataSchema | null;
  paramMapping?: Record<string, string>;
  height?: number;
}

const BarChart: React.FC<BarChartProps> = ({
  className,
  data,
  configuration,
  isStatic,
  indexBy,
  noAnimation,
  height,
  // schema,
  // paramMapping,
}) => {
  const theme = useTheme();
  const { t } = useLanguageContext();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width: chartWidth } = useContainerDimensions(chartContainerRef);
  const {
    groupMode,
    valueScale,
    minScale,
    maxScale,
    enableGridX,
    enableGridY,
    enableLabel,
    // bottomLegend,
    // manualBottomLabel,
  } = configuration;
  const isColumnChart = !!configuration.isColumn;

  // const maxPoints = isColumnChart ? chartWidth / 30 : chartHeight / 30;
  // const dynamicData = downSampleGenericArrayData(data || [], Math.round(maxPoints));
  const keysAndLegend = getBarKeysAndLegend(data[0], getChartColors(), indexBy);
  const widthRotation = calculateLabelRotation(chartWidth, data.length);
  const widthBottomMargin = calculateBotLabelMargin(widthRotation);
  const legendOffset =
    keysAndLegend.longestLegend < 7 ? 50 + keysAndLegend.longestLegend * 4 : null;
  const legendWidth = legendOffset || Math.max(chartWidth * 0.1, 100);
  const widthLeftMargin = calculateLeftLabelMargin(data, !!isColumnChart);

  const chartMargin = {
    top: isStatic ? 10 : 25,
    right: isStatic ? 10 : 20,
    bottom: isStatic ? 10 : isColumnChart ? widthBottomMargin : 50,
    left: isStatic ? 10 : widthLeftMargin,
  };

  // const botLabel = manualBottomLabel
  //   ? bottomLegend
  //   : schema?.legend
  //   ? paramMapping?.[schema.legend.parameterId] || schema.legend.parameterId
  //   : "";

  return (
    <Box className={className} ref={chartContainerRef} component="div">
      <Stack
        style={{ width: "100%", height: height ? `${height}px` : "100%" }}
        direction="row"
        spacing={0}
      >
        <Box
          component="div"
          style={{
            width: isStatic ? "100%" : `calc(${chartWidth}px - ${legendWidth}px)`,
            height: "100%",
          }}
        >
          <ResponsiveBar
            data={data}
            keys={keysAndLegend.formattedKeys.keys}
            indexBy={keysAndLegend.formattedKeys.indexBy}
            axisTop={null}
            axisRight={null}
            isInteractive={isStatic ? false : true}
            animate={!noAnimation}
            axisBottom={
              isStatic
                ? null
                : {
                    // tickSize: 5,
                    // tickPadding: 7,
                    // tickRotation: isColumnChart ? widthRotation : 0,
                    // legend: isColumnChart ? "" : botLabel,
                    // legendPosition: "middle",
                    // legendOffset: 40,
                    renderTick: (props) => (
                      <CustomBotTick
                        x={props.x}
                        y={props.y}
                        value={props.value}
                        widthRotation={isColumnChart ? widthRotation : widthRotation}
                        color={theme.palette.common.black}
                        gray={theme.palette.text.secondary}
                        chartWidth={chartWidth}
                        numbOfBars={keysAndLegend.formattedKeys.keys.length}
                      />
                    ),
                  }
            }
            axisLeft={
              isStatic
                ? null
                : {
                    // tickSize: 5,
                    // tickPadding: 5,
                    // tickRotation: 0,
                    // legendPosition: "middle",
                    // legendOffset: -50,
                    // legend: isColumnChart ? botLabel : "",
                    renderTick: (props) => (
                      <CustomLeftTick
                        x={props.x}
                        y={props.y}
                        value={props.value}
                        widthRotation={widthRotation}
                        color={theme.palette.common.black}
                        gray={theme.palette.text.secondary}
                        chartWidth={chartWidth}
                        numbOfBars={keysAndLegend.formattedKeys.keys.length}
                      />
                    ),
                  }
            }
            ariaLabel={isColumnChart ? t("Column chart ") : t("Bar chart")}
            layout={isColumnChart ? "vertical" : "horizontal"}
            colors={keysAndLegend.colors}
            colorBy="id"
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            groupMode={groupMode}
            valueScale={valueScale ? { type: valueScale } : undefined}
            minValue={minScale}
            maxValue={maxScale}
            enableLabel={isStatic ? false : enableLabel}
            enableGridX={enableGridX}
            enableGridY={enableGridY}
            // static
            margin={chartMargin}
            padding={0.3}
            indexScale={{ type: "band", round: true }}
            label={(d) => {
              const result = formatNumber(
                d.value,
                configuration.fixedDecimalValue,
                configuration.decimalPoints
              );
              if (!result) {
                return `${d.value}`;
              }
              return `${result}`;
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            legends={undefined}
            role="application"
          />
        </Box>

        {isStatic ? null : <BarLegend data={keysAndLegend} width={legendWidth} />}
      </Stack>
    </Box>
  );
};

export default memo(BarChart);

const calculateLabelRotation = (chartWidth: number, numbOfBars: number): number => {
  const maxChartWidth = 1400;
  const maxRotation = 90;
  const initialRotation = numbOfBars * 1.2;
  const minBarNumbs = 7;
  const minRotation = numbOfBars < minBarNumbs ? 0 : 20;

  if (numbOfBars < minBarNumbs && chartWidth >= maxChartWidth) {
    return 0; // No rotation if chartWidth is 1400px or more
  } else if (numbOfBars > 40) {
    return 90;
  } else {
    // Calculate rotation based on chartWidth
    const additionalRotation =
      maxRotation * ((maxChartWidth - chartWidth) / maxChartWidth);
    const result = Math.min(maxRotation, initialRotation + additionalRotation);
    if (result < 0) {
      return minRotation;
    }
    return Math.max(result, minRotation);
  }
};

const calculateBotLabelMargin = (rotation: number): number => {
  const maxRotation = 90;
  const marginOffset = 90;
  const minMargin = 70;

  if (rotation === 0) {
    return minMargin;
  } else {
    const calculatedMargin =
      (rotation / maxRotation) * (marginOffset - minMargin) + minMargin;
    return Math.min(Math.max(minMargin, calculatedMargin), minMargin);
  }
};
const calculateLeftLabelMargin = (
  data: BarColumnRadarData,
  isColumnChart: boolean
): number => {
  const maxMargin = 100;
  const minMargin = 20;
  let longestValue = 0;

  const colValues = Object.entries(data[0])
    .filter(([key]) => key !== "group")
    .map(([_, value]) => value);

  const arr = isColumnChart ? colValues : data.map((ele) => ele.group || "");

  arr.forEach((item) => {
    const itemToUse = item.toString();
    if (itemToUse.length > longestValue) {
      longestValue = itemToUse.length;
    }
  });

  const labelWidth =
    longestValue < 4
      ? longestValue + 4
      : longestValue < 5
      ? longestValue + 2.2
      : longestValue < 6
      ? longestValue + 1.5
      : longestValue;
  const valueWidth = Math.max(labelWidth * 8, minMargin);

  return Math.min(maxMargin, valueWidth);
};

interface CustomTickProps {
  x: number;
  y: number;
  value: string;
  widthRotation: number;
  color: string;
  gray: string;
  chartWidth: number;
  numbOfBars: number;
}

const CustomBotTick: React.FC<CustomTickProps> = ({
  x,
  y,
  value,
  widthRotation,
  color,
  gray,
}) => {
  const maxChar = widthRotation === 90 ? 7 : 20;
  const valueToUse = value ? value.toString().substring(0, maxChar) : value;
  const mathChar = maxChar === 20 ? valueToUse.length * 2.3 : valueToUse.length * 1.9;
  const translateY = Math.min(mathChar, widthRotation);

  return (
    <g transform={`translate(${x},${y + 22})`}>
      <line stroke={gray} strokeWidth={1} y1={-22} y2={-15} />
      <Tooltip arrow title={value} placement="top">
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: color,
            fontSize: 10,
            transform: `translate(${0}px, ${translateY}px) rotate(${widthRotation}deg)`,
          }}
        >
          {valueToUse}
        </text>
      </Tooltip>
    </g>
  );
};

const CustomLeftTick: React.FC<CustomTickProps> = ({ x, y, value, color, gray }) => {
  const textRef = useRef<SVGTextElement>(null);
  const [translateX, setTranslateX] = useState(0);

  useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox();
      setTranslateX(bbox.width / 2 - 18);
    }
  }, [value]);

  return (
    <g transform={`translate(${x - 30},${y})`}>
      <Tooltip arrow title={value} placement="top">
        <text
          ref={textRef}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fill: color,
            fontSize: 10,
            transform: `translate(-${translateX}px, 0px)`,
            textAlign: "right",
          }}
        >
          {value}
        </text>
      </Tooltip>
      <line stroke={gray} strokeWidth={1} x1={22} x2={30} y1={0} y2={0} />
    </g>
  );
};
