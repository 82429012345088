import { LineAreaHeatMapScatterPlotData } from "../EditExcellenceChartForms/excellenceChartFormUtils";

const lineChartDefaultData: LineAreaHeatMapScatterPlotData = [
  {
    id: "Machine 1",
    name: "Machine 1",
    data: [
      {
        x: "08:00",
        y: 180,
      },
      {
        x: "09:00",
        y: 182,
      },
      {
        x: "10:00",
        y: 181,
      },
      {
        x: "11:00",
        y: 179,
      },
      {
        x: "12:00",
        y: 180,
      },
      {
        x: "13:00",
        y: 180,
      },
      {
        x: "14:00",
        y: 182,
      },
      {
        x: "15:00",
        y: 180,
      },
      {
        x: "16:00",
        y: 181,
      },
      {
        x: "17:00",
        y: 179,
      },
    ],
  },
  {
    id: "Machine 2",
    name: "Machine 2",
    data: [
      {
        x: "08:00",
        y: 168,
      },
      {
        x: "09:00",
        y: 169,
      },
      {
        x: "10:00",
        y: 170,
      },
      {
        x: "11:00",
        y: 173,
      },
      {
        x: "12:00",
        y: 173,
      },
      {
        x: "13:00",
        y: 175,
      },
      {
        x: "14:00",
        y: 170,
      },
      {
        x: "15:00",
        y: 171,
      },
      {
        x: "16:00",
        y: 169,
      },
      {
        x: "17:00",
        y: 169,
      },
    ],
  },
  {
    id: "Machine 3",
    name: "Machine 3",
    data: [
      {
        x: "08:00",
        y: 160,
      },
      {
        x: "09:00",
        y: 160,
      },
      {
        x: "10:00",
        y: 160,
      },
      {
        x: "11:00",
        y: 161,
      },
      {
        x: "12:00",
        y: 160,
      },
      {
        x: "13:00",
        y: 160,
      },
      {
        x: "14:00",
        y: 161,
      },
      {
        x: "15:00",
        y: 160,
      },
      {
        x: "16:00",
        y: 160,
      },
      {
        x: "17:00",
        y: 159,
      },
    ],
  },
  {
    id: "Machine 4",
    name: "Machine 4",
    data: [
      {
        x: "08:00",
        y: 145,
      },
      {
        x: "09:00",
        y: 146,
      },
      {
        x: "10:00",
        y: 145,
      },
      {
        x: "11:00",
        y: 145,
      },
      {
        x: "12:00",
        y: 145,
      },
      {
        x: "13:00",
        y: 144,
      },
      {
        x: "14:00",
        y: 145,
      },
      {
        x: "15:00",
        y: 145,
      },
      {
        x: "16:00",
        y: 145,
      },
      {
        x: "17:00",
        y: 143,
      },
    ],
  },
];

export default lineChartDefaultData;
