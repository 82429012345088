import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { css } from "@emotion/react";

const cssStyles = (color: string) => ({
  symbolWrapper: css({
    svg: {
      width: "100%",
      height: "100%",
    },
    "svg, g, path": {
      color: `${color} !important`,
      stroke: `${color} !important`,
    },
  }),
});

interface PidSvgSymbolComponentProps extends React.SVGProps<SVGSVGElement> {
  width: number;
  height: number;
  color?: string;
  symbolText?: string;
}

const PidSvgSymbolComponent: React.FC<PidSvgSymbolComponentProps> = ({
  width,
  height,
  color,
  symbolText,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles(color || theme.palette.text.primary) };
  const [svgContent, setSvgContent] = useState<string>("");

  useEffect(() => {
    const fetchSvg = async () => {
      try {
        if (symbolText) {
          const processed = processSVG(symbolText);
          setSvgContent(handleSvgSymbol(processed));
          return;
        }

        const response = await fetch("http://localhost:8000/svg");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        let svgText = await response.text();

        setSvgContent(handleSvgSymbol(svgText));
      } catch (error) {
        console.error("Error fetching SVG:", error);
      }
    };

    fetchSvg();
  }, [symbolText]);

  return svgContent ? (
    <div
      css={styles.symbolWrapper}
      style={{ width, height, stroke: color || theme.palette.common.black }}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  ) : null;
};

export default PidSvgSymbolComponent;

const handleSvgSymbol = (svgText: string) => {
  // Decode any escaped characters in the SVG text
  const formattedSvgText = svgText
    .replace(/\\"/g, '"') // Unescape double quotes
    .replace(/\\n/g, "\n") // Unescape newlines
    .replace(/\\t/g, "\t") // Unescape tabs
    .trim(); // Remove leading and trailing whitespace

  // Remove extra quotes at the beginning and end, if present
  if (svgText.startsWith('"') && svgText.endsWith('"')) {
    svgText = svgText.slice(1, -1);
  }

  return formattedSvgText;
};

const processSVG = (svgContent: string) => {
  // Check if svgContent contains a viewBox
  if (!svgContent.includes("viewBox")) {
    // Extract width and height from the SVG string
    const widthMatch = svgContent.match(/width="(\d+\.?\d*)"/);
    const heightMatch = svgContent.match(/height="(\d+\.?\d*)"/);

    const width = widthMatch ? widthMatch[1] : "100";
    const height = heightMatch ? heightMatch[1] : "100";

    // Insert viewBox and preserveAspectRatio into the SVG tag
    svgContent = svgContent.replace(
      "<svg",
      `<svg viewBox="0 0 ${width} ${height}" preserveAspectRatio="none"`
    );
  }

  return svgContent;
};
