import {
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import { memo, useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import {
  camelCaseToTitleCase,
  handleGetSelectOption,
  titleCaseToCamelCase,
} from "../../../../Global/Utils/commonFunctions";
import Select from "../../../MaterialUI/FormFields/Select";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { TableGridColumnDataTypes, TableGridDateType } from "../tableGridUtils";
import HeightIcon from "@mui/icons-material/Height";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import FilterListIcon from "@mui/icons-material/FilterList";
import WaterIcon from "@mui/icons-material/Water";
import ShuffleIcon from "@mui/icons-material/Shuffle";
import CheckIcon from "@mui/icons-material/Check";
import {
  MobileTableGridDateQuery,
  MobileTableGridFilterMode,
  MobileTableGridSearchQuery,
  mobileTableGridFilterFunctions,
} from "./mobileTableGridFilterFunctions";
import DatePicker from "../../../MaterialUI/DateTimePickers/DatePicker";
import TimePicker from "../../../MaterialUI/DateTimePickers/TimePicker";
import { MobileTableGridFormattedRow } from "./mobileTableGridUtils";

const initialSearchQuery = {
  first: "",
  second: "",
};
const initialDateQuery: MobileTableGridDateQuery = {
  firstDate: null,
  secondDate: null,
  type: "date",
};
const initialColumnSearch = "All columns";
const initialFilterMode = "Fuzzy";

interface MobileTableGridFiltersProps {
  showSearch: boolean;
  formattedRows: MobileTableGridFormattedRow[];
  setFilteredRows: React.Dispatch<React.SetStateAction<MobileTableGridFormattedRow[]>>;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
}

const MobileTableGridFilters: React.FC<MobileTableGridFiltersProps> = ({
  showSearch,
  formattedRows,
  setFilteredRows,
  setPageIndex,
}) => {
  const styles = { ...cssLayoutStyles };
  const [searchQuery, setSearchQuery] =
    useState<MobileTableGridSearchQuery>(initialSearchQuery);
  const [dateQuery, setDateQuery] = useState<MobileTableGridDateQuery>(initialDateQuery);
  const [selectedFilterMode, setSelectedFilterMode] =
    useState<MobileTableGridFilterMode>(initialFilterMode);
  const [selectedSearchColumn, setSelectedSearchColumn] =
    useState<string>(initialColumnSearch);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterModes, setFilterModes] = useState<FilterModesSelect[] | null>(null);
  const [columnType, setColumnType] = useState<TableGridColumnDataTypes | null>(null);

  const columnKeys = handleGetColumnKeys(formattedRows);
  const columnOptions = [
    { value: "All columns", description: "All columns" },
    ...handleGetSelectOption(columnKeys),
  ];
  const open = Boolean(anchorEl);

  /**
   * Handles the filtering of rows when
   * user types on the search field
   */
  useEffect(() => {
    if (showSearch) {
      const colKeys = handleGetColumnKeys(formattedRows);

      const searchableColumns =
        selectedSearchColumn === "All columns" ? colKeys : [selectedSearchColumn];

      const result = mobileTableGridFilterFunctions(
        selectedFilterMode,
        searchableColumns,
        searchQuery,
        formattedRows,
        dateQuery
      );

      setFilteredRows(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showSearch,
    searchQuery,
    formattedRows,
    dateQuery,
    selectedFilterMode,
    selectedSearchColumn,
  ]);

  useEffect(() => {
    setPageIndex(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch, searchQuery, dateQuery, selectedFilterMode, selectedSearchColumn]);

  // When search is toggled, reset filteredMobileRows
  useEffect(() => {
    if (!showSearch) {
      setSearchQuery(initialSearchQuery);
      setDateQuery(initialDateQuery);
      setSelectedFilterMode(initialFilterMode);
      setSelectedSearchColumn(initialColumnSearch);
      setFilterModes(null);
      setAnchorEl(null);
      setColumnType(null);
      // defined in parent
      setFilteredRows(formattedRows);
      setPageIndex(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSearch]);

  const handleSelectSearchColumn = (val: string) => {
    const camelCaseVal = titleCaseToCamelCase(val);
    const colType: TableGridColumnDataTypes =
      val === "All columns"
        ? "string"
        : formattedRows?.[0]?.[camelCaseVal].type || "string";
    setSelectedSearchColumn(val);

    if (val === "All columns") {
      setFilterModes(null);
      setSelectedFilterMode("Fuzzy");
      return;
    }

    setColumnType(colType);
    switch (colType) {
      case "string": {
        setFilterModes(stringFilterModes);
        setSelectedFilterMode("Fuzzy");
        break;
      }
      case "date": {
        setFilterModes(dateAndTimeFilterModes);
        setSelectedFilterMode("Before");
        break;
      }
      case "time": {
        setFilterModes(dateAndTimeFilterModes);
        setSelectedFilterMode("Before");
        break;
      }
      case "dateTime": {
        setFilterModes(dateAndTimeFilterModes);
        setSelectedFilterMode("Before");
        break;
      }
      case "number": {
        setFilterModes(numberFilterModes);
        setSelectedFilterMode("Fuzzy");
        break;
      }
      case "boolean": {
        setFilterModes(booleanFilterModes);
        setSelectedFilterMode("Yes value");
        break;
      }
      default: {
        setFilterModes(stringFilterModes);
        setSelectedFilterMode("Fuzzy");
      }
    }
  };

  return (
    <>
      <Stack spacing={2}>
        <FilterInputFields
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          selectedFilterMode={selectedFilterMode}
          dateQuery={dateQuery}
          setDateQuery={setDateQuery}
          columnType={columnType}
        />
        <Stack spacing={1}>
          <Stack spacing={2} direction="row" alignItems="flex-end">
            <Select
              selectOptions={columnOptions}
              value={selectedSearchColumn}
              onChange={(e) => handleSelectSearchColumn(e.target.value)}
              label="Search column"
            />
            {filterModes ? (
              <IconButton
                aria-label="change filter mode"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              >
                <FilterListIcon color="primary" />
              </IconButton>
            ) : null}
          </Stack>
          <Typography variant="caption" color="textSecondary">
            Filter Mode: {selectedFilterMode}
          </Typography>
        </Stack>
      </Stack>

      <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
        {filterModes?.length ? (
          <Stack css={styles.widthLimit20}>
            {filterModes.map((mode) => (
              <MenuItem
                css={styles.width100}
                key={mode.value}
                onClick={() => {
                  setSelectedFilterMode(mode.value);
                  setAnchorEl(null);
                }}
              >
                <Stack spacing={2} direction="row" alignItems="center">
                  <ListItemIcon>
                    <GetFilterIcon modeVal={mode.value} />
                  </ListItemIcon>
                  <ListItemText>{mode.value}</ListItemText>
                </Stack>
              </MenuItem>
            ))}
          </Stack>
        ) : null}
      </Menu>
    </>
  );
};

export default memo(MobileTableGridFilters);

type FilterModesSelect = {
  value: MobileTableGridFilterMode;
  description: MobileTableGridFilterMode;
};

const stringFilterModes: FilterModesSelect[] = [
  { value: "Fuzzy", description: "Fuzzy" },
  { value: "Contains", description: "Contains" },
  { value: "Starts with", description: "Starts with" },
  { value: "Ends with", description: "Ends with" },
  { value: "Empty", description: "Empty" },
  { value: "Not empty", description: "Not empty" },
];

const numberFilterModes: FilterModesSelect[] = [
  ...stringFilterModes,
  { value: "Equals", description: "Equals" },
  { value: "Not equals", description: "Not equals" },
  { value: "Between", description: "Between" },
  { value: "Between inclusive", description: "Between inclusive" },
  { value: "Greater than", description: "Greater than" },
  { value: "Greater than or equal to", description: "Greater than or equal to" },
  { value: "Less than", description: "Less than" },
  { value: "Less than or equal to", description: "Less than or equal to" },
];

const dateAndTimeFilterModes: FilterModesSelect[] = [
  { value: "Before", description: "Before" },
  { value: "After", description: "After" },
  { value: "Between dates", description: "Between dates" },
];

const booleanFilterModes: FilterModesSelect[] = [
  { value: "Yes value", description: "Yes value" },
  { value: "No value", description: "No value" },
];

const GetFilterIcon: React.FC<{ modeVal: string }> = ({ modeVal }) => {
  switch (modeVal) {
    case "Fuzzy": {
      return <WaterIcon fontSize="small" />;
    }
    case "Contains": {
      return (
        <Typography variant="body1" color="textPrimary">
          *
        </Typography>
      );
    }
    case "Starts with": {
      return (
        <Typography variant="body1" color="textPrimary">
          a
        </Typography>
      );
    }
    case "Ends with": {
      return (
        <Typography variant="body1" color="textPrimary">
          Z
        </Typography>
      );
    }
    case "Empty": {
      return (
        <Typography variant="body1" color="textPrimary">
          ∅
        </Typography>
      );
    }
    case "Not empty": {
      return (
        <Typography variant="body1" color="textPrimary">
          !∅
        </Typography>
      );
    }
    case "Equals": {
      return (
        <Typography variant="body1" color="textPrimary">
          =
        </Typography>
      );
    }
    case "Not equals": {
      return (
        <Typography variant="body1" color="textPrimary">
          ≠
        </Typography>
      );
    }
    case "Between": {
      return <HeightIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />;
    }
    case "Between inclusive": {
      return <ShuffleIcon fontSize="small" />;
    }
    case "Greater than": {
      return (
        <Typography variant="body1" color="textPrimary">
          {">"}
        </Typography>
      );
    }
    case "Greater than or equal to": {
      return (
        <Typography variant="body1" color="textPrimary">
          ≥
        </Typography>
      );
    }
    case "Less than": {
      return (
        <Typography variant="body1" color="textPrimary">
          {"<"}
        </Typography>
      );
    }
    case "Less than or equal to": {
      return (
        <Typography variant="body1" color="textPrimary">
          ≤
        </Typography>
      );
    }
    case "Before": {
      return (
        <VerticalAlignBottomIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
      );
    }
    case "After": {
      return (
        <VerticalAlignBottomIcon sx={{ transform: "rotate(-90deg)" }} fontSize="small" />
      );
    }
    case "Between dates": {
      return <HeightIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />;
    }
    case "Yes value": {
      return <CheckIcon fontSize="small" />;
    }
    case "No value": {
      return <CloseIcon fontSize="small" />;
    }
    default: {
      return null;
    }
  }
};

interface FilterInputFieldsProps {
  searchQuery: MobileTableGridSearchQuery;
  setSearchQuery: React.Dispatch<React.SetStateAction<MobileTableGridSearchQuery>>;
  selectedFilterMode: MobileTableGridFilterMode;
  columnType: TableGridColumnDataTypes | null;
  dateQuery: MobileTableGridDateQuery;
  setDateQuery: React.Dispatch<React.SetStateAction<MobileTableGridDateQuery>>;
}

const FilterInputFields: React.FC<FilterInputFieldsProps> = ({
  searchQuery,
  setSearchQuery,
  selectedFilterMode,
  columnType,
  dateQuery,
  setDateQuery,
}) => {
  const dateTimeType: TableGridDateType | null =
    columnType === "time"
      ? "time"
      : columnType === "date"
      ? "date"
      : columnType === "dateTime"
      ? "dateTime"
      : null;

  switch (selectedFilterMode) {
    case "Between": {
      return (
        <Stack spacing={2} alignItems="center" direction="row">
          <TextFieldType
            value={searchQuery.first}
            numbersOnly={columnType === "number"}
            onChange={(val) => setSearchQuery((prev) => ({ ...prev, first: val }))}
            onClear={() => setSearchQuery((prev) => ({ ...prev, first: "" }))}
          />

          <TextFieldType
            value={searchQuery.second || ""}
            numbersOnly={columnType === "number"}
            onChange={(val) => setSearchQuery((prev) => ({ ...prev, second: val }))}
            onClear={() => setSearchQuery((prev) => ({ ...prev, second: "" }))}
          />
        </Stack>
      );
    }
    case "Between inclusive": {
      return (
        <Stack spacing={2} alignItems="center" direction="row">
          <TextFieldType
            value={searchQuery.first}
            numbersOnly={columnType === "number"}
            onChange={(val) => setSearchQuery((prev) => ({ ...prev, first: val }))}
            onClear={() => setSearchQuery((prev) => ({ ...prev, first: "" }))}
          />

          <TextFieldType
            value={searchQuery.second || ""}
            numbersOnly={columnType === "number"}
            onChange={(val) => setSearchQuery((prev) => ({ ...prev, second: val }))}
            onClear={() => setSearchQuery((prev) => ({ ...prev, second: "" }))}
          />
        </Stack>
      );
    }
    case "Before": {
      if (!dateTimeType) {
        return null;
      }
      return (
        <PickerType
          onChange={(e) =>
            setDateQuery({
              firstDate: e,
              secondDate: null,
              type: dateTimeType,
            })
          }
          value={dateQuery.firstDate}
          type={dateTimeType}
        />
      );
    }
    case "After": {
      if (!dateTimeType) {
        return null;
      }
      return (
        <PickerType
          onChange={(e) =>
            setDateQuery({
              firstDate: e,
              secondDate: null,
              type: dateTimeType,
            })
          }
          value={dateQuery.firstDate}
          type={dateTimeType}
        />
      );
    }
    case "Between dates": {
      if (!dateTimeType) {
        return null;
      }
      return (
        <Stack spacing={2} alignItems="center" direction="row">
          <PickerType
            onChange={(e) =>
              setDateQuery({
                firstDate: e,
                secondDate: null,
                type: dateTimeType,
              })
            }
            value={dateQuery.firstDate}
            type={dateTimeType}
          />

          <PickerType
            onChange={(e) =>
              setDateQuery({
                ...dateQuery,
                secondDate: e,
              })
            }
            value={dateQuery.secondDate}
            minDate={dateQuery.firstDate || undefined}
            type={dateTimeType}
          />
        </Stack>
      );
    }
    case "Yes value": {
      return (
        <Stack spacing={2} alignItems="center" direction="row">
          <CheckIcon />
          <Typography variant="body1">Results with a checkmark</Typography>
        </Stack>
      );
    }
    case "No value": {
      return (
        <Stack spacing={2} alignItems="center" direction="row">
          <CloseIcon />
          <Typography variant="body1">Results without a checkmark</Typography>
        </Stack>
      );
    }
    default: {
      return (
        <TextFieldType
          value={searchQuery.first}
          numbersOnly={columnType === "number"}
          onChange={(val) => setSearchQuery(() => ({ first: val }))}
          onClear={() => setSearchQuery(() => ({ first: "" }))}
        />
      );
    }
  }
};
interface TextFieldTypeProps {
  value: string;
  numbersOnly: boolean;
  onChange: (value: string) => void;
  onClear: () => void;
}
const TextFieldType: React.FC<TextFieldTypeProps> = ({
  value,
  numbersOnly,
  onChange,
  onClear,
}) => {
  return (
    <TextField
      value={value}
      onChange={(e) => onChange(e.target.value)}
      label="Search"
      numberField={numbersOnly}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClear} aria-label="clear search field" size="small">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

interface PickerTypeProps {
  type: TableGridDateType;
  value: Date | null;
  onChange: (value: Date | null) => void;
  minDate?: Date | undefined;
}

const PickerType: React.FC<PickerTypeProps> = ({ type, value, onChange, minDate }) => {
  switch (type) {
    case "date": {
      return <DatePicker onChange={onChange} value={value} minDate={minDate} />;
    }
    case "dateTime": {
      return null;
    }
    case "time": {
      return <TimePicker onChange={onChange} value={value} minTime={minDate} />;
    }
    default: {
      return null;
    }
  }
};

const handleGetColumnKeys = (formattedRows: MobileTableGridFormattedRow[]): string[] => {
  return Object.keys(formattedRows?.[0] || {}).map((key) => camelCaseToTitleCase(key));
};
