import { Query } from "../callApi";
import {
  PostQueryBoschProcessCreateInput,
  PostQueryBoschWorkflowCreateInput,
  PutQueryBoschProcessInput,
  PutQueryBoschWorkflowInput,
} from "./apiBoschWorkflowsInputs";

export const postQueryBoschWorkflowCreate = (
  input: PostQueryBoschWorkflowCreateInput
): Query => ({
  endpoint: "workflows/workflow",
  method: "POST",
  variables: input,
});

export const putQueryBoschWorkflow = (
  input: PutQueryBoschWorkflowInput,
  id: string
): Query => ({
  endpoint: `workflow/${id}`,
  method: "PUT",
  variables: input,
});

export const deleteQueryBoschWorkflow = (id: string): Query => ({
  endpoint: `workflow/${id}`,
  method: "DELETE",
});

export const postQueryBoschProcessCreate = (
  input: PostQueryBoschProcessCreateInput
): Query => ({
  endpoint: "process",
  method: "POST",
  variables: input,
});

export const putQueryBoschProcess = (
  input: PutQueryBoschProcessInput,
  id: string
): Query => ({
  endpoint: `process/${id}`,
  method: "PUT",
  variables: input,
});

export const deleteQueryBoschProcess = (id: string): Query => ({
  endpoint: `process/${id}`,
  method: "DELETE",
});
