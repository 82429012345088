import { useEffect, useState } from "react";
import {
  GetQueryMaintenanceMachinesSnippet,
  PostQueryCreateMachineTaskSnippet,
  PostQueryCreateTaskTemplateSnippet,
} from "../../../../../Api/Maintenance/apiMaintenanceSnippets";
import { FormStatuses, SelectOption } from "../../../../../Global/Types/commonTypes";
import MultiStageForm from "../../../../SmallComponents/MultiStageForm/MultiStageForm";
import { Box, Grid, InputAdornment, Stack, Typography } from "@mui/material";
import Select from "../../../../MaterialUI/FormFields/Select";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import { handleGetSelectOption } from "../../../../../Global/Utils/commonFunctions";
import DateAndTimePicker from "../../../../MaterialUI/DateTimePickers/DateAndTimePicker";
import { differenceInMinutes, isBefore, isValid } from "date-fns";
import callApi from "../../../../../Api/callApi";
import {
  postQueryCreateTask,
  postQueryCreateTaskTemplate,
} from "../../../../../Api/Maintenance/apiMaintenancePostQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";
import {
  PostQueryCreateTaskInput,
  PostQueryCreateTaskTemplateInput,
} from "../../../../../Api/Maintenance/apiMaintenanceInputs";
import {
  MachineTaskStatus,
  MachineTaskTemplatePeriod,
} from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import {
  MaintenanceMachineTaskType,
  MaintenancePlanAddEditFormResult,
  maintenanceMachineTaskTypeOptions,
} from "../maintenancePlanUtils";
import { useDetectFormsUnsavedChanges } from "../../../../../Global/Hooks/useDetectFormsUnsavedChanges";

const FORM_STAGES = ["Select Task Type", "Task Configuration"];
const statusOptions: SelectOption[] = [
  {
    value: "Open",
    description: "Open",
  },
  {
    value: "InProgress",
    description: "In Progress",
  },
];

type FormValues = {
  machine: string;
  task_description: string;
  start_date: Date | null;
  end_date: Date | null;
  status: MachineTaskStatus | "";
  repetition_period: MachineTaskTemplatePeriod | "";
  planned_duration: number;
};

const initialValues: FormValues = {
  machine: "",
  task_description: "",
  start_date: null,
  end_date: null,
  status: "",
  repetition_period: "",
  planned_duration: 0,
};

interface MaintenanceTableAddFormProps {
  machines: GetQueryMaintenanceMachinesSnippet;
  handleOnCreate: (value: MaintenancePlanAddEditFormResult) => Promise<void>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const MaintenancePlanAddForm: React.FC<MaintenanceTableAddFormProps> = ({
  machines,
  handleOnCreate,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);

  const [taskType, setTaskType] = useState<MaintenanceMachineTaskType | "">("");
  const [formInputs, setFormInputs] = useState<FormValues>(initialValues);
  const { setAuthedUser } = useAuthedContext();

  useDetectFormsUnsavedChanges(initialValues, formInputs, handleSetUnsavedChanges);

  useEffect(() => {
    return () => {
      setTaskType("");
      setFormInputs(initialValues);
    };
  }, []);

  const handleChangeField = (key: keyof FormValues, value: any) => {
    setFormInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleOnNextStage = async () => {
    try {
      setAlertStatus("warning");
      setAlertMessage(null);

      if (activeStep === 0) {
        if (!taskType) {
          setAlertStatus("warning");
          setAlertMessage("You must select a task type");
          return false;
        }
        setFormInputs(initialValues);
        return true;
      }

      if (activeStep === 1) {
        const alert = formInputAlert(taskType === "task", formInputs);
        if (alert) {
          setAlertStatus("warning");
          setAlertMessage(alert);
          return false;
        }

        const machineDocumentId = machines.find(
          (machine) => machine.machine === formInputs.machine
        )?.id;

        if (taskType === "task") {
          const input = {
            ...formInputs,
            planned_duration: differenceInMinutes(
              formInputs.end_date!,
              formInputs.start_date!
            ),
          } as PostQueryCreateTaskInput;

          const newSingleTask = await callApi<PostQueryCreateMachineTaskSnippet>({
            query: postQueryCreateTask(machineDocumentId!, input),
            auth: { setAuthedUser },
          });

          await handleOnCreate({
            task: newSingleTask,
          });
        } else {
          const input: PostQueryCreateTaskTemplateInput = {
            ...(formInputs as PostQueryCreateTaskTemplateInput),
          };
          const newTemplate = await callApi<PostQueryCreateTaskTemplateSnippet>({
            query: postQueryCreateTaskTemplate(machineDocumentId!, input),
            auth: { setAuthedUser },
          });

          await handleOnCreate({
            template: newTemplate,
          });
        }
        setAlertStatus("success");
        setUnsavedChanges(false);
        setAlertMessage("Successfully added");
      }

      return false;
    } catch (err) {
      console.log("handleOnNextStage err", err);
      setAlertStatus("error");
      setAlertMessage("Something went wrong");
      return false;
    }
  };

  return (
    <MultiStageForm
      steps={FORM_STAGES}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      mobile
      title=""
      lastNextButtonLabel={taskType === "task" ? "Create Task" : "Create Task Template"}
      disableNextButton={alertStatus === "success"}
      disablePrevButton={alertStatus === "success"}
    >
      {activeStep === 0 ? (
        <Stack spacing={3}>
          <Select
            label="Type"
            value={taskType}
            onChange={(e) => setTaskType(e.target.value as MaintenanceMachineTaskType)}
            selectOptions={maintenanceMachineTaskTypeOptions}
          />

          <Stack spacing={1}>
            <Typography variant="h4">Repetitive Task Template: </Typography>
            <Typography variant="body1">
              Set the start and Planned end Dates, along with repetitive period such as
              daily, weekly, monthly, or yearly. Once these parameters are defined, the
              system automatically generates tasks to fit the specified pattern.
            </Typography>
          </Stack>
        </Stack>
      ) : null}

      {activeStep === 1 ? (
        <Box component="div">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Task Description"
                value={formInputs.task_description}
                onChange={(e) => handleChangeField("task_description", e.target.value)}
                multiline
                minRows={2}
                maxRows={5}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                fullWidth
                selectOptions={handleGetSelectOption(
                  machines.map((machine) => machine.machine)
                )}
                label="Machine"
                value={formInputs.machine}
                onChange={(e) => handleChangeField("machine", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                label="Planned Start Date"
                value={formInputs.start_date}
                onChange={(val) => handleChangeField("start_date", val)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateAndTimePicker
                label="Planned End Date"
                value={formInputs.end_date}
                minDate={formInputs.start_date || undefined}
                onChange={(val) => handleChangeField("end_date", val)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {taskType === "task" ? (
                <Select
                  selectOptions={statusOptions}
                  label="Status"
                  value={formInputs.status}
                  onChange={(e) => handleChangeField("status", e.target.value)}
                />
              ) : (
                <Select
                  selectOptions={handleGetSelectOption(
                    ["daily", "weekly", "monthly", "yearly"],
                    true
                  )}
                  label="Repetitive Period"
                  value={formInputs.repetition_period}
                  onChange={(e) => handleChangeField("repetition_period", e.target.value)}
                />
              )}
            </Grid>
            {taskType === "task" ? null : (
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Tasks Duration"
                  value={formInputs.planned_duration}
                  onChange={(e) => handleChangeField("planned_duration", e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">minutes</InputAdornment>
                    ),
                  }}
                  numberField
                />
              </Grid>
            )}
          </Grid>
        </Box>
      ) : null}
    </MultiStageForm>
  );
};

export default MaintenancePlanAddForm;

const formInputAlert = (isSingleTask: boolean, formInputs: FormValues): string | null => {
  if (!formInputs.machine) {
    return "You must select a machine";
  }
  if (!formInputs.task_description) {
    return "You must specify a task description";
  }
  if (!formInputs.start_date) {
    return "You must specify a Planned start Date";
  }
  if (isSingleTask && !isValid(formInputs.start_date)) {
    return "Planned Start Date must be valid date";
  }
  if (isSingleTask && !formInputs.end_date) {
    return "You must specify a Planned end Date";
  }
  if (formInputs.start_date && formInputs.end_date) {
    if (isValid(formInputs.start_date) && isValid(formInputs.end_date)) {
      if (isBefore(formInputs.end_date, formInputs.start_date)) {
        return "Planned Start Date cannot be before the Planned end Date";
      }
    } else {
      return "Start and Planned end Dates must be valid dates";
    }
  }

  if (isSingleTask && !formInputs.status) {
    return "You must select a task status";
  }
  if (!isSingleTask && !formInputs.repetition_period) {
    return "You must select a repetitive period";
  }
  return null;
};
