import { Box, Stack, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../Components/MaterialUI/LabelWithBoldedPart";
import { formatDateAndTime } from "../../../../Global/Utils/commonFunctions";
import { KanbanTaskTemplate } from "../taskManagerUtils";

interface ViewKanbanTemplateProps {
  template: KanbanTaskTemplate;
}

const ViewKanbanTemplate: React.FC<ViewKanbanTemplateProps> = ({ template }) => {
  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text="ID" bolded={template.id} />
        <LabelWithBoldedPart text="Name" bolded={template.name} />
        <LabelWithBoldedPart text="Version" bolded={template.version} />
        <LabelWithBoldedPart
          text="Created At"
          bolded={formatDateAndTime(template.created_at)}
        />
        <LabelWithBoldedPart
          text="Updated At"
          bolded={formatDateAndTime(template.updated_at)}
        />
        <LabelWithBoldedPart text="Description" bolded={template.description} />
      </Stack>

      <Typography variant="h3" mb={1}>
        Steps:
      </Typography>
      <div dangerouslySetInnerHTML={{ __html: template.steps }} />
    </Box>
  );
};

export default ViewKanbanTemplate;
