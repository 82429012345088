import { object } from "yup";
import {
  YUP_EMAIL,
  YUP_PASSWORD_STRING,
  YUP_REQUIRED_STRING,
  YUP_STRING,
} from "../Global/Constants/yupConstants";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../Global/Styles/layout";
import cssSpacingStyles from "../Global/Styles/spacing";
import { useState } from "react";
import { FormStatuses } from "../Global/Types/commonTypes";
import { Formik } from "formik";
import { Stack, Typography, Box } from "@mui/material";
import TextField from "../Components/MaterialUI/FormFields/TextFields";
import Button from "../Components/MaterialUI/Button";
import Alert from "../Components/MaterialUI/Alert";
import { Link } from "react-router-dom";
import Checkbox from "../Components/MaterialUI/FormFields/Checkbox";
import { PostQueryUsersCreateInput } from "../Api/Auth/apiAuthInputs";
import { postQueryUsersCreate } from "../Api/Auth/apiAuthPostQueries";
import callApi from "../Api/callApi";
import AuthPagesLayout from "../Components/AuthPageComponents/AuthPageLayout";
import cssFontStyles from "../Global/Styles/font";

const fieldValidation = object({
  firstName: YUP_REQUIRED_STRING,
  lastName: YUP_REQUIRED_STRING,
  title: YUP_REQUIRED_STRING,
  position: YUP_REQUIRED_STRING,
  email: YUP_EMAIL,
  username: YUP_STRING,
  companyName: YUP_REQUIRED_STRING,
  password: YUP_PASSWORD_STRING,
});

type RegisterFormValues = {
  firstName: string;
  lastName: string;
  title: string;
  position: string;
  email: string;
  companyName: string;
  password: string;
  agreedToNews: boolean;
};

const initialValues: RegisterFormValues = {
  firstName: "",
  lastName: "",
  title: "",
  position: "",
  email: "",
  companyName: "",
  password: "",
  agreedToNews: false,
};

const Register: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
  };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const handleFormSubmit = async (values: RegisterFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage("Loading...");

      const input: PostQueryUsersCreateInput = {
        first_name: values.firstName,
        last_name: values.lastName,
        title: values.title,
        position: values.position,
        email: values.email,
        username: values.email,
        company_name: values.companyName,
        password: values.password,
        agreed_to_news: values.agreedToNews,
      };
      await callApi({ query: postQueryUsersCreate(input), auth: null });

      setFormStatus("success");
      setAlertMessage("Email successfully sent! Please verify your email!");
    } catch (err) {
      console.log("Register form, handleFormSubmit() err: ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => (
        <form css={styles.marginHorizontalAuto} onSubmit={handleSubmit}>
          <AuthPagesLayout>
            <Typography align="center" mb={2} variant="h1">
              Sign up
            </Typography>

            <TextField
              name="firstName"
              label="First name"
              error={touched["firstName"] && !!errors["firstName"]}
              helperText={touched["firstName"] && errors["firstName"]}
              onChange={handleChange}
              value={values.firstName}
            />

            <TextField
              name="lastName"
              label="Last name"
              error={touched["lastName"] && !!errors["lastName"]}
              helperText={touched["lastName"] && errors["lastName"]}
              onChange={handleChange}
              value={values.lastName}
            />

            <TextField
              name="title"
              label="Title"
              error={touched["title"] && !!errors["title"]}
              helperText={touched["title"] && errors["title"]}
              onChange={handleChange}
              value={values.title}
            />

            <TextField
              name="position"
              label="Position / role"
              error={touched["position"] && !!errors["position"]}
              helperText={touched["position"] && errors["position"]}
              onChange={handleChange}
              value={values.position}
            />

            <TextField
              name="companyName"
              label="Company name"
              error={touched["companyName"] && !!errors["companyName"]}
              helperText={touched["companyName"] && errors["companyName"]}
              onChange={handleChange}
              value={values.companyName}
            />

            <Stack spacing={2}>
              <TextField
                name="email"
                label="Email"
                error={touched["email"] && !!errors["email"]}
                helperText={touched["email"] && errors["email"]}
                onChange={handleChange}
                value={values.email}
              />
              <TextField
                name="password"
                label="Password"
                type={"password"}
                error={touched["password"] && !!errors["password"]}
                helperText={touched["password"] && errors["password"]}
                onChange={handleChange}
                value={values.password}
              />

              <Checkbox
                label={
                  <Typography variant="body1">
                    I agree with the{" "}
                    <Typography
                      style={{ color: theme.palette.primary.main }}
                      variant="body1"
                      component={Link}
                      to="/terms-and-conditions"
                    >
                      Terms & Conditions.
                    </Typography>
                  </Typography>
                }
              />
              <Checkbox
                label="I agree to receive inspiration, marketing promotions and updates via email."
                value={values.agreedToNews}
                onChange={handleChange}
                name="agreedToNews"
              />
              <Box
                component="div"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  css={[styles.width100, styles.widthLimit10]}
                  type="submit"
                  loading={formStatus === "loading"}
                >
                  Sign up
                </Button>
              </Box>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
              <Stack direction="column" alignItems="center">
                <Link
                  to="/"
                  css={styles.boldText}
                  style={{ color: theme.palette.primary.main }}
                >
                  Already have an account? Sign in
                </Link>
              </Stack>
            </Stack>
          </AuthPagesLayout>
        </form>
      )}
    </Formik>
  );
};

export default Register;
