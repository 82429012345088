import { Box, Stack, Typography, useTheme } from "@mui/material";
import KanbanBoard from "../../../Components/SmallComponents/KanbanBoard/KanbanBoard";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Checkbox from "../../../Components/MaterialUI/FormFields/Checkbox";
import { useEffect, useState } from "react";
import { KanbanTask } from "../../TaskManager/Components/taskManagerUtils";
import { useAuthedContext } from "../../../context/AuthContext";
import callApi from "../../../Api/callApi";
import { KanbanBoardColumn } from "../../../Components/SmallComponents/KanbanBoard/kanbanBoardUtils";
import {
  getQueryKanbanTasks,
  getQueryKanbanWorkflowBoardColumns,
  GetQueryKanbanWorkflowBoardColumnsSnippet,
  getQueryKanbanWorkflows,
  GetQueryKanbanWorkflowsSnippet,
} from "../api/queries";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import Select from "../../../Components/MaterialUI/FormFields/Select";
import {
  postQueryKanbanTaskCheckMove,
  PostQueryKanbanTaskCheckMoveSnippet,
} from "../api/mutations";

const KanBanBoardPage: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [filterCompleted, setFilterCompleted] = useState<boolean>(true);
  const [tasks, setTasks] = useState<KanbanTask[]>([]);
  const [workflowOptions, setWorkflowOptions] = useState<SelectOption[]>([]);
  const [displayedTasks, setDisplayedTasks] = useState<KanbanTask[]>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string>("");
  const [columns, setColumns] = useState<KanbanBoardColumn[]>([]);

  const [workflowChangeStatus, setWorkflowChangeStatus] = useState<FormStatuses>(null);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      await handleFetchTasks();
      await handleFetchWorkflows();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const workflowTasks = tasks.filter(
      (item) => item.workflow_id === selectedWorkflow && item.status === "active"
    );
    if (filterCompleted) {
      setDisplayedTasks(() => workflowTasks.filter((item) => item.status !== "finished"));
    } else {
      setDisplayedTasks(workflowTasks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCompleted, tasks, selectedWorkflow]);

  const handleFetchTasks = async () => {
    try {
      const data = await callApi<KanbanTask[]>({
        query: getQueryKanbanTasks,
        auth: { setAuthedUser },
      });

      setTasks(data);
    } catch (err) {
      console.log("err", err);
    }
  };
  const handleFetchWorkflows = async () => {
    try {
      const res = await callApi<GetQueryKanbanWorkflowsSnippet>({
        query: getQueryKanbanWorkflows(),
        auth: { setAuthedUser },
      });
      const options: SelectOption[] = res.map((item) => ({
        value: item.id,
        description: item.data.name,
      }));
      setWorkflowOptions(options);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleChangeColumns = async (id: string) => {
    try {
      setWorkflowChangeStatus("loading");
      const data = await callApi<GetQueryKanbanWorkflowBoardColumnsSnippet>({
        query: getQueryKanbanWorkflowBoardColumns(id),
        auth: { setAuthedUser },
      });
      const cols: KanbanBoardColumn[] = data
        .slice()
        .sort((a, b) => a.order - b.order)
        .map((item) => ({
          id: item.node_id,
          title: item.node_name,
        }));

      setSelectedWorkflow(id);
      setColumns(cols);
      setWorkflowChangeStatus(null);
    } catch (err) {
      console.log("err", err);
      setWorkflowChangeStatus("error");
    }
  };

  const handleUpdateTask = async (updatedTask: KanbanTask) => {
    try {
      console.log("updatedTask ", updatedTask);
    } catch (err) {
      console.log(err);
    }
  };

  const handleCheckTaskMove = async (taskID: string, nodeID: string) => {
    try {
      const checkResult = await callApi<PostQueryKanbanTaskCheckMoveSnippet>({
        query: postQueryKanbanTaskCheckMove(taskID, nodeID),
        auth: { setAuthedUser },
      });

      return checkResult;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box component="div" css={[styles.width100]}>
      <Typography
        css={styles.contentBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        Kanban Board
      </Typography>

      <Stack
        mb={2}
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="row"
      >
        <Select
          css={styles.widthLimit20}
          label="Workflow"
          value={selectedWorkflow}
          selectOptions={workflowOptions}
          onChange={(e) => handleChangeColumns(e.target.value)}
          disabled={workflowChangeStatus === "loading"}
        />

        <Box component="div" style={{ display: "none" }}>
          <Checkbox
            label="Filter out finished tasks"
            value={filterCompleted}
            checked={filterCompleted}
            onChange={() => setFilterCompleted((prev) => !prev)}
            disabled={workflowChangeStatus === "loading"}
          />
        </Box>
      </Stack>

      <KanbanBoard
        tasks={displayedTasks}
        handleUpdateTask={handleUpdateTask}
        refetchTasks={handleFetchTasks}
        columns={columns}
        setColumns={setColumns}
        disableColDrag
        disableColNameChange
        handleCheckTaskMove={handleCheckTaskMove}
      />
    </Box>
  );
};

export default KanBanBoardPage;
