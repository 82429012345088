import { SelectOption } from "../../../../Global/Types/commonTypes";
import { Node, XYPosition } from "reactflow";
import {
  DEFAULT_NODE_HEIGHT,
  DEFAULT_NODE_WIDTH,
} from "../../../SmallComponents/ReactFlow/FlowNodes/flowNodesTypes";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";

export type BoschNewNodeModal = {
  position: XYPosition;
  id: string;
} | null;

// ---------------- ALL NODE TYPES ----------------=
export enum BOSCH_TASK_NODE_TYPE {
  MaterialPicking = "Material Picking",
  Bna = "BNA",
  Inspection = "Inspection",
  Shipping = "Shipping",
  Milling = "Milling",
  Cutting = "Cutting",
  Tcc = "TCC",
}
const nodeLabels: Record<keyof typeof BOSCH_TASK_NODE_TYPE, string> = {
  MaterialPicking: "Material Picking",
  Bna: "BNA",
  Inspection: "Inspection",
  Shipping: "Shipping",
  Milling: "Milling",
  Cutting: "Cutting",
  Tcc: "TCC",
};

export const BOSCH_NODES_OPTIONS: SelectOption[] = Object.entries(
  BOSCH_TASK_NODE_TYPE
).map(([key]) => ({
  value: nodeLabels[key as keyof typeof BOSCH_TASK_NODE_TYPE],
  description: nodeLabels[key as keyof typeof BOSCH_TASK_NODE_TYPE],
}));

export type BoschAllTaskNodesData = {
  [BOSCH_TASK_NODE_TYPE.MaterialPicking]?: BoschMaterialPickingNodeData;
  [BOSCH_TASK_NODE_TYPE.Bna]?: BoschBnaNodeData;
  [BOSCH_TASK_NODE_TYPE.Inspection]?: BoschInspectionNodeData;
  [BOSCH_TASK_NODE_TYPE.Shipping]?: BoschShippingNodeData;
  [BOSCH_TASK_NODE_TYPE.Milling]?: BoschMillingNodeData;
  [BOSCH_TASK_NODE_TYPE.Cutting]?: BoschCuttingNodeData;
  [BOSCH_TASK_NODE_TYPE.Tcc]?: BoschTccNodeData;
};

// ---------------- NODE TYPES ----------------
export type BoschNodeCommonData = {
  id: string;
  type: string;
  name: string;
  createdOn: string;
  updatedOn: string;
  //
  duration: string;
  timeUnit: string;
  cycles: string;
  plannedStartDate: string | undefined;
  plannedEndDate: string | undefined;
  committedStartDate: string | undefined;
  committedEndDate: string | undefined;
  realStartDate: string | undefined;
  realEndDate: string | undefined;
  //
  machineType: string;
  machineAllocation: string;
  machine: string | undefined;
  hrRole: string;
  hrQuantity: string;
  hrAllocation: string;
  humanResources: string | undefined;
  materialsAllocation: string;
  materials: {
    material: string;
    quantity: string;
  }[];
  //
  preConditions: {
    key: string;
    value: string;
  }[];
  postConditions: {
    key: string;
    value: string;
  }[];
};

// INITIAL
export type BoschInitialNodeData = {
  id: string;
  name: string;
  createdOn: string;
  updatedOn: string;
};
export type BoschInitialNodeType = Node & {
  data: BoschInitialNodeData;
};

// BUG
export type BoschBugNodeData = BoschNodeCommonData;
export type BoschBugNodeType = Node & {
  data: BoschBugNodeData;
};
// Material
export type BoschMaterialPickingNodeData = BoschNodeCommonData;
export type BoschMaterialPickingNodeType = Node & {
  data: BoschMaterialPickingNodeData;
};
// Bna
export type BoschBnaNodeData = BoschNodeCommonData;
export type BoschBnaNodeType = Node & {
  data: BoschBugNodeData;
};
// Inspection
export type BoschInspectionNodeData = BoschNodeCommonData;
export type BoschInspectionNodeType = Node & {
  data: BoschBugNodeData;
};
// Shipping
export type BoschShippingNodeData = BoschNodeCommonData;
export type BoschShippingNodeType = Node & {
  data: BoschBugNodeData;
};
// Milling
export type BoschMillingNodeData = BoschNodeCommonData;
export type BoschMillingNodeType = Node & {
  data: BoschBugNodeData;
};
// Cutting
export type BoschCuttingNodeData = BoschNodeCommonData;
export type BoschCuttingNodeType = Node & {
  data: BoschBugNodeData;
};
// TCC
export type BoschTccNodeData = BoschNodeCommonData;
export type BoschTccNodeType = Node & {
  data: BoschBugNodeData;
};

// ---------------- NODE CONFIGS ----------------
const BOSCH_CORE_NODE_CONFIG = {
  style: {
    background: "#fff",
    color: "#000",
    fontSize: 12,
    borderRadius: 15,
    width: DEFAULT_NODE_WIDTH,
    cursor: "default",
    height: DEFAULT_NODE_HEIGHT,
    padding: 7,
  },
  dragHandle: ".custom-drag-handle",
};
export const BOSCH_INITIAL_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  style: {
    ...BOSCH_CORE_NODE_CONFIG.style,
    border: "3px solid #30AFB8",
  },
  type: "initial",
};
export const BOSCH_MATERIAL_PICKING_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  type: BOSCH_TASK_NODE_TYPE.MaterialPicking,
};
export const BOSCH_BNA_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  type: BOSCH_TASK_NODE_TYPE.Bna,
};
export const BOSCH_INSPECTION_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  type: BOSCH_TASK_NODE_TYPE.Inspection,
};
export const BOSCH_SHIPPING_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  type: BOSCH_TASK_NODE_TYPE.Shipping,
};
export const BOSCH_MILLING_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  type: BOSCH_TASK_NODE_TYPE.Milling,
};
export const BOSCH_CUTTING_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  type: BOSCH_TASK_NODE_TYPE.Cutting,
};
export const BOSCH_TCC_NODE_CONFIG = {
  ...BOSCH_CORE_NODE_CONFIG,
  type: BOSCH_TASK_NODE_TYPE.Tcc,
};

type timeUnitScheme = "hours" | "minutes" | "seconds";
const timeUnitArray: timeUnitScheme[] = ["hours", "minutes", "seconds"];
export const timeUnitSchemeOptions: SelectOption[] = handleGetSelectOption(
  timeUnitArray,
  true
);
