import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { CalendarWidgetData } from "./calendarWidgetTypes";
import { ResponsiveCalendar } from "@nivo/calendar";
import { getChartColors } from "../../nivoTheme";

interface CalendarWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: CalendarWidgetData;
  isStatic?: boolean;
}

const CalendarWidget: React.FC<CalendarWidgetProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 0 : 40,
    right: isStatic ? 5 : 40,
    bottom: isStatic ? 0 : 40,
    left: isStatic ? 5 : 40,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveCalendar
        data={data}
        from="2015-03-01"
        to="2016-07-12"
        emptyColor="#eeeeee"
        colors={getChartColors()}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        margin={chartMargin}
        yearSpacing={40}
        monthBorderColor="#ffffff"
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        isInteractive={isStatic ? false : true}
        legends={
          isStatic
            ? undefined
            : [
                {
                  anchor: "bottom-right",
                  direction: "row",
                  translateY: 36,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: "right-to-left",
                },
              ]
        }
      />
    </Box>
  );
};

export default CalendarWidget;
