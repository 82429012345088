import { SerializedStyles } from "@emotion/react";
import { Box, Stack, useTheme } from "@mui/material";
import { ResponsiveRadar } from "@nivo/radar";
import { RadarChartConfiguration } from "./radarChartTypes";
import { BarColumnRadarData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { getChartColors } from "../nivoTheme";
import { BarChartDataSchema } from "../BarChart/barChartTypes";
import { formatNumber } from "../../../Global/Utils/commonFunctions";
import { getBarKeysAndLegend } from "../excellenceUtils";
import { BarLegend } from "../ExcellenceSmallComponents";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import { memo, useRef } from "react";

interface RadarChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: RadarChartConfiguration;
  data: BarColumnRadarData;
  isStatic?: boolean;
  indexBy?: string;
  noAnimation?: boolean;
  schema: BarChartDataSchema | null;
  paramMapping?: Record<string, string>;
  height?: number;
}

const RadarChart: React.FC<RadarChartProps> = ({
  className,
  data,
  configuration,
  isStatic,
  indexBy,
  height,
}) => {
  const theme = useTheme();
  const { enableDotLabel, enableDots, interpolate } = configuration;
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width: chartWidth } = useContainerDimensions(chartContainerRef);
  const legendWidth = Math.max(chartWidth * 0.1, 100);

  const chartMargin = {
    top: isStatic ? 5 : 60,
    right: isStatic ? 5 : 25,
    bottom: isStatic ? 5 : 30,
    left: isStatic ? 5 : 25,
  };

  // const dynamicData = downSampleGenericArrayData(data || [], Math.round(chartWidth / 30));
  const keysAndLegend = getBarKeysAndLegend(data[0], getChartColors(), indexBy);

  return (
    <Box className={className} ref={chartContainerRef} component="div">
      <Stack
        style={{ width: "100%", height: height ? `${height}px` : "100%" }}
        direction="row"
        spacing={0}
      >
        <Box
          component="div"
          style={{
            width: isStatic ? "100%" : `calc(${chartWidth}px - ${legendWidth}px)`,
            height: "100%",
          }}
        >
          <ResponsiveRadar
            data={data}
            keys={keysAndLegend.formattedKeys.keys}
            indexBy={keysAndLegend.formattedKeys.indexBy}
            colors={keysAndLegend.colors}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            enableDotLabel={isStatic ? false : enableDotLabel}
            isInteractive={isStatic ? false : true}
            enableDots={enableDots}
            curve={interpolate}
            valueFormat=">-.2f"
            margin={chartMargin}
            gridLabelOffset={25}
            dotSize={10}
            dotColor={{ theme: "background" }}
            dotBorderWidth={2}
            blendMode={theme.palette.mode === "light" ? "multiply" : undefined}
            dotLabel={(d) => {
              try {
                const result = formatNumber(
                  d.value,
                  configuration.fixedDecimalValue,
                  configuration.decimalPoints
                );
                if (!result) {
                  return `${d.value}`;
                }
                return `${result}`;
              } catch (err) {
                return d.value;
              }
            }}
            motionConfig="wobbly"
            legends={undefined}
          />
        </Box>

        {isStatic ? null : <BarLegend data={keysAndLegend} width={legendWidth} />}
      </Stack>
    </Box>
  );
};

export default memo(RadarChart);
