import { SerializedStyles } from "@emotion/react";
import { LineAreaHeatMapScatterPlotData } from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import ScatterPlot from "../../../../ExcellenceWidgets/AdvancedWidgets/ScatterPlot/ScatterPlot";
import scatterPlotDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/scatterPlotDefaultData";

interface ExcellenceScatterPlotProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: LineAreaHeatMapScatterPlotData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceScatterPlot: React.FC<ExcellenceScatterPlotProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <ScatterPlot
      className={className}
      configuration={configuration}
      data={data || scatterPlotDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceScatterPlot;
