import { addDays, subDays } from "date-fns";
import { LineAreaHeatMapScatterPlotData } from "../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import { PredictiveMeintenanceStatisticsType } from "./predictiveMeintenanceUtils";

const formatDate = (date: Date): string => {
  return date.toISOString().split(".")[0] + ".000";
};

const today = new Date();

export const predictiveMeintenanceChartData: LineAreaHeatMapScatterPlotData = [
  {
    id: "prediction",
    name: "Prediction",
    data: [
      { x: formatDate(subDays(today, 4)), y: 0 },
      { x: formatDate(subDays(today, 3)), y: 23 },
      { x: formatDate(subDays(today, 2)), y: 35 },
      { x: formatDate(subDays(today, 1)), y: 43 },
      { x: formatDate(today), y: 54 },
      { x: formatDate(addDays(today, 1)), y: 68 },
      { x: formatDate(addDays(today, 2)), y: 82 },
      { x: formatDate(addDays(today, 3)), y: 96 },
      { x: formatDate(addDays(today, 4)), y: 110 },
      { x: formatDate(addDays(today, 5)), y: 124 },
      { x: formatDate(addDays(today, 6)), y: 136 },
      { x: formatDate(addDays(today, 7)), y: 150 },
      { x: formatDate(addDays(today, 8)), y: 164 },
      { x: formatDate(addDays(today, 9)), y: 178 },
      { x: formatDate(addDays(today, 10)), y: 190 },
      { x: formatDate(addDays(today, 11)), y: 204 },
      { x: formatDate(addDays(today, 12)), y: 218 },
      { x: formatDate(addDays(today, 13)), y: 232 },
      { x: formatDate(addDays(today, 14)), y: 246 },
      { x: formatDate(addDays(today, 15)), y: 260 },
      { x: formatDate(addDays(today, 16)), y: 274 },
      { x: formatDate(addDays(today, 17)), y: 286 },
      { x: formatDate(addDays(today, 18)), y: 300 },
    ],
  },
  {
    id: "upperBound",
    name: "Upper Bound",
    data: [
      { x: formatDate(subDays(today, 4)), y: 0 },
      { x: formatDate(subDays(today, 3)), y: 0 },
      { x: formatDate(subDays(today, 2)), y: 0 },
      { x: formatDate(subDays(today, 1)), y: 0 },
      { x: formatDate(today), y: 55 },
      { x: formatDate(addDays(today, 1)), y: 69 },
      { x: formatDate(addDays(today, 2)), y: 84 },
      { x: formatDate(addDays(today, 3)), y: 99 },
      { x: formatDate(addDays(today, 4)), y: 114 },
      { x: formatDate(addDays(today, 5)), y: 129 },
      { x: formatDate(addDays(today, 6)), y: 142 },
      { x: formatDate(addDays(today, 7)), y: 157 },
      { x: formatDate(addDays(today, 8)), y: 172 },
      { x: formatDate(addDays(today, 9)), y: 187 },
      { x: formatDate(addDays(today, 10)), y: 200 },
      { x: formatDate(addDays(today, 11)), y: 215 },
      { x: formatDate(addDays(today, 12)), y: 230 },
      { x: formatDate(addDays(today, 13)), y: 245 },
      { x: formatDate(addDays(today, 14)), y: 260 },
      { x: formatDate(addDays(today, 15)), y: 275 },
      { x: formatDate(addDays(today, 16)), y: 289 },
      { x: formatDate(addDays(today, 17)), y: 301 },
      { x: formatDate(addDays(today, 18)), y: 315 },
    ],
  },
  {
    id: "lowerBound",
    name: "Lower Bound",
    data: [
      { x: formatDate(subDays(today, 4)), y: 0 },
      { x: formatDate(subDays(today, 3)), y: 0 },
      { x: formatDate(subDays(today, 2)), y: 0 },
      { x: formatDate(subDays(today, 1)), y: 0 },
      { x: formatDate(today), y: 53 },
      { x: formatDate(addDays(today, 1)), y: 67 },
      { x: formatDate(addDays(today, 2)), y: 80 },
      { x: formatDate(addDays(today, 3)), y: 93 },
      { x: formatDate(addDays(today, 4)), y: 106 },
      { x: formatDate(addDays(today, 5)), y: 119 },
      { x: formatDate(addDays(today, 6)), y: 130 },
      { x: formatDate(addDays(today, 7)), y: 143 },
      { x: formatDate(addDays(today, 8)), y: 156 },
      { x: formatDate(addDays(today, 9)), y: 169 },
      { x: formatDate(addDays(today, 10)), y: 180 },
      { x: formatDate(addDays(today, 11)), y: 193 },
      { x: formatDate(addDays(today, 12)), y: 206 },
      { x: formatDate(addDays(today, 13)), y: 219 },
      { x: formatDate(addDays(today, 14)), y: 232 },
      { x: formatDate(addDays(today, 15)), y: 245 },
      { x: formatDate(addDays(today, 16)), y: 259 },
      { x: formatDate(addDays(today, 17)), y: 271 },
      { x: formatDate(addDays(today, 18)), y: 285 },
    ],
  },
];

export const predictiveMeintenanceStatistics: PredictiveMeintenanceStatisticsType = {
  averageDaysLeft: 33,
  worstDaysLeft: 27,
  bestDaysLeft: 40,
  modelPredictionQuality: "High",
};
