import { ChartConfigFormValuesWithTitle } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { PieChartConfiguration } from "./pieChartTypes";
import { Box, Grid } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import { useLanguageContext } from "../../../context/LanguageContext";

type KeyOfConfigValue = keyof ChartConfigFormValuesWithTitle<PieChartConfiguration>;

interface EditPieChartConfigProps {
  config: ChartConfigFormValuesWithTitle<PieChartConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<PieChartConfiguration>>
  >;
}

const EditPieChartConfig: React.FC<EditPieChartConfigProps> = ({
  config,
  setUpdatedConfig,
}) => {
  const { t } = useLanguageContext();
  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string | boolean) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable arc labels")}
            name="enableArcLabels"
            defaultChecked={config.enableArcLabels}
            onChange={(e) => handleUpdateFormValue("enableArcLabels", e.target.checked)}
            value={config.enableArcLabels}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable arc link labels")}
            name="enableArcLinkLabels"
            defaultChecked={config.enableArcLinkLabels}
            onChange={(e) =>
              handleUpdateFormValue("enableArcLinkLabels", e.target.checked)
            }
            value={config.enableArcLinkLabels}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Fixed Decimal Value")}
            defaultChecked={!!config.fixedDecimalValue}
            onChange={(e) => handleUpdateFormValue("fixedDecimalValue", e.target.checked)}
            value={!!config.fixedDecimalValue}
          />
        </Grid>
        {config.fixedDecimalValue ? (
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("Decimal Points")}
              onChange={(e) => handleUpdateFormValue("decimalPoints", e.target.value)}
              value={config.decimalPoints === undefined ? 0 : config.decimalPoints}
              numberField
              noThousandSeparator
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default EditPieChartConfig;
