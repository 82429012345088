import { IconButton, useTheme } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import SearchIcon from "@mui/icons-material/Search";
import cssComponentsStyles from "../../../Global/Styles/components";
import { WidgetGridItem } from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import { ExcellenceGridItemData, ExcellenceWidgetItemMode } from "./excellenceUtils";
import { useEffect, useState } from "react";
import Button from "../../MaterialUI/Button";

interface ExcellenceWidgetModesProps {
  widgetItem: WidgetGridItem<ExcellenceGridItemData>;
  widgetIndex: number;
  widgetModes: ExcellenceWidgetItemMode[];
  setWidgetModes: React.Dispatch<React.SetStateAction<ExcellenceWidgetItemMode[]>>;
}

const ExcellenceWidgetModes: React.FC<ExcellenceWidgetModesProps> = ({
  widgetItem,
  widgetIndex,
  widgetModes,
  setWidgetModes,
}) => {
  if (!!widgetItem.widget.chart?.timeChart?.dataSchema && !!widgetItem.loading) {
    return (
      <TimeChartModes
        widgetIndex={widgetIndex}
        widgetModes={widgetModes}
        setWidgetModes={setWidgetModes}
      />
    );
  }

  return null;
};

export default ExcellenceWidgetModes;

interface TimeChartModesProps {
  widgetIndex: number;
  widgetModes: ExcellenceWidgetItemMode[];
  setWidgetModes: React.Dispatch<React.SetStateAction<ExcellenceWidgetItemMode[]>>;
}

const TimeChartModes: React.FC<TimeChartModesProps> = ({
  widgetIndex,
  widgetModes,
  setWidgetModes,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssComponentsStyles(theme),
  };

  const [periodPreviewMode, setPeriodPreviewMode] = useState<boolean>(false);
  const [scopeMode, setScopeMode] = useState<boolean>(false);

  useEffect(() => {
    const foundModeIndex = widgetModes.findIndex(
      (item) => item.itemIndex === widgetIndex
    );
    const result: ExcellenceWidgetItemMode = {
      itemIndex: widgetIndex,
      timeChart: {
        periodPreviewMode,
        scopeMode,
      },
    };

    if (foundModeIndex !== -1) {
      setWidgetModes((prev) =>
        prev.map((item, index) => {
          if (index === foundModeIndex) {
            return result;
          }
          return item;
        })
      );
    } else {
      setWidgetModes([...widgetModes, result]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetIndex, periodPreviewMode, scopeMode]);

  const handleExit = () => {
    setScopeMode(false);
    setPeriodPreviewMode(false);
  };

  return (
    <>
      {periodPreviewMode || scopeMode ? (
        <Button variant="text" onClick={handleExit} size="small">
          Exit Mode
        </Button>
      ) : (
        <>
          <IconButton aria-label="scope mode toggle" onClick={() => setScopeMode(true)}>
            <SearchIcon css={styles.greyIcon} />
          </IconButton>
          <IconButton
            aria-label="period mode toggle"
            onClick={() => setPeriodPreviewMode(true)}
          >
            <SwapHorizIcon css={styles.greyIcon} />
          </IconButton>
        </>
      )}
    </>
  );
};
