import { Box, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import MaterialsTable from "./MaterialsTable";
import MaterialTreeView from "./MaterialTreeView";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import {
  getQueryErpClassifications,
  getQueryMaterials,
} from "../../Api/SupplyChain/apiSupplyChainGetQueries";
import {
  GetQueryErpClassificationsSnippet,
  GetQueryMaterialsSnippet,
} from "../../Api/SupplyChain/apiSupplyChainSnippets";

const MaterialsPage: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [tabView, setTabView] = useState<number>(0);
  const [treeData, setTreeData] = useState<GetQueryErpClassificationsSnippet>([]);
  const [tableData, setTableData] = useState<GetQueryMaterialsSnippet>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        const erpData = await callApi<GetQueryErpClassificationsSnippet>({
          query: getQueryErpClassifications(),
          auth: { setAuthedUser },
        });
        const materials = await callApi<GetQueryMaterialsSnippet>({
          query: getQueryMaterials(),
          auth: { setAuthedUser },
        });

        setTreeData(erpData);
        setTableData(materials);
      } catch (error) {
        console.log("There was an error: ", error);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="div">
      <Stack
        css={[styles.width100, styles.sectionBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2">Materials</Typography>

        <Tabs
          css={styles.sectionBreak}
          value={tabView}
          onChange={(_: any, val: React.SetStateAction<number>) => setTabView(val)}
          aria-label="configure chart tabs"
        >
          <Tab label="Table View" />
          <Tab label="Tree View" />
        </Tabs>
      </Stack>

      {tabView === 0 ? (
        <MaterialsTable tableData={tableData} loading={loading} />
      ) : (
        <MaterialTreeView treeData={treeData} />
      )}
    </Box>
  );
};

export default MaterialsPage;
