import { LeftNavSingleItem } from "../../../Layout/layoutVariables";
import { allLeftNavRoutes } from "../../../Layout/Router/routesMapping";

export const findTextInNavItems = (path: string): string | null => {
  const searchItems = (navItems: LeftNavSingleItem[], path: string): string | null => {
    for (const item of navItems) {
      if (item.url === path) {
        return item.text;
      }

      if (item.nested) {
        const nestedResult = searchItems(item.nested, path);
        if (nestedResult) return nestedResult;
      }
    }
    return null;
  };

  return searchItems(allLeftNavRoutes, path);
};

export type PageKeys = {
  page_key: string;
  id: string;
};
