import { Query } from "../../Api/callApi";
import { KanbanTaskStatus } from "../TaskManager/Components/taskManagerUtils";

export const getQueryKanOrders = (): Query => ({
  endpoint: "order_management/orders/fetch-all",
  method: "GET",
});

export type KanOrder = {
  business_unit: string;
  contact_person_development: string;
  contact_person_ppm: string;
  cost_center: string;
  id: string;
  me_sampling_needed_panels: number;
  order_options: { option_name: string; option_value: string }[];
  quantity_panels: number;
  quantity_pcbs: number;
  sample_name: string;
  sap_order_number: number;
  status: string;
  name: string;
  number_of_board_bare_panel: string | number;
  number_of_board_bare_pcb: string | number;
  assembly_board: string;
  order_number: string | number;
  panels: string;
  progress: number;
  windchill_link: string;
  preferred_date: Date;
  start_date: Date;
  end_date: Date;
  finished_date: Date;
  placed_at: Date;
  material_availability_date: Date;
};

export const getQueryKanOrderTasks = (orderID: string): Query => ({
  endpoint: `workflows/tasks/order/${orderID}`,
  method: "GET",
});

export type KanOrderTask = {
  created_at: Date;
  end_date: Date | null;
  id: string;
  node_id: string;
  order_id: string;
  start_date: Date;
  status: KanbanTaskStatus;
  template_id: string;
  template_version: string;
  updated_at: Date;
  workflow_id: string;
  workflow_version: string;
};
