import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { Datum, ResponsiveLine } from "@nivo/line";
import { LineAreaHeatMapScatterPlotData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { getChartColors } from "../nivoTheme";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import { memo, useRef } from "react";
import lineChartDefaultData from "../ExcellenceDefaultConfig/lineChartDefaultData";
import { LineChartConfig } from "./lineChartTypes";
import {
  TimeChartThreshold,
  TimeChartThresholdValues,
} from "../TimeChart/timeChartTypes";
import { timeChartMinMaxY } from "../TimeChart/timeChartUtils";
import { LineChartXScaleMinMax } from "./lineChartUtils";

interface LineChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: LineChartConfig;
  data: LineAreaHeatMapScatterPlotData | null;
  isStatic?: boolean;
  xScaleMinMax?: LineChartXScaleMinMax;
}

const LineChart: React.FC<LineChartProps> = ({
  className,
  configuration,
  data,
  // xScaleMinMax,
  isStatic,
}) => {
  const {
    axisLeftLegend,
    axisBottomLegend,
    enablePoints,
    interpolate,
    enableGridX,
    enableGridY,
    threshold,
  } = configuration;
  const theme = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width: chartWidth } = useContainerDimensions(chartContainerRef);

  const dynamicData = getDynamicData(chartWidth, data || lineChartDefaultData);
  const longestLegend = getLongestLegendWordChars(dynamicData);
  const yScaleMinMax = timeChartMinMaxY(dynamicData, configuration, threshold?.values);
  const widthRotation = calculateLabelRotation(chartWidth);

  const MARKER_COLORS: Record<keyof TimeChartThresholdValues, string> = {
    lowLowThreshold: theme.palette.error.dark,
    lowThreshold: theme.palette.warning.dark,
    highThreshold: theme.palette.warning.dark,
    highHighThreshold: theme.palette.error.dark,
  };
  const chartMargin = {
    top: isStatic ? 10 : 25,
    right: isStatic ? 10 : 50 + longestLegend * 6,
    bottom: isStatic ? 10 : 60,
    left: isStatic ? 10 : 60,
  };

  return (
    <Box
      component="div"
      sx={{ position: "relative" }}
      className={className}
      ref={chartContainerRef}
    >
      <ResponsiveLine
        data={dynamicData}
        margin={chartMargin}
        enablePoints={enablePoints}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        isInteractive={isStatic ? false : true}
        curve={interpolate}
        colors={(d) => getLineColors(d, threshold, MARKER_COLORS)}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          crosshair:
            theme.palette.mode === "dark"
              ? {
                  line: {
                    stroke: "#FFFFFF",
                    strokeWidth: 2,
                  },
                }
              : undefined,
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        xScale={{
          type: "point",
          // min: xScaleMinMax ? xScaleMinMax.min : undefined,
          // max: xScaleMinMax ? xScaleMinMax.max : undefined,
        }}
        yScale={{
          type: "linear",
          min: yScaleMinMax.min,
          max: yScaleMinMax.max,
          stacked: false,
          reverse: false,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={
          isStatic || !dynamicData?.length
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: widthRotation,
                legend: axisBottomLegend,
                legendOffset: 36,
                legendPosition: "middle",
              }
        }
        axisLeft={
          isStatic || !dynamicData?.length
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisLeftLegend,
                legendOffset: -50,
                legendPosition: "middle",
              }
        }
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{
          from: "serieColor",
          modifiers: theme.palette.mode === "light" ? [["darker", 1.6]] : undefined,
        }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={
          isStatic
            ? undefined
            : [
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                  data: dynamicData.map((item) => ({
                    color: getLineColors(item, threshold, MARKER_COLORS),
                    id: item.id,
                    label: item.name,
                  })),
                },
              ]
        }
        animate={false}
      />
    </Box>
  );
};

export default memo(LineChart);

type LineDatum = {
  x: string;
  y: number;
};
const getDynamicData = (width: number, data: LineAreaHeatMapScatterPlotData) => {
  // 1. Calculate max numb of points based on chart width
  const maxDataPoints = Math.round(width / 27);

  // 2. Down-sample the data to have a length up to maxPoints
  const adjustedData = data.map((item, dataIndex) => {
    const typedDatum = item.data as LineDatum[];

    const downSampledArray = downSampleData(typedDatum, maxDataPoints);

    return {
      ...item,
      name: item.name || item.id,
      color: getChartColors()?.[dataIndex] || "black",
      data: downSampledArray,
    };
  });

  return adjustedData;
};

const downSampleData = (data: LineDatum[], maxDataPoints: number): LineDatum[] => {
  const dataLength = data.length;

  // If the data length is less than or equal to maxDataPoints, no need to downsample
  if (dataLength <= maxDataPoints) {
    return data;
  }

  // Calculate the step size for downsampling
  const stepSize = Math.ceil(dataLength / maxDataPoints);

  // Downsample the data by picking every nth point
  const downSampledData = data.filter((_, index) => index % stepSize === 0);

  return downSampledData;
};

const getLongestLegendWordChars = (data: LineAreaHeatMapScatterPlotData) => {
  const wordsArr = data.map((item) => item.name);

  let longestLength = 0;

  wordsArr.forEach((word) => {
    if (word.length > longestLength) {
      longestLength = word.length;
    }
  });

  return longestLength;
};

type SingleDatum = {
  id: string;
  name: string;
  color: string;
  data: Datum[];
};

const getLineColors = (
  datum: SingleDatum,
  threshold: TimeChartThreshold | undefined,
  markerColors: Record<keyof TimeChartThresholdValues, string>
): string => {
  if (threshold?.mode === "valuesFromNodes" && threshold?.values) {
    if (datum.id === threshold.values.highHighThreshold?.node) {
      return markerColors.highHighThreshold;
    } else if (datum.id === threshold.values.highThreshold?.node) {
      return markerColors.highThreshold;
    } else if (datum.id === threshold.values.lowThreshold?.node) {
      return markerColors.lowThreshold;
    } else if (datum.id === threshold.values.lowLowThreshold?.node) {
      return markerColors.lowLowThreshold;
    }
  }
  return datum.color;
};

const calculateLabelRotation = (chartWidth: number): number => {
  const maxChartWidth = 1400;
  const maxRotation = 90;
  const initialRotation = 2;

  if (chartWidth >= maxChartWidth) {
    return 0; // No rotation if chartWidth is 1400px or more
  } else {
    // Calculate rotation based on chartWidth
    const additionalRotation =
      maxRotation * ((maxChartWidth - chartWidth) / maxChartWidth);
    return Math.min(maxRotation, initialRotation + additionalRotation);
  }
};
