import React, { useEffect, useState } from "react";
import { Box, Typography, Link, Divider } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { findTextInNavItems } from "../../Components/PageComponents/Help/helpUtils";
import { GetQueryHelpPageKeysSnippet } from "../../Api/Help/apiHelpSnippets";
import callApi from "../../Api/callApi";
import { useAuthedContext } from "../../context/AuthContext";
import {
  getQueryHelpPageContent,
  getQueryHelpPageKeys,
} from "../../Api/Help/apiHelpGetQueries";

interface HelpContentProps {
  locationPath: string;
}

const HelpPage: React.FC<HelpContentProps> = ({ locationPath }) => {
  const { setAuthedUser } = useAuthedContext();
  const [mainText, setMainText] = useState<string>("Help Section");
  const [secondaryText, setSecondaryText] = useState<string>("General help content.");
  const [pageContent, setPageContent] = useState<string>("");

  useEffect(() => {
    fetchHelpContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPath]);

  const setDefaultText = () => {
    setMainText("Help Section");
    setSecondaryText("No specific content found for this section.");
  };

  const fetchHelpContent = async () => {
    try {
      const foundPageText = findTextInNavItems(locationPath);

      if (foundPageText) {
        setMainText(foundPageText);
        setSecondaryText(`Content related to ${foundPageText}`);

        const pageKeys = await callApi<GetQueryHelpPageKeysSnippet>({
          query: getQueryHelpPageKeys(),
          auth: { setAuthedUser },
        });
        const currentPage = pageKeys.find((key) => key.page_key === foundPageText);

        if (currentPage) {
          const helpContent = await callApi<string>({
            query: getQueryHelpPageContent(currentPage.id),
            auth: { setAuthedUser },
          });

          setPageContent(helpContent);
        }
      } else {
        setDefaultText();
      }
    } catch (error) {
      console.log("Failed to load Help content", error.message);
      setDefaultText();
    }
  };

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      padding={2}
    >
      <Box component="div">
        <Typography variant="body1" gutterBottom>
          {mainText}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {secondaryText}
        </Typography>
      </Box>

      <Box component="div" marginTop={2}>
        <div dangerouslySetInnerHTML={{ __html: pageContent }} />
      </Box>

      <Box component="div" marginTop={2}>
        <Divider />
        <Box component="div" marginTop={2} paddingLeft={2}>
          <Typography variant="body2" mb={1}>
            <Link
              component={RouterLink}
              to="/release-notes"
              underline="none"
              color="primary"
            >
              Release Notes
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HelpPage;
