import { useState, useEffect } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import callApi from "../../../../../Api/callApi";
import { postQueryUANode } from "../../../../../Api/DataSources/apiDSPostQueries";
import { PostQueryUANodeSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import { UaNode } from "../../../../../Api/DataSources/apiDSDataTypes";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { css } from "@emotion/react";
import Alert from "../../../../MaterialUI/Alert";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { useAuthedContext } from "../../../../../context/AuthContext";
import cssComponentsStyles from "../../../../../Global/Styles/components";

type FormattedNodes = {
  name: string;
  value: string;
}[];

const formatSingleUANodeValue = (node: UaNode): FormattedNodes => {
  const emptyValue = {
    StatusCode: "",
    "Source Timestamp": "",
    "Sever Timestamp": "",
    Value: "",
  };

  const resultNonValue = [
    {
      name: "NodeId",
      value: node.NodeId,
    },
    {
      name: "DisplayName",
      value: node.DisplayName,
    },
    {
      name: "DataType",
      value: node.DataType,
    },
  ];

  // logic for node.Value
  const nodeValue = Object.entries(node.Value || emptyValue);
  const resultValue = nodeValue.map((item) => {
    const field = item[0];
    const value = item[1];
    return {
      name: field,
      value: value,
    };
  });

  const result = [...resultNonValue, ...resultValue];

  return result;
};

const cssStyles = {
  tableCell: css({
    border: "none",
    verticalAlign: "baseline",
  }),
};

interface UaLastNodeValuesProps {
  disabled?: boolean;
  lastSelectedNode: string | null;
  server: string;
}

const UaLastNodeValues: React.FC<UaLastNodeValuesProps> = ({
  lastSelectedNode,
  disabled,
  server,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles,
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const [nodeValues, setNodeValues] = useState<FormattedNodes>([]);
  const [noNodeValue, setNoNodeValue] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSelectedNode]);

  const handleFetchData = async () => {
    try {
      if (lastSelectedNode) {
        setLoading(true);
        setNoNodeValue(false);
        // fetch nodes
        const nodeData = await callApi<PostQueryUANodeSnippet>({
          query: postQueryUANode({
            url: server,
            nodes: [lastSelectedNode],
          }),
          auth: { setAuthedUser },
        });
        const formattedNodeValue = formatSingleUANodeValue(nodeData[0]);
        if (nodeData[0]?.Value) {
          setNoNodeValue(false);
        } else {
          setNoNodeValue(true);
        }
        setNodeValues(formattedNodeValue);
      }

      setLoading(false);
    } catch (err) {
      console.log("UaItem useEffect err ", err);
      setLoading(false);
    }
  };

  return (
    <Box component="div" css={styles.widthLimit40}>
      {nodeValues.length ? (
        <>
          <IconButton
            css={[styles.contentBreak, styles.iconButton]}
            onClick={handleFetchData}
            disabled={loading || disabled}
            size="small"
          >
            <Tooltip title="Refetch node data" enterDelay={500}>
              <RefreshIcon />
            </Tooltip>
          </IconButton>

          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell css={styles.tableCell}>
                    <Typography fontWeight={600}>Name:</Typography>
                  </TableCell>
                  <TableCell css={styles.tableCell}>
                    <Typography fontWeight={600}>Value:</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {nodeValues.map((node, index) => (
                  <TableRow key={`${node.name}-${index}`}>
                    <TableCell css={styles.tableCell}>{node.name}</TableCell>
                    <TableCell css={styles.tableCell}>{node.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Alert
            css={styles.reverseContentBreak}
            showAlert={noNodeValue}
            message="Last selected node does not have a value"
            severity="warning"
          />
        </>
      ) : (
        <Typography css={styles.reverseSectionBreak} variant="h4">
          Select a node to see data values.
        </Typography>
      )}
    </Box>
  );
};

export default UaLastNodeValues;
