import { useCallback, useEffect, useRef, useState } from "react";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  ReactFlowProvider,
  Connection,
  Edge,
  Controls,
  MiniMap,
  Panel,
} from "reactflow";
import { SerializedStyles } from "@emotion/react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { Box, Stack } from "@mui/material";
import "reactflow/dist/style.css";
import {
  ReactCustomFlowType,
  WorkflowNewNodeModal,
  getWorkflowLayoutedElements,
} from "./reactFlowUtils";
import Button from "../../MaterialUI/Button";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import throttle from "lodash.throttle";
import { v4 as uuidv4 } from "uuid";
import CreateNewFlowNodeModal from "./CreateNewFlowNodeModal";
import { FLOW_NODE_TYPE } from "./FlowNodes/flowNodesTypes";
import FlowInitialNode from "./FlowNodes/Nodes/FlowInitialNode";
import FlowTextNode from "./FlowNodes/Nodes/FlowTextNode";
import FlowEnhancedNode from "./FlowNodes/Nodes/FlowEnhancedNode";

const cssStyles = {
  flowWrapper: css({
    " .react-flow__node": {
      border: "1px solid black",
    },
    " .react-flow__node.selected": {
      border: "1px solid red",
    },
  }),
};

interface ReactFlowCustomProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  workflow: ReactCustomFlowType;
  initialNodeUpdated: boolean;
  setWorkflow: React.Dispatch<React.SetStateAction<ReactCustomFlowType | null>>;
}

const ReactFlowCustom: React.FC<ReactFlowCustomProps> = ({
  className,
  workflow,
  initialNodeUpdated,
  setWorkflow,
}) => {
  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          "ResizeObserver loop completed with undelivered notifications" ||
            "ResizeObserver loop limit exceeded"
        )
      ) {
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = "none";
        }
      }
    };
    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  return (
    <ReactFlowProvider>
      <MyReactFlow
        className={className}
        workflow={workflow}
        initialNodeUpdated={initialNodeUpdated}
        setWorkflow={setWorkflow}
      />
    </ReactFlowProvider>
  );
};

export default ReactFlowCustom;

// -------- MyReactFlow Logic --------

const fitViewOptions = {
  padding: 0.2,
  maxZoom: 1,
};
const proOptions = { hideAttribution: true };
const nodeTypes = {
  initial: FlowInitialNode,
  [FLOW_NODE_TYPE.Text]: FlowTextNode,
  [FLOW_NODE_TYPE.Enhanced]: FlowEnhancedNode,
};

interface MyReactFlowProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  workflow: ReactCustomFlowType;
  initialNodeUpdated: boolean;
  setWorkflow: React.Dispatch<React.SetStateAction<ReactCustomFlowType | null>>;
}

const MyReactFlow: React.FC<MyReactFlowProps> = ({
  className,
  workflow,
  initialNodeUpdated,
  setWorkflow,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles, ...cssLayoutStyles };
  const [nodes, setNodes, onNodesChange] = useNodesState(workflow.nodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(workflow.edges);

  const [newNodeModal, setNewNodeModal] = useState<WorkflowNewNodeModal>(null);

  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const connectingNodeId = useRef<any>(null);

  const { project } = useReactFlow();

  // eslint-disable-next-line
  const updateWorkflowThrottled = useCallback(
    throttle((updatedNodes, updatedEdges) => {
      setWorkflow((prev) => {
        if (prev?.id) {
          return {
            ...prev,
            nodes: updatedNodes,
            edges: updatedEdges,
          };
        }
        return null;
      });
    }, 1000),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  /**
   * keep workflow nodes up to date
   */
  useEffect(() => {
    updateWorkflowThrottled(nodes, edges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, edges]);

  /**
   * update nodes when the initial node changes
   * from the edit workflow form
   */
  useEffect(() => {
    setNodes(workflow.nodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialNodeUpdated]);

  /** When a node connects to another */
  const onConnect = useCallback((params: Edge | Connection) => {
    setEdges((eds) => addEdge({ ...params, type: "step" }, eds));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When new connection is initialized
  const onConnectStart = useCallback((_: any, { nodeId }: { nodeId: any }) => {
    connectingNodeId.current = nodeId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // When connection is completed
  const onConnectEnd = useCallback(
    (event: any) => {
      const targetIsPane = event.target.classList.contains("react-flow__pane");

      if (targetIsPane && reactFlowWrapper.current) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
        const id = uuidv4().split("-")[0];

        setNewNodeModal({
          id,
          // we are removing the half of the node width (75) to center the new node
          position: project({ x: event.clientX - left - 75, y: event.clientY - top }),
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project]
  );

  /** Changes the layout and sorts nodes */
  const handleLayoutChange = useCallback(
    (direction: "TB" | "LR") => {
      const { nodes: layoutedNodes, edges: layoutedEdges } = getWorkflowLayoutedElements(
        nodes,
        edges,
        direction
      );

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nodes, edges]
  );

  return (
    <>
      <Box
        component="div"
        css={[styles.width100, styles.height100]}
        className={className}
        ref={reactFlowWrapper}
      >
        <ReactFlow
          css={styles.flowWrapper}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={onConnectStart}
          onConnectEnd={onConnectEnd}
          fitView
          fitViewOptions={fitViewOptions}
          minZoom={0.5}
          maxZoom={2}
          proOptions={proOptions}
        >
          <Panel position="top-right">
            <Stack direction="row" spacing={2}>
              <Button
                variant="text"
                size="small"
                color={theme.palette.mode === "light" ? "info" : "primary"}
                onClick={() => handleLayoutChange("TB")}
                startIcon={<VerticalAlignCenterIcon />}
              >
                Vertical
              </Button>
              <Button
                variant="text"
                size="small"
                color={theme.palette.mode === "light" ? "info" : "primary"}
                onClick={() => handleLayoutChange("LR")}
                startIcon={<AlignHorizontalLeftIcon />}
              >
                Horizontal
              </Button>
            </Stack>
          </Panel>
          <Controls fitViewOptions={fitViewOptions} />
          {/* <Background
          color={theme.palette.mode === "light" ? "#ccc" : "#1a1818"}
          variant={BackgroundVariant.Lines}
        /> */}
          <MiniMap nodeStrokeWidth={3} zoomable pannable />
        </ReactFlow>
      </Box>

      <CreateNewFlowNodeModal
        newNodeModal={newNodeModal}
        setNewNodeModal={setNewNodeModal}
        setNodes={setNodes}
        setEdges={setEdges}
        connectingNodeId={connectingNodeId}
        nodes={nodes}
      />
    </>
  );
};
