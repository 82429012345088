import { ResponsiveLine, Serie } from "@nivo/line";
import { getChartColors } from "../nivoTheme";
import { Box } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import React from "react";
import { subDays } from "date-fns";

interface IndicatorWidgetChartProps {
  isInteractive: boolean;
  chartData: number[];
}

const IndicatorWidgetChart: React.FC<IndicatorWidgetChartProps> = ({
  isInteractive,
  chartData,
}) => {
  const theme = useTheme();

  const properData: Serie[] = [
    {
      id: "line-1",
      name: "Line 1",
      data: chartData.map((item, index) => ({
        y: item,
        x: subDays(new Date(), index).toISOString(),
      })),
    },
  ];

  return (
    <Box
      component="div"
      sx={{
        width: isInteractive ? theme.breakpoints.values.lg : "45%",
        height: isInteractive ? 300 : 90,
        overflow: "hidden",
        zIndex: 1,
      }}
    >
      <ResponsiveLine
        data={properData}
        margin={isInteractive ? { top: 40, right: 90, bottom: 43, left: 43 } : undefined}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          stacked: true,
          reverse: false,
        }}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          crosshair:
            theme.palette.mode === "dark"
              ? {
                  line: {
                    stroke: theme.palette.common.white,
                    strokeWidth: 1,
                  },
                }
              : undefined,
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={
          isInteractive
            ? {
                tickSize: 5,
                tickPadding: 5,
                tickValues: "10",
                tickRotation: 0,
                legendOffset: 36,
                legendPosition: "middle",
                truncateTickAt: 0,
              }
            : null
        }
        axisLeft={
          isInteractive
            ? {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendOffset: -40,
                legendPosition: "middle",
                truncateTickAt: 0,
              }
            : null
        }
        enableGridX={isInteractive}
        enableGridY={isInteractive}
        colors={getChartColors()}
        enablePoints={false}
        enableCrosshair={isInteractive}
        crosshairType="cross"
        isInteractive={isInteractive}
        useMesh
      />
    </Box>
  );
};

export default IndicatorWidgetChart;
