import { Box, Typography, useTheme } from "@mui/material";
import { MAIN_LOCATION_OPTIONS, LocationNodeStyle } from "./locationNetworkChartUtils";
import { filteredColor, selectedColor } from "../../../Global/Constants/styleConstants";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import { css } from "@emotion/react";

const cssStyles = () => ({
  boxContainerStyle: css({
    display: "flex",
    alignItems: "center",
  }),
  colorBoxesStyle: css({
    width: 14,
    height: 14,
    borderRadius: "50%",
  }),
});

interface LegendProps {
  nodeStyles: { [level: number]: LocationNodeStyle };
  selectedNodeId: string | null | undefined;
  filteredNodeIds: string[];
  visibleNodesLabels: string[];
  toggleNodeLabelVisibility: (value: string) => void;
}

const NetworkChartLegend: React.FC<LegendProps> = ({
  nodeStyles,
  selectedNodeId,
  filteredNodeIds,
  visibleNodesLabels,
  toggleNodeLabelVisibility,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(),
  };
  const isNodeSelected = selectedNodeId !== null;
  const isNodeFiltered = filteredNodeIds.length > 0;

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      mb={1}
    >
      <Box component="div" display="flex" alignItems="center" ml={4}>
        <Checkbox
          value={"All"}
          checked={visibleNodesLabels.includes("All")}
          onChange={() => toggleNodeLabelVisibility("All")}
          label="Show all labels"
          size="small"
        />
      </Box>
      {MAIN_LOCATION_OPTIONS.map((option, index) => {
        const style = nodeStyles[index];
        if (!style) {
          return null;
        }
        return (
          <Box
            component="div"
            key={`${option.value}-${index}`}
            display="flex"
            alignItems="center"
            gap={1}
            ml={2.7}
          >
            <Checkbox
              value={option.value}
              checked={visibleNodesLabels.includes(option.value)}
              onChange={() => toggleNodeLabelVisibility(option.value)}
              size="small"
            />
            <Box component="div" css={styles.colorBoxesStyle} bgcolor={style.color} />
            <Typography style={{ color: theme.palette.text.primary }} mb={0}>
              {option.description}
            </Typography>
          </Box>
        );
      })}
      {isNodeSelected ? (
        <Box component="div" css={styles.boxContainerStyle} gap={1} ml={7.7}>
          <Box component="div" css={styles.colorBoxesStyle} bgcolor={selectedColor} />
          <Typography style={{ color: theme.palette.text.primary }}>Selected</Typography>
        </Box>
      ) : null}
      {isNodeFiltered ? (
        <Box component="div" css={styles.boxContainerStyle} gap={1} ml={7.7}>
          <Box component="div" css={styles.colorBoxesStyle} bgcolor={filteredColor} />
          <Typography style={{ color: theme.palette.text.primary }}>Filtered</Typography>
        </Box>
      ) : null}
    </Box>
  );
};

export default NetworkChartLegend;
