import { ChartConfigFormValuesWithTitle } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Box, Grid } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import Select from "../../MaterialUI/FormFields/Select";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { RadarChartConfiguration, RadarChartCurve } from "./radarChartTypes";
import { useLanguageContext } from "../../../context/LanguageContext";

type KeyOfConfigValue = keyof ChartConfigFormValuesWithTitle<RadarChartConfiguration>;

interface EditRadarChartConfigProps {
  config: ChartConfigFormValuesWithTitle<RadarChartConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<RadarChartConfiguration>>
  >;
}

const EditRadarChartConfig: React.FC<EditRadarChartConfigProps> = ({
  config,
  setUpdatedConfig,
}) => {
  const { t } = useLanguageContext();
  const curveArray: RadarChartCurve[] = [
    "basisClosed",
    "cardinalClosed",
    "catmullRomClosed",
    "linearClosed",
  ];
  const curveOptions: SelectOption[] = handleGetSelectOption(curveArray, true);

  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string | boolean) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            selectOptions={curveOptions}
            name="interpolate"
            label={t("Interpolate")}
            onChange={(e) => handleUpdateFormValue("interpolate", e.target.value)}
            value={config.interpolate}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable dots")}
            name="enableDots"
            defaultChecked={config.enableDots}
            onChange={(e) => handleUpdateFormValue("enableDots", e.target.checked)}
            value={config.enableDots}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable dot labels")}
            name="enableDotLabel"
            defaultChecked={config.enableDotLabel}
            onChange={(e) => handleUpdateFormValue("enableDotLabel", e.target.checked)}
            value={config.enableDotLabel}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Fixed Decimal Value")}
            defaultChecked={!!config.fixedDecimalValue}
            onChange={(e) => handleUpdateFormValue("fixedDecimalValue", e.target.checked)}
            value={!!config.fixedDecimalValue}
          />
        </Grid>
        {config.fixedDecimalValue ? (
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("Decimal Points")}
              onChange={(e) => handleUpdateFormValue("decimalPoints", e.target.value)}
              value={config.decimalPoints === undefined ? 0 : config.decimalPoints}
              numberField
              noThousandSeparator
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default EditRadarChartConfig;
