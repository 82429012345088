import { SelectOption } from "../../../../Global/Types/commonTypes";
import { Node } from "reactflow";

export const DEFAULT_NODE_HEIGHT = 130;
export const DEFAULT_NODE_WIDTH = 300;

export enum FLOW_NODE_TYPE {
  Text = "Text",
  Enhanced = "Enhanced",
}
// export const FLOW_NODES_OPTIONS: SelectOption[] = Object.entries(FLOW_NODE_TYPE).map(
//   ([key, value]) => ({ value: key, description: value })
// );
export const FLOW_NODES_OPTIONS: SelectOption[] = Object.entries(FLOW_NODE_TYPE)
  .map(([key, value]) => ({ value: key, description: value }))
  .slice(0, 1);

export type FlowAllTaskNodesData = {
  [FLOW_NODE_TYPE.Text]?: FlowTextNodeData;
  [FLOW_NODE_TYPE.Enhanced]?: FlowEnhancedNodeData;
};

type FlowCommonData = {
  id: string;
  name: string;
  createdOn: string;
  updatedOn: string;
};

export type FlowInitialNodeData = FlowCommonData;
export type FlowInitialNodeType = Node & {
  data: FlowInitialNodeData;
};

export type FlowTextNodeData = FlowCommonData & {};
export type FlowTextNodeType = Node & {
  data: FlowInitialNodeData;
};

export type FlowEnhancedNodeData = FlowCommonData & {
  color: string;
};
export type FlowEnhancedNodeType = Node & {
  data: FlowInitialNodeData;
  color: string;
};

// ---------------- NODE CONFIGS ----------------
const FLOW_CORE_NODE_CONFIG = {
  style: {
    background: "#fff",
    color: "#000",
    fontSize: 12,
    borderRadius: 15,
    width: DEFAULT_NODE_WIDTH,
    cursor: "default",
    height: DEFAULT_NODE_HEIGHT,
    padding: 7,
  },
  dragHandle: ".custom-drag-handle",
};
export const FLOW_INITIAL_NODE_CONFIG = {
  ...FLOW_CORE_NODE_CONFIG,
  style: {
    ...FLOW_CORE_NODE_CONFIG.style,
    border: "3px solid #30AFB8",
  },
  type: "initial",
};
export const FLOW_TEXT_NODE_CONFIG = {
  ...FLOW_CORE_NODE_CONFIG,
  type: FLOW_NODE_TYPE.Text,
};
export const FLOW_ENHANCED_NODE_CONFIG = {
  ...FLOW_CORE_NODE_CONFIG,
  type: FLOW_NODE_TYPE.Enhanced,
};
