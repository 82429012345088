import { CalendarWidgetData } from "../CalendarWidget/calendarWidgetTypes";

const calendarWidgetDefaultData: CalendarWidgetData =  [
    {
      "value": 301,
      "day": "2016-01-01"
    },
    {
      "value": 134,
      "day": "2015-12-10"
    },
    {
      "value": 314,
      "day": "2016-07-04"
    },
    {
      "value": 248,
      "day": "2017-06-21"
    },
    {
      "value": 290,
      "day": "2015-07-13"
    },
    {
      "value": 191,
      "day": "2016-05-26"
    },
    {
      "value": 272,
      "day": "2018-05-26"
    },
    {
      "value": 243,
      "day": "2016-11-14"
    },
    {
      "value": 347,
      "day": "2017-05-02"
    },
    {
      "value": 57,
      "day": "2016-03-01"
    },
    {
      "value": 365,
      "day": "2018-06-09"
    },
    {
      "value": 379,
      "day": "2016-12-03"
    },
    {
      "value": 193,
      "day": "2016-10-02"
    },
    {
      "value": 265,
      "day": "2018-07-05"
    },
    {
      "value": 326,
      "day": "2015-05-05"
    },
    {
      "value": 164,
      "day": "2015-12-08"
    },
    {
      "value": 171,
      "day": "2018-08-04"
    },
    {
      "value": 94,
      "day": "2015-11-14"
    },
    {
      "value": 106,
      "day": "2015-07-31"
    },
    {
      "value": 211,
      "day": "2017-11-14"
    },
    {
      "value": 330,
      "day": "2017-06-26"
    },
    {
      "value": 173,
      "day": "2016-04-23"
    },
    {
      "value": 43,
      "day": "2017-08-02"
    },
    {
      "value": 227,
      "day": "2017-05-06"
    },
    {
      "value": 18,
      "day": "2016-02-10"
    },
    {
      "value": 276,
      "day": "2017-09-16"
    },
    {
      "value": 239,
      "day": "2017-04-18"
    },
    {
      "value": 140,
      "day": "2015-09-25"
    },
    {
      "value": 237,
      "day": "2016-08-30"
    },
    {
      "value": 130,
      "day": "2015-06-30"
    },
    {
      "value": 82,
      "day": "2017-08-08"
    },
    {
      "value": 162,
      "day": "2015-11-03"
    },
    {
      "value": 234,
      "day": "2017-10-26"
    },
    {
      "value": 377,
      "day": "2016-05-06"
    },
    {
      "value": 324,
      "day": "2017-09-18"
    },
    {
      "value": 176,
      "day": "2018-05-04"
    },
    {
      "value": 17,
      "day": "2017-01-26"
    },
    {
      "value": 179,
      "day": "2017-11-18"
    },
    {
      "value": 205,
      "day": "2016-03-25"
    },
    {
      "value": 318,
      "day": "2016-08-27"
    },
    {
      "value": 260,
      "day": "2017-10-02"
    },
    {
      "value": 231,
      "day": "2017-12-07"
    },
    {
      "value": 350,
      "day": "2016-09-22"
    },
    {
      "value": 40,
      "day": "2015-12-27"
    },
    {
      "value": 59,
      "day": "2016-01-28"
    },
    {
      "value": 307,
      "day": "2016-11-30"
    },
    {
      "value": 357,
      "day": "2018-03-30"
    },
    {
      "value": 324,
      "day": "2017-02-02"
    },
    {
      "value": 107,
      "day": "2017-08-18"
    },
    {
      "value": 26,
      "day": "2017-08-11"
    },
    {
      "value": 259,
      "day": "2016-04-10"
    },
    {
      "value": 145,
      "day": "2017-04-29"
    },
    {
      "value": 210,
      "day": "2015-11-13"
    },
    {
      "value": 166,
      "day": "2016-01-15"
    },
    {
      "value": 281,
      "day": "2016-08-14"
    },
    {
      "value": 94,
      "day": "2018-03-13"
    },
    {
      "value": 150,
      "day": "2016-05-17"
    },
    {
      "value": 29,
      "day": "2017-10-18"
    },
    {
      "value": 55,
      "day": "2017-12-30"
    },
    {
      "value": 225,
      "day": "2016-01-24"
    },
    {
      "value": 94,
      "day": "2016-11-21"
    },
    {
      "value": 150,
      "day": "2017-02-20"
    },
    {
      "value": 360,
      "day": "2017-12-16"
    },
    {
      "value": 326,
      "day": "2017-11-01"
    },
    {
      "value": 277,
      "day": "2018-02-04"
    },
    {
      "value": 115,
      "day": "2015-06-13"
    },
    {
      "value": 122,
      "day": "2015-05-31"
    },
    {
      "value": 157,
      "day": "2016-02-27"
    },
    {
      "value": 188,
      "day": "2017-12-23"
    },
    {
      "value": 274,
      "day": "2016-07-29"
    },
    {
      "value": 352,
      "day": "2017-10-03"
    },
    {
      "value": 247,
      "day": "2018-04-02"
    },
    {
      "value": 389,
      "day": "2017-06-22"
    },
    {
      "value": 319,
      "day": "2016-07-12"
    },
    {
      "value": 342,
      "day": "2016-02-21"
    },
    {
      "value": 171,
      "day": "2015-11-07"
    },
    {
      "value": 166,
      "day": "2015-09-27"
    },
    {
      "value": 286,
      "day": "2017-06-05"
    },
    {
      "value": 7,
      "day": "2017-05-13"
    },
    {
      "value": 326,
      "day": "2017-07-18"
    },
    {
      "value": 146,
      "day": "2016-11-03"
    },
    {
      "value": 20,
      "day": "2017-01-27"
    },
    {
      "value": 28,
      "day": "2018-02-13"
    },
    {
      "value": 395,
      "day": "2016-05-22"
    },
    {
      "value": 58,
      "day": "2017-10-27"
    },
    {
      "value": 3,
      "day": "2018-05-24"
    },
    {
      "value": 92,
      "day": "2017-11-26"
    },
    {
      "value": 230,
      "day": "2017-05-26"
    },
    {
      "value": 185,
      "day": "2016-10-22"
    },
    {
      "value": 157,
      "day": "2015-06-09"
    },
    {
      "value": 30,
      "day": "2015-11-26"
    },
    {
      "value": 110,
      "day": "2017-04-09"
    },
    {
      "value": 234,
      "day": "2016-10-05"
    },
    {
      "value": 162,
      "day": "2017-05-16"
    },
    {
      "value": 186,
      "day": "2018-03-05"
    },
    {
      "value": 96,
      "day": "2017-07-13"
    },
    {
      "value": 388,
      "day": "2015-10-10"
    },
    {
      "value": 50,
      "day": "2017-02-04"
    },
    {
      "value": 374,
      "day": "2018-01-06"
    },
    {
      "value": 11,
      "day": "2017-04-26"
    },
    {
      "value": 142,
      "day": "2018-03-11"
    },
    {
      "value": 199,
      "day": "2016-03-22"
    },
    {
      "value": 177,
      "day": "2018-04-24"
    },
    {
      "value": 60,
      "day": "2017-10-19"
    },
    {
      "value": 348,
      "day": "2016-05-23"
    },
    {
      "value": 256,
      "day": "2016-11-11"
    },
    {
      "value": 116,
      "day": "2017-04-03"
    },
    {
      "value": 143,
      "day": "2016-04-17"
    },
    {
      "value": 322,
      "day": "2015-04-30"
    },
    {
      "value": 329,
      "day": "2015-10-31"
    },
    {
      "value": 80,
      "day": "2015-09-16"
    },
    {
      "value": 260,
      "day": "2016-09-16"
    },
    {
      "value": 396,
      "day": "2017-08-26"
    },
    {
      "value": 151,
      "day": "2017-07-03"
    },
    {
      "value": 243,
      "day": "2017-09-06"
    },
    {
      "value": 282,
      "day": "2015-05-30"
    },
    {
      "value": 224,
      "day": "2016-05-04"
    },
    {
      "value": 317,
      "day": "2015-04-27"
    },
    {
      "value": 280,
      "day": "2016-11-12"
    },
    {
      "value": 24,
      "day": "2016-09-01"
    },
    {
      "value": 154,
      "day": "2016-05-10"
    },
    {
      "value": 38,
      "day": "2017-08-14"
    },
    {
      "value": 282,
      "day": "2017-03-02"
    },
    {
      "value": 262,
      "day": "2018-01-03"
    },
    {
      "value": 383,
      "day": "2017-12-06"
    },
    {
      "value": 66,
      "day": "2017-05-27"
    },
    {
      "value": 189,
      "day": "2015-12-15"
    },
    {
      "value": 288,
      "day": "2017-11-17"
    },
    {
      "value": 110,
      "day": "2018-02-25"
    },
    {
      "value": 291,
      "day": "2016-07-24"
    },
    {
      "value": 250,
      "day": "2016-10-21"
    },
    {
      "value": 289,
      "day": "2017-03-23"
    },
    {
      "value": 28,
      "day": "2015-08-26"
    },
    {
      "value": 144,
      "day": "2017-06-08"
    },
    {
      "value": 385,
      "day": "2016-02-11"
    },
    {
      "value": 378,
      "day": "2017-02-05"
    },
    {
      "value": 194,
      "day": "2018-02-10"
    },
    {
      "value": 41,
      "day": "2015-10-12"
    },
    {
      "value": 144,
      "day": "2016-05-28"
    },
    {
      "value": 345,
      "day": "2018-07-26"
    },
    {
      "value": 101,
      "day": "2018-01-25"
    },
    {
      "value": 181,
      "day": "2015-08-25"
    },
    {
      "value": 335,
      "day": "2015-12-05"
    },
    {
      "value": 91,
      "day": "2016-06-05"
    },
    {
      "value": 299,
      "day": "2015-08-19"
    },
    {
      "value": 201,
      "day": "2017-09-08"
    },
    {
      "value": 328,
      "day": "2015-11-27"
    },
    {
      "value": 334,
      "day": "2016-08-04"
    },
    {
      "value": 265,
      "day": "2016-01-09"
    },
    {
      "value": 230,
      "day": "2017-02-17"
    },
    {
      "value": 172,
      "day": "2016-08-20"
    },
    {
      "value": 138,
      "day": "2018-05-23"
    },
    {
      "value": 151,
      "day": "2018-03-16"
    },
    {
      "value": 34,
      "day": "2017-02-16"
    },
    {
      "value": 201,
      "day": "2017-05-19"
    },
    {
      "value": 340,
      "day": "2015-10-18"
    },
    {
      "value": 189,
      "day": "2017-09-17"
    },
    {
      "value": 146,
      "day": "2015-10-11"
    },
    {
      "value": 179,
      "day": "2016-04-03"
    },
    {
      "value": 263,
      "day": "2016-09-09"
    },
    {
      "value": 141,
      "day": "2015-10-23"
    },
    {
      "value": 245,
      "day": "2015-11-22"
    },
    {
      "value": 307,
      "day": "2016-07-21"
    },
    {
      "value": 207,
      "day": "2015-08-01"
    },
    {
      "value": 6,
      "day": "2015-05-21"
    },
    {
      "value": 161,
      "day": "2015-10-17"
    },
    {
      "value": 12,
      "day": "2017-07-10"
    },
    {
      "value": 340,
      "day": "2017-05-07"
    },
    {
      "value": 387,
      "day": "2016-02-20"
    },
    {
      "value": 206,
      "day": "2016-03-15"
    },
    {
      "value": 190,
      "day": "2016-05-16"
    },
    {
      "value": 262,
      "day": "2018-06-30"
    },
    {
      "value": 230,
      "day": "2016-11-20"
    },
    {
      "value": 321,
      "day": "2015-05-22"
    },
    {
      "value": 378,
      "day": "2015-08-09"
    },
    {
      "value": 124,
      "day": "2016-01-04"
    },
    {
      "value": 258,
      "day": "2017-09-09"
    },
    {
      "value": 2,
      "day": "2015-09-17"
    },
    {
      "value": 13,
      "day": "2016-04-05"
    },
    {
      "value": 128,
      "day": "2018-06-04"
    },
    {
      "value": 257,
      "day": "2016-07-28"
    },
    {
      "value": 156,
      "day": "2016-04-06"
    },
    {
      "value": 329,
      "day": "2017-10-21"
    },
    {
      "value": 232,
      "day": "2017-12-20"
    },
    {
      "value": 303,
      "day": "2017-02-19"
    },
    {
      "value": 114,
      "day": "2017-03-25"
    },
    {
      "value": 202,
      "day": "2015-06-14"
    },
    {
      "value": 12,
      "day": "2016-09-08"
    },
    {
      "value": 268,
      "day": "2018-06-18"
    },
    {
      "value": 3,
      "day": "2016-06-29"
    },
    {
      "value": 399,
      "day": "2018-01-23"
    },
    {
      "value": 66,
      "day": "2015-06-25"
    },
    {
      "value": 285,
      "day": "2016-12-02"
    },
    {
      "value": 365,
      "day": "2015-11-18"
    },
    {
      "value": 276,
      "day": "2017-04-13"
    },
    {
      "value": 185,
      "day": "2016-02-12"
    },
    {
      "value": 304,
      "day": "2016-01-30"
    },
    {
      "value": 29,
      "day": "2017-11-06"
    },
    {
      "value": 124,
      "day": "2018-07-28"
    },
    {
      "value": 273,
      "day": "2015-07-10"
    },
    {
      "value": 192,
      "day": "2017-01-13"
    },
    {
      "value": 107,
      "day": "2016-03-03"
    },
    {
      "value": 216,
      "day": "2018-03-21"
    },
    {
      "value": 337,
      "day": "2018-07-06"
    },
    {
      "value": 20,
      "day": "2015-08-08"
    },
    {
      "value": 156,
      "day": "2017-03-01"
    },
    {
      "value": 120,
      "day": "2018-02-28"
    },
    {
      "value": 182,
      "day": "2015-12-09"
    },
    {
      "value": 321,
      "day": "2016-05-12"
    },
    {
      "value": 3,
      "day": "2015-09-19"
    },
    {
      "value": 295,
      "day": "2018-01-15"
    },
    {
      "value": 144,
      "day": "2015-04-07"
    },
    {
      "value": 153,
      "day": "2016-05-05"
    },
    {
      "value": 164,
      "day": "2018-07-13"
    },
    {
      "value": 388,
      "day": "2017-12-10"
    },
    {
      "value": 69,
      "day": "2016-10-12"
    },
    {
      "value": 68,
      "day": "2017-11-11"
    },
    {
      "value": 50,
      "day": "2015-06-04"
    },
    {
      "value": 37,
      "day": "2016-09-23"
    },
    {
      "value": 18,
      "day": "2016-07-16"
    },
    {
      "value": 94,
      "day": "2017-01-29"
    },
    {
      "value": 205,
      "day": "2016-09-20"
    },
    {
      "value": 286,
      "day": "2016-06-21"
    },
    {
      "value": 374,
      "day": "2015-09-11"
    },
    {
      "value": 369,
      "day": "2016-04-13"
    },
    {
      "value": 357,
      "day": "2016-04-27"
    },
    {
      "value": 49,
      "day": "2018-07-07"
    },
    {
      "value": 176,
      "day": "2018-02-27"
    },
    {
      "value": 339,
      "day": "2017-04-05"
    },
    {
      "value": 295,
      "day": "2015-09-12"
    },
    {
      "value": 79,
      "day": "2015-12-02"
    },
    {
      "value": 76,
      "day": "2018-03-28"
    },
    {
      "value": 296,
      "day": "2018-02-08"
    },
    {
      "value": 363,
      "day": "2016-12-30"
    },
    {
      "value": 367,
      "day": "2016-01-20"
    },
    {
      "value": 304,
      "day": "2016-07-11"
    },
    {
      "value": 248,
      "day": "2018-07-22"
    },
    {
      "value": 212,
      "day": "2018-03-23"
    },
    {
      "value": 280,
      "day": "2018-08-06"
    },
    {
      "value": 134,
      "day": "2015-07-24"
    },
    {
      "value": 61,
      "day": "2016-06-02"
    },
    {
      "value": 268,
      "day": "2017-01-28"
    },
    {
      "value": 345,
      "day": "2018-07-16"
    },
    {
      "value": 395,
      "day": "2018-05-29"
    },
    {
      "value": 158,
      "day": "2015-12-07"
    },
    {
      "value": 261,
      "day": "2015-10-09"
    },
    {
      "value": 43,
      "day": "2016-02-01"
    },
    {
      "value": 109,
      "day": "2015-09-30"
    },
    {
      "value": 400,
      "day": "2016-12-08"
    },
    {
      "value": 378,
      "day": "2016-11-05"
    },
    {
      "value": 258,
      "day": "2016-10-04"
    },
    {
      "value": 138,
      "day": "2016-04-11"
    },
    {
      "value": 116,
      "day": "2015-04-17"
    },
    {
      "value": 123,
      "day": "2015-05-09"
    },
    {
      "value": 161,
      "day": "2015-10-02"
    },
    {
      "value": 2,
      "day": "2018-05-30"
    },
    {
      "value": 90,
      "day": "2016-03-27"
    },
    {
      "value": 52,
      "day": "2015-11-08"
    },
    {
      "value": 396,
      "day": "2017-08-05"
    },
    {
      "value": 127,
      "day": "2016-06-28"
    },
    {
      "value": 128,
      "day": "2018-02-05"
    },
    {
      "value": 248,
      "day": "2016-10-07"
    },
    {
      "value": 208,
      "day": "2016-05-19"
    },
    {
      "value": 212,
      "day": "2017-09-13"
    },
    {
      "value": 85,
      "day": "2017-08-09"
    },
    {
      "value": 386,
      "day": "2017-02-12"
    },
    {
      "value": 261,
      "day": "2015-10-26"
    },
    {
      "value": 302,
      "day": "2016-12-18"
    },
    {
      "value": 4,
      "day": "2017-01-03"
    },
    {
      "value": 8,
      "day": "2015-11-10"
    },
    {
      "value": 187,
      "day": "2015-05-15"
    },
    {
      "value": 209,
      "day": "2018-05-06"
    },
    {
      "value": 254,
      "day": "2017-06-11"
    },
    {
      "value": 244,
      "day": "2015-09-22"
    },
    {
      "value": 84,
      "day": "2016-05-09"
    },
    {
      "value": 271,
      "day": "2015-07-01"
    },
    {
      "value": 0,
      "day": "2018-02-20"
    },
    {
      "value": 185,
      "day": "2017-10-25"
    },
    {
      "value": 170,
      "day": "2016-12-17"
    },
    {
      "value": 290,
      "day": "2015-10-20"
    },
    {
      "value": 247,
      "day": "2015-09-09"
    },
    {
      "value": 183,
      "day": "2015-07-17"
    },
    {
      "value": 205,
      "day": "2017-10-11"
    },
    {
      "value": 310,
      "day": "2017-05-24"
    },
    {
      "value": 42,
      "day": "2017-02-24"
    },
    {
      "value": 315,
      "day": "2015-07-03"
    },
    {
      "value": 306,
      "day": "2017-12-29"
    },
    {
      "value": 210,
      "day": "2016-08-15"
    },
    {
      "value": 180,
      "day": "2016-03-26"
    },
    {
      "value": 173,
      "day": "2016-03-23"
    },
    {
      "value": 208,
      "day": "2015-08-28"
    },
    {
      "value": 40,
      "day": "2017-02-26"
    },
    {
      "value": 56,
      "day": "2017-06-18"
    },
    {
      "value": 234,
      "day": "2017-09-20"
    },
    {
      "value": 11,
      "day": "2018-01-16"
    },
    {
      "value": 316,
      "day": "2016-02-03"
    },
    {
      "value": 125,
      "day": "2016-06-14"
    },
    {
      "value": 121,
      "day": "2015-05-03"
    },
    {
      "value": 113,
      "day": "2017-04-16"
    },
    {
      "value": 397,
      "day": "2015-12-25"
    },
    {
      "value": 165,
      "day": "2016-07-22"
    },
    {
      "value": 190,
      "day": "2017-09-10"
    },
    {
      "value": 235,
      "day": "2016-01-18"
    },
    {
      "value": 40,
      "day": "2018-07-11"
    },
    {
      "value": 165,
      "day": "2015-06-19"
    },
    {
      "value": 332,
      "day": "2016-03-30"
    },
    {
      "value": 96,
      "day": "2017-03-27"
    },
    {
      "value": 246,
      "day": "2018-04-15"
    },
    {
      "value": 57,
      "day": "2015-08-29"
    },
    {
      "value": 242,
      "day": "2016-08-13"
    },
    {
      "value": 345,
      "day": "2016-07-10"
    },
    {
      "value": 75,
      "day": "2015-08-24"
    },
    {
      "value": 199,
      "day": "2017-07-22"
    },
    {
      "value": 175,
      "day": "2016-10-08"
    },
    {
      "value": 186,
      "day": "2015-06-20"
    },
    {
      "value": 322,
      "day": "2015-09-05"
    },
    {
      "value": 211,
      "day": "2017-07-08"
    },
    {
      "value": 239,
      "day": "2017-08-04"
    },
    {
      "value": 381,
      "day": "2017-10-29"
    },
    {
      "value": 233,
      "day": "2015-12-06"
    },
    {
      "value": 342,
      "day": "2015-06-02"
    },
    {
      "value": 321,
      "day": "2015-05-24"
    },
    {
      "value": 278,
      "day": "2018-02-12"
    },
    {
      "value": 40,
      "day": "2018-02-03"
    },
    {
      "value": 143,
      "day": "2017-03-18"
    },
    {
      "value": 301,
      "day": "2017-11-27"
    },
    {
      "value": 52,
      "day": "2016-05-14"
    },
    {
      "value": 205,
      "day": "2017-07-09"
    },
    {
      "value": 118,
      "day": "2016-03-28"
    },
    {
      "value": 288,
      "day": "2017-06-28"
    },
    {
      "value": 334,
      "day": "2017-12-25"
    },
    {
      "value": 85,
      "day": "2018-01-07"
    },
    {
      "value": 277,
      "day": "2015-04-19"
    },
    {
      "value": 297,
      "day": "2017-01-09"
    },
    {
      "value": 180,
      "day": "2016-08-22"
    },
    {
      "value": 157,
      "day": "2016-04-14"
    },
    {
      "value": 17,
      "day": "2017-06-24"
    },
    {
      "value": 190,
      "day": "2016-02-05"
    },
    {
      "value": 53,
      "day": "2016-08-23"
    },
    {
      "value": 356,
      "day": "2018-01-30"
    },
    {
      "value": 362,
      "day": "2017-02-13"
    },
    {
      "value": 95,
      "day": "2018-05-17"
    },
    {
      "value": 198,
      "day": "2016-10-06"
    },
    {
      "value": 136,
      "day": "2017-09-25"
    },
    {
      "value": 186,
      "day": "2016-03-29"
    },
    {
      "value": 245,
      "day": "2016-02-26"
    },
    {
      "value": 362,
      "day": "2017-08-07"
    },
    {
      "value": 136,
      "day": "2016-12-21"
    },
    {
      "value": 28,
      "day": "2018-03-03"
    },
    {
      "value": 223,
      "day": "2018-06-11"
    },
    {
      "value": 107,
      "day": "2018-07-02"
    },
    {
      "value": 108,
      "day": "2016-10-25"
    },
    {
      "value": 355,
      "day": "2017-12-18"
    },
    {
      "value": 149,
      "day": "2016-10-14"
    },
    {
      "value": 211,
      "day": "2018-01-10"
    },
    {
      "value": 294,
      "day": "2016-01-31"
    },
    {
      "value": 279,
      "day": "2017-02-11"
    },
    {
      "value": 81,
      "day": "2016-02-29"
    },
    {
      "value": 384,
      "day": "2016-03-13"
    },
    {
      "value": 371,
      "day": "2017-03-16"
    },
    {
      "value": 227,
      "day": "2017-03-09"
    },
    {
      "value": 222,
      "day": "2015-07-06"
    },
    {
      "value": 365,
      "day": "2018-03-18"
    },
    {
      "value": 326,
      "day": "2015-08-15"
    },
    {
      "value": 84,
      "day": "2018-04-26"
    },
    {
      "value": 85,
      "day": "2016-04-26"
    },
    {
      "value": 202,
      "day": "2018-05-21"
    },
    {
      "value": 61,
      "day": "2015-07-29"
    },
    {
      "value": 313,
      "day": "2018-02-18"
    },
    {
      "value": 296,
      "day": "2016-02-23"
    },
    {
      "value": 335,
      "day": "2017-10-09"
    },
    {
      "value": 89,
      "day": "2018-04-29"
    },
    {
      "value": 385,
      "day": "2018-06-13"
    },
    {
      "value": 142,
      "day": "2018-07-19"
    },
    {
      "value": 84,
      "day": "2017-12-17"
    },
    {
      "value": 21,
      "day": "2016-07-08"
    },
    {
      "value": 205,
      "day": "2015-04-16"
    },
    {
      "value": 155,
      "day": "2017-12-31"
    },
    {
      "value": 99,
      "day": "2018-02-21"
    },
    {
      "value": 3,
      "day": "2017-03-29"
    },
    {
      "value": 26,
      "day": "2017-05-09"
    },
    {
      "value": 244,
      "day": "2015-10-03"
    },
    {
      "value": 331,
      "day": "2016-08-16"
    },
    {
      "value": 333,
      "day": "2017-11-13"
    },
    {
      "value": 305,
      "day": "2017-01-20"
    },
    {
      "value": 116,
      "day": "2017-03-04"
    },
    {
      "value": 156,
      "day": "2017-02-15"
    },
    {
      "value": 236,
      "day": "2016-01-14"
    },
    {
      "value": 315,
      "day": "2015-04-13"
    },
    {
      "value": 275,
      "day": "2016-06-16"
    },
    {
      "value": 260,
      "day": "2017-12-21"
    },
    {
      "value": 389,
      "day": "2016-02-22"
    },
    {
      "value": 211,
      "day": "2016-08-18"
    },
    {
      "value": 132,
      "day": "2018-05-03"
    },
    {
      "value": 161,
      "day": "2017-09-24"
    },
    {
      "value": 33,
      "day": "2018-04-05"
    },
    {
      "value": 95,
      "day": "2018-07-30"
    },
    {
      "value": 305,
      "day": "2017-10-04"
    },
    {
      "value": 310,
      "day": "2018-01-05"
    },
    {
      "value": 367,
      "day": "2017-12-13"
    },
    {
      "value": 97,
      "day": "2016-08-08"
    },
    {
      "value": 4,
      "day": "2016-07-30"
    },
    {
      "value": 165,
      "day": "2015-10-25"
    },
    {
      "value": 60,
      "day": "2018-01-17"
    },
    {
      "value": 184,
      "day": "2017-10-07"
    },
    {
      "value": 299,
      "day": "2018-07-18"
    },
    {
      "value": 272,
      "day": "2015-12-29"
    },
    {
      "value": 142,
      "day": "2017-01-15"
    },
    {
      "value": 141,
      "day": "2015-04-23"
    },
    {
      "value": 66,
      "day": "2015-11-28"
    },
    {
      "value": 23,
      "day": "2015-07-09"
    },
    {
      "value": 78,
      "day": "2018-06-22"
    },
    {
      "value": 396,
      "day": "2017-07-30"
    },
    {
      "value": 331,
      "day": "2018-02-01"
    },
    {
      "value": 202,
      "day": "2015-10-16"
    },
    {
      "value": 389,
      "day": "2015-07-08"
    },
    {
      "value": 179,
      "day": "2016-03-05"
    },
    {
      "value": 98,
      "day": "2017-05-15"
    },
    {
      "value": 235,
      "day": "2017-02-21"
    },
    {
      "value": 328,
      "day": "2017-03-08"
    },
    {
      "value": 60,
      "day": "2016-07-01"
    },
    {
      "value": 46,
      "day": "2017-07-29"
    },
    {
      "value": 237,
      "day": "2018-06-16"
    },
    {
      "value": 33,
      "day": "2016-01-07"
    },
    {
      "value": 174,
      "day": "2018-06-29"
    },
    {
      "value": 151,
      "day": "2017-08-19"
    },
    {
      "value": 157,
      "day": "2017-10-12"
    },
    {
      "value": 370,
      "day": "2015-08-17"
    },
    {
      "value": 271,
      "day": "2017-12-14"
    },
    {
      "value": 66,
      "day": "2016-05-24"
    },
    {
      "value": 297,
      "day": "2017-03-22"
    },
    {
      "value": 290,
      "day": "2018-08-09"
    },
    {
      "value": 287,
      "day": "2017-08-10"
    },
    {
      "value": 101,
      "day": "2018-03-06"
    },
    {
      "value": 134,
      "day": "2016-05-15"
    },
    {
      "value": 320,
      "day": "2015-10-14"
    },
    {
      "value": 80,
      "day": "2017-02-14"
    },
    {
      "value": 41,
      "day": "2018-07-27"
    },
    {
      "value": 324,
      "day": "2018-02-19"
    },
    {
      "value": 239,
      "day": "2016-10-20"
    },
    {
      "value": 46,
      "day": "2016-07-14"
    },
    {
      "value": 209,
      "day": "2015-08-07"
    },
    {
      "value": 141,
      "day": "2015-06-01"
    },
    {
      "value": 267,
      "day": "2018-02-11"
    },
    {
      "value": 35,
      "day": "2016-08-07"
    },
    {
      "value": 42,
      "day": "2015-12-13"
    },
    {
      "value": 4,
      "day": "2016-06-11"
    },
    {
      "value": 384,
      "day": "2016-09-24"
    },
    {
      "value": 196,
      "day": "2017-06-27"
    },
    {
      "value": 27,
      "day": "2017-06-15"
    },
    {
      "value": 53,
      "day": "2017-04-02"
    },
    {
      "value": 85,
      "day": "2015-11-30"
    },
    {
      "value": 357,
      "day": "2016-06-01"
    },
    {
      "value": 25,
      "day": "2015-07-20"
    },
    {
      "value": 252,
      "day": "2017-09-12"
    },
    {
      "value": 51,
      "day": "2016-11-06"
    },
    {
      "value": 53,
      "day": "2016-01-25"
    },
    {
      "value": 268,
      "day": "2018-04-30"
    },
    {
      "value": 220,
      "day": "2015-09-24"
    },
    {
      "value": 353,
      "day": "2016-12-11"
    },
    {
      "value": 377,
      "day": "2017-08-06"
    },
    {
      "value": 380,
      "day": "2016-11-28"
    },
    {
      "value": 45,
      "day": "2015-11-23"
    },
    {
      "value": 98,
      "day": "2016-04-07"
    },
    {
      "value": 39,
      "day": "2017-05-04"
    },
    {
      "value": 355,
      "day": "2017-09-27"
    },
    {
      "value": 384,
      "day": "2017-05-08"
    },
    {
      "value": 269,
      "day": "2017-12-03"
    },
    {
      "value": 5,
      "day": "2018-01-26"
    },
    {
      "value": 210,
      "day": "2018-03-17"
    },
    {
      "value": 186,
      "day": "2017-05-21"
    },
    {
      "value": 300,
      "day": "2017-02-28"
    },
    {
      "value": 184,
      "day": "2015-10-21"
    },
    {
      "value": 10,
      "day": "2016-02-19"
    },
    {
      "value": 307,
      "day": "2015-04-26"
    },
    {
      "value": 260,
      "day": "2016-07-13"
    },
    {
      "value": 279,
      "day": "2016-03-08"
    },
    {
      "value": 256,
      "day": "2016-06-30"
    },
    {
      "value": 161,
      "day": "2015-11-21"
    },
    {
      "value": 225,
      "day": "2017-03-12"
    },
    {
      "value": 388,
      "day": "2017-06-12"
    },
    {
      "value": 310,
      "day": "2018-04-17"
    },
    {
      "value": 225,
      "day": "2016-04-29"
    },
    {
      "value": 138,
      "day": "2016-07-09"
    },
    {
      "value": 146,
      "day": "2016-02-02"
    },
    {
      "value": 345,
      "day": "2017-01-31"
    },
    {
      "value": 10,
      "day": "2018-07-20"
    },
    {
      "value": 89,
      "day": "2016-11-16"
    },
    {
      "value": 57,
      "day": "2015-12-16"
    },
    {
      "value": 113,
      "day": "2016-06-23"
    },
    {
      "value": 70,
      "day": "2017-07-05"
    },
    {
      "value": 356,
      "day": "2016-10-18"
    },
    {
      "value": 322,
      "day": "2016-01-16"
    },
    {
      "value": 299,
      "day": "2018-04-10"
    },
    {
      "value": 291,
      "day": "2015-04-10"
    },
    {
      "value": 45,
      "day": "2018-08-02"
    },
    {
      "value": 38,
      "day": "2015-06-17"
    },
    {
      "value": 60,
      "day": "2017-09-19"
    },
    {
      "value": 131,
      "day": "2017-06-25"
    },
    {
      "value": 69,
      "day": "2016-04-04"
    },
    {
      "value": 253,
      "day": "2015-07-28"
    },
    {
      "value": 8,
      "day": "2017-10-01"
    },
    {
      "value": 169,
      "day": "2016-11-15"
    },
    {
      "value": 394,
      "day": "2017-12-08"
    },
    {
      "value": 16,
      "day": "2015-12-21"
    },
    {
      "value": 24,
      "day": "2016-09-04"
    },
    {
      "value": 7,
      "day": "2016-07-26"
    },
    {
      "value": 92,
      "day": "2016-04-15"
    },
    {
      "value": 294,
      "day": "2015-07-30"
    },
    {
      "value": 316,
      "day": "2017-01-24"
    },
    {
      "value": 165,
      "day": "2018-05-27"
    },
    {
      "value": 272,
      "day": "2017-07-06"
    },
    {
      "value": 237,
      "day": "2017-05-05"
    },
    {
      "value": 385,
      "day": "2016-10-19"
    },
    {
      "value": 398,
      "day": "2016-10-30"
    },
    {
      "value": 233,
      "day": "2017-07-26"
    },
    {
      "value": 97,
      "day": "2017-01-11"
    },
    {
      "value": 70,
      "day": "2017-03-14"
    },
    {
      "value": 58,
      "day": "2016-03-14"
    },
    {
      "value": 61,
      "day": "2015-12-28"
    },
    {
      "value": 223,
      "day": "2018-06-20"
    },
    {
      "value": 65,
      "day": "2016-10-24"
    },
    {
      "value": 297,
      "day": "2018-05-14"
    },
    {
      "value": 398,
      "day": "2016-08-21"
    },
    {
      "value": 364,
      "day": "2015-10-06"
    },
    {
      "value": 61,
      "day": "2017-02-10"
    },
    {
      "value": 112,
      "day": "2015-05-04"
    },
    {
      "value": 230,
      "day": "2017-11-29"
    },
    {
      "value": 240,
      "day": "2015-07-18"
    },
    {
      "value": 346,
      "day": "2016-06-09"
    },
    {
      "value": 360,
      "day": "2018-05-10"
    },
    {
      "value": 245,
      "day": "2016-12-14"
    },
    {
      "value": 331,
      "day": "2017-01-07"
    },
    {
      "value": 189,
      "day": "2016-03-10"
    },
    {
      "value": 120,
      "day": "2015-05-26"
    },
    {
      "value": 394,
      "day": "2016-11-27"
    },
    {
      "value": 349,
      "day": "2018-01-22"
    },
    {
      "value": 315,
      "day": "2015-12-11"
    },
    {
      "value": 281,
      "day": "2016-06-13"
    },
    {
      "value": 42,
      "day": "2016-11-17"
    },
    {
      "value": 199,
      "day": "2017-01-30"
    },
    {
      "value": 34,
      "day": "2016-03-02"
    },
    {
      "value": 295,
      "day": "2016-01-02"
    },
    {
      "value": 35,
      "day": "2016-03-24"
    },
    {
      "value": 216,
      "day": "2015-04-29"
    },
    {
      "value": 322,
      "day": "2016-01-08"
    },
    {
      "value": 92,
      "day": "2015-11-11"
    },
    {
      "value": 291,
      "day": "2015-06-26"
    },
    {
      "value": 20,
      "day": "2017-05-17"
    },
    {
      "value": 322,
      "day": "2016-11-22"
    },
    {
      "value": 12,
      "day": "2016-11-25"
    },
    {
      "value": 82,
      "day": "2016-05-31"
    },
    {
      "value": 330,
      "day": "2018-01-01"
    },
    {
      "value": 334,
      "day": "2018-06-01"
    },
    {
      "value": 305,
      "day": "2016-12-16"
    },
    {
      "value": 351,
      "day": "2017-02-18"
    },
    {
      "value": 388,
      "day": "2015-07-07"
    },
    {
      "value": 254,
      "day": "2018-03-14"
    },
    {
      "value": 125,
      "day": "2017-07-16"
    },
    {
      "value": 109,
      "day": "2017-12-01"
    },
    {
      "value": 42,
      "day": "2018-03-31"
    },
    {
      "value": 49,
      "day": "2017-11-12"
    },
    {
      "value": 195,
      "day": "2017-04-04"
    },
    {
      "value": 307,
      "day": "2016-04-02"
    },
    {
      "value": 202,
      "day": "2016-12-26"
    },
    {
      "value": 147,
      "day": "2018-07-10"
    },
    {
      "value": 74,
      "day": "2017-09-21"
    },
    {
      "value": 229,
      "day": "2016-04-28"
    },
    {
      "value": 303,
      "day": "2015-07-16"
    },
    {
      "value": 228,
      "day": "2018-08-05"
    },
    {
      "value": 34,
      "day": "2016-10-26"
    },
    {
      "value": 97,
      "day": "2018-05-08"
    },
    {
      "value": 291,
      "day": "2017-09-07"
    },
    {
      "value": 252,
      "day": "2018-04-14"
    },
    {
      "value": 145,
      "day": "2017-01-05"
    },
    {
      "value": 5,
      "day": "2018-07-04"
    },
    {
      "value": 257,
      "day": "2018-06-23"
    },
    {
      "value": 364,
      "day": "2018-06-17"
    },
    {
      "value": 392,
      "day": "2018-01-08"
    },
    {
      "value": 0,
      "day": "2017-03-07"
    },
    {
      "value": 201,
      "day": "2016-10-16"
    },
    {
      "value": 110,
      "day": "2015-05-12"
    },
    {
      "value": 302,
      "day": "2016-01-05"
    },
    {
      "value": 193,
      "day": "2016-08-06"
    },
    {
      "value": 180,
      "day": "2015-12-14"
    },
    {
      "value": 277,
      "day": "2017-06-17"
    },
    {
      "value": 83,
      "day": "2016-06-08"
    },
    {
      "value": 198,
      "day": "2016-01-21"
    },
    {
      "value": 163,
      "day": "2017-09-02"
    },
    {
      "value": 235,
      "day": "2016-12-22"
    },
    {
      "value": 206,
      "day": "2018-05-20"
    },
    {
      "value": 271,
      "day": "2017-08-20"
    },
    {
      "value": 88,
      "day": "2017-03-03"
    },
    {
      "value": 163,
      "day": "2015-05-23"
    },
    {
      "value": 365,
      "day": "2017-01-01"
    },
    {
      "value": 347,
      "day": "2016-01-17"
    },
    {
      "value": 199,
      "day": "2018-03-25"
    },
    {
      "value": 18,
      "day": "2018-01-20"
    },
    {
      "value": 51,
      "day": "2017-10-13"
    },
    {
      "value": 265,
      "day": "2016-08-01"
    },
    {
      "value": 296,
      "day": "2017-05-18"
    },
    {
      "value": 276,
      "day": "2017-10-10"
    },
    {
      "value": 181,
      "day": "2016-01-23"
    },
    {
      "value": 193,
      "day": "2015-06-03"
    },
    {
      "value": 151,
      "day": "2016-12-07"
    },
    {
      "value": 268,
      "day": "2016-05-21"
    },
    {
      "value": 11,
      "day": "2017-03-28"
    },
    {
      "value": 17,
      "day": "2018-04-21"
    },
    {
      "value": 57,
      "day": "2015-07-26"
    },
    {
      "value": 361,
      "day": "2017-02-01"
    },
    {
      "value": 102,
      "day": "2016-12-24"
    },
    {
      "value": 96,
      "day": "2016-09-30"
    },
    {
      "value": 112,
      "day": "2016-07-06"
    },
    {
      "value": 321,
      "day": "2018-03-09"
    },
    {
      "value": 236,
      "day": "2015-11-19"
    },
    {
      "value": 46,
      "day": "2016-05-01"
    },
    {
      "value": 397,
      "day": "2015-05-19"
    },
    {
      "value": 304,
      "day": "2018-01-19"
    },
    {
      "value": 71,
      "day": "2017-11-28"
    },
    {
      "value": 243,
      "day": "2018-04-23"
    },
    {
      "value": 359,
      "day": "2018-02-07"
    },
    {
      "value": 18,
      "day": "2018-06-10"
    },
    {
      "value": 38,
      "day": "2018-01-18"
    },
    {
      "value": 192,
      "day": "2016-02-16"
    },
    {
      "value": 99,
      "day": "2017-03-17"
    },
    {
      "value": 121,
      "day": "2017-07-14"
    },
    {
      "value": 12,
      "day": "2017-12-09"
    },
    {
      "value": 178,
      "day": "2017-04-10"
    },
    {
      "value": 116,
      "day": "2016-12-27"
    },
    {
      "value": 230,
      "day": "2018-07-03"
    },
    {
      "value": 380,
      "day": "2015-05-08"
    },
    {
      "value": 213,
      "day": "2016-02-15"
    },
    {
      "value": 184,
      "day": "2015-05-02"
    },
    {
      "value": 378,
      "day": "2017-06-03"
    },
    {
      "value": 23,
      "day": "2017-05-25"
    },
    {
      "value": 21,
      "day": "2015-06-06"
    },
    {
      "value": 102,
      "day": "2017-06-14"
    },
    {
      "value": 213,
      "day": "2015-06-16"
    },
    {
      "value": 227,
      "day": "2017-05-29"
    },
    {
      "value": 359,
      "day": "2016-07-17"
    },
    {
      "value": 274,
      "day": "2017-02-27"
    },
    {
      "value": 62,
      "day": "2015-04-25"
    },
    {
      "value": 247,
      "day": "2018-01-09"
    },
    {
      "value": 105,
      "day": "2015-07-23"
    },
    {
      "value": 91,
      "day": "2017-06-19"
    },
    {
      "value": 169,
      "day": "2016-04-12"
    },
    {
      "value": 256,
      "day": "2016-06-25"
    },
    {
      "value": 42,
      "day": "2015-09-10"
    },
    {
      "value": 249,
      "day": "2018-05-13"
    },
    {
      "value": 361,
      "day": "2017-06-16"
    },
    {
      "value": 200,
      "day": "2015-06-07"
    },
    {
      "value": 264,
      "day": "2017-01-17"
    },
    {
      "value": 96,
      "day": "2016-11-04"
    },
    {
      "value": 250,
      "day": "2016-07-02"
    },
    {
      "value": 288,
      "day": "2015-12-20"
    },
    {
      "value": 386,
      "day": "2016-01-11"
    },
    {
      "value": 291,
      "day": "2016-03-17"
    },
    {
      "value": 103,
      "day": "2015-10-30"
    },
    {
      "value": 208,
      "day": "2015-09-04"
    },
    {
      "value": 354,
      "day": "2017-11-23"
    },
    {
      "value": 333,
      "day": "2016-09-26"
    },
    {
      "value": 231,
      "day": "2018-01-11"
    },
    {
      "value": 225,
      "day": "2016-10-17"
    },
    {
      "value": 155,
      "day": "2016-11-26"
    },
    {
      "value": 129,
      "day": "2016-10-03"
    },
    {
      "value": 255,
      "day": "2015-10-19"
    },
    {
      "value": 366,
      "day": "2016-12-09"
    },
    {
      "value": 337,
      "day": "2016-04-18"
    },
    {
      "value": 336,
      "day": "2015-11-02"
    },
    {
      "value": 335,
      "day": "2017-08-27"
    },
    {
      "value": 110,
      "day": "2017-05-10"
    },
    {
      "value": 354,
      "day": "2016-10-23"
    },
    {
      "value": 38,
      "day": "2018-03-20"
    },
    {
      "value": 288,
      "day": "2016-01-10"
    },
    {
      "value": 74,
      "day": "2016-07-07"
    },
    {
      "value": 109,
      "day": "2016-08-05"
    },
    {
      "value": 383,
      "day": "2017-12-26"
    },
    {
      "value": 296,
      "day": "2015-04-21"
    },
    {
      "value": 307,
      "day": "2016-12-04"
    },
    {
      "value": 65,
      "day": "2015-06-22"
    },
    {
      "value": 11,
      "day": "2016-12-10"
    },
    {
      "value": 105,
      "day": "2015-08-31"
    },
    {
      "value": 299,
      "day": "2018-04-11"
    },
    {
      "value": 272,
      "day": "2015-05-06"
    },
    {
      "value": 53,
      "day": "2016-09-11"
    },
    {
      "value": 331,
      "day": "2017-11-20"
    },
    {
      "value": 82,
      "day": "2016-03-07"
    },
    {
      "value": 40,
      "day": "2016-01-22"
    },
    {
      "value": 88,
      "day": "2018-01-04"
    },
    {
      "value": 42,
      "day": "2015-12-04"
    },
    {
      "value": 291,
      "day": "2015-10-01"
    },
    {
      "value": 25,
      "day": "2015-09-15"
    },
    {
      "value": 168,
      "day": "2017-08-29"
    },
    {
      "value": 214,
      "day": "2017-11-22"
    },
    {
      "value": 353,
      "day": "2015-11-15"
    },
    {
      "value": 313,
      "day": "2018-07-01"
    },
    {
      "value": 13,
      "day": "2017-08-24"
    },
    {
      "value": 317,
      "day": "2016-09-13"
    },
    {
      "value": 209,
      "day": "2015-10-08"
    },
    {
      "value": 170,
      "day": "2016-02-04"
    },
    {
      "value": 365,
      "day": "2016-10-27"
    },
    {
      "value": 282,
      "day": "2017-06-01"
    },
    {
      "value": 383,
      "day": "2018-06-26"
    },
    {
      "value": 213,
      "day": "2015-04-03"
    },
    {
      "value": 66,
      "day": "2017-03-24"
    },
    {
      "value": 77,
      "day": "2017-11-10"
    },
    {
      "value": 175,
      "day": "2018-02-16"
    },
    {
      "value": 73,
      "day": "2017-01-25"
    },
    {
      "value": 202,
      "day": "2015-11-09"
    },
    {
      "value": 367,
      "day": "2016-08-10"
    },
    {
      "value": 248,
      "day": "2015-04-01"
    },
    {
      "value": 136,
      "day": "2017-08-12"
    },
    {
      "value": 180,
      "day": "2018-06-05"
    },
    {
      "value": 167,
      "day": "2017-11-30"
    },
    {
      "value": 308,
      "day": "2015-12-18"
    },
    {
      "value": 23,
      "day": "2017-11-07"
    },
    {
      "value": 75,
      "day": "2015-04-22"
    },
    {
      "value": 106,
      "day": "2015-09-29"
    },
    {
      "value": 42,
      "day": "2018-05-09"
    },
    {
      "value": 87,
      "day": "2016-08-03"
    },
    {
      "value": 21,
      "day": "2016-05-07"
    },
    {
      "value": 236,
      "day": "2018-03-15"
    },
    {
      "value": 148,
      "day": "2016-10-11"
    },
    {
      "value": 39,
      "day": "2016-08-28"
    },
    {
      "value": 305,
      "day": "2018-04-01"
    },
    {
      "value": 126,
      "day": "2015-10-15"
    },
    {
      "value": 284,
      "day": "2015-06-05"
    },
    {
      "value": 206,
      "day": "2016-11-10"
    },
    {
      "value": 103,
      "day": "2017-09-23"
    },
    {
      "value": 83,
      "day": "2015-10-22"
    },
    {
      "value": 120,
      "day": "2017-08-13"
    },
    {
      "value": 146,
      "day": "2017-05-14"
    },
    {
      "value": 350,
      "day": "2016-09-02"
    },
    {
      "value": 287,
      "day": "2016-04-16"
    },
    {
      "value": 112,
      "day": "2017-07-21"
    },
    {
      "value": 30,
      "day": "2015-11-17"
    },
    {
      "value": 208,
      "day": "2017-04-07"
    },
    {
      "value": 193,
      "day": "2016-09-10"
    },
    {
      "value": 119,
      "day": "2015-05-10"
    },
    {
      "value": 11,
      "day": "2017-03-10"
    },
    {
      "value": 226,
      "day": "2018-08-07"
    },
    {
      "value": 250,
      "day": "2018-07-17"
    },
    {
      "value": 28,
      "day": "2018-06-15"
    },
    {
      "value": 258,
      "day": "2018-02-15"
    },
    {
      "value": 209,
      "day": "2018-03-02"
    },
    {
      "value": 352,
      "day": "2018-07-23"
    },
    {
      "value": 95,
      "day": "2018-03-29"
    },
    {
      "value": 392,
      "day": "2015-06-18"
    },
    {
      "value": 131,
      "day": "2018-05-05"
    },
    {
      "value": 298,
      "day": "2017-12-27"
    },
    {
      "value": 6,
      "day": "2016-09-21"
    },
    {
      "value": 140,
      "day": "2015-07-04"
    },
    {
      "value": 321,
      "day": "2017-04-30"
    },
    {
      "value": 252,
      "day": "2017-01-19"
    },
    {
      "value": 207,
      "day": "2018-02-02"
    },
    {
      "value": 334,
      "day": "2017-05-30"
    },
    {
      "value": 80,
      "day": "2016-06-10"
    },
    {
      "value": 354,
      "day": "2016-08-29"
    },
    {
      "value": 163,
      "day": "2017-10-16"
    },
    {
      "value": 45,
      "day": "2018-06-25"
    },
    {
      "value": 129,
      "day": "2018-03-19"
    },
    {
      "value": 157,
      "day": "2016-12-06"
    },
    {
      "value": 129,
      "day": "2015-07-05"
    },
    {
      "value": 16,
      "day": "2016-07-18"
    },
    {
      "value": 145,
      "day": "2017-02-23"
    }
  ]

export default calendarWidgetDefaultData