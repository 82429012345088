import React, { memo, useCallback, useState } from "react";
import {
  Edge,
  Handle,
  NodeResizeControl,
  Position,
  XYPosition,
  useReactFlow,
} from "reactflow";
import ChevronRightOutlinedIcon from "@mui/icons-material/ChevronRightOutlined";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import DragHandleOutlinedIcon from "@mui/icons-material/DragHandleOutlined";
import {
  Box,
  ClickAwayListener,
  IconButton,
  Menu,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { v4 as uuidv4 } from "uuid";
import { InfoOutlined } from "@mui/icons-material";
import { KAN_FLOW_NODE_TYPE } from "../../../../kanbanWorkflowUtils";
import cssComponentsStyles from "../../../../../../Global/Styles/components";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import {
  findNodeAllChildren,
  NodeWithAllChildren,
} from "../../../../../../Components/SmallComponents/ReactFlow/reactFlowUtils";

const cssStyles = {
  resizeHandle: css({
    position: "absolute",
    right: "-3px",
    bottom: "-3px",
    transform: "rotate(45deg)",
    cursor: "se-resize",
  }),
  dragIcon: css({
    cursor: "grab",
  }),
  headingItem: css({
    width: "33.3%",
  }),
  headingMenuButton: css({
    display: "flex",
    justifyContent: "flex-end",
  }),
};

const DEFAULT_HANDLE_STYLE = {
  width: 15,
  height: 15,
  bottom: -7.5,
};
const controlStyle = {
  background: "transparent",
  border: "none",
};

interface FlowCustomNodeProps {
  id: string;
  children: React.ReactNode;
  nodeType: KAN_FLOW_NODE_TYPE | "initial";
  handleOpenEditModal?: () => void;
  icon?: React.ReactElement;
}

const KanFlowNode: React.FC<FlowCustomNodeProps> = ({
  id,
  children,
  nodeType,
  handleOpenEditModal,
  icon,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles,
    ...cssComponentsStyles(theme),
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  const openMenu = Boolean(menuAnchor);
  const { deleteElements, setNodes, getNode, getEdges, setEdges } = useReactFlow();
  const thisNode = getNode(id);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };
  const handleDelete = useCallback(() => {
    deleteElements({ nodes: [{ id }] });
    handleCloseMenu();
  }, [id, deleteElements]);

  const handleCopy = () => {
    const copyNode = getNode(id);
    if (copyNode) {
      const id = uuidv4().split("-")[0];
      const newPosition: XYPosition = {
        ...copyNode.position,
        x: copyNode.position.x + 60,
        y: copyNode.position.y - 60,
      };
      setNodes((nds) =>
        nds.concat({
          ...copyNode,
          position: newPosition,
          id,
          data: { ...copyNode.data, id },
        })
      );
      handleCloseMenu();
    }
  };

  const handleCopyWithChildren = () => {
    const allEdges = getEdges();
    // 1. Get all children
    const allChildrenMapping = findNodeAllChildren(allEdges, id);
    const nodeWithChildrenIds = Object.keys(allChildrenMapping);

    /** key is oldNodeID, value is newNodeID */
    const oldToNewNodesMapping: { [key: string]: string } = {};

    // 2. Copy nodes
    const copiedNodes = nodeWithChildrenIds.map((nodeID) => {
      const copyNode = getNode(nodeID);
      const newNodeID = uuidv4().split("-")[0];

      if (!copyNode) {
        throw new Error("Node not found");
      }
      oldToNewNodesMapping[nodeID] = newNodeID;
      const newPosition: XYPosition = {
        ...copyNode.position,
        x: copyNode.position.x + 60,
        y: copyNode.position.y - 60,
      };

      return {
        ...copyNode,
        id: newNodeID,
        selected: true,
        position: newPosition,
        data: {
          ...copyNode.data,
          id: newNodeID,
        },
      };
    });

    // 3. Reconstruct mapping with the copied values
    const allCopiedChildrenMapping: NodeWithAllChildren = nodeWithChildrenIds.reduce(
      (acc, curr) => {
        const copiedChildren = allChildrenMapping[curr].map(
          (childID) => oldToNewNodesMapping[childID]
        );

        return {
          ...acc,
          [oldToNewNodesMapping[curr]]: copiedChildren,
        };
      },
      {} as NodeWithAllChildren
    );

    // 4. Copy edges
    const copiedNodeWithChildrenIds = Object.entries(allCopiedChildrenMapping);
    const copiedEdges: Edge<any>[] = [];

    for (const [parentID, childrenIDs] of copiedNodeWithChildrenIds) {
      childrenIDs.forEach((childID) => {
        const newEdgeID = uuidv4().split("-")[0];

        copiedEdges.push({
          id: newEdgeID,
          type: "step",
          source: parentID,
          target: childID,
        });
      });
    }

    // 5. Deselect the copied node
    setNodes((nds) =>
      nds.concat(copiedNodes).map((node) => {
        if (node.id === id) {
          return {
            ...node,
            selected: false,
          };
        }
        return node;
      })
    );

    setEdges((eds) => eds.concat(copiedEdges));
    handleCloseMenu();
  };

  return (
    <Box component="div" css={styles.height100}>
      {nodeType === "initial" ? null : (
        <Handle
          type="target"
          position={Position.Top}
          style={{
            ...DEFAULT_HANDLE_STYLE,
            left: "50%",
            background: "red",
            top: "-5px",
          }}
        />
      )}

      <Stack
        css={styles.textBreak}
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box component="div" css={styles.headingItem} />
        <DragHandleOutlinedIcon
          css={[styles.dragIcon, styles.greyIcon, styles.headingItem]}
          className="custom-drag-handle"
          fontSize="small"
        />

        <Stack
          css={styles.headingItem}
          spacing={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {icon ? (
            <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
              <div>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  onClose={() => setOpenTooltip(false)}
                  open={openTooltip}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={`Node type is: ${nodeType}`}
                >
                  <IconButton
                    aria-label="open task type tooltip"
                    onClick={() => setOpenTooltip(true)}
                    size="small"
                  >
                    {icon}
                  </IconButton>
                </Tooltip>
              </div>
            </ClickAwayListener>
          ) : null}

          {thisNode?.type === "initial" ? (
            <IconButton
              aria-label="widget info menu"
              onClick={handleOpenMenu}
              size="small"
            >
              <InfoOutlined css={styles.greyIcon} fontSize="small" />
            </IconButton>
          ) : (
            <IconButton
              aria-label="widget setting menu"
              onClick={handleOpenMenu}
              size="small"
            >
              <TuneOutlinedIcon css={styles.greyIcon} fontSize="small" />
            </IconButton>
          )}
        </Stack>
      </Stack>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{ ...DEFAULT_HANDLE_STYLE, left: "50%", background: "blue" }}
      />

      <NodeResizeControl style={controlStyle} minWidth={200} minHeight={100}>
        <ChevronRightOutlinedIcon css={[styles.resizeHandle, styles.greyIcon]} />
      </NodeResizeControl>

      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {nodeType === "initial" ? (
          <Typography
            variant="body2"
            align="center"
            color="textPrimary"
            style={{ maxWidth: "250px" }}
          >
            In order to edit the initial node, click on the edit icon at the top menu bar.
            Editing the initial node will also edit the workflow's name.
          </Typography>
        ) : (
          <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
            <Stack alignItems="center">
              <IconButton aria-label="copy node" onClick={handleCopy}>
                <DifferenceOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                Copy
              </Typography>
            </Stack>

            <Stack alignItems="center">
              <IconButton aria-label="copy node" onClick={handleCopyWithChildren}>
                <DifferenceOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                Copy with children
              </Typography>
            </Stack>

            <Stack alignItems="center">
              <IconButton
                aria-label="edit widget configuration"
                onClick={
                  handleOpenEditModal
                    ? () => {
                        handleOpenEditModal();
                        handleCloseMenu();
                      }
                    : undefined
                }
              >
                <EditOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                Edit
              </Typography>
            </Stack>

            <Stack alignItems="center">
              <IconButton onClick={handleDelete} aria-label="delete">
                <DeleteOutlineOutlinedIcon />
              </IconButton>
              <Typography variant="caption" align="center" color="textPrimary">
                Delete
              </Typography>
            </Stack>
          </Stack>
        )}
      </Menu>

      {children}
    </Box>
  );
};

export default memo(KanFlowNode);
