import React, { memo } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Divider, Typography } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { BoschInitialNodeData } from "../boschNodesUtils";
import BoschNode from "./BoschNode";

interface FlowCustomNodeProps {
  id: string;
  data: BoschInitialNodeData;
}

const BoschInitialNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };

  return (
    <BoschNode id={id} nodeType={"initial"}>
      <Typography variant="body1" align="center">
        {`<< Workflow >>`}
      </Typography>

      <Divider css={[styles.textBreak, styles.reverseTextBreak]} color="lightgrey" />

      <Typography variant="h2" align="center">
        {data.name}
      </Typography>
    </BoschNode>
  );
};

export default memo(BoschInitialNode);
