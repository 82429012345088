import React, { useState } from "react";
import {
  Box,
  Menu as MUILanguageMenu,
  IconButton,
  Typography,
  Stack,
  MenuItem,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssComponentsStyles from "../../Global/Styles/components";
import { Theme } from "@mui/material";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import { useLanguageContext } from "../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  alertsMenuDropdown: css({
    maxHeight: "600px",
    marginTop: 1.5,
    overflow: "auto",
  }),
  alertSecondaryText: css({
    color:
      theme.palette.mode === "light" ? theme.palette.grey[900] : theme.palette.grey[400],
    fontWeight: 400,
    fontSize: "0.9rem",
    lineHeight: 1.5,
  }),
  flagIconsStyle: css({
    marginRight: "8px",
    width: "20px",
    height: "15px",
    display: "inline-block",
  }),
});

const LanguagesMenu = () => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t, setLanguage } = useLanguageContext();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeLanguage = (lng: string) => {
    setLanguage(lng);
    handleClose();
  };

  return (
    <>
      <Box component="div" css={styles.flexCenter}>
        <Stack alignItems="center" onClick={handleClick} css={{ cursor: "pointer" }}>
          <IconButton aria-label="open alerts menu">
            <LanguageOutlinedIcon />
          </IconButton>
          <Typography variant="caption" align="center" color="textSecondary">
            {t("language")}
          </Typography>
        </Stack>
      </Box>

      <MUILanguageMenu
        css={styles.alertsMenuDropdown}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => changeLanguage("en")} css={[styles.hoverItem]}>
          <img
            src="/Flag_of_the_United_Kingdom.png"
            alt="Flag of the United Kingdom"
            css={styles.flagIconsStyle}
          />
          <Typography variant="body1">English</Typography>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("de")} css={[styles.hoverItem]}>
          <img
            src="/Flag_of_Germany.png"
            alt="Flag of Germany"
            css={styles.flagIconsStyle}
          />
          <Typography variant="body1">Deutsch</Typography>
        </MenuItem>
        <MenuItem onClick={() => changeLanguage("bg")} css={[styles.hoverItem]}>
          <img
            src="/Flag_of_Bulgaria.png"
            alt="Flag of Bulgaria"
            css={styles.flagIconsStyle}
          />
          <Typography variant="body1">Български</Typography>
        </MenuItem>
      </MUILanguageMenu>
    </>
  );
};

export default LanguagesMenu;
