import { SankeyData } from "../Sankey/sankeyTypes";

const sankeyDefaultData: SankeyData = {
  nodes: [
    {
      id: "Data 1",
      nodeColor: "hsl(64, 70%, 50%)",
    },
    {
      id: "Data 2",
      nodeColor: "hsl(7, 70%, 50%)",
    },
    {
      id: "Data 3",
      nodeColor: "hsl(72, 70%, 50%)",
    },
    {
      id: "Data 4",
      nodeColor: "hsl(299, 70%, 50%)",
    },
    {
      id: "Data 5",
      nodeColor: "hsl(143, 70%, 50%)",
    },
    {
      id: "Data 6",
      nodeColor: "hsl(76, 70%, 50%)",
    },
  ],
  links: [
    {
      source: "Data 2",
      target: "Data 4",
      value: 187,
    },
    {
      source: "Data 6",
      target: "Data 1",
      value: 89,
    },
    {
      source: "Data 6",
      target: "Data 5",
      value: 64,
    },
    {
      source: "Data 6",
      target: "Data 3",
      value: 95,
    },
    {
      source: "Data 4",
      target: "Data 5",
      value: 80,
    },
    {
      source: "Data 3",
      target: "Data 2",
      value: 88,
    },
    {
      source: "Data 1",
      target: "Data 3",
      value: 48,
    },
  ],
};

export default sankeyDefaultData;
