import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { ResponsiveSwarmPlot } from "@nivo/swarmplot";
import { SwarmPlotData } from "./swarmPlotTypes";
import { getChartColors } from "../../nivoTheme";

interface SwarmPlotProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: SwarmPlotData;
  isStatic?: boolean;
}

const SwarmPlot: React.FC<SwarmPlotProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 10 : 70,
    right: isStatic ? 10 : 90,
    bottom: isStatic ? 10 : 60,
    left: isStatic ? 10 : 90,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveSwarmPlot
        data={data}
        groups={["group A", "group B", "group C"]}
        value="price"
        valueFormat="$.2f"
        valueScale={{ type: "linear", min: 0, max: 500, reverse: false }}
        size={{
          key: "volume",
          values: [4, 20],
          sizes: [6, 20],
        }}
        forceStrength={4}
        simulationIterations={100}
        borderColor={{
          from: "color",
          modifiers: [
            ["darker", 0.6],
            ["opacity", 0.5],
          ],
        }}
        isInteractive={isStatic ? false : true}
        margin={chartMargin}
        colors={getChartColors()}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        axisTop={
          isStatic
            ? null
            : {
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: "group if vertical, price if horizontal",
                legendPosition: "middle",
                legendOffset: -46,
              }
        }
        axisRight={
          isStatic
            ? null
            : {
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: "price if vertical, group if horizontal",
                legendPosition: "middle",
                legendOffset: 76,
              }
        }
        axisBottom={
          isStatic
            ? null
            : {
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: "group if vertical, price if horizontal",
                legendPosition: "middle",
                legendOffset: 46,
              }
        }
        axisLeft={
          isStatic
            ? null
            : {
                tickSize: 10,
                tickPadding: 5,
                tickRotation: 0,
                legend: "price if vertical, group if horizontal",
                legendPosition: "middle",
                legendOffset: -76,
              }
        }
      />
    </Box>
  );
};

export default SwarmPlot;
