import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TimePicker as MUITimePicker, TimePickerProps } from "@mui/x-date-pickers";
import enGB from "date-fns/locale/en-GB";

interface CustomTimePickerProps extends TimePickerProps<Date> {
  name?: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
}

const TimePicker: React.FC<CustomTimePickerProps> = ({
  className,
  onBlur,
  ...otherProps
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
      <div style={{ width: "98%" }}>
        <MUITimePicker
          className={className}
          slotProps={{
            textField: {
              variant: "standard",
              size: "small",
              onBlur,
            },
          }}
          {...otherProps}
        />
      </div>
    </LocalizationProvider>
  );
};

export default TimePicker;
