import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../../context/AuthContext";
import {
  MachineTaskStatus,
  MachineTaskTemplatePeriod,
  MaintenanceMachineTask,
} from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import MultiStageForm from "../../../../SmallComponents/MultiStageForm/MultiStageForm";
import { Box, Grid, InputAdornment, Stack, Typography, useTheme } from "@mui/material";
import Select from "../../../../MaterialUI/FormFields/Select";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import { handleGetSelectOption } from "../../../../../Global/Utils/commonFunctions";
import DateAndTimePicker from "../../../../MaterialUI/DateTimePickers/DateAndTimePicker";
import {
  GetQueryMaintenanceMachinesSnippet,
  PostQueryUpdateMachineTaskSnippet,
} from "../../../../../Api/Maintenance/apiMaintenanceSnippets";
import {
  MaintenanceMachineTaskType,
  MaintenancePlanAddEditFormResult,
  MaintenancePlanTemplateApplyChangesOption,
  maintenanceMachineTaskTypeOptions,
  maintenancePlanTemplateApplyChangesOptions,
} from "../maintenancePlanUtils";
import RadioGroup from "../../../../MaterialUI/FormFields/RadioGroup";
import Collapse from "../../../../MaterialUI/Collapse";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import Button from "../../../../MaterialUI/Button";
import { addMinutes, differenceInMinutes, isBefore, isValid } from "date-fns";
import callApi from "../../../../../Api/callApi";
import {
  PostQueryUpdateTaskInput,
  PostQueryUpdateTaskTemplateInput,
} from "../../../../../Api/Maintenance/apiMaintenanceInputs";
import {
  postQueryUpdateTask,
  postQueryUpdateTaskTemplate,
} from "../../../../../Api/Maintenance/apiMaintenancePostQueries";
import { useDetectFormsUnsavedChanges } from "../../../../../Global/Hooks/useDetectFormsUnsavedChanges";

const FORM_STAGES = ["Select Task Type", "Task Configuration"];
const statusOptions: SelectOption[] = [
  {
    value: "Open",
    description: "Open",
  },
  {
    value: "InProgress",
    description: "In Progress",
  },
  {
    value: "Closed",
    description: "Closed",
  },
];

type FormValues = {
  machine: string;
  task_description: string;
  start_date: Date | null;
  end_date: Date | null;
  status: MachineTaskStatus | "";
  repetition_period: MachineTaskTemplatePeriod | "";
  extra_description: string;
  planned_duration: number;
};

interface MaintenancePlanEditFormProps {
  machines: GetQueryMaintenanceMachinesSnippet;
  task: MaintenanceMachineTask;
  handleOnEdit: (value?: MaintenancePlanAddEditFormResult) => Promise<void>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const MaintenancePlanEditForm: React.FC<MaintenancePlanEditFormProps> = ({
  machines,
  task,
  handleOnEdit,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [activeStep, setActiveStep] = useState<number>(0);

  const [taskType, setTaskType] = useState<MaintenanceMachineTaskType | "">("");
  const [applyChangesTo, setApplyChangesTo] =
    useState<MaintenancePlanTemplateApplyChangesOption>("All Open Tasks");
  const [formInputs, setFormInputs] = useState<FormValues>(getInitialValues(task));
  const { setAuthedUser } = useAuthedContext();
  const initialFormValues = getInitialValues(task);

  useDetectFormsUnsavedChanges(initialFormValues, formInputs, handleSetUnsavedChanges);

  useEffect(() => {
    return () => {
      setTaskType("");
      setFormInputs(initialFormValues);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeField = (key: keyof FormValues, value: any) => {
    setFormInputs((prev) => ({ ...prev, [key]: value }));
  };

  const handleOnNextStage = async () => {
    try {
      setAlertStatus(null);
      setAlertMessage(null);

      if (activeStep === 0) {
        if (!taskType) {
          setAlertStatus("warning");
          setAlertMessage("You must select a task type");
          return false;
        }
        return true;
      }

      if (activeStep === 1) {
        await handleEdit();
      }

      return false;
    } catch (err) {
      console.log("handleOnNextStage err", err);
      setAlertStatus("error");
      setAlertMessage("Something went wrong");
      return false;
    }
  };

  const handleEdit = async () => {
    try {
      setAlertStatus("loading");
      setAlertMessage("Saving data...");

      const alert = formInputAlert(
        formInputs,
        !!task.task_template_id,
        taskType === "task"
      );
      if (alert) {
        setAlertStatus("warning");
        setAlertMessage(alert);
        return false;
      }

      if (taskType === "task" || !task.task_template_id) {
        const input: PostQueryUpdateTaskInput = {
          task_description: formInputs.task_description,
          start_date: formInputs.start_date!.toISOString(),
          status: formInputs.status as MachineTaskStatus,
          planned_duration: differenceInMinutes(
            formInputs.end_date!,
            formInputs.start_date!
          ),
          extra_description: formInputs.extra_description,
        };

        await callApi<PostQueryUpdateMachineTaskSnippet>({
          query: postQueryUpdateTask(task.id, input),
          auth: { setAuthedUser },
        });
      } else {
        const input: PostQueryUpdateTaskTemplateInput = {
          task_description: formInputs.task_description,
          start_date: formInputs.start_date!.toISOString(),
          end_date: formInputs.end_date!.toISOString(),
          repetition_period: formInputs.repetition_period as MachineTaskTemplatePeriod,
          planned_duration: formInputs.planned_duration,
        };

        await callApi<PostQueryUpdateMachineTaskSnippet>({
          query: postQueryUpdateTaskTemplate(task.task_template_id, input),
          auth: { setAuthedUser },
        });
      }

      await handleOnEdit();
      setAlertStatus("success");
      setUnsavedChanges(false);
      setAlertMessage("Successfully saved changes");
    } catch (err) {
      console.log("handleEdit err ", err);
      setAlertStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  console.log(task);

  if (!task.task_template_id) {
    return (
      <Box component="div">
        <EditForm
          machines={machines}
          task={task}
          formInputs={formInputs}
          handleChangeField={handleChangeField}
          isSingle
        />

        <Stack css={styles.labelBreak} alignItems="center" justifyContent="center">
          <Button
            css={styles.reverseContentBreak}
            onClick={handleEdit}
            loading={alertStatus === "loading"}
            disabled={alertStatus === "success"}
          >
            Save Changes
          </Button>
        </Stack>
      </Box>
    );
  }

  return (
    <MultiStageForm
      steps={FORM_STAGES}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleOnNextStage={handleOnNextStage}
      alertMessage={alertMessage}
      alertStatus={alertStatus}
      mobile
      title=""
      lastNextButtonLabel={taskType === "task" ? "Edit Task" : "Edit Task Template"}
      disableNextButton={alertStatus === "success"}
      disablePrevButton={alertStatus === "success"}
    >
      {activeStep === 0 ? (
        <Box component="div">
          <Select
            css={styles.contentBreak}
            label="Type"
            value={taskType}
            onChange={(e) => setTaskType(e.target.value as MaintenanceMachineTaskType)}
            selectOptions={maintenanceMachineTaskTypeOptions}
          />
          <Collapse in={taskType === "repetitive"}>
            <RadioGroup
              css={styles.contentBreak}
              label="Apply Changes to"
              value={applyChangesTo}
              onChange={(e) =>
                setApplyChangesTo(
                  e.target.value as MaintenancePlanTemplateApplyChangesOption
                )
              }
              radioOptions={maintenancePlanTemplateApplyChangesOptions}
            />
          </Collapse>
          <Stack spacing={1}>
            <Typography variant="h4">Repetitive Task Template: </Typography>
            <Typography variant="body1">
              If you choose to edit the task template, the changes will be propagated to
              all open tasks which are derived from this template. You can optionally
              apply the changes to all tasks in progress as well.
            </Typography>
          </Stack>
        </Box>
      ) : null}

      {activeStep === 1 ? (
        <EditForm
          machines={machines}
          task={task}
          formInputs={formInputs}
          handleChangeField={handleChangeField}
          isSingle={taskType === "task"}
        />
      ) : null}
    </MultiStageForm>
  );
};

export default MaintenancePlanEditForm;

interface EditFormProps {
  task: MaintenanceMachineTask;
  machines: GetQueryMaintenanceMachinesSnippet;
  formInputs: FormValues;
  handleChangeField: (key: keyof FormValues, value: any) => void;
  isSingle: boolean;
}

const EditForm: React.FC<EditFormProps> = ({
  task,
  machines,
  formInputs,
  handleChangeField,
  isSingle,
}) => {
  const singleAndTemplated = isSingle && !!task.task_template_id;
  const notSingleAndTemplated = !isSingle && !!task.task_template_id;

  return (
    <Box component="div">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Task Description"
            value={formInputs.task_description}
            onChange={(e) => handleChangeField("task_description", e.target.value)}
            disabled={singleAndTemplated}
            multiline
            minRows={2}
            maxRows={5}
          />
        </Grid>
        {singleAndTemplated ? (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Extra Description"
              value={formInputs.extra_description}
              onChange={(e) => handleChangeField("extra_description", e.target.value)}
              multiline
              minRows={2}
              maxRows={5}
            />
          </Grid>
        ) : null}
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            selectOptions={handleGetSelectOption(
              machines.map((machine) => machine.machine)
            )}
            label="Machine"
            value={formInputs.machine}
            onChange={(e) => handleChangeField("machine", e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DateAndTimePicker
            label="Planned Start date"
            value={formInputs.start_date}
            onChange={(val) => handleChangeField("start_date", val)}
            disabled={singleAndTemplated}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateAndTimePicker
            label="Planned End date"
            value={formInputs.end_date}
            minDate={formInputs.start_date || undefined}
            onChange={(val) => handleChangeField("end_date", val)}
            disabled={singleAndTemplated}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {isSingle ? (
            <Select
              selectOptions={statusOptions}
              label="Status"
              value={formInputs.status}
              onChange={(e) => handleChangeField("status", e.target.value)}
            />
          ) : (
            <Select
              selectOptions={handleGetSelectOption(
                ["daily", "weekly", "monthly", "yearly"],
                true
              )}
              label="Repetitive Period"
              value={formInputs.repetition_period}
              onChange={(e) => handleChangeField("repetition_period", e.target.value)}
            />
          )}
        </Grid>
        {notSingleAndTemplated ? (
          <Grid item xs={12} sm={6}>
            <TextField
              label="Tasks Duration"
              value={formInputs.planned_duration}
              onChange={(e) => handleChangeField("planned_duration", e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="start">minutes</InputAdornment>,
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

const getInitialValues = (task: MaintenanceMachineTask): FormValues => {
  if (task.task_template) {
    return {
      machine: task.task_template.machine,
      task_description: task.task_template.task_description,
      start_date: new Date(task.task_template.start_date),
      end_date: new Date(task.task_template.end_date),
      status: task.status,
      repetition_period: task.task_template.repetition_period || "",
      extra_description: task.extra_description || "",
      planned_duration: task.task_template.planned_duration || 0,
    };
  }

  return {
    machine: task.machine,
    task_description: task.task_description,
    start_date: task.start_date ? new Date(task.start_date) : null,
    end_date: task.planned_duration
      ? addMinutes(new Date(task.start_date), task.planned_duration)
      : null,
    status: task.status,
    repetition_period: task.repetition_period || "",
    extra_description: task.extra_description || "",
    planned_duration: task.planned_duration || 0,
  };
};

const formInputAlert = (
  formInputs: FormValues,
  fromTemplate: boolean,
  isSingle: boolean
): string | null => {
  if (!formInputs.machine) {
    return "You must select a machine";
  }
  if (!formInputs.task_description) {
    return "You must specify a task description";
  }
  if (!formInputs.start_date) {
    return "You must specify a Planned start Date";
  }
  if (!isValid(formInputs.start_date)) {
    return "Planned Start Date must be valid date";
  }
  if (!formInputs.end_date) {
    return "You must specify a Planned end Date";
  }
  if (formInputs.start_date && formInputs.end_date) {
    if (isValid(formInputs.start_date) && isValid(formInputs.end_date)) {
      if (isBefore(formInputs.end_date, formInputs.start_date)) {
        return "Planned Start Date cannot be before the Planned end Date";
      }
    } else {
      return "Start and Planned end Dates must be valid dates";
    }
  }

  if (!(fromTemplate || !isSingle) && !formInputs.status) {
    return "You must select a task status";
  }
  if (fromTemplate && !isSingle && !formInputs.repetition_period) {
    return "You must select a repetitive period";
  }
  return null;
};
