import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import {
  GetQueryDATemplateSnippet,
  PostQueryDABulkUploadConfigSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../../context/AuthContext";
import callApi from "../../../../../Api/callApi";
import { getQueryDATemplate } from "../../../../../Api/DataSources/apiDSGetQueries";
import FileDropzone from "../../../../SmallComponents/Dropzone/FileDropzone";
import Button from "../../../../MaterialUI/Button";
import Alert from "../../../../MaterialUI/Alert";
import Modal from "../../../../MaterialUI/Modal";
import ResponsiveTableGrid from "../../../../SmallComponents/TableGrid/ResponsiveTableGrid";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { PostQueryDABulkUploadConfigInput } from "../../../../../Api/DataSources/apiDSInputs";
import { postQueryDABulkUploadConfig } from "../../../../../Api/DataSources/apiDSPostQueries";
import { TableGridColumnSchema } from "../../../../SmallComponents/TableGrid/constructTableGrid";

const COLUMNS: TableGridColumnSchema[] = [
  { id: "customname", label: "Custom Name", type: "string" },
  { id: "itemname", label: "Name", type: "string" },
  { id: "description", label: "Description", type: "string" },
  { id: "group", label: "Group", type: "string" },
  { id: "digits", label: "Digits", type: "string" },
  { id: "highalarmlimit", label: "High Alarm Limit", type: "string" },
  { id: "highwarninglimit", label: "High Warning Limit", type: "string" },
  { id: "lowalarmlimit", label: "Low Alarm Limit", type: "string" },
  { id: "lowwarninglimit", label: "Low Warning Limit", type: "string" },
  { id: "maxmeasurementrange", label: "Max Measurement Range", type: "string" },
  { id: "minmeasurementrange", label: "Min Measurement Range", type: "string" },
  { id: "setpoint", label: "Setpoint", type: "string" },
  { id: "type", label: "Type", type: "string" },
  { id: "unit", label: "Unit", type: "string" },
];

interface UaFileUploadStageProps {
  setFileIsUploaded: React.Dispatch<React.SetStateAction<boolean>>;
  serverURL: string;
  setSelectedNodes: React.Dispatch<React.SetStateAction<string[]>>;
}

const UaFileUploadStage: React.FC<UaFileUploadStageProps> = ({
  setFileIsUploaded,
  serverURL,
  setSelectedNodes,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const { setAuthedUser } = useAuthedContext();

  const [file, setFile] = useState<FileWithPath | null>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [nodesFromFile, setNodesFromFile] =
    useState<PostQueryDABulkUploadConfigSnippet | null>(null);
  const [fileIsProcessed, setFileIsProcessed] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (file) {
        setAlertMessage(null);
        setAlertStatus(null);
        if (openModal) {
          setOpenModal(false);
        }

        try {
          setAlertMessage("Loading...");
          setAlertStatus("loading");

          const input: PostQueryDABulkUploadConfigInput = {
            server_name: serverURL,
            file: file,
          };

          const nodes = await callApi<PostQueryDABulkUploadConfigSnippet>({
            query: postQueryDABulkUploadConfig(input),
            auth: { setAuthedUser },
          });

          const nodeIDs: string[] = nodes.fetched_nodes.map((item) => item.itemname);
          const badNodes = !!nodes.unfetched_nodes.length;

          setAlertMessage(
            badNodes
              ? "File data has been processed with rejected nodes"
              : "All file data has been accepted successfully"
          );
          setAlertStatus(badNodes ? "warning" : "success");
          setNodesFromFile(nodes);
          setFileIsUploaded(true);
          setSelectedNodes(nodeIDs);
          setFileIsProcessed(true);
        } catch (error) {
          setFile(null);
          console.log("err: ", error);
          setAlertMessage("File has not been processed - incorrect file data");
          setAlertStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleDownloadTableSample = async () => {
    try {
      const templateURL = await callApi<GetQueryDATemplateSnippet>({
        query: getQueryDATemplate,
        auth: { setAuthedUser },
      });

      const response = await fetch(templateURL);
      const blob = await response.blob();

      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);

      // Specify the desired file name here
      const fileName = "opc-da-template.xlsx";
      downloadLink.download = fileName;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (err) {
      console.log("err: ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };

  const uploadButton = (
    <FileDropzone
      setFiles={(files) => setFile(files[0])}
      setErrorMessage={setAlertMessage}
      accept={{
        ".xlsx, .xls, csv": [
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "text/csv",
        ],
      }}
    >
      <Button
        disabled={alertStatus === "loading"}
        color="secondary"
        loading={alertStatus === "loading"}
      >
        Upload {fileIsProcessed ? "New" : ""} File
      </Button>
    </FileDropzone>
  );

  return (
    <Box component="div">
      <Stack
        css={styles.sectionBreak}
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Stack direction="row" spacing={3} alignItems="center" justifyContent="center">
          {fileIsProcessed ? (
            <Button
              disabled={alertStatus === "loading"}
              color="secondary"
              loading={alertStatus === "loading"}
              onClick={() => setOpenModal(true)}
            >
              Upload {fileIsProcessed ? "New" : ""} File
            </Button>
          ) : (
            uploadButton
          )}
          <Button
            variant="text"
            size="small"
            disabled={alertStatus === "loading"}
            onClick={handleDownloadTableSample}
          >
            Download Template
          </Button>
        </Stack>
        <Typography variant="caption" color="textSecondary">
          Accepted file formats: .xlsx, .xls, csv
        </Typography>

        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={alertStatus}
        />
      </Stack>

      {nodesFromFile ? (
        <Stack spacing={6}>
          {nodesFromFile.unfetched_nodes.length ? (
            <Box component="div">
              <Stack
                css={styles.textBreak}
                direction="row"
                alignItems="center"
                justifyContent="center"
              >
                <WarningAmberOutlinedIcon color="warning" />

                <Box component="div">
                  <Typography css={styles.leftSpacer1} variant="h3" textAlign="center">
                    Not Accepted Nodes
                  </Typography>
                </Box>
              </Stack>

              <Typography
                css={styles.labelBreak}
                variant="body2"
                color="textSecondary"
                textAlign="center"
              >
                These are nodes which were not found on the server
              </Typography>
              <ResponsiveTableGrid
                rows={nodesFromFile.unfetched_nodes}
                colSchema={COLUMNS}
                responsive="desktop"
                loading={alertStatus === "loading"}
              />
            </Box>
          ) : null}

          <Box component="div">
            <Typography css={styles.textBreak} variant="h3" textAlign="center">
              Accepted Nodes
            </Typography>
            <Typography
              css={styles.labelBreak}
              variant="body2"
              color="textSecondary"
              textAlign="center"
            >
              These are nodes which will be saved in the connection
            </Typography>
            <ResponsiveTableGrid
              rows={nodesFromFile.fetched_nodes}
              colSchema={COLUMNS}
              responsive="desktop"
              loading={alertStatus === "loading"}
            />
          </Box>
        </Stack>
      ) : null}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        label="Confirm File Upload"
      >
        <Stack spacing={3} alignItems="center" justifyContent="center">
          <Typography variant="body1">
            You current file data will be lost if you upload a new file.
          </Typography>
          {uploadButton}
        </Stack>
      </Modal>
    </Box>
  );
};

export default UaFileUploadStage;
