import { SerializedStyles } from "@emotion/react";
import { Box } from "@mui/material";
import { BoxPlotConfig, BoxPlotData, BoxPlotDataSchema } from "./boxPlotTypes";
// @ts-ignore
import { ResponsiveBoxPlot } from "@nivo/boxplot";
import { getChartColors } from "../../nivoTheme";
import useTheme from "@mui/material/styles/useTheme";

interface BoxPlotProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: BoxPlotConfig;
  data: BoxPlotData;
  isStatic?: boolean;
  schema: BoxPlotDataSchema | null;
  paramMapping?: Record<string, string>;
}

const BoxPlot: React.FC<BoxPlotProps> = ({
  className,
  data,
  isStatic,
  configuration,
  schema,
  paramMapping,
}) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 10 : 40,
    right: isStatic ? 10 : 100,
    bottom: isStatic ? 10 : 70,
    left: isStatic ? 10 : 60,
  };

  const botLegend = configuration?.manualBottomLabel
    ? configuration?.bottomLegend
    : schema?.legend
    ? paramMapping?.[schema.legend.parameterId] || schema.legend.parameterId
    : "";

  return (
    <Box component="div" className={className}>
      <ResponsiveBoxPlot
        data={data}
        margin={chartMargin}
        subGroupBy="subgroup"
        groupBy="group"
        padding={0.12}
        enableGridX={true}
        isInteractive={isStatic ? false : true}
        axisBottom={
          isStatic
            ? null
            : {
                tickSize: 5,
                tickPadding: 10,
                tickRotation: 0,
                legend: botLegend,
                legendPosition: "middle",
                legendOffset: 45,
              }
        }
        colors={getChartColors()}
        // @ts-ignore
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        borderRadius={2}
        borderWidth={2}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        medianWidth={2}
        medianColor={{
          from: "theme",
          modifiers: [["darker", 0.2]],
        }}
        whiskerEndSize={0.6}
        whiskerColor={{
          from: "color",
          modifiers: [["darker", 0.3]],
        }}
        motionConfig="stiff"
        legends={
          isStatic
            ? undefined
            : [
                {
                  anchor: "right",
                  direction: "column",
                  justify: false,
                  translateX: 80,
                  translateY: 0,
                  itemWidth: 60,
                  itemHeight: 20,
                  itemsSpacing: 3,
                  itemTextColor: "#999",
                  itemDirection: "left-to-right",
                  symbolSize: 20,
                  symbolShape: "square",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]
        }
      />
    </Box>
  );
};

export default BoxPlot;
