import { Query } from "../../../Api/callApi";
import {
  KanbanTaskTemplate,
  KanbanTemplateFieldType,
} from "../../TaskManager/Components/taskManagerUtils";
import { KanbanWorkflowType, NodeCondition } from "../kanbanWorkflowUtils";

export const getQueryKanbanWorkflows = (): Query => ({
  endpoint: "workflows/workflows",
  method: "GET",
});
export type GetQueryKanbanWorkflowsSnippet = {
  id: string;
  data: KanbanWorkflowType;
}[];

export const getQueryKanbanWorkflow = (id: string): Query => ({
  endpoint: `workflows/workflow/${id}`,
  method: "GET",
});
export type GetQueryKanbanWorkflowSnippet = {
  id: string;
  data: KanbanWorkflowType;
};

export const getQueryKanbanWorkflowBoardColumns = (id: string): Query => ({
  endpoint: `workflows/workflow/${id}/kanban`,
  method: "GET",
});
export type GetQueryKanbanWorkflowBoardColumnsSnippet = {
  node_id: string;
  node_name: string;
  order: number;
}[];

export const getQueryKanbanTasks: Query = {
  endpoint: "/workflows/tasks",
  method: "GET",
};
export const getQueryKanbanTaskTemplates: Query = {
  endpoint: "workflows/task/templates",
  method: "GET",
};
export type GetQueryKanbanTaskTemplatesSnippet = {
  id: string;
  name: string;
  versions: Omit<KanbanTaskTemplate, "name">[];
}[];

export const getQueryKanbanTaskConditions: Query = {
  endpoint: "workflows/conditions",
  method: "GET",
};
export type GetQueryKanbanTaskConditionsSnippet = NodeCondition[];

export const getQueryKanbanTemplateFields: Query = {
  endpoint: "workflows/task/templates/fields",
  method: "GET",
};
export type GetQueryKanbanTemplateFieldsSnippet = {
  id: string;
  name: string;
  type: KanbanTemplateFieldType;
}[];
