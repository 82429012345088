import { OrderDetailsTableRow } from "./ordersUtils";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";

const colSchema: TableGridColumnSchema[] = [
  { id: "sample_name", label: "Sample Name", type: "string" },
  { id: "task_name", label: "Task Name", type: "string" },
  { id: "data_field", label: "Data Field", type: "string" },
  { id: "data_value", label: "Data Value", type: "string" },
];

interface OrderManagementTableProps {
  orderDetailsTableRows: OrderDetailsTableRow[];
  loading: boolean;
}

const OrderDetailsTable: React.FC<OrderManagementTableProps> = ({
  orderDetailsTableRows,
  loading,
}) => {
  return (
    <ResponsiveTableGrid
      rows={orderDetailsTableRows}
      colSchema={colSchema}
      editMode={false}
      responsive="responsive"
      loading={loading}
      configuration={{
        density: "compact",
      }}
    />
  );
};

export default OrderDetailsTable;
