import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { TimeRangeData } from "./timeRangeTypes";
import { ResponsiveTimeRange } from "@nivo/calendar";
import { getChartColors } from "../../nivoTheme";

interface TimeRangeProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: TimeRangeData;
  isStatic?: boolean;
}

const TimeRange: React.FC<TimeRangeProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 70 : 40,
    right: isStatic ? 10 : 40,
    bottom: isStatic ? 70 : 10,
    left: isStatic ? 10 : 20,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveTimeRange
        data={data}
        from="2018-04-01"
        to="2018-08-12"
        emptyColor="#eeeeee"
        colors={getChartColors()}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        margin={chartMargin}
        dayBorderWidth={2}
        dayBorderColor="#ffffff"
        isInteractive={isStatic ? false : true}
        legends={
          isStatic
            ? undefined
            : [
                {
                  anchor: "bottom-right",
                  direction: "row",
                  justify: false,
                  itemCount: 4,
                  itemWidth: 42,
                  itemHeight: 36,
                  itemsSpacing: 14,
                  itemDirection: "right-to-left",
                  translateX: -60,
                  translateY: -60,
                  symbolSize: 20,
                },
              ]
        }
      />
    </Box>
  );
};

export default TimeRange;
