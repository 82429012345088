import { InputAdornment, Stack } from "@mui/material";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { ModbusConnectionParametersData } from "./modbusConnectionTypes";

interface ModbusConnectionParametersProps {
  data: ModbusConnectionParametersData;
  handleUpdateData: (data: ModbusConnectionParametersData) => void;
  isLoading: boolean;
  subExists: boolean;
}

const ModbusConnectionParameters: React.FC<ModbusConnectionParametersProps> = ({
  data,
  handleUpdateData,
  isLoading,
  subExists,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [stateData, setStateData] = useState<ModbusConnectionParametersData>(data);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      setStateData(() => data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      debounceUpdateData(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = useCallback(debounce(handleUpdateData, 500), []);

  const handleChangeStateData = (key: string, value: string) => {
    setStateData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Stack css={styles.width100} spacing={2}>
      <TextField
        label="Connection Name"
        value={stateData.name}
        onChange={(e) => handleChangeStateData("name", e.target.value)}
        fullWidth
        disabled={isLoading}
      />

      <Stack direction="row" spacing={2}>
        <TextField
          css={styles.width100}
          label="Host"
          value={stateData.host}
          onChange={(e) => handleChangeStateData("host", e.target.value)}
          fullWidth
          disabled={isLoading || subExists}
        />
        <TextField
          css={styles.widthLimit15}
          label="Port"
          value={stateData.port}
          onChange={(e) => handleChangeStateData("port", e.target.value)}
          fullWidth
          disabled={isLoading || subExists}
          numberField
          noThousandSeparator
          allowNegatives={false}
        />
      </Stack>

      <TextField
        css={styles.widthLimit10}
        label="Update Rate"
        value={stateData.updateRate}
        onChange={(e) => handleChangeStateData("updateRate", e.target.value)}
        fullWidth
        disabled={isLoading}
        numberField
        InputProps={{
          endAdornment: <InputAdornment position="start">s</InputAdornment>,
        }}
      />
    </Stack>
  );
};

export default ModbusConnectionParameters;
