import { css } from "@emotion/react";
import { Stack, Box, Typography, Theme, Breadcrumbs } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import { Link } from "react-router-dom";

const cssStyles = (theme: Theme) => ({
  termsBox: css({
    width: "100%",
    height: "100%",
    background: "linear-gradient(to right bottom, rgb(48, 175, 184), rgb(226, 255, 243))",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "2rem",
  }),
  termsBoxHeading: css({
    color: "rgb(205, 247, 233)",
    fontWeight: "bold",
    marginBottom: "1rem",
    textAlign: "center",
  }),
  termsBoxSubHeading: css({
    color: "rgb(205, 247, 233)",
    fontWeight: "bold",
    marginBottom: "4rem",
    textAlign: "center",
  }),
  termsBoxSpan: css({
    color: "rgb(78, 78, 78)",
  }),
  termsTermsBox: css({
    width: "100%",
    maxWidth: "1600px",
    padding: "4rem",
    margin: "0 auto",
  }),
  signInBoxRightFormLink: css({
    color: "#2DA1AA",
    fontWeight: "bold",
  }),
  footer: css({
    width: "100%",
    background: theme.palette.primary.light100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "4rem",
    padding: "2rem 1rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "2rem",
    },
  }),
  footerLink: css({
    color: "#2DA1AA",
    fontWeight: "bold",
    textDecoration: "none",
  }),
});

interface AccordionProps {
  children: React.ReactNode;
  pageName: string;
}

const LegalPageLayout: React.FC<AccordionProps> = ({ children, pageName }) => {
  const theme = useTheme();
  const styles = { ...cssStyles(theme) };

  return (
    <Stack
      direction="column"
      sx={{
        background:
          theme.palette.mode === "light"
            ? theme.palette.common.white
            : theme.palette.customColors.darkBackgroundColor,
      }}
    >
      <Box component="div" css={styles.termsBox}>
        <Typography component="h1" variant="h1" css={styles.termsBoxHeading}>
          <Typography component="span" variant="h1" css={styles.termsBoxSpan}>
            GIANT
          </Typography>{" "}
          - Your{" "}
          <Typography component="span" variant="h1" css={styles.termsBoxSpan}>
            G
          </Typography>
          eneric{" "}
          <Typography component="span" variant="h1" css={styles.termsBoxSpan}>
            I
          </Typography>
          nformation{" "}
          <Typography component="span" variant="h1" css={styles.termsBoxSpan}>
            AN
          </Typography>
          alysis{" "}
          <Typography component="span" variant="h1" css={styles.termsBoxSpan}>
            T
          </Typography>
          oolbox
        </Typography>
        <Typography component="h2" variant="h2" css={styles.termsBoxSubHeading}>
          The platform where your data become problem solver
        </Typography>
      </Box>

      <Stack direction="column" css={[styles.termsTermsBox]}>
        <Breadcrumbs>
          <Link to={"/"} css={styles.signInBoxRightFormLink}>
            Home
          </Link>
          <Typography color="text.primary">{pageName}</Typography>
        </Breadcrumbs>

        {children}
      </Stack>

      <Box component="div" css={styles.footer}>
        <Link to={"/terms-and-conditions"} css={styles.footerLink}>
          Terms & Conditions
        </Link>
        <Link to={"/privacy-policy"} css={styles.footerLink}>
          Privacy Policy
        </Link>
        <Link to={"/cookie-policy"} css={styles.footerLink}>
          Cookie Policy
        </Link>
      </Box>
    </Stack>
  );
};

export default LegalPageLayout;
