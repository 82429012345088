import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useAuthedContext } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import callApi from "../../Api/callApi";
import cssSpacingStyles from "../../Global/Styles/spacing";
import cssLayoutStyles from "../../Global/Styles/layout";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import {
  EMPTY_KANBAN_TASK_TEMPLATE,
  KanbanTaskTemplate,
  KanbanTaskTemplateRow,
} from "./Components/taskManagerUtils";
import Modal from "../../Components/MaterialUI/Modal";
import EditKanTaskTemplate from "./Components/KanbanTaskTemplate/EditKanTaskTemplate";
import CellDoubleActionButton from "../../Components/SmallComponents/TableGrid/MobileTableGrid/CellDoubleActionButton";
import ViewKanbanTemplate from "./Components/KanbanTaskTemplate/ViewKanbanTemplate";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import AddKanTaskTemplate from "./Components/KanbanTaskTemplate/AddKanTaskTemplate";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import KanTaskTemplateFiles from "./Components/KanbanTaskTemplate/KanTaskTemplateFiles";
import {
  GetQueryKanbanTaskTemplatesSnippet,
  getQueryKanbanTaskTemplates,
} from "../KanbanWorkflows/api/queries";
import {
  PostQueryKanbanTaskTemplateInput,
  PutQueryKanbanTaskTemplateInput,
  deleteQueryKanbanTemplate,
  putQueryKanbanTaskTemplate,
  putQueryKanbanTaskTemplateName,
} from "../KanbanWorkflows/api/mutations";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import Button from "../../Components/MaterialUI/Button";
import { FormStatuses } from "../../Global/Types/commonTypes";
import Alert from "../../Components/MaterialUI/Alert";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";

type ColActionType = {
  type:
    | "Edit Template"
    | "View Template"
    | "Add New Template"
    | "Template Files"
    | "Confirm Deletion";
  template: KanbanTaskTemplate;
} | null;

const colSchema: TableGridColumnSchema[] = [
  { id: "name", label: "Name", type: "string" },
  { id: "description", label: "Description", type: "string" },
  { id: "created_at", label: "Created At", type: "date" },
  { id: "updated_at", label: "Updated At", type: "date" },
  { id: "actions", label: "Actions", type: "button" },
];

const TaskDesignerPage: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [templates, setTemplates] = useState<KanbanTaskTemplateRow[]>([]);
  const [openModal, setOpenModal] = useState<ColActionType>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(true);
  const [deleteStatus, setDeleteStatus] = useState<FormStatuses>(null);
  const [deleteAlert, setDeleteAlert] = useState<string | null>(null);

  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setPageLoading(true);
      await handleFetchTasks();
      setPageLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<ExtraTopNavMenu handleOpenModal={openAddNewTemplateModal} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleFetchTasks = async (closeModal?: boolean) => {
    try {
      const templatesData = await callApi<GetQueryKanbanTaskTemplatesSnippet>({
        query: getQueryKanbanTaskTemplates,
        auth: { setAuthedUser },
      });

      setTemplates(
        templatesData.map((item) => {
          const latestVersion =
            item.versions.find((ver) => ver.status === "active") || item.versions[0];

          const fullTemplate: KanbanTaskTemplate = {
            ...latestVersion,
            name: item.name,
          };

          return {
            id: item.id,
            name: item.name,
            created_at: latestVersion.created_at,
            description: latestVersion.description,
            updated_at: latestVersion.updated_at,
            steps: latestVersion.steps,
            actions: (
              <Stack direction="row" alignItems="center" spacing={1}>
                <CellDoubleActionButton
                  justify="flex-start"
                  handleOnViewClick={() => {
                    onActionClick({
                      template: fullTemplate,
                      type: "View Template",
                    });
                  }}
                  handleOnEditClick={() => {
                    onActionClick({
                      template: fullTemplate,
                      type: "Edit Template",
                    });
                  }}
                />
                <IconButton
                  onClick={() =>
                    onActionClick({
                      template: fullTemplate,
                      type: "Template Files",
                    })
                  }
                >
                  <AttachFileIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    onActionClick({
                      template: fullTemplate,
                      type: "Confirm Deletion",
                    })
                  }
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Stack>
            ),
          };
        })
      );
      if (closeModal) {
        setOpenModal(null);
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const onActionClick = (data: ColActionType) => {
    setOpenModal(data);
    setDeleteStatus(null);
    setDeleteAlert(null);
  };

  const openAddNewTemplateModal = () => {
    setOpenModal({
      type: "Add New Template",
      template: EMPTY_KANBAN_TASK_TEMPLATE,
    });
  };

  const handleEditSave = async (
    data: PostQueryKanbanTaskTemplateInput,
    templateID: string
  ) => {
    try {
      const input: PutQueryKanbanTaskTemplateInput = {
        description: data.description,
        steps: data.steps,
        data_fields: data.data_fields,
      };

      await callApi({
        query: putQueryKanbanTaskTemplate(templateID, input),
        auth: { setAuthedUser },
      });
      await callApi({
        query: putQueryKanbanTaskTemplateName(templateID, data.name),
        auth: { setAuthedUser },
      });
      await handleFetchTasks();

      setOpenModal(null);
      setUnsavedChanges(false);
      return "success";
    } catch (err) {
      console.log("handleSave() err", err);
      return "error";
    }
  };

  const handleDelete = async () => {
    try {
      if (!openModal?.template?.template_id) {
        return;
      }
      setDeleteStatus("loading");
      setDeleteAlert("Loading...");

      await callApi({
        query: deleteQueryKanbanTemplate(openModal.template.template_id),
        auth: { setAuthedUser },
      });
      await handleFetchTasks();

      setDeleteStatus(null);
      setDeleteAlert(null);
      setOpenModal(null);
    } catch (err) {
      setDeleteStatus("error");
      setDeleteAlert("Cannot be deleted");
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Box component="div">
      <Typography css={styles.contentBreak} variant="h2" textAlign="center">
        Task Designer
      </Typography>

      <ResponsiveTableGrid
        rows={templates}
        colSchema={colSchema}
        responsive="responsive"
        configuration={{
          density: "compact",
          initialRowsPerPage: 15,
          columnVisibility: { task_id: false, progress: false },
        }}
        loading={pageLoading}
      />

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        maxWidth={"md"}
        label={openModal?.type || ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {openModal?.type === "Edit Template" ? (
          <EditKanTaskTemplate
            template={openModal.template}
            onSave={handleEditSave}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
          />
        ) : null}

        {openModal?.type === "View Template" ? (
          <ViewKanbanTemplate template={openModal.template} />
        ) : null}

        {openModal?.type === "Template Files" ? (
          <KanTaskTemplateFiles
            template={openModal.template}
            handleCloseModal={() => setOpenModal(null)}
            onFileUpload={handleFetchTasks}
          />
        ) : null}

        {openModal?.type === "Add New Template" ? (
          <AddKanTaskTemplate
            emptyTemplate={openModal.template}
            onCreateNew={() => handleFetchTasks(true)}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {openModal?.type === "Confirm Deletion" ? (
          <Stack spacing={3} alignItems="center" justifyContent="center">
            <Typography variant="body1">
              Are you sure you want to delete this template?
            </Typography>
            <LabelWithBoldedPart bolded={openModal.template.name} text="Name" />
            <Button
              css={[styles.width100, styles.widthLimit20]}
              color="error"
              onClick={handleDelete}
              loading={deleteStatus === "loading"}
            >
              Confirm Deletion
            </Button>
            <Alert
              css={[styles.widthLimit20]}
              message={deleteAlert || ""}
              showAlert={!!deleteAlert}
              severity={deleteStatus}
            />
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default TaskDesignerPage;

interface ExtraTopNavMenuProps {
  handleOpenModal: () => void;
}

const ExtraTopNavMenu: React.FC<ExtraTopNavMenuProps> = ({ handleOpenModal }) => {
  return (
    <Stack alignItems="center">
      <IconButton aria-label="add new template" onClick={handleOpenModal}>
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        Add
      </Typography>
    </Stack>
  );
};
