import { AreaChartConfiguration } from "../AreaChart/areaChartTypes";

export const areaChartDefaultConfiguration: AreaChartConfiguration = {
  axisTopLegend: "Temperature °C",
  axisBottomLegend: "Time",
  colors: "paired",
  enableGridX: true,
  startLabel: true,
  endLabel: true,
};
