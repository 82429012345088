import { Box, Divider, Grid } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import DaItems from "./DaItems";
import DaLastItem from "./DaLastItem";
import { GetQueryDAAttributesSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import { DaFormattedCanonical } from "./daUtils";

interface DaItemsStageProps {
  itemsList: string[];
  selectedItems: string[];
  serverName: string;
  handleChange: (nodes: string[]) => void;
  selectedGroupProperties: GetQueryDAAttributesSnippet;
  canonicalDetails: DaFormattedCanonical | null;
}

const DaItemsStage: React.FC<DaItemsStageProps> = ({
  itemsList,
  selectedItems,
  serverName,
  handleChange,
  selectedGroupProperties,
  canonicalDetails,
}) => {
  const [stateSelectedItems, setStateSelectedItems] = useState<string[]>(selectedItems);
  const [lastSelectedItem, setLastSelectedItem] = useState<string | null>(null);

  useEffect(() => {
    if (JSON.stringify(selectedItems) !== JSON.stringify(stateSelectedItems)) {
      setStateSelectedItems(() => selectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems]);

  useEffect(() => {
    if (JSON.stringify(selectedItems) !== JSON.stringify(stateSelectedItems)) {
      debounceUpdateData(stateSelectedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelectedItems]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = useCallback(debounce(handleChange, 500), []);

  return (
    <Box component="div" p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5.5}>
          <DaItems
            itemsList={itemsList}
            selectedItems={stateSelectedItems}
            setSelectedItems={setStateSelectedItems}
            setLastSelectedItem={setLastSelectedItem}
          />
        </Grid>

        <Grid item xs={12} sm={1}>
          <Divider style={{ margin: "auto", width: "1px" }} orientation="vertical" />
        </Grid>

        <Grid item xs={12} sm={5.5}>
          <DaLastItem
            lastSelectedItem={lastSelectedItem}
            serverName={serverName}
            selectedGroupProperties={selectedGroupProperties}
            canonicalDetails={canonicalDetails}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(DaItemsStage);
