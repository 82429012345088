import { BoxPlotData } from "../BoxPlot/boxPlotTypes";

const boxPlotDefaultData: BoxPlotData = [
  {
    group: "Alpha",
    subgroup: "A",
    value: 7.323671793622035,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 3.2040194454280875,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.970939681031941,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.124480362374149,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.834310450626112,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 4.528049409670487,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.66623695994081,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 4.924561528159643,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.33042038176886,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 3.4708816935119584,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 3.482971544753444,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 4.733336483411709,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.320488887343682,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.221409203464503,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.154888984728795,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 3.850650487641064,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 3.91877582080189,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 6.551159882918423,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 5.7986476709329215,
  },
  {
    group: "Alpha",
    subgroup: "A",
    value: 3.8608847745675696,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.666963565720637,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.998546497943824,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.92094688746685,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.591005554648837,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 4.224058346572185,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.592943587602311,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.106286635959435,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 5.849080717855455,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 4.755447231207308,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.259432950523862,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.059827846043268,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.173450377693689,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 5.8655915276438835,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.418374431357922,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 4.964792125034367,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 4.31455733638269,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 5.509720033860091,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.114718550675719,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 6.099914371460938,
  },
  {
    group: "Alpha",
    subgroup: "B",
    value: 5.709790302749396,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 8.791579739619307,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 8.096434161291484,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 8.999185730645268,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.409882942580092,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 8.98565056972775,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 10.057100146554292,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 10.619958546617736,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.929523843466343,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.065822535287149,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.473799792901888,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.802765338286818,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 9.043853838158864,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.58260922097315,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 8.99318108705136,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 6.7591974680278035,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 10.074629241213376,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 6.8191338868792295,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.815219538197405,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 8.777137084768029,
  },
  {
    group: "Beta",
    subgroup: "A",
    value: 7.495578033975987,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 10.38751009448699,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 8.182164830985737,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.696935364445523,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.130013591518717,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 9.560500795442668,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.086997443713909,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.73608698178271,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 8.705618808677405,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 6.108065500336937,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.977036158424407,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.361154474112544,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 9.617570824458692,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.281202858127737,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.609342798657035,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 6.7874770982135,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 9.557601000484656,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 6.7964599110109285,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 4.4447997616102075,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 7.767153061500107,
  },
  {
    group: "Beta",
    subgroup: "B",
    value: 3.176362971456645,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.263668490634133,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.8545837926821,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 6.3230619828191275,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.693692568310613,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 5.680329147194396,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 5.505091519492174,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 5.85244398272594,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 5.040054246058564,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 3.8012003002032504,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.745615350956777,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.763089330063493,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.310542593881125,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.316363501010022,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.596627076233559,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 3.8346190928202186,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 5.655741352071199,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 5.482709091656622,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 5.788357036321719,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.950003272909718,
  },
  {
    group: "Gamma",
    subgroup: "A",
    value: 4.484944815972794,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 6.275410776489583,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 9.252426542706608,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 6.9274020805571865,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 6.300723222957189,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 7.579608687257662,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 8.986273446136623,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 7.174979181611932,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 7.5476426508907055,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 6.920763895435605,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 8.22252664032103,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 9.123207381755194,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 4.914494114840885,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 4.073620352376999,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 8.257342317344213,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 4.999615840469301,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 6.982591926023477,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 7.607928669660306,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 7.021445250612275,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 7.211331274225607,
  },
  {
    group: "Gamma",
    subgroup: "B",
    value: 8.43556590821126,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 6.138121152701333,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 6.338114515935468,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 3.587580053367005,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 5.9307016228275575,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.12147728080247,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.232979442750337,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.726553191675513,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 6.7845100872616895,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.91839097494214,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 7.222521911859617,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 5.266059343842043,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.626846572465714,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.97072660924833,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.763380230987983,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.118051606361555,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 5.145893758820943,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 4.48989901027562,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 5.0367251753872875,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 5.019293620477533,
  },
  {
    group: "Delta",
    subgroup: "A",
    value: 3.9244144237761156,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.007755349565675,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 6.332010355927209,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 6.652664466401811,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.57638052394149,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.907008301576692,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.6902009722080695,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.070442174093862,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 6.253710372972002,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 7.135770622074034,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 6.442684652004735,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 6.549754667123556,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 4.723889034459646,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.909110636386507,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 6.407066331138667,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.617712030580068,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.698103972044007,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.138417757231051,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 4.823317011651243,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.390841114383912,
  },
  {
    group: "Delta",
    subgroup: "B",
    value: 5.442746763808994,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.5854863692085575,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 3.0386900013547455,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.7634915073485065,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 5.992174832578424,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 5.126407423854896,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 2.937609015099429,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.8085966440239405,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.947775027736716,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.5110443747936335,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.250040509552251,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 5.198460640669028,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 5.361389597588952,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 6.770133709213796,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.802352314690822,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 5.891854846253773,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 3.9104726791822246,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.1796416811885,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 5.715598197453059,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 4.239569055235339,
  },
  {
    group: "Epsilon",
    subgroup: "A",
    value: 5.953807666850226,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 8.60498715089961,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 7.149949097037723,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 8.015476591762228,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 7.5518939139809405,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 6.343491261519372,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 4.061342601234766,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 9.517293213561786,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 4.667723528826146,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 7.767317223849735,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 6.061817604510351,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 5.233222997026536,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 9.870664619920401,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 7.584291135559827,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 3.7295095800221616,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 9.205805391722919,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 5.967847097234631,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 6.837360202909596,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 10.897815638041266,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 7.813824559717586,
  },
  {
    group: "Epsilon",
    subgroup: "B",
    value: 7.756506607172786,
  },
];

export default boxPlotDefaultData;
