import { SerializedStyles } from "@emotion/react";
import { CalendarWidgetData } from "../../../../ExcellenceWidgets/AdvancedWidgets/CalendarWidget/calendarWidgetTypes";
import CalendarWidget from "../../../../ExcellenceWidgets/AdvancedWidgets/CalendarWidget/CalendarWidget";
import calendarWidgetDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/calendarWidgetDefaultData";

interface ExcellenceCalendarWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: CalendarWidgetData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceCalendarWidget: React.FC<ExcellenceCalendarWidgetProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <CalendarWidget
      className={className}
      configuration={configuration}
      data={data || calendarWidgetDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceCalendarWidget;
