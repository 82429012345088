import { useEffect, useState } from "react";
import { AutocompleteOption, FormStatuses } from "../../../Global/Types/commonTypes";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import {
  GetUnitsOfMeasureSnippet,
  UnitOfMeasure,
} from "../../../Api/Excellence/apiExcellenceSnippets";
import callApi from "../../../Api/callApi";
import { getUnitsOfMeasure } from "../../../Api/Excellence/apiExcellenceGetQueries";
import { useAuthedContext } from "../../../context/AuthContext";
import Alert from "../../MaterialUI/Alert";
import {
  UnitOfMeasureMapValue,
  getUnitsOfMeasureMapping,
} from "./unitsOfMeasureDropdownUtils";
import { Stack } from "@mui/material";
import { SerializedStyles } from "@emotion/react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useLanguageContext } from "../../../context/LanguageContext";

const EMPTY_UNIT = "EMPTY";

interface UnitsOfMeasureDropdownProps {
  css?: (SerializedStyles | false)[] | SerializedStyles;
  className?: string;
  targetUnitValue: string | null;
  handleOnUnitsChange: (
    option: AutocompleteOption | null,
    isEmpty: boolean,
    symbol: string
  ) => void;
  fetchedUnits?: GetUnitsOfMeasureSnippet | null;
  unitsLoading?: boolean;
}

const UnitsOfMeasureDropdown: React.FC<UnitsOfMeasureDropdownProps> = ({
  className,
  targetUnitValue,
  handleOnUnitsChange,
  fetchedUnits,
  unitsLoading,
}) => {
  const styles = { ...cssLayoutStyles };
  const { t } = useLanguageContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [selectOptions, setSelectOptions] = useState<AutocompleteOption[]>([]);
  const [unitsMapping, setUnitsMapping] = useState<UnitOfMeasureMapValue>({});
  const [selectedUnit, setSelectedUnit] = useState<AutocompleteOption | null>(null);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        setFormStatus("loading");
        if (unitsLoading) {
          return;
        }

        // 1. fetch units or use from props
        const units =
          fetchedUnits ||
          (await callApi<GetUnitsOfMeasureSnippet>({
            query: getUnitsOfMeasure,
            auth: { setAuthedUser },
          }));

        // 2. parse into options format
        const unitsOptions = unitsOfMeasureOptions(units);

        setUnitsMapping(getUnitsOfMeasureMapping(units));
        setSelectOptions(unitsOptions);
        setFormStatus("success");
      } catch (err) {
        console.log(err);
        setFormStatus("error");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unitsLoading, fetchedUnits]);

  useEffect(() => {
    if (targetUnitValue) {
      const mapValue = unitsMapping?.[targetUnitValue];
      setSelectedUnit(
        mapValue
          ? {
              value: mapValue.unitID,
              description: `${mapValue.unitName} (${mapValue.unitSymbol})`,
            }
          : null
      );
    } else {
      setSelectedUnit(null);
    }
  }, [targetUnitValue, unitsMapping]);

  const onUnitsChange = (option: AutocompleteOption | null) => {
    const isEmpty = !option || option.value === EMPTY_UNIT;
    const symbol = unitsMapping[option?.value || ""]?.unitSymbol;
    handleOnUnitsChange(option, isEmpty, symbol || "");
  };

  return (
    <Stack className={className} css={styles.width100} spacing={2}>
      <Autocomplete
        css={styles.width100}
        label={t("Units of Measure")}
        options={selectOptions}
        handleOnChange={onUnitsChange}
        value={selectedUnit}
        disabled={!selectOptions?.length}
      />
      {formStatus === "error" ? (
        <Alert
          message={"Could not fetch units of measure"}
          showAlert={formStatus === "error"}
          severity={formStatus}
        />
      ) : null}
    </Stack>
  );
};

export default UnitsOfMeasureDropdown;

const unitsOfMeasureOptions = (units: UnitOfMeasure[] | null): AutocompleteOption[] => {
  if (!units?.length) {
    return [];
  }

  const empty: AutocompleteOption = { value: EMPTY_UNIT, description: "Set to Empty" };

  const options: AutocompleteOption[] = units.map((item) => ({
    value: item.id,
    description: `${item.name} (${item.symbol})`,
  }));

  return [empty, ...options];
};
