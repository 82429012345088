import {
  BarColumnRadarData,
  ChartConfigFormValuesWithTitle,
  ExcellenceChartFormProps,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import useTheme from "@mui/material/styles/useTheme";
import { Grid, Typography } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { RadarChartConfiguration } from "./radarChartTypes";
import EditRadarChartConfig from "./EditRadarChartConfig";
import EditBarChartDataSchema from "../BarChart/EditBarChartDataSchema";
import { useLazyQuery } from "@apollo/client";
import { AutocompleteOption, FormStatuses } from "../../../Global/Types/commonTypes";
import { graphQlQueryExcellenceBarData } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { BarChartDataSchema } from "../BarChart/barChartTypes";
import Alert from "../../MaterialUI/Alert";
import ExcellenceRadarChart from "../../PageComponents/Excellence/ExcellenceCharts/ExcellenceRadarChart/ExcellenceRadarChart";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import { useLanguageContext } from "../../../context/LanguageContext";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";

interface EditRadarChartFormProps extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: RadarChartConfiguration;
  data: BarColumnRadarData;
  dataSchema: BarChartDataSchema | null;
}

const EditRadarChartForm: React.FC<EditRadarChartFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  widgetTitle,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<RadarChartConfiguration>,
      BarChartDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: dataSchema as BarChartDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<BarColumnRadarData>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);

  // data tab
  const [dateFrom, setDateFrom] = useState<Date | null>(
    dataSchema?.startTime ? new Date(dataSchema.startTime) : null
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    dataSchema?.endTime ? new Date(dataSchema.endTime) : null
  );
  const [selectedConnections, setSelectedConnections] = useState<AutocompleteOption[]>(
    []
  );

  const [getBarData] = useLazyQuery(graphQlQueryExcellenceBarData);

  useEffect(() => {
    (async () => {
      if (updatedSchema) {
        try {
          setFetchStatus("loading");
          const result = await getBarData({
            variables: {
              input: updatedSchema,
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.barchartData.chartData;

          setUpdatedData(resultData);
          setResetChartFetch((prev) => !prev);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchema]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      radarChart: { config: otherConfig, data: updatedData, dataSchema: updatedSchema },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditRadarChartConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditBarChartDataSchema
              parameters={parameters?.parameters || []}
              dataSchema={updatedSchema}
              handleUpdateDataSchema={(val) => setUpdatedSchema(val)}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              selectedConnections={selectedConnections}
              setSelectedConnections={setSelectedConnections}
            />
          ) : null}

          <Alert
            css={styles.reverseContentBreak}
            message={t("Something went wrong")}
            showAlert={fetchStatus === "error"}
            severity={"error"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography css={styles.labelBreak} variant="h3">
            {updatedConfig.title}
          </Typography>
          <ExcellenceRadarChart
            css={chartClass}
            configuration={updatedConfig}
            data={updatedData}
            schema={updatedSchema}
            resetChartFetch={resetChartFetch}
            dataFetchFromParent
            parameters={parameters?.parameters}
          />
        </Grid>
      </Grid>

      <LoadingBackdrop loading={fetchStatus === "loading"} />
    </ExcellenceChartFormWrapper>
  );
};

export default EditRadarChartForm;
