import { Box, Stack, Typography } from "@mui/material";
import { KanbanWorkflowsGeneralNodeData } from "../../../../../kanbanWorkflowUtils";
import LabelWithBoldedPart from "../../../../../../../Components/MaterialUI/LabelWithBoldedPart";

interface NodeGeneralDataModalProps {
  data: KanbanWorkflowsGeneralNodeData;
}

const NodeGeneralDataModal: React.FC<NodeGeneralDataModalProps> = ({ data }) => {
  return (
    <Stack spacing={1}>
      {data?.preConditions?.length ? (
        <Box component="div">
          <Box component="div" sx={{ marginBottom: "8px" }}>
            <Typography mb={1} variant="h4" align="center" color="textSecondary">
              Pre-conditions
            </Typography>
            <Stack spacing={1}>
              {data.preConditions.map((item) => (
                <LabelWithBoldedPart
                  key={item.id}
                  bolded={`${item.comparison_operator} ${item.value}`}
                  text={item.data_field.name}
                  inverse
                />
              ))}
            </Stack>
          </Box>
        </Box>
      ) : (
        <LabelWithBoldedPart text="Pre-conditions" bolded={""} />
      )}

      <LabelWithBoldedPart text="ID" bolded={data.id} />
      <LabelWithBoldedPart text="Name" bolded={data.name || ""} />
      <LabelWithBoldedPart text="Template" bolded={data.template_name || ""} />
      <LabelWithBoldedPart
        text="Attributes"
        bolded={
          data?.template_attributes?.length ? data.template_attributes.join(", ") : ""
        }
      />

      {data?.postConditions?.length ? (
        <Box component="div">
          <Box component="div" sx={{ marginTop: "12px" }}>
            <Typography mb={1} variant="h4" align="center" color="textSecondary">
              Post-conditions
            </Typography>
            <Stack spacing={1}>
              {data.postConditions.map((item) => (
                <LabelWithBoldedPart
                  key={item.id}
                  bolded={`${item.comparison_operator} ${item.value}`}
                  text={item.data_field.name}
                  inverse
                />
              ))}
            </Stack>
          </Box>
        </Box>
      ) : (
        <LabelWithBoldedPart text="Post-conditions" bolded={""} />
      )}
    </Stack>
  );
};

export default NodeGeneralDataModal;
