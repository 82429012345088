export type ConditionMonitoring = {
  id: string;
  area: string;
  machines: MachineStatus[];
};

export type MachineStatus = {
  id: string;
  name: string;
  state: MachineState;
  chartData: MachineChartData[];
};

export type MachineChartData = {
  id: string;
  color: string;
  data: {
    x: string;
    y: number;
  }[];
};

export type MachineState = "normal" | "warning" | "critical";

export const getMessage = (machineStates: Record<MachineState, number>): string => {
  if (machineStates.critical > 0) {
    return `${machineStates.critical} Machine${
      machineStates.critical > 1 ? "s" : ""
    } in Critical State`;
  } else if (machineStates.warning > 0) {
    return `${machineStates.warning} Machine${
      machineStates.warning > 1 ? "s" : ""
    } with Warning`;
  } else {
    return "All Machines in Normal State";
  }
};