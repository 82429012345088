import { ExcellenceWidget } from "../../../Api/Excellence/apiExcellenceSnippets";
import { BoxPlotConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/BoxPlot/boxPlotTypes";
import { CalendarWidgetConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/CalendarWidget/calendarWidgetTypes";
import { FunnelConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Funnel/funnelTypes";
import { HeatMapConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/HeatMap/heatMapTypes";
import { NetworkConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Network/networkTypes";
import { SankeyConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Sankey/sankeyTypes";
import { ScatterPlotConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/ScatterPlot/scatterPlotTypes";
import { SunburstConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/Sunburst/sunburstTypes";
import { SwarmPlotConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/SwarmPlot/swarmPlotTypes";
import { TimeRangeConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/TimeRange/timeRangeTypes";
import { TreeMapConfigAndData } from "../../ExcellenceWidgets/AdvancedWidgets/TreeMap/treeMapTypes";
import { AreaChartConfigAndData } from "../../ExcellenceWidgets/AreaChart/areaChartTypes";
import { BarChartConfigAndData } from "../../ExcellenceWidgets/BarChart/barChartTypes";
import { ExcellenceTableConfigAndData } from "../../ExcellenceWidgets/ExcellenceTable/editExcellenceTabletypes";
import { GaugeWidgetConfigAndData } from "../../ExcellenceWidgets/GaugeWidget/gaugeWidgetUtils";
import { IndicatorWidgetConfigAndData } from "../../ExcellenceWidgets/IndicatorWidget/indicatorWidgetTypes";
import { TimeChartConfigAndData } from "../../ExcellenceWidgets/TimeChart/timeChartTypes";
import { PieChartConfigAndData } from "../../ExcellenceWidgets/PieChart/pieChartTypes";
import { RadarChartConfigAndData } from "../../ExcellenceWidgets/RadarChart/radarChartTypes";
import { TextWidgetConfigAndData } from "../../ExcellenceWidgets/TextWidget/textWidgetTypes";
import { WidgetGridItem } from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import { LineChartConfigAndData } from "../../ExcellenceWidgets/LineChart/lineChartTypes";

export type ExcellenceGridItemData = {
  widget: {
    chart?: ExcellenceChartOption;
  };
  loading?: boolean;
  title: string;
  id?: string | number;
};

export type ExcellenceChartOption = {
  // Excellence widgets
  lineChart?: LineChartConfigAndData;
  timeChart?: TimeChartConfigAndData;
  barChart?: BarChartConfigAndData;
  columnChart?: BarChartConfigAndData;
  table?: ExcellenceTableConfigAndData;
  pieChart?: PieChartConfigAndData;
  donutChart?: PieChartConfigAndData;
  radarChart?: RadarChartConfigAndData;
  areaChart?: AreaChartConfigAndData;
  textWidget?: TextWidgetConfigAndData;
  gaugeWidget?: GaugeWidgetConfigAndData;
  indicatorWidget?: IndicatorWidgetConfigAndData;

  // Advanced widgets
  boxPlot?: BoxPlotConfigAndData;
  heatMap?: HeatMapConfigAndData;
  calendar?: CalendarWidgetConfigAndData;
  funnel?: FunnelConfigAndData;
  network?: NetworkConfigAndData;
  sankey?: SankeyConfigAndData;
  scatterPlot?: ScatterPlotConfigAndData;
  sunburst?: SunburstConfigAndData;
  swarmPlot?: SwarmPlotConfigAndData;
  timeRange?: TimeRangeConfigAndData;
  treeMap?: TreeMapConfigAndData;
};

export type ExcellenceUploadedFile = {
  name: string;
  url: string;
  id: string;
};

export type ExcellenceGridLayoutSettings = {
  name: string;
  id: string | number;
  existingFile?: string;
  uploadedFiles?: ExcellenceUploadedFile[];
};

export type ExcellenceChartKeyType = keyof ExcellenceChartOption;

export type ExcellenceWidgetItemMode = {
  itemIndex: number;
  timeChart?: {
    periodPreviewMode: boolean;
    scopeMode: boolean;
  };
};

export const excellenceHandlePrepareWidgetsData = (
  data: ExcellenceWidget[]
): WidgetGridItem<ExcellenceGridItemData>[] => {
  const result = data.map((widget) => {
    const chart = widget.content?.widget?.chart;
    const loading = Boolean(chart?.timeChart || chart?.pieChart || chart?.donutChart);

    return {
      layout: widget.content.layout,
      loading,
      id: widget.id,
      widget: widget.content.widget,
      title: widget.content.title,
    };
  });

  return result;
};