import { Query } from "../callApi";

export const getQueryTTObject = (objectCode: string): Query => ({
  endpoint: `track_and_trace/object/${objectCode}`,
  method: "GET",
});

export const getQueryTTObjects: Query = {
  endpoint: `track_and_trace/objects`,
  method: "GET",
};

export const getQueryTTLocations: Query = {
  endpoint: `track_and_trace/objects`,
  method: "GET",
  variables: {
    is_location: true,
  },
};

export const getQueryTTObjectParent = (objectCode: string): Query => ({
  endpoint: `track_and_trace/object/${objectCode}/parent`,
  method: "GET",
});

export const getQueryTTObjectChildren = (objectCode: string): Query => ({
  endpoint: `track_and_trace/object/${objectCode}/children`,
  method: "GET",
});

export const getQueryTTObjectParentChain = (objectCode: string): Query => ({
  endpoint: `track_and_trace/object/${objectCode}/parent-chain`,
  method: "GET",
});

export const getQueryTTObjectAvailability = (objectCode: string): Query => ({
  endpoint: `track_and_trace/object/${objectCode}/availability`,
  method: "GET",
});

export const getQueryTTObjectsAvailability: Query = {
  endpoint: `track_and_trace/objects/availability`,
  method: "GET",
};

export const getQueryTTFileData = (fileId: string): Query => ({
  endpoint: `track_and_trace/file/${fileId}`,
  method: "GET",
});

export const getQueryTTObjectFilesData = (objectDocumentId: string): Query => ({
  endpoint: `track_and_trace/object/${objectDocumentId}/files`,
  method: "GET",
});

export const getQueryTTDownloadFile = (fileId: string): Query => ({
  endpoint: `track_and_trace/file/${fileId}/download`,
  method: "GET",
  returnJson: false,
});

export const getQueryTTDisplayFile = (fileId: string): Query => ({
  endpoint: `track_and_trace/file/${fileId}/display`,
  method: "GET",
  returnJson: false,
});
