import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { FunnelData } from "./funnelTypes";
import { ResponsiveFunnel } from "@nivo/funnel";
import {
  DynamicGridChartType,
  getChartColors,
  getCurrentColorScheme,
} from "../../nivoTheme";

interface FunnelProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: FunnelData;
  isStatic?: boolean;
}

const Funnel: React.FC<FunnelProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 10 : 20,
    right: isStatic ? 10 : 20,
    bottom: isStatic ? 10 : 20,
    left: isStatic ? 10 : 20,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveFunnel
        data={data}
        margin={chartMargin}
        valueFormat=">-.4s"
        colors={getChartColors()}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        borderWidth={20}
        labelColor={
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
            ? {
                from: "color",
                modifiers: [["darker", 10]],
              }
            : "#FFFFFF"
        }
        beforeSeparatorLength={100}
        beforeSeparatorOffset={20}
        afterSeparatorLength={100}
        afterSeparatorOffset={20}
        currentPartSizeExtension={10}
        currentBorderWidth={40}
        motionConfig="wobbly"
        isInteractive={isStatic ? false : true}
      />
    </Box>
  );
};

export default Funnel;
