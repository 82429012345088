import { LabelImage } from "../../../../Api/VisionControl/apiVisionControlSnippets";

export type LabelRecognitionTestedModalData = {
  masterLabelName: string;
  masterLabelUrl: string;
  testedLabelName: string;
  testedLabelUrl: string;
  confidence: number;
};
export type LabelRecognitionTestedModalDataNoMaster = {
  testedLabelName: string;
  testedLabelUrl: string;
};

export type AllLabelImages = {
  master: LabelImage[];
  tested: LabelImage[];
};

export type LabelSortOption = "Newest" | "Oldest" | "Alphabetic" | "Alphabetic reverse";

export const labelsSortByValue = (
  items: LabelImage[],
  sortingValue: LabelSortOption
): LabelImage[] => {
  const sortingOptions: { [key: string]: (a: LabelImage, b: LabelImage) => number } = {
    Newest: (a, b) =>
      new Date(b.last_modified).getTime() - new Date(a.last_modified).getTime(),
    Oldest: (a, b) =>
      new Date(a.last_modified).getTime() - new Date(b.last_modified).getTime(),
    Alphabetic: (a, b) => a.image_name.localeCompare(b.image_name),
    "Alphabetic reverse": (a, b) => b.image_name.localeCompare(a.image_name),
  };

  const selectedSorting = sortingOptions[sortingValue];

  return items.sort(selectedSorting);
};
