import { Query } from "../callApi";

export const getQueryOperationsForms = (): Query => ({
  endpoint: "operations/forms",
  method: "GET",
});

export const getQueryOperationsFullForm = (layoutID: string): Query => ({
  endpoint: `operations/form/${layoutID}`,
  method: "GET",
});
