import {
  Box,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { BasicTableColumnCell, BasicTableOrder, BasicTableRow } from "./basicTableUtils";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import cssFontStyles from "../../../Global/Styles/font";

const cssStyles = (theme: Theme) => ({
  basicHeader: css({
    background: theme.palette.primary.main,
    th: {
      "span, svg": {
        color: `${theme.palette.common.white} !important`,
      },
    },
  }),
});

interface BasicTableHeaderProps<T extends BasicTableRow> {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order: BasicTableOrder;
  orderBy: keyof T;
  columns: BasicTableColumnCell[];
}

const BasicTableHeader = <T extends BasicTableRow>({
  order,
  orderBy,
  onRequestSort,
  columns,
}: BasicTableHeaderProps<T>) => {
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssFontStyles };

  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead css={styles.basicHeader}>
      <TableRow>
        {columns.map((headCell) => (
          <TableCell
            key={headCell.id.toString()}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              css={orderBy === headCell.id ? styles.bolderText : undefined}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id.toString())}
            >
              <Typography variant="body1">{headCell.label}</Typography>
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default BasicTableHeader;
