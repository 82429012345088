import { Query } from "../callApi";
import { PostQueryCreateAlertInput, PostQueryCreateRuleInput } from "./apiRulesInputs";

export const postQueryCreateRule = (input: PostQueryCreateRuleInput): Query => ({
  endpoint: "data_manager/rule",
  method: "POST",
  variables: input,
});

export const postQueryUpdateRule = (
  ruleId: string,
  input: PostQueryCreateRuleInput
): Query => ({
  endpoint: `data_manager/rule/${ruleId}`,
  method: "PUT",
  variables: input,
});

export const postQueryCreateAlert = (
  ruleId: string,
  input: PostQueryCreateAlertInput
): Query => ({
  endpoint: `data_manager/rule/${ruleId}/alert`,
  method: "POST",
  variables: input,
});

export const postQueryDeleteAlert = (alertId: string): Query => ({
  endpoint: `data_manager/alert/${alertId}`,
  method: "DELETE",
});

export const postQuerySetAlertToRead = (alertId: string): Query => ({
  endpoint: `data_manager/alert/${alertId}`,
  method: "PUT",
});
