import { Query } from "../callApi";
import {
  PostQueryCreateMachineInput,
  PostQueryCreateTaskInput,
  PostQueryCreateTaskTemplateInput,
  PostQueryUpdateMachineInput,
  PostQueryUpdateTaskInput,
  PostQueryUpdateTaskTemplateInput,
  PostQueryUploadFileInput,
} from "./apiMaintenanceInputs";

export const postQueryCreateMachine = (input: PostQueryCreateMachineInput): Query => ({
  endpoint: "maintenance/machine",
  method: "POST",
  variables: input,
});

export const postQueryUpdateMachine = (
  id: string,
  input: PostQueryUpdateMachineInput
): Query => ({
  endpoint: `maintenance/machine/${id}`,
  method: "PUT",
  variables: input,
});

export const postQueryDeleteMachine = (documentId: string): Query => ({
  endpoint: `maintenance/machine/${documentId}`,
  method: "DELETE",
});

export const postQueryMaintenanceUploadFile = (
  machineDocumentId: string,
  input: PostQueryUploadFileInput
): Query => ({
  endpoint: `maintenance/machine/${machineDocumentId}/file/upload`,
  method: "POST",
  multipartForm: true,
  variables: input,
});

export const postQueryMaintenanceDeleteFile = (fileId: string): Query => ({
  endpoint: `maintenance/file/${fileId}`,
  method: "DELETE",
});

export const postQueryCreateTask = (
  machineDocumentId: string,
  input: PostQueryCreateTaskInput
): Query => ({
  endpoint: `maintenance/machine/${machineDocumentId}/task`,
  method: "POST",
  variables: input,
});

export const postQueryUpdateTask = (
  taskId: string,
  task: PostQueryUpdateTaskInput
): Query => ({
  endpoint: `maintenance/machine/task/${taskId}`,
  method: "PUT",
  variables: task,
});

export const postQueryDeleteTask = (taskId: string): Query => ({
  endpoint: `maintenance/machine/task/${taskId}`,
  method: "DELETE",
});

export const postQueryCreateTaskTemplate = (
  machineDocumentId: string,
  input: PostQueryCreateTaskTemplateInput
): Query => ({
  endpoint: `maintenance/machine/${machineDocumentId}/repetitive-task-template`,
  method: "POST",
  variables: input,
});
export const postQueryUpdateTaskTemplate = (
  templateID: string,
  input: PostQueryUpdateTaskTemplateInput
): Query => ({
  endpoint: `maintenance/machine/repetitive-task-template/${templateID}`,
  method: "PUT",
  variables: input,
});
export const postQueryMaintenanceUploadTaskFile = (
  taskID: string,
  input: PostQueryUploadFileInput
): Query => ({
  endpoint: `maintenance/machine/task/${taskID}/file/upload`,
  method: "POST",
  multipartForm: true,
  variables: input,
});
export const postQueryMaintenanceUploadTaskTemplateFile = (
  templateID: string,
  input: PostQueryUploadFileInput
): Query => ({
  endpoint: `maintenance/machine/repetitive-task-template/${templateID}/file/upload`,
  method: "POST",
  multipartForm: true,
  variables: input,
});
