import { SerializedStyles } from "@emotion/react";
import { DynamicGridFormFieldOptions } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import Button from "../../../MaterialUI/Button";
import { Box, Stack } from "@mui/material";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import RadioGroup from "../../../MaterialUI/FormFields/RadioGroup";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import Checkbox from "../../../MaterialUI/FormFields/Checkbox";

const EXAMPLE_OPTIONS = handleGetSelectOption(["Option 1", "Option 2", "Option 3"]);

interface DynamicFormFieldProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  formField: DynamicGridFormFieldOptions;
}

const DynamicFormField: React.FC<DynamicFormFieldProps> = ({ className, formField }) => {
  const styles = { ...cssLayoutStyles };

  if (formField.button) {
    return (
      <Box component="div" className={className}>
        <Button css={styles.width100}>Button</Button>
      </Box>
    );
  }
  if (formField.textField) {
    return (
      <Box component="div" className={className}>
        <TextField css={styles.width100} label="Your text here">
          Button
        </TextField>
      </Box>
    );
  }
  if (formField.radioButton) {
    return (
      <Box component="div" className={className}>
        <RadioGroup
          css={styles.width100}
          radioOptions={EXAMPLE_OPTIONS}
          label="Your text here"
        />
      </Box>
    );
  }
  if (formField.checkbox) {
    return (
      <Stack className={className} spacing={0}>
        {EXAMPLE_OPTIONS.map((item) => (
          <Checkbox key={`checkbox-${item.value}`} label={item.description} />
        ))}
      </Stack>
    );
  }

  return null;
};

export default DynamicFormField;
