import { Query } from "../callApi";

export const getQueryBoschWorkflows = (): Query => ({
  endpoint: "workflows/workflows",
  method: "GET",
});

export const getQueryBoschWorkflow = (id: string): Query => ({
  endpoint: `workflows/workflow/${id}`,
  method: "GET",
});
export const getQueryBoschProcesses = (): Query => ({
  endpoint: "opex/processes",
  method: "GET",
});

export const getQueryBoschProcess = (id: string): Query => ({
  endpoint: `opex/process/${id}`,
  method: "GET",
});
