import React, { memo, useState } from "react";
import { useReactFlow } from "reactflow";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import PidFlowNode from "../PidFlowNode";
import {
  PID_FLOW_NODE_TYPE,
  PID_WORKFLOW_PADDING,
  PID_WORKFLOW_TOP_PART_HEIGHT,
} from "../pidNodesUtils";
import { PidWorkflowsBackgroundNodeData } from "./pidBackgroundNodeUtils";
import PidNodeBackgroundForm from "./PidNodeBackgroundForm";
import { Box, useTheme } from "@mui/material";

type ModalType = "Edit Node" | "Node Data" | null;

interface FlowCustomNodeProps {
  id: string;
  data: PidWorkflowsBackgroundNodeData;
}

const PidWorkflowBackgroundNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const [modalType, setModalType] = useState<ModalType>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodes, getNodes } = useReactFlow();

  const handleEditSubmit = (nodeData: PidWorkflowsBackgroundNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit Node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <PidFlowNode
        id={id}
        handleOpenEditModal={handleOpenEditModal}
        nodeType={PID_FLOW_NODE_TYPE.Background}
      >
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
          component="div"
        >
          <Box
            sx={{
              position: "absolute",
              width: `calc(100% + ${PID_WORKFLOW_PADDING * 2}px)`,
              height: `calc(100% + ${PID_WORKFLOW_PADDING * 2}px)`,
              top: `calc(-${PID_WORKFLOW_TOP_PART_HEIGHT}px - ${PID_WORKFLOW_PADDING}px)`,
              left: -PID_WORKFLOW_PADDING,
              zIndex: -1,
              // background: data.bgColor,
              background: theme.palette.background.paper,
              backgroundImage: data.bgImageUrl ? `url(${data.bgImageUrl})` : undefined,
              backgroundSize: data.bgImageUrl ? "cover" : undefined,
              backgroundPosition: data.bgImageUrl ? "center" : undefined,
            }}
            component="div"
          />
        </Box>
      </PidFlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType || ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Node" ? (
          <PidNodeBackgroundForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default memo(PidWorkflowBackgroundNode);
