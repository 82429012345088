import { Formik } from "formik";
import {
  YUP_NUMBER_REQUIRED_BETWEEN_0_100,
  YUP_REQUIRED_STRING,
} from "../../../Global/Constants/yupConstants";
import { object } from "yup";
import Select from "../../MaterialUI/FormFields/Select";
import Button from "../../MaterialUI/Button";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { useQuery } from "@apollo/client";
import { graphQlQueryExcellenceParameters } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { useEffect, useState } from "react";
import {
  AutocompleteGroupedOption,
  AutocompleteOption,
  FormStatuses,
} from "../../../Global/Types/commonTypes";
import Alert from "../../MaterialUI/Alert";
import { PostQueryCreateRuleInput } from "../../../Api/Rules/apiRulesInputs";
import {
  postQueryCreateAlert,
  postQueryCreateRule,
  postQueryUpdateRule,
} from "../../../Api/Rules/apiRulesPostQueries";
import {
  PostQueryCreateAlertSnipper,
  PostQueryCreateRuleSnippet,
  Rule,
} from "../../../Api/Rules/apiRulesSnippets";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import { ModalType } from "../../../pages/DataManager/Rules/Rules";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

export const RULES_LOCAL_STORAGE_KEY: string = "savedRules";
const OPERATOR_OPTIONS = handleGetSelectOption([">", "<", "="]);
const ACTION_OPTIONS = handleGetSelectOption(["Send Alert", "Send Email"]);

const fieldValidation = object({
  operator: YUP_REQUIRED_STRING,
  value: YUP_NUMBER_REQUIRED_BETWEEN_0_100,
  action: YUP_REQUIRED_STRING,
});

type CreateRuleValues = {
  operator: string;
  value: string;
  action: string;
};

interface RuleFormProps {
  modalType: ModalType;
  ruleId?: string;
  connection?: string;
  node?: string;
  operator?: string;
  value?: string;
  action?: string;
  setOpenRuleFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRulesData: React.Dispatch<React.SetStateAction<Rule[]>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const RuleForm: React.FC<RuleFormProps> = ({
  modalType,
  ruleId,
  connection,
  node,
  operator,
  value,
  action,
  setOpenRuleFormModal,
  setRulesData,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const { data, loading } = useQuery<{ parameters: ExcellenceParameter[] }>(
    graphQlQueryExcellenceParameters
  );
  const allConnectionOptions = handleGetSelectOption([
    ...new Set((data?.parameters || []).map((item) => item.container)),
  ]);
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [selectedNodes, setSelectedNodes] = useState<AutocompleteGroupedOption[]>(
    node && connection
      ? [
          {
            description: node,
            groupName: connection,
            value: node,
          },
        ]
      : []
  );
  const [selectedConnections, setSelectedConnections] = useState<AutocompleteOption[]>(
    connection ? [{ description: connection, value: connection }] : []
  );
  const { setAlertsData, setAuthedUser } = useAuthedContext();
  const initialValues: CreateRuleValues = {
    operator: operator || "",
    value: value || "",
    action: action || "",
  };

  useEffect(() => {
    const nodeOptionsFromSubs: AutocompleteGroupedOption[] =
      data?.parameters.map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      })) || [];
    setNodeOptions(nodeOptionsFromSubs);

    const savedRules = JSON.parse(localStorage.getItem("savedRules") || "[]");
    const savedRule = savedRules.find((rule: Rule) => rule.id === ruleId?.toString());
    setCurrentValue(savedRule?.currentValue || 0);
    // eslint-disable-next-line
  }, [data]);

  const handleOnConnectionChange = async (subs: AutocompleteOption[] | null) => {
    if (!subs?.length) {
      setNodeOptions([]);
      setSelectedNodes([]);
    } else {
      // 1. remove selectedNodes which are not in all node options
      setSelectedNodes((prev) =>
        prev.filter((node) => subs.some((sub) => sub.value === node.groupName))
      );
      if (selectedConnections.length > 0) return;

      // 2. set all node options based on selected subs
      const nodeOptionsFromSubs: AutocompleteGroupedOption[] =
        data?.parameters
          .map((item) => ({
            groupName: item.container,
            value: item.id,
            description: item.name,
          }))
          .filter((node) => subs.some((sub) => sub.value === node.groupName)) || [];
      setNodeOptions(nodeOptionsFromSubs);
    }

    setSelectedConnections(subs || []);
  };

  const handleOnNodeChange = async (nodes: AutocompleteGroupedOption[] | null) => {
    try {
      setFormStatus("loading");

      if (nodes && nodes.length > 1) {
        nodes.splice(1);
      }

      if (selectedConnections.length > 0) {
        const nodeOptionsFromSubs: AutocompleteGroupedOption[] =
          data?.parameters
            .map((item) => ({
              groupName: item.container,
              value: item.id,
              description: item.name,
            }))
            .filter((node) =>
              selectedConnections.some((sub) => sub.value === node.groupName)
            ) || [];
        setNodeOptions(nodeOptionsFromSubs);
      }

      setSelectedNodes(nodes || []);
      setFormStatus("success");
    } catch (err) {
      console.log("err handleOnNodeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleUpdateCurrentValue = async () => {
    setFormStatus("loading");
    setAlertMessage("Loading...");
    if (!ruleId || !value) return;
    if (!currentValue) {
      setFormStatus("error");
      setAlertMessage("Please enter a value");
      return;
    }

    try {
      const object: { id: string; currentValue: number } = {
        id: ruleId,
        currentValue: currentValue,
      };

      const savedRules = JSON.parse(
        localStorage.getItem(RULES_LOCAL_STORAGE_KEY) || "[]"
      );

      const existingRuleIndex = savedRules.findIndex(
        (rule: any) => rule.id === object.id
      );

      if (existingRuleIndex !== -1) {
        savedRules[existingRuleIndex] = object;
      } else {
        savedRules.push(object);
      }

      localStorage.setItem(RULES_LOCAL_STORAGE_KEY, JSON.stringify(savedRules));

      if (currentValue && operator) {
        const shouldCreateAlert =
          (operator === "<" && currentValue < +value) ||
          (operator === ">" && currentValue > +value) ||
          (operator === "=" && currentValue === +value);

        if (shouldCreateAlert) {
          const alert = await callApi<PostQueryCreateAlertSnipper>({
            query: postQueryCreateAlert(ruleId.toString(), {
              alert_message: `${node} = ${currentValue}`,
            }),
            auth: { setAuthedUser },
          });

          if (alert) {
            setAlertsData((prevAlerts) => ({
              ...prevAlerts,
              unread: [alert, ...prevAlerts.unread],
            }));
            setFormStatus("success");
            setAlertMessage("Successfully saved");
            setOpenRuleFormModal(false);
          }
        } else {
          setFormStatus("success");
          setAlertMessage("Successfully saved");
          setOpenRuleFormModal(false);
        }
      }
    } catch (err) {
      console.log("Update current value, err: ", err.message);
      setFormStatus("error");
      setAlertMessage(err.message);
    }
  };

  const handleFormSubmit = async (values: CreateRuleValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage("Loading...");

      if (selectedConnections.length === 0) {
        setFormStatus("error");
        setAlertMessage("Please select a connection");
        return;
      }

      if (selectedNodes.length === 0) {
        setFormStatus("error");
        setAlertMessage("Please select a node");
        return;
      }

      const input: PostQueryCreateRuleInput = {
        container_name: selectedConnections[0].value,
        parameter_name: selectedNodes[0].value,
        rule: values.operator,
        number: +values.value,
        alert_message: values.action,
      };

      if (!modalType) {
        const rule = await callApi<PostQueryCreateRuleSnippet>({
          query: postQueryCreateRule(input),
          auth: { setAuthedUser },
        });

        if (rule) {
          setFormStatus("success");
          setAlertMessage("Rule created successfully");
          setRulesData((prev) => [...prev, rule]);
          setUnsavedChanges(false);
          setOpenRuleFormModal(false);
        }
      } else if (modalType === "edit") {
        if (!ruleId) return;

        const rule = await callApi<PostQueryCreateRuleSnippet>({
          query: postQueryUpdateRule(ruleId.toString(), input),
          auth: { setAuthedUser },
        });

        if (rule) {
          setFormStatus("success");
          setAlertMessage("Rule updated successfully");
          setUnsavedChanges(false);
          setRulesData((prevRulesData) => {
            return prevRulesData.map((rule) => {
              if (rule.id.toString() === ruleId.toString()) {
                return {
                  ...rule,
                  container_name: selectedConnections[0].value,
                  parameter_name: selectedNodes[0].value,
                  rule: values.operator,
                  number: +values.value,
                  alert_message: values.action,
                };
              }
              return rule;
            });
          });
          setOpenRuleFormModal(false);
        }
      }
    } catch (err) {
      console.log("Create rule form, err: ", err.message);
      setFormStatus("error");
      setAlertMessage(err.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack gap={4}>
              <Box component="div">
                <Typography>If</Typography>

                <Autocomplete
                  label="Select connections"
                  multiple
                  options={allConnectionOptions}
                  value={selectedConnections}
                  handleOnChange={handleOnConnectionChange}
                  disabled={loading || modalType === "update" ? true : false}
                />

                {selectedConnections.length > 0 ? (
                  <Box component="div" mt={2}>
                    <AutocompleteGrouped
                      label="Select nodes"
                      multiple
                      options={nodeOptions}
                      value={selectedNodes}
                      handleOnChange={handleOnNodeChange}
                      disabled={loading || modalType === "update" ? true : false}
                    />
                  </Box>
                ) : null}
              </Box>

              <Box component="div">
                <Typography>Operator</Typography>
                <Select
                  name="operator"
                  selectOptions={OPERATOR_OPTIONS}
                  label="Select an operator"
                  error={touched["operator"] && !!errors["operator"]}
                  helperText={touched["operator"] && errors["operator"]}
                  onChange={handleChange}
                  value={values.operator}
                  disabled={loading || modalType === "update" ? true : false}
                />
              </Box>

              <Box component="div">
                <Typography>Value</Typography>
                <TextField
                  name="value"
                  label="Type a value"
                  error={touched["value"] && !!errors["value"]}
                  helperText={touched["value"] && errors["value"]}
                  onChange={handleChange}
                  value={values.value}
                  disabled={loading || modalType === "update" ? true : false}
                />
              </Box>

              <Box component="div">
                <Typography>Action</Typography>
                <Select
                  name="action"
                  selectOptions={ACTION_OPTIONS}
                  label="Select an action"
                  error={touched["action"] && !!errors["action"]}
                  helperText={touched["action"] && errors["action"]}
                  onChange={handleChange}
                  value={values.action}
                  disabled={loading || modalType === "update" ? true : false}
                />
              </Box>

              {modalType === "update" ? (
                <>
                  <Divider />
                  <Box component="div">
                    <Typography>Current {node} value</Typography>
                    <TextField
                      name="value"
                      label="Type a value"
                      onChange={(e) => setCurrentValue(+e.target.value)}
                      value={currentValue}
                    />
                  </Box>
                </>
              ) : null}

              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />

              {!modalType ? <Button type="submit">Create</Button> : null}
              {modalType === "edit" ? <Button type="submit">Save</Button> : null}
              {modalType === "update" ? (
                <Button onClick={handleUpdateCurrentValue}>Update</Button>
              ) : null}
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default RuleForm;
