import { Query } from "../callApi";

export const getQueryMaintenanceMachines: Query = {
  endpoint: "maintenance/machines",
  method: "GET",
};

export const getQueryMaintenanceMacine = (documentId: string): Query => ({
  endpoint: `maintenance/machine/${documentId}`,
  method: "GET",
});

export const getQueryMaintenanceFiles = (machineDocumentId: string): Query => ({
  endpoint: `maintenance/machine/${machineDocumentId}/files`,
  method: "GET",
});

export const getQueryMaintenanceFile = (fileId: string): Query => ({
  endpoint: `maintenance/file/${fileId}`,
  method: "GET",
});

export const getQueryMaintenanceFileDownload = (fileId: string): Query => ({
  endpoint: `maintenance/file/${fileId}/download`,
  method: "GET",
  returnJson: false,
});

export const getQueryMaintenanceFileDisplay = (fileId: string): Query => ({
  endpoint: `maintenance/file/${fileId}/display`,
  method: "GET",
  returnJson: false,
});

export const getQueryMaintenanceMacineTasks = (machineDocumentId: string): Query => ({
  endpoint: `maintenance/machine/${machineDocumentId}/tasks`,
  method: "GET",
});

export const getQueryMaintenanceTask = (taskId: string): Query => ({
  endpoint: `maintenance/machine/task/${taskId}`,
  method: "GET",
});

export const getQueryMaintenanceTasks = (limit?: number): Query => ({
  endpoint: limit ? `maintenance/tasks?limit=${limit}` : `maintenance/tasks`,
  method: "GET",
});
