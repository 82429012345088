import { LineAreaHeatMapScatterPlotData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  TimeChartThresholdValues,
  ChartXScaleMinMax,
  TimeChartConfiguration,
} from "./timeChartTypes";
import {
  ExcellenceDateTimeConfigurationData,
  dateTimeConfigCalculateDateFromTimeValue,
} from "../../SmallComponents/DateTimeConfiguration/dateTimeConfigurationUtils";
import { dateOrStringTransform } from "../../../Global/Utils/commonFunctions";
import { PeriodModeDates } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import { Layer } from "@nivo/line";

export const timeChartMinMaxY = (
  array: LineAreaHeatMapScatterPlotData,
  configuration: TimeChartConfiguration,
  threshold?: TimeChartThresholdValues
): { min: number; max: number } => {
  const configYMin = configuration.yAxisMinAndMax ? configuration.yAxisMin : null;
  const configYMax = configuration.yAxisMinAndMax ? configuration.yAxisMax : null;

  let min = Infinity;
  let max = -Infinity;
  // let minIsThreshold: boolean = false;

  array.forEach((item) => {
    item.data.forEach((dataItem) => {
      if (dataItem.y < min) {
        min = dataItem.y;
      }
      if (dataItem.y > max) {
        max = dataItem.y;
      }
    });
  });

  const parseThresholdValue = (value: number | string) => {
    if (typeof value === "string") {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return parsedValue;
      }
    }
    return value as number;
  };

  if (threshold) {
    if (threshold.lowLowThreshold) {
      const value = parseThresholdValue(threshold.lowLowThreshold.value);
      if (!isNaN(value)) {
        if ((value || value === 0) && value < min) {
          // minIsThreshold = true;
          min = value;
        }
      }
    }
    if (threshold.lowThreshold) {
      const value = parseThresholdValue(threshold.lowThreshold.value);
      if (!isNaN(value)) {
        if ((value || value === 0) && value < min) {
          // minIsThreshold = true;
          min = value;
        }
      }
    }
    if (threshold.highThreshold) {
      const value = parseThresholdValue(threshold.highThreshold.value);
      if (!isNaN(value)) {
        max = Math.max(max, value);
      }
    }
    if (threshold.highHighThreshold) {
      const value = parseThresholdValue(threshold.highHighThreshold.value);
      if (!isNaN(value)) {
        max = Math.max(max, value);
      }
    }
  }

  const configYMinToUse = configYMin === "" ? null : configYMin;
  const configYMaxToUse = configYMax === "" ? null : configYMax;

  return {
    // min: changeByPercent(min, minIsThreshold ? "increase" : "decrease"),
    min: configYMinToUse ?? changeByPercent(min, min > 0 ? "decrease" : "increase"),
    max: configYMaxToUse ?? changeByPercent(max, "increase"),
  };
};

const changeByPercent = (inputNumber: number, mode: "increase" | "decrease"): number => {
  const percentage = 1.3;
  const increasedNumber =
    mode === "increase" ? inputNumber * percentage : inputNumber / percentage;

  const roundedNumber = Math.round(increasedNumber * 100) / 100;

  return roundedNumber;
};

type StartEndTimes = {
  startTime: string | null;
  endTime: string | null;
};

export const getTimeChartStartEndTimes = (
  data: ExcellenceDateTimeConfigurationData
): StartEndTimes => {
  const {
    dateFrom,
    dateTo,
    //
    liveDataPeriodNumber,
    liveDataPeriodTime,
    liveDataStartNumber,
    liveDataStartPeriod,
    //
    commonPeriod,
    commonPeriodStart,
    commonPeriodEnd,
  } = data;
  let startTime = null;
  let endTime = null;

  // 1. Static data
  if (dateFrom && dateTo) {
    startTime = dateOrStringTransform<string>(dateFrom, "string");
    endTime = dateOrStringTransform<string>(dateTo, "string");
  }

  // 2. Live Data
  if (liveDataPeriodNumber && liveDataPeriodTime) {
    const parsedEndDate =
      liveDataStartNumber && liveDataStartPeriod
        ? dateTimeConfigCalculateDateFromTimeValue(
            +liveDataStartNumber,
            liveDataStartPeriod
          )
        : new Date();

    const parsedDateFromNow = dateTimeConfigCalculateDateFromTimeValue(
      +liveDataPeriodNumber,
      liveDataPeriodTime,
      parsedEndDate || new Date()
    );

    if (parsedEndDate && parsedDateFromNow) {
      startTime = parsedDateFromNow.toISOString();
      endTime = parsedEndDate.toISOString();
    }
  }

  // 3. Common Period
  if (commonPeriod && commonPeriodStart && commonPeriodEnd) {
    startTime = dateOrStringTransform<string>(commonPeriodStart, "string");
    endTime = dateOrStringTransform<string>(commonPeriodEnd, "string");
  }

  return {
    startTime,
    endTime,
  };
};

export const getTimeChartXScaleMinMax = (
  data: ExcellenceDateTimeConfigurationData | null | undefined,
  dataPoints: LineAreaHeatMapScatterPlotData | null,
  period?: PeriodModeDates
): ChartXScaleMinMax => {
  if (!data) {
    return null;
  }
  if (period) {
    return {
      min: period.startDate.toISOString(),
      max: period.endDate.toISOString(),
    };
  }
  if (!data.commonPeriod && !data.dateFrom) {
    return null;
  }

  const startEnd = getTimeChartStartEndTimes(data);
  if (dataPoints && startEnd.startTime && startEnd.endTime) {
    return {
      min: startEnd.startTime!,
      max: startEnd.endTime!,
    };
  }

  return null;
};

export const defaultLayers: Layer[] = [
  "grid",
  "markers",
  "areas",
  "lines",
  "slices",
  "points",
  "axes",
  "legends",
];
