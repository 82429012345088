import {
  Box,
  Card,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { MaintenanceMachineTask } from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import { MaintenancePlanAddEditFormResult } from "../maintenancePlanUtils";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useState } from "react";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import DatePicker from "../../../../MaterialUI/DateTimePickers/DatePicker";
import TimePicker from "../../../../MaterialUI/DateTimePickers/TimePicker";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";
import Button from "../../../../MaterialUI/Button";
import { differenceInMinutes, isValid } from "date-fns";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

type HoursWorked = {
  date: Date | null;
  startTime: Date | null;
  endTime: Date | null;
  wholeShift: boolean;
  rest: number;
};

interface MaintenancePlanWorkingHoursProps {
  task: MaintenanceMachineTask;
  handleChange: (value?: MaintenancePlanAddEditFormResult) => Promise<void>;
  isAddNew: boolean;
}

const MaintenancePlanWorkingHours: React.FC<MaintenancePlanWorkingHoursProps> = ({
  isAddNew,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [workHours, setWorkHours] = useState<HoursWorked[]>([]);

  const totalHours = workHours.reduce((acc, curr) => {
    if (curr.wholeShift) {
      const fullRest = 1 * 60;
      const fullShift = 8 * 60;
      return acc + fullShift - fullRest;
    }
    if (curr.endTime && curr.startTime) {
      if (isValid(curr.endTime) && isValid(curr.startTime)) {
        const mins = differenceInMinutes(curr.endTime, curr.startTime);
        const endResult = mins - curr.rest;
        if (endResult > 0) {
          return acc + endResult;
        }
        return acc + 0;
      }
    }
    return acc;
  }, 0);
  const formattedHours = totalHours > 0 ? (totalHours / 60).toFixed(2) : 0;

  const handleChangeHours = (key: keyof HoursWorked, value: any, shiftIndex: number) => {
    setWorkHours((prev) =>
      prev.map((item, index) => {
        if (index === shiftIndex) {
          return {
            ...item,
            [key]: value,
          };
        }
        return item;
      })
    );
  };

  const handleAdd = () => {
    setWorkHours((prev) => [
      ...prev,
      {
        date: null,
        startTime: null,
        endTime: null,
        wholeShift: false,
        rest: 0,
      },
    ]);
  };

  const handleRemove = (index: number) => {
    setWorkHours((prev) => prev.filter((_, itemIndex) => index !== itemIndex));
  };
  const handleSave = () => {};

  if (isAddNew) {
    return (
      <Box component="div" css={styles.textBreak}>
        <Stack
          css={styles.labelBreak}
          spacing={2}
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography variant="body1">Here, you can log workout hours</Typography>

          <Button onClick={handleAdd}>Add New</Button>
        </Stack>

        <Divider css={styles.labelBreak} />

        {workHours.length ? (
          <Box component="div">
            <Box component="div" css={styles.contentBreak}>
              {workHours.map((item, index) => (
                <Card
                  style={{ padding: theme.spacing(2), paddingTop: theme.spacing(1) }}
                  css={styles.labelBreak}
                  key={`item=${index}`}
                >
                  <Stack flexDirection="row" justifyContent="flex-end">
                    <IconButton aria-label="remove" onClick={() => handleRemove(index)}>
                      <DeleteOutlineOutlinedIcon color="error" />
                    </IconButton>
                  </Stack>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <DatePicker
                        label="Date"
                        value={item.date}
                        onChange={(e) => handleChangeHours("date", e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box component="div" style={{ paddingTop: "10px" }}>
                        <Checkbox
                          label="Work took whole shift"
                          checked={item.wholeShift}
                          onChange={(e) =>
                            handleChangeHours("wholeShift", e.target.checked, index)
                          }
                        />
                      </Box>
                    </Grid>
                    {item.wholeShift ? null : (
                      <>
                        <Grid item xs={12} sm={4}>
                          <TimePicker
                            label="Start Time"
                            value={item.startTime}
                            onChange={(e) => handleChangeHours("startTime", e, index)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TimePicker
                            label="End Time"
                            value={item.endTime}
                            onChange={(e) => handleChangeHours("endTime", e, index)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <TextField
                            label="Rest"
                            value={item.rest}
                            onChange={(e) =>
                              handleChangeHours("rest", e.target.value, index)
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start">minutes</InputAdornment>
                              ),
                            }}
                            numberField
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Card>
              ))}
            </Box>

            <Divider css={styles.labelBreak} />
            <LabelWithBoldedPart
              css={styles.contentBreak}
              text="Total hours"
              bolded={formattedHours}
              variant="body1"
            />

            <Stack css={styles.width100} alignItems="center" justifyContent="center">
              <Button css={styles.widthLimit20} onClick={handleSave}>
                Save Changes
              </Button>
            </Stack>
          </Box>
        ) : (
          <Typography variant="body1" textAlign="center">
            You haven't added any working hours yet
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box component="div" css={styles.textBreak}>
      <Typography variant="body1" textAlign="center">
        Task doesn't have any logged in working hours
      </Typography>
    </Box>
  );
};

export default MaintenancePlanWorkingHours;
