import { Query } from "../../../Api/callApi";
import { ShiftPlan } from "./shiftPlanningUtils";

export const getQueryDemoShifts: Query = {
  endpoint: "project_management/demo-shifts",
  method: "GET",
};

export const createDemoShift = (input: ShiftPlan): Query => ({
  endpoint: "project_management/demo-shift",
  method: "POST",
  variables: { data: input },
});

export const updateDemoShift = (id: string, input: ShiftPlan): Query => ({
  endpoint: `project_management/demo-shift/${id}`,
  method: "PUT",
  variables: { data: input },
});
