import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import { ResponsiveRadialBar } from "@nivo/radial-bar";
import { OEEModalTitle, OEERadialChartsDataType, getChartColorByType } from "../oEEUtils";
import { oEERadialChartsData } from "./oEEChartsData";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Modal from "../../../MaterialUI/Modal";
import Button from "../../../MaterialUI/Button";
import EditOEEBarChartForm from "./EditOEEBarChartForm";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { format, startOfMonth } from "date-fns";

const OEERadialChart: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [selectedTimeChartData, setSelectedTimeChartData] =
    useState<OEERadialChartsDataType>([]);
  const currentTarget = oEERadialChartsData.currentTarget;
  const [modalTitle, setModalTitle] = useState<OEEModalTitle | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);
  const [selectedDesiredTarget, setSelectedDesiredTarget] = useState<number>(80);

  useEffect(() => {
    setSelectedTimeChartData(oEERadialChartsData.chartData);
  }, []);

  const chartValues = selectedTimeChartData.reduce((acc, curr) => {
    acc[curr.id] = curr.data[0].y;
    return acc;
  }, {} as Record<string, number>);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body2" mb={1}>
            OEE
          </Typography>
          <Typography variant="h3">Overall</Typography>
        </Grid>

        <Grid item>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Typography variant="body2" color={theme.palette.grey[700]}>
                {`${format(startOfMonth(new Date()), "dd.MM.yyyy")} - ${format(
                  new Date(),
                  "dd.MM.yyyy"
                )}`}
              </Typography>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="widget setting menu"
                onClick={handleOpenMenu}
                size="small"
              >
                <TuneOutlinedIcon css={styles.greyIcon} fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {selectedTimeChartData.length !== 0 ? (
        <>
          <Box component="div" height="245px" position="relative">
            <ResponsiveRadialBar
              data={selectedTimeChartData}
              valueFormat=">-.2f"
              padding={0.2}
              cornerRadius={3}
              endAngle={360}
              maxValue={100}
              innerRadius={0.4}
              colors={({ id }) => {
                const mainId = (id as string).split(".")[0];
                return getChartColorByType(mainId);
              }}
              borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
              radialAxisStart={null}
              enableRadialGrid={false}
              enableCircularGrid={false}
              layers={["bars", "labels"]}
              margin={{ top: 0, right: 10, bottom: 0, left: 10 }}
              theme={{
                text: {
                  fill: theme.palette.common.black,
                },
                tooltip: {
                  container: {
                    background: theme.palette.common.white,
                  },
                },
              }}
            />
            <Box
              component="div"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
            >
              <Typography variant="h3" style={{ color: theme.palette.common.black }}>
                {currentTarget}%
              </Typography>
            </Box>
          </Box>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mt={2}
            mr={2}
            ml={2}
          >
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">Availability</Typography>
              <Typography
                variant="h4"
                style={{ color: getChartColorByType("Availability") }}
              >
                {chartValues.Availability}%
              </Typography>
            </Box>
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">Performance</Typography>
              <Typography
                variant="h4"
                style={{ color: getChartColorByType("Performance") }}
              >
                {chartValues.Performance}%
              </Typography>
            </Box>
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">Quality</Typography>
              <Typography variant="h4" style={{ color: getChartColorByType("Quality") }}>
                {chartValues.Quality}%
              </Typography>
            </Box>
            <Box
              component="div"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="body2">Target</Typography>
              <Typography variant="h4" color={theme.palette.common.black}>
                {selectedDesiredTarget}%
              </Typography>
            </Box>
          </Stack>
        </>
      ) : null}
      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
          <Stack alignItems="center">
            <IconButton
              aria-label="edit loss"
              onClick={() => setModalTitle("Edit OEE Chart")}
            >
              <EditOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Edit
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="copy widget"
              onClick={() => setModalTitle("Copy OEE Chart")}
            >
              <DifferenceOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center">
              Copy
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="delete loss"
              onClick={() => setModalTitle("Delete OEE Chart")}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Delete
            </Typography>
          </Stack>
        </Stack>
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth={modalTitle === "Delete OEE Chart" ? "sm" : "md"}
        label={modalTitle}
      >
        {modalTitle === "Edit OEE Chart" ? (
          <EditOEEBarChartForm
            selectedDesiredTarget={selectedDesiredTarget}
            setSelectedDesiredTarget={setSelectedDesiredTarget}
          />
        ) : null}
        {modalTitle === "Delete OEE Chart" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>Clicking the below button will delete this chart</Typography>
            <Button>Confirm Delete</Button>
          </Stack>
        ) : null}
      </Modal>
    </>
  );
};

export default OEERadialChart;
