import { SortableContext, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useMemo, useState } from "react";
import { KanbanBoardColumn, KanbanBoardId } from "./kanbanBoardUtils";
import KanbanBoardTaskCard from "./KanbanBoardTaskCard";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { KanbanTask } from "../../../pages/TaskManager/Components/taskManagerUtils";

interface Props {
  column: KanbanBoardColumn;
  deleteColumn?: (id: KanbanBoardId) => void;
  updateColumn?: (id: KanbanBoardId, title: string) => void;

  createTask?: (columnId: KanbanBoardId) => void;
  updateTask?: (id: KanbanBoardId, content: string) => void;
  deleteTask?: (id: KanbanBoardId) => void;
  tasks: KanbanTask[];
  handleOnTaskClick: (task: KanbanTask) => void;
  refetchTasks: () => Promise<void>;
  disableColDrag?: boolean;
  disableColNameChange?: boolean;
}

function KanbanBoardColumnContainer({
  column,
  deleteColumn,
  updateColumn,
  // createTask,
  tasks,
  deleteTask,
  handleOnTaskClick,
  refetchTasks,
  disableColDrag,
  disableColNameChange,
}: Props) {
  const theme = useTheme();
  const [editMode, setEditMode] = useState(false);

  const tasksIds = useMemo(() => {
    return tasks.map((task) => task.id);
  }, [tasks]);

  const { setNodeRef, attributes, listeners, transform, transition, isDragging } =
    useSortable({
      id: column.id,
      data: {
        type: "Column",
        column,
      },
      disabled: disableColDrag || editMode,
    });

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
    backgroundColor:
      theme.palette.mode === "dark"
        ? theme.palette.customColors.darkGray
        : theme.palette.grey[100],
    borderColor: theme.palette.common.black,
    width: "280px",
    height: "700px",
    maxHeight: "700px",
    borderRadius: "0.375rem",
    display: "flex",
  };

  if (isDragging) {
    return (
      <Box
        component="div"
        ref={setNodeRef}
        style={{
          ...style,
          opacity: 0.4,
          flexDirection: "column",
        }}
      ></Box>
    );
  }

  return (
    <Box
      component="div"
      ref={setNodeRef}
      style={{
        ...style,
        flexDirection: "column",
      }}
    >
      {/* Column title */}
      <Box
        component="div"
        {...attributes}
        {...listeners}
        onClick={() => {
          if (disableColNameChange) {
            return;
          }
          setEditMode(true);
        }}
        style={{
          backgroundColor: style.backgroundColor,
          height: "60px",
          cursor: disableColDrag ? "default" : "grab",
          borderRadius: style.borderRadius,
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          padding: "0.75rem",
          fontWeight: "bold",
          borderColor: style.borderColor,
          borderWidth: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          component="div"
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Box
            component="div"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: style.backgroundColor,
              border: `1px solid ${
                theme.palette.mode === "dark"
                  ? theme.palette.grey[900]
                  : theme.palette.grey[400]
              }`,
              padding: "2.5px 10px",
              borderRadius: "100%",
            }}
          >
            <Typography variant="body2">{tasksIds.length}</Typography>
          </Box>

          {!editMode && column.title}

          {editMode && (
            <TextField
              value={column.title}
              onChange={
                updateColumn ? (e) => updateColumn(column.id, e.target.value) : undefined
              }
              autoFocus
              onBlur={() => {
                setEditMode(false);
              }}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                setEditMode(false);
              }}
            />
          )}
        </Box>
        {deleteColumn ? (
          <IconButton
            aria-label="delete column"
            onClick={() => {
              deleteColumn(column.id);
            }}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        ) : null}
      </Box>

      {/* Column task container */}
      <Box
        component="div"
        style={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          gap: "1rem",
          padding: "0.5rem",
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <SortableContext items={tasksIds}>
          {tasks.map((task) => (
            <KanbanBoardTaskCard
              key={task.id}
              task={task}
              deleteTask={deleteTask}
              handleOnClick={handleOnTaskClick}
              refetchTasks={refetchTasks}
            />
          ))}
        </SortableContext>
      </Box>

      {/* Column footer */}
      {/* <Button
        onClick={() => {
          createTask(column.id);
        }}
        variant="outlined"
      >
        Add task
      </Button> */}
    </Box>
  );
}

export default KanbanBoardColumnContainer;
