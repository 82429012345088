import { SerializedStyles } from "@emotion/react";
import {
  TextWidgetConfiguration,
  TextWidgetDataSchema,
} from "../../../../ExcellenceWidgets/TextWidget/textWidgetTypes";
import TextWidget from "../../../../ExcellenceWidgets/TextWidget/TextWidget";
import textWidgetDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/textWidgetDefaultData";

interface ExcellenceTextWidgetProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: null;
  configuration: TextWidgetConfiguration;
  isStatic?: boolean;
  schema: TextWidgetDataSchema | null;
}

const ExcellenceTextWidget: React.FC<ExcellenceTextWidgetProps> = ({
  className,
  configuration,
  isStatic,
  schema,
}) => {
  return (
    <TextWidget
      className={className}
      configuration={configuration}
      data={schema?.content || textWidgetDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceTextWidget;
