import { Card, Typography, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { MachineStatus } from "./conditionMonitoringUtils";
import cssComponentsStyles from "../../../../Global/Styles/components";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import ConditionMonitoringIcon from "./ConditionMonitoringIcon";
import { useState } from "react";
import Modal from "../../../MaterialUI/Modal";
import ConditionMonitoringMachineChart from "./ConditionMonitoringMachineChart";

const cssStyles = () => ({
  cardStyle: css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 18,
    boxShadow: "none",
  }),
});

interface ConditionMonitoringMachineInfoProps {
  machine: MachineStatus;
}

const ConditionMonitoringMachineInfo: React.FC<ConditionMonitoringMachineInfoProps> = ({
  machine,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(),
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [openChartModal, setOpenChartModal] = useState<boolean>(false);

  return (
    <>
      <Card
        css={[styles.cardStyle, styles.card, styles.fullPadding2]}
        onClick={() => setOpenChartModal(true)}
      >
        <ConditionMonitoringIcon state={machine.state} />
        <Typography>{machine.name}</Typography>
      </Card>

      <Modal
        open={openChartModal}
        fullWidth
        maxWidth="lg"
        label={`${machine.name}`}
        onClose={() => setOpenChartModal(false)}
      >
        <ConditionMonitoringMachineChart machineChartData={machine.chartData} />
      </Modal>
    </>
  );
};

export default ConditionMonitoringMachineInfo;
