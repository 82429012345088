import {
  Box,
  MobileStepper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import Button from "../../MaterialUI/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Alert from "../../MaterialUI/Alert";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../../Layout/layoutVariables";
import { useEffect } from "react";

const cssStyles = (theme: Theme) => ({
  absoluteItem: css({
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      right: 0,
      top: 0,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(3),
    },
  }),
  stepper: css({
    marginBottom: theme.spacing(1),
  }),
});

interface MultiStageFormProps {
  steps: string[];
  children: React.ReactNode;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleOnNextStage: () => Promise<boolean>;
  alertMessage: string | null;
  alertStatus: FormStatuses;
  disableNextButton?: boolean;
  disablePrevButton?: boolean;
  firstBack?: () => void;
  mobile?: boolean;
  lastNextButtonLabel?: string;
  title: string;
  saveCurrentStageData?: () => void;
  handleExitForm?: () => void;
  hasUnsavedChanges?: boolean;
}

const MultiStageForm: React.FC<MultiStageFormProps> = ({
  steps,
  activeStep,
  setActiveStep,
  children,
  handleOnNextStage,
  alertMessage,
  alertStatus,
  disableNextButton,
  disablePrevButton,
  firstBack,
  mobile,
  lastNextButtonLabel,
  title,
  saveCurrentStageData,
  handleExitForm,
  hasUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles(theme) };
  const xsMediaQuery = useMediaQuery("(max-width:599px)");
  const nextText = lastNextButtonLabel || "Confirm";

  const { setUnsavedChanges } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    if (hasUnsavedChanges !== undefined) {
      setUnsavedChanges(hasUnsavedChanges);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUnsavedChanges]);

  const handleNext = async () => {
    const result = await handleOnNextStage();
    if (result) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (firstBack && activeStep === 0) {
      firstBack();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleStepClick = (index: number) => {
    if (index < activeStep) {
      setActiveStep(index);
    }
  };

  const extraButtons = (
    <Stack
      css={styles.absoluteItem}
      spacing={2}
      alignItems="center"
      justifyContent="center"
      direction="row"
    >
      {handleExitForm ? (
        <Button
          size="small"
          onClick={handleExitForm}
          disabled={alertStatus === "loading"}
          color="secondary"
        >
          Cancel and Exit
        </Button>
      ) : null}

      {saveCurrentStageData ? (
        <Button
          size="small"
          onClick={saveCurrentStageData}
          disabled={alertStatus === "loading"}
          color="secondary"
        >
          Save and Exit
        </Button>
      ) : null}
    </Stack>
  );

  return (
    <Stack spacing={2}>
      <Typography style={{ paddingBottom: "10px" }} variant="h2" align="center">
        {title}
      </Typography>

      {xsMediaQuery ? null : (
        <Stepper css={styles.stepper} alternativeLabel activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step
              style={{ cursor: index < activeStep ? "pointer" : "default" }}
              key={label}
              onClick={() => handleStepClick(index)}
            >
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      )}

      <Box component="div">{children}</Box>

      <Stack alignItems="center" justifyContent="center">
        <Alert message={alertMessage} showAlert={!!alertMessage} severity={alertStatus} />
      </Stack>

      {xsMediaQuery || mobile ? (
        <Stack style={{ marginBottom: "0.5rem" }} spacing={2}>
          <MobileStepper
            variant="text"
            steps={steps.length}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={disableNextButton || alertStatus === "loading"}
                endIcon={
                  activeStep === steps.length - 1 ? undefined : <KeyboardArrowRight />
                }
              >
                {activeStep === steps.length - 1 ? nextText : "Next"}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={disablePrevButton || (firstBack ? false : activeStep === 0)}
                loading={alertStatus === "loading"}
                startIcon={<KeyboardArrowLeft />}
              >
                Back
              </Button>
            }
          />
          <Typography variant="h3" align="center">
            {steps[activeStep]}
          </Typography>

          {extraButtons}
        </Stack>
      ) : (
        <Box component="div" style={{ position: "relative" }}>
          <Stack spacing={2} alignItems="center" justifyContent="center" direction="row">
            <Button
              size="small"
              onClick={handleBack}
              disabled={disablePrevButton || (firstBack ? false : activeStep === 0)}
              loading={alertStatus === "loading"}
              startIcon={<KeyboardArrowLeft />}
            >
              Back
            </Button>

            <Button
              size="small"
              onClick={handleNext}
              disabled={disableNextButton || alertStatus === "loading"}
              endIcon={
                activeStep === steps.length - 1 ? undefined : <KeyboardArrowRight />
              }
            >
              {activeStep === steps.length - 1 ? nextText : "Next"}
            </Button>
          </Stack>

          {extraButtons}
        </Box>
      )}
    </Stack>
  );
};

export default MultiStageForm;
