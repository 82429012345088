import { PidWorkflowViewMode } from "../../pidWorkflowUtils";
import {
  PidWorkflowsBackgroundNodeData,
  PidWorkflowsBackgroundNodeType,
} from "./Background/pidBackgroundNodeUtils";
import { PidWorkflowsInitialNodeType } from "./Initial/pidInitialNodeUtils";
import {
  PidWorkflowsInputNodeData,
  PidWorkflowsInputNodeType,
} from "./Input/pidInputNodeUtils";
import { PidWorkflowsSvgNodeData, PidWorkflowsSvgNodeType } from "./Svg/pidSvgNodeUtils";
import {
  PidWorkflowsTextNodeData,
  PidWorkflowsTextNodeType,
} from "./Text/pidTextNodeUtils";

export const PID_WORKFLOW_DEFAULT_NODE_HEIGHT = 130;
export const PID_WORKFLOW_DEFAULT_NODE_WIDTH = 300;
export const PID_WORKFLOW_TOP_PART_HEIGHT = 38;
export const PID_WORKFLOW_PADDING = 8;

// Default
export const PID_WORKFLOW_VIEW_MODE_NODE_STYLE = {
  background: "#fff",
  borderWidth: "1px",
};
export const PID_WORKFLOW_EDIT_MODE_NODE_STYLE = {
  background: "transparent",
  borderWidth: "0px",
};

export const PID_WORKFLOW_DEFAULT_NODE_CONFIG = {
  style: {
    color: "#000",
    fontSize: 12,
    borderRadius: 15,
    width: PID_WORKFLOW_DEFAULT_NODE_WIDTH,
    cursor: "default",
    height: PID_WORKFLOW_DEFAULT_NODE_HEIGHT,
    padding: 7,
    ...PID_WORKFLOW_VIEW_MODE_NODE_STYLE,
  },
  dragHandle: ".custom-drag-handle",
};

export type PidSharedNodeData = {
  id: string;
  type: PidFlowNodeTypeKey;
  label: string;
  mode: PidWorkflowViewMode;
  createdOn: string;
  updatedOn: string;
};

export enum PID_FLOW_NODE_TYPE {
  initial = "initial",
  Text = "Text",
  Input = "Input",
  SVG = "SVG",
  Background = "Background",
}
export type PidFlowNodeTypeKey = keyof typeof PID_FLOW_NODE_TYPE;

export type PidWorkflowAllNodes = (
  | PidWorkflowsInitialNodeType
  | PidWorkflowsTextNodeType
  | PidWorkflowsSvgNodeType
  | PidWorkflowsInputNodeType
  | PidWorkflowsBackgroundNodeType
)[];

export type PidFlowAllNodesData = {
  [PID_FLOW_NODE_TYPE.Text]?: PidWorkflowsTextNodeData;
  [PID_FLOW_NODE_TYPE.SVG]?: PidWorkflowsSvgNodeData;
  [PID_FLOW_NODE_TYPE.Input]?: PidWorkflowsInputNodeData;
  [PID_FLOW_NODE_TYPE.Background]?: PidWorkflowsBackgroundNodeData;
};
