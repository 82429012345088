import { Box, Typography, Theme, Grid, Stack } from "@mui/material";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { Link } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const cssStyles = (theme: Theme) => ({
  mainContainer: css({
    width: "100%",
    height: "100vh",
  }),
  boxLeft: css({
    height: "100%",
    background: "linear-gradient(to right bottom, rgb(48, 175, 184), rgb(226, 255, 243))",
    padding: "4rem 2rem",
    [theme.breakpoints.down("md")]: {
      height: "8rem",
      padding: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12rem",
    },
  }),
  boxRight: css({
    height: "100%",
    background:
      theme.palette.mode === "light"
        ? theme.palette.common.white
        : theme.palette.customColors.darkBackgroundColor,
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      boxShadow: "none",
    },
  }),
  boxRightStack: css({
    padding: "0 2rem",
    [theme.breakpoints.down("md")]: {
      padding: "2rem 1rem",
    },
  }),
  boxHeading: css({
    color: "rgb(205, 247, 233)",
    fontWeight: "bold",
    marginBottom: "1rem",
    textAlign: "center",
  }),
  boxSubHeading: css({
    color: "rgb(205, 247, 233)",
    fontWeight: "bold",
    marginBottom: "4rem",
    textAlign: "center",
  }),
  boxSpan: css({
    color: "rgb(78, 78, 78)",
  }),
  image: css({
    width: "100%",
    maxWidth: "800px",
    height: "80%",
    objectFit: "contain",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }),
  signInBoxRightUserIcon: css({
    color: theme.palette.primary.main,
    fontSize: "4rem",
  }),
  footer: css({
    width: "100%",
    background: theme.palette.primary.light100,
    padding: "2rem 1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      gap: "2rem",
    },
  }),
  footerLink: css({
    color: theme.palette.primary.main,
    fontWeight: "bold",
    textDecoration: "none",
  }),
});

interface AccordionProps {
  children: React.ReactNode;
}

const AuthPagesLayout: React.FC<AccordionProps> = ({ children }) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };

  return (
    <Grid container css={styles.mainContainer}>
      <Grid item xs={12} md={6} css={styles.boxLeft}>
        <Typography variant="h1" component="h1" css={styles.boxHeading}>
          <Typography variant="h1" component="span" css={styles.boxSpan}>
            GIANT
          </Typography>{" "}
          - Your{" "}
          <Typography variant="h1" component="span" css={styles.boxSpan}>
            G
          </Typography>
          eneric{" "}
          <Typography variant="h1" component="span" css={styles.boxSpan}>
            I
          </Typography>
          nformation{" "}
          <Typography variant="h1" component="span" css={styles.boxSpan}>
            AN
          </Typography>
          alysis{" "}
          <Typography variant="h1" component="span" css={styles.boxSpan}>
            T
          </Typography>
          oolbox
        </Typography>
        <Typography variant="h2" component="h2" css={styles.boxSubHeading}>
          The platform where your data becomes a problem solver
        </Typography>
        <img
          css={styles.image}
          alt="register project image"
          src="/register_project_image.png"
        />
      </Grid>
      <Grid item xs={12} md={6} css={styles.boxRight}>
        <Stack
          width="100%"
          maxWidth="37.5rem"
          flex="1"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          css={styles.boxRightStack}
        >
          <AccountCircleIcon css={styles.signInBoxRightUserIcon} />
          {children}
        </Stack>
        <Box component="div" css={styles.footer}>
          <Link to="/terms-and-conditions" css={styles.footerLink}>
            Terms & Conditions
          </Link>
          <Link to="/privacy-policy" css={styles.footerLink}>
            Privacy Policy
          </Link>
          <Link to="/cookie-policy" css={styles.footerLink}>
            Cookie Policy
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AuthPagesLayout;
