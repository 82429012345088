import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ExcellenceChartFormProps,
  IndicatorWidgetData,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import EditIndicatorWidgetConfig from "./EditIndicatorWidgetConfig";
import useTheme from "@mui/material/styles/useTheme";
import {
  IndicatorDataSchema,
  IndicatorWidgetConfiguration,
} from "./indicatorWidgetTypes";
import { useLazyQuery } from "@apollo/client";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { AutocompleteOption, FormStatuses } from "../../../Global/Types/commonTypes";
import { graphQlQueryExcellenceIndicatorData } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import { getUnitsOfMeasure } from "../../../Api/Excellence/apiExcellenceGetQueries";
import { GetUnitsOfMeasureSnippet } from "../../../Api/Excellence/apiExcellenceSnippets";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import Alert from "../../MaterialUI/Alert";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";
import ExcellenceIndicator from "../../PageComponents/Excellence/ExcellenceCharts/ExcellenceIndicator/ExcellenceIndicator";
import EditIndicatorWidgetDataSchema from "./EditIndicatorWidgetDataSchema";
import indicatorWidgetDefaultData from "../ExcellenceDefaultConfig/indicatorWidgetDefaultData";
import { useLanguageContext } from "../../../context/LanguageContext";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";

interface EditIndicatorWidgetFormProps
  extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: IndicatorWidgetConfiguration;
  data: IndicatorWidgetData;
  isColumnChart?: boolean;
  dataSchema: IndicatorDataSchema | null;
}

const EditIndicatorWidgetForm: React.FC<EditIndicatorWidgetFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      IndicatorWidgetConfiguration,
      IndicatorDataSchema | null
    >({
      initialConfig: {
        ...config,
      },
      initialSchema: dataSchema as IndicatorDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<IndicatorWidgetData>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);
  const [unitsOfMeasure, setUnitsOfMeasure] = useState<GetUnitsOfMeasureSnippet | null>(
    null
  );
  // data tab
  const [selectedConnections, setSelectedConnections] = useState<AutocompleteOption[]>(
    []
  );

  const [getIndicatorData] = useLazyQuery(graphQlQueryExcellenceIndicatorData);
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        const units = await callApi<GetUnitsOfMeasureSnippet>({
          query: getUnitsOfMeasure,
          auth: { setAuthedUser },
        });
        setUnitsOfMeasure(units);
      } catch (err) {
        console.error(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (updatedSchema) {
        try {
          setFetchStatus("loading");
          const result = await getIndicatorData({
            variables: {
              input: updatedSchema,
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.indicatorData.data;

          setUpdatedData(resultData);
          setResetChartFetch((prev) => !prev);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchema]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      indicatorWidget: {
        config: { ...otherConfig, title },
        data: updatedData,
        dataSchema: updatedSchema,
      },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditIndicatorWidgetConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
              unitsOfMeasure={unitsOfMeasure}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditIndicatorWidgetDataSchema
              parameters={parameters?.parameters || []}
              dataSchema={updatedSchema}
              handleUpdateDataSchema={(val) => setUpdatedSchema(val)}
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
              selectedConnections={selectedConnections}
              setSelectedConnections={setSelectedConnections}
            />
          ) : null}

          <Alert
            css={[styles.reverseContentBreak, styles.contentBreak]}
            message={t("Something went wrong")}
            showAlert={fetchStatus === "error"}
            severity={"error"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <ExcellenceIndicator
            css={chartClass}
            configuration={updatedConfig}
            data={!updatedSchema ? indicatorWidgetDefaultData : updatedData}
            schema={updatedSchema}
            resetChartFetch={resetChartFetch}
            dataFetchFromParent
            parameters={parameters?.parameters}
          />
        </Grid>
      </Grid>

      <LoadingBackdrop loading={fetchStatus === "loading"} />
    </ExcellenceChartFormWrapper>
  );
};

export default EditIndicatorWidgetForm;
