import { Query } from "../callApi";

export const getQueryRules: Query = {
  endpoint: `data_manager/rules`,
  method: "GET",
};

export const getQueryRule = (ruleId: string): Query => ({
  endpoint: `data_manager/rule/${ruleId}`,
  method: "GET",
});

export const getQueryAlerts: Query = {
  endpoint: `data_manager/alerts`,
  method: "GET",
};
