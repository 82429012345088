import { useState } from "react";
import { object } from "yup";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { Formik } from "formik";
import { Stack } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import {
  YUP_NUMBER_REQUIRED_NUMBER,
  YUP_REQUIRED_STRING,
} from "../../../Global/Constants/yupConstants";
import callApi from "../../../Api/callApi";
import { postQueryUpdateObject } from "../../../Api/TrackAndTrace/apiTTPostQueries";
import {
  GetQueryTTObjectsSnippet,
  PostQueryUpdateTTObjectSnippet,
} from "../../../Api/TrackAndTrace/apiTTSnippets";
import { PostQueryUpdateTTObjectInput } from "../../../Api/TrackAndTrace/apiTTInputs";
import { TTObject } from "../../../Api/TrackAndTrace/apiTTDataTypes";
import { useAuthedContext } from "../../../context/AuthContext";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  vendor: YUP_REQUIRED_STRING,
  quantityInStock: YUP_NUMBER_REQUIRED_NUMBER,
  unitPrice: YUP_NUMBER_REQUIRED_NUMBER,
});

type ObjectFormValues = {
  vendor: string;
  quantityInStock: number;
  unitPrice: number;
};

interface WarehousesObjectFormProps {
  objectData: TTObject;
  setObjectsData: React.Dispatch<
    React.SetStateAction<GetQueryTTObjectsSnippet | undefined>
  >;
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const WarehousesObjectForm: React.FC<WarehousesObjectFormProps> = ({
  objectData,
  setObjectsData,
  setOpenInfo,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const initialValues: ObjectFormValues = {
    vendor: objectData.vendor || "",
    quantityInStock: objectData.quantity_in_stock || 0,
    unitPrice: objectData.unit_price || 0,
  };

  const handleFormSubmit = async (values: ObjectFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage("Loading...");

      const body: PostQueryUpdateTTObjectInput = {
        object_code: objectData.object_code,
        object_type: objectData.object_type,
        vendor: values.vendor,
        quantity_in_stock: values.quantityInStock,
        last_restock_date: objectData.last_restock_date,
        min_quantity: objectData.min_quantity,
        unit_price: values.unitPrice,
        total_value: objectData.total_value,
        purchase_date: objectData.purchase_date,
        location: objectData.location,
        is_deleted: false,
        is_location: false,
      };

      const updatedObject = await callApi<PostQueryUpdateTTObjectSnippet>({
        query: postQueryUpdateObject(objectData.id as string, body),
        auth: { setAuthedUser },
      });

      if (updatedObject) {
        setObjectsData((prevData) => {
          if (prevData) {
            const updatedData = prevData.map((object) => {
              if (object.id === objectData.id) {
                return {
                  id: object.id,
                  ...updatedObject,
                };
              }
              return object;
            });
            return updatedData as TTObject[];
          }
          return prevData;
        });
        setFormStatus("success");
        setAlertMessage("Object updated successfully");
        setUnsavedChanges(false);
        setOpenInfo(false);
      }
    } catch (err) {
      console.log("WarehouseObjectForm, handleFormSubmit() err: ", err.message);
      setFormStatus("error");
      setAlertMessage(err.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack gap={3}>
              <TextField
                name="vendor"
                label="Vendor"
                value={values.vendor}
                onChange={handleChange}
                error={touched.vendor && !!errors.vendor}
                helperText={touched.vendor && errors.vendor}
              />

              <TextField
                name="quantityInStock"
                label="Quantity In Stock"
                value={values.quantityInStock}
                onChange={handleChange}
                error={touched.quantityInStock && !!errors.quantityInStock}
                helperText={touched.quantityInStock && errors.quantityInStock}
              />

              <TextField
                name="unitPrice"
                label="Unit Price"
                value={values.unitPrice}
                onChange={handleChange}
                error={touched.unitPrice && !!errors.unitPrice}
                helperText={touched.unitPrice && errors.unitPrice}
              />

              <Button type="submit" loading={formStatus === "loading"} fullWidth>
                Update
              </Button>

              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default WarehousesObjectForm;
