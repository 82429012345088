import { TimeRangeData } from "../TimeRange/timeRangeTypes";

const timeRangeDefaultData: TimeRangeData = [
  {
    value: 202,
    day: "2018-06-08",
  },
  {
    value: 307,
    day: "2018-07-08",
  },
  {
    value: 244,
    day: "2018-07-05",
  },
  {
    value: 28,
    day: "2018-07-23",
  },
  {
    value: 76,
    day: "2018-06-02",
  },
  {
    value: 359,
    day: "2018-04-02",
  },
  {
    value: 341,
    day: "2018-04-23",
  },
  {
    value: 344,
    day: "2018-04-15",
  },
  {
    value: 266,
    day: "2018-05-03",
  },
  {
    value: 101,
    day: "2018-08-07",
  },
  {
    value: 350,
    day: "2018-04-11",
  },
  {
    value: 79,
    day: "2018-06-26",
  },
  {
    value: 3,
    day: "2018-04-12",
  },
  {
    value: 46,
    day: "2018-06-16",
  },
  {
    value: 147,
    day: "2018-04-30",
  },
  {
    value: 264,
    day: "2018-05-15",
  },
  {
    value: 242,
    day: "2018-07-18",
  },
  {
    value: 248,
    day: "2018-06-25",
  },
  {
    value: 200,
    day: "2018-06-19",
  },
  {
    value: 258,
    day: "2018-06-14",
  },
  {
    value: 187,
    day: "2018-07-01",
  },
  {
    value: 368,
    day: "2018-07-21",
  },
  {
    value: 197,
    day: "2018-05-08",
  },
  {
    value: 250,
    day: "2018-05-29",
  },
  {
    value: 228,
    day: "2018-05-14",
  },
  {
    value: 79,
    day: "2018-04-29",
  },
  {
    value: 118,
    day: "2018-07-06",
  },
  {
    value: 70,
    day: "2018-07-11",
  },
  {
    value: 141,
    day: "2018-05-24",
  },
  {
    value: 322,
    day: "2018-07-13",
  },
  {
    value: 128,
    day: "2018-04-27",
  },
  {
    value: 335,
    day: "2018-07-10",
  },
  {
    value: 125,
    day: "2018-05-16",
  },
  {
    value: 208,
    day: "2018-04-16",
  },
  {
    value: 25,
    day: "2018-07-22",
  },
  {
    value: 238,
    day: "2018-05-04",
  },
  {
    value: 176,
    day: "2018-08-03",
  },
  {
    value: 260,
    day: "2018-06-05",
  },
  {
    value: 249,
    day: "2018-07-14",
  },
  {
    value: 266,
    day: "2018-06-11",
  },
  {
    value: 288,
    day: "2018-04-18",
  },
  {
    value: 323,
    day: "2018-07-12",
  },
  {
    value: 314,
    day: "2018-07-07",
  },
  {
    value: 269,
    day: "2018-04-25",
  },
  {
    value: 112,
    day: "2018-08-02",
  },
  {
    value: 250,
    day: "2018-08-04",
  },
  {
    value: 198,
    day: "2018-06-27",
  },
  {
    value: 65,
    day: "2018-06-20",
  },
  {
    value: 29,
    day: "2018-07-04",
  },
  {
    value: 191,
    day: "2018-07-03",
  },
  {
    value: 12,
    day: "2018-05-23",
  },
  {
    value: 370,
    day: "2018-05-30",
  },
  {
    value: 291,
    day: "2018-04-09",
  },
  {
    value: 51,
    day: "2018-04-26",
  },
  {
    value: 338,
    day: "2018-07-16",
  },
  {
    value: 15,
    day: "2018-06-13",
  },
  {
    value: 296,
    day: "2018-07-25",
  },
  {
    value: 334,
    day: "2018-08-08",
  },
  {
    value: 119,
    day: "2018-04-03",
  },
  {
    value: 293,
    day: "2018-06-28",
  },
  {
    value: 257,
    day: "2018-04-07",
  },
  {
    value: 117,
    day: "2018-04-05",
  },
  {
    value: 297,
    day: "2018-06-30",
  },
  {
    value: 47,
    day: "2018-07-30",
  },
  {
    value: 32,
    day: "2018-07-20",
  },
  {
    value: 351,
    day: "2018-07-28",
  },
  {
    value: 127,
    day: "2018-07-29",
  },
  {
    value: 107,
    day: "2018-08-06",
  },
  {
    value: 268,
    day: "2018-05-07",
  },
  {
    value: 251,
    day: "2018-05-28",
  },
  {
    value: 356,
    day: "2018-05-06",
  },
  {
    value: 46,
    day: "2018-08-10",
  },
  {
    value: 120,
    day: "2018-04-04",
  },
  {
    value: 59,
    day: "2018-07-17",
  },
  {
    value: 44,
    day: "2018-05-21",
  },
  {
    value: 10,
    day: "2018-04-17",
  },
  {
    value: 236,
    day: "2018-06-06",
  },
  {
    value: 31,
    day: "2018-06-04",
  },
  {
    value: 179,
    day: "2018-07-27",
  },
  {
    value: 19,
    day: "2018-06-12",
  },
  {
    value: 93,
    day: "2018-04-28",
  },
  {
    value: 230,
    day: "2018-06-17",
  },
  {
    value: 273,
    day: "2018-06-03",
  },
  {
    value: 31,
    day: "2018-05-19",
  },
  {
    value: 198,
    day: "2018-07-24",
  },
  {
    value: 132,
    day: "2018-04-08",
  },
  {
    value: 148,
    day: "2018-06-24",
  },
  {
    value: 232,
    day: "2018-04-20",
  },
  {
    value: 321,
    day: "2018-08-11",
  },
  {
    value: 8,
    day: "2018-04-19",
  },
];

export default timeRangeDefaultData;
