import {
  BarChartAseetByHours,
  BarChartForAssetsDataType,
  BarChartLossesByMainAndSubCategory,
  BarChartLossesWeeklyViewType,
  OEEAssetChartByType,
  OEERadialChartsDataTypeWithTarget,
  OEESingleAssetDataTypeWithPercentageType,
  ParetoChartType,
} from "../oEEUtils";

export const oEEBarChartDataForAssets: BarChartForAssetsDataType[] = [
  {
    asset: "Press Brake 1",
    date: "7/2/2024",
    Undeclared: 8700,
    Availability: 3600,
    Performance: 1140,
    Quality: 3600,
    "Outside Plant Control": 960,
  },
  {
    asset: "Laser Cutter 1",
    date: "7/2/2024",
    Undeclared: 7500,
    Availability: 3600,
    Performance: 1640,
    Quality: 3400,
    "Outside Plant Control": 660,
  },
  {
    asset: "Assembly Station 1",
    date: "7/2/2024",
    Undeclared: 6500,
    Availability: 2500,
    Performance: 2650,
    Quality: 2900,
    "Outside Plant Control": 1130,
  },
  {
    asset: "Labeling Machine 2",
    date: "7/2/2024",
    Undeclared: 4000,
    Availability: 3200,
    Performance: 1300,
    Quality: 2600,
    "Outside Plant Control": 1600,
  },
  {
    asset: "Packaging Line 2",
    date: "7/2/2024",
    Undeclared: 5000,
    Availability: 2090,
    Performance: 1800,
    Quality: 1200,
    "Outside Plant Control": 1400,
  },
];

export const barChartLossesByType: BarChartLossesByMainAndSubCategory[] = [
  {
    type: "Outside Plant Control",
    workingTime: 8280,
  },
  {
    type: "Quality",
    workingTime: 10260,
  },
  {
    type: "Performance",
    workingTime: 12900,
  },
  {
    type: "Availability",
    workingTime: 13500,
  },
  {
    type: "Undeclared",
    workingTime: 23340,
  },
];

export const barChartLossesByMainCategory: BarChartLossesByMainAndSubCategory[] = [
  // Performance
  {
    category: "Slow Cycles",
    workingTime: 4000,
  },
  // Availability
  {
    category: "Unscheduled Downtime",
    workingTime: 4100,
  },
  // Availability
  {
    category: "Scheduled Downtime",
    workingTime: 8400,
  },
  // Performance
  {
    category: "Small Stops",
    workingTime: 8900,
  },
  // Quality
  {
    category: "Defects",
    workingTime: 10260,
  },
  // Undeclared
  {
    category: "Undeclared",
    workingTime: 23340,
  },
];

export const barChartLossesBySubcategory: BarChartLossesByMainAndSubCategory[] = [
  // Slow Cycles
  {
    subcategory: "Equipment Wear and Tear",
    workingTime: 4000,
  },
  // Unscheduled Downtime
  {
    subcategory: "Equipment Failures",
    workingTime: 4100,
  },
  // Scheduled Downtime
  {
    subcategory: "Preventive Maintenance",
    workingTime: 8400,
  },
  // Small Stops
  {
    subcategory: "Software/Application Stops",
    workingTime: 8900,
  },
  // Defects
  {
    subcategory: "Material Defects",
    workingTime: 10260,
  },
  // Undeclared
  {
    subcategory: "Undeclared",
    workingTime: 23340,
  },
];

export const barChartLossesWeeklyView: BarChartLossesWeeklyViewType[] = [
  {
    date: "7/2/2024",
    Undeclared: 8700,
    Availability: 3600,
    Performance: 1140,
    Quality: 3600,
    "Outside Plant Control": 960,
  },
  {
    date: "7/3/2024",
    Undeclared: 3360,
    Availability: 2040,
    Performance: 1500,
    Quality: 1560,
    "Outside Plant Control": 1320,
  },
  {
    date: "7/4/2024",
    Undeclared: 3720,
    Availability: 1920,
    Performance: 1860,
    Quality: 1200,
    "Outside Plant Control": 660,
  },
  {
    date: "7/5/2024",
    Undeclared: 1860,
    Availability: 3180,
    Performance: 1000,
    Quality: 780,
    "Outside Plant Control": 1060,
  },
  {
    date: "7/6/2024",
    Undeclared: 1140,
    Availability: 660,
    Performance: 1860,
    Quality: 2280,
    "Outside Plant Control": 1200,
  },
  {
    date: "7/7/2024",
    Undeclared: 1060,
    Availability: 1320,
    Performance: 1260,
    Quality: 1500,
    "Outside Plant Control": 1260,
  },
  {
    date: "7/8/2024",
    Undeclared: 1200,
    Availability: 780,
    Performance: 720,
    Quality: 1200,
    "Outside Plant Control": 480,
  },
];

export const oEERadialChartsData: OEERadialChartsDataTypeWithTarget = {
  currentTarget: 79.7,
  chartData: [
    {
      id: "Quality",
      data: [
        {
          x: "Quality",
          y: 83.2,
        },
      ],
    },
    {
      id: "Performance",
      data: [
        {
          x: "Performance",
          y: 87.8,
        },
      ],
    },
    {
      id: "Availability",
      data: [
        {
          x: "Availability",
          y: 92.8,
        },
      ],
    },
  ],
};

export const oEEAssetChartByType: OEEAssetChartByType = {
  type: "Availability",
  chartData: [
    {
      date: "7/2/2024",
      workingTime: 5326,
    },
    {
      date: "7/3/2024",
      workingTime: 6453,
    },
    {
      date: "7/4/2024",
      workingTime: 6432,
    },
    {
      date: "7/5/2024",
      workingTime: 5121,
    },
    {
      date: "7/6/2024",
      workingTime: 5698,
    },
    {
      date: "7/7/2024",
      workingTime: 6539,
    },
    {
      date: "7/8/2024",
      workingTime: 6326,
    },
  ],
};

export const oEESingleAssetData: OEESingleAssetDataTypeWithPercentageType = {
  percentage: 79,
  chartData: [
    {
      id: "Press Brake 1",
      data: [
        {
          x: "1/7/2024",
          y: 15,
        },
        {
          x: "2/7/2024",
          y: 20,
        },
        {
          x: "3/7/2024",
          y: 17,
        },
        {
          x: "4/7/2024",
          y: 14,
        },
        {
          x: "5/7/2024",
          y: 10,
        },
        {
          x: "6/7/2024",
          y: 12,
        },
        {
          x: "7/7/2024",
          y: 16,
        },
        {
          x: "8/7/2024",
          y: 18,
        },
      ],
    },
  ],
};

export const paretoChartData: ParetoChartType = {
  asset: "Press Brake 1",
  chartData: [
    {
      subcategory: "Material Defects",
      workingTime: 10985,
    },
    {
      subcategory: "Preventive Maintenance",
      workingTime: 9565,
    },
    {
      subcategory: "Mechanical Error",
      workingTime: 8968,
    },
    {
      subcategory: "Electrical Error",
      workingTime: 6596,
    },
    {
      subcategory: "Software/Application Stops",
      workingTime: 5874,
    },
    {
      subcategory: "Inspection",
      workingTime: 4963,
    },
    {
      subcategory: "Slowdown due to Queuing",
      workingTime: 3612,
    },
    {
      subcategory: "Material Shortages",
      workingTime: 2947,
    },
  ],
};

export const barChartAseetByHoursData: BarChartAseetByHours = {
  asset: "Press Brake 1",
  chartData: [
    {
      hour: "08:00",
      values: [
        { category: "ProductChangeover", value: 19 },
        { category: "Working", value: 32 },
        { category: "Downtime", value: 5 },
        { category: "SpeedLoss", value: 4 },
      ],
    },
    {
      hour: "09:00",
      values: [
        { category: "Downtime", value: 15 },
        { category: "SpeedLoss", value: 5 },
        { category: "Working", value: 40 },
      ],
    },
    {
      hour: "10:00",
      values: [
        { category: "Working", value: 45 },
        { category: "SpeedLoss", value: 10 },
        { category: "Downtime", value: 5 },
      ],
    },
    {
      hour: "11:00",
      values: [
        { category: "Working", value: 50 },
        { category: "SpeedLoss", value: 10 },
      ],
    },
    {
      hour: "12:00",
      values: [
        { category: "Working", value: 30 },
        { category: "Break", value: 30 },
      ],
    },
    {
      hour: "13:00",
      values: [
        { category: "Working", value: 50 },
        { category: "SpeedLoss", value: 10 },
      ],
    },
    {
      hour: "14:00",
      values: [{ category: "Working", value: 60 }],
    },
    {
      hour: "15:00",
      values: [{ category: "Working", value: 60 }],
    },
    {
      hour: "16:00",
      values: [
        { category: "Working", value: 40 },
        { category: "SpeedLoss", value: 10 },
        { category: "Working", value: 10 },
      ],
    },
  ],
};
