import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { calculateTranslationInPercentage } from "./gaugeWidgetUtils";
import { Box, Theme, useTheme } from "@mui/material";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";

const cssStyles = (theme: Theme) => ({
  gaugeArrowContainer: css({
    position: "absolute",
    top: "50%",
    left: "50%",
  }),
  gaugeArrow: css({
    fontSize: "100px",
    color: theme.palette.grey[800],
  }),
});

interface GaugeArrowProps {
  currentValueInPercentage: number;
  arrowFontSize: number;
}

const GaugeArrow: React.FC<GaugeArrowProps> = ({
  currentValueInPercentage,
  arrowFontSize,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
  };
  const [arrowRotation, setArrowRotation] = useState<number>(0);

  // Calculate the arrow rotation angle based on the gauge currentValue
  useEffect(() => {
    const maxRotation = 180; // Maximum rotation angle (180 degrees)
    const rotation =
      (currentValueInPercentage / 100) * maxRotation - maxRotation / 2 - 90;
    setArrowRotation(rotation);
  }, [currentValueInPercentage]);

  const arrowTranslation = calculateTranslationInPercentage(currentValueInPercentage);

  return (
    <Box component="div" sx={arrowTranslation} css={styles.gaugeArrowContainer}>
      <TrendingFlatIcon
        css={styles.gaugeArrow}
        style={{
          transform: `rotate(${arrowRotation}deg) `,
          fontSize: `${arrowFontSize}px`,
        }}
      />
    </Box>
  );
};

export default GaugeArrow;
