import { Theme } from "@mui/material";
import { TimeChartConfiguration } from "../../../ExcellenceWidgets/TimeChart/timeChartTypes";

export type PredictiveMeintenanceStatisticsType = {
  averageDaysLeft: number;
  worstDaysLeft: number;
  bestDaysLeft: number;
  modelPredictionQuality: string;
};

export const createPredictiveMaintenanceConfiguration = (
  theme: Theme
): TimeChartConfiguration => ({
  unitsOfMeasure: "",
  scale: "",
  interpolate: "linear",
  colors: "paired",
  enablePoints: false,
  enableGridX: false,
  enableGridY: true,
  axisLeftLegend: "Plugged Tubes",
  axisBottomLegend: "Date",
  gridLineColor:
    theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[900],
});

export const predictiveMaintenanceChartMargin = {
  top: 20,
  right: 100,
  bottom: 80,
  left: 60,
};
