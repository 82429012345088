import { SelectOption } from "../../../Global/Types/commonTypes";
import { Box, Grid, Stack } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Select from "../../MaterialUI/FormFields/Select";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import { ChartConfigFormValuesWithTitle } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { TimeChartConfiguration } from "./timeChartTypes";
import { useLanguageContext } from "../../../context/LanguageContext";

const interpolateOptions: SelectOption[] = [
  { value: "linear", description: "Linear" },
  { value: "basis", description: "Basis" },
  { value: "cardinal", description: "Cardinal" },
];

type KeyOfConfigValue = keyof ChartConfigFormValuesWithTitle<TimeChartConfiguration>;

interface EditTimeChartConfigProps {
  config: ChartConfigFormValuesWithTitle<TimeChartConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<TimeChartConfiguration>>
  >;
}

const EditTimeChartConfig: React.FC<EditTimeChartConfigProps> = ({
  config,
  setUpdatedConfig,
}) => {
  const { t } = useLanguageContext();
  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string | boolean) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="axisBottomLegend"
            label={t("X axis label")}
            onChange={(e) => handleUpdateFormValue("axisBottomLegend", e.target.value)}
            value={config.axisBottomLegend}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="axisLeftLegend"
            label={t("Y axis label")}
            onChange={(e) => handleUpdateFormValue("axisLeftLegend", e.target.value)}
            value={config.axisLeftLegend}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            name="interpolate"
            selectOptions={interpolateOptions}
            defaultValue={config.interpolate}
            label={t("Interpolate")}
            onChange={(e) => handleUpdateFormValue("interpolate", e.target.value)}
            value={config.interpolate}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Y-axis Min & Max")}
            name="yAxisMinAndMax"
            defaultChecked={config.yAxisMinAndMax}
            onChange={(e) => handleUpdateFormValue("yAxisMinAndMax", e.target.checked)}
            value={config.yAxisMinAndMax}
          />
        </Grid>

        {config.yAxisMinAndMax ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                name="yAxisMin"
                label={t("Y-axis Min")}
                onChange={(e) => handleUpdateFormValue("yAxisMin", e.target.value)}
                value={config.yAxisMin ?? ""}
                numberField
                allowNegatives
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="yAxisMax"
                label={t("Y-axis Max")}
                onChange={(e) => handleUpdateFormValue("yAxisMax", e.target.value)}
                value={config.yAxisMax ?? ""}
                numberField
                allowNegatives
              />
            </Grid>
          </>
        ) : null}

        <Grid item xs={12}>
          <Stack spacing={2} direction="row" flexWrap="wrap">
            <Checkbox
              label={t("Show points")}
              name="enablePoints"
              defaultChecked={config.enablePoints}
              onChange={(e) => handleUpdateFormValue("enablePoints", e.target.checked)}
              value={config.enablePoints}
            />
            <Checkbox
              label={t("Show grid X")}
              name="enableGridX"
              defaultChecked={config.enableGridX}
              onChange={(e) => handleUpdateFormValue("enableGridX", e.target.checked)}
              value={config.enableGridX}
            />
            <Checkbox
              label={t("Show grid Y")}
              name="enableGridY"
              defaultChecked={config.enableGridY}
              onChange={(e) => handleUpdateFormValue("enableGridY", e.target.checked)}
              value={config.enableGridY}
            />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditTimeChartConfig;
