import { DaAttribute } from "../../../../../Api/DataSources/apiDSDataTypes";
import {
  PostQueryDaNodesSnippet,
  GetQueryDAAttributesSnippet,
  GetQueryDACanonicalDataTypesSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import { TableGridColumnSchema } from "../../../../SmallComponents/TableGrid/constructTableGrid";

export type FormattedDaGroupProperties = {
  mandatory: DaAttribute[];
  recommended: DaAttribute[];
  optional: DaAttribute[];
  optional2: DaAttribute[];
  optional3: DaAttribute[];
};

export type DaFormattedCanonical = {
  [key: string]: string;
};

const getGroupPropColumnPlace = (propID: DaAttribute["id"]) => {
  switch (propID) {
    case 1: {
      return "mandatory";
    }
    case 2: {
      return "mandatory";
    }
    case 3: {
      return "mandatory";
    }
    case 4: {
      return "mandatory";
    }
    case 5: {
      return "recommended";
    }
    case 6: {
      return "recommended";
    }
    case 100: {
      return "recommended";
    }
    case 101: {
      return "recommended";
    }
    case 102: {
      return "optional2";
    }
    case 103: {
      return "optional2";
    }
    case 104: {
      return "optional2";
    }
    case 105: {
      return "optional2";
    }
    case 106: {
      return "optional3";
    }
    case 107: {
      return "optional3";
    }
    case 108: {
      return "optional3";
    }
    case 300: {
      return "optional3";
    }
    case 301: {
      return "optional3";
    }
    case 307: {
      return "optional";
    }
    case 308: {
      return "optional";
    }
    case 309: {
      return "optional";
    }
    case 310: {
      return "optional";
    }

    default: {
      return null;
    }
  }
};

export const prepareDaGroupProperties = (
  groupProperties: GetQueryDAAttributesSnippet
): FormattedDaGroupProperties => {
  const mandatory = [];
  const recommended = [];
  const optional = [];
  const optional2 = [];
  const optional3 = [];

  for (const prop of groupProperties) {
    const columnPlace = getGroupPropColumnPlace(prop.opcda_attribute_id);

    if (columnPlace === "mandatory") {
      mandatory.push(prop);
    } else if (columnPlace === "recommended") {
      recommended.push(prop);
    } else if (columnPlace === "optional") {
      optional.push(prop);
    } else if (columnPlace === "optional2") {
      optional2.push(prop);
    } else {
      optional3.push(prop);
    }
  }

  return {
    mandatory,
    recommended,
    optional,
    optional2,
    optional3,
  };
};

export const prepareDaSubColumns = (
  selectedGroupProperties: GetQueryDAAttributesSnippet
): TableGridColumnSchema[] => {
  const result: TableGridColumnSchema[] = [
    {
      id: "name",
      label: "Name",
      type: "string",
    },
    {
      id: "group",
      label: "Group",
      type: "string",
    },
  ];

  for (const prop of selectedGroupProperties) {
    const type = prop.datatype;
    const propID = prop.opcda_attribute_id;
    const isCanonicalValue = propID === 1;

    result.push({
      id: `field-${propID}`,
      label: prop.name,
      type: isCanonicalValue ? "string" : type,
    });
  }

  return result;
};

export const prepareDaSubRows = (
  itemsAndProps: PostQueryDaNodesSnippet,
  formattedCanonical: DaFormattedCanonical
): Record<string, any>[] => {
  const result = itemsAndProps.map((itemWithProps) => {
    const otherProps = itemWithProps.attributes.reduce((acc, curr) => {
      const field = curr.id;
      const value = curr.value;
      const isCanonicalValue = field === 1;

      return {
        ...acc,
        [`field-${field}`]: isCanonicalValue ? formattedCanonical[value] : value,
      };
    }, {});

    const [group, name] = itemWithProps.nodeName.split(".");
    return {
      group,
      name,
      ...otherProps,
    };
  });

  return result;
};

export const formatDaCanonicalData = (
  data: GetQueryDACanonicalDataTypesSnippet
): DaFormattedCanonical => {
  const result: DaFormattedCanonical = data.reduce((acc: DaFormattedCanonical, curr) => {
    const key = curr.canonical_data_type_code;
    const value = curr.canonical_data_type_value;
    return {
      ...acc,
      [key]: value,
    };
  }, {});
  return result;
};
