import React from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { MaintenanceResponsible } from "../../../Api/Maintenance/apiMaintenanceDataTypes";

interface ResponsibleModalLayoutProps {
  responsible: MaintenanceResponsible[];
}

const ResponsibleModalLayout: React.FC<ResponsibleModalLayoutProps> = ({
  responsible,
}) => {
  return (
    <Box component="div">
      {responsible.map((level) => (
        <Box component="div" key={level.title}>
          <Typography component="h3" variant="h3" fontWeight="normal">
            {level.title}
          </Typography>
          <List>
            {level.persons.map((person) => (
              <ListItem key={person.name}>
                <ListItemAvatar>
                  <Avatar>
                    <PersonIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={person.name}
                  secondary={
                    <Stack
                      direction="row"
                      justifyContent="ceneter"
                      alignItems="center"
                      flexWrap="wrap"
                      gap={1}
                      mt={1}
                    >
                      <Typography variant="h5" component="h5" fontWeight="normal">
                        {person.contact_info.phone}
                      </Typography>
                      <Typography variant="h5" component="h5" fontWeight="normal">
                        {person.contact_info.email}
                      </Typography>
                      <Typography variant="h5" component="h5" fontWeight="normal">
                        {person.contact_info.address}
                      </Typography>
                      <Typography variant="h5" component="h5" fontWeight="normal">
                        {person.contact_info.department}
                      </Typography>
                      <Typography variant="h5" component="h5" fontWeight="normal">
                        {person.contact_info.job_description}
                      </Typography>
                    </Stack>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Box>
  );
};

export default ResponsibleModalLayout;
