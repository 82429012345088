import { Grid, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import ContentBox from "../../../MaterialUI/ContentBox";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import {
  ConditionMonitoring,
  MachineState,
  getMessage,
} from "./conditionMonitoringUtils";
import ConditionMonitoringIcon from "./ConditionMonitoringIcon";
import ConditionMonitoringMachineInfo from "./ConditionMonitoringMachineInfo";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useState } from "react";
import cssComponentsStyles from "../../../../Global/Styles/components";

interface ConditionMonitoringProductionPlantProps {
  productionPlantData: ConditionMonitoring;
}

const ConditionMonitoringProductionPlant: React.FC<
  ConditionMonitoringProductionPlantProps
> = ({ productionPlantData }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const [showMachines, setShowMachines] = useState<boolean>(true);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchor);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const toggleShowMachines = () => {
    setShowMachines((prevShowMachines) => !prevShowMachines);
  };

  const machineStates = productionPlantData.machines.reduce(
    (acc, machine) => {
      acc[machine.state]++;
      return acc;
    },
    { normal: 0, warning: 0, critical: 0 } as Record<MachineState, number>
  );

  const message = getMessage(machineStates);

  return (
    <ContentBox css={styles.width100}>
      <Stack spacing={2}>
        <Grid container display="flex" alignItems="center" justifyContent="space-between">
          <ConditionMonitoringIcon
            state={
              machineStates.critical > 0
                ? "critical"
                : machineStates.warning > 0
                ? "warning"
                : "normal"
            }
            isMainIcon
          />
          <IconButton
            aria-label="widget setting menu"
            onClick={handleOpenMenu}
            size="small"
          >
            <TuneOutlinedIcon css={styles.greyIcon} fontSize="medium" />
          </IconButton>
        </Grid>
        <Stack spacing={1.5} onClick={toggleShowMachines}>
          <Typography variant="h2">
            Production Plant {productionPlantData.area}
          </Typography>
          <Typography variant="h6" style={theme.customizedTextStyles.labelStyles}>
            {message}
          </Typography>
        </Stack>

        {showMachines && (
          <Stack spacing={1}>
            {productionPlantData.machines.map((machine) => (
              <ConditionMonitoringMachineInfo key={machine.id} machine={machine} />
            ))}
          </Stack>
        )}
      </Stack>

      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
          <Stack alignItems="center">
            <IconButton aria-label="edit production plant" disabled>
              <EditOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Edit
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton disabled aria-label="delete production plant">
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Delete
            </Typography>
          </Stack>
        </Stack>
      </Menu>
    </ContentBox>
  );
};

export default ConditionMonitoringProductionPlant;
