import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { ShiftPlan, ShiftPlanPerson } from "./shiftPlanningUtils";
import { ResponsiveTimeRange, TimeRangeDayData } from "@nivo/calendar";
import { getChartColors } from "../../ExcellenceWidgets/nivoTheme";
import ContentBox from "../../MaterialUI/ContentBox";
import { useEffect, useRef, useState } from "react";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import {
  startOfMonth,
  endOfMonth,
  startOfWeek,
  addDays,
  format,
  addWeeks,
} from "date-fns";
import ShiftPlanningTable from "./ShiftPlanningTable";
import Select from "../../MaterialUI/FormFields/Select";
import ShiftCalendarPeriod from "./ShiftCalendarPeriod";
import { SelectOption } from "../../../Global/Types/commonTypes";
import Button from "../../MaterialUI/Button";
import Modal from "../../MaterialUI/Modal";
import LabelWithBoldedPart from "../../MaterialUI/LabelWithBoldedPart";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";

type CalendarData = {
  day: string;
  value: number;
  peopleIDs: string[];
}[];

type CalendarStartEnd = {
  from: string;
  to: string;
};

type PeriodOption = "month" | "quarter" | "year";

interface ShiftPlanningCalendarProps {
  plans: ShiftPlan[];
}

const ShiftPlanningCalendar: React.FC<ShiftPlanningCalendarProps> = ({ plans }) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const calendarRef = useRef<HTMLDivElement>(null);
  const { width: calendarWidth } = useContainerDimensions(calendarRef);

  const [calendarFromTo, setCalendarFromTo] = useState<CalendarStartEnd>({
    from: startOfMonth(new Date()).toISOString(),
    to: endOfMonth(new Date()).toISOString(),
  });
  const [period, setPeriod] = useState<PeriodOption>("month");
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  const [displayData, setDisplayData] = useState<CalendarData>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [tablePeople, setTablePeople] = useState<ShiftPlanPerson[]>([]);

  const calendarHeight: number = calendarWidth
    ? period === "month"
      ? calendarWidth / 1.1
      : period === "year"
      ? calendarWidth / 4.5
      : calendarWidth / 1.5
    : 400;

  const leftRightMargin = period === "year" ? 40 : 10;

  // @ts-ignore
  const selectOptions: SelectOption[] = plans.map((item, index) => ({
    value: index,
    description: item.name,
  }));

  useEffect(() => {
    setDisplayData(transformToCalendarData(plans?.[selectedPlan]));
    setTablePeople([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plans]);

  const handleOnPlanChange = (value: number) => {
    setSelectedPlan(value);
    setDisplayData(transformToCalendarData(plans[value]));
    setTablePeople([]);
  };

  const handleOnClick = (datum: TimeRangeDayData) => {
    // @ts-ignore
    if (datum?.value) {
      // @ts-ignore
      const peopleIDs: string[] = datum.peopleIDs;
      const tablePeople: ShiftPlanPerson[] = [];

      // 1. get all tasks for the clicked date
      peopleIDs.forEach((name) => {
        plans?.[selectedPlan]?.people.forEach((person) => {
          if (person.name === name) {
            tablePeople.push(person);
          }
        });
      });

      setTablePeople(tablePeople);
    }
  };

  return (
    <Box component="div" css={styles.width100}>
      <ContentBox css={styles.width100}>
        <Stack
          css={styles.sectionBreak}
          direction="row"
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
        >
          <Select
            css={styles.widthLimit20}
            selectOptions={selectOptions}
            label="Selected Plan"
            value={selectedPlan}
            // @ts-ignore
            onChange={(e) => handleOnPlanChange(e.target.value)}
          />

          <Box component="div">
            <Button variant="outlined" onClick={() => setOpenModal(true)}>
              View Shift Plan Information
            </Button>
          </Box>
        </Stack>

        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            lg={period === "year" ? 12 : 6}
            xl={period === "year" ? 12 : 4}
          >
            <ShiftCalendarPeriod
              css={styles.contentBreak}
              calendarFromTo={calendarFromTo}
              setCalendarFromTo={setCalendarFromTo}
              setPeriod={setPeriod}
              period={period}
            />

            <Box
              component="div"
              css={[
                styles.sectionBreak,
                period !== "year" && styles.widthLimit30,
                styles.marginHorizontalAuto,
              ]}
              ref={calendarRef}
              style={{ height: calendarHeight, minHeight: "250px" }}
            >
              <ResponsiveTimeRange
                data={displayData}
                from={calendarFromTo.from}
                to={calendarFromTo.to}
                emptyColor={theme.palette.background.paper}
                colors={getChartColors()}
                theme={{
                  text: {
                    fill: theme.palette.common.black,
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.common.white,
                    },
                  },
                }}
                onClick={handleOnClick}
                dayBorderColor={theme.palette.grey[600]}
                margin={{
                  top: 30,
                  right: leftRightMargin,
                  bottom: 100,
                  left: leftRightMargin,
                }}
                dayBorderWidth={2}
                firstWeekday="monday"
                weekdayTicks={[0, 1, 2, 3, 4, 5, 6]}
                dayRadius={4}
                minValue={1}
                legendFormat={(value) => `${value} Shifts`}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "row",
                    justify: false,
                    itemCount: 2,
                    itemWidth: 33,
                    itemHeight: 36,
                    itemsSpacing: 60,
                    itemDirection: "left-to-right",
                    translateX: -200,
                    translateY: -60,
                    symbolSize: 20,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={period === "year" ? 12 : 6}
            xl={period === "year" ? 12 : 8}
          >
            {!tablePeople.length ? (
              <Stack
                css={styles.height100}
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h4" textAlign="center" color="textSecondary">
                  Click on a calendar cell which has at least one shift to view all people
                  for this particular day in a table format.
                </Typography>
                <Typography variant="h4" textAlign="center" color="textSecondary">
                  Clicking on an empty cell won't have any effect.
                </Typography>
                <Typography variant="h4" textAlign="center" color="textSecondary">
                  If you are unable to find any days with shifts, try changing the period
                  to quarter or year.
                </Typography>
              </Stack>
            ) : (
              <ShiftPlanningTable shiftPlanPeople={tablePeople} hideColumns={[]} />
            )}
          </Grid>
        </Grid>
      </ContentBox>

      <Modal
        onClose={() => setOpenModal(false)}
        open={openModal}
        label="Shift Plan Information"
        fullWidth
      >
        <Stack spacing={2}>
          <LabelWithBoldedPart
            text="Shift Plan Name"
            bolded={plans[selectedPlan]?.name || ""}
          />
          <LabelWithBoldedPart
            text="Start Date"
            bolded={
              plans[selectedPlan]?.startDate
                ? formatDateAndTime(plans[selectedPlan].startDate, "date")
                : ""
            }
          />
          <LabelWithBoldedPart
            text="Duration in Weeks"
            bolded={plans[selectedPlan]?.weeksDuration || ""}
          />
        </Stack>
      </Modal>
    </Box>
  );
};

export default ShiftPlanningCalendar;

const transformToCalendarData = (plan: ShiftPlan): CalendarData => {
  if (!plan?.people?.length) {
    return [];
  }

  const datesToAdd: Record<
    string,
    {
      value: number;
      people: string[];
    }
  > = {};
  const weeks = Array.from(Array(plan.weeksDuration).keys());

  plan.people.forEach((person) => {
    weeks.forEach((week) => {
      const planStart = addWeeks(new Date(plan.startDate), week);
      const start = startOfWeek(planStart, { weekStartsOn: 1 });

      person.working_days.forEach((day) => {
        const dateToUse = addDays(start, day);

        if (datesToAdd[dateToUse.toISOString()]) {
          datesToAdd[dateToUse.toISOString()] = {
            value: datesToAdd[dateToUse.toISOString()].value + 1,
            people: [...datesToAdd[dateToUse.toISOString()].people, person.name],
          };
        } else {
          datesToAdd[dateToUse.toISOString()] = {
            value: 1,
            people: [person.name],
          };
        }
      });
    });
  });

  const data: CalendarData = Object.entries(datesToAdd).map(([key, value]) => ({
    day: format(new Date(key), "yyyy-MM-dd"),
    value: value.value,
    peopleIDs: value.people,
  }));
  return data;
};
