import { Query } from "../callApi";
import {
  DeleteQueryExcellenceDashboard,
  DeleteQueryExcellenceDashboardFileInput,
  PostQueryExcellenceDashboardInput,
  PostQueryExcellenceUploadFileInput,
  PostQueryExcellenceWidgetsInput,
  PutQueryExcellenceDashboardInput,
} from "./apiExcellenceInputs";

export const postQueryExcellenceDashboard = (
  input: PostQueryExcellenceDashboardInput
): Query => ({
  endpoint: "excellence/dashboard",
  method: "POST",
  variables: input,
});

export const postQueryExcellenceWidgets = (
  input: PostQueryExcellenceWidgetsInput
): Query => ({
  endpoint: "excellence/widgets",
  method: "POST",
  variables: input,
});

export const putQueryExcellenceDashboard = (
  input: PutQueryExcellenceDashboardInput
): Query => ({
  endpoint: "excellence/dashboard",
  method: "PUT",
  variables: input,
});
export const deleteQueryExcellenceDashboard = (
  id: DeleteQueryExcellenceDashboard
): Query => ({
  endpoint: `excellence/dashboard/${id}`,
  method: "DELETE",
});

export const postQueryExcellenceUploadFile = (
  dashboardID: string,
  input: PostQueryExcellenceUploadFileInput
): Query => ({
  endpoint: `excellence/dashboard/${dashboardID}/file`,
  method: "POST",
  variables: input,
  multipartForm: true,
  multipartCustomKey: "files",
});

export const deleteQueryExcellenceDashboardFile = (
  id: DeleteQueryExcellenceDashboardFileInput,
  filename: string
): Query => ({
  endpoint: `excellence/dashboard/${id}/file/${filename}`,
  method: "DELETE",
});
