import { SerializedStyles } from "@emotion/react";
import TimeRange from "../../../../ExcellenceWidgets/AdvancedWidgets/TimeRange/TimeRange";
import { TimeRangeData } from "../../../../ExcellenceWidgets/AdvancedWidgets/TimeRange/timeRangeTypes";
import timeRangeDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/timeRangeDefaultData";

interface ExcellenceTimeRangeProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: TimeRangeData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceTimeRange: React.FC<ExcellenceTimeRangeProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <TimeRange
      className={className}
      configuration={configuration}
      data={data || timeRangeDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceTimeRange;
