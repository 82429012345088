import { addDays } from "date-fns";
import { AnomalyDetectionTableRow } from "./anomalyDetectionUtils";

export const AnomalyDetectionTableRowsData: AnomalyDetectionTableRow[] = [
  {
    id: "HC110.MV_pval",
    tagNumber: "HC110.MV_pval",
    description: "Total Plant",
    system: "Recipe",
    unit: "t/d",
    startDate: addDays(new Date(), -20),
    endDate: new Date(),
    asIsAverage: 1015,
    asIsDeviation: 1.1,
    asIsMin: 990,
    asIsMax: 1040,
    toBeAverage: 1020,
    toBeDeviation: 3,
    toBeMin: 970,
    toBeMax: 1070,
  },
  {
    id: "FY118.CPV_pval",
    tagNumber: "FY118.CPV_pval",
    description: "Total Plant",
    system: "Recipe",
    unit: "kg/h",
    startDate: addDays(new Date(), -15),
    endDate: new Date(),
    asIsAverage: 41769,
    asIsDeviation: 1.6,
    asIsMin: 39754,
    asIsMax: 44323,
    toBeAverage: 1000,
    toBeDeviation: 3,
    toBeMin: 970,
    toBeMax: 1030,
  },
  {
    id: "FIC118.PV_pval",
    tagNumber: "FIC118.PV_pval",
    description: "EGR Flow",
    system: "Paste Tank (118-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -10),
    endDate: new Date(),
    asIsAverage: 17804,
    asIsDeviation: 1.9,
    asIsMin: 18444,
    asIsMax: 17255,
    toBeAverage: 17817,
    toBeDeviation: 3,
    toBeMin: 17280,
    toBeMax: 18355,
  },
  {
    id: "HC210.MV_pval",
    tagNumber: "HC210.MV_pval",
    description: "Intermediate Plant",
    system: "Process",
    unit: "t/d",
    startDate: addDays(new Date(), -18),
    endDate: new Date(),
    asIsAverage: 820,
    asIsDeviation: 2.1,
    asIsMin: 790,
    asIsMax: 850,
    toBeAverage: 825,
    toBeDeviation: 2.5,
    toBeMin: 800,
    toBeMax: 860,
  },
  {
    id: "FY218.CPV_pval",
    tagNumber: "FY218.CPV_pval",
    description: "Intermediate Plant",
    system: "Process",
    unit: "kg/h",
    startDate: addDays(new Date(), -12),
    endDate: new Date(),
    asIsAverage: 32000,
    asIsDeviation: 2.2,
    asIsMin: 31000,
    asIsMax: 33000,
    toBeAverage: 32250,
    toBeDeviation: 2.6,
    toBeMin: 31500,
    toBeMax: 33500,
  },
  {
    id: "FIC218.PV_pval",
    tagNumber: "FIC218.PV_pval",
    description: "Coolant Flow",
    system: "Heat Exchanger (218-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -9),
    endDate: new Date(),
    asIsAverage: 14350,
    asIsDeviation: 1.8,
    asIsMin: 13500,
    asIsMax: 15000,
    toBeAverage: 14400,
    toBeDeviation: 2.0,
    toBeMin: 14000,
    toBeMax: 14800,
  },
  {
    id: "HC310.MV_pval",
    tagNumber: "HC310.MV_pval",
    description: "Final Plant",
    system: "Refinement",
    unit: "t/d",
    startDate: addDays(new Date(), -17),
    endDate: new Date(),
    asIsAverage: 950,
    asIsDeviation: 1.5,
    asIsMin: 925,
    asIsMax: 975,
    toBeAverage: 960,
    toBeDeviation: 1.8,
    toBeMin: 940,
    toBeMax: 980,
  },
  {
    id: "FY318.CPV_pval",
    tagNumber: "FY318.CPV_pval",
    description: "Final Plant",
    system: "Refinement",
    unit: "kg/h",
    startDate: addDays(new Date(), -14),
    endDate: new Date(),
    asIsAverage: 38900,
    asIsDeviation: 2.0,
    asIsMin: 37500,
    asIsMax: 40000,
    toBeAverage: 39000,
    toBeDeviation: 2.3,
    toBeMin: 38000,
    toBeMax: 40000,
  },
  {
    id: "FIC318.PV_pval",
    tagNumber: "FIC318.PV_pval",
    description: "Steam Flow",
    system: "Boiler (318-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -11),
    endDate: new Date(),
    asIsAverage: 21000,
    asIsDeviation: 1.7,
    asIsMin: 20700,
    asIsMax: 20000,
    toBeAverage: 21100,
    toBeDeviation: 2.0,
    toBeMin: 20500,
    toBeMax: 21500,
  },
  {
    id: "HC410.MV_pval",
    tagNumber: "HC410.MV_pval",
    description: "Auxiliary Plant",
    system: "Support",
    unit: "t/d",
    startDate: addDays(new Date(), -16),
    endDate: new Date(),
    asIsAverage: 700,
    asIsDeviation: 2.3,
    asIsMin: 680,
    asIsMax: 720,
    toBeAverage: 710,
    toBeDeviation: 2.6,
    toBeMin: 690,
    toBeMax: 730,
  },
  {
    id: "FY418.CPV_pval",
    tagNumber: "FY418.CPV_pval",
    description: "Auxiliary Plant",
    system: "Support",
    unit: "kg/h",
    startDate: addDays(new Date(), -13),
    endDate: new Date(),
    asIsAverage: 27500,
    asIsDeviation: 2.5,
    asIsMin: 26000,
    asIsMax: 29000,
    toBeAverage: 27600,
    toBeDeviation: 2.8,
    toBeMin: 26500,
    toBeMax: 28500,
  },
  {
    id: "FIC418.PV_pval",
    tagNumber: "FIC418.PV_pval",
    description: "Fuel Flow",
    system: "Generator (418-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -10),
    endDate: new Date(),
    asIsAverage: 12500,
    asIsDeviation: 2.1,
    asIsMin: 12000,
    asIsMax: 13000,
    toBeAverage: 12600,
    toBeDeviation: 2.4,
    toBeMin: 12200,
    toBeMax: 12800,
  },
  {
    id: "HC510.MV_pval",
    tagNumber: "HC510.MV_pval",
    description: "Primary Plant",
    system: "Main Line",
    unit: "t/d",
    startDate: addDays(new Date(), -19),
    endDate: new Date(),
    asIsAverage: 600,
    asIsDeviation: 2.0,
    asIsMin: 580,
    asIsMax: 620,
    toBeAverage: 605,
    toBeDeviation: 2.3,
    toBeMin: 590,
    toBeMax: 620,
  },
  {
    id: "FY518.CPV_pval",
    tagNumber: "FY518.CPV_pval",
    description: "Primary Plant",
    system: "Main Line",
    unit: "kg/h",
    startDate: addDays(new Date(), -15),
    endDate: new Date(),
    asIsAverage: 28500,
    asIsDeviation: 2.2,
    asIsMin: 27500,
    asIsMax: 29500,
    toBeAverage: 28600,
    toBeDeviation: 2.5,
    toBeMin: 28000,
    toBeMax: 29200,
  },
  {
    id: "FIC518.PV_pval",
    tagNumber: "FIC518.PV_pval",
    description: "Air Flow",
    system: "Compressor (518-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -12),
    endDate: new Date(),
    asIsAverage: 18000,
    asIsDeviation: 1.9,
    asIsMin: 17000,
    asIsMax: 19000,
    toBeAverage: 18100,
    toBeDeviation: 2.1,
    toBeMin: 17500,
    toBeMax: 18500,
  },
  {
    id: "HC610.MV_pval",
    tagNumber: "HC610.MV_pval",
    description: "Secondary Plant",
    system: "Line B",
    unit: "t/d",
    startDate: addDays(new Date(), -18),
    endDate: new Date(),
    asIsAverage: 450,
    asIsDeviation: 1.6,
    asIsMin: 430,
    asIsMax: 470,
    toBeAverage: 455,
    toBeDeviation: 1.8,
    toBeMin: 440,
    toBeMax: 470,
  },
  {
    id: "FY618.CPV_pval",
    tagNumber: "FY618.CPV_pval",
    description: "Secondary Plant",
    system: "Line B",
    unit: "kg/h",
    startDate: addDays(new Date(), -14),
    endDate: new Date(),
    asIsAverage: 19000,
    asIsDeviation: 1.8,
    asIsMin: 18000,
    asIsMax: 20000,
    toBeAverage: 19200,
    toBeDeviation: 2.0,
    toBeMin: 18500,
    toBeMax: 19800,
  },
  {
    id: "FIC618.PV_pval",
    tagNumber: "FIC618.PV_pval",
    description: "Nitrogen Flow",
    system: "Separator (618-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -11),
    endDate: new Date(),
    asIsAverage: 15500,
    asIsDeviation: 1.7,
    asIsMin: 15000,
    asIsMax: 16000,
    toBeAverage: 15600,
    toBeDeviation: 1.9,
    toBeMin: 15200,
    toBeMax: 15800,
  },
  {
    id: "HC710.MV_pval",
    tagNumber: "HC710.MV_pval",
    description: "Tertiary Plant",
    system: "Support Line",
    unit: "t/d",
    startDate: addDays(new Date(), -17),
    endDate: new Date(),
    asIsAverage: 800,
    asIsDeviation: 1.5,
    asIsMin: 780,
    asIsMax: 820,
    toBeAverage: 810,
    toBeDeviation: 1.7,
    toBeMin: 790,
    toBeMax: 830,
  },
  {
    id: "FY718.CPV_pval",
    tagNumber: "FY718.CPV_pval",
    description: "Tertiary Plant",
    system: "Support Line",
    unit: "kg/h",
    startDate: addDays(new Date(), -13),
    endDate: new Date(),
    asIsAverage: 34000,
    asIsDeviation: 1.9,
    asIsMin: 33000,
    asIsMax: 35000,
    toBeAverage: 34100,
    toBeDeviation: 2.1,
    toBeMin: 33500,
    toBeMax: 34700,
  },
  {
    id: "FIC718.PV_pval",
    tagNumber: "FIC718.PV_pval",
    description: "Oxygen Flow",
    system: "Separator (718-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -10),
    endDate: new Date(),
    asIsAverage: 16500,
    asIsDeviation: 1.8,
    asIsMin: 16000,
    asIsMax: 17000,
    toBeAverage: 16600,
    toBeDeviation: 2.0,
    toBeMin: 16200,
    toBeMax: 17000,
  },
  {
    id: "HC810.MV_pval",
    tagNumber: "HC810.MV_pval",
    description: "Quaternary Plant",
    system: "Line C",
    unit: "t/d",
    startDate: addDays(new Date(), -16),
    endDate: new Date(),
    asIsAverage: 500,
    asIsDeviation: 1.4,
    asIsMin: 480,
    asIsMax: 520,
    toBeAverage: 505,
    toBeDeviation: 1.6,
    toBeMin: 490,
    toBeMax: 520,
  },
  {
    id: "FY818.CPV_pval",
    tagNumber: "FY818.CPV_pval",
    description: "Quaternary Plant",
    system: "Line C",
    unit: "kg/h",
    startDate: addDays(new Date(), -12),
    endDate: new Date(),
    asIsAverage: 22000,
    asIsDeviation: 1.7,
    asIsMin: 21000,
    asIsMax: 23000,
    toBeAverage: 22100,
    toBeDeviation: 1.9,
    toBeMin: 21500,
    toBeMax: 22700,
  },
  {
    id: "FIC818.PV_pval",
    tagNumber: "FIC818.PV_pval",
    description: "Hydrogen Flow",
    system: "Separator (818-D013)",
    unit: "kg/h",
    startDate: addDays(new Date(), -9),
    endDate: new Date(),
    asIsAverage: 12500,
    asIsDeviation: 1.5,
    asIsMin: 12000,
    asIsMax: 13000,
    toBeAverage: 12600,
    toBeDeviation: 1.7,
    toBeMin: 12200,
    toBeMax: 12800,
  },
];
