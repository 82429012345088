const radarChartDefaultData = [
  {
    group: "Factory 1",
    Planned: 91,
    Produced: 27,
  },
  {
    group: "Factory 2",
    Planned: 52,
    Produced: 26,
  },
  {
    group: "Factory 3",
    Planned: 85,
    Produced: 74,
  },
  {
    group: "Factory 4",
    Planned: 62,
    Produced: 115,
  },
  {
    group: "Factory 5",
    Planned: 56,
    Produced: 76,
  },
];

export default radarChartDefaultData;
