import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { TreeMapData } from "./treeMapTypes";
import { ResponsiveTreeMap } from "@nivo/treemap";
import {
  DynamicGridChartType,
  getChartColors,
  getCurrentColorScheme,
} from "../../nivoTheme";

interface TreeMapProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: TreeMapData;
  isStatic?: boolean;
}

const TreeMap: React.FC<TreeMapProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 10 : 10,
    right: isStatic ? 10 : 10,
    bottom: isStatic ? 10 : 10,
    left: isStatic ? 10 : 10,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveTreeMap
        data={data}
        identity="name"
        value="loc"
        valueFormat=".02s"
        margin={chartMargin}
        colors={getChartColors()}
        theme={{
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        labelSkipSize={12}
        labelTextColor={
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
            ? "#000000"
            : "#FFFFFF"
        }
        isInteractive={isStatic ? false : true}
        parentLabelPosition="left"
        parentLabelTextColor={
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
          getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
            ? "#000000"
            : "#FFFFFF"
        }
        borderColor={{
          from: "color",
          modifiers: [["darker", 1]],
        }}
      />
    </Box>
  );
};

export default TreeMap;
