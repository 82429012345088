import { Box, Grid } from "@mui/material";
import { Formik } from "formik";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../../Global/Constants/yupConstants";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import { v4 as uuidv4 } from "uuid";
import Button from "../../../../MaterialUI/Button";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import {
  FLOW_NODE_TYPE,
  FlowAllTaskNodesData,
  FlowTextNodeData,
} from "../flowNodesTypes";
import { useDetectFormsUnsavedChanges } from "../../../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

interface FlowTextFormProps {
  handleCreateSubmit?: (nodeData: FlowAllTaskNodesData) => void;
  handleEditSubmit?: (nodeData: FlowTextNodeData) => void;
  data?: FlowTextNodeData;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const FlowTextForm: React.FC<FlowTextFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const styles = { ...cssLayoutStyles };

  const initialValues: FlowTextNodeData = {
    id: data?.id || uuidv4().split("-")[0],
    createdOn: data?.createdOn || new Date().toISOString(),
    updatedOn: data?.updatedOn || new Date().toISOString(),
    name: data?.name || "",
  };

  const handleFormSubmit = (values: FlowTextNodeData) => {
    if (handleCreateSubmit) {
      handleCreateSubmit({
        [FLOW_NODE_TYPE.Text]: values,
      });
    }
    if (handleEditSubmit) {
      handleEditSubmit(values);
    }
    setUnsavedChanges(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form css={styles.width100} onSubmit={handleSubmit}>
            <Grid spacing={3} container>
              <Grid item xs={12} sm={12}>
                <TextField
                  name="name"
                  label="Label"
                  error={touched["name"] && !!errors["name"]}
                  helperText={touched["name"] && errors["name"]}
                  onChange={handleChange}
                  value={values.name}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Box component="div" css={styles.flexCenter}>
                  <Button type="submit">{data?.id ? "Edit" : "Create New"} Node</Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default FlowTextForm;
