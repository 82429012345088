import { GaugeChartConfiguration } from "../PieChart/pieChartTypes";

export const gaugeChartDefaultConfiguration: GaugeChartConfiguration = {
  enableArcLabels: true,
  enableArcLinkLabels: true,
  colors: "Theme colors",
  colorsSequence: "green-yellow-red",
  unitOfMeasure: "",
  sections: "1",
  showDataInPercentage: false,
  gaugeMinValue: 0,
  gaugeMaxValue: 100,
  sectionRanges: [
    {
      min: 1,
      max: 100,
    },
  ],
  sectionTitles: [],
};
