import { ConditionMonitoring } from "./conditionMonitoringUtils";

export const conditionMonitoringData: ConditionMonitoring[] = [
  {
    id: "production-plant-1",
    area: "Area 1",
    machines: [
      {
        id: "Machine 1A",
        name: "Machine 1A",
        state: "normal",
        chartData: [
          {
            id: "Machine 1A",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 1B",
        name: "Machine 1B",
        state: "normal",
        chartData: [
          {
            id: "Machine 1B",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 1C",
        name: "Machine 1C",
        state: "normal",
        chartData: [
          {
            id: "Machine 1C",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 1D",
        name: "Machine 1D",
        state: "normal",
        chartData: [
          {
            id: "Machine 1D",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 1E",
        name: "Machine 1E",
        state: "normal",
        chartData: [
          {
            id: "Machine 1E",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 1F",
        name: "Machine 1F",
        state: "normal",
        chartData: [
          {
            id: "Machine 1F",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "production-plant-2",
    area: "Area 2",
    machines: [
      {
        id: "Machine 2A",
        name: "Machine 2A",
        state: "warning",
        chartData: [
          {
            id: "Machine 2A",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 2B",
        name: "Machine 2B",
        state: "normal",
        chartData: [
          {
            id: "Machine 2B",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 2C",
        name: "Machine 2C",
        state: "normal",
        chartData: [
          {
            id: "Machine 2C",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 2D",
        name: "Machine 2D",
        state: "critical",
        chartData: [
          {
            id: "Machine 2D",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: "production-plant-3",
    area: "Area 3",
    machines: [
      {
        id: "Machine 3A",
        name: "Machine 3A",
        state: "normal",
        chartData: [
          {
            id: "Machine 3A",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 3B",
        name: "Machine 3B",
        state: "warning",
        chartData: [
          {
            id: "Machine 3B",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 3C",
        name: "Machine 3C",
        state: "warning",
        chartData: [
          {
            id: "Machine 3C",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 3D",
        name: "Machine 3D",
        state: "normal",
        chartData: [
          {
            id: "Machine 3D",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
      {
        id: "Machine 3E",
        name: "Machine 3E",
        state: "normal",
        chartData: [
          {
            id: "Machine 3E",
            color: "#59b1b9",
            data: [
              {
                x: "09:08:49 AM",
                y: 50,
              },
              {
                x: "09:32:56 AM",
                y: 45,
              },
              {
                x: "09:56:38 AM",
                y: 52,
              },
              {
                x: "10:03:49 AM",
                y: 53,
              },
              {
                x: "10:10:56 AM",
                y: 52,
              },
              {
                x: "10:16:38 AM",
                y: 48,
              },
            ],
          },
        ],
      },
    ],
  },
];
