import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";

export const EXCELLENCE_TABLE_DEFAULT_COLUMNS: TableGridColumnSchema[] = [
  { id: "year", label: "Year", type: "number" },
  { id: "month", label: "Month", type: "string" },
  { id: "totalCost", label: "Total Cost", type: "number" },
  { id: "averageCostPerUnit", label: "Average Cost per Unit", type: "number" },
  { id: "unitsConsumed", label: "Units Consumed", type: "number" },
  { id: "isRenewable", label: "Is Renewable", type: "boolean" },
  { id: "lastUpdated", label: "Last Updated", type: "date" },
];

interface EnergyCost {
  year: number;
  month: string;
  totalCost: number;
  averageCostPerUnit: number;
  unitsConsumed: number;
  isRenewable: boolean;
  lastUpdated: string | null;
}

export const EXCELLENCE_TABLE_DEFAULT_ROWS: EnergyCost[] = [
  {
    year: 2023,
    month: "January",
    totalCost: 4121.66,
    averageCostPerUnit: 0.17,
    unitsConsumed: 32360,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "February",
    totalCost: 5732.14,
    averageCostPerUnit: 0.16,
    unitsConsumed: 34516,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "March",
    totalCost: 4008.43,
    averageCostPerUnit: 0.12,
    unitsConsumed: 33160,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "April",
    totalCost: 5789.06,
    averageCostPerUnit: 0.13,
    unitsConsumed: 31364,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "May",
    totalCost: 5932.74,
    averageCostPerUnit: 0.12,
    unitsConsumed: 30632,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "June",
    totalCost: 4149.15,
    averageCostPerUnit: 0.16,
    unitsConsumed: 29345,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "July",
    totalCost: 4738.68,
    averageCostPerUnit: 0.15,
    unitsConsumed: 34407,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "August",
    totalCost: 5966.56,
    averageCostPerUnit: 0.15,
    unitsConsumed: 29063,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "September",
    totalCost: 5097.37,
    averageCostPerUnit: 0.15,
    unitsConsumed: 33723,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "October",
    totalCost: 5287.4,
    averageCostPerUnit: 0.13,
    unitsConsumed: 33576,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "November",
    totalCost: 4733.89,
    averageCostPerUnit: 0.13,
    unitsConsumed: 31107,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "December",
    totalCost: 4522.91,
    averageCostPerUnit: 0.17,
    unitsConsumed: 29645,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "January",
    totalCost: 5461.34,
    averageCostPerUnit: 0.15,
    unitsConsumed: 34151,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "February",
    totalCost: 4960.68,
    averageCostPerUnit: 0.15,
    unitsConsumed: 33582,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "March",
    totalCost: 5251.53,
    averageCostPerUnit: 0.13,
    unitsConsumed: 32974,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "April",
    totalCost: 5922.43,
    averageCostPerUnit: 0.16,
    unitsConsumed: 28107,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "May",
    totalCost: 5088.54,
    averageCostPerUnit: 0.17,
    unitsConsumed: 31859,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "June",
    totalCost: 5241.76,
    averageCostPerUnit: 0.14,
    unitsConsumed: 28446,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "July",
    totalCost: 4534.16,
    averageCostPerUnit: 0.14,
    unitsConsumed: 33429,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "August",
    totalCost: 5487.89,
    averageCostPerUnit: 0.16,
    unitsConsumed: 28457,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "September",
    totalCost: 4186.41,
    averageCostPerUnit: 0.16,
    unitsConsumed: 31043,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "October",
    totalCost: 4657.17,
    averageCostPerUnit: 0.15,
    unitsConsumed: 32878,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "November",
    totalCost: 5496.11,
    averageCostPerUnit: 0.18,
    unitsConsumed: 29538,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2024,
    month: "December",
    totalCost: 5487.0,
    averageCostPerUnit: 0.17,
    unitsConsumed: 29647,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "January",
    totalCost: 5605.11,
    averageCostPerUnit: 0.17,
    unitsConsumed: 33759,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "February",
    totalCost: 4222.69,
    averageCostPerUnit: 0.12,
    unitsConsumed: 31906,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "March",
    totalCost: 4594.92,
    averageCostPerUnit: 0.16,
    unitsConsumed: 30234,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "April",
    totalCost: 5510.01,
    averageCostPerUnit: 0.12,
    unitsConsumed: 28630,
    isRenewable: true,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "May",
    totalCost: 5230.73,
    averageCostPerUnit: 0.12,
    unitsConsumed: 34225,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "June",
    totalCost: 4704.19,
    averageCostPerUnit: 0.18,
    unitsConsumed: 31465,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "July",
    totalCost: 5263.85,
    averageCostPerUnit: 0.17,
    unitsConsumed: 28462,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
  {
    year: 2023,
    month: "August",
    totalCost: 5725.04,
    averageCostPerUnit: 0.13,
    unitsConsumed: 34565,
    isRenewable: false,
    lastUpdated: new Date().toISOString(),
  },
];
