import { Query } from "../callApi";
import {
  PostQueryCreateTTMapInput,
  PostQueryCreateTTObjectInput,
  PostQueryUpdateTTObjectInput,
  PostQueryUploadFileInput,
} from "./apiTTInputs";

export const postQueryCreateObject = (input: PostQueryCreateTTObjectInput): Query => ({
  endpoint: "track_and_trace/object",
  method: "POST",
  variables: input,
});

export const postQueryUpdateObject = (
  objectCode: string,
  input: PostQueryUpdateTTObjectInput
): Query => ({
  endpoint: `track_and_trace/object/${objectCode}`,
  method: "PUT",
  variables: input,
});

export const postQueryDeleteObject = (objectCode: string): Query => ({
  endpoint: `track_and_trace/object/${objectCode}`,
  method: "DELETE",
});

export const postQueryCreateMap = (input: PostQueryCreateTTMapInput): Query => ({
  endpoint: "track_and_trace/map",
  method: "POST",
  variables: input,
});

export const postQueryDeleteMap = (objectCode: string): Query => ({
  endpoint: `track_and_trace/map/${objectCode}`,
  method: "DELETE",
});

export const postQueryTTUploadFile = (
  objectDocumentId: string,
  input: PostQueryUploadFileInput
): Query => ({
  endpoint: `track_and_trace/object/${objectDocumentId}/file/upload`,
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryTTDeleteFile = (fileId: string): Query => ({
  endpoint: `track_and_trace/file/${fileId}`,
  method: "DELETE",
});
