import { useEffect, useState } from "react";
import { ModbusSubscription } from "../../../../../Api/DataSources/apiDSDataTypes";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import useTheme from "@mui/material/styles/useTheme";
import Alert from "../../../../MaterialUI/Alert";
import { Grid, Stack, Divider, Box, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";

type OldNew = {
  old: string | number;
  new: string | number;
};
type PreviousVersionDiff = {
  mapping?: {
    added: string[];
    removed: string[];
  };
  name?: OldNew;
  host?: OldNew;
  port?: OldNew;
};

interface OpcModbusHistoryModalContentProps {
  sub: ModbusSubscription;
  isShowDifference: boolean;
  previousSub: ModbusSubscription | null;
}

const OpcModbusHistoryModalContent: React.FC<OpcModbusHistoryModalContentProps> = ({
  sub,
  isShowDifference,
  previousSub,
}) => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [previousVersionDiff, setPreviousVersionDiff] =
    useState<PreviousVersionDiff | null>(null);

  const currentSubMappingNames = sub.values_addresses_map?.length
    ? sub.values_addresses_map.map((item) => item.value_name).join(", ")
    : "No mapping provided";

  useEffect(() => {
    (async () => {
      try {
        setFetchStatus("loading");
        setAlertMessage("Loading...");
        if (isShowDifference) {
          handleDiffFromPreviousVersion();
        }
        setFetchStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("OpcMQTTHistoryModalContent, useEffect on mount err ", err);
        setFetchStatus("error");
        setAlertMessage("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDiffFromPreviousVersion = () => {
    if (previousSub) {
      const addedMapping = sub.values_addresses_map.filter(
        (newItem) =>
          !previousSub.values_addresses_map.some(
            (oldItem) => newItem.value_name === oldItem.value_name
          )
      );
      const removedMapping = previousSub.values_addresses_map.filter(
        (oldItem) =>
          !sub.values_addresses_map.some(
            (newItem) => newItem.value_name === oldItem.value_name
          )
      );

      const nameDiff = handleConnectionOldNewString(sub.name, previousSub.name);
      const hostDiff = handleConnectionOldNewString(sub.host, previousSub.host);
      const portDiff = handleConnectionOldNewString(sub.port, previousSub.port);

      const addedMappingNames = addedMapping.map((item) => item.value_name);
      const removedMappingNames = removedMapping.map((item) => item.value_name);

      setPreviousVersionDiff({
        mapping: {
          added: addedMappingNames,
          removed: removedMappingNames,
        },
        name: nameDiff,
        host: hostDiff,
        port: portDiff,
      });
    }
  };

  if (fetchStatus !== "success") {
    return (
      <Alert
        css={styles.widthLimit25}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={fetchStatus}
      />
    );
  }

  if (!isShowDifference) {
    return (
      <Stack spacing={2}>
        <LabelWithBoldedPart text={"Name"} bolded={sub.name} />
        <LabelWithBoldedPart text={"Host"} bolded={sub.host} />
        <LabelWithBoldedPart text={"Port"} bolded={sub.port} />
        <LabelWithBoldedPart text={"Mapping values"} bolded={currentSubMappingNames} />
      </Stack>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack
          css={styles.height100}
          spacing={2}
          justifyContent="space-between"
          direction="row"
        >
          <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="new" />
          <Divider flexItem orientation="vertical" />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="old" />
      </Grid>
    </Grid>
  );
};

export default OpcModbusHistoryModalContent;

const DifferenceList: React.FC<{
  previousVersionDiff: PreviousVersionDiff | null;
  keyValue: keyof OldNew;
}> = ({ previousVersionDiff, keyValue }) => {
  const mappingValue =
    keyValue === "new"
      ? previousVersionDiff?.mapping?.added.join(", ")
      : previousVersionDiff?.mapping?.removed.join(", ");

  const mappingText =
    keyValue === "new" ? "New mapping values added" : "Old mapping values removed";

  return (
    <Box component="div" sx={{ width: "100%" }}>
      <Typography sx={{ marginBottom: "1rem" }} variant="h4">
        {keyValue === "new" ? "Current version" : "Previous version"}
      </Typography>
      <Stack spacing={2}>
        <LabelWithBoldedPart
          text={"Name"}
          bolded={
            previousVersionDiff?.name ? previousVersionDiff.name[keyValue] : "No change"
          }
        />
        <LabelWithBoldedPart
          text={"Host"}
          bolded={
            previousVersionDiff?.host ? previousVersionDiff.host[keyValue] : "No change"
          }
        />
        <LabelWithBoldedPart
          text={"Port"}
          bolded={
            previousVersionDiff?.port ? previousVersionDiff.port[keyValue] : "No change"
          }
        />
        <LabelWithBoldedPart text={mappingText} bolded={mappingValue || "No change"} />
      </Stack>
    </Box>
  );
};

export const handleConnectionOldNewString = (
  newStr: string | number,
  oldStr: string | number
): OldNew | undefined => {
  if (newStr === oldStr) {
    return undefined;
  }

  return {
    new: `${newStr}`,
    old: `${oldStr}`,
  };
};
