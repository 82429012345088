import {
  ExcellenceTableData,
  ChartConfigFormValuesWithTitle,
  ExcellenceChartFormProps,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import useTheme from "@mui/material/styles/useTheme";
import { Grid, Typography } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import {
  ExcellenceTableConfiguration,
  ExcellenceTableDataSchema,
} from "./editExcellenceTabletypes";
import EditExcellenceTableDataSchema from "./EditExcellenceTableDataSchema";
import EditExcellenceTableConfig from "./EditExcellenceTableConfig";
import { useLanguageContext } from "../../../context/LanguageContext";
import { AutocompleteOption, FormStatuses } from "../../../Global/Types/commonTypes";
import { useLazyQuery } from "@apollo/client";
import { graphQlQueryExcellenceTableData } from "../../../GraphQL/Excellence/graphQLQueriesExcellence";
import Alert from "../../MaterialUI/Alert";
import {
  EXCELLENCE_TABLE_DEFAULT_COLUMNS,
  EXCELLENCE_TABLE_DEFAULT_ROWS,
} from "../ExcellenceDefaultConfig/excellenceTableDefaultData";
import ExcellenceTable from "../../PageComponents/Excellence/ExcellenceCharts/ExcellenceTable/ExcellenceTable";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";

interface EditExcellenceTableFormProps
  extends Omit<ExcellenceChartFormProps, "dataSchema"> {
  config: ExcellenceTableConfiguration;
  data: ExcellenceTableData;
  dataSchema: ExcellenceTableDataSchema | null;
}

const EditExcellenceTableForm: React.FC<EditExcellenceTableFormProps> = ({
  chartClass,
  config,
  dataSchema,
  data,
  widgetTitle,
  handleSaveChanges,
  parameters,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [tabValue, setTabValue] = useState(0);

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<ExcellenceTableConfiguration>,
      ExcellenceTableDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: dataSchema as ExcellenceTableDataSchema,
      handleSetUnsavedChanges,
    });

  // tab number 0
  const [fieldAlert, setFieldAlert] = useState<string | null>(null);

  // tab number 1
  const [updatedData, setUpdatedData] = useState<ExcellenceTableData>(data);
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);

  // data tab
  const [dateFrom, setDateFrom] = useState<Date | null>(
    dataSchema?.startTime ? new Date(dataSchema.startTime) : null
  );
  const [dateTo, setDateTo] = useState<Date | null>(
    dataSchema?.endTime ? new Date(dataSchema.endTime) : null
  );
  const [selectedConnections, setSelectedConnections] =
    useState<AutocompleteOption | null>(null);

  const [getTableData] = useLazyQuery(graphQlQueryExcellenceTableData);

  useEffect(() => {
    (async () => {
      if (updatedSchema) {
        try {
          setFetchStatus("loading");
          const result = await getTableData({
            variables: {
              input: updatedSchema,
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.tableData.chartData;

          setUpdatedData(resultData);
          setResetChartFetch((prev) => !prev);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchema]);

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    const updatedChart: DynamicGridChartOptions = {
      table: { config: otherConfig, data: updatedData, dataSchema: updatedSchema },
    };

    // do some field checks
    if (!title) {
      setFieldAlert(t("Title is required"));
    } else {
      // all fields checks have passed
      handleSaveChanges(updatedChart, title);
    }
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab={false}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          {tabValue === 0 ? (
            <EditExcellenceTableConfig
              config={updatedConfig}
              setUpdatedConfig={setUpdatedConfig}
            />
          ) : null}

          {tabValue === 1 ? (
            <EditExcellenceTableDataSchema
              parameters={parameters?.parameters || []}
              dataSchema={updatedSchema}
              handleUpdateDataSchema={(val) => setUpdatedSchema(val)}
              dateFrom={dateFrom}
              setDateFrom={setDateFrom}
              dateTo={dateTo}
              setDateTo={setDateTo}
              selectedConnections={selectedConnections}
              setSelectedConnections={setSelectedConnections}
            />
          ) : null}

          <Alert
            css={styles.reverseContentBreak}
            message={t("Something went wrong")}
            showAlert={fetchStatus === "error"}
            severity={"error"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography css={styles.labelBreak} variant="h3">
            {updatedConfig.title}
          </Typography>
          <ExcellenceTable
            css={chartClass}
            configuration={updatedConfig}
            data={
              !updatedSchema
                ? {
                    columns: EXCELLENCE_TABLE_DEFAULT_COLUMNS,
                    rows: EXCELLENCE_TABLE_DEFAULT_ROWS,
                  }
                : updatedData
            }
            noAnimation
            schema={updatedSchema}
            resetChartFetch={resetChartFetch}
            parameters={parameters?.parameters || []}
            dataFetchFromParent
          />
        </Grid>
      </Grid>
    </ExcellenceChartFormWrapper>
  );
};

export default EditExcellenceTableForm;
