import { Formik } from "formik";
import { Box, Grid } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Button from "../../MaterialUI/Button";
import { object } from "yup";
import {
  YUP_NUMBER_BETWEEN_0_100,
  YUP_REQUIRED_STRING,
} from "../../../Global/Constants/yupConstants";
import Alert from "../../MaterialUI/Alert";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { SerializedStyles } from "@emotion/react";
import DatePicker from "../../MaterialUI/DateTimePickers/DatePicker";
import cssLayoutStyles from "../../../Global/Styles/layout";
import isValid from "date-fns/isValid";
import { PostQueryProjectCreateInput } from "../../../Api/ProjectManagement/apiProjectInputs";
import { postQueryProjectCreate } from "../../../Api/ProjectManagement/apiProjectPostQueries";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import { PostQueryProjectCreateSnippet } from "../../../Api/ProjectManagement/apiProjectSnippets";
import { OpExProject } from "../../../Api/ProjectManagement/apiProjectDataTypes";
import { Task } from "gantt-task-react";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
  start: YUP_REQUIRED_STRING,
  end: YUP_REQUIRED_STRING,
  progress: YUP_NUMBER_BETWEEN_0_100,
});

type FormValues = {
  name: string;
  start: Date | null;
  end: Date | null;
  progress: string;
};

interface CreateOpExProjectProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  setProjectsData: React.Dispatch<React.SetStateAction<OpExProject[]>>;
  setGanttChartData: React.Dispatch<React.SetStateAction<Task[] | undefined>>;
  setGanttEditTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const PlanningProjectsForm: React.FC<CreateOpExProjectProps> = ({
  className,
  setProjectsData,
  setGanttChartData,
  setOpenModal,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const styles = { ...cssLayoutStyles };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { setAuthedUser } = useAuthedContext();

  const initialValues: FormValues = {
    name: "",
    start: null,
    end: null,
    progress: "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const input: PostQueryProjectCreateInput = {
        data: {
          name: values.name,
          start: values.start,
          end: values.end,
          progress: values.progress,
          tasks: [],
        },
      };
      const result = await callApi<PostQueryProjectCreateSnippet>({
        query: postQueryProjectCreate(input),
        auth: { setAuthedUser },
      });

      if (result) {
        const project: Task = {
          id: result.id,
          type: "project",
          name: result.data.name,
          start: new Date(result.data.start),
          end: new Date(result.data.end),
          progress: result.data.progress,
        };

        setGanttChartData((prev) => {
          const updatedPrev = prev || [];
          return [...updatedPrev, project];
        });
        setProjectsData((prev) => {
          const updatedPrev = prev || [];
          return [
            ...updatedPrev,
            {
              ...result.data,
              id: result.id,
              start: result.data.start.toString(),
              end: result.data.end.toString(),
            },
          ];
        });
        setFormStatus("success");
        setUnsavedChanges(false);
        setOpenModal(false);
      }
    } catch (err) {
      console.log("CreateOpExProject err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Grid className={className} spacing={3} container>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  label="Project Name"
                  error={touched["name"] && !!errors["name"]}
                  helperText={touched["name"] && errors["name"]}
                  onChange={handleChange}
                  value={values.name}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="progress"
                  label="Project Progress"
                  error={touched["progress"] && !!errors["progress"]}
                  helperText={touched["progress"] && errors["progress"]}
                  onChange={handleChange}
                  value={values.progress}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DatePicker
                  css={styles.width100}
                  name="start"
                  label="Project Start Date"
                  error={touched["start"] && !!errors["start"]}
                  helperText={touched["start"] && errors["start"]}
                  onChange={(value) => setFieldValue("start", value)}
                  value={values.start}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <DatePicker
                  css={styles.width100}
                  name="end"
                  label="Project End Date"
                  error={touched["end"] && !!errors["end"]}
                  helperText={touched["end"] && errors["end"]}
                  onChange={(value) => setFieldValue("end", value)}
                  value={values.end}
                  minDate={
                    values.start && isValid(values.start) ? values.start : undefined
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Box component="div" css={styles.flexCenter}>
                  <Button type="submit" loading={formStatus === "loading"}>
                    Create New Project
                  </Button>
                </Box>
                <Alert
                  message={alertMessage || ""}
                  showAlert={!!alertMessage}
                  severity={formStatus}
                />
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default PlanningProjectsForm;
