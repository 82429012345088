import {
  PID_FLOW_NODE_TYPE,
  PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  PidSharedNodeData,
} from "../pidNodesUtils";
import { Node } from "reactflow";

export type PidWorkflowsBackgroundNodeType = Node & {
  data: PidWorkflowsBackgroundNodeData;
};
export type PidWorkflowsBackgroundNodeData = PidSharedNodeData & {
  bgColor: string;
  bgImageUrl?: string | null;
};
export const PID_WORKFLOW_BACKGROUND_NODE_CONFIG = {
  ...PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  type: PID_FLOW_NODE_TYPE.Background,
  style: {
    ...PID_WORKFLOW_DEFAULT_NODE_CONFIG.style,
    height: 200,
    width: 400,
    zIndex: 0,
  },
};
