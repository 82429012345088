import { ChartConfigFormValuesWithTitle } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Box, Grid } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { ExcellenceTableConfiguration } from "./editExcellenceTabletypes";
import { useLanguageContext } from "../../../context/LanguageContext";

type KeyOfConfigValue =
  keyof ChartConfigFormValuesWithTitle<ExcellenceTableConfiguration>;

interface EditExcellenceTableConfigProps {
  config: ChartConfigFormValuesWithTitle<ExcellenceTableConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<ExcellenceTableConfiguration>>
  >;
}

const EditExcellenceTableConfig: React.FC<EditExcellenceTableConfigProps> = ({
  config,
  setUpdatedConfig,
}) => {
  const { t } = useLanguageContext();
  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditExcellenceTableConfig;
