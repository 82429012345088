import { SerializedStyles } from "@emotion/react";
import { Box } from "@mui/material";
import { Datum, Layer, Point, ResponsiveLine } from "@nivo/line";
import {
  TimeChartConfiguration,
  TimeChartThresholdValues,
  ChartXScaleMinMax,
  TimeChartThreshold,
  TimeChartCircle,
  ExcellenceTimeChartSettings,
} from "./timeChartTypes";
import { LineAreaHeatMapScatterPlotData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import timeChartDefaultData from "../ExcellenceDefaultConfig/timeChartDefaultData";
import useTheme from "@mui/material/styles/useTheme";
import useContainerDimensions from "../../../Global/Hooks/useContainerDimensions";
import { memo, useRef } from "react";
import { defaultLayers, timeChartMinMaxY } from "./timeChartUtils";
import { getChartColors } from "../nivoTheme";
import { isEqual } from "date-fns";

type ThresholdMarker = {
  axis: "y" | "x";
  lineStyle: {
    stroke: string;
    strokeWidth: number;
  };
  value: number | string;
};

interface TimeChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: TimeChartConfiguration;
  data: LineAreaHeatMapScatterPlotData | null;
  isStatic?: boolean;
  customLayers?: Array<(props: any) => JSX.Element>;
  animate?: boolean;
  xScaleMinMax?: ChartXScaleMinMax;
  circles?: {
    circles: TimeChartCircle[] | null;
    setCircles: React.Dispatch<React.SetStateAction<TimeChartCircle[] | null>>;
  };
  initialLoading?: boolean;
  hideLegend?: boolean;
  chartSettings?: ExcellenceTimeChartSettings;
}

const TimeChart: React.FC<TimeChartProps> = ({
  className,
  configuration,
  data,
  isStatic,
  customLayers,
  // animate = true,
  xScaleMinMax,
  circles,
  initialLoading,
  hideLegend = false,
  chartSettings = {},
}) => {
  const {
    axisLeftLegend,
    axisBottomLegend,
    enablePoints,
    interpolate,
    enableGridX,
    enableGridY,
    threshold,
    gridLineColor,
    sliceTooltip,
  } = configuration;
  const {
    chartMargin,
    axisBottomFormat,
    axisBottomTickValues,
    axisLeftTickValues,
    maxYValue,
  } = chartSettings;
  const theme = useTheme();
  const chartContainerRef = useRef<HTMLDivElement>(null);
  const { width: chartWidth } = useContainerDimensions(chartContainerRef);

  const widthRotation = calculateLabelRotation(chartWidth);
  const widthBottomMargin = calculateLabelMargin(widthRotation);

  const dataToUse: LineAreaHeatMapScatterPlotData = data ? data : timeChartDefaultData;

  const dynamicData = getDynamicData(chartWidth, dataToUse);
  // console.log("dynamicData ", dynamicData[0].data);

  const yScaleMinMax = timeChartMinMaxY(dataToUse, configuration, threshold?.values);
  const longestLegend = getLongestLegendWordChars(dataToUse);

  const MARKER_COLORS: Record<keyof TimeChartThresholdValues, string> = {
    lowLowThreshold: theme.palette.error.dark,
    lowThreshold: theme.palette.warning.dark,
    highThreshold: theme.palette.warning.dark,
    highHighThreshold: theme.palette.error.dark,
  };

  const defaultChartMargin = {
    top: isStatic ? 10 : 25,
    right: isStatic ? 10 : 50 + longestLegend * 6,
    bottom: isStatic ? 10 : widthBottomMargin - 0,
    left: isStatic ? 10 : 60,
  };

  const finalChartMargin = chartMargin || defaultChartMargin;

  const markers: ThresholdMarker[] | null = threshold?.values
    ? Object.entries(threshold.values)
        .map(([key, value]) => {
          const color = MARKER_COLORS[key as keyof TimeChartThresholdValues];
          const axis = "y" as "y";

          return {
            axis,
            lineStyle: {
              stroke: color,
              strokeWidth: 2,
            },
            value: value?.value || "",
          };
        })
        .filter((marker) => {
          return marker.value !== "";
        })
    : null;

  const chartLayers: Layer[] | undefined = customLayers?.length
    ? [...customLayers.map((layer) => layer as Layer), ...defaultLayers]
    : undefined;

  const handleOnClick = (point: Point, _: React.MouseEvent<Element, MouseEvent>) => {
    if (!circles) {
      return;
    }
    circles.setCircles((prev) => {
      const result = {
        pointPosition: { x: point.x + 60, y: point.y + 25 },
        id: point.id,
        xFormatted: point.data.xFormatted,
        color: theme.palette.primary.main,
      };

      if (!prev?.length) {
        return [result];
      }
      if (prev.length === 1) {
        const firstCircleDate = new Date(prev[0].xFormatted);
        if (isEqual(firstCircleDate, new Date(result.xFormatted))) {
          return prev;
        }
        return [...prev, result];
      }
      return prev;
    });
  };

  return (
    <Box
      component="div"
      sx={{ position: "relative" }}
      className={className}
      ref={chartContainerRef}
    >
      <ResponsiveLine
        data={dynamicData}
        margin={finalChartMargin}
        onClick={handleOnClick}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S.%L",
          useUTC: true,
          precision: "millisecond",
          min: xScaleMinMax ? new Date(xScaleMinMax.min) : undefined,
          max: xScaleMinMax ? new Date(xScaleMinMax.max) : undefined,
        }}
        yScale={{
          type: "linear",
          min: initialLoading ? "auto" : yScaleMinMax.min,
          max: initialLoading ? "auto" : maxYValue ? maxYValue : yScaleMinMax.max,
          stacked: false,
          reverse: false,
        }}
        xFormat={"time:%Y-%m-%d %H:%M:%S.%L"}
        enablePoints={enablePoints}
        enableGridX={enableGridX}
        enableGridY={enableGridY}
        isInteractive={isStatic ? false : true}
        curve={interpolate}
        colors={(d) => getLineColors(d, threshold, MARKER_COLORS)}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          grid: {
            line: {
              stroke: gridLineColor,
              strokeWidth: 1,
            },
          },
          crosshair:
            theme.palette.mode === "dark"
              ? {
                  line: {
                    stroke: "#FFFFFF",
                    strokeWidth: 2,
                  },
                }
              : undefined,
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        enableSlices="x"
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={
          isStatic
            ? null
            : {
                format: axisBottomFormat ? axisBottomFormat : "%Y-%m-%d %H:%M",
                legend: axisBottomLegend,
                legendOffset: 15,
                legendPosition: "middle",
                tickRotation: widthRotation,
                tickPadding: 30,
                tickValues: axisBottomTickValues ? axisBottomTickValues : undefined,
              }
        }
        axisLeft={
          isStatic
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: axisLeftLegend,
                legendOffset: -50,
                legendPosition: "middle",
                tickValues: axisLeftTickValues ? axisLeftTickValues : undefined,
              }
        }
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{
          from: "serieColor",
          modifiers: theme.palette.mode === "light" ? [["darker", 1.6]] : undefined,
        }}
        pointLabelYOffset={-12}
        useMesh={true}
        legends={
          hideLegend || isStatic
            ? undefined
            : [
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 100,
                  translateY: 0,
                  itemsSpacing: 0,
                  itemDirection: "left-to-right",
                  itemWidth: 80,
                  itemHeight: 20,
                  itemOpacity: 0.75,
                  symbolSize: 12,
                  symbolShape: "circle",
                  symbolBorderColor: "rgba(0, 0, 0, .5)",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, .03)",
                        itemOpacity: 1,
                      },
                    },
                  ],
                  data: dynamicData.map((item) => ({
                    color: getLineColors(item, threshold, MARKER_COLORS),
                    id: item.id,
                    label: item.name,
                  })),
                },
              ]
        }
        markers={isStatic ? undefined : markers || undefined}
        animate={false}
        sliceTooltip={sliceTooltip ? sliceTooltip : undefined}
        layers={chartLayers}
      />
      {circles?.circles?.length ? (
        <>
          {circles.circles.map((circle, index) => (
            <DraggableCircle
              key={`circle-${index}`}
              pointPosition={circle.pointPosition}
              id={circle.id}
              xFormatted={circle.xFormatted}
              color={circle.color}
            />
          ))}
        </>
      ) : null}
    </Box>
  );
};

export default memo(TimeChart);

const calculateLabelRotation = (chartWidth: number): number => {
  const maxChartWidth = 1400;
  const maxRotation = 90;
  const initialRotation = 2;

  if (chartWidth >= maxChartWidth) {
    return 0; // No rotation if chartWidth is 1400px or more
  } else {
    // Calculate rotation based on chartWidth
    const additionalRotation =
      maxRotation * ((maxChartWidth - chartWidth) / maxChartWidth);
    return Math.min(maxRotation, initialRotation + additionalRotation);
  }
};

const calculateLabelMargin = (rotation: number): number => {
  const maxRotation = 90;
  const maxMargin = 150;
  const minMargin = 75;

  if (rotation === 0) {
    return minMargin;
  } else {
    const calculatedMargin =
      (rotation / maxRotation) * (maxMargin - minMargin) + minMargin;
    return Math.max(minMargin, calculatedMargin);
  }
};

const getLongestLegendWordChars = (data: LineAreaHeatMapScatterPlotData) => {
  const wordsArr = data.map((item) => item.name);

  let longestLength = 0;

  wordsArr.forEach((word) => {
    if (word.length > longestLength) {
      longestLength = word.length;
    }
  });

  return longestLength;
};

type TimeDatum = Datum & {
  x: string;
  y: number;
};
type TimeDateDatum = Datum & {
  x: Date;
  y: number;
};

const allowedPointIntervalsInSeconds = [
  1, 5, 10, 15, 30, 45, 60, 120, 300, 600, 900, 1800, 3600, 10800, 43200, 86400, 259200,
  604800, 2592000, 5184000, 7776000, 15552000, 31536000,
];

const getDynamicData = (width: number, data: LineAreaHeatMapScatterPlotData) => {
  // 1. Calculate max numb of points based on chart width
  // 1px === 1point
  const maxDataPoints = Math.round(width);

  // 2. Down-sample the data to have a length up to maxPoints
  const adjustedData = data.map((item, dataIndex) => {
    const typedDatum = item.data as TimeDatum[];
    const downSampledArray = getDynamicDataFromSeconds(typedDatum, maxDataPoints);

    return {
      ...item,
      name: item.name,
      color: getChartColors()?.[dataIndex] || "black",
      data: downSampledArray,
    };
  });

  return adjustedData;
};

const downSampleFromSeconds = (
  data: TimeDatum[],
  intervalInSeconds: number
): TimeDateDatum[] => {
  const downSampledArray: TimeDateDatum[] = [];

  if (data.length === 0) {
    return [];
  }

  const intervalInMillis = intervalInSeconds * 1000;

  // Convert ISO date strings to actual Date objects
  const parsedData = data.map((datum) => ({
    ...datum,
    x: new Date(datum.x),
    y: datum.y,
  }));

  // Initialize the first data point
  if (!parsedData[0]?.x?.getTime()) {
    console.log("parsedData ", parsedData);
  }
  let prevTime = parsedData[0].x.getTime();
  downSampledArray.push(parsedData[0]);

  parsedData.forEach((datum, i) => {
    if (i === 0) {
      // Skip the first data point, as it's already included
      return;
    }

    if (!datum?.x?.getTime()) {
      console.log("datum ", datum);
    }
    const currentTime = datum.x.getTime();
    const timeDifference = currentTime - prevTime;

    // If time difference is greater than or equal to the interval, include this data point
    if (timeDifference >= intervalInMillis) {
      downSampledArray.push(datum);
      prevTime = currentTime;
    }
  });

  // Convert Date object back to ISO date string
  // const resultTimeDatum = downSampledArray.map((item) => ({
  //   ...item,
  //   x: item.x.toISOString(),
  //   y: item.y,
  // }));

  return downSampledArray;
};

const getDynamicDataFromSeconds = (data: TimeDatum[], maxDataPoints: number) => {
  if (data.length <= maxDataPoints) {
    return data;
  }

  for (const seconds of allowedPointIntervalsInSeconds) {
    const parsed = downSampleFromSeconds(data, seconds);
    if (parsed.length <= maxDataPoints) {
      return parsed;
    }
  }
  return [];
};
type SingleDatum = {
  id: string;
  name: string;
  color: string;
  data: Datum[];
};

const getLineColors = (
  datum: SingleDatum,
  threshold: TimeChartThreshold | undefined,
  markerColors: Record<keyof TimeChartThresholdValues, string>
): string => {
  if (threshold?.mode === "valuesFromNodes" && threshold?.values) {
    if (datum.id === threshold.values.highHighThreshold?.node) {
      return markerColors.highHighThreshold;
    } else if (datum.id === threshold.values.highThreshold?.node) {
      return markerColors.highThreshold;
    } else if (datum.id === threshold.values.lowThreshold?.node) {
      return markerColors.lowThreshold;
    } else if (datum.id === threshold.values.lowLowThreshold?.node) {
      return markerColors.lowLowThreshold;
    }
  }
  return datum.color;
};

const DraggableCircle: React.FC<TimeChartCircle> = ({ pointPosition, color }) => {
  return (
    <svg
      width={40}
      height={40}
      style={{
        position: "absolute",
        left: pointPosition.x - 20,
        top: pointPosition.y - 20,
      }}
      stroke="#000"
      strokeWidth={0.005}
      viewBox="-179.2 -179.2 870.4 870.4"
    >
      <rect
        id="SVGRepo_bgCarrier"
        width={870.4}
        height={870.4}
        x={-179.2}
        y={-179.2}
        fill={color}
        strokeWidth={0}
        rx={435.2}
      />
      <g id="SVGRepo_iconCarrier">
        <style>{".st0{fill:#000}"}</style>
        <path
          d="M312.069 53.445c-71.26-71.26-187.194-71.26-258.454 0-71.261 71.26-71.261 187.206 0 258.466 71.26 71.26 187.194 71.26 258.454 0s71.26-187.206 0-258.466zm-25.375 233.091c-57.351 57.34-150.353 57.34-207.704-.011s-57.351-150.353 0-207.693c57.351-57.351 150.342-57.351 207.693 0s57.362 150.342.011 207.704z"
          className="st0"
        />
        <path
          d="M101.911 112.531c-29.357 37.725-31.801 89.631-7.321 129.702 1.877 3.087 5.902 4.048 8.978 2.182 3.065-1.888 4.037-5.903 2.16-8.978-21.666-35.456-19.506-81.538 6.469-114.876 2.226-2.837 1.713-6.938-1.135-9.154a6.523 6.523 0 0 0-9.151 1.124zM498.544 447.722l-132.637-129.2c-7.255-7.07-18.84-6.982-26.008.174l-21.033 21.033c-7.156 7.156-7.234 18.742-.153 25.986l129.19 132.636c14.346 17.324 35.542 18.35 51.917 1.964 16.396-16.364 16.037-38.247-1.276-52.593z"
          className="st0"
        />
      </g>
    </svg>
  );
};
