import React from "react";
import { CircularProgress, Box, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssComponentsStyles from "../../../Global/Styles/components";
import cssLayoutStyles from "../../../Global/Styles/layout";

const LoadingScreen: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssComponentsStyles(theme), ...cssLayoutStyles };

  return (
    <Box
      component="div"
      css={[styles.card, styles.flexCenter]}
      flexDirection="column"
      minHeight="100vh"
      minWidth="100vw"
    >
      <CircularProgress />
      <Typography variant="h4" mt={2} color={"primary.main"}>
        Loading...
      </Typography>
    </Box>
  );
};

export default LoadingScreen;
