import {
  ChartConfigFormValuesWithTitle,
  chartSectionsNumberShemeOptions,
  gaugeChartColorSchemeOptions,
  getCustomSetSequenceColorSchemeOptions,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import Select from "../../MaterialUI/FormFields/Select";
import {
  GaugeWidgetConfiguration,
  KeyOfConfigValue,
  SectionRange,
  getGaugeWidgetAlertMessage,
} from "./gaugeWidgetUtils";
import { AutocompleteOption, SelectOption } from "../../../Global/Types/commonTypes";
import UnitsOfMeasureDropdown from "../../SmallComponents/UnitsOfMeasureDropdown/UnitsOfMeasureDropdown";
import React, { useEffect, useState } from "react";
import Alert from "../../MaterialUI/Alert";
import GaugeWidgetSectionsRangeConfig from "./GaugeWidgetSectionsRangeConfig";
import { useLanguageContext } from "../../../context/LanguageContext";

interface EditGaugeWidgetConfigProps {
  config: ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>>
  >;
  gaugeDataMaxValue: number;
  gaugeDataMinValue: number;
}

const EditGaugeWidgetConfig: React.FC<EditGaugeWidgetConfigProps> = ({
  config,
  setUpdatedConfig,
  gaugeDataMaxValue,
  gaugeDataMinValue,
}) => {
  const theme = useTheme();
  const { t, language } = useLanguageContext();
  const [unitId, setUnitId] = useState<string | null>(null);
  const [colorSequenceOptions, setColorSequenceOptions] = useState<SelectOption[]>([]);
  const [valueErrors, setValueErrors] = useState<{ [key: string]: boolean }>({});
  const [alertMessages, setAlertMessages] = useState<string[]>([]);

  useEffect(() => {
    setColorSequenceOptions(getCustomSetSequenceColorSchemeOptions(config.sections));
    setUpdatedConfig((prev) => ({
      ...prev,
      gaugeMaxValue: gaugeDataMaxValue,
      gaugeMinValue: gaugeDataMinValue,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.sections, gaugeDataMaxValue, gaugeDataMinValue]);

  const handleUpdateFormValue = (
    key: KeyOfConfigValue,
    value: string | number | boolean | SectionRange[]
  ) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleUnitChange = (option: AutocompleteOption | null, isEmpty: boolean) => {
    if (isEmpty) {
      handleUpdateFormValue("unitOfMeasure", "");
      handleUpdateFormValue("showDataInPercentage", false);
      setUnitId(null);
    } else {
      handleUpdateFormValue("unitOfMeasure", option ? option.description : "");
      handleUpdateFormValue("showDataInPercentage", false);
      setUnitId(option ? option.value : "");
    }
  };

  const handleGaugeRangesChange = (
    key: "gaugeMinValue" | "gaugeMaxValue",
    value: number
  ) => {
    const isMinValue = key === "gaugeMinValue";
    const limit = isMinValue ? gaugeDataMinValue : gaugeDataMaxValue;
    const errorKey = `${key}Error`;
    const message = getGaugeWidgetAlertMessage(
      language,
      isMinValue,
      limit,
      gaugeDataMaxValue
    );

    if (
      (isMinValue && value < limit) ||
      value > gaugeDataMaxValue ||
      (!isMinValue && value > limit)
    ) {
      setValueErrors((prev) => ({ ...prev, [errorKey]: true }));
      setAlertMessages((prev) => [...prev, message]);
    } else {
      setValueErrors((prev) => ({ ...prev, [errorKey]: false }));
      setAlertMessages((prev) => prev.filter((msg) => msg !== message));
      handleUpdateFormValue(key, value);
    }
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12} sm={Number(config.sections) !== 1 ? 6 : 9}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select
            selectOptions={chartSectionsNumberShemeOptions}
            name="sections"
            label={t("Number of Sections")}
            onChange={(e) => handleUpdateFormValue("sections", e.target.value)}
            value={config.sections}
          />
        </Grid>

        {Number(config.sections) !== 1 ? (
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label={t(`Gauge Min Value`)}
              numberField
              value={config.gaugeMinValue || ""}
              onChange={(e) =>
                handleGaugeRangesChange("gaugeMinValue", Number(e.target.value))
              }
              error={valueErrors["gaugeMinValueError"]}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={12}>
          <Typography style={theme.customizedTextStyles.labelStyles}>
            {t("The gauge will display values between")} {gaugeDataMinValue} {t("and")}{" "}
            {gaugeDataMaxValue}
          </Typography>
        </Grid>
        {Number(config.sections) === 1 ? (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t(`Gauge Min Value`)}
                numberField
                value={config.gaugeMinValue || ""}
                onChange={(e) =>
                  handleGaugeRangesChange("gaugeMinValue", Number(e.target.value))
                }
                error={valueErrors["gaugeMinValueError"]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={t(`Gauge Max Value`)}
                numberField
                value={gaugeDataMaxValue || ""}
                onChange={(e) =>
                  handleGaugeRangesChange("gaugeMaxValue", Number(e.target.value))
                }
                error={valueErrors["gaugeMaxValueError"]}
              />
            </Grid>
          </>
        ) : null}

        <GaugeWidgetSectionsRangeConfig
          config={config}
          setUpdatedConfig={setUpdatedConfig}
          handleUpdateFormValue={handleUpdateFormValue}
          gaugeDataMaxValue={gaugeDataMaxValue}
          gaugeDataMinValue={gaugeDataMinValue}
          valueErrors={valueErrors}
          setValueErrors={setValueErrors}
          setAlertMessages={setAlertMessages}
        />

        <Grid item xs={12} sm={12}>
          <Alert
            message={alertMessages.length > 0 ? alertMessages[0] : ""}
            showAlert={alertMessages.length > 0}
            severity={"info"}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Select
            selectOptions={gaugeChartColorSchemeOptions}
            name="colors"
            label={t("Colors")}
            onChange={(e) => handleUpdateFormValue("colors", e.target.value)}
            value={config.colors}
          />
        </Grid>
        {config.colors === "Custom set" ? (
          <Grid item xs={12} sm={6}>
            <Select
              selectOptions={colorSequenceOptions}
              name="colorsSequence"
              label={t("Colors Sequence")}
              onChange={(e) => handleUpdateFormValue("colorsSequence", e.target.value)}
              value={config.colorsSequence}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} />
        )}
        <Grid item xs={12} sm={6}>
          <UnitsOfMeasureDropdown
            targetUnitValue={unitId}
            handleOnUnitsChange={handleUnitChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Calculate data in percentage")}
            name="showDataInPercentage"
            defaultChecked={config.showDataInPercentage}
            onChange={(e) =>
              handleUpdateFormValue("showDataInPercentage", e.target.checked)
            }
            checked={config.showDataInPercentage}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable arc labels")}
            name="enableArcLabels"
            defaultChecked={config.enableArcLabels}
            onChange={(e) => handleUpdateFormValue("enableArcLabels", e.target.checked)}
            checked={config.enableArcLabels}
          />
        </Grid>
        <Grid item xs={12} sm={6} mb={3}>
          <Checkbox
            label={t("Enable arc link labels")}
            name="enableArcLinkLabels"
            defaultChecked={config.enableArcLinkLabels}
            onChange={(e) =>
              handleUpdateFormValue("enableArcLinkLabels", e.target.checked)
            }
            checked={config.enableArcLinkLabels}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditGaugeWidgetConfig;
