import { Box, Typography } from "@mui/material";
import { css } from "@emotion/react";

const cssStyles = {
  logoText: css({
    fontWeight: "900",
    fontSize: "1.6rem",
    color: "#247e85",
  }),
};

const Logo: React.FC = () => {
  const styles = { ...cssStyles };

  return (
    <Box component="div">
      <img
        src="/logo-2.png"
        alt="Giant"
        width="23"
        style={{ verticalAlign: "text-bottom" }}
      />
      <Typography css={styles.logoText} component="span">
        &nbsp;&nbsp;evniko GIANT
      </Typography>
    </Box>
  );
};

export default Logo;
