import { Box, Stack, Typography, useTheme } from "@mui/material";
import { getChartColors } from "../../../ExcellenceWidgets/nivoTheme";

const PredictiveMaintenanceCustomLegend: React.FC = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 1 }} component="div">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: theme.palette.error.main,
              borderRadius: "50%",
            }}
            component="div"
          />
          <Typography variant="body2">Confidence Interval</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            sx={{
              width: 12,
              height: 12,
              backgroundColor: getChartColors()[0],
              borderRadius: "50%",
            }}
            component="div"
          />
          <Typography variant="body2">Prediction</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default PredictiveMaintenanceCustomLegend;
