import { TrendDirection } from "../IndicatorWidget/indicatorWidgetUtils";
import { IndicatorWidgetConfiguration } from "../IndicatorWidget/indicatorWidgetTypes";

const indicatorWidgetDefaultConfiguration: IndicatorWidgetConfiguration = {
  positiveDirection: TrendDirection.UP,
  valueType: "Average",
  title: "Indicator Title",
  subtitle: "Subtitle",
  unitsOfMeasure: "",
  symbol: "",
};

export default indicatorWidgetDefaultConfiguration;
