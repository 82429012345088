import { IconButton, Stack, Typography } from "@mui/material";
import { css } from "@emotion/react";
import AddchartOutlinedIcon from "@mui/icons-material/AddchartOutlined";
import AlarmAddOutlinedIcon from "@mui/icons-material/AlarmAddOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import RadarOutlinedIcon from "@mui/icons-material/RadarOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import LegendToggleOutlinedIcon from "@mui/icons-material/LegendToggleOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import DonutSmallOutlinedIcon from "@mui/icons-material/DonutSmallOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import OneKPlusOutlinedIcon from "@mui/icons-material/OneKPlusOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import MapIcon from "@mui/icons-material/Map";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import StreamIcon from "@mui/icons-material/Stream";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import Brightness5Icon from "@mui/icons-material/Brightness5";
import GrainIcon from "@mui/icons-material/Grain";
import EventNoteIcon from "@mui/icons-material/EventNote";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { ExcellenceChartKeyType } from "./excellenceUtils";
import { useLanguageContext } from "../../../context/LanguageContext";

const cssStyles = {
  barChartIcon: css({
    transform: "rotate(90deg)",
  }),
};

interface AddExcellenceChartIconButtonProps {
  type: ExcellenceChartKeyType;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}

const AddExcellenceChartIconButton: React.FC<AddExcellenceChartIconButtonProps> = ({
  type,
  onClick,
  disabled,
}) => {
  const styles = { ...cssStyles };
  const { t } = useLanguageContext();

  switch (type) {
    // Excellence widgets
    case "lineChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="line chart" onClick={onClick} disabled={disabled}>
            <AddchartOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Line Chart")}
          </Typography>
        </Stack>
      );
    }

    case "timeChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="time chart" onClick={onClick} disabled={disabled}>
            <AlarmAddOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Time Chart")}
          </Typography>
        </Stack>
      );
    }

    case "barChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="bar chart" onClick={onClick} disabled={disabled}>
            <BarChartOutlinedIcon css={styles.barChartIcon} />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Bar Chart")}
          </Typography>
        </Stack>
      );
    }

    case "radarChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="radar chart" onClick={onClick} disabled={disabled}>
            <RadarOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Radar Chart")}
          </Typography>
        </Stack>
      );
    }

    case "columnChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="column chart" onClick={onClick} disabled={disabled}>
            <LeaderboardOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Column Chart")}
          </Typography>
        </Stack>
      );
    }

    case "areaChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="area chart" onClick={onClick} disabled={disabled}>
            <LegendToggleOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Area Chart")}
          </Typography>
        </Stack>
      );
    }

    case "pieChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="pie chart" onClick={onClick} disabled={disabled}>
            <PieChartOutlineOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t(`Pie
              Chart`)}
          </Typography>
        </Stack>
      );
    }

    case "donutChart": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="donut chart" onClick={onClick} disabled={disabled}>
            <DonutSmallOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t(`Donut
            Chart`)}
          </Typography>
        </Stack>
      );
    }

    case "table": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="table" onClick={onClick} disabled={disabled}>
            <TableChartOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Table")}
          </Typography>
        </Stack>
      );
    }

    case "gaugeWidget": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="gauge" onClick={onClick} disabled={disabled}>
            <SpeedOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Gauge")}
          </Typography>
        </Stack>
      );
    }

    case "textWidget": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="text" onClick={onClick} disabled={disabled}>
            <ArticleOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Text")}
          </Typography>
        </Stack>
      );
    }

    case "indicatorWidget": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="indicator" onClick={onClick} disabled={disabled}>
            <OneKPlusOutlinedIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Indicator")}
          </Typography>
        </Stack>
      );
    }

    // Advanced widgets
    case "boxPlot": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="box plot" onClick={onClick} disabled={disabled}>
            <CheckBoxOutlineBlankIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Box Plot")}
          </Typography>
        </Stack>
      );
    }

    case "heatMap": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="heat map" onClick={onClick} disabled={disabled}>
            <MapIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Heat Map")}
          </Typography>
        </Stack>
      );
    }

    case "calendar": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="calendar" onClick={onClick} disabled={disabled}>
            <CalendarMonthIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Calendar")}
          </Typography>
        </Stack>
      );
    }

    case "funnel": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="funnel" onClick={onClick} disabled={disabled}>
            <FilterAltIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Funnel")}
          </Typography>
        </Stack>
      );
    }

    case "network": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="network" onClick={onClick} disabled={disabled}>
            <StreamIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Network")}
          </Typography>
        </Stack>
      );
    }

    case "sankey": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="sankey" onClick={onClick} disabled={disabled}>
            <BlurLinearIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Sankey")}
          </Typography>
        </Stack>
      );
    }

    case "scatterPlot": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="scatter plot" onClick={onClick} disabled={disabled}>
            <ScatterPlotIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Scatter Plot")}
          </Typography>
        </Stack>
      );
    }

    case "sunburst": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="sunburst" onClick={onClick} disabled={disabled}>
            <Brightness5Icon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Sunburst")}
          </Typography>
        </Stack>
      );
    }

    case "swarmPlot": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="swarm plot" onClick={onClick} disabled={disabled}>
            <GrainIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Swarm Plot")}
          </Typography>
        </Stack>
      );
    }

    case "timeRange": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="time range" onClick={onClick} disabled={disabled}>
            <EventNoteIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Time Range")}
          </Typography>
        </Stack>
      );
    }

    case "treeMap": {
      return (
        <Stack alignItems="center">
          <IconButton aria-label="tree map" onClick={onClick} disabled={disabled}>
            <AccountTreeIcon />
          </IconButton>
          <Typography
            variant="caption"
            align="center"
            whiteSpace="pre-line"
            color={disabled ? "textSecondary" : "textPrimary"}
          >
            {t("Tree Map")}
          </Typography>
        </Stack>
      );
    }
  }
};

export default AddExcellenceChartIconButton;
