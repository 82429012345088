import {
  Box,
  Breadcrumbs,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { TreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useEffect, useMemo, useState } from "react";
import Button from "../../MaterialUI/Button";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import TextField from "../../MaterialUI/FormFields/TextFields";
import SearchIcon from "@mui/icons-material/Search";
import {
  CollapsibleTreeItemData,
  CollapsibleTreeItemUiValues,
} from "./collapsibleTreeTypes";
import RecursiveTreeItem from "./RecursiveTreeItem";
import { css } from "@emotion/react";
import cssComponentsStyles from "../../../Global/Styles/components";
import CloseIcon from "@mui/icons-material/Close";

const cssStyles = {
  tree: css({
    position: "relative",
    minHeight: 180,
    flexGrow: 1,
    width: "100%",
    overflowX: "auto",
  }),
  loadingContainer: css({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
  }),
};

const INITIAL_NODE_DEPTH = 3;

type TreeItemsMap = {
  [key: string]: {
    depth: number;
    parentID: string | null;
    data: CollapsibleTreeItemData;
    id: string;
  };
};

interface CollapsibleSelectTreeProps {
  data: (CollapsibleTreeItemData | null)[];
  selected: string[];
  setSelected: React.Dispatch<React.SetStateAction<string[]>>;
  disabled?: boolean;
  loading?: boolean;
  fetchNodesData?: (nodes: string[]) => Promise<void>;
  maxHeight?: number;
  noParentSelections?: boolean;
}

const CollapsibleSelectTree: React.FC<CollapsibleSelectTreeProps> = ({
  data: dateNodes,
  selected,
  setSelected,
  disabled,
  loading,
  fetchNodesData,
  maxHeight = 500,
  noParentSelections,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles,
    ...cssComponentsStyles(theme),
  };
  const uniqueNodes = useMemo(() => makeNodesUniquePerParent(dateNodes), [dateNodes]);

  // for current view
  const [expanded, setExpanded] = useState<string[]>([]);
  const [treeData, setTreeData] =
    useState<(CollapsibleTreeItemData | null)[]>(uniqueNodes);
  const [parentNodes, setParentNodes] = useState<string[] | null>(null);
  const [uiValues, setUiValues] = useState<CollapsibleTreeItemUiValues>({});

  // for all data
  const [nodeDepth, setNodeDepth] = useState<number>(INITIAL_NODE_DEPTH);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [stateSelected, setStateSelected] = useState<string[]>([]);
  const [parentsSelected, setParentsSelected] = useState<string[]>([]);

  const treeDepthMap = useMemo(() => getTreeMap(uniqueNodes, 1, null), [uniqueNodes]);
  const idsMap = useMemo(() => idToUniqueIdMap(uniqueNodes), [uniqueNodes]);

  useEffect(() => {
    if (noParentSelections) {
      const allParentsSet = new Set<string>();
      // 1. get all unique parents for each selected node
      stateSelected.forEach((nodeID) => {
        const allParents = getNodeIDWithParents(nodeID, treeDepthMap);
        allParents.forEach((parent) => {
          allParentsSet.add(parent);
        });
      });

      // 2. find if all children for this parent are selected
      const allParentsArr = Array.from(allParentsSet);
      const parentsToSelect: string[] = [];

      allParentsArr.forEach((parentNode) => {
        const nodeChildren = treeDepthMap[parentNode]?.data?.children || [];
        const childrenIDs = nodeChildren.map((child) => child?.uniqueID);

        if (childrenIDs?.length) {
          const allChildrenSelected = childrenIDs.every(
            (child) => child && stateSelected.includes(child)
          );

          if (allChildrenSelected) {
            parentsToSelect.push(parentNode);
          }
        }
      });
      setParentsSelected(parentsToSelect);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected, noParentSelections]);

  useEffect(() => {
    const uniqueNodesSet = new Set<string>();

    selected.forEach((item) => {
      const identicalNodes = idsMap[item];
      identicalNodes.forEach((uniqueID) => {
        uniqueNodesSet.add(uniqueID);
      });
    });

    const uniqueNodesArr = Array.from(uniqueNodesSet);
    setStateSelected(uniqueNodesArr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  useEffect(() => {
    setTreeData(uniqueNodes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateNodes]);

  useEffect(() => {
    if (!loading) {
      setUiValues((prev) => {
        // 1. find all indeterminate nodes
        const allIndeterminate: string[] = [];
        stateSelected.forEach((item) => {
          const allParents = getNodeIDWithParents(item, treeDepthMap);
          allParents.forEach((parent) => {
            if (!stateSelected.includes(parent)) {
              allIndeterminate.push(parent);
            }
          });
        });

        // 2. set previous flags for indeterminate to false
        const formattedPrev = Object.keys(prev).reduce((acc, curr) => {
          const prevCurr = prev[curr] || {};

          return {
            ...acc,
            [curr]: {
              ...prevCurr,
              indeterminate: false,
            },
          };
        }, prev);

        // 3. update indeterminate flags based on allIndeterminate array
        const newData = allIndeterminate.reduce((acc, curr) => {
          const prevCurr = formattedPrev[curr] || {};

          return {
            ...acc,
            [curr]: {
              ...prevCurr,
              indeterminate: true,
            },
          };
        }, formattedPrev);

        return {
          ...formattedPrev,
          ...newData,
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelected, loading]);

  const handleToggleExpand = async (nodeIds: string[], newDepth?: number) => {
    const depthToUse = newDepth ? newDepth - 1 : nodeDepth;
    const deepNode = nodeIds.find((item) => treeDepthMap[item]?.depth > depthToUse);

    if (deepNode) {
      const newTree = treeDepthMap[deepNode].data;
      const allParentIDs = getNodeIDWithParents(deepNode, treeDepthMap).reverse();

      setNodeDepth((prev) => (newDepth ? newDepth + 1 : prev + INITIAL_NODE_DEPTH));
      setParentNodes(allParentIDs);
      setExpanded([deepNode]);
      setTreeData([newTree]);
      return;
    }

    setExpanded(nodeIds);

    if (fetchNodesData) {
      const nodesToFetch = findNodesWithNullChildrenIds(treeData);

      await fetchNodesData(nodesToFetch);
    }
  };

  const handleSelect = (_: React.SyntheticEvent, nodeIds: string[]) => {
    const node = nodeIds[0];
    const nodesToUse = noParentSelections
      ? [...stateSelected, ...parentsSelected]
      : [...stateSelected];
    const nodeExists = nodesToUse.some((item) => item === node);
    let finalStateResult: string[] = [];

    if (nodeExists) {
      /**
       * Node is selected, so we must de-select all occurrences of it.
       * If node has parents, we must de-select all its parents.
       * Same for its children, if there are any, we de-select them.
       */

      /** Nodes with the same ID are considered identical nodes with
       * different locations. If one is de-selected, we must de-select all of them.
       */
      let deSelectedResult = [...nodesToUse];
      const nodeID = treeDepthMap[node].id;
      const identicalNodes = idsMap[nodeID];

      identicalNodes.forEach((uniqueNode) => {
        deSelectedResult = deSelectedResult.filter((item) => item !== uniqueNode);
        const allParents = getNodeIDWithParents(uniqueNode, treeDepthMap);
        const allChildren = getNodeWithAllChildren(uniqueNode, treeDepthMap);

        allParents.forEach((parent) => {
          deSelectedResult = deSelectedResult.filter((item) => item !== parent);
        });
        allChildren.forEach((child) => {
          deSelectedResult = deSelectedResult.filter((item) => item !== child);
        });
      });

      finalStateResult = deSelectedResult;
    } else {
      /**
       * Node is not selected, so we must select it.
       * If the node has children, we select them as well.
       * We must ensure that children which are already
       * selected are not de-selected. Also, if every child
       * for the node's parent are selected, also select the parent
       */
      const allChildren = getNodeWithAllChildren(node, treeDepthMap);
      let updatedStateSelected = [...stateSelected, node];

      allChildren.forEach((child) => {
        const childExists = updatedStateSelected.some((item) => item === child);
        if (!childExists) {
          updatedStateSelected.push(child);
        }
      });

      // auto-select parents if all children have been selected
      const allParents = getNodeIDWithParents(node, treeDepthMap);

      allParents.forEach((parent) => {
        const parentChildren = treeDepthMap[parent]?.data?.children;

        const allChildrenSelected = parentChildren?.every((child) => {
          if (child?.uniqueID) {
            return updatedStateSelected.includes(child.uniqueID);
          }
          return false;
        });

        if (allChildrenSelected) {
          updatedStateSelected.push(parent);
        }
      });

      /** Nodes with the same ID are considered identical nodes with
       * different locations. If one is selected, we must select all of them.
       */
      const selectedNodeIDs = updatedStateSelected.map((item) => treeDepthMap[item].id);
      const allOccurrencesSet = new Set<string>();

      selectedNodeIDs.forEach((item) => {
        const identicalNodes = idsMap[item];
        identicalNodes.forEach((uniqueID) => {
          allOccurrencesSet.add(uniqueID);
        });
      });

      const allOccurrencesArr = Array.from(allOccurrencesSet);

      if (noParentSelections) {
        // pop out all nodes which are parents
        finalStateResult = allOccurrencesArr.filter((nodeID) => {
          const hasChildren = treeDepthMap[nodeID]?.data?.children?.length;
          return !hasChildren;
        });
      } else {
        finalStateResult = allOccurrencesArr;
      }
    }

    /**
     * Update the state values here. We don't update the
     * stateSelect value because it is updated in the useEffect,
     * which depends on the prop selected value
     */
    const nodeIDsSet = new Set<string>();
    finalStateResult.forEach((item) => {
      nodeIDsSet.add(treeDepthMap[item].id);
    });
    const nodeIDsSetArr = Array.from(nodeIDsSet);

    setSelected(nodeIDsSetArr);
  };

  const handleBreadcrumbClick = (id: string) => {
    const idDepth = treeDepthMap[id].depth;
    const surfaceNode = idDepth <= INITIAL_NODE_DEPTH;
    const allParents = getNodeIDWithParents(id, treeDepthMap);

    if (!surfaceNode) {
      handleToggleExpand([id], idDepth);
      return;
    }
    //code here: depth <= initial depth => return initial tree

    setNodeDepth(INITIAL_NODE_DEPTH);
    setParentNodes(null);
    setExpanded(allParents.reverse());
    setTreeData(uniqueNodes);
  };

  const handleSearch = () => {
    if (!searchQuery) {
      handleReset();
      return;
    }

    const matchedTree = filterTreeByLabel(uniqueNodes, searchQuery);
    const nodesWithDepth = getNodesWithDepthArr(matchedTree, treeDepthMap);
    const matchedNodeIDs = getMatchedNodeIDs(matchedTree, searchQuery);

    const findHighestDepth = nodesWithDepth.reduce((highestDepth, node) => {
      return node.depth > highestDepth ? node.depth : highestDepth;
    }, 0);

    setExpanded(() => nodesWithDepth.map((node) => node.uniqueID));
    setNodeDepth(findHighestDepth + 1);
    setTreeData(matchedTree);
    setParentNodes(null);
    setUiValues((prev) => {
      const newData = matchedNodeIDs.reduce((acc, curr) => {
        const prevCurr = prev[curr] || {};

        return {
          ...acc,
          [curr]: {
            ...prevCurr,
            highlighted: true,
          },
        };
      }, prev);

      return {
        ...prev,
        ...newData,
      };
    });
  };

  const handleReset = () => {
    setTreeData(uniqueNodes);
    setNodeDepth(INITIAL_NODE_DEPTH);
    setExpanded([]);
    setParentNodes(null);
    setSearchQuery("");
    setUiValues((prev) => {
      const result = Object.keys(prev).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: {
            ...acc[curr],
            highlighted: false,
          },
        };
      }, prev);

      return result;
    });
  };

  return (
    <Box component="div" css={styles.tree}>
      {loading ? (
        <Box component="div" css={styles.loadingContainer}>
          <CircularProgress size={75} />
        </Box>
      ) : null}

      {parentNodes && treeData ? (
        <Breadcrumbs css={styles.labelBreak} aria-label="breadcrumb">
          {parentNodes.map((node, index) => (
            <Button
              key={node}
              variant="text"
              onClick={() => handleBreadcrumbClick(node)}
              disabled={index === parentNodes.length - 1}
            >
              {treeDepthMap[node].data.label}
            </Button>
          ))}
        </Breadcrumbs>
      ) : null}

      <Stack css={styles.contentBreak} direction="row" spacing={3}>
        <TextField
          label="Search"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          type="search"
        />
        <Stack direction="row" spacing={2.5} alignItems="center">
          <IconButton
            css={styles.iconButton}
            size="small"
            onClick={handleSearch}
            disabled={!searchQuery}
          >
            <Tooltip title="Search" enterDelay={500}>
              <SearchIcon />
            </Tooltip>
          </IconButton>
          <IconButton css={styles.iconButton} size="small" onClick={handleReset}>
            <Tooltip title="Clear search" enterDelay={500}>
              <CloseIcon />
            </Tooltip>
          </IconButton>
        </Stack>
      </Stack>

      {searchQuery && !treeData.length ? (
        <Typography variant="h4">No data found with this search query</Typography>
      ) : null}

      <Box component="div" style={{ maxHeight: `${maxHeight}px`, overflowY: "auto" }}>
        <TreeView
          aria-label="icon expansion"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          multiSelect
          selected={
            noParentSelections ? [...stateSelected, ...parentsSelected] : stateSelected
          }
          expanded={expanded}
          onNodeToggle={(_, nodeIds) => handleToggleExpand(nodeIds)}
          onNodeSelect={handleSelect}
        >
          {treeData.map((item, index) => (
            <RecursiveTreeItem
              key={`root-${index}`}
              data={item}
              disabled={disabled}
              uiValues={uiValues}
            />
          ))}
        </TreeView>
      </Box>
    </Box>
  );
};

export default CollapsibleSelectTree;

/** Creates a map with tree item data, depth, parentID */
const getTreeMap = (
  data: (CollapsibleTreeItemData | null)[],
  depth: number,
  parentID: string | null
): TreeItemsMap => {
  const removeNulls = data.filter(
    (node) => !!node?.uniqueID
  ) as CollapsibleTreeItemData[];

  const result = removeNulls.reduce((acc, curr) => {
    const children = curr.children
      ? getTreeMap(curr.children, depth + 1, curr.uniqueID)
      : {};

    return {
      ...acc,
      [curr.uniqueID]: {
        depth,
        data: curr,
        parentID,
        id: curr.id,
      },
      ...children,
    };
  }, {} as TreeItemsMap);

  return result;
};

/** Returns the node and all its parents  */
const getNodeIDWithParents = (nodeID: string, treeMap: TreeItemsMap): string[] => {
  const parentNode = treeMap[nodeID];

  if (!parentNode) {
    // Handle the case where nodeID is not found in the treeMap
    return [];
  }

  const parentID = parentNode.parentID;
  const allParents: string[] = [nodeID]; // Include the current node ID in the result

  if (parentID) {
    allParents.push(...getNodeIDWithParents(parentID, treeMap));
  }
  return allParents;
};

/** Returns the node and all its children */
const getNodeWithAllChildren = (nodeID: string, treeMap: TreeItemsMap) => {
  const node = treeMap[nodeID];

  if (!node) {
    // node is not found
    return [];
  }

  const nodeChildren = node.data?.children;
  const result: string[] = [nodeID]; // Include the current node ID in the result

  if (nodeChildren?.length) {
    const removeNulls = nodeChildren.filter(
      (node) => !!node?.uniqueID
    ) as CollapsibleTreeItemData[];

    removeNulls.forEach((node) => {
      result.push(...getNodeWithAllChildren(node.uniqueID, treeMap));
    });
  }

  return result;
};

/** Returns a filtered tree based on the search query */
const filterTreeByLabel = (
  treeData: (CollapsibleTreeItemData | null)[],
  searchQuery: string
): CollapsibleTreeItemData[] => {
  const filterNode = (
    node: CollapsibleTreeItemData | null
  ): CollapsibleTreeItemData | null => {
    if (!node?.uniqueID) {
      return null;
    }
    // Check if the node's label includes the search query
    const isMatch = node.label.toLowerCase().includes(searchQuery.toLowerCase());

    const removeNulls = node.children?.filter(
      (node) => !!node?.uniqueID
    ) as CollapsibleTreeItemData[];

    // If the node has children, filter them recursively
    const filteredChildren = removeNulls
      ? removeNulls.map((child) => filterNode(child)).filter((item) => !!item?.uniqueID)
      : undefined;

    // Include the node in the result if it matches the search query or has matching children
    if (isMatch || (filteredChildren && filteredChildren.length > 0)) {
      return { ...node, children: filteredChildren as CollapsibleTreeItemData[] };
    }

    return null;
  };

  // Start filtering from the root nodes
  return treeData
    .map((rootNode) => filterNode(rootNode))
    .filter((node) => !!node) as CollapsibleTreeItemData[];
};

type NodesWithDepth = {
  uniqueID: string;
  depth: number;
}[];

/** Returns an array with depth and node IDs */
const getNodesWithDepthArr = (
  data: CollapsibleTreeItemData[],
  treeMap: TreeItemsMap
): NodesWithDepth => {
  const result: NodesWithDepth = [];

  const traverseTree = (node: CollapsibleTreeItemData) => {
    result.push({ uniqueID: node.uniqueID, depth: treeMap[node.uniqueID].depth });

    if (node.children) {
      const removeNulls = node.children?.filter(
        (node) => !!node?.uniqueID
      ) as CollapsibleTreeItemData[];

      removeNulls.forEach((child) => traverseTree(child));
    }
  };

  data.forEach((rootNode) => traverseTree(rootNode));

  return result;
};

/** Returns all provided nodeIDs that have a child === null */
function findNodesWithNullChildrenIds(
  data: (CollapsibleTreeItemData | null)[]
): string[] {
  const result: string[] = [];

  function traverse(node: CollapsibleTreeItemData) {
    if (node.children && node.children.some((child) => child === null)) {
      result.push(node.uniqueID);
    }

    if (node.children && node.children.length > 0) {
      for (const child of node.children) {
        if (child !== null && typeof child === "object") {
          traverse(child);
        }
      }
    }
  }

  for (const item of data) {
    if (item !== null && typeof item === "object") {
      traverse(item);
    }
  }

  return result;
}

/** Returns all nodeIDs that match the search query */
const getMatchedNodeIDs = (
  tree: CollapsibleTreeItemData[],
  searchQuery: string
): string[] => {
  const matchedNodeIDs: string[] = [];

  const traverseTree = (node: CollapsibleTreeItemData) => {
    const isMatch = node.label.toLowerCase().includes(searchQuery.toLowerCase());

    if (isMatch) {
      matchedNodeIDs.push(node.uniqueID);
    }

    if (node.children) {
      for (const child of node.children) {
        if (child) {
          traverseTree(child);
        }
      }
    }
  };

  for (const node of tree) {
    traverseTree(node);
  }

  return matchedNodeIDs;
};

/** makes every node unique per parent by attaching the parentID
 * to the child using "." notation and saved under uniqueID
 */
const makeNodesUniquePerParent = (data: (CollapsibleTreeItemData | null)[]) => {
  const allUniqueNodes: (CollapsibleTreeItemData | null)[] = [];

  const makeNodeChildrenUnique = (
    node: CollapsibleTreeItemData | null,
    parentIds: string[] = []
  ): CollapsibleTreeItemData | null => {
    const currentId = node?.uniqueID
      ? [...parentIds, node.uniqueID].join(".")
      : undefined;

    if (node?.children) {
      const childrenResult: (CollapsibleTreeItemData | null)[] = [];

      node.children.forEach((child) => {
        const uniqueChild = makeNodeChildrenUnique(
          child,
          currentId ? [...parentIds, node.uniqueID] : parentIds
        );

        if (uniqueChild?.uniqueID) {
          childrenResult.push({
            ...uniqueChild,
            uniqueID: `${currentId}.${uniqueChild.uniqueID}`,
          });
        } else {
          childrenResult.push(uniqueChild);
        }
      });

      return { ...node, uniqueID: `${currentId}`, children: childrenResult };
    }

    if (node?.uniqueID) {
      return { ...node, uniqueID: `${currentId}` };
    }
    return null;
  };

  data.forEach((node) => {
    const uniqueNode = makeNodeChildrenUnique(node);
    allUniqueNodes.push(uniqueNode);
  });

  return allUniqueNodes;
};

/** maps node.id to node.uniqueID */
const idToUniqueIdMap = (
  data: (CollapsibleTreeItemData | null)[]
): Record<string, string[]> => {
  const removeNulls = data.filter(
    (node) => !!node?.uniqueID
  ) as CollapsibleTreeItemData[];

  const result = removeNulls.reduce((acc, curr) => {
    const childrenResult: Record<string, string[]> = {};

    if (curr.children) {
      // Recursively get values from children
      curr.children.forEach((child) => {
        const childResult = idToUniqueIdMap(child ? [child] : []);
        Object.keys(childResult).forEach((childKey) => {
          if (childrenResult[childKey]) {
            childrenResult[childKey] = [
              ...childrenResult[childKey],
              ...childResult[childKey],
            ];
          } else {
            childrenResult[childKey] = [...childResult[childKey]];
          }
        });
      });
    }

    // Handle the current node
    const nodeID = curr.id;
    if (acc[nodeID]) {
      acc[nodeID] = [...acc[nodeID], curr.uniqueID];
    } else {
      acc[nodeID] = [curr.uniqueID];
    }

    return {
      ...acc,
      ...childrenResult,
    };
  }, {} as Record<string, string[]>);

  return result;
};
