const gaugeWidgetDefaultData = {
  currentValue: 20,
  rangeData: [
    {
      id: "cold",
      label: "Cold",
      value: 1,
      index: 0,
      averageValue: 4.5,
    },
    {
      id: "cold",
      label: "Cold",
      value: 10,
      index: 1,
      averageValue: 15,
    },
    {
      id: "warm",
      label: "Warm",
      value: 20,
      index: 2,
      averageValue: 25,
    },
    {
      id: "hot",
      label: "Hot",
      value: 30,
      index: 3,
      averageValue: 35,
    },
    {
      id: "extreme",
      label: "Extreme",
      value: 40,
      index: 4,
      averageValue: 45,
    },
    {
      id: "hot",
      label: "Hot",
      value: 50,
      index: 5,
      averageValue: 55,
    },
    {
      id: "extreme",
      label: "Extreme",
      value: 60,
      index: 6,
      averageValue: 65,
    },
    {
      id: "extreme",
      label: "Extreme",
      value: 70,
      index: 7,
      averageValue: 75,
    },
    {
      id: "extreme",
      label: "Extreme",
      value: 80,
      index: 8,
      averageValue: 85,
    },
    {
      id: "extreme",
      label: "Extreme",
      value: 90,
      index: 9,
      averageValue: 95,
    },
    {
      id: "extreme",
      label: "Extreme",
      value: 100,
      index: 10,
      averageValue: 95,
    },
  ],
};

export default gaugeWidgetDefaultData;
