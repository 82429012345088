import { Query } from "../../../Api/callApi";
import { PostQueryOrderInput } from "./apiOrdersInputs";

export const postQueryOrderAddNew = (code: string, quantity: number): Query => ({
  endpoint: "project_management/project-new",
  method: "POST",
  variables: {
    product_code: code,
    product_family: "Rotary Shaft",
    quantity,
  },
});

export const postQueryOrder = (input: PostQueryOrderInput): Query => ({
  endpoint: "order_management/orders",
  method: "POST",
  variables: input,
});

export const getQueryOrdersBusinessUnits = (): Query => ({
  endpoint: "order_management/business_units",
  method: "GET",
});

export const getQueryFetchAllOrders = (): Query => ({
  endpoint: "order_management/orders/fetch-all",
  method: "GET",
});

export const getQueryOrderNumber = (): Query => ({
  endpoint: "order_management/order/number",
  method: "GET",
});

export const getQueryOrderDetails = (): Query => ({
  endpoint: "order_management/orders/details",
  method: "GET",
});
