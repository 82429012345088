import { Box, Grid } from "@mui/material";
import { ChartConfigFormValuesWithTitle } from "../../EditExcellenceChartForms/excellenceChartFormUtils";
import { BoxPlotConfig } from "./boxPlotTypes";
import Checkbox from "../../../MaterialUI/FormFields/Checkbox";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import { useLanguageContext } from "../../../../context/LanguageContext";

type KeyOfConfigValue = keyof ChartConfigFormValuesWithTitle<BoxPlotConfig>;

interface EditBoxPlotConfigProps {
  config: ChartConfigFormValuesWithTitle<BoxPlotConfig>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<BoxPlotConfig>>
  >;
}

const EditBoxPlotConfig: React.FC<EditBoxPlotConfigProps> = ({
  config,
  setUpdatedConfig,
}) => {
  const { t } = useLanguageContext();
  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string | boolean) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Checkbox
            label="Fixed Decimal Value"
            defaultChecked={!!config.fixedDecimalValue}
            onChange={(e) => handleUpdateFormValue("fixedDecimalValue", e.target.checked)}
            value={!!config.fixedDecimalValue}
          />
        </Grid>
        {config.fixedDecimalValue ? (
          <Grid item xs={12} sm={6}>
            <TextField
              label="Decimal Points"
              onChange={(e) => handleUpdateFormValue("decimalPoints", e.target.value)}
              value={config.decimalPoints === undefined ? 0 : config.decimalPoints}
              numberField
              noThousandSeparator
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} />
        )} */}
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Manual Bottom Label")}
            name="manualBottomLabel"
            defaultChecked={!!config.manualBottomLabel}
            onChange={(e) => handleUpdateFormValue("manualBottomLabel", e.target.checked)}
            value={!!config.manualBottomLabel}
          />
        </Grid>
        {config.manualBottomLabel ? (
          <Grid item xs={12} sm={6}>
            <TextField
              name="bottomLegend"
              label={t("Bottom Label")}
              onChange={(e) => handleUpdateFormValue("bottomLegend", e.target.value)}
              value={config.bottomLegend}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default EditBoxPlotConfig;
