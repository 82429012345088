import { SwarmPlotData } from "../SwarmPlot/swarmPlotTypes";

const swarmPlotDefaultData: SwarmPlotData = [
  {
    id: "0.0",
    group: "group A",
    price: 482,
    volume: 11,
  },
  {
    id: "0.1",
    group: "group B",
    price: 181,
    volume: 16,
  },
  {
    id: "0.2",
    group: "group A",
    price: 334,
    volume: 11,
  },
  {
    id: "0.3",
    group: "group A",
    price: 450,
    volume: 13,
  },
  {
    id: "0.4",
    group: "group A",
    price: 99,
    volume: 19,
  },
  {
    id: "0.5",
    group: "group C",
    price: 3,
    volume: 5,
  },
  {
    id: "0.6",
    group: "group C",
    price: 81,
    volume: 11,
  },
  {
    id: "0.7",
    group: "group B",
    price: 383,
    volume: 9,
  },
  {
    id: "0.8",
    group: "group C",
    price: 427,
    volume: 19,
  },
  {
    id: "0.9",
    group: "group A",
    price: 272,
    volume: 14,
  },
  {
    id: "0.10",
    group: "group C",
    price: 467,
    volume: 17,
  },
  {
    id: "0.11",
    group: "group B",
    price: 381,
    volume: 12,
  },
  {
    id: "0.12",
    group: "group A",
    price: 343,
    volume: 4,
  },
  {
    id: "0.13",
    group: "group A",
    price: 453,
    volume: 17,
  },
  {
    id: "0.14",
    group: "group A",
    price: 277,
    volume: 12,
  },
  {
    id: "0.15",
    group: "group C",
    price: 368,
    volume: 15,
  },
  {
    id: "0.16",
    group: "group C",
    price: 294,
    volume: 15,
  },
  {
    id: "0.17",
    group: "group B",
    price: 297,
    volume: 16,
  },
  {
    id: "0.18",
    group: "group C",
    price: 364,
    volume: 12,
  },
  {
    id: "0.19",
    group: "group A",
    price: 152,
    volume: 12,
  },
  {
    id: "0.20",
    group: "group A",
    price: 439,
    volume: 12,
  },
  {
    id: "0.21",
    group: "group B",
    price: 226,
    volume: 12,
  },
  {
    id: "0.22",
    group: "group B",
    price: 446,
    volume: 8,
  },
  {
    id: "0.23",
    group: "group A",
    price: 273,
    volume: 9,
  },
  {
    id: "0.24",
    group: "group A",
    price: 49,
    volume: 20,
  },
  {
    id: "0.25",
    group: "group A",
    price: 405,
    volume: 5,
  },
  {
    id: "0.26",
    group: "group C",
    price: 146,
    volume: 13,
  },
  {
    id: "0.27",
    group: "group A",
    price: 286,
    volume: 15,
  },
  {
    id: "0.28",
    group: "group C",
    price: 256,
    volume: 18,
  },
  {
    id: "0.29",
    group: "group C",
    price: 81,
    volume: 12,
  },
  {
    id: "0.30",
    group: "group A",
    price: 498,
    volume: 13,
  },
  {
    id: "0.31",
    group: "group B",
    price: 324,
    volume: 15,
  },
  {
    id: "0.32",
    group: "group A",
    price: 414,
    volume: 15,
  },
  {
    id: "0.33",
    group: "group C",
    price: 458,
    volume: 11,
  },
  {
    id: "0.34",
    group: "group B",
    price: 198,
    volume: 20,
  },
  {
    id: "0.35",
    group: "group A",
    price: 241,
    volume: 6,
  },
  {
    id: "0.36",
    group: "group C",
    price: 426,
    volume: 7,
  },
  {
    id: "0.37",
    group: "group C",
    price: 388,
    volume: 16,
  },
  {
    id: "0.38",
    group: "group A",
    price: 306,
    volume: 18,
  },
  {
    id: "0.39",
    group: "group B",
    price: 121,
    volume: 17,
  },
  {
    id: "0.40",
    group: "group B",
    price: 7,
    volume: 14,
  },
  {
    id: "0.41",
    group: "group C",
    price: 494,
    volume: 15,
  },
  {
    id: "0.42",
    group: "group B",
    price: 109,
    volume: 5,
  },
  {
    id: "0.43",
    group: "group C",
    price: 429,
    volume: 13,
  },
  {
    id: "0.44",
    group: "group A",
    price: 323,
    volume: 16,
  },
  {
    id: "0.45",
    group: "group C",
    price: 346,
    volume: 8,
  },
  {
    id: "0.46",
    group: "group C",
    price: 250,
    volume: 19,
  },
  {
    id: "0.47",
    group: "group A",
    price: 12,
    volume: 4,
  },
  {
    id: "0.48",
    group: "group B",
    price: 107,
    volume: 11,
  },
  {
    id: "0.49",
    group: "group B",
    price: 489,
    volume: 19,
  },
  {
    id: "0.50",
    group: "group C",
    price: 29,
    volume: 9,
  },
  {
    id: "0.51",
    group: "group A",
    price: 438,
    volume: 9,
  },
  {
    id: "0.52",
    group: "group C",
    price: 378,
    volume: 19,
  },
  {
    id: "0.53",
    group: "group B",
    price: 315,
    volume: 15,
  },
  {
    id: "0.54",
    group: "group C",
    price: 27,
    volume: 11,
  },
  {
    id: "0.55",
    group: "group B",
    price: 396,
    volume: 5,
  },
  {
    id: "0.56",
    group: "group B",
    price: 273,
    volume: 5,
  },
  {
    id: "0.57",
    group: "group B",
    price: 297,
    volume: 11,
  },
  {
    id: "0.58",
    group: "group B",
    price: 437,
    volume: 6,
  },
  {
    id: "0.59",
    group: "group A",
    price: 65,
    volume: 14,
  },
  {
    id: "0.60",
    group: "group C",
    price: 320,
    volume: 15,
  },
  {
    id: "0.61",
    group: "group C",
    price: 43,
    volume: 6,
  },
  {
    id: "0.62",
    group: "group B",
    price: 420,
    volume: 15,
  },
  {
    id: "0.63",
    group: "group A",
    price: 66,
    volume: 10,
  },
  {
    id: "0.64",
    group: "group C",
    price: 132,
    volume: 7,
  },
  {
    id: "0.65",
    group: "group B",
    price: 75,
    volume: 9,
  },
  {
    id: "0.66",
    group: "group A",
    price: 145,
    volume: 8,
  },
  {
    id: "0.67",
    group: "group B",
    price: 102,
    volume: 7,
  },
  {
    id: "0.68",
    group: "group C",
    price: 155,
    volume: 17,
  },
  {
    id: "0.69",
    group: "group B",
    price: 110,
    volume: 9,
  },
  {
    id: "0.70",
    group: "group A",
    price: 78,
    volume: 15,
  },
  {
    id: "0.71",
    group: "group A",
    price: 283,
    volume: 19,
  },
  {
    id: "0.72",
    group: "group B",
    price: 485,
    volume: 20,
  },
  {
    id: "0.73",
    group: "group A",
    price: 51,
    volume: 14,
  },
  {
    id: "0.74",
    group: "group A",
    price: 252,
    volume: 8,
  },
  {
    id: "0.75",
    group: "group A",
    price: 454,
    volume: 11,
  },
  {
    id: "0.76",
    group: "group A",
    price: 134,
    volume: 17,
  },
  {
    id: "0.77",
    group: "group B",
    price: 60,
    volume: 19,
  },
  {
    id: "0.78",
    group: "group C",
    price: 477,
    volume: 12,
  },
  {
    id: "0.79",
    group: "group A",
    price: 492,
    volume: 9,
  },
  {
    id: "1.0",
    group: "group A",
    price: 223,
    volume: 18,
  },
  {
    id: "1.1",
    group: "group B",
    price: 250,
    volume: 18,
  },
  {
    id: "1.2",
    group: "group B",
    price: 439,
    volume: 12,
  },
  {
    id: "1.3",
    group: "group B",
    price: 240,
    volume: 11,
  },
  {
    id: "1.4",
    group: "group A",
    price: 499,
    volume: 6,
  },
  {
    id: "1.5",
    group: "group C",
    price: 500,
    volume: 6,
  },
  {
    id: "1.6",
    group: "group B",
    price: 124,
    volume: 14,
  },
  {
    id: "1.7",
    group: "group A",
    price: 58,
    volume: 16,
  },
  {
    id: "1.8",
    group: "group B",
    price: 404,
    volume: 6,
  },
  {
    id: "1.9",
    group: "group B",
    price: 243,
    volume: 9,
  },
  {
    id: "1.10",
    group: "group C",
    price: 451,
    volume: 13,
  },
  {
    id: "1.11",
    group: "group C",
    price: 351,
    volume: 5,
  },
  {
    id: "1.12",
    group: "group C",
    price: 51,
    volume: 17,
  },
  {
    id: "1.13",
    group: "group A",
    price: 412,
    volume: 14,
  },
  {
    id: "1.14",
    group: "group A",
    price: 391,
    volume: 20,
  },
  {
    id: "1.15",
    group: "group B",
    price: 496,
    volume: 6,
  },
  {
    id: "1.16",
    group: "group C",
    price: 40,
    volume: 15,
  },
  {
    id: "1.17",
    group: "group B",
    price: 49,
    volume: 10,
  },
  {
    id: "1.18",
    group: "group C",
    price: 166,
    volume: 10,
  },
  {
    id: "1.19",
    group: "group A",
    price: 421,
    volume: 14,
  },
  {
    id: "1.20",
    group: "group A",
    price: 103,
    volume: 17,
  },
  {
    id: "1.21",
    group: "group A",
    price: 390,
    volume: 9,
  },
  {
    id: "1.22",
    group: "group B",
    price: 158,
    volume: 4,
  },
  {
    id: "1.23",
    group: "group C",
    price: 221,
    volume: 12,
  },
  {
    id: "1.24",
    group: "group A",
    price: 472,
    volume: 8,
  },
  {
    id: "1.25",
    group: "group A",
    price: 177,
    volume: 19,
  },
  {
    id: "1.26",
    group: "group B",
    price: 1,
    volume: 20,
  },
  {
    id: "1.27",
    group: "group C",
    price: 271,
    volume: 12,
  },
  {
    id: "1.28",
    group: "group A",
    price: 345,
    volume: 9,
  },
  {
    id: "1.29",
    group: "group B",
    price: 416,
    volume: 9,
  },
  {
    id: "1.30",
    group: "group B",
    price: 255,
    volume: 9,
  },
  {
    id: "1.31",
    group: "group B",
    price: 354,
    volume: 14,
  },
  {
    id: "1.32",
    group: "group C",
    price: 11,
    volume: 8,
  },
  {
    id: "1.33",
    group: "group C",
    price: 333,
    volume: 4,
  },
  {
    id: "1.34",
    group: "group B",
    price: 102,
    volume: 15,
  },
  {
    id: "1.35",
    group: "group C",
    price: 279,
    volume: 13,
  },
  {
    id: "1.36",
    group: "group B",
    price: 204,
    volume: 12,
  },
  {
    id: "1.37",
    group: "group C",
    price: 207,
    volume: 18,
  },
  {
    id: "1.38",
    group: "group A",
    price: 436,
    volume: 5,
  },
  {
    id: "1.39",
    group: "group C",
    price: 335,
    volume: 8,
  },
  {
    id: "1.40",
    group: "group C",
    price: 262,
    volume: 18,
  },
  {
    id: "1.41",
    group: "group B",
    price: 209,
    volume: 14,
  },
  {
    id: "1.42",
    group: "group B",
    price: 259,
    volume: 8,
  },
  {
    id: "1.43",
    group: "group B",
    price: 496,
    volume: 11,
  },
  {
    id: "1.44",
    group: "group A",
    price: 389,
    volume: 13,
  },
  {
    id: "1.45",
    group: "group A",
    price: 39,
    volume: 11,
  },
  {
    id: "1.46",
    group: "group A",
    price: 78,
    volume: 16,
  },
  {
    id: "1.47",
    group: "group B",
    price: 331,
    volume: 5,
  },
  {
    id: "1.48",
    group: "group B",
    price: 152,
    volume: 19,
  },
  {
    id: "1.49",
    group: "group A",
    price: 164,
    volume: 20,
  },
  {
    id: "1.50",
    group: "group A",
    price: 490,
    volume: 11,
  },
  {
    id: "1.51",
    group: "group C",
    price: 147,
    volume: 10,
  },
  {
    id: "1.52",
    group: "group A",
    price: 7,
    volume: 18,
  },
  {
    id: "1.53",
    group: "group B",
    price: 141,
    volume: 8,
  },
  {
    id: "1.54",
    group: "group C",
    price: 495,
    volume: 6,
  },
  {
    id: "1.55",
    group: "group C",
    price: 410,
    volume: 7,
  },
  {
    id: "1.56",
    group: "group C",
    price: 421,
    volume: 4,
  },
  {
    id: "1.57",
    group: "group A",
    price: 141,
    volume: 11,
  },
  {
    id: "1.58",
    group: "group B",
    price: 23,
    volume: 4,
  },
  {
    id: "1.59",
    group: "group B",
    price: 370,
    volume: 10,
  },
  {
    id: "1.60",
    group: "group B",
    price: 350,
    volume: 9,
  },
  {
    id: "1.61",
    group: "group B",
    price: 273,
    volume: 5,
  },
  {
    id: "1.62",
    group: "group B",
    price: 438,
    volume: 14,
  },
  {
    id: "1.63",
    group: "group B",
    price: 195,
    volume: 15,
  },
  {
    id: "1.64",
    group: "group B",
    price: 140,
    volume: 13,
  },
  {
    id: "1.65",
    group: "group B",
    price: 492,
    volume: 15,
  },
  {
    id: "1.66",
    group: "group C",
    price: 129,
    volume: 18,
  },
  {
    id: "1.67",
    group: "group B",
    price: 271,
    volume: 17,
  },
  {
    id: "1.68",
    group: "group C",
    price: 43,
    volume: 15,
  },
  {
    id: "1.69",
    group: "group A",
    price: 380,
    volume: 20,
  },
  {
    id: "1.70",
    group: "group A",
    price: 453,
    volume: 18,
  },
  {
    id: "1.71",
    group: "group C",
    price: 193,
    volume: 7,
  },
  {
    id: "1.72",
    group: "group C",
    price: 336,
    volume: 18,
  },
  {
    id: "1.73",
    group: "group B",
    price: 442,
    volume: 15,
  },
  {
    id: "2.0",
    group: "group B",
    price: 464,
    volume: 9,
  },
  {
    id: "2.1",
    group: "group B",
    price: 218,
    volume: 4,
  },
  {
    id: "2.2",
    group: "group A",
    price: 10,
    volume: 16,
  },
  {
    id: "2.3",
    group: "group C",
    price: 271,
    volume: 16,
  },
  {
    id: "2.4",
    group: "group A",
    price: 205,
    volume: 14,
  },
  {
    id: "2.5",
    group: "group C",
    price: 332,
    volume: 17,
  },
  {
    id: "2.6",
    group: "group C",
    price: 258,
    volume: 12,
  },
  {
    id: "2.7",
    group: "group A",
    price: 357,
    volume: 4,
  },
  {
    id: "2.8",
    group: "group C",
    price: 45,
    volume: 7,
  },
  {
    id: "2.9",
    group: "group B",
    price: 469,
    volume: 5,
  },
  {
    id: "2.10",
    group: "group B",
    price: 363,
    volume: 10,
  },
  {
    id: "2.11",
    group: "group B",
    price: 425,
    volume: 8,
  },
  {
    id: "2.12",
    group: "group A",
    price: 201,
    volume: 10,
  },
  {
    id: "2.13",
    group: "group B",
    price: 55,
    volume: 4,
  },
  {
    id: "2.14",
    group: "group C",
    price: 192,
    volume: 13,
  },
  {
    id: "2.15",
    group: "group A",
    price: 236,
    volume: 13,
  },
  {
    id: "2.16",
    group: "group B",
    price: 389,
    volume: 13,
  },
  {
    id: "2.17",
    group: "group C",
    price: 455,
    volume: 8,
  },
  {
    id: "2.18",
    group: "group A",
    price: 329,
    volume: 20,
  },
  {
    id: "2.19",
    group: "group A",
    price: 124,
    volume: 9,
  },
  {
    id: "2.20",
    group: "group C",
    price: 13,
    volume: 12,
  },
  {
    id: "2.21",
    group: "group C",
    price: 186,
    volume: 9,
  },
  {
    id: "2.22",
    group: "group B",
    price: 285,
    volume: 17,
  },
  {
    id: "2.23",
    group: "group A",
    price: 186,
    volume: 13,
  },
  {
    id: "2.24",
    group: "group C",
    price: 248,
    volume: 13,
  },
  {
    id: "2.25",
    group: "group C",
    price: 180,
    volume: 17,
  },
  {
    id: "2.26",
    group: "group C",
    price: 32,
    volume: 5,
  },
  {
    id: "2.27",
    group: "group B",
    price: 233,
    volume: 4,
  },
  {
    id: "2.28",
    group: "group B",
    price: 491,
    volume: 11,
  },
  {
    id: "2.29",
    group: "group B",
    price: 101,
    volume: 11,
  },
  {
    id: "2.30",
    group: "group C",
    price: 306,
    volume: 17,
  },
  {
    id: "2.31",
    group: "group A",
    price: 283,
    volume: 7,
  },
  {
    id: "2.32",
    group: "group A",
    price: 50,
    volume: 14,
  },
  {
    id: "2.33",
    group: "group B",
    price: 376,
    volume: 18,
  },
  {
    id: "2.34",
    group: "group A",
    price: 95,
    volume: 6,
  },
  {
    id: "2.35",
    group: "group A",
    price: 244,
    volume: 6,
  },
  {
    id: "2.36",
    group: "group C",
    price: 445,
    volume: 4,
  },
  {
    id: "2.37",
    group: "group B",
    price: 78,
    volume: 4,
  },
  {
    id: "2.38",
    group: "group A",
    price: 478,
    volume: 4,
  },
  {
    id: "2.39",
    group: "group B",
    price: 326,
    volume: 19,
  },
  {
    id: "2.40",
    group: "group C",
    price: 113,
    volume: 8,
  },
  {
    id: "2.41",
    group: "group A",
    price: 97,
    volume: 12,
  },
  {
    id: "2.42",
    group: "group B",
    price: 386,
    volume: 8,
  },
  {
    id: "2.43",
    group: "group A",
    price: 390,
    volume: 12,
  },
  {
    id: "2.44",
    group: "group A",
    price: 486,
    volume: 16,
  },
  {
    id: "2.45",
    group: "group B",
    price: 468,
    volume: 8,
  },
  {
    id: "2.46",
    group: "group B",
    price: 491,
    volume: 14,
  },
  {
    id: "2.47",
    group: "group A",
    price: 136,
    volume: 13,
  },
  {
    id: "2.48",
    group: "group C",
    price: 31,
    volume: 9,
  },
  {
    id: "2.49",
    group: "group A",
    price: 3,
    volume: 13,
  },
  {
    id: "2.50",
    group: "group C",
    price: 105,
    volume: 10,
  },
  {
    id: "2.51",
    group: "group C",
    price: 418,
    volume: 19,
  },
  {
    id: "2.52",
    group: "group A",
    price: 491,
    volume: 20,
  },
  {
    id: "2.53",
    group: "group B",
    price: 345,
    volume: 16,
  },
  {
    id: "2.54",
    group: "group B",
    price: 143,
    volume: 18,
  },
  {
    id: "2.55",
    group: "group B",
    price: 423,
    volume: 19,
  },
  {
    id: "2.56",
    group: "group C",
    price: 448,
    volume: 7,
  },
  {
    id: "2.57",
    group: "group B",
    price: 46,
    volume: 8,
  },
  {
    id: "2.58",
    group: "group A",
    price: 97,
    volume: 13,
  },
  {
    id: "2.59",
    group: "group B",
    price: 450,
    volume: 9,
  },
  {
    id: "2.60",
    group: "group C",
    price: 170,
    volume: 8,
  },
  {
    id: "2.61",
    group: "group B",
    price: 372,
    volume: 16,
  },
  {
    id: "2.62",
    group: "group C",
    price: 315,
    volume: 11,
  },
  {
    id: "2.63",
    group: "group B",
    price: 281,
    volume: 10,
  },
];

export default swarmPlotDefaultData;
