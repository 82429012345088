import { Box, Divider, Stack, Theme, Typography, useTheme } from "@mui/material";
import {
  ResponsiveLine,
  CustomLayerProps,
} from "@nivo/line";
import { getChartColors } from "../../../ExcellenceWidgets/nivoTheme";
import { css } from "@emotion/react";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import { MachineChartData } from "./conditionMonitoringUtils";

const cssStyles = (theme: Theme) => ({
  tooltipStyle: css({
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    boxShadow: theme.shadows[1],
  }),
});

interface ConditionMonitoringMachineChartProps {
  machineChartData: MachineChartData[];
}

const ConditionMonitoringMachineChart: React.FC<ConditionMonitoringMachineChartProps> = ({
  machineChartData,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
    ...cssStyles(theme),
  };

  const gridLineColor =
    theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[900];

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        height: 350,
        overflow: "hidden",
        zIndex: 1,
      }}
    >
      <ResponsiveLine
        data={machineChartData}
        margin={{ top: 6, right: 40, bottom: 30, left: 40 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          min: 20,
          max: 80,
          stacked: true,
          reverse: false,
        }}
        enablePoints={false}
        enableGridX={false}
        colors={getChartColors()}
        lineWidth={3}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          grid: {
            line: {
              stroke: gridLineColor,
              strokeWidth: 1,
            },
          },
          crosshair:
            theme.palette.mode === "dark"
              ? {
                  line: {
                    stroke: theme.palette.common.white,
                    strokeWidth: 1,
                  },
                }
              : undefined,
        }}
        yFormat=" >-.2f"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 10,
          tickPadding: 5,
          tickValues: machineChartData[0].data.map((d) => d.x),
          legendOffset: 36,
          legendPosition: "middle",
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickValues: [20, 26, 32, 38, 44, 50, 56, 62, 68, 74, 80],
          legendOffset: -40,
          legendPosition: "middle",
        }}
        enableSlices="x"
        enableCrosshair={true}
        crosshairType="cross"
        isInteractive={true}
        useMesh
        sliceTooltip={({ slice }) => {
          return (
            <>
              {slice.points.map((point) => (
                <Stack
                  key={point.id}
                  css={[styles.card, styles.leftRightPadding2, styles.tooltipStyle]}
                  gap={1}
                >
                  <Typography variant="body2" color={theme.palette.text.primary} pl={2.5}>
                    {point.data.xFormatted}
                  </Typography>
                  <Divider />
                  <Stack direction="row" css={[styles.flexCenter]} gap={1}>
                    <Box
                      component="div"
                      sx={{
                        width: 12,
                        height: 12,
                        backgroundColor: point.color,
                        borderRadius: "50%",
                      }}
                    />
                    <Typography
                      variant="body2"
                      color={theme.palette.customColors.greyText}
                    >
                      {point.serieId}:
                    </Typography>
                    <Typography variant="body2">{point.data.yFormatted}</Typography>
                  </Stack>
                </Stack>
              ))}
            </>
          );
        }}
        layers={[
          "grid",
          "markers",
          "areas",
          "lines",
          "slices",
          "points",
          "axes",
          "legends",
          customLinesLayer,
          // CustomCrosshairLayer,
        ]}
      />
    </Box>
  );
};

export default ConditionMonitoringMachineChart;

const customLinesLayer = ({ yScale, innerWidth }: CustomLayerProps) => {
  const theme = useTheme();
  const lines = [
    { y: 41, color: theme.palette.warning.light, label: "L" },
    { y: 54, color: theme.palette.warning.light, label: "H" },
    { y: 28, color: theme.palette.error.light, label: "LL" },
    { y: 72, color: theme.palette.error.light, label: "HH" },
  ];

  const yScaleTyped = yScale as (value: number) => number;

  return (
    <>
      {lines.map((line, index) => (
        <g key={index}>
          <line
            x1={0}
            x2={innerWidth}
            y1={yScaleTyped(line.y)}
            y2={yScaleTyped(line.y)}
            stroke={line.color}
            strokeWidth={2}
            strokeDasharray="6 6"
          />
          <text
            x={innerWidth + 5}
            y={yScaleTyped(line.y)}
            fill={line.color}
            fontSize={12}
          >
            {line.label}
          </text>
        </g>
      ))}
    </>
  );
};

// interface CustomCrosshairLayerProps {
//   points: Point[];
//   xScale: ScalePoint<string | number>;
//   yScale: ScaleLinear<number, number>;
// }

// const CustomCrosshairLayer: React.FC<CustomCrosshairLayerProps> = ({
//   xScale,
//   yScale,
//   points,
// }) => {
//   // const theme = useTheme();

//   if (points.length === 0) return null;

//   const x = xScale(points[0].data.x as string | number);
//   const yMin = yScale.range()[0];
//   const yMax = yScale.range()[1];

//   return (
//     <>
//       <line
//         x1={x}
//         x2={x}
//         y1={yMin}
//         y2={yMax}
//         stroke='red'
//         // stroke={theme.palette.common.black}
//         strokeWidth={1}
//         strokeDasharray="6 6"
//       />
//     </>
//   );
// };
