import { Grid, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { addMonths, isWithinInterval } from "date-fns";
import { AnomalyDetectionTableRow } from "../../Components/PageComponents/AnomalyDetection/anomalyDetectionUtils";
import AnomalyDetectionTable from "../../Components/PageComponents/AnomalyDetection/AnomalyDetectionTable";
import DatePicker from "../../Components/MaterialUI/DateTimePickers/DatePicker";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import Checkbox from "../../Components/MaterialUI/FormFields/Checkbox";
import { AnomalyDetectionTableRowsData } from "../../Components/PageComponents/AnomalyDetection/anomalyDetectionData";

const AnomalyDetectionPage: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };

  const [rows, setRows] = useState<AnomalyDetectionTableRow[]>([]);
  const [parametersData, setParametersData] = useState<AnomalyDetectionTableRow[]>([]);
  const [fromDate, setFromDate] = useState<Date>(addMonths(new Date(), -1));
  const [toDate, setToDate] = useState<Date>(new Date());
  const [showAboveProcessLimit, setShowAboveProcessLimit] = useState<boolean>(false);

  useEffect(() => {
    const parametersData: AnomalyDetectionTableRow[] = AnomalyDetectionTableRowsData;
    setParametersData(parametersData);
    setRows(filterRows(parametersData, fromDate, toDate, showAboveProcessLimit));
  }, [fromDate, toDate, showAboveProcessLimit]);

  const handleDateChange = (value: Date | null, type: "from" | "to") => {
    if (value) {
      if (type === "from") {
        setFromDate(value);
      } else {
        setToDate(value);
      }
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowAboveProcessLimit(event.target.checked);
  };

  return (
    <>
      <Typography
        css={styles.labelBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        Anomaly Detection
      </Typography>
      <Grid container spacing={2} mb={2} alignItems="flex-end">
        <Grid item xs={6} sm={3} lg={2.5}>
          <DatePicker
            css={[styles.width100]}
            label="From"
            value={fromDate}
            onChange={(date) => handleDateChange(date, "from")}
          />
        </Grid>
        <Grid item xs={6} sm={3} lg={2.5}>
          <DatePicker
            css={[styles.width100]}
            label="To"
            value={toDate}
            onChange={(date) => handleDateChange(date, "to")}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={2.5}>
          <Checkbox
            checked={showAboveProcessLimit}
            onChange={handleCheckboxChange}
            label="Select all outliers"
            size="small"
          />
        </Grid>
      </Grid>

      <AnomalyDetectionTable
        rows={rows}
        loading={!parametersData.length}
        hideColumns={["startDate", "endDate"]}
      />
    </>
  );
};

export default AnomalyDetectionPage;

const filterRows = (
  data: AnomalyDetectionTableRow[],
  from: Date,
  to: Date,
  filterHighlighted: boolean
) => {
  let filteredData = data.filter(
    (row) =>
      isWithinInterval(row.startDate, { start: from, end: to }) &&
      isWithinInterval(row.endDate, { start: from, end: to })
  );

  if (filterHighlighted) {
    filteredData = filteredData.filter((row) => {
      const conditions = [
        row.asIsMin < row.toBeMin,
        row.asIsMax > row.toBeMax,
        row.toBeMin > row.asIsMin,
        row.toBeMax < row.asIsMax,
        row.asIsAverage > row.toBeAverage,
        row.toBeAverage < row.asIsAverage,
        row.asIsDeviation > row.toBeDeviation,
        row.toBeDeviation < row.asIsDeviation,
      ];
      return conditions.some(Boolean);
    });
  }

  return filteredData;
};
