import dagre from "dagre";
import { Node, Edge, Position, XYPosition } from "reactflow";

export type ReactCustomFlowType = {
  id: string;
  name: string;
  createdOn: string;
  updatedOn: string;
  nodes: Node<any>[];
  edges: Edge<any>[];
};

export type WorkflowNewNodeModal = {
  position: XYPosition;
  id: string;
} | null;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

export const getWorkflowLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction: "TB" | "LR" = "TB"
) => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: node.width, height: node.height });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    if (node.width && node.height) {
      node.position = {
        x: nodeWithPosition.x - node.width / 2,
        y: nodeWithPosition.y - node.height / 2,
      };
    }

    return node;
  });

  return { nodes, edges };
};

export type NodeWithAllChildren = {
  [key: string]: string[];
};

/**
 * Function returns all children for a node. Key is a nodeID,
 * andd value is an array of all its direct children. The total
 * The total number of all array element for each value (all direct children)
 * is the number of edges shared between the whole tree (tree here means,
 * every node that is derived from the children of the function argument node)
 */
export const findNodeAllChildren = (
  edges: Edge<any>[],
  nodeId: string
): NodeWithAllChildren => {
  const children: NodeWithAllChildren = {};

  const findChildrenRecursive = (currentNodeId: string) => {
    const directChildren = edges
      .filter((edge) => edge.source === currentNodeId)
      .map((edge) => edge.target);

    children[currentNodeId] = directChildren;

    directChildren.forEach((childId) => {
      findChildrenRecursive(childId);
    });
  };

  findChildrenRecursive(nodeId);

  return children;
};
