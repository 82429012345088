import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { GetQueryOEEBulletChartSnippet } from "../../../../Api/OEE/apiOEESnippets";
import callApi from "../../../../Api/callApi";
import { getQueryAssetCycleRateData } from "../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../context/AuthContext";
import { ResponsiveBullet } from "@nivo/bullet";
import { OEEBulletChartType } from "../../../../Api/OEE/apiOEEDataTypes";

interface OEEBulletChartProps {
  assetIds: string[];
  title: string;
}

const OEEBulletChart: React.FC<OEEBulletChartProps> = ({ assetIds, title }) => {
  const theme = useTheme();
  const { setAuthedUser } = useAuthedContext();
  const [bulletChartData, setBulletChartData] = useState<OEEBulletChartType[]>([]);
  const measureAndMarkerColors =
    theme.palette.mode === "light" ? theme.palette.grey[800] : theme.palette.grey[200];

  const bulletChartColors = [
    theme.palette.error.light,
    theme.palette.warning.light,
    theme.palette.success.light,
    theme.palette.success.dark,
  ];

  useEffect(() => {
    fetchChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchChartData = async () => {
    const dataPromises = assetIds.map(async (assetId) => {
      const chartData = await callApi<GetQueryOEEBulletChartSnippet>({
        query: getQueryAssetCycleRateData(assetId),
        auth: { setAuthedUser },
      });
      return {
        ...chartData,
        title: (
          <text>
            <tspan
              style={{
                fill: theme.palette.text.primary,
                fontWeight: 500,
                fontSize: "12px",
              }}
            >
              {chartData.id}
            </tspan>
            <tspan
              x={0}
              dy={18}
              style={{
                fill: theme.palette.text.secondary,
                fontSize: "12px",
              }}
            >
              (Parts/min)
            </tspan>
          </text>
        ),
      };
    });

    const chartDataArray = await Promise.all(dataPromises);
    setBulletChartData(chartDataArray);
  };

  return (
    <>
      <Typography variant="body2">{title}</Typography>
      {bulletChartData.length > 0 ? (
        bulletChartData.map((data, index) => (
          <Box component="div" key={index}>
            <Box component="div" height="80px">
              <ResponsiveBullet
                data={[data]}
                margin={{ top: 30, right: 20, bottom: 20, left: 190 }}
                titleOffsetX={-185}
                measureSize={0.2}
                markerSize={1}
                rangeColors={bulletChartColors}
                measureColors={measureAndMarkerColors}
                markerColors={measureAndMarkerColors}
                theme={{
                  text: {
                    fill: theme.palette.common.black,
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.common.white,
                    },
                  },
                }}
              />
            </Box>
          </Box>
        ))
      ) : (
        <Typography variant="h5" ml={1} mb={3}>
          Loading...
        </Typography>
      )}
    </>
  );
};
export default OEEBulletChart;
