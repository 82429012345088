import { SerializedStyles } from "@emotion/react";
import Sunburst from "../../../../ExcellenceWidgets/AdvancedWidgets/Sunburst/Sunburst";
import { SunburstData } from "../../../../ExcellenceWidgets/AdvancedWidgets/Sunburst/sunburstTypes";
import sunburstDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/sunburstDefaultData";

interface ExcellenceSunburstProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: SunburstData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceSunburst: React.FC<ExcellenceSunburstProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <Sunburst
      className={className}
      configuration={configuration}
      data={data || sunburstDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceSunburst;
