import { Stack } from "@mui/material";
import { Formik } from "formik";
import { useState } from "react";
import { PutQueryOperationsFormInput } from "../../../../Api/Operations/apiOperationsInputs";
import { putQueryOperationsForm } from "../../../../Api/Operations/apiOperationsPostQueries";
import callApi from "../../../../Api/callApi";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../../context/AuthContext";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { DynamicFormGridLayoutSettings } from "./dynamicFormsUtils";
import Button from "../../../MaterialUI/Button";
import Alert from "../../../MaterialUI/Alert";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import { useDetectFormsUnsavedChanges } from "../../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
};

interface UpdateDynamicFormLayoutProps {
  settings: DynamicFormGridLayoutSettings;
  handleCloseModal: () => void;
  setTitle: React.Dispatch<React.SetStateAction<string | null>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdateDynamicFormLayout: React.FC<UpdateDynamicFormLayoutProps> = ({
  settings,
  handleCloseModal,
  setTitle,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const { setAuthedUser } = useAuthedContext();
  const initialValues: FormValues = {
    name: settings.name,
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const operationsInput: PutQueryOperationsFormInput = {
        id: settings.id,
        name: values.name,
      };
      await callApi<string>({
        query: putQueryOperationsForm(operationsInput),
        auth: { setAuthedUser },
      });

      handleCloseModal();
      setTitle(values.name);
      setFormStatus("success");
      setUnsavedChanges(false);
    } catch (err) {
      console.log("UpdateDynamicFormLayout err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <TextField
                name="name"
                label="Form Name"
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />
              <Button type="submit" loading={formStatus === "loading"}>
                Update
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default UpdateDynamicFormLayout;
