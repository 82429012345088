import { useState } from "react";
import BasicTable from "../../../../MaterialUI/BasicTable/BasicTable";
import { BasicTableColumnCell } from "../../../../MaterialUI/BasicTable/basicTableUtils";
import { SerializedStyles } from "@emotion/react";
import Modal from "../../../../MaterialUI/Modal";
import ModbusMappingForm from "./ModbusMappingForm";
import { Box, Stack } from "@mui/material";
import Button from "../../../../MaterialUI/Button";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { ModbusMappingRow } from "./modbusUtils";

const COLUMNS: BasicTableColumnCell[] = [
  { id: "name", label: "Name" },
  { id: "address", label: "Starting address" },
  { id: "type", label: "Type" },
  { id: "unit", label: "Unit" },
];

const NEW_MAPPING = "new";

interface ModbusMappingProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  mappingRows: ModbusMappingRow[];
  setMappingRows: React.Dispatch<React.SetStateAction<ModbusMappingRow[]>>;
  host: string;
  port: number;
}

const ModbusMapping: React.FC<ModbusMappingProps> = ({
  className,
  mappingRows,
  setMappingRows,
  host,
  port,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [mappingModal, setMappingModal] = useState<null | "new" | ModbusMappingRow>(null);

  const handleOpenModal = (id?: string) => {
    if (!id) {
      // add new mapping
      setMappingModal(NEW_MAPPING);
    } else {
      // edit mapping with this id
      const foundRow = mappingRows.find((item) => item.id === id);
      if (foundRow?.id) {
        setMappingModal(foundRow);
      }
    }
  };

  return (
    <Box component="div">
      <Stack
        css={styles.contentBreak}
        direction="row"
        spacing={3}
        justifyContent="center"
      >
        <Button variant="outlined" color="secondary" onClick={() => handleOpenModal()}>
          Add New Mapping
        </Button>
      </Stack>

      <BasicTable
        className={className}
        dense
        data={{ rows: mappingRows, columns: COLUMNS }}
        defaultOrderBy="name"
        emptyTableMessage="You haven't configured any address mappings"
        handleOnClick={{
          handleRowButton: (id) => {
            handleOpenModal(id);
          },
        }}
      />

      <Modal
        open={mappingModal !== null}
        onClose={() => setMappingModal(null)}
        label={mappingModal === NEW_MAPPING ? "Add new mapping" : "Edit mapping"}
        fullWidth
      >
        <ModbusMappingForm
          setRows={setMappingRows}
          editRow={mappingModal === NEW_MAPPING ? null : mappingModal}
          handleCloseModal={() => setMappingModal(null)}
          host={host}
          port={port}
        />
      </Modal>
    </Box>
  );
};

export default ModbusMapping;
