import {
  MRT_Column,
  MRT_ColumnDef,
  MRT_FilterFn,
  MRT_Header,
  MRT_SortingFn,
} from "material-react-table";
import {
  TableGridColumnDataTypes,
  TableGridHandleSaveCellChanges,
  tableGridDateFilterFunc,
} from "./tableGridUtils";
import {
  TableGridCellComponent,
  TableGridEditComponent,
} from "./TableGridColumnComponents";
import { ReactNode } from "react";
import TableGridDateTimeFilter from "./TableGridDateTimeFilter";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";

type StringPair = Record<string, any>;
export type TableGridColumnSchema = {
  id: string;
  label: string;
  type: TableGridColumnDataTypes;
  width?: number;
  marked?: boolean;
  markedId?: string;
  columns?: TableGridColumnSchema[];
  disableEditing?: boolean;
  bolded100?: boolean;
  formatNumb?: boolean;
};
type ColumnFilterArgs = {
  column: MRT_Column<StringPair>;
  header: MRT_Header;
};
type ColumnFilter = (args: ColumnFilterArgs) => ReactNode;
type FilterVariant =
  | "text"
  | "checkbox"
  | "multi-select"
  | "range"
  | "range-slider"
  | "select"
  | undefined;

const constructTableGridColumns = <T extends StringPair>(
  schema: TableGridColumnSchema[],
  handleSaveCellChanges: TableGridHandleSaveCellChanges
): MRT_ColumnDef<T>[] => {
  return schema.map((col) => {
    if (col.columns?.length) {
      return {
        id: col.id,
        header: col.label,
        columns: constructTableGridColumns(col.columns, handleSaveCellChanges),
        size: col.width,
      };
    }

    return getColumnCellData(col, handleSaveCellChanges);
  });
};

export default constructTableGridColumns;

const getColumnCellData = <T extends StringPair>(
  col: TableGridColumnSchema,
  handleSaveCellChanges: TableGridHandleSaveCellChanges
): MRT_ColumnDef<T> => {
  let columnFilterModeOptions = undefined;
  let accessorFn: ((originalRow: StringPair) => any) | undefined = undefined;
  let sortingFn: MRT_SortingFn<StringPair> | undefined = undefined;
  let filterFn: MRT_FilterFn<StringPair> | undefined = undefined;
  let Filter: ColumnFilter | undefined = undefined;
  let enableGlobalFilter: boolean | undefined = undefined;
  let enableSorting: boolean | undefined = undefined;
  let filterVariant: FilterVariant = undefined;

  switch (col.type) {
    case "string": {
      columnFilterModeOptions = [
        "fuzzy",
        "contains",
        "startsWith",
        "endsWith",
        "empty",
        "notEmpty",
      ];
      sortingFn = "alphanumeric";
      break;
    }
    case "number": {
      sortingFn = "alphanumeric";
      break;
    }
    case "currency": {
      sortingFn = "alphanumeric";
      break;
    }
    case "boolean": {
      enableGlobalFilter = false;
      filterVariant = "checkbox";
      enableSorting = false;
      break;
    }
    case "date": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id], "date") : "");
      sortingFn = "datetime";
      filterFn = tableGridDateFilterFunc;
      Filter = ({ header }) => <TableGridDateTimeFilter header={header} type="date" />;
      break;
    }
    case "time": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id], "time") : "");
      sortingFn = "datetime";
      filterFn = tableGridDateFilterFunc;
      Filter = ({ header }) => <TableGridDateTimeFilter header={header} type="time" />;
      break;
    }
    case "dateTime": {
      accessorFn = (row) => (row[col.id] ? formatDateAndTime(row[col.id]) : "");
      sortingFn = "datetime";
      filterFn = tableGridDateFilterFunc;
      Filter = ({ header }) => <TableGridDateTimeFilter header={header} type="date" />;
      break;
    }
    case "button": {
      enableSorting = false;
      enableGlobalFilter = false;
      break;
    }
    case "dropdown": {
      enableSorting = false;
      enableGlobalFilter = false;
      break;
    }
  }

  const result: MRT_ColumnDef<Record<string, any>> = {
    accessorKey: col.id,
    header: col.label,
    meta: {
      type: col.type,
    },
    size: col.width,
    columnFilterModeOptions,
    Cell: ({ cell, row }) => (
      <TableGridCellComponent
        cellValue={cell.getValue<string>()}
        type={col.type}
        marked={col.marked}
        markedId={col.markedId}
        rowData={row.original}
        bolded100={col.bolded100}
        formatNumb={col.formatNumb}
      />
    ),
    Edit: ({ cell }) => (
      <TableGridEditComponent
        rowIndex={cell.row.index}
        colKey={cell.column.id}
        colValue={cell.getValue()}
        type={col.type}
        handleSaveCellChanges={handleSaveCellChanges}
        disableEditing={col.disableEditing}
      />
    ),
    accessorFn,
    sortingFn,
    filterFn,
    Filter,
    filterVariant,
    enableGlobalFilter,
    enableSorting,
  };

  return result as MRT_ColumnDef<T>;
};
