import { useEffect, useState } from "react";
import {
  Box,
  Menu as MUIAlertsMenu,
  IconButton,
  Typography,
  Stack,
  useTheme,
} from "@mui/material";
import { css } from "@emotion/react";
import cssLayoutStyles from "../../Global/Styles/layout";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { formatDateAndTime } from "../../Global/Utils/commonFunctions";
import { useAuthedContext } from "../../context/AuthContext";
import { Alert, Rule } from "../../Api/Rules/apiRulesSnippets";
import callApi from "../../Api/callApi";
import Button from "../../Components/MaterialUI/Button";
import { getQueryRule } from "../../Api/Rules/apiRulesGetQueries";
import cssComponentsStyles from "../../Global/Styles/components";
import { Theme } from "@mui/material";
import { useLanguageContext } from "../../context/LanguageContext";

const cssStyles = (theme: Theme) => ({
  alertsMenuDropdown: css({
    maxHeight: "600px",
    marginTop: 1.5,
    overflow: "auto",
  }),
  alertSecondaryText: css({
    color:
      theme.palette.mode === "light" ? theme.palette.grey[900] : theme.palette.grey[400],
    fontWeight: 400,
    fontSize: "0.9rem",
    lineHeight: 1.5,
  }),
});

const AlertsMenu = () => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssLayoutStyles,
    ...cssComponentsStyles(theme),
  };
  const { t } = useLanguageContext();
  // const { alertsData, setAlertsData } = useAuthedContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [localAlerts, setLocalAlerts] = useState<{
    read: Alert[];
    unread: Alert[];
  }>({
    read: [],
    unread: [],
  });

  const open = Boolean(anchorEl);
  const { reFetchAlerts } = useAuthedContext();

  useEffect(() => {
    if (window) {
      try {
        const kanAlerts = localStorage.getItem("unreadKanAlerts");
        const readKanAlerts = localStorage.getItem("readKanAlerts");

        const unread: Alert[] = [];
        const read: Alert[] = [];
        if (kanAlerts) {
          const parsed = JSON.parse(kanAlerts);
          unread.push(...parsed);
        }
        if (readKanAlerts) {
          const parsed = JSON.parse(readKanAlerts);
          read.push(...parsed);
        }

        setLocalAlerts({
          read,
          unread,
        });
      } catch (err) {
        console.log(err);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reFetchAlerts]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarkAlertRead = async (alertId: string) => {
    try {
      // await callApi({ query: postQuerySetAlertToRead(alertId), auth: { setAuthedUser } });

      const alertIndex = localAlerts.unread.findIndex(
        (alert) => alert.id.toString() === alertId
      );
      if (alertIndex !== -1) {
        const updatedUnread = [...localAlerts.unread];
        updatedUnread.splice(alertIndex, 1);

        const updatedRead = [localAlerts.unread[alertIndex], ...localAlerts.read];

        setLocalAlerts({
          unread: updatedUnread,
          read: updatedRead,
        });
        localStorage.setItem("unreadKanAlerts", JSON.stringify(updatedUnread));
        localStorage.setItem("readKanAlerts", JSON.stringify(updatedRead));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteAlert = async (alertId: string) => {
    try {
      // await callApi({ query: postQueryDeleteAlert(alertId), auth: { setAuthedUser } });

      const alertIndex = localAlerts.read.findIndex(
        (alert) => alert.id.toString() === alertId
      );

      if (alertIndex !== -1) {
        const updatedRead = [...localAlerts.read];
        updatedRead.splice(alertIndex, 1);

        setLocalAlerts({
          unread: localAlerts.unread,
          read: updatedRead,
        });
        localStorage.setItem("readKanAlerts", JSON.stringify(updatedRead));
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box component="div" css={styles.flexCenter}>
        <Stack alignItems="center" onClick={handleClick} css={{ cursor: "pointer" }}>
          <IconButton aria-label="open alerts menu">
            {localAlerts.unread.length > 0 ? (
              <NotificationsActiveIcon css={styles.iconHover} />
            ) : (
              <NotificationsNoneIcon css={styles.iconHover} />
            )}
          </IconButton>
          <Typography variant="caption" align="center" color="textSecondary">
            {t("Alerts")} ({localAlerts.unread.length || 0})
          </Typography>
        </Stack>
      </Box>

      <MUIAlertsMenu
        css={styles.alertsMenuDropdown}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        elevation={0}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {localAlerts.unread.length > 0
          ? localAlerts.unread.map((alert) => (
              <AlertCard
                key={alert.id}
                alert={alert}
                handleMarkAlertRead={handleMarkAlertRead}
                handleDeleteAlert={handleDeleteAlert}
              />
            ))
          : null}

        {localAlerts.read.length > 0
          ? localAlerts.read.map((alert) => (
              <AlertCard
                key={alert.id}
                alert={alert}
                handleMarkAlertRead={handleMarkAlertRead}
                handleDeleteAlert={handleDeleteAlert}
                read
              />
            ))
          : null}

        {localAlerts.read.length === 0 && localAlerts.unread.length === 0 ? (
          <Typography paddingX={2} paddingY={1} variant="body1">
            {t("No new alerts")}
          </Typography>
        ) : null}
      </MUIAlertsMenu>
    </>
  );
};

export default AlertsMenu;

interface AlertProps {
  alert: Alert;
  read?: boolean;
  handleMarkAlertRead: (alertId: string) => Promise<void>;
  handleDeleteAlert: (alertId: string) => Promise<void>;
}

const AlertCard: React.FC<AlertProps> = ({
  alert,
  read,
  handleMarkAlertRead,
  handleDeleteAlert,
}) => {
  const { t } = useLanguageContext();
  const [ruleData, setRuleData] = useState<Rule | undefined>();
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      return;
      if (ruleData) return;
      try {
        const ruleData = await callApi<Rule>({
          query: getQueryRule(alert.rule_id.toString()),
          auth: { setAuthedUser },
        });

        if (ruleData) {
          setRuleData(ruleData);
        }
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert.rule_id, ruleData]);

  return (
    <Stack
      style={{ maxWidth: "400px" }}
      paddingX={1}
      direction="row"
      alignItems="center"
      spacing={2}
    >
      {read ? <NotificationsNoneIcon /> : <NotificationsIcon color="primary" />}

      <Stack sx={{ flex: 1 }} width="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
          <Typography variant="body2">{formatDateAndTime(alert.triggered_at)}</Typography>

          {!read ? (
            <Button
              variant="text"
              onClick={() => {
                handleMarkAlertRead(alert.id.toString());
              }}
            >
              {t("Mark as read")}
            </Button>
          ) : (
            <Button
              variant="text"
              onClick={() => {
                handleDeleteAlert(alert.id.toString());
              }}
              color="error"
            >
              {t("Delete")}
            </Button>
          )}
        </Stack>

        <Typography variant="body2">{alert.message}</Typography>

        {/* <Typography component="p" css={[styles.alertSecondaryText]}>
          {t("Alert according to rule")}
        </Typography>

        <Typography component="p" css={[styles.alertSecondaryText]}>
          {ruleData
            ? `"${ruleData?.parameter_name} ${ruleData?.rule} ${ruleData?.number}"`
            : t("Loading...")}
        </Typography> */}
      </Stack>
    </Stack>
  );
};
