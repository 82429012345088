import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { BarLegendData } from "./excellenceUtils";

interface BarLegendProps {
  data: BarLegendData;
  width: number;
}

export const BarLegend: React.FC<BarLegendProps> = ({ data, width }) => {
  return (
    <Box
      component="div"
      style={{
        overflowY: "auto",
        height: "100%",
        maxWidth: `${width}px`,
        paddingBottom: "1rem",
        paddingRight: "5px",
      }}
    >
      <Stack spacing={1} justifyContent="flex-end">
        {data.legend.map((item) => (
          <Tooltip key={`${item.label}-${item.color}`} arrow title={item.label}>
            <Stack spacing={1} direction="row">
              <Box component="div">
                <Box component="div" style={{ height: "3px" }} />
                <Box
                  component="div"
                  style={{
                    width: "14px",
                    height: "14px",
                    borderRadius: "100%",
                    backgroundColor: item.color,
                  }}
                />
              </Box>

              <Typography
                style={{
                  flex: 1,
                  whiteSpace: "no-wrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                variant="caption"
              >
                {item.label}
              </Typography>
            </Stack>
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
};
