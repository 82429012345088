import { Box, Stack } from "@mui/material";
import { GetQueryMaintenanceTasksSnippet } from "../../../../../Api/Maintenance/apiMaintenanceSnippets";
import { SerializedStyles } from "@emotion/react";
import { useEffect, useState } from "react";
import { MachineTaskStatus } from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import {
  AutocompleteOption,
  SelectOption,
} from "../../../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../../../Global/Utils/commonFunctions";
import Select from "../../../../MaterialUI/FormFields/Select";

type Filters = {
  status: MachineTaskStatus | "All";
  priority: string;
  machine: string;
  responsible: string;
};

const INITIAL_FILTERS: Filters = {
  status: "Open",
  machine: "",
  priority: "",
  responsible: "",
};

type FieldName = "status" | "machine" | "priority" | "responsible";

const statusOptions: SelectOption[] = handleGetSelectOption(["Open", "Closed", "All"]);
const priorityOptions: SelectOption[] = [];

interface MaintenanceCalendarFiltersProps {
  css?: (SerializedStyles | false)[] | SerializedStyles;
  className?: string;
  allData: GetQueryMaintenanceTasksSnippet;
  changeDisplayData: (allData: GetQueryMaintenanceTasksSnippet) => void;
}

const MaintenancePlanCalendarFilters: React.FC<MaintenanceCalendarFiltersProps> = ({
  className,
  allData,
  changeDisplayData,
}) => {
  const [filters, setFilters] = useState<Filters>(INITIAL_FILTERS);

  const filterOptions = getFilterOptions(allData);

  useEffect(() => {
    const result = filterData(allData, filters);
    changeDisplayData(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, allData]);

  const handleFiltersChange = (field: FieldName, value: any) => {
    switch (field) {
      case "status": {
        setFilters((prev) => ({ ...prev, status: value }));
        break;
      }
      case "machine": {
        setFilters((prev) => ({ ...prev, machine: value === "empty" ? "" : value }));
        break;
      }
      case "priority": {
        setFilters((prev) => ({ ...prev, priority: value }));
        break;
      }
      case "responsible": {
        setFilters((prev) => ({ ...prev, responsible: value === "empty" ? "" : value }));
        break;
      }
    }
  };

  return (
    <Box component="div" className={className}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2} alignItems="center">
        <Select
          selectOptions={filterOptions.responsibleOptions}
          value={filters.responsible}
          onChange={(e) => handleFiltersChange("responsible", e.target.value)}
          label="Maintenance Responsible"
        />
        <Select
          selectOptions={statusOptions}
          value={filters.status}
          onChange={(e) => handleFiltersChange("status", e.target.value)}
          label="Status"
        />
        <Select
          selectOptions={filterOptions.machineOptions}
          value={filters.machine}
          onChange={(e) => handleFiltersChange("machine", e.target.value)}
          label="Machine"
        />
        <Select
          selectOptions={priorityOptions}
          value={filters.priority}
          onChange={(e) => handleFiltersChange("priority", e.target.value)}
          label="Priority"
          disabled
        />
      </Stack>
    </Box>
  );
};

export default MaintenancePlanCalendarFilters;

type FilterOptions = {
  machineOptions: SelectOption[];
  responsibleOptions: AutocompleteOption[];
};

const getFilterOptions = (allData: GetQueryMaintenanceTasksSnippet): FilterOptions => {
  const machinesSet = new Set<string>();
  const responsibleSet = new Set<string>();

  allData.forEach((item) => {
    if (item.machine) {
      machinesSet.add(item.machine);
    }
    if (item.responsible) {
      const responsiblePersons = item.responsible.map((resp) => resp.persons);
      const responsibleNames = responsiblePersons?.map((pers) => pers?.[0]?.name);
      (responsibleNames || []).forEach((name) => {
        if (name) {
          responsibleSet.add(name);
        }
      });
    }
  });

  const machineArr = handleGetSelectOption(Array.from(machinesSet));
  const responsibleArr = handleGetSelectOption(Array.from(responsibleSet));

  return {
    machineOptions: [{ value: "empty", description: "Set to Empty" }, ...machineArr],
    responsibleOptions: [
      { value: "empty", description: "Set to Empty" },
      ...responsibleArr,
    ],
  };
};

const filterData = (
  allData: GetQueryMaintenanceTasksSnippet,
  filters: Filters
): GetQueryMaintenanceTasksSnippet => {
  const result: GetQueryMaintenanceTasksSnippet = [];

  allData.forEach((item) => {
    let statusCheck: boolean = true;
    let priorityCheck: boolean = true;
    let machineCheck: boolean = true;
    let responsibleCheck: boolean = true;

    if (filters.status !== "All") {
      statusCheck = item.status === filters.status;
    }
    if (filters.priority) {
    }
    if (filters.machine) {
      machineCheck = item.machine === filters.machine;
    }
    if (filters.responsible) {
      const responsibleArr: string[] = [];
      const responsiblePersons = item.responsible?.map((resp) => resp.persons);
      const responsibleNames = responsiblePersons?.map((pers) => pers?.[0]?.name);
      (responsibleNames || []).forEach((name) => {
        if (name) {
          responsibleArr.push(name);
        }
      });
      responsibleCheck = responsibleArr.includes(filters.responsible);
    }

    if (statusCheck && priorityCheck && machineCheck && responsibleCheck) {
      result.push(item);
    }
  });

  return result;
};
