import { Stack, useTheme } from "@mui/material";
import Modal from "../../MaterialUI/Modal";
import {
  BOSCH_NODES_OPTIONS,
  BoschAllTaskNodesData,
  BoschNewNodeModal,
  BOSCH_TASK_NODE_TYPE,
  BOSCH_BNA_NODE_CONFIG,
  BOSCH_CUTTING_NODE_CONFIG,
  BOSCH_INSPECTION_NODE_CONFIG,
  BOSCH_MATERIAL_PICKING_NODE_CONFIG,
  BOSCH_MILLING_NODE_CONFIG,
  BOSCH_SHIPPING_NODE_CONFIG,
  BOSCH_TCC_NODE_CONFIG,
} from "./BoschNodes/boschNodesUtils";
import Select from "../../MaterialUI/FormFields/Select";
import { Node, Edge } from "reactflow";
import { useState } from "react";
import Collapse from "../../MaterialUI/Collapse";
import BoschGeneralForm from "./BoschNodes/Forms/BoschGeneralForm";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";

interface BoschCreateNewNodeModalProps {
  newNodeModal: BoschNewNodeModal;
  setNewNodeModal: React.Dispatch<React.SetStateAction<BoschNewNodeModal>>;
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  connectingNodeId: React.MutableRefObject<any>;
}

const BoschCreateNewNodeModal: React.FC<BoschCreateNewNodeModalProps> = ({
  newNodeModal,
  setNewNodeModal,
  setNodes,
  setEdges,
  connectingNodeId,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [selectedType, setSelectedType] = useState<BOSCH_TASK_NODE_TYPE | "">("");
  const [typeConfig, setTypeConfig] = useState<any>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const handleOnTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as BOSCH_TASK_NODE_TYPE;
    switch (val) {
      case BOSCH_TASK_NODE_TYPE.MaterialPicking: {
        setTypeConfig(BOSCH_MATERIAL_PICKING_NODE_CONFIG);
        break;
      }
      case BOSCH_TASK_NODE_TYPE.Bna: {
        setTypeConfig(BOSCH_BNA_NODE_CONFIG);
        break;
      }
      case BOSCH_TASK_NODE_TYPE.Inspection: {
        setTypeConfig(BOSCH_INSPECTION_NODE_CONFIG);
        break;
      }
      case BOSCH_TASK_NODE_TYPE.Shipping: {
        setTypeConfig(BOSCH_SHIPPING_NODE_CONFIG);
        break;
      }
      case BOSCH_TASK_NODE_TYPE.Milling: {
        setTypeConfig(BOSCH_MILLING_NODE_CONFIG);
        break;
      }
      case BOSCH_TASK_NODE_TYPE.Cutting: {
        setTypeConfig(BOSCH_CUTTING_NODE_CONFIG);
        break;
      }
      case BOSCH_TASK_NODE_TYPE.Tcc: {
        setTypeConfig(BOSCH_TCC_NODE_CONFIG);
        break;
      }
    }
    setSelectedType(val);
  };

  const handleCreateSubmit = (nodeData: BoschAllTaskNodesData) => {
    if (newNodeModal && typeConfig) {
      const newNode = {
        id: newNodeModal.id,
        position: newNodeModal.position,
        data: getNodeData(nodeData),
        ...typeConfig,
      };

      setNodes((nds) => nds.concat(newNode));
      setEdges((eds) =>
        eds.concat({
          id: newNodeModal.id,
          source: connectingNodeId.current,
          target: newNodeModal.id,
          type: "step",
        })
      );
      setNewNodeModal(null);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Modal
      onClose={() => setNewNodeModal(null)}
      open={!!newNodeModal}
      fullWidth
      label="Creating new node"
      unsavedChanges={unsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    >
      <Stack spacing={3} alignItems="center" justifyContent="center">
        <Select
          selectOptions={BOSCH_NODES_OPTIONS}
          fullWidth
          value={selectedType}
          onChange={handleOnTypeChange}
        />

        <Collapse css={styles.width100} in={!!selectedType && !!typeConfig}>
          {selectedType === BOSCH_TASK_NODE_TYPE.MaterialPicking ? (
            <BoschGeneralForm
              handleCreateSubmit={handleCreateSubmit}
              nodeType={BOSCH_TASK_NODE_TYPE.MaterialPicking}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {selectedType === BOSCH_TASK_NODE_TYPE.Bna ? (
            <BoschGeneralForm
              handleCreateSubmit={handleCreateSubmit}
              nodeType={BOSCH_TASK_NODE_TYPE.Bna}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {selectedType === BOSCH_TASK_NODE_TYPE.Inspection ? (
            <BoschGeneralForm
              handleCreateSubmit={handleCreateSubmit}
              nodeType={BOSCH_TASK_NODE_TYPE.Inspection}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {selectedType === BOSCH_TASK_NODE_TYPE.Shipping ? (
            <BoschGeneralForm
              handleCreateSubmit={handleCreateSubmit}
              nodeType={BOSCH_TASK_NODE_TYPE.Shipping}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {selectedType === BOSCH_TASK_NODE_TYPE.Milling ? (
            <BoschGeneralForm
              handleCreateSubmit={handleCreateSubmit}
              nodeType={BOSCH_TASK_NODE_TYPE.Milling}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {selectedType === BOSCH_TASK_NODE_TYPE.Cutting ? (
            <BoschGeneralForm
              handleCreateSubmit={handleCreateSubmit}
              nodeType={BOSCH_TASK_NODE_TYPE.Cutting}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
          {selectedType === BOSCH_TASK_NODE_TYPE.Tcc ? (
            <BoschGeneralForm
              handleCreateSubmit={handleCreateSubmit}
              nodeType={BOSCH_TASK_NODE_TYPE.Tcc}
              handleSetUnsavedChanges={handleSetUnsavedChanges}
              setUnsavedChanges={setUnsavedChanges}
            />
          ) : null}
        </Collapse>
      </Stack>
    </Modal>
  );
};

export default BoschCreateNewNodeModal;

const getNodeData = (nodeData: BoschAllTaskNodesData) => {
  const foundData = Object.values(nodeData).find((val) => !!val);
  return foundData;
};
