import { Query } from "../callApi";
import { PostQueryProjectCreateInput, PutQueryProjectInput } from "./apiProjectInputs";

export const postQueryProjectCreate = (input: PostQueryProjectCreateInput): Query => ({
  endpoint: "project_management/project",
  method: "POST",
  variables: input,
});

export const putQueryProject = (input: PutQueryProjectInput, id: string): Query => ({
  endpoint: `project_management/project/${id}`,
  method: "PUT",
  variables: input,
});

export const deleteQueryProject = (id: string): Query => ({
  endpoint: `project_management/project/${id}`,
  method: "DELETE",
});
