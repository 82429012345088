import React, { memo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Typography } from "@mui/material";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FLOW_NODE_TYPE, FlowTextNodeData } from "../flowNodesTypes";
import FlowNode from "./FlowNode";
import { useReactFlow } from "reactflow";
import FlowTextForm from "../Forms/FlowTextForm";
import Modal from "../../../../MaterialUI/Modal";
import cssLayoutStyles from "../../../../../Global/Styles/layout";

interface FlowTextNodeProps {
  id: string;
  data: FlowTextNodeData;
}
type ModalType = "Edit Node" | null;

const FlowTextNode: React.FC<FlowTextNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [modalType, setModalType] = useState<ModalType>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodes } = useReactFlow();

  const handleEditSubmit = (nodeData: FlowTextNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit Node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <FlowNode
        id={id}
        nodeType={FLOW_NODE_TYPE.Text}
        handleOpenEditModal={handleOpenEditModal}
      >
        <Box
          component="div"
          style={{ height: "calc(100% - 55px)" }}
          css={[styles.width100, styles.flexCenter]}
        >
          <Typography variant="h2" align="center">
            {data.name}
          </Typography>
        </Box>
      </FlowNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType || ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit Node" ? (
          <FlowTextForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default memo(FlowTextNode);
