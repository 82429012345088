import { Query } from "../callApi";
import {
  PostQueryLoginInput,
  PostQueryPasswordResetConfirmedInput,
  PostQueryResetPasswordInput,
  PostQueryUserUploadProfilePictureInput,
  PostQueryUsersCreateInput,
} from "./apiAuthInputs";

export const postQueryLogin = (input: PostQueryLoginInput): Query => ({
  endpoint: "users/login",
  method: "POST",
  variables: input,
  receiveErrorMessage: true,
});

export const postQueryUsersCreate = (input: PostQueryUsersCreateInput): Query => ({
  endpoint: "users/create",
  method: "POST",
  variables: input,
});

export const postQueryTokenRefresh = (): Query => ({
  endpoint: "users/token/refresh",
  method: "POST",
});

export const postQueryResetPassword = (input: PostQueryResetPasswordInput): Query => ({
  endpoint: "users/reset_password",
  method: "POST",
  variables: input,
});

export const postQueryPasswordResetConfirmed = (
  token: string,
  input: PostQueryPasswordResetConfirmedInput
): Query => ({
  endpoint: `users/password_reset_confirmed?token=${token}`,
  method: "POST",
  variables: input,
});

export const postQueryUserUploadProfilePicture = (
  input: PostQueryUserUploadProfilePictureInput
): Query => ({
  endpoint: "users/user/profile-picture",
  method: "POST",
  variables: input,
  multipartForm: true,
});

export const postQueryUserDeleteProfilePicture = (): Query => ({
  endpoint: "users/user/profile-picture",
  method: "DELETE",
});
