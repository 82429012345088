import { ExcellenceTableDataFormat } from "../../ExcellenceWidgets/ExcellenceTable/editExcellenceTabletypes";
import WidgetIconButton from "../../SmallComponents/DynamicGridLayout.tsx/DynamicFormFields/WidgetIconButton";
import { ExcellenceChartKeyType } from "./excellenceUtils";

interface ExcellenceSwitchOptionsProps {
  chart: ExcellenceChartKeyType | null;
  tableDataFormat?: ExcellenceTableDataFormat;
  handleSwitch: (type: ExcellenceChartKeyType) => void;
}

const ExcellenceSwitchOptions: React.FC<ExcellenceSwitchOptionsProps> = ({
  chart,
  handleSwitch,
  tableDataFormat,
}) => {
  switch (chart) {
    // Excellence Charts
    case "lineChart": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton type="areaChart" onClick={() => handleSwitch("areaChart")} />
          <WidgetIconButton type="heatMap" onClick={() => handleSwitch("heatMap")} />
        </>
      );
    }
    case "areaChart": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton type="lineChart" onClick={() => handleSwitch("lineChart")} />
          <WidgetIconButton type="heatMap" onClick={() => handleSwitch("heatMap")} />
        </>
      );
    }
    case "timeChart": {
      return (
        <>
          <WidgetIconButton disabled type="table" onClick={() => handleSwitch("table")} />
        </>
      );
    }
    case "barChart": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton
            type="columnChart"
            onClick={() => handleSwitch("columnChart")}
          />
          <WidgetIconButton
            type="radarChart"
            onClick={() => handleSwitch("radarChart")}
          />
        </>
      );
    }
    case "columnChart": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton type="barChart" onClick={() => handleSwitch("barChart")} />
          <WidgetIconButton
            type="radarChart"
            onClick={() => handleSwitch("radarChart")}
          />
        </>
      );
    }
    case "radarChart": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton type="barChart" onClick={() => handleSwitch("barChart")} />
          <WidgetIconButton
            type="columnChart"
            onClick={() => handleSwitch("columnChart")}
          />
        </>
      );
    }
    case "pieChart": {
      return (
        <>
          <WidgetIconButton disabled type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton
            type="donutChart"
            onClick={() => handleSwitch("donutChart")}
          />
        </>
      );
    }
    case "donutChart": {
      return (
        <>
          <WidgetIconButton disabled type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton type="pieChart" onClick={() => handleSwitch("pieChart")} />
        </>
      );
    }
    case "table": {
      return (
        <>
          <WidgetIconButton
            type="lineChart"
            disabled
            onClick={() => handleSwitch("lineChart")}
            // disabled={tableDataFormat !== "lineAreaHeatMap"}
          />
          <WidgetIconButton
            type="timeChart"
            disabled
            onClick={() => handleSwitch("timeChart")}
            // disabled={tableDataFormat !== "lineAreaHeatMap"}
          />
          <WidgetIconButton
            type="barChart"
            onClick={() => handleSwitch("barChart")}
            disabled={tableDataFormat !== "barRadar"}
          />
          <WidgetIconButton
            type="radarChart"
            onClick={() => handleSwitch("radarChart")}
            disabled={tableDataFormat !== "barRadar"}
          />
          <WidgetIconButton
            type="columnChart"
            onClick={() => handleSwitch("columnChart")}
            disabled={tableDataFormat !== "barRadar"}
          />
          <WidgetIconButton
            type="areaChart"
            onClick={() => handleSwitch("areaChart")}
            disabled={tableDataFormat !== "lineAreaHeatMap"}
          />
          <WidgetIconButton
            type="pieChart"
            onClick={() => handleSwitch("pieChart")}
            disabled={tableDataFormat !== "pie"}
          />
          <WidgetIconButton
            type="donutChart"
            onClick={() => handleSwitch("donutChart")}
            disabled={tableDataFormat !== "pie"}
          />
          {/*  Advanced Charts */}
          <WidgetIconButton
            type="boxPlot"
            onClick={() => handleSwitch("boxPlot")}
            disabled={tableDataFormat !== "boxPlot"}
          />
          <WidgetIconButton
            type="heatMap"
            onClick={() => handleSwitch("heatMap")}
            disabled={tableDataFormat !== "lineAreaHeatMap"}
          />
          <WidgetIconButton
            type="sunburst"
            onClick={() => handleSwitch("sunburst")}
            disabled={tableDataFormat !== "sunburst"}
          />
        </>
      );
    }
    case "boxPlot": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
        </>
      );
    }
    case "heatMap": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
          <WidgetIconButton type="areaChart" onClick={() => handleSwitch("areaChart")} />
          <WidgetIconButton type="lineChart" onClick={() => handleSwitch("lineChart")} />
        </>
      );
    }
    case "sunburst": {
      return (
        <>
          <WidgetIconButton type="table" onClick={() => handleSwitch("table")} />
        </>
      );
    }

    default: {
      return null;
    }
  }
};

export default ExcellenceSwitchOptions;
