import { Query } from "../callApi";
import { GetQueryPidSingleParamInput } from "./apiPidWorkflowsInputs";

export const getQueryPidWorkflows = (): Query => ({
  endpoint: "p_and_id_workflows/workflows",
  method: "GET",
});

export const getQueryPidSingleWorkflow = (id: string): Query => ({
  endpoint: `p_and_id_workflows/workflow/${id}`,
  method: "GET",
});

export const getQueryPidWorkflowFields = (id: string): Query => ({
  endpoint: `p_and_id_workflows/workflow/${id}/data`,
  method: "GET",
});

export const getQueryPidAllSvgs = (): Query => ({
  endpoint: "p_and_id_workflows/categories/all-svgs",
  method: "GET",
});

export const getQueryPidSingleParam = (input: GetQueryPidSingleParamInput): Query => ({
  endpoint: `p_and_id_workflows/parameter`,
  method: "GET",
  variables: input,
});

export const getQueryPidWorkflowImages = (): Query => ({
  endpoint: `p_and_id_workflows/images`,
  method: "GET",
});
