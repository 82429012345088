import { SelectOption } from "../../../Global/Types/commonTypes";

export enum TrendDirection {
  UP = "UP",
  DOWN = "DOWN",
  FLAT = "FLAT",
}

export const positiveTrendSelectOptions: SelectOption[] = [
  { value: TrendDirection.UP, description: "Upwards" },
  { value: TrendDirection.DOWN, description: "Downwards" },
  { value: "target", description: "On Target" },
];
