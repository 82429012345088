import {
  AutocompleteGroupedOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";
import { useEffect, useState } from "react";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import isValid from "date-fns/isValid";
import { Box, IconButton, Stack, Theme, Tooltip, Typography } from "@mui/material";
import DateAndTimePicker from "../../MaterialUI/DateTimePickers/DateAndTimePicker";
import Autocomplete from "../../MaterialUI/FormFields/Autocomplete";
import AutocompleteGrouped from "../../MaterialUI/FormFields/AutocompleteGrouped";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { css } from "@emotion/react";
import {
  PieAggregationFunction,
  PieChartDataSchema,
  PieChartParameter,
} from "./pieChartTypes";
import Select from "../../MaterialUI/FormFields/Select";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { ExcellenceParamMapping } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { useLanguageContext } from "../../../context/LanguageContext";

const AGGREGATE_OPTIONS: SelectOption[] = Object.entries(PieAggregationFunction).map(
  ([key, value]) => ({ description: key, value: value })
);
const AGGREGATE_STRING_OPTIONS: SelectOption[] = [
  { description: "Count", value: PieAggregationFunction.Count },
];

const cssStyles = (theme: Theme) => ({
  autocompleteGroup: css({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.secondary.light,
  }),
  autocompleteList: css({
    padding: 0,
  }),
});

type ParamRow = {
  parameter: AutocompleteGroupedOption | null;
  aggregation: "" | PieAggregationFunction;
};

const EMPTY_PARAM_ROW: ParamRow = {
  aggregation: "",
  parameter: null,
};

interface EditPieChartDataSchemaProps {
  parameters: ExcellenceParameter[];
  dataSchema: PieChartDataSchema | null;
  handleUpdateDataSchema: (schema: PieChartDataSchema | null) => void;
  dateFrom: Date | null;
  setDateFrom: React.Dispatch<React.SetStateAction<Date | null>>;
  dateTo: Date | null;
  setDateTo: React.Dispatch<React.SetStateAction<Date | null>>;
  selectedConnections: SelectOption[];
  setSelectedConnections: React.Dispatch<React.SetStateAction<SelectOption[]>>;
}

const EditPieChartDataSchema: React.FC<EditPieChartDataSchemaProps> = ({
  parameters,
  dataSchema,
  handleUpdateDataSchema,
  dateFrom,
  setDateFrom,
  dateTo,
  setDateTo,
  selectedConnections,
  setSelectedConnections,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssStyles(theme),
    ...cssLayoutStyles,
  };
  const { t } = useLanguageContext();
  // options
  const [nodeOptions, setNodeOptions] = useState<AutocompleteGroupedOption[]>([]);
  const [connectionOptions, setConnectionOptions] = useState<SelectOption[]>([]);
  const [paramMapping, setParamMapping] = useState<ExcellenceParamMapping | null>(null);

  const [selectedLegend, setSelectedLegend] = useState<AutocompleteGroupedOption | null>(
    null
  );
  const [paramRows, setParamRows] = useState<ParamRow[]>([]);
  const [connectionsSet, setConnectionsSet] = useState<boolean>(false);

  // system-used
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [reUpdateSchema, setReUpdateSchema] = useState<boolean>(false);

  useEffect(() => {
    if (selectedConnections.length && !paramRows.length) {
      setParamRows([EMPTY_PARAM_ROW]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSchema && paramMapping) {
      const selectedConnectionsSet: Set<string> = new Set();
      const selectedNodesArr: AutocompleteGroupedOption[] = [];

      for (const item of dataSchema.parameters) {
        selectedConnectionsSet.add(item.container);
        selectedNodesArr.push({
          value: item.parameterId,
          description: item.parameterId,
          groupName: item.container,
        });
      }
      if (dataSchema.legend) {
        selectedConnectionsSet.add(dataSchema.legend.container);
      }
      const selectedConnectionsArr: SelectOption[] = Array.from(
        selectedConnectionsSet
      ).map((item) => {
        const conn = Object.values(paramMapping).find(
          (param) => param.connection === item
        );
        const connName = conn?.connectionName || item;

        return {
          value: item,
          description: connName,
        };
      });

      const nodeParams: ParamRow[] = dataSchema.parameters.map((item) => ({
        aggregation: item.aggregation,
        parameter: {
          value: item.parameterId,
          groupName: item.container,
          description: paramMapping?.[item.parameterId].paramName || item.parameterId,
        },
      }));
      const legend: AutocompleteGroupedOption | null = dataSchema?.legend?.parameterId
        ? {
            value: dataSchema.legend.parameterId,
            groupName: paramMapping[dataSchema.legend.parameterId].connectionName,
            description: paramMapping[dataSchema.legend.parameterId].paramName,
          }
        : null;

      setSelectedConnections(selectedConnectionsArr);
      setSelectedLegend(legend);
      setParamRows(nodeParams);
      setConnectionsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramMapping]);

  useEffect(() => {
    const connectionsRecord: Record<string, string> = {};
    parameters.forEach((item) => {
      connectionsRecord[item.container] = item.containerName;
    });

    const allConnectionOptions: SelectOption[] = Object.entries(connectionsRecord).map(
      ([key, value]) => ({
        value: key,
        description: value,
      })
    );

    handleGetSelectOption([...new Set(parameters.map((item) => item.container))]);

    const mapping: ExcellenceParamMapping = {};
    parameters.forEach((param) => {
      if (param.id && !mapping?.[param.id]) {
        mapping[param.id] = {
          connection: param.container,
          paramID: param.id,
          paramName: param.name,
          connectionName: param.containerName,
          type: param.type,
        };
      }
    });

    setConnectionOptions(allConnectionOptions);
    setParamMapping(mapping);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  useEffect(() => {
    const paramOptions = handleGetParamOptions(parameters, selectedConnections);
    setNodeOptions(paramOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConnections]);

  useEffect(() => {
    const startTime = dateFrom && isValid(dateFrom) ? dateFrom.toISOString() : null;
    const endTime = dateTo && isValid(dateTo) ? dateTo.toISOString() : null;
    if (connectionsSet && !selectedConnections.length) {
      handleUpdateDataSchema(null);
    } else {
      if (selectedLegend && paramMapping?.[selectedLegend?.value || ""]?.paramID) {
        const properParams: PieChartParameter[] = paramRows
          .filter((item) => item.aggregation && item.parameter)
          .map((param) => ({
            parameterId: param.parameter!.value,
            container: paramMapping[param.parameter!.value].connection,
            aggregation: param.aggregation,
            parameterName: param.parameter?.description || param.parameter!.value,
          }));

        const updatedSchema: PieChartDataSchema = {
          startTime,
          endTime,
          legend: {
            parameterId: paramMapping[selectedLegend.value].paramID,
            container: paramMapping[selectedLegend.value].connection,
            parameterName: paramMapping[selectedLegend.value].paramName,
          },
          parameters: properParams,
        };
        handleUpdateDataSchema(updatedSchema);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reUpdateSchema]);

  const onDateRangeChange = async (val: Date | null, type: "dateTo" | "dateFrom") => {
    try {
      let fullDataRow: boolean = false;

      if (type === "dateTo") {
        setDateTo(val);
        fullDataRow = !!dateFrom && isValid(dateFrom);
      } else {
        setDateFrom(val);
        fullDataRow = !!dateTo && isValid(dateTo);
      }

      if (val && isValid(val) && fullDataRow) {
        setReUpdateSchema((prev) => !prev);
      }
    } catch (err) {
      console.log("err onDateRangeChange() ", err);
      setFormStatus("error");
    }
  };

  const handleAddNewParam = () => {
    setParamRows((prev) => [...prev, EMPTY_PARAM_ROW]);
  };

  const handleOnNodeChange = (val: AutocompleteGroupedOption, index: number) => {
    setParamRows((prev) => {
      const result = prev.map((item, itemIndex) => {
        if (itemIndex === index) {
          if (val === null) {
            setReUpdateSchema((prev) => !prev);
            return {
              ...item,
              parameter: null,
              aggregation: "" as PieAggregationFunction,
            };
          } else {
            return {
              ...item,
              parameter: val,
              aggregation: "" as PieAggregationFunction,
            };
          }
        }

        return item;
      });
      return result;
    });
  };

  const handleOnAggregateChange = (val: string, index: number) => {
    let fullDataRow: boolean = false;
    setParamRows((prev) => {
      const result = prev.map((item, itemIndex) => {
        if (itemIndex === index) {
          fullDataRow = !!item.parameter;
          return {
            ...item,
            aggregation: val as PieAggregationFunction,
          };
        }

        return item;
      });
      return result;
    });

    if (fullDataRow) {
      setReUpdateSchema((prev) => !prev);
    }
  };

  const handleOnLegendChange = (val: AutocompleteGroupedOption) => {
    setSelectedLegend(val);
    setReUpdateSchema((prev) => !prev);
    if (!paramRows.length) {
      setParamRows([EMPTY_PARAM_ROW]);
    }
  };
  const handleOnConnectionChange = (val: SelectOption[]) => {
    setSelectedConnections(val || []);
    setParamRows([]);
    if (!val.length) {
      setReUpdateSchema((prev) => !prev);
    }
  };

  const handleRemoveParamRow = (index: number) => {
    setParamRows((prev) => prev.filter((_, rowIndex) => index !== rowIndex));
    setReUpdateSchema((prev) => !prev);
  };

  if (!parameters?.length) {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("You don't have access to any active connections")}
        </Typography>
      </Box>
    );
  }

  if (formStatus === "error") {
    return (
      <Box component="div">
        <Typography variant="h3" color="error">
          {t("Something went wrong")}
        </Typography>
      </Box>
    );
  }

  return (
    <Stack
      css={styles.contentBreak}
      spacing={3}
      alignItems="center"
      justifyContent="center"
    >
      <Stack css={styles.width100} spacing={3} direction={{ xs: "column", sm: "row" }}>
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date from")}
          value={dateFrom}
          onChange={(val) => onDateRangeChange(val, "dateFrom")}
        />
        <DateAndTimePicker
          css={styles.width100}
          label={t("Date to")}
          value={dateTo}
          onChange={(val) => onDateRangeChange(val, "dateTo")}
        />
      </Stack>

      <Autocomplete
        css={styles.width100}
        label={t("Selected Connections")}
        multiple
        options={connectionOptions}
        value={selectedConnections}
        handleOnChange={handleOnConnectionChange}
        disabled={formStatus === "loading"}
      />

      {selectedConnections.length ? (
        <Stack css={styles.width100} direction="row" spacing={3} alignItems="center">
          <Box component="div" style={{ flex: 1 }}>
            <AutocompleteGrouped
              css={styles.width100}
              options={nodeOptions}
              label={t("Legend")}
              value={selectedLegend}
              handleOnChange={handleOnLegendChange}
            />
          </Box>
          {selectedLegend ? (
            <Tooltip title={t("Add New Parameter")}>
              <IconButton css={styles.marginLeftAuto} onClick={handleAddNewParam}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Stack>
      ) : null}

      {selectedLegend && paramRows.length ? (
        <Stack
          css={[styles.width100, styles.overflowYAuto]}
          sx={{ maxHeight: "250px" }}
          gap={3}
        >
          {paramRows.map((item, index) => (
            <Stack
              key={`item-index-${index}`}
              direction="row"
              gap={3}
              alignItems="center"
            >
              <AutocompleteGrouped
                css={styles.width100}
                label={`${t("Parameter")} ${index + 1}`}
                options={nodeOptions}
                value={item.parameter}
                handleOnChange={(e: AutocompleteGroupedOption) =>
                  handleOnNodeChange(e, index)
                }
                disabled={formStatus === "loading"}
              />
              <Select
                selectOptions={
                  paramMapping?.[item.parameter?.value || ""]?.type === "string"
                    ? AGGREGATE_STRING_OPTIONS
                    : AGGREGATE_OPTIONS
                }
                label={`${t("Aggregate Function")} ${index + 1}`}
                value={item.aggregation}
                onChange={(e) => handleOnAggregateChange(e.target.value, index)}
                disabled={formStatus === "loading" || !item.parameter}
              />
              {index === 0 ? null : (
                <Tooltip title={t("Remove Parameter")}>
                  <IconButton
                    css={styles.marginLeftAuto}
                    onClick={() => handleRemoveParamRow(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Stack>
  );
};

export default EditPieChartDataSchema;

const handleGetParamOptions = (
  parameters: ExcellenceParameter[],
  selectedConnectionsArr: SelectOption[]
): AutocompleteGroupedOption[] => {
  const result =
    parameters
      .map((item) => ({
        groupName: item.container,
        value: item.id,
        description: item.name,
      }))
      .filter((node) =>
        selectedConnectionsArr.some((sub) => sub.value === node.groupName)
      ) || [];

  const resultWithGroupNames = result.map((item) => {
    const found = selectedConnectionsArr.find((conn) => conn.value === item.groupName);
    const name = found?.description || item.groupName;
    return {
      ...item,
      groupName: name,
    };
  });

  return resultWithGroupNames;
};
