import { Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../Global/Constants/yupConstants";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import TextField from "../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../Components/MaterialUI/Button";
import Alert from "../../../Components/MaterialUI/Alert";
import { LongPlanningProductRow } from "./longTermPlanningUtils";
import callApi from "../../../Api/callApi";
import {
  postQueryNeoperlProduct,
  putQueryNeoperlProduct,
  PutQueryNeoperlProduct,
} from "./longTermEndpoints";
import { useAuthedContext } from "../../../context/AuthContext";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  product_name: YUP_REQUIRED_STRING,
  quantity: YUP_REQUIRED_STRING,
});

type FormValues = {
  product_name: string;
  quantity: number | string;
};

interface LongTermProductFormProps {
  product: LongPlanningProductRow | null;
  onSubmit: () => Promise<void>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const LongTermProductForm: React.FC<LongTermProductFormProps> = ({
  product,
  onSubmit,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const initialValues: FormValues = {
    product_name: product?.product_name || "",
    quantity: product?.quantity || "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      if (product?.product_id) {
        await handleEdit(values, product.product_id);
      } else {
        await handleAdd(values);
      }
      await onSubmit();

      setFormStatus("success");
      setUnsavedChanges(false);
      setAlertMessage(t("Success!"));
    } catch (err) {
      console.log("CreateDynamicGridLayoutForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleEdit = async (values: FormValues, productID: string) => {
    const input: PutQueryNeoperlProduct = {
      product_name: values.product_name,
      quantity: +values.quantity,
    };
    await callApi<string>({
      query: putQueryNeoperlProduct(productID, input),
      auth: { setAuthedUser },
    });
  };

  const handleAdd = async (values: FormValues) => {
    const input: PutQueryNeoperlProduct = {
      product_name: values.product_name,
      quantity: +values.quantity,
    };
    await callApi<string>({
      query: postQueryNeoperlProduct(input),
      auth: { setAuthedUser },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="product_name"
                  label="Name"
                  error={touched["product_name"] && !!errors["product_name"]}
                  helperText={touched["product_name"] && errors["product_name"]}
                  onChange={handleChange}
                  value={values.product_name}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="quantity"
                  label="Quantity"
                  error={touched["quantity"] && !!errors["quantity"]}
                  helperText={touched["quantity"] && errors["quantity"]}
                  onChange={handleChange}
                  value={values.quantity}
                  numberField
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={3} alignItems="center" justifyContent="center">
                  <Button
                    type="submit"
                    loading={formStatus === "loading"}
                    disabled={formStatus === "success"}
                  >
                    {product?.product_id ? "Edit " : "Create New"} Product
                  </Button>
                  <Alert
                    message={alertMessage || ""}
                    showAlert={!!alertMessage}
                    severity={formStatus}
                  />
                </Stack>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default LongTermProductForm;
