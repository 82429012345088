import { PidWorkflowDataField } from "../../../pidWorkflowUtils";
import {
  PID_FLOW_NODE_TYPE,
  PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  PidSharedNodeData,
} from "../pidNodesUtils";
import { Node } from "reactflow";

export type PidWorkflowsInputNodeType = Node & {
  data: PidWorkflowsInputNodeData;
};
export type PidWorkflowsInputNodeData = PidSharedNodeData & {
  dataField: PidWorkflowDataField;
  altName: string;
};
export const PID_WORKFLOW_INPUT_NODE_CONFIG = {
  ...PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  type: PID_FLOW_NODE_TYPE.Input,
  style: {
    ...PID_WORKFLOW_DEFAULT_NODE_CONFIG.style,
    height: 100,
    zIndex: 4,
  },
};
