import { Query } from "../callApi";

export const getQueryMaterials = (): Query => ({
  endpoint: "supply_chain/materials/materials",
  method: "GET",
});

export const getQueryErpClassifications = (): Query => ({
  endpoint: "supply_chain/erp/classifications/tree",
  method: "GET",
});

export const getQuerySupplyProducts = (): Query => ({
  endpoint: "supply_chain/products/products",
  method: "GET",
});

export const getQuerySupplySemiProducts = (): Query => ({
  endpoint: "supply_chain/materials/semi-products",
  method: "GET",
});
