import { SerializedStyles, css } from "@emotion/react";
import { ExcellenceChartOption, ExcellenceWidgetItemMode } from "./excellenceUtils";
import ExcellenceLineChart from "./ExcellenceCharts/ExcellenceLineChart/ExcellenceLineChart";
import ExcellenceBarChart from "./ExcellenceCharts/ExcellenceBarChart/ExcellenceBarChart";
import ExcellenceTable from "./ExcellenceCharts/ExcellenceTable/ExcellenceTable";
import ExcellencePieChart from "./ExcellenceCharts/ExcellencePieChart/ExcellencePieChart";
import ExcellenceRadarChart from "./ExcellenceCharts/ExcellenceRadarChart/ExcellenceRadarChart";
import ExcellenceAreaChart from "./ExcellenceCharts/ExcellenceAreaChart/ExcellenceAreaChart";
import ExcellenceTextWidget from "./ExcellenceCharts/ExcellenceTextWidget/ExcellenceTextWidget";
import ExcellenceGauge from "./ExcellenceCharts/ExcellenceGauge/ExcellenceGauge";
import ExcellenceIndicator from "./ExcellenceCharts/ExcellenceIndicator/ExcellenceIndicator";
import ExcellenceBoxPlot from "./ExcellenceCharts/ExcellenceBoxPlot/ExcellenceBoxPlot";
import ExcellenceHeatmap from "./ExcellenceCharts/ExcellenceHeatmap/ExcellenceHeatmap";
import ExcellenceCalendarWidget from "./ExcellenceCharts/ExcellenceCalendarWidget/ExcellenceCalendarWidget";
import ExcellenceFunnel from "./ExcellenceCharts/ExcellenceFunnel/ExcellenceFunnel";
import ExcellenceNetwork from "./ExcellenceCharts/ExcellenceNetwork/ExcellenceNetwork";
import ExcellenceSankey from "./ExcellenceCharts/ExcellenceSankey/ExcellenceSankey";
import ExcellenceScatterPlot from "./ExcellenceCharts/ExcellenceScatterPlot/ExcellenceScatterPlot";
import ExcellenceSunburst from "./ExcellenceCharts/ExcellenceSunburst/ExcellenceSunburst";
import ExcellenceSwarmPlot from "./ExcellenceCharts/ExcellenceSwarmPlot/ExcellenceSwarmPlot";
import ExcellenceTimeRange from "./ExcellenceCharts/ExcellenceTimeRange/ExcellenceTimeRange";
import ExcellenceTreeMap from "./ExcellenceCharts/ExcellenceTreeMap/ExcellenceTreeMap";
import { Box, Typography, useTheme } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import {
  WIDGET_GRID_HEADING_MENU_HEIGHT,
  WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM,
} from "../../LargeComponents/WidgetsGrid/widgetsGridUtils";
import ExcellenceTimeChart from "./ExcellenceCharts/ExcellenceTimeChart/ExcellenceTimeChart";
import { useEffect, useState } from "react";
import { ExcellenceParameter } from "../../../GraphQL/Excellence/graphQLTypesExcellence";

const TITLE_HEIGHT = "24px";

const cssStyles = {
  chartStyle: css({
    width: "100%",
    height: `calc(100% - ${WIDGET_GRID_HEADING_MENU_HEIGHT} - ${WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM})`,
  }),
  itemTitle: css({
    height: TITLE_HEIGHT,
    paddingLeft: "20px",
  }),
};
interface ExcellenceChartItemProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  chart: ExcellenceChartOption;
  isStatic?: boolean;
  title: string;
  widgetLoading: boolean;
  widgetModes?: ExcellenceWidgetItemMode[];
  widgetIndex?: number;
  isUpdated?: boolean;
  setUpdatedWidgetToNull?: () => void;
  parameters: ExcellenceParameter[];
}

const ExcellenceChartItem: React.FC<ExcellenceChartItemProps> = ({
  className,
  chart,
  isStatic,
  title,
  widgetLoading,
  widgetModes,
  widgetIndex,
  isUpdated,
  setUpdatedWidgetToNull,
  parameters,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles, ...cssLayoutStyles };
  const [resetChartFetch, setResetChartFetch] = useState<boolean>(false);

  const foundMode = widgetModes?.find((item) => item?.itemIndex === widgetIndex);

  useEffect(() => {
    if (isUpdated && setUpdatedWidgetToNull) {
      setResetChartFetch((prev) => !prev);
      setUpdatedWidgetToNull();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated, chart]);

  // Excellence widgets
  if (chart.lineChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceLineChart
          className={className}
          css={styles.chartStyle}
          configuration={chart.lineChart.config}
          data={chart.lineChart.data}
          isStatic={isStatic}
          resetChartFetch={resetChartFetch}
          // @ts-ignore
          schema={chart.lineChart.dataSchema}
          parameters={parameters}
        />
      </Box>
    );
  }

  if (chart.timeChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <ExcellenceTimeChart
          className={className}
          css={styles.chartStyle}
          timeChart={chart.timeChart}
          isStatic={isStatic}
          loading={widgetLoading}
          chartMode={foundMode || null}
          resetChartFetch={resetChartFetch}
          titleComponent={
            <Typography
              css={styles.itemTitle}
              variant="h3"
              sx={{ color: theme.palette.customColors.greyText }}
            >
              {title}
            </Typography>
          }
        />
      </Box>
    );
  }

  if (chart.barChart || chart.columnChart) {
    const dataToUse = chart.barChart?.data || chart.columnChart?.data;
    const configToUse = chart.barChart?.config || chart.columnChart?.config;
    const schemaToUse = chart.columnChart?.dataSchema || chart.barChart?.dataSchema;

    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceBarChart
          className={className}
          css={styles.chartStyle}
          resetChartFetch={resetChartFetch}
          isStatic={isStatic}
          configuration={configToUse!}
          data={dataToUse!}
          // @ts-ignore
          schema={schemaToUse}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.table) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceTable
          className={className}
          css={styles.chartStyle}
          resetChartFetch={resetChartFetch}
          isStatic={isStatic}
          configuration={chart.table?.config}
          data={chart.table.data}
          schema={chart.table.dataSchema}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.pieChart || chart.donutChart) {
    const dataToUse = chart.donutChart?.data || chart.pieChart?.data;
    const configToUse = chart.donutChart?.config || chart.pieChart?.config;
    const schemaToUse = chart.pieChart?.dataSchema || chart.donutChart?.dataSchema;
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellencePieChart
          className={className}
          css={styles.chartStyle}
          configuration={configToUse!}
          data={dataToUse!}
          isStatic={isStatic}
          isDonut={!!chart.donutChart?.config}
          // @ts-ignore
          schema={schemaToUse || null}
          resetChartFetch={resetChartFetch}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.radarChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceRadarChart
          className={className}
          css={styles.chartStyle}
          configuration={chart.radarChart.config}
          data={chart.radarChart.data}
          isStatic={isStatic}
          schema={chart.radarChart?.dataSchema}
          resetChartFetch={false}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.areaChart) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceAreaChart
          className={className}
          css={styles.chartStyle}
          configuration={chart.areaChart.config}
          data={chart.areaChart.data}
          isStatic={isStatic}
          schema={chart.areaChart?.dataSchema}
          resetChartFetch={false}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.textWidget) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceTextWidget
          className={className}
          css={styles.chartStyle}
          configuration={chart.textWidget.config}
          data={null}
          isStatic={isStatic}
          schema={chart.textWidget.dataSchema}
        />
      </Box>
    );
  }
  if (chart.gaugeWidget) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceGauge
          className={className}
          css={styles.chartStyle}
          configuration={chart.gaugeWidget.config}
          data={chart.gaugeWidget.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.indicatorWidget) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <ExcellenceIndicator
          className={className}
          css={styles.chartStyle}
          configuration={chart.indicatorWidget.config}
          data={chart.indicatorWidget.data}
          isStatic={isStatic}
          resetChartFetch={resetChartFetch}
          // @ts-ignore
          schema={chart.indicatorWidget.dataSchema}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.boxPlot) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceBoxPlot
          className={className}
          css={styles.chartStyle}
          configuration={chart.boxPlot.config}
          data={chart.boxPlot.data}
          isStatic={isStatic}
          resetChartFetch={resetChartFetch}
          // @ts-ignore
          schema={chart.boxPlot.dataSchema}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.heatMap) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceHeatmap
          className={className}
          css={styles.chartStyle}
          configuration={chart.heatMap.config}
          data={chart.heatMap.data}
          isStatic={isStatic}
          resetChartFetch={resetChartFetch}
          // @ts-ignore
          schema={chart.heatMap.dataSchema}
          parameters={parameters}
        />
      </Box>
    );
  }
  if (chart.calendar) {
    return (
      <ExcellenceCalendarWidget
        className={className}
        css={styles.chartStyle}
        configuration={chart.calendar.config}
        data={chart.calendar.data}
        isStatic={isStatic}
      />
    );
  }
  if (chart.funnel) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceFunnel
          className={className}
          css={styles.chartStyle}
          configuration={chart.funnel.config}
          data={chart.funnel.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.network) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceNetwork
          className={className}
          css={styles.chartStyle}
          configuration={chart.network.config}
          data={chart.network.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.sankey) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceSankey
          className={className}
          css={styles.chartStyle}
          configuration={chart.sankey.config}
          data={chart.sankey.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.scatterPlot) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceScatterPlot
          className={className}
          css={styles.chartStyle}
          configuration={chart.scatterPlot.config}
          data={chart.scatterPlot.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.sunburst) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceSunburst
          className={className}
          css={styles.chartStyle}
          configuration={chart.sunburst.config}
          data={chart.sunburst.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.swarmPlot) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceSwarmPlot
          className={className}
          css={styles.chartStyle}
          configuration={chart.swarmPlot.config}
          data={chart.swarmPlot.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.timeRange) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceTimeRange
          className={className}
          css={styles.chartStyle}
          configuration={chart.timeRange.config}
          data={chart.timeRange.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }
  if (chart.treeMap) {
    return (
      <Box component="div" css={[styles.width100, styles.height100]}>
        <Typography
          css={styles.itemTitle}
          variant="h3"
          sx={{ color: theme.palette.customColors.greyText }}
        >
          {title}
        </Typography>
        <ExcellenceTreeMap
          className={className}
          css={styles.chartStyle}
          configuration={chart.treeMap.config}
          data={chart.treeMap.data}
          isStatic={isStatic}
        />
      </Box>
    );
  }

  return null;
};

export default ExcellenceChartItem;
