import { Layout } from "react-grid-layout";

export type WidgetGridItemLayout = {
  xl: Layout;
  lg: Layout;
  md: Layout;
  sm: Layout;
  xs: Layout;
};

export type WidgetGridItem<T extends Record<string, any>> = T & {
  layout: WidgetGridItemLayout;
  isLocked?: boolean
};

export const WIDGET_GRID_HEADING_MENU_HEIGHT = "25px";
export const WIDGET_GRID_HEADING_MENU_MARGIN_BOTTOM = "4px";
