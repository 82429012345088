import {
  PID_FLOW_NODE_TYPE,
  PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  PidSharedNodeData,
} from "../pidNodesUtils";
import { Node } from "reactflow";

export type PidWorkflowsTextNodeType = Node & {
  data: PidWorkflowsTextNodeData;
};
export type PidWorkflowsTextNodeData = PidSharedNodeData & {};
export const PID_WORKFLOW_TEXT_NODE_CONFIG = {
  ...PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  type: PID_FLOW_NODE_TYPE.Text,
  style: {
    ...PID_WORKFLOW_DEFAULT_NODE_CONFIG.style,
    height: 90,
    zIndex: 5,
  },
};
