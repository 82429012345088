import { Stack, Box, Typography, useTheme, IconButton } from "@mui/material";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { FormStatuses } from "../../Global/Types/commonTypes";
import ShiftPlanningCalendar from "../../Components/PageComponents/ShiftPlanning/ShiftPlanningCalendar";
import { ShiftPlan } from "../../Components/PageComponents/ShiftPlanning/shiftPlanningUtils";
import { useAuthedContext } from "../../context/AuthContext";
import callApi from "../../Api/callApi";
import Alert from "../../Components/MaterialUI/Alert";
import { getQueryDemoShifts } from "../../Components/PageComponents/ShiftPlanning/tempApiCalls";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../Layout/layoutVariables";
import Modal from "../../Components/MaterialUI/Modal";
import ShiftPlanningAddModal from "../../Components/PageComponents/ShiftPlanning/ShiftPlanningAddModal";

const ShiftPlanning: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };

  const [shiftPlans, setShiftPlans] = useState<ShiftPlan[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses>("loading");
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setAuthedUser } = useAuthedContext();
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  useEffect(() => {
    (async () => {
      setAlertMessage("Fetching Data...");
      setAlertStatus("loading");

      await handleFetch();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(
        <CreateNewTaskMenu
          handleAdd={() => {
            setOpenAddModal(true);
          }}
        />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleFetch = async () => {
    try {
      setAlertMessage(null);
      setAlertStatus(null);

      const result = await callApi<{ data: ShiftPlan }[]>({
        query: getQueryDemoShifts,
        auth: { setAuthedUser },
      });

      setShiftPlans(result.map((item) => item.data));
    } catch (err) {
      console.log("err ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <Stack
        css={[styles.width100, styles.contentBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        <Typography variant="h2">Shift Planning</Typography>
        <Box component="div" />
      </Stack>

      <Alert severity={alertStatus} showAlert={!!alertMessage} message={alertMessage} />

      {alertStatus !== "loading" ? <ShiftPlanningCalendar plans={shiftPlans} /> : null}

      <Modal
        open={openAddModal}
        fullWidth
        label={"Add Task"}
        onClose={() => setOpenAddModal(false)}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        <ShiftPlanningAddModal
          handleOnCreate={handleFetch}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Modal>
    </>
  );
};

export default ShiftPlanning;

interface CreateNewTaskMenuProps {
  handleAdd: () => void;
}
const CreateNewTaskMenu: React.FC<CreateNewTaskMenuProps> = ({ handleAdd }) => {
  return (
    <Stack alignItems="center">
      <IconButton aria-label="create new project" onClick={handleAdd}>
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        Add
      </Typography>
    </Stack>
  );
};
