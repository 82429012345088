import { Box } from "@mui/material";
import { SerializedStyles } from "@emotion/react";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { AnomalyDetectionTableRow, colSchema } from "./anomalyDetectionUtils";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";

type ColumnsToHide = keyof AnomalyDetectionTableRow | "actions";

interface AnomalyDetectionTableProps {
  css?: (SerializedStyles | false)[] | SerializedStyles;
  className?: string;
  rows: AnomalyDetectionTableRow[];
  loading?: boolean;
  hideColumns?: ColumnsToHide[];
}

const AnomalyDetectionTable: React.FC<AnomalyDetectionTableProps> = ({
  className,
  rows,
  loading,
  hideColumns = [],
}) => {
  const filteredColSchema = colSchema
    .map((group) => ({
      ...group,
      columns: group.columns.filter(
        (col) => !hideColumns.includes(col.id as ColumnsToHide)
      ),
    }))
    .filter((group) => group.columns.length > 0);

  return (
    <Box component="div" className={className}>
      <ResponsiveTableGrid
        rows={rows}
        colSchema={filteredColSchema as TableGridColumnSchema[]}
        responsive="desktop"
        loading={loading}
      />
    </Box>
  );
};

export default AnomalyDetectionTable;
