import { Box, Grid, useTheme } from "@mui/material";
import { Formik, FormikHelpers } from "formik";
import { AddNewBulletChart } from "../oEEUtils";
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { object } from "yup";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { Stack } from "@mui/system";
import Button from "../../../MaterialUI/Button";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import {
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../../Global/Types/commonTypes";
import { useState } from "react";
import Autocomplete from "../../../MaterialUI/FormFields/Autocomplete";
import Alert from "../../../MaterialUI/Alert";
import TextField from "../../../MaterialUI/FormFields/TextFields";

const fieldValidation = object({
  title: YUP_REQUIRED_STRING,
  assetsIds: YUP_REQUIRED_STRING,
});

const initialValues: AddNewBulletChart = {
  title: "",
  assetsIds: "",
};

interface OEEAddDashboardFormProps {
  addedAssetsToCategories: SelectOption[];
}

const OEEAddDashboardForm: React.FC<OEEAddDashboardFormProps> = ({
  addedAssetsToCategories,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const [selectedAssets, setSelectedAssets] = useState<AutocompleteOption[]>([]);
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const handleFormSubmit = (values: AddNewBulletChart) => {
    console.log(values);
    setFormStatus("loading");
    setAlertMessage(null);
  };

  const handleOnAssetsChange = (
    val: SelectOption[],
    setFieldValue: FormikHelpers<AddNewBulletChart>["setFieldValue"]
  ) => {
    setSelectedAssets(val || []);
    setFieldValue("assetsIds", val.map((asset) => asset.value).join(", "));
  };

  return (
    <Box component="div" pr={2}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
      >
        {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="title"
                  label="Title"
                  error={touched["title"] && !!errors["title"]}
                  helperText={touched["title"] && errors["title"]}
                  onChange={handleChange}
                  value={values.title}
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  css={styles.width100}
                  label="Asset"
                  multiple
                  options={addedAssetsToCategories}
                  value={selectedAssets}
                  handleOnChange={(val) => handleOnAssetsChange(val, setFieldValue)}
                  disabled={formStatus === "loading"}
                />
              </Grid>
            </Grid>

            <Stack spacing={1} justifyContent={"center"} alignItems={"center"} mt={2}>
              <Button
                type="submit"
                onClick={() => handleFormSubmit(values)}
                loading={formStatus === "loading"}
                css={[styles.width100, styles.widthLimit15]}
              >
                Add Dashboard
              </Button>

              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
                css={styles.reverseLabelBreak}
              />
            </Stack>
          </form>
        )}
      </Formik>
    </Box>
  );
};

export default OEEAddDashboardForm;
