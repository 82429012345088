import { useState } from "react";
import { Formik } from "formik";
import { Grid, InputAdornment, Stack, Typography } from "@mui/material";
import { object } from "yup";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import TextField from "../../../MaterialUI/FormFields/TextFields";
import DatePicker from "../../../MaterialUI/DateTimePickers/DatePicker";
import { COMMON_GRID_SPACING } from "../../../../Global/Constants/uiConstants";
import Button from "../../../MaterialUI/Button";
import Alert from "../../../MaterialUI/Alert";
import { FormStatuses, SelectOption } from "../../../../Global/Types/commonTypes";
import isValid from "date-fns/isValid";
import isBefore from "date-fns/isBefore";
import Modal from "../../../MaterialUI/Modal";
import { GanttChartDateType } from "./GanttChart";
import DateAndTimePicker from "../../../MaterialUI/DateTimePickers/DateAndTimePicker";
import TimePicker from "../../../MaterialUI/DateTimePickers/TimePicker";
import { endOfDay } from "date-fns";
import Select from "../../../MaterialUI/FormFields/Select";
import { GanttTask } from "./ganttChartUtils";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

const SELECT_OPTIONS: SelectOption[] = [
  { value: "open", description: "Open" },
  { value: "in-progress", description: "In Progress" },
  { value: "completed", description: "Completed" },
];

type FormValues = {
  name: string;
  startDate: Date | null;
  endDate: Date | null;
  originalPlannedStartDate: Date | null;
  originalPlannedEndDate: Date | null;
  committedPlannedStartDate: Date | null;
  committedPlannedEndDate: Date | null;
  status: string;
  priority: number;
  progress: number;
};

interface GanttChartFormProps {
  selectedTask: GanttTask;
  setEditTaskModal: React.Dispatch<React.SetStateAction<boolean>>;
  tasks: GanttTask[];
  setTasks: React.Dispatch<React.SetStateAction<GanttTask[] | undefined>>;
  onTaskEdit?: (task: GanttTask) => void;
  onTaskDelete?: (task: GanttTask) => void;
  dateType: GanttChartDateType;
  project: GanttTask | undefined;
}

const GanttChartForm: React.FC<GanttChartFormProps> = ({
  tasks,
  selectedTask,
  setEditTaskModal,
  setTasks,
  onTaskEdit,
  onTaskDelete,
  dateType,
  project,
}) => {
  const styles = { ...cssLayoutStyles };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const initialValues: FormValues = {
    name: selectedTask.name,
    startDate: selectedTask.start,
    endDate: selectedTask.end,
    originalPlannedStartDate: selectedTask.originalPlannedStartDate,
    originalPlannedEndDate: selectedTask.originalPlannedEndDate,
    committedPlannedStartDate: selectedTask.committedPlannedStartDate,
    committedPlannedEndDate: selectedTask.committedPlannedEndDate,
    status: selectedTask.status,
    priority: selectedTask.priority,
    progress: selectedTask.progress,
  };

  const handleTaskDelete = () => {
    setTasks(
      (prevTasks) => prevTasks && prevTasks.filter((task) => task.id !== selectedTask.id)
    );

    if (onTaskDelete) onTaskDelete(selectedTask);
    setEditTaskModal(false);
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const {
        startDate,
        endDate,
        originalPlannedStartDate,
        originalPlannedEndDate,
        committedPlannedStartDate,
        committedPlannedEndDate,
        status,
        priority,
      } = values;

      const selectedProject =
        selectedTask.type === "project"
          ? selectedTask
          : tasks.find(
              (task) => task.type === "project" && task.name === selectedTask.project
            );

      if (!selectedProject) {
        setFormStatus("warning");
        setAlertMessage("Project for this task not found");
        return;
      }

      if (!startDate || !isValid(startDate)) {
        setFormStatus("warning");
        setAlertMessage("You must select a valid Real start date");
        return;
      }
      if (startDate && isBefore(startDate, selectedProject.start)) {
        setFormStatus("warning");
        setAlertMessage("Start date cannot be before the project's Real start date");
        return;
      }
      if (!isValid(endDate)) {
        setFormStatus("warning");
        setAlertMessage("You must select a valid Real end date");
        return;
      }
      if (startDate && endDate && isBefore(endDate, startDate)) {
        setFormStatus("warning");
        setAlertMessage("Real end date cannot be before Real start date");
        return;
      }

      if (
        originalPlannedStartDate &&
        isValid(originalPlannedStartDate) &&
        originalPlannedEndDate &&
        isValid(originalPlannedEndDate) &&
        isBefore(originalPlannedEndDate, originalPlannedStartDate)
      ) {
        setFormStatus("warning");
        setAlertMessage(
          "Original planned end date cannot be before Original planned start date"
        );
        return;
      }
      if (
        committedPlannedStartDate &&
        isValid(committedPlannedStartDate) &&
        committedPlannedEndDate &&
        isValid(committedPlannedEndDate) &&
        isBefore(committedPlannedEndDate, committedPlannedStartDate)
      ) {
        setFormStatus("warning");
        setAlertMessage(
          "Committed planned end date cannot be before Committed planned start date"
        );
        return;
      }

      const editedTask = {
        ...selectedTask,
        name: values.name,
        startDate: startDate,
        endDate: endDate,
        originalPlannedStartDate: originalPlannedStartDate,
        originalPlannedEndDate: originalPlannedEndDate,
        committedPlannedStartDate: committedPlannedStartDate,
        committedPlannedEndDate: committedPlannedEndDate,
        status: status,
        priority: priority,
      };

      if (setTasks) {
        setTasks((prevTasks) =>
          (prevTasks ?? []).map((task) =>
            task.id === selectedTask.id ? editedTask : task
          )
        );
      }

      if (onTaskEdit) onTaskEdit(editedTask);

      setEditTaskModal(false);
    } catch (err) {
      console.log("OpExEditTask handleFormSubmit err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={fieldValidation}
      >
        {({ handleSubmit, handleChange, touched, errors, values, setFieldValue }) => (
          <form
            style={{ paddingRight: "1px" }}
            css={styles.width100}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={COMMON_GRID_SPACING}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  label="Name"
                  error={touched["name"] && !!errors["name"]}
                  helperText={touched["name"] && errors["name"]}
                  onChange={handleChange}
                  value={values.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {dateType === "date" ? (
                  <DatePicker
                    css={styles.width100}
                    name="startDate"
                    label="Real start date"
                    error={touched["startDate"] && !!errors["startDate"]}
                    helperText={touched["startDate"] && errors["startDate"]}
                    onChange={(value) => setFieldValue("startDate", value)}
                    value={values.startDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "date-time" ? (
                  <DateAndTimePicker
                    css={styles.width100}
                    name="startDate"
                    label="Real start date"
                    onChange={(value) => setFieldValue("startDate", value)}
                    value={values.startDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "time" ? (
                  <TimePicker
                    css={styles.width100}
                    name="startDate"
                    label="Real start date"
                    onChange={(value) => setFieldValue("startDate", value)}
                    value={values.startDate}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                {dateType === "date" ? (
                  <DatePicker
                    css={styles.width100}
                    name="endDate"
                    label="Real end date"
                    error={touched["endDate"] && !!errors["endDate"]}
                    helperText={touched["endDate"] && errors["endDate"]}
                    onChange={(value) => setFieldValue("endDate", value)}
                    value={values.endDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "date-time" ? (
                  <DateAndTimePicker
                    css={styles.width100}
                    name="endDate"
                    label="Real end date"
                    onChange={(value) => setFieldValue("endDate", value)}
                    value={values.endDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}
                {dateType === "time" ? (
                  <TimePicker
                    css={styles.width100}
                    name="endDate"
                    label="Real end date"
                    onChange={(value) => setFieldValue("endDate", value)}
                    value={values.endDate}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                {dateType === "date" ? (
                  <DatePicker
                    css={styles.width100}
                    name="committedPlannedStartDate"
                    label="Committed planned start date"
                    error={
                      touched["committedPlannedStartDate"] &&
                      !!errors["committedPlannedStartDate"]
                    }
                    helperText={
                      touched["committedPlannedStartDate"] &&
                      errors["committedPlannedStartDate"]
                    }
                    onChange={(value) =>
                      setFieldValue("committedPlannedStartDate", value)
                    }
                    value={values.committedPlannedStartDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "date-time" ? (
                  <DateAndTimePicker
                    css={styles.width100}
                    name="committedPlannedStartDate"
                    label="Committed planned start date"
                    onChange={(value) =>
                      setFieldValue("committedPlannedStartDate", value)
                    }
                    value={values.committedPlannedStartDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "time" ? (
                  <TimePicker
                    css={styles.width100}
                    name="committedPlannedStartDate"
                    label="Original planned start date"
                    onChange={(value) =>
                      setFieldValue("committedPlannedStartDate", value)
                    }
                    value={values.committedPlannedStartDate}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                {dateType === "date" ? (
                  <DatePicker
                    css={styles.width100}
                    name="committedPlannedEndDate"
                    label="Committed planned end date"
                    error={
                      touched["committedPlannedEndDate"] &&
                      !!errors["committedPlannedEndDate"]
                    }
                    helperText={
                      touched["committedPlannedEndDate"] &&
                      errors["committedPlannedEndDate"]
                    }
                    onChange={(value) => setFieldValue("committedPlannedEndDate", value)}
                    value={values.committedPlannedEndDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "date-time" ? (
                  <DateAndTimePicker
                    css={styles.width100}
                    name="committedPlannedEndDate"
                    label="Committed planned end date"
                    onChange={(value) => setFieldValue("committedPlannedEndDate", value)}
                    value={values.committedPlannedEndDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "time" ? (
                  <TimePicker
                    css={styles.width100}
                    name="committedPlannedEndDate"
                    label="Committed planned end date"
                    onChange={(value) => setFieldValue("committedPlannedEndDate", value)}
                    value={values.committedPlannedEndDate}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                {dateType === "date" ? (
                  <DatePicker
                    css={styles.width100}
                    name="originalPlannedStartDate"
                    label="Original planned start date"
                    error={
                      touched["originalPlannedStartDate"] &&
                      !!errors["originalPlannedStartDate"]
                    }
                    helperText={
                      touched["originalPlannedStartDate"] &&
                      errors["originalPlannedStartDate"]
                    }
                    onChange={(value) => setFieldValue("originalPlannedStartDate", value)}
                    value={values.originalPlannedStartDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "date-time" ? (
                  <DateAndTimePicker
                    css={styles.width100}
                    name="originalPlannedStartDate"
                    label="Original planned start date"
                    onChange={(value) => setFieldValue("originalPlannedStartDate", value)}
                    value={values.originalPlannedStartDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "time" ? (
                  <TimePicker
                    css={styles.width100}
                    name="originalPlannedStartDate"
                    label="Original planned start date"
                    onChange={(value) => setFieldValue("originalPlannedStartDate", value)}
                    value={values.originalPlannedStartDate}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                {dateType === "date" ? (
                  <DatePicker
                    css={styles.width100}
                    name="originalPlannedEndDate"
                    label="Original planned end date"
                    error={
                      touched["originalPlannedEndDate"] &&
                      !!errors["originalPlannedEndDate"]
                    }
                    helperText={
                      touched["originalPlannedEndDate"] &&
                      errors["originalPlannedEndDate"]
                    }
                    onChange={(value) => setFieldValue("originalPlannedEndDate", value)}
                    value={values.originalPlannedEndDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "date-time" ? (
                  <DateAndTimePicker
                    css={styles.width100}
                    name="originalPlannedEndDate"
                    label="Original planned end date"
                    onChange={(value) => setFieldValue("originalPlannedEndDate", value)}
                    value={values.originalPlannedEndDate}
                    minDate={project && endOfDay(project.start)}
                  />
                ) : null}

                {dateType === "time" ? (
                  <TimePicker
                    css={styles.width100}
                    name="originalPlannedEndDate"
                    label="Original planned end date"
                    onChange={(value) => setFieldValue("originalPlannedEndDate", value)}
                    value={values.originalPlannedEndDate}
                  />
                ) : null}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  css={styles.width100}
                  name="status"
                  label="Status"
                  error={touched["status"] && !!errors["status"]}
                  helperText={touched["status"] && errors["status"]}
                  onChange={handleChange}
                  value={values.status}
                  selectOptions={SELECT_OPTIONS}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="priority"
                  label="Priority"
                  error={touched["priority"] && !!errors["priority"]}
                  helperText={touched["priority"] && errors["priority"]}
                  onChange={handleChange}
                  value={values.priority}
                  numberField
                />
              </Grid>

              {selectedTask.type === "project" && selectedTask.project ? null : (
                <Grid item xs={12} sm={6}>
                  <TextField
                    name="progress"
                    label="Progress"
                    error={touched["progress"] && !!errors["progress"]}
                    helperText={touched["progress"] && errors["progress"]}
                    onChange={handleChange}
                    value={values.progress}
                    numberField
                    InputProps={{
                      endAdornment: <InputAdornment position="start">%</InputAdornment>,
                    }}
                  />
                </Grid>
              )}

              <Grid item xs={12} mt={2}>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Button color="error" onClick={() => setOpenDeleteModal(true)}>
                    Delete
                  </Button>
                  <Button type="submit" loading={formStatus === "loading"}>
                    Save
                  </Button>
                </Stack>
              </Grid>

              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Grid>
          </form>
        )}
      </Formik>

      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        fullWidth
        maxWidth="sm"
        label="Confirm deletion"
      >
        <Stack width="100%" justifyContent="center" alignItems="flex-start" gap={2}>
          <Typography component="p" variant="body1">
            Are you sure you want to delete this task?
          </Typography>
          <Stack
            width="100%"
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            gap={1}
          >
            <Button color="error" onClick={() => setOpenDeleteModal(false)}>
              Disagree
            </Button>
            <Button onClick={handleTaskDelete} autoFocus>
              Agree
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
};

export default GanttChartForm;
