import { Stack, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";

interface CellDoubleActionButtonProps {
  handleOnViewClick: () => void;
  handleOnEditClick: () => void;
  justify?: "center" | "flex-start";
}

const CellDoubleActionButton: React.FC<CellDoubleActionButtonProps> = ({
  handleOnViewClick,
  handleOnEditClick,
  justify,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={justify || "space-between"}
      alignItems="center"
      spacing={1}
    >
      <IconButton onClick={handleOnViewClick}>
        <VisibilityIcon />
      </IconButton>
      <IconButton onClick={handleOnEditClick}>
        <EditIcon />
      </IconButton>
    </Stack>
  );
};

export default CellDoubleActionButton;
