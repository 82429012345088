import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import {
  assetDataColors,
  calculateWorkingAndNonWorkingMinutes,
  getAssetsOptions,
  HourData,
  OEEModalTitle,
  TransformedHourData,
} from "../oEEUtils";
import { barChartAseetByHoursData } from "./oEEChartsData";
import { ResponsiveBar } from "@nivo/bar";
import { parseSecondsToHMS } from "../../../../Global/Utils/commonFunctions";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Button from "../../../MaterialUI/Button";
import Modal from "../../../MaterialUI/Modal";
import EditOEEBarChartForm from "./EditOEEBarChartForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { format, subDays, addDays, isToday } from "date-fns";
import Select from "../../../MaterialUI/FormFields/Select";
import { AutocompleteOption } from "../../../../Global/Types/commonTypes";
import callApi from "../../../../Api/callApi";
import { GetQueryOEEAseetsFunctionalLocationsSnippet } from "../../../../Api/OEE/apiOEESnippets";
import { getQueryAssetsFunctionalLocations } from "../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../context/AuthContext";

interface OEEBarChartAssetByHourProps {}

const OEEBarChartAssetByHour: React.FC<OEEBarChartAssetByHourProps> = ({}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<HourData[]>([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [modalTitle, setModalTitle] = useState<OEEModalTitle | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [assetTypeOptions, setAssetTypeOptions] = useState<AutocompleteOption[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>("667020b8aafe32a08a125f2a");
  const openMenu = Boolean(menuAnchor);

  const fetchOEEData = async () => {
    try {
      const oEEAssets = await callApi<GetQueryOEEAseetsFunctionalLocationsSnippet>({
        query: getQueryAssetsFunctionalLocations,
        auth: { setAuthedUser },
      });
      setAssetTypeOptions(getAssetsOptions(oEEAssets));
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBarChartData(barChartAseetByHoursData.chartData);
  }, []);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleAssetChange = (e: React.ChangeEvent<{ value: string }>) => {
    const selectedValue = e.target.value as string;
    const selectedOption = assetTypeOptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setSelectedAsset(selectedOption.value);
    }
  };

  const handleDateChange = (direction: "prev" | "next") => {
    setSelectedDate((prevDate) =>
      direction === "prev" ? subDays(prevDate, 1) : addDays(prevDate, 1)
    );
  };

  const transformedData = barChartAseetByHoursData.chartData.map((entry) => {
    const transformedEntry: TransformedHourData = { hour: entry.hour };
    entry.values.forEach((item, index) => {
      transformedEntry[`${item.category} ${index + 1}`] = item.value;
    });
    return transformedEntry;
  });

  const sortedData = transformedData.slice().sort((a, b) => a.hour.localeCompare(b.hour));

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" mb={1}>
        <Grid item>
          <Select
            selectOptions={assetTypeOptions}
            label="Select Asset"
            onChange={(e) => handleAssetChange(e)}
            value={selectedAsset}
          />
        </Grid>

        <Grid item mr={2}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <IconButton
                aria-label="previous day"
                onClick={() => handleDateChange("prev")}
                size="small"
              >
                <ArrowBackIosIcon css={styles.greyIcon} fontSize="small" />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body2" color={theme.palette.grey[700]}>
                {format(selectedDate, "dd.MM.yyyy")}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="next day"
                onClick={() => handleDateChange("next")}
                size="small"
                disabled={isToday(selectedDate)}
              >
                <ArrowForwardIosIcon css={styles.greyIcon} fontSize="small" />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="widget setting menu"
                onClick={handleOpenMenu}
                size="small"
              >
                <TuneOutlinedIcon css={styles.greyIcon} fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {barChartData.length > 0 ? (
        sortedData.map((entry, index) => {
          const entryKeys = Object.keys(entry).filter((key) => key !== "hour");
          const { actualWorkingMinutes, maxPossibleWorkingMinutes } =
            calculateWorkingAndNonWorkingMinutes(entry);

          return (
            <Box
              component="div"
              key={index}
              height={index === 0 || index === sortedData.length - 1 ? "45px" : "25px"}
            >
              <ResponsiveBar
                data={[entry]}
                keys={entryKeys}
                indexBy="hour"
                margin={{
                  top: index === 0 ? 20 : 0,
                  right: 50,
                  bottom: index === sortedData.length - 1 ? 20 : 0,
                  left: 40,
                }}
                layout="horizontal"
                colors={({ id }) =>
                  assetDataColors[
                    (id as string).split(" ")[0] as keyof typeof assetDataColors
                  ]
                }
                theme={{
                  text: {
                    fill: theme.palette.common.black,
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.common.white,
                    },
                  },
                }}
                padding={0.2}
                label={({ id }) =>
                  id.toString().includes("Working") || id.toString().includes("SpeedLoss")
                    ? ""
                    : id.toString().split(" ")[0]
                }
                tooltip={({ id, value }) => (
                  <div
                    style={{
                      padding: "3px 10px",
                      background: theme.palette.common.white,
                      border: `1px solid ${theme.palette.divider}`,
                    }}
                  >
                    <strong>{id.toString().split(" ")[0]}</strong>:{" "}
                    {parseSecondsToHMS(value)}
                  </div>
                )}
                axisTop={
                  index === 0
                    ? {
                        tickValues: [15, 30, 45, 60],
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: -36,
                      }
                    : null
                }
                axisRight={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: "middle",
                  legendOffset: 40,
                  format: () => `${actualWorkingMinutes} / ${maxPossibleWorkingMinutes}`,
                }}
                axisBottom={
                  index === sortedData.length - 1
                    ? {
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: 32,
                      }
                    : null
                }
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legendPosition: "middle",
                  legendOffset: -40,
                }}
              />
            </Box>
          );
        })
      ) : (
        <Typography variant="h5" ml={1} mb={3}>
          Loading...
        </Typography>
      )}

      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
          <Stack alignItems="center">
            <IconButton
              aria-label="edit loss"
              onClick={() => setModalTitle("Edit OEE Chart")}
            >
              <EditOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Edit
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="copy widget"
              onClick={() => setModalTitle("Copy OEE Chart")}
            >
              <DifferenceOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center">
              Copy
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="delete loss"
              onClick={() => setModalTitle("Delete OEE Chart")}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Delete
            </Typography>
          </Stack>
        </Stack>
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth={modalTitle === "Delete OEE Chart" ? "sm" : "md"}
        label={modalTitle}
      >
        {modalTitle === "Edit OEE Chart" ? (
          <EditOEEBarChartForm isForSingleAssetByHour={true} />
        ) : null}
        {modalTitle === "Delete OEE Chart" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>Clicking the below button will delete this chart</Typography>
            <Button>Confirm Delete</Button>
          </Stack>
        ) : null}
      </Modal>
    </>
  );
};

export default OEEBarChartAssetByHour;
