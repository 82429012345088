import { useBlocker } from "react-router-dom";
import Modal from "../Components/MaterialUI/Modal";
import Button from "../Components/MaterialUI/Button";
import { Stack, Typography } from "@mui/material";

interface NavigationBlockerProps {
  message: string;
  unsavedChanges: boolean;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const NavigationBlocker: React.FC<NavigationBlockerProps> = ({
  message,
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const blocker = useBlocker(unsavedChanges);

  const handleClose = () => {
    if (blocker?.reset) {
      blocker.reset();
    }
  };
  const handleConfirm = () => {
    if (blocker?.proceed) {
      setUnsavedChanges(false);
      blocker.proceed();
    }
  };

  return (
    <Modal open={blocker.state === "blocked"} onClose={handleClose} label="Are you sure?">
      <Typography variant="body1" mb={3}>
        {message}
      </Typography>

      <Stack spacing={3} direction="row" alignItems="center" justifyContent="center">
        <Button onClick={handleConfirm} color="error">
          Proceed and Lose Changes
        </Button>
      </Stack>
    </Modal>
  );
};

export default NavigationBlocker;
