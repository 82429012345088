import { Stack, IconButton } from "@mui/material";
import { GetQueryMaintenanceTasksSnippet } from "../../../../Api/Maintenance/apiMaintenanceSnippets";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  MaintenanceMachineTask,
  MachineTaskStatus,
  MaintenanceMachineTaskTemplateMutation,
} from "../../../../Api/Maintenance/apiMaintenanceDataTypes";
import { SelectOption } from "../../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../../Global/Utils/commonFunctions";
import { addMinutes, formatDistance } from "date-fns";
import CellDoubleActionButton from "../../../SmallComponents/TableGrid/MobileTableGrid/CellDoubleActionButton";

export type MaintenancePlanModalType = {
  type:
    | "Task Comments"
    | "Add Comment"
    | "Task Documents"
    | "Add Document"
    | "Maintenance Responsible"
    | "Add Responsible"
    | "Task Information"
    | "Edit Task"
    | "Delete Task"
    | "Working Hours"
    | "Add Working Hours";
  taskID: string;
} | null;
export type MaintenancePlanAddEditFormResult = {
  template?: MaintenanceMachineTaskTemplateMutation;
  task?: MaintenanceMachineTask;
};

export interface MaintenancePlanTableTaskRow {
  id: string;
  setup: string;
  taskDescription: string;
  startDate: string;
  plannedDuration: string;
  executionDuration: string;
  status: MachineTaskStatus;
  comments: React.ReactNode;
  responsible: React.ReactNode;
  documents: React.ReactNode;
  workHours: React.ReactNode;
  repetitive: boolean;
  extraDescription: string;
}

export type MaintenancePlanTemplateApplyChangesOption =
  | "All Open Tasks"
  | "All Open and In Progress Tasks";
export const maintenancePlanTemplateApplyChangesOptions = handleGetSelectOption([
  "All Open Tasks",
  "All Open and In Progress Tasks",
]);

export type MaintenanceMachineTaskType = "repetitive" | "task";
export const maintenanceMachineTaskTypeOptions: SelectOption[] = [
  {
    value: "repetitive",
    description: "Repetitive Task Template",
  },
  {
    value: "task",
    description: "Single Task",
  },
];

export const handleGetMaintenancePlanTableRows = (
  onActionClick: (data: MaintenancePlanModalType) => void,
  machineTasksData: GetQueryMaintenanceTasksSnippet | undefined
): MaintenancePlanTableTaskRow[] => {
  if (!machineTasksData) {
    return [];
  }

  return machineTasksData.map((task) => ({
    id: task.id,
    setup: task.machine,
    taskDescription: task.task_description,
    startDate: task.start_date,
    plannedDuration: task.planned_duration
      ? formatDistance(
          new Date(task.start_date),
          addMinutes(new Date(task.start_date), task.planned_duration)
        )
      : "",
    executionDuration: task.execution_duration
      ? formatDistance(
          new Date(task.start_date),
          addMinutes(new Date(task.start_date), task.execution_duration)
        )
      : "",
    // @ts-ignore
    status: task.status === "InProgress" ? "In Progress" : task.status,
    comments: (
      <CellDoubleActionButton
        handleOnViewClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Task Comments",
          });
        }}
        handleOnEditClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Add Comment",
          });
        }}
      />
    ),
    responsible: (
      <CellDoubleActionButton
        handleOnViewClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Maintenance Responsible",
          });
        }}
        handleOnEditClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Add Responsible",
          });
        }}
      />
    ),
    documents: (
      <CellDoubleActionButton
        handleOnViewClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Task Documents",
          });
        }}
        handleOnEditClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Add Document",
          });
        }}
      />
    ),
    workHours: (
      <CellDoubleActionButton
        handleOnViewClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Working Hours",
          });
        }}
        handleOnEditClick={() => {
          onActionClick({
            taskID: task.id,
            type: "Add Working Hours",
          });
        }}
      />
    ),
    repetitive: !!task.task_template_id,
    extraDescription: task.extra_description || "",
    actions: (
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <IconButton
          onClick={() => {
            onActionClick({
              taskID: task.id,
              type: "Task Information",
            });
          }}
        >
          <VisibilityIcon />
        </IconButton>

        <IconButton
          onClick={() => {
            onActionClick({
              taskID: task.id,
              type: "Edit Task",
            });
          }}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          onClick={() => {
            onActionClick({
              taskID: task.id,
              type: "Delete Task",
            });
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Stack>
    ),
  }));
};
