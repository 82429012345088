import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { SankeyData } from "./sankeyTypes";
import { ResponsiveSankey } from "@nivo/sankey";
import { getChartColors } from "../../nivoTheme";

interface SankeyProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: SankeyData;
  isStatic?: boolean;
}

const Sankey: React.FC<SankeyProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 10 : 30,
    right: isStatic ? 10 : 140,
    bottom: isStatic ? 10 : 20,
    left: isStatic ? 10 : 50,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveSankey
        data={data}
        margin={chartMargin}
        align="justify"
        colors={getChartColors()}
        nodeOpacity={1}
        nodeHoverOthersOpacity={0.35}
        nodeThickness={18}
        nodeSpacing={24}
        nodeBorderWidth={0}
        nodeBorderColor={{
          from: "color",
          modifiers: [["darker", 0.8]],
        }}
        nodeBorderRadius={3}
        linkOpacity={theme.palette.mode === "light" ? 0.5 : 1}
        linkHoverOthersOpacity={theme.palette.mode === "light" ? 0.1 : 1}
        linkContract={3}
        enableLinkGradient={true}
        labelPosition="outside"
        labelOrientation="vertical"
        labelPadding={16}
        labelTextColor={
          theme.palette.mode === "light"
            ? {
                from: "color",
                modifiers: [["darker", 10]],
              }
            : {
                from: "color",
                modifiers: [["darker", 0]],
              }
        }
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        isInteractive={isStatic ? false : true}
        legends={
          isStatic
            ? undefined
            : [
                {
                  anchor: "bottom-right",
                  direction: "column",
                  translateX: 130,
                  itemWidth: 100,
                  itemHeight: 14,
                  itemDirection: "right-to-left",
                  itemsSpacing: 2,
                  symbolSize: 14,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemTextColor: "#000",
                      },
                    },
                  ],
                },
              ]
        }
      />
    </Box>
  );
};

export default Sankey;
