import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FileWithPath } from "react-dropzone";
import LabelWithBoldedPart from "../../../MaterialUI/LabelWithBoldedPart";
import FileDropzone from "../../../SmallComponents/Dropzone/FileDropzone";
import Button from "../../../MaterialUI/Button";
import Alert from "../../../MaterialUI/Alert";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import { isTouchDevice } from "../../../../Global/Utils/commonFunctions";
import Modal from "../../../MaterialUI/Modal";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import useTheme from "@mui/material/styles/useTheme";

const IS_TOUCH_DEVICE = isTouchDevice();

interface CameraOrDeviceUploadProps {
  file: FileWithPath | null;
  setFile: React.Dispatch<React.SetStateAction<FileWithPath | null>>;
  buttonText?: string;
}

const CameraOrDeviceUpload: React.FC<CameraOrDeviceUploadProps> = ({
  file,
  setFile,
  buttonText,
}) => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string | null>(null);

  return (
    <>
      <Stack spacing={3} direction="row" alignItems="center">
        <Stack spacing={3} direction="row">
          {IS_TOUCH_DEVICE ? (
            <Button
              onClick={() => setInfoModal(true)}
              variant="outlined"
              color="primary"
              startIcon={<CameraAltIcon />}
            >
              Use Camera
            </Button>
          ) : null}

          <FileDropzone
            setFiles={(files) => setFile(files[0])}
            setErrorMessage={setFileError}
          >
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<DriveFolderUploadIcon />}
            >
              {buttonText ? buttonText : "Upload Image"}
            </Button>
          </FileDropzone>
        </Stack>

        {file?.name ? (
          <LabelWithBoldedPart text={"Uploaded file"} bolded={file.name} />
        ) : null}
        {fileError ? (
          <Alert message={fileError} showAlert={!!fileError} severity="warning" />
        ) : null}
      </Stack>

      <Modal open={infoModal} onClose={() => setInfoModal(false)} fullWidth>
        <Typography css={styles.labelBreak} variant="body1">
          Always capture images in a well-lit environment devoid of shadows. Maximize the
          zoom level and ensure all four corners of the label are visible for optimal
          results.
        </Typography>

        <Box component="div" css={styles.flexCenter}>
          <FileDropzone
            setFiles={(files) => setFile(files[0])}
            setErrorMessage={setFileError}
            useCamera
            handleOnDrop={() => setInfoModal(false)}
          >
            <Button>Confirm</Button>
          </FileDropzone>
        </Box>
      </Modal>
    </>
  );
};

export default CameraOrDeviceUpload;
