import { Stack, Typography, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

export interface ModalLayoutData {
  type: string;
  data: any;
}

interface CellActionButtonProps {
  handleOnClick: () => void;
  text: string;
  icon?: React.ReactNode;
}

const CellActionButton: React.FC<CellActionButtonProps> = ({
  handleOnClick,
  text,
  icon = <VisibilityIcon />,
}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Typography component="h4" variant="h4" fontWeight="normal" mr={1}>
        {text}
      </Typography>
      <IconButton onClick={handleOnClick}>{icon}</IconButton>
    </Stack>
  );
};

export default CellActionButton;
