import { Box, Grid } from "@mui/material";
import { ChartConfigFormValuesWithTitle } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { AreaChartConfiguration } from "./areaChartTypes";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import { useLanguageContext } from "../../../context/LanguageContext";

type KeyOfConfigValue = keyof ChartConfigFormValuesWithTitle<AreaChartConfiguration>;

interface EditAreaChartConfigProps {
  config: ChartConfigFormValuesWithTitle<AreaChartConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<AreaChartConfiguration>>
  >;
}

const EditAreaChartConfig: React.FC<EditAreaChartConfigProps> = ({
  config,
  setUpdatedConfig,
}) => {
  const { t } = useLanguageContext();
  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string | boolean) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="axisBottomLegend"
            label={t("X axis label")}
            onChange={(e) => handleUpdateFormValue("axisBottomLegend", e.target.value)}
            value={config.axisBottomLegend}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="axisTopLegend"
            label={t("Y axis label")}
            onChange={(e) => handleUpdateFormValue("axisTopLegend", e.target.value)}
            value={config.axisTopLegend}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Show grid X")}
            name="enableGridX"
            defaultChecked={config.enableGridX}
            onChange={(e) => handleUpdateFormValue("enableGridX", e.target.checked)}
            value={config.enableGridX}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Show start label")}
            name="startLabel"
            defaultChecked={config.startLabel}
            onChange={(e) => handleUpdateFormValue("startLabel", e.target.checked)}
            value={config.startLabel}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Show end label")}
            name="endLabel"
            defaultChecked={config.endLabel}
            onChange={(e) => handleUpdateFormValue("endLabel", e.target.checked)}
            value={config.endLabel}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditAreaChartConfig;
