import { getChartColors } from "../../ExcellenceWidgets/nivoTheme";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import {
  grayColor,
  filteredColor,
  selectedColor,
} from "../../../Global/Constants/styleConstants";

export const MAIN_LOCATION_OPTIONS = handleGetSelectOption([
  "Site",
  "Warehouse",
  "Room",
  "Rack",
  "Shelf",
]);

export type LocationChartNode = {
  id: string;
  dbId: string;
  size: number;
  color: string;
};

export type LocationChartLink = {
  source: string;
  target: string;
  height: number;
};

export type LocationNodeStyle = {
  size: number;
  color: string;
};

export const getLocationNodeStyles = (): { [level: number]: LocationNodeStyle } => {
  const colors = getChartColors();
  return [
    { size: 30, color: colors[0] },
    { size: 20, color: colors[4] },
    { size: 15, color: colors[3] },
    { size: 12, color: colors[2] },
    { size: 10, color: colors[1] },
  ];
};

export const getLocationNodeColor = (
  node: LocationChartNode,
  filteredNodeIds: string[],
  grayedNodes: string[],
  selectedNodeId?: string | null
) => {
  if (grayedNodes.includes(node.id)) {
    return grayColor;
  }
  if (filteredNodeIds.length === 0 && !selectedNodeId) {
    return node.color;
  }
  if (node.dbId === selectedNodeId) {
    return selectedColor;
  }
  if (filteredNodeIds.includes(node.dbId)) {
    return filteredColor;
  }
  return node.color;
};
