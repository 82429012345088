import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import {
  BasicTableColumnCell,
  BasicTableRow,
} from "../../../Components/MaterialUI/BasicTable/basicTableUtils";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useAuthedContext } from "../../../context/AuthContext";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { AppRouterProps } from "../../../Layout/layoutVariables";
import callApi from "../../../Api/callApi";
import { getQueryKanbanWorkflows, GetQueryKanbanWorkflowsSnippet } from "../api/queries";
import Button from "../../../Components/MaterialUI/Button";
import ContentBox from "../../../Components/MaterialUI/ContentBox";
import Alert from "../../../Components/MaterialUI/Alert";
import TextField from "../../../Components/MaterialUI/FormFields/TextFields";
import BasicTable from "../../../Components/MaterialUI/BasicTable/BasicTable";
import ROUTES_MAPPING from "../../../Layout/Router/routesMapping";
import Modal from "../../../Components/MaterialUI/Modal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CreateKanbanWorkflowForm from "./Components/CreateKanbanWorkflowForm";

const TABLE_COLUMNS: BasicTableColumnCell[] = [
  { id: "name", label: "Workflow Name" },
  { id: "lastUpdated", label: "Last Updated" },
  { id: "createdAt", label: "Created on" },
];

const WorkflowsPage: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [workflowRows, setWorkflowRows] = useState<BasicTableRow[]>([]);
  const [open, setOpen] = useState(false);

  const [displayedRows, setDisplayedRows] = useState<BasicTableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        setAlertMessage("Loading...");

        const res = await callApi<GetQueryKanbanWorkflowsSnippet>({
          query: getQueryKanbanWorkflows(),
          auth: { setAuthedUser },
        });

        const rows: BasicTableRow[] = res.map((item) => ({
          id: item.id,
          name: item.data.name,
          lastUpdated: item.data.updatedOn,
          createdAt: item.data.createdOn,
        }));

        setWorkflowRows(rows);
        setPageStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("useEffect err ", err);
        setPageStatus("error");
        setAlertMessage("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(<CreateNewWorkflow handleOpenModal={handleOpenModal} />);
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  useEffect(() => {
    setDisplayedRows(workflowRows);
  }, [workflowRows]);

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOnSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    if (!val) {
      setDisplayedRows(workflowRows);
    } else {
      setDisplayedRows(() =>
        workflowRows.filter((item) =>
          `${item.name}`.toLowerCase().includes(val.toLowerCase())
        )
      );
    }
    setSearchQuery(val);
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Box component="div">
      <Typography
        css={styles.sectionBreak}
        variant="h2"
        align="center"
        color="textSecondary"
      >
        Workflows
      </Typography>
      {smMediaQuery ? (
        <Button css={styles.sectionBreak} onClick={handleOpenModal}>
          Create New Workflow
        </Button>
      ) : null}

      <Alert
        css={[styles.width100, styles.widthLimit25]}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      {workflowRows.length ? (
        <ContentBox css={[styles.height100, styles.contentBreak]}>
          <TextField label="Search" value={searchQuery} onChange={handleOnSearch} />
        </ContentBox>
      ) : null}

      <BasicTable
        css={styles.sectionBreak}
        data={{ rows: displayedRows, columns: TABLE_COLUMNS }}
        defaultOrderBy="name"
        isLoading={pageStatus === "loading"}
        emptyTableMessage={
          searchQuery
            ? "No results found. Try searching for something else."
            : "You haven't created any workflows yet."
        }
        handleOnClick={{
          handleRowLink: (id) => `${ROUTES_MAPPING["Workflows-Workflow Modeler"]}/${id}`,
        }}
      />

      <Modal
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="sm"
        label="Create New Workflow"
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        <CreateKanbanWorkflowForm
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Modal>
    </Box>
  );
};

export default WorkflowsPage;

interface CreateNewWorkflowProps {
  handleOpenModal: () => void;
}

const CreateNewWorkflow: React.FC<CreateNewWorkflowProps> = ({ handleOpenModal }) => {
  return (
    <Stack alignItems="center">
      <IconButton aria-label="create new workflow" onClick={handleOpenModal}>
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        Add
      </Typography>
    </Stack>
  );
};
