import {
  PID_FLOW_NODE_TYPE,
  PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  PidSharedNodeData,
} from "../pidNodesUtils";
import { Node } from "reactflow";

export type PidWorkflowsSvgNodeType = Node & {
  data: PidWorkflowsSvgNodeData;
};
export type PidWorkflowsSvgNodeData = PidSharedNodeData & {
  pidSymbol: PidSvgSymbol | null;
  customLabel: string;
};
export const PID_WORKFLOW_SVG_NODE_CONFIG = {
  ...PID_WORKFLOW_DEFAULT_NODE_CONFIG,
  type: PID_FLOW_NODE_TYPE.SVG,
  style: {
    ...PID_WORKFLOW_DEFAULT_NODE_CONFIG.style,
    height: 150,
    zIndex: 3,
  },
};

export type PidSvgSymbol = {
  category: string;
  id: string;
  label: string;
  svg: string;
};

export type PidSvgCategorySymbols = Record<string, PidSvgSymbol[]>;
