import React, { useState, useEffect, useRef, ReactNode } from "react";
import LoadingBackdrop from "../../MaterialUI/LoadingBackdrop";

interface LazyRenderProps {
  children: ReactNode;
  placeholder?: ReactNode;
  rootMargin?: string;
  threshold?: number;
  delay?: number; // Optional delay in milliseconds before checking visibility
  backgroundColor?: string;
}

const LazyRender: React.FC<LazyRenderProps> = ({
  children,
  placeholder = (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <LoadingBackdrop loading />.
    </div>
  ),
  rootMargin = "0px",
  threshold = 0.1,
  delay = 0,
  backgroundColor,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDelayOver, setIsDelayOver] = useState(delay === 0); // Track if the delay is over
  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // If there's a delay, set a timeout before observing
    if (delay > 0) {
      const timeoutId = setTimeout(() => {
        setIsDelayOver(true);
      }, delay);

      return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount
    } else {
      setIsDelayOver(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delay]);

  useEffect(() => {
    if (!isDelayOver) return; // Do nothing if delay isn't over

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing once visible
        }
      },
      {
        root: null,
        rootMargin: rootMargin,
        threshold: threshold,
      }
    );

    if (divRef.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      observer.observe(divRef.current);
    }

    return () => {
      if (observer && divRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(divRef.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelayOver, rootMargin, threshold]);

  return (
    <div
      ref={divRef}
      style={{
        width: "100%",
        height: "100%",
        ...(backgroundColor && { backgroundColor }),
      }}
    >
      {isVisible ? children : placeholder}
    </div>
  );
};

export default LazyRender;
