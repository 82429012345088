import { useEffect, useState } from "react";
import { IconButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import ContentBox from "../../../Components/MaterialUI/ContentBox";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import Modal from "../../../Components/MaterialUI/Modal";
import EditIcon from "@mui/icons-material/Edit";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { useOutletContext } from "react-router-dom";
import { AppRouterProps } from "../../../Layout/layoutVariables";
import RuleForm from "../../../Components/PageComponents/Rules/RuleForm";
import callApi from "../../../Api/callApi";
import { getQueryRules } from "../../../Api/Rules/apiRulesGetQueries";
import { GetQueryRulesSnippet, Rule } from "../../../Api/Rules/apiRulesSnippets";
import InputIcon from "@mui/icons-material/Input";
import { useAuthedContext } from "../../../context/AuthContext";

export type ModalType = "edit" | "update" | undefined;

const Rules = () => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme) };
  const [rulesData, setRulesData] = useState<Rule[]>([]);
  const [openRuleFormModal, setOpenRuleFormModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<ModalType>();
  const [modalData, setModalData] = useState<Rule | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { smMediaQuery, setExtraTopNavMenu } = useOutletContext<AppRouterProps>();
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        const rulesData = await callApi<GetQueryRulesSnippet>({
          query: getQueryRules,
          auth: { setAuthedUser },
        });

        setRulesData(rulesData);
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (smMediaQuery) {
      setExtraTopNavMenu(null);
    } else {
      setExtraTopNavMenu(
        <CreateNewRuleMenu handleOpenModal={() => setOpenRuleFormModal(true)} />
      );
    }

    return () => {
      setExtraTopNavMenu(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smMediaQuery]);

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <ContentBox css={styles.topBottomPadding3}>
      <Typography component="h2" variant="h2">
        Active Rules
      </Typography>

      {rulesData && rulesData.length > 0 ? (
        <Stack mt={2} gap={2}>
          {rulesData?.map((rule) => (
            <Stack key={rule.id} direction="row" justifyContent="space-between">
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={2}
              >
                <Typography component="p" variant="h4" color="primary">
                  If
                </Typography>
                <Typography component="p" variant="body1">
                  {rule.parameter_name}
                </Typography>
                <Typography component="p" variant="h2" color="primary">
                  {rule.rule}
                </Typography>
                <Typography component="p" variant="body1">
                  {rule.number}
                </Typography>
                <Typography component="p" variant="h4" color="primary">
                  then
                </Typography>
                <Typography>{rule.alert_message}</Typography>
              </Stack>

              <Stack direction="row" justifyContent="center" alignItems="center">
                <Tooltip title="Edit Rule">
                  <IconButton
                    onClick={() => {
                      setModalType("edit");
                      setModalData(rule);
                      setOpenRuleFormModal(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Update Parameter">
                  <IconButton
                    onClick={() => {
                      setModalType("update");
                      setModalData(rule);
                      setOpenRuleFormModal(true);
                    }}
                  >
                    <InputIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          ))}
        </Stack>
      ) : (
        <Typography component="p" variant="body1" my={2}>
          {rulesData && rulesData.length === 0
            ? "No rules data available."
            : "Loading..."}
        </Typography>
      )}

      <Modal
        open={openRuleFormModal}
        fullWidth
        label={
          !modalType
            ? "Create Rule"
            : modalType === "edit"
            ? "Edit Rule"
            : "Update Parameter Value"
        }
        onClose={() => {
          setOpenRuleFormModal(false);
          setTimeout(() => {
            setModalType(undefined);
          }, 200);
        }}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        <RuleForm
          modalType={modalType}
          ruleId={modalType ? modalData?.id : undefined}
          connection={modalType ? modalData?.container_name : undefined}
          node={modalType ? modalData?.parameter_name : undefined}
          operator={modalType ? modalData?.rule : undefined}
          value={modalType ? modalData?.number.toString() : undefined}
          action={modalType ? modalData?.alert_message : undefined}
          setOpenRuleFormModal={setOpenRuleFormModal}
          setRulesData={setRulesData}
          handleSetUnsavedChanges={handleSetUnsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
        />
      </Modal>
    </ContentBox>
  );
};

export default Rules;

interface CreateNewRuleMenuProps {
  handleOpenModal: () => void;
}

const CreateNewRuleMenu: React.FC<CreateNewRuleMenuProps> = ({ handleOpenModal }) => {
  return (
    <Stack alignItems="center">
      <IconButton onClick={handleOpenModal}>
        <AddOutlinedIcon />
      </IconButton>
      <Typography variant="caption" align="center" color="textPrimary">
        Add
      </Typography>
    </Stack>
  );
};
