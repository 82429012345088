import { FC } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, useGLTF } from "@react-three/drei";

interface GLTFModelProps {
  modelPath: string;
}

const GLTFModel: FC<GLTFModelProps> = ({ modelPath }) => {
  const { scene } = useGLTF(modelPath);
  return <primitive object={scene} />;
};

const GLTFViewer: FC<GLTFModelProps> = ({ modelPath }) => {
  return (
    <Canvas style={{ width: "100%", height: "100%" }}>
      <ambientLight intensity={0.5} />
      <directionalLight position={[2, 2, 5]} />
      <OrbitControls />
      <GLTFModel modelPath={modelPath} />
    </Canvas>
  );
};

export default GLTFViewer;
