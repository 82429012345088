import { useState } from "react";
import { MaintenanceMachineTask } from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import {
  MaintenanceMachineTaskType,
  MaintenancePlanAddEditFormResult,
  MaintenancePlanTemplateApplyChangesOption,
  maintenanceMachineTaskTypeOptions,
  maintenancePlanTemplateApplyChangesOptions,
} from "../maintenancePlanUtils";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import Select from "../../../../MaterialUI/FormFields/Select";
import {
  Box,
  Stack,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
  IconButton,
  Grid,
} from "@mui/material";
import Button from "../../../../MaterialUI/Button";
import PersonIcon from "@mui/icons-material/Person";
import Modal from "../../../../MaterialUI/Modal";
import RadioGroup from "../../../../MaterialUI/FormFields/RadioGroup";
import Collapse from "../../../../MaterialUI/Collapse";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Alert from "../../../../MaterialUI/Alert";
import TextField from "../../../../MaterialUI/FormFields/TextFields";

type ModalType = "Remove Responsible" | "Edit Responsible" | null;
type DisplayedResp = {
  task: boolean;
  template: boolean;
};

interface MaintenancePlanResponsibleProps {
  task: MaintenanceMachineTask;
  handleChange: (value?: MaintenancePlanAddEditFormResult) => Promise<void>;
  isAddNew: boolean;
}

const MaintenancePlanResponsible: React.FC<MaintenancePlanResponsibleProps> = ({
  task,
  isAddNew,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  const [displayedResp, setDisplayedResp] = useState<DisplayedResp>({
    task: true,
    template: true,
  });
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [taskType, setTaskType] = useState<MaintenanceMachineTaskType | "">("");
  const [applyChangesTo, setApplyChangesTo] =
    useState<MaintenancePlanTemplateApplyChangesOption>("All Open Tasks");
  const [responsibleToDelete, setResponsibleToDelete] = useState<string | null>(null);

  const [addManually, setAddManually] = useState<boolean>(false);
  const [manuallyView, setManuallyView] = useState<boolean>(false);
  const [selectedPerson, _] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);

  const handleProceed = () => {
    if (!selectedPerson && !addManually) {
      setAlertMessage(
        "You either select an organisation responsible person or add one manually."
      );
      setAlertStatus("warning");
      return;
    }
    setAlertMessage(null);
    setAlertStatus(null);

    if (addManually) {
      setManuallyView(true);
    }
  };

  const handleDeleteResponsible = () => {
    // request to add new comment
  };

  if (manuallyView) {
    return (
      <Box component="div">
        <Grid css={styles.contentBreak} container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField label="Title" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Name" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Email" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Phone Number" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Department" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField label="Job Description" />
          </Grid>
        </Grid>

        <Stack
          css={[styles.width100, styles.contentBreak]}
          alignItems="center"
          justifyContent="center"
        >
          <Button css={styles.widthLimit20}>Add Responsible Person</Button>
        </Stack>

        {!!alertMessage ? (
          <Alert
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={alertStatus}
          />
        ) : null}
      </Box>
    );
  }

  if (isAddNew) {
    return (
      <Stack spacing={3}>
        <Typography variant="body1">
          If responsible person is added to the template, it will be assigned on every
          corresponding repetitive task derived from this template. You can add people
          from your organisation (employees) or add a person manually (contractors).
        </Typography>
        <Stack direction="row" spacing={3} alignItems="center">
          <Select label="Organisation Responsible" selectOptions={[]} />
          <Checkbox
            css={[styles.width100, styles.widthLimit10]}
            label="Add Manually"
            checked={addManually}
            onChange={(e) => setAddManually(e.target.checked)}
          />
        </Stack>

        <Select
          label="Responsible Type"
          value={taskType}
          onChange={(e) => setTaskType(e.target.value as MaintenanceMachineTaskType)}
          selectOptions={maintenanceMachineTaskTypeOptions}
        />

        {taskType === "repetitive" ? (
          <Collapse in={taskType === "repetitive"}>
            <RadioGroup
              label="Apply Changes to"
              value={applyChangesTo}
              onChange={(e) =>
                setApplyChangesTo(
                  e.target.value as MaintenancePlanTemplateApplyChangesOption
                )
              }
              radioOptions={maintenancePlanTemplateApplyChangesOptions}
            />
          </Collapse>
        ) : null}

        <Stack css={styles.width100} alignItems="center" justifyContent="center">
          <Button css={styles.widthLimit20} onClick={handleProceed}>
            Add Responsible Person
          </Button>
        </Stack>

        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={alertStatus}
        />
      </Stack>
    );
  }

  return (
    <Box component="div">
      {task.task_template_id ? (
        <Stack css={styles.contentBreak} spacing={3} direction="row">
          <Checkbox
            label="Task Responsible"
            checked={displayedResp.task}
            onChange={(e) =>
              setDisplayedResp((prev) => ({
                ...prev,
                task: e.target.checked,
              }))
            }
          />
          <Checkbox
            label="Template Responsible"
            checked={displayedResp.template}
            onChange={(e) =>
              setDisplayedResp((prev) => ({
                ...prev,
                template: e.target.checked,
              }))
            }
          />
        </Stack>
      ) : null}

      {task?.responsible?.length ? (
        <>
          {task.responsible.map((level) => (
            <Box component="div" key={level.title}>
              <Typography component="h3" variant="h3" fontWeight="normal">
                {level.title}
              </Typography>
              <List>
                {level.persons.map((person) => (
                  <ListItem
                    key={person.name}
                    secondaryAction={
                      <IconButton
                        onClick={() => {
                          setResponsibleToDelete(person.name);
                          setOpenModal("Remove Responsible");
                        }}
                      >
                        <DeleteOutlineOutlinedIcon color="error" />
                      </IconButton>
                    }
                  >
                    <ListItemAvatar>
                      <Avatar>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={person.name}
                      secondary={
                        <Stack
                          direction="row"
                          justifyContent="ceneter"
                          alignItems="center"
                          flexWrap="wrap"
                          gap={1}
                          mt={1}
                        >
                          <Typography variant="h5" component="h5" fontWeight="normal">
                            {person.contact_info.phone}
                          </Typography>
                          <Typography variant="h5" component="h5" fontWeight="normal">
                            {person.contact_info.email}
                          </Typography>
                          <Typography variant="h5" component="h5" fontWeight="normal">
                            {person.contact_info.address}
                          </Typography>
                          <Typography variant="h5" component="h5" fontWeight="normal">
                            {person.contact_info.department}
                          </Typography>
                          <Typography variant="h5" component="h5" fontWeight="normal">
                            {person.contact_info.job_description}
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </>
      ) : (
        <Typography
          style={{ marginBottom: theme.spacing(1.5) }}
          textAlign="center"
          variant="h4"
        >
          Task doesn't have any responsible people
        </Typography>
      )}

      <Modal
        open={openModal !== null}
        fullWidth
        label={openModal || ""}
        onClose={() => setOpenModal(null)}
      >
        {openModal === "Remove Responsible" ? (
          <Stack spacing={3}>
            <Typography variant="body1">
              Are you sure you want to remove this responsible person?
            </Typography>
            <LabelWithBoldedPart text="Person" bolded={responsibleToDelete || ""} />

            <Stack css={styles.width100} alignItems="center" justifyContent="center">
              <Button
                css={styles.widthLimit20}
                onClick={handleDeleteResponsible}
                color="error"
              >
                Remove Responsible
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default MaintenancePlanResponsible;
