import { Query } from "../callApi";
import {
  DeleteQueryOperationsForm,
  PostQueryOperationsFormInput,
  PostQueryOperationsWidgetsInput,
  PutQueryOperationsFormInput,
} from "./apiOperationsInputs";

export const postQueryOperationsForm = (input: PostQueryOperationsFormInput): Query => ({
  endpoint: "operations/form",
  method: "POST",
  variables: input,
});

export const postQueryOperationsWidgets = (
  input: PostQueryOperationsWidgetsInput
): Query => ({
  endpoint: "operations/widgets",
  method: "POST",
  variables: input,
});

export const putQueryOperationsForm = (input: PutQueryOperationsFormInput): Query => ({
  endpoint: "operations/form",
  method: "PUT",
  variables: input,
});
export const deleteQueryOperationsForm = (id: DeleteQueryOperationsForm): Query => ({
  endpoint: `operations/form/${id}`,
  method: "DELETE",
});
