import { Formik } from "formik";
import { Stack } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Button from "../../MaterialUI/Button";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../Global/Constants/yupConstants";
import Alert from "../../MaterialUI/Alert";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { SerializedStyles } from "@emotion/react";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
};

interface EditWorkflowFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  currentName: string;
  handleEdit: (updatedName: string) => void;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditWorkflowForm: React.FC<EditWorkflowFormProps> = ({
  className,
  currentName,
  handleEdit,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const initialValues: FormValues = {
    name: currentName,
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      setFormStatus("success");
      setUnsavedChanges(false);
      handleEdit(values.name);
    } catch (err) {
      console.log("EditWorkflowForm err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              className={className}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                name="name"
                label="Workflow Name"
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />

              <Button type="submit" loading={formStatus === "loading"}>
                Edit Workflow
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default EditWorkflowForm;
