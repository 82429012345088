import { Grid, Stack } from "@mui/material";
import { Formik } from "formik";
import { t } from "i18next";
import { useState } from "react";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../Global/Constants/yupConstants";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import TextField from "../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../Components/MaterialUI/Button";
import Alert from "../../../Components/MaterialUI/Alert";
import { LongPlanningMachineRow, LongPlanningProductRow } from "./longTermPlanningUtils";
import { useAuthedContext } from "../../../context/AuthContext";
import {
  postQueryNeoperlMachine,
  putQueryNeoperlMachine,
  PutQueryNeoperlMachine,
} from "./longTermEndpoints";
import callApi from "../../../Api/callApi";
import Select from "../../../Components/MaterialUI/FormFields/Select";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  machine_type: YUP_REQUIRED_STRING,
  production_plant: YUP_REQUIRED_STRING,
  maximum_speed: YUP_REQUIRED_STRING,
  average_output_production_per_hour: YUP_REQUIRED_STRING,
});

type FormValues = {
  machine_type: string;
  production_plant: number | string;
  products: string[];
  maximum_speed: number | string;
  average_output_production_per_hour: string | number;
};

const typeOptions = handleGetSelectOption(["Auto Machine", "Semi Auto"]);

interface LongTermMachineFormProps {
  machine: LongPlanningMachineRow | null;
  onSubmit: () => Promise<void>;
  allProducts: LongPlanningProductRow[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const LongTermMachineForm: React.FC<LongTermMachineFormProps> = ({
  machine,
  onSubmit,
  allProducts,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const productOptions: SelectOption[] = allProducts.map((item) => ({
    value: item.product_name,
    description: item.product_name,
  }));

  const initialValues: FormValues = {
    machine_type: machine?.machine_type || "",
    production_plant: machine?.production_plant || "",
    products: machine?.products?.length ? machine.products : [],
    maximum_speed: machine?.maximum_speed || "",
    average_output_production_per_hour: machine?.average_output_production_per_hour || "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      if (!values.products.length) {
        setFormStatus("warning");
        setAlertMessage("You must select at least one product");
        return;
      }

      setFormStatus("loading");
      setAlertMessage(null);

      if (machine?.machine_id) {
        await handleEdit(values, machine.machine_id);
      } else {
        await handleAdd(values);
      }
      await onSubmit();

      setFormStatus("success");
      setUnsavedChanges(false);
      setAlertMessage(t("Success!"));
    } catch (err) {
      console.log("CreateDynamicGridLayoutForm err ", err);
      setFormStatus("error");
      setAlertMessage(t("Something went wrong"));
    }
  };

  const handleEdit = async (values: FormValues, machineID: string) => {
    const input: PutQueryNeoperlMachine = {
      machine_type: values.machine_type,
      production_plant: `${values.production_plant}`,
      products: values.products,
      maximum_speed: +values.maximum_speed,
      average_output_production_per_hour: +values.average_output_production_per_hour,
    };
    await callApi<string>({
      query: putQueryNeoperlMachine(machineID, input),
      auth: { setAuthedUser },
    });
  };

  const handleAdd = async (values: FormValues) => {
    const input: PutQueryNeoperlMachine = {
      machine_type: values.machine_type,
      production_plant: `${values.production_plant}`,
      products: values.products,
      maximum_speed: +values.maximum_speed,
      average_output_production_per_hour: +values.average_output_production_per_hour,
    };
    await callApi<string>({
      query: postQueryNeoperlMachine(input),
      auth: { setAuthedUser },
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Select
                  selectOptions={typeOptions}
                  name="machine_type"
                  label="Type"
                  error={touched["machine_type"] && !!errors["machine_type"]}
                  helperText={touched["machine_type"] && errors["machine_type"]}
                  onChange={handleChange}
                  value={values.machine_type}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="production_plant"
                  label="Production Plant"
                  error={touched["production_plant"] && !!errors["production_plant"]}
                  helperText={touched["production_plant"] && errors["production_plant"]}
                  onChange={handleChange}
                  value={values.production_plant}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Select
                  selectOptions={productOptions}
                  name="products"
                  label="Products"
                  error={touched["products"] && !!errors["products"]}
                  helperText={touched["products"] && errors["products"]}
                  onChange={handleChange}
                  value={values.products}
                  multiple
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="maximum_speed"
                  label="Maximum Speed"
                  error={touched["maximum_speed"] && !!errors["maximum_speed"]}
                  helperText={touched["maximum_speed"] && errors["maximum_speed"]}
                  onChange={handleChange}
                  value={values.maximum_speed}
                  numberField
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  name="average_output_production_per_hour"
                  label="Average Output Production Per Hour"
                  error={
                    touched["average_output_production_per_hour"] &&
                    !!errors["average_output_production_per_hour"]
                  }
                  helperText={
                    touched["average_output_production_per_hour"] &&
                    errors["average_output_production_per_hour"]
                  }
                  onChange={handleChange}
                  value={values.average_output_production_per_hour}
                  numberField
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={3} alignItems="center" justifyContent="center">
                  <Button
                    type="submit"
                    loading={formStatus === "loading"}
                    disabled={formStatus === "success"}
                  >
                    {machine?.machine_id ? "Edit " : "Create New"} Machine
                  </Button>
                  <Alert
                    message={alertMessage || ""}
                    showAlert={!!alertMessage}
                    severity={formStatus}
                  />
                </Stack>
              </Grid>
            </Grid>
          </form>
        );
      }}
    </Formik>
  );
};

export default LongTermMachineForm;
