import { Box, Grid, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  BarChartLossesByMainAndSubCategory,
  LossesChartType,
  OEEModalTitle,
  chartTypeOptions,
  getOEEChartColors,
} from "../oEEUtils";
import { barChartLossesByType } from "./oEEChartsData";
import { ResponsiveBar } from "@nivo/bar";
import { parseSecondsToHMS } from "../../../../Global/Utils/commonFunctions";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Button from "../../../MaterialUI/Button";
import Modal from "../../../MaterialUI/Modal";
import EditOEEBarChartForm from "./EditOEEBarChartForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { PeriodModeDates } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import ChartPeriodViewer from "../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import { format, startOfMonth } from "date-fns";
import Select from "../../../MaterialUI/FormFields/Select";

interface OEEBarChartLossesByTypeProps {}

const OEEBarChartLossesByType: React.FC<OEEBarChartLossesByTypeProps> = ({}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [barChartData, setBarChartData] = useState<BarChartLossesByMainAndSubCategory[]>(
    []
  );
  const [dates, setDates] = useState<PeriodModeDates | null>({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  });
  const [modalTitle, setModalTitle] = useState<OEEModalTitle | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [isChartPeriodViewerVisible, setIsChartPeriodViewerVisible] =
    useState<boolean>(false);
  const [selectedLossesTypeChart, setSelectedLossesTypeChart] =
    useState<LossesChartType>("byType");
  const openMenu = Boolean(menuAnchor);

  useEffect(() => {
    setBarChartData(barChartLossesByType);
  }, []);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const toggleChartPeriodViewer = () => {
    setIsChartPeriodViewerVisible((prev) => !prev);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {/* <Typography variant="body2" mb={1}>
            Losses
          </Typography>
          <Typography variant="h3">By Type</Typography> */}
          <Select
            selectOptions={chartTypeOptions}
            value={selectedLossesTypeChart}
            label="Losses Type"
            onChange={(e) =>
              setSelectedLossesTypeChart(e.target.value as LossesChartType)
            }
          />
        </Grid>

        <Grid item mr={2}>
          <Grid container alignItems="center" spacing={2}>
            {isChartPeriodViewerVisible && dates ? (
              <Grid item>
                <Stack alignItems="flex-end">
                  <ChartPeriodViewer
                    dates={dates}
                    setDates={setDates}
                    isOEEComponent={true}
                  />
                </Stack>
              </Grid>
            ) : dates ? (
              <Grid item>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  {`${format(dates.startDate, "dd.MM.yyyy")} - ${format(
                    dates.endDate,
                    "dd.MM.yyyy"
                  )}`}
                </Typography>
              </Grid>
            ) : null}

            <Grid item>
              <IconButton
                aria-label="toggle period viewer"
                onClick={toggleChartPeriodViewer}
                size="small"
              >
                <SwapHorizIcon
                  css={styles.greyIcon}
                  style={{
                    color: isChartPeriodViewerVisible ? theme.palette.primary.main : "",
                  }}
                />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="widget setting menu"
                onClick={handleOpenMenu}
                size="small"
              >
                <TuneOutlinedIcon css={styles.greyIcon} fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {barChartData.length > 0 ? (
        <Box component="div" height="250px">
          <ResponsiveBar
            data={barChartData}
            keys={["workingTime"]}
            indexBy="type"
            margin={{ top: 20, right: 20, bottom: 30, left: 130 }}
            layout="horizontal"
            colors={({ data }) => getOEEChartColors(data)}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            padding={0.2}
            valueFormat={(value) => parseSecondsToHMS(value)}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value) => parseSecondsToHMS(value),
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
          />
        </Box>
      ) : (
        <Typography variant="h5" ml={1} mb={3}>
          Loading...
        </Typography>
      )}

      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
          <Stack alignItems="center">
            <IconButton
              aria-label="edit loss"
              onClick={() => setModalTitle("Edit OEE Chart")}
            >
              <EditOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Edit
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="copy widget"
              onClick={() => setModalTitle("Copy OEE Chart")}
            >
              <DifferenceOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center">
              Copy
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="delete loss"
              onClick={() => setModalTitle("Delete OEE Chart")}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Delete
            </Typography>
          </Stack>
        </Stack>
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth={modalTitle === "Delete OEE Chart" ? "sm" : "md"}
        label={modalTitle}
      >
        {modalTitle === "Edit OEE Chart" ? <EditOEEBarChartForm /> : null}
        {modalTitle === "Delete OEE Chart" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>Clicking the below button will delete this chart</Typography>
            <Button>Confirm Delete</Button>
          </Stack>
        ) : null}
      </Modal>
    </>
  );
};

export default OEEBarChartLossesByType;
