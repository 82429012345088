import { TreeMapData } from "../TreeMap/treeMapTypes";

const treeMapDefaultData: TreeMapData = {
  name: "Factory",
  color: "hsl(284, 70%, 50%)",
  children: [
    {
      name: "Production",
      color: "hsl(120, 70%, 50%)",
      children: [
        {
          name: "Machine A",
          color: "hsl(200, 70%, 50%)",
          loc: 3200,
        },
        {
          name: "Machine B",
          color: "hsl(40, 70%, 50%)",
          loc: 4800,
        },
        {
          name: "Machine C",
          color: "hsl(320, 70%, 50%)",
          loc: 7500,
        },
        {
          name: "Machine D",
          color: "hsl(80, 70%, 50%)",
          loc: 12000,
        },
      ],
    },
    {
      name: "Storage",
      color: "hsl(200, 70%, 50%)",
      children: [
        {
          name: "Machine E",
          color: "hsl(240, 70%, 50%)",
          loc: 5800,
        },
        {
          name: "Machine F",
          color: "hsl(160, 70%, 50%)",
          loc: 4200,
        },
        {
          name: "Machine G",
          color: "hsl(280, 70%, 50%)",
          loc: 3100,
        },
      ],
    },
    {
      name: "Packaging",
      color: "hsl(40, 70%, 50%)",
      children: [
        {
          name: "Machine H",
          color: "hsl(160, 70%, 50%)",
          loc: 6400,
        },
        {
          name: "Machine I",
          color: "hsl(320, 70%, 50%)",
          loc: 7800,
        },
      ],
    },
    {
      name: "Maintenance",
      color: "hsl(280, 70%, 50%)",
      children: [
        {
          name: "Machine J",
          color: "hsl(40, 70%, 50%)",
          loc: 1800,
        },
      ],
    },
  ],
};

export default treeMapDefaultData;
