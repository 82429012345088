import { Box, Divider, Grid } from "@mui/material";
import UaNodes from "./UaNodes";
import { UAConnectionTree } from "../../../../../Api/DataSources/apiDSDataTypes";
import { memo, useCallback, useEffect, useState } from "react";
import UaLastNodeValues from "./UaLastNodeValues";
import debounce from "lodash.debounce";

interface UaNodesStageProps {
  treeData: UAConnectionTree;
  selectedNodes: string[];
  serverURL: string;
  handleChange: (nodes: string[]) => void;
}

const UaNodesStage: React.FC<UaNodesStageProps> = ({
  treeData,
  selectedNodes,
  serverURL,
  handleChange,
}) => {
  const [stateSelectedNodes, setStateSelectedNodes] = useState<string[]>(selectedNodes);
  const [lastSelectedNode, setLastSelectedNode] = useState<string | null>(null);

  useEffect(() => {
    if (JSON.stringify(selectedNodes) !== JSON.stringify(stateSelectedNodes)) {
      setStateSelectedNodes(() => selectedNodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodes]);

  useEffect(() => {
    if (JSON.stringify(selectedNodes) !== JSON.stringify(stateSelectedNodes)) {
      debounceUpdateData(stateSelectedNodes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateSelectedNodes]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = useCallback(debounce(handleChange, 500), []);

  return (
    <Box component="div" p={3}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5.5}>
          <UaNodes
            nodeList={treeData}
            selectedNodes={stateSelectedNodes}
            setSelectedNodes={setStateSelectedNodes}
            setLastSelectedNode={setLastSelectedNode}
            serverURL={serverURL}
          />
        </Grid>

        <Grid item xs={12} sm={1}>
          <Divider style={{ margin: "auto", width: "1px" }} orientation="vertical" />
        </Grid>

        <Grid item xs={12} sm={5.5}>
          <UaLastNodeValues lastSelectedNode={lastSelectedNode} server={serverURL} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(UaNodesStage);
