import { SerializedStyles } from "@emotion/react";
import { Box, useTheme } from "@mui/material";
import { LineAreaHeatMapScatterPlotData } from "../../EditExcellenceChartForms/excellenceChartFormUtils";
import { ResponsiveScatterPlot } from "@nivo/scatterplot";
import { getChartColors } from "../../nivoTheme";

interface ScatterPlotProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  configuration: null;
  data: LineAreaHeatMapScatterPlotData;
  isStatic?: boolean;
}

const ScatterPlot: React.FC<ScatterPlotProps> = ({ className, data, isStatic }) => {
  const theme = useTheme();
  const chartMargin = {
    top: isStatic ? 10 : 30,
    right: isStatic ? 10 : 100,
    bottom: isStatic ? 10 : 50,
    left: isStatic ? 10 : 60,
  };

  return (
    <Box component="div" className={className}>
      <ResponsiveScatterPlot
        data={data}
        margin={chartMargin}
        xScale={{ type: "linear", min: 0, max: "auto" }}
        xFormat=">-.2f"
        yScale={{ type: "linear", min: 0, max: "auto" }}
        yFormat=">-.2f"
        isInteractive={isStatic ? false : true}
        axisTop={null}
        axisRight={null}
        colors={getChartColors()}
        theme={{
          text: {
            fill: theme.palette.common.black,
          },
          tooltip: {
            container: {
              background: theme.palette.common.white,
            },
          },
        }}
        axisBottom={
          isStatic
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "weight",
                legendPosition: "middle",
                legendOffset: 40,
              }
        }
        axisLeft={
          isStatic
            ? null
            : {
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "size",
                legendPosition: "middle",
                legendOffset: -50,
              }
        }
        legends={
          isStatic
            ? undefined
            : [
                {
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 130,
                  translateY: 0,
                  itemWidth: 100,
                  itemHeight: 12,
                  itemsSpacing: 5,
                  itemDirection: "left-to-right",
                  symbolSize: 12,
                  symbolShape: "circle",
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemOpacity: 1,
                      },
                    },
                  ],
                },
              ]
        }
      />
    </Box>
  );
};

export default ScatterPlot;
