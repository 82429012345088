import { Query } from "../callApi";

export const getQueryHelpPageContent = (page_key_id: string): Query => ({
  endpoint: `help/full/text/page/${page_key_id}`,
  method: "GET",
});

export const getQueryHelpPageKeys = (): Query => ({
  endpoint: `help/page_keys`,
  method: "GET",
});

export const getQueryHelpPageKey = (page_key_id: string): Query => ({
  endpoint: `help/page_key/${page_key_id}`,
  method: "GET",
});
