import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import FolderSharedOutlinedIcon from "@mui/icons-material/FolderSharedOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import StraightenOutlinedIcon from "@mui/icons-material/StraightenOutlined";
import ManageSearchOutlinedIcon from "@mui/icons-material/ManageSearchOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import DriveFileMoveOutlinedIcon from "@mui/icons-material/DriveFileMoveOutlined";
import RestorePageOutlinedIcon from "@mui/icons-material/RestorePageOutlined";
import SavedSearchOutlinedIcon from "@mui/icons-material/SavedSearchOutlined";
import { LeftNavList } from "../layoutVariables";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import CastConnectedIcon from "@mui/icons-material/CastConnected";
import ClassIcon from "@mui/icons-material/Class";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { getCurrentEnv } from "../../Global/Utils/commonFunctions";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import RuleIcon from "@mui/icons-material/Rule";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import FitbitIcon from "@mui/icons-material/Fitbit";
import DeveloperBoardIcon from "@mui/icons-material/DeveloperBoard";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import ResetTvIcon from "@mui/icons-material/ResetTv";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import InstallDesktopIcon from "@mui/icons-material/InstallDesktop";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import HiveIcon from "@mui/icons-material/Hive";
import PinDropIcon from "@mui/icons-material/PinDrop";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import ShowChartOutlinedIcon from "@mui/icons-material/ShowChartOutlined";
import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import ElectricBoltOutlinedIcon from "@mui/icons-material/ElectricBoltOutlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import TrendingDownOutlinedIcon from "@mui/icons-material/TrendingDownOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import ListAltIcon from "@mui/icons-material/ListAlt";
import StreamIcon from "@mui/icons-material/Stream";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import ChecklistOutlinedIcon from "@mui/icons-material/ChecklistOutlined";
import QueueOutlinedIcon from "@mui/icons-material/QueueOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";
import SchemaIcon from "@mui/icons-material/Schema";

const IS_PARTNER_ENV = getCurrentEnv() === "partner";

type LeftNavMenu = {
  title: string;
  list: LeftNavList;
};
export const NAV_GIANT_HOME: LeftNavMenu = {
  title: "GIANT Analytics",
  list: [
    {
      text: "Home",
      url: "/",
      Icon: HomeOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
  ],
};
export const NAV_GIANT_PLANNING: LeftNavMenu = {
  title: "GIANT Planning",
  list: [
    {
      text: "Projects",
      url: "/GIANT-OpEx/projects",
      Icon: BallotOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Orders",
      url: null,
      Icon: LowPriorityIcon,
      disabled: IS_PARTNER_ENV,
      nested: [
        {
          text: "Orders Information",
          url: "GIANT-OpEx/orders-information",
          Icon: ChecklistOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Orders Management",
          url: "GIANT-OpEx/orders-management",
          Icon: QueueOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Orders Details",
          url: "GIANT-OpEx/orders-details",
          Icon: DisplaySettingsOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
      ],
    },
    {
      text: "Workflows",
      url: null,
      Icon: AccountTreeOutlinedIcon,
      disabled: IS_PARTNER_ENV,
      nested: [
        {
          text: "Task Designer",
          url: "/GIANT-OpEx/workflows/task-designer",
          Icon: DesignServicesIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Task Manager",
          url: "/GIANT-OpEx/workflows/task-manager",
          Icon: ListAltIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Kanban Board",
          url: "/GIANT-OpEx/board",
          Icon: ViewKanbanIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Workflow Modeler",
          url: "/GIANT-OpEx/workflows",
          Icon: StreamIcon,
          disabled: IS_PARTNER_ENV,
        },
      ],
    },
    {
      text: "Shift Planning",
      url: "/GIANT-OpEx/shift-planning",
      Icon: GroupAddOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Capacities",
      url: null,
      Icon: EqualizerIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Capabilities",
      url: null,
      Icon: FitbitIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Long-term Planning",
      url: "/GIANT-OpEx/long-term-planning",
      Icon: EventAvailableIcon,
      disabled: IS_PARTNER_ENV,
    },
  ],
};
export const NAV_GIANT_ANALYTICS: LeftNavMenu = {
  title: "GIANT Analytics",
  list: [
    {
      text: "Standard KPIs",
      url: null,
      Icon: BallotOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Financial KPIs",
          url: null,
          Icon: LocalAtmOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Maintenance KPIs",
          url: "/GIANT-OpEx/Maintenance-KPIs",
          Icon: AssessmentOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "OpEx KPIs",
          url: "/GIANT-OpEx/OpEx-KPIs",
          Icon: ShowChartOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Energy KPIs",
          url: null,
          Icon: ElectricBoltOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
      ],
    },
    {
      text: "Dashboards",
      url: "/GIANT-OpEx/dashboards/",
      Icon: DashboardOutlinedIcon,
      disabled: false,
    },
    {
      text: "DoE",
      url: null,
      Icon: SyncOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Anomaly Detection",
      url: "/GIANT-OpEx/Anomaly-Detection",
      Icon: InfoOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "P&ID Charts",
      url: "/GIANT-OpEx/pid-charts",
      Icon: SchemaIcon,
      disabled: IS_PARTNER_ENV,
    },
  ],
};
export const NAV_GIANT_OPEX: LeftNavMenu = {
  title: "GIANT OpEx",
  list: [
    {
      text: "Forms",
      url: "/GIANT-OpEx/forms",
      Icon: FeedOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Processes",
      url: "/GIANT-OpEx/processes",
      Icon: TaskOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Process Control",
      url: null,
      Icon: DeveloperBoardIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "OEE",
      url: null,
      Icon: RunningWithErrorsIcon,
      disabled: IS_PARTNER_ENV,
      nested: [
        {
          text: "Dashboard",
          url: "/GIANT-OpEx/Dashboard",
          Icon: DashboardCustomizeOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Losses",
          url: "/GIANT-OpEx/Losses",
          Icon: TrendingDownOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Assets",
          url: "/GIANT-OpEx/Assets",
          Icon: PieChartOutlineOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Categories",
          url: "/GIANT-OpEx/Categories",
          Icon: CategoryOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
      ],
    },
    {
      text: "Maintenance",
      url: null,
      Icon: BuildOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Machine Park",
          url: "/GIANT-Supply-Chain/Maintenance",
          Icon: AssignmentOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Maintenance Plan",
          url: "/GIANT-Supply-Chain/Maintenance-Tasks",
          Icon: QueuePlayNextIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Condition Monitoring",
          url: "/GIANT-Supply-Chain/Condition-Monitoring",
          Icon: ResetTvIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Predictive Maintenance",
          url: "/GIANT-Supply-Chain/Predictive-Maintenance",
          Icon: InstallDesktopIcon,
          disabled: IS_PARTNER_ENV,
        },
      ],
    },
  ],
};

export const NAV_GIANT_QUALITY: LeftNavMenu = {
  title: "GIANT Quality",
  list: [
    {
      text: "Quality Data",
      url: "/GIANT-Quality/Quality-Data",
      Icon: VerifiedOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Quality Parameters",
      url: "/GIANT-Quality/Quality-Parameters",
      Icon: VerticalSplitIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Test Plans",
      url: null,
      Icon: SavedSearchOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Reserve Samples",
      url: null,
      Icon: LibraryBooksOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Vision Control",
      url: null,
      Icon: CastConnectedIcon,
      disabled: false,
      nested: [
        {
          text: "Label Recognition",
          url: "/GIANT-Quality/vision-control/label-recognition",
          Icon: ClassIcon,
          disabled: IS_PARTNER_ENV,
        },
      ],
    },
  ],
};
export const NAV_GIANT_SUPPLY_CHAIN: LeftNavMenu = {
  title: "GIANT Supply Chain",
  list: [
    {
      text: "Stock",
      url: "/GIANT-Supply-Chain/My-Warehouses/Inventory",
      Icon: WarehouseOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Object Tracking",
      url: "/GIANT-Supply-Chain/Object-Tracking",
      Icon: DocumentScannerIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Shipments",
      url: null,
      Icon: LocalShippingIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Materials",
      url: "/GIANT-Supply-Chain/Materials",
      Icon: HiveIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Products",
      url: "/GIANT-Supply-Chain/Products",
      Icon: ProductionQuantityLimitsIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "Locations",
      url: "/GIANT-Supply-Chain/My-Warehouses/Locations",
      Icon: PinDropIcon,
      disabled: IS_PARTNER_ENV,
    },
  ],
};

export const NAV_GIANT_TOOLBOX: LeftNavMenu = {
  title: "GIANT Toolbox Manager",
  list: [
    {
      text: "GIANT User Manager",
      url: null,
      Icon: ManageAccountsOutlinedIcon,
      disabled: IS_PARTNER_ENV,
    },
    {
      text: "GIANT Integrator",
      url: null,
      Icon: IntegrationInstructionsOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Data Sources",
          url: "/GIANT-Toolbox-Manager/GIANT-Integrator/Data-Sources",
          Icon: FolderCopyOutlinedIcon,
          disabled: false,
        },
        {
          text: "Connections",
          url: "/GIANT-Toolbox-Manager/GIANT-Integrator/Connections",
          Icon: FolderSharedOutlinedIcon,
          disabled: false,
        },
      ],
    },
    {
      text: "GIANT Data Manager",
      url: null,
      Icon: DescriptionOutlinedIcon,
      disabled: false,
      nested: [
        {
          text: "Rules",
          url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Rules",
          Icon: RuleIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Manual Entries",
          url: null,
          Icon: EditCalendarOutlinedIcon,
          disabled: false,
          nested: [
            {
              text: "Manual Parameters",
              url: null,
              Icon: DriveFileRenameOutlineOutlinedIcon,
              disabled: false,
              nested: [
                {
                  text: "New",
                  url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Manual-Paramenters/new",
                  Icon: CreateNewFolderOutlinedIcon,
                  disabled: IS_PARTNER_ENV,
                },
                {
                  text: "Edit",
                  url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Manual-Paramenters/edit",
                  Icon: ModeEditOutlineOutlinedIcon,
                  disabled: IS_PARTNER_ENV,
                },
              ],
            },
            {
              text: "Setpoint Management",
              url: null,
              Icon: ListOutlinedIcon,
              disabled: false,
              nested: [
                {
                  text: "Edit",
                  url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Setpoint-Management/Edit",
                  Icon: ModeEditOutlineOutlinedIcon,
                  disabled: IS_PARTNER_ENV,
                },
                {
                  text: "Approve",
                  url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Setpoint-Management/Approve",
                  Icon: AssignmentTurnedInOutlinedIcon,
                  disabled: IS_PARTNER_ENV,
                },
                {
                  text: "History",
                  url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Manual-Entries/Setpoint-Management/History",
                  Icon: HistoryOutlinedIcon,
                  disabled: IS_PARTNER_ENV,
                },
              ],
            },
          ],
        },
        {
          text: "Measurement Configuration",
          url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Measurement-Configuration",
          Icon: StraightenOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "View Logs",
          url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/View-Logs",
          Icon: ManageSearchOutlinedIcon,
          disabled: IS_PARTNER_ENV,
        },
        {
          text: "Watchdogs",
          url: null,
          Icon: VisibilityOutlinedIcon,
          disabled: false,
          nested: [
            {
              text: "New",
              url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Watchdogs/new",
              Icon: CreateNewFolderOutlinedIcon,
              disabled: IS_PARTNER_ENV,
            },
            {
              text: "Edit",
              url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Watchdogs/edit",
              Icon: ModeEditOutlineOutlinedIcon,
              disabled: IS_PARTNER_ENV,
            },
          ],
        },
        {
          text: "Archiving",
          url: null,
          Icon: Inventory2OutlinedIcon,
          disabled: false,
          nested: [
            {
              text: "Move to Archive",
              url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Archiving/Move-to-Archive",
              Icon: DriveFileMoveOutlinedIcon,
              disabled: IS_PARTNER_ENV,
            },
            {
              text: "Restore Archive",
              url: "/GIANT-Toolbox-Manager/GIANT-Data-Manager/Archiving/Restore-Archive",
              Icon: RestorePageOutlinedIcon,
              disabled: IS_PARTNER_ENV,
            },
          ],
        },
      ],
    },
  ],
};
