import { Box, Grid, Stack, useTheme } from "@mui/material";
import Button from "../../../../Components/MaterialUI/Button";
import Alert from "../../../../Components/MaterialUI/Alert";
import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../../../Global/Types/commonTypes";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { KanbanTaskTemplate, KanbanTemplateFile } from "../taskManagerUtils";
import TextField from "../../../../Components/MaterialUI/FormFields/TextFields";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import {
  PostQueryKanbanTaskTemplateInput,
  postQueryKanbanTaskTemplateUploadFile,
} from "../../../KanbanWorkflows/api/mutations";
import {
  getQueryKanbanTemplateFields,
  GetQueryKanbanTemplateFieldsSnippet,
} from "../../../KanbanWorkflows/api/queries";
import Autocomplete from "../../../../Components/MaterialUI/FormFields/Autocomplete";
import QuillEditor from "../../../../Components/SmallComponents/QuillEditor/QuillEditor";

type FieldType = "name" | "steps" | "description";

interface EditKanTaskTemplateProps {
  template: KanbanTaskTemplate;
  onSave: (
    data: PostQueryKanbanTaskTemplateInput,
    templateID: string
  ) => Promise<"success" | "error">;
  isCreateNew?: boolean;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
}

const EditKanTaskTemplate: React.FC<EditKanTaskTemplateProps> = ({
  template,
  onSave,
  isCreateNew,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [formAlert, setFormAlert] = useState<string | null>(null);
  const [stateTemplate, setStateTemplate] = useState<KanbanTaskTemplate>(template);
  const [fieldsOptions, setFieldsOptions] = useState<SelectOption[]>([]);
  const [selectedFields, setSelectedFields] = useState<SelectOption[]>([]);
  const initialStateTemplate = template;

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    const hasUnsavedChanges =
      stateTemplate.name !== initialStateTemplate.name ||
      stateTemplate.description !== initialStateTemplate.description ||
      stateTemplate.steps !== initialStateTemplate.steps ||
      selectedFields.some(
        (field, index) =>
          field.value !== initialStateTemplate.data_fields[index]?.data_fields.id
      );
    handleSetUnsavedChanges(hasUnsavedChanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTemplate, selectedFields]);

  useEffect(() => {
    (async () => {
      try {
        setFormStatus("loading");
        const templateFields = await callApi<GetQueryKanbanTemplateFieldsSnippet>({
          query: getQueryKanbanTemplateFields,
          auth: { setAuthedUser },
        });
        const options: SelectOption[] = templateFields.map((item) => {
          return {
            description: item.name,
            value: item.id,
          };
        });

        setFieldsOptions(options);
        setSelectedFields(getSelectedFields(template));
        setFormStatus(null);
      } catch (err) {
        setFormStatus("error");
        setFormAlert("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    const checks = handleChecks(stateTemplate);
    if (checks) {
      setFormStatus("warning");
      setFormAlert(`${checks} is required`);
      return;
    }
    setFormStatus("loading");
    setFormAlert(isCreateNew ? "Creating..." : "Updating...");

    const templateInput: PostQueryKanbanTaskTemplateInput = {
      description: stateTemplate.description,
      steps: stateTemplate.steps,
      name: stateTemplate.name,
      data_fields: selectedFields.map((item) => item.value),
    };
    const result = await onSave(templateInput, stateTemplate.template_id);
    setFormStatus("success");
    setFormAlert(isCreateNew ? "Successfully created!" : "Successfully updated!");

    if (result === "error") {
      setFormStatus("error");
      setFormAlert("Something went wrong");
    } else {
      setFormStatus(null);
      setFormAlert(null);
    }
  };

  const onChangeSimpleField = (type: FieldType, val: string) => {
    setStateTemplate((prev) => {
      return {
        ...prev,
        [type]: val,
      };
    });
  };

  const handleUploadImage = async (file: File): Promise<KanbanTemplateFile> => {
    try {
      const res = await callApi<KanbanTemplateFile>({
        query: postQueryKanbanTaskTemplateUploadFile(
          template.template_id,
          template.id,
          file
        ),
        auth: { setAuthedUser },
      });

      return res;
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  };

  return (
    <Box component="div">
      <Grid css={styles.sectionBreak} container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Name"
            value={stateTemplate.name}
            onChange={(e) => onChangeSimpleField("name", e.target.value)}
            disabled={formStatus === "loading" || formStatus === "success"}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Description"
            value={stateTemplate.description}
            onChange={(e) => onChangeSimpleField("description", e.target.value)}
            multiline
            disabled={formStatus === "loading" || formStatus === "success"}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            label="Select Attributes for a Task"
            options={fieldsOptions}
            value={selectedFields}
            handleOnChange={(val) => setSelectedFields(val)}
            disabled={formStatus === "loading" || formStatus === "success"}
            multiple
          />
        </Grid>
        <Grid item xs={12}>
          <QuillEditor
            label="Steps"
            css={[styles.width100]}
            editorState={stateTemplate.steps}
            onEditorStateChange={(state: string) => {
              onChangeSimpleField("steps", state);
            }}
            disabled={formStatus === "loading" || formStatus === "success"}
            handleUploadImage={isCreateNew ? undefined : handleUploadImage}
          />
        </Grid>
      </Grid>

      <Stack spacing={3} alignItems="center" justifyContent="center">
        <Button
          css={[styles.width100, styles.widthLimit20]}
          onClick={handleSave}
          disabled={formStatus === "loading" || formStatus === "success"}
        >
          {isCreateNew ? "Create New Template" : "Save Changes"}
        </Button>
        <Alert
          css={[styles.width100, styles.widthLimit30]}
          message={formAlert || ""}
          showAlert={!!formAlert}
          severity={formStatus}
        />
      </Stack>
    </Box>
  );
};

export default EditKanTaskTemplate;

const handleChecks = (stateTemplate: KanbanTaskTemplate): string | null => {
  if (!stateTemplate.name) {
    return "Name";
  }
  return null;
};

const getSelectedFields = (stateTemplate: KanbanTaskTemplate): SelectOption[] => {
  return stateTemplate.data_fields.map((item) => ({
    value: item.data_fields.id,
    description: item.data_fields.name,
  }));
};
