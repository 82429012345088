import { SerializedStyles } from "@emotion/react";
import { Box } from "@mui/material";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import { ShiftPlanPerson } from "./shiftPlanningUtils";

const colSchema: TableGridColumnSchema[] = [
  { id: "name", label: "Name", type: "string" },
  { id: "start_time", label: "Start Time", type: "time" },
  { id: "end_time", label: "End Time", type: "time" },
  { id: "working_days", label: "Working Days", type: "string" },
  { id: "role", label: "Role", type: "string" },
  { id: "qualifications", label: "Qualifications", type: "string" },
];

type ColumnsToHide = keyof ShiftPlanPerson | "actions";

const daysMapping = {
  0: "Monday",
  1: "Tuesday",
  2: "Wednesday",
  3: "Thursday",
  4: "Friday",
  5: "Saturday",
  6: "Sunday",
};

interface ShiftPlanningTableProps {
  css?: (SerializedStyles | false)[] | SerializedStyles;
  className?: string;
  shiftPlanPeople: ShiftPlanPerson[];
  hideColumns: ColumnsToHide[];
  loading?: boolean;
}

const ShiftPlanningTable: React.FC<ShiftPlanningTableProps> = ({
  className,
  shiftPlanPeople,
  hideColumns,
  loading,
}) => {
  const rows = shiftPlanPeople.map((item) => {
    // @ts-ignore
    const days = item.working_days.map((ele) => daysMapping[ele]);

    return { ...item, working_days: days.join(", ") };
  });

  return (
    <Box component="div" className={className}>
      <ResponsiveTableGrid
        rows={rows}
        colSchema={colSchema.filter(
          (col) => !hideColumns.includes(col.id as ColumnsToHide)
        )}
        responsive={"responsive"}
        configuration={{
          hideAddButton: true,
          density: "compact",
        }}
        loading={loading}
      />
    </Box>
  );
};

export default ShiftPlanningTable;
