import { Grid, InputAdornment } from "@mui/material";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useCallback, useEffect, useState } from "react";
import debounce from "lodash.debounce";
import { DaConnectionParametersData } from "./daConnectionTypes";
import { handleGetSelectOption } from "../../../../../Global/Utils/commonFunctions";
import RadioGroup from "../../../../MaterialUI/FormFields/RadioGroup";

interface DaConnectionParametersProps {
  data: DaConnectionParametersData;
  handleUpdateData: (data: DaConnectionParametersData) => void;
  isLoading: boolean;
  serversList: string[];
  isEdit: boolean;
}

const DaConnectionParameters: React.FC<DaConnectionParametersProps> = ({
  data,
  handleUpdateData,
  isLoading,
  serversList,
  isEdit,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [stateData, setStateData] = useState<DaConnectionParametersData>(data);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      setStateData(() => data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(stateData)) {
      debounceUpdateData(stateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdateData = useCallback(debounce(handleUpdateData, 500), []);

  const handleChangeStateData = (key: string, value: string) => {
    setStateData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Grid container spacing={4} css={styles.flexCenter}>
      <Grid item xs={8} md={4}>
        <TextField
          label="Connection Name"
          value={stateData.name}
          onChange={(e) => handleChangeStateData("name", e.target.value)}
          fullWidth
          disabled={isLoading}
        />
      </Grid>
      <Grid item xs={8} md={4}>
        <TextField
          label="Update Rate"
          value={stateData.updateRate}
          onChange={(e) => handleChangeStateData("updateRate", e.target.value)}
          fullWidth
          disabled={isLoading}
          InputProps={{
            endAdornment: <InputAdornment position="start">ms</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <RadioGroup
          radioOptions={handleGetSelectOption(serversList)}
          label="Server"
          value={stateData.server}
          onChange={(e) => handleChangeStateData("server", e.target.value)}
          disabled={isEdit || isLoading}
          row
        />
      </Grid>
    </Grid>
  );
};

export default DaConnectionParameters;
