import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useLocation, useNavigate } from "react-router-dom";
import ContentBox from "../Components/MaterialUI/ContentBox";
import cssLayoutStyles from "../Global/Styles/layout";
import Button from "../Components/MaterialUI/Button";
import useTheme from "@mui/material/styles/useTheme";
import cssSpacingStyles from "../Global/Styles/spacing";

const RouteErrorBoundary: React.FC = () => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const location = useLocation();
  const navigate = useNavigate();

  const { resetBoundary } = useErrorBoundary();
  const [allowReset, setAllowReset] = useState<boolean>(false);

  useEffect(() => {
    if (allowReset) {
      resetBoundary();
    }
    setAllowReset(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box component="div" css={[styles.reverseSectionBreak, styles.flexCenter]}>
      <ContentBox css={styles.widthLimit30} label="Something went wrong">
        <Stack spacing={2}>
          <Typography variant="body1">
            We're sorry, but it seems there's a problem with the data for this page.
            Please try again later or contact our support team for assistance.
          </Typography>
          <Box component="div" css={styles.flexCenter}>
            <Button onClick={() => navigate(0)}>Refresh Page</Button>
          </Box>
        </Stack>
      </ContentBox>
    </Box>
  );
};

export default RouteErrorBoundary;
