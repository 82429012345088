import {
  NAV_GIANT_ANALYTICS,
  NAV_GIANT_HOME,
  NAV_GIANT_OPEX,
  NAV_GIANT_PLANNING,
  NAV_GIANT_QUALITY,
  NAV_GIANT_SUPPLY_CHAIN,
  NAV_GIANT_TOOLBOX,
} from "../../Layout/AppNavigation/leftNavData";
import { LeftNavSingleItem } from "../../Layout/layoutVariables";
import { useLanguageContext } from "../../context/LanguageContext";

const translateNavItem = (
  item: LeftNavSingleItem,
  t: (key: string) => string
): LeftNavSingleItem => {
  const translatedItem = {
    ...item,
    text: t(item.text),
  };

  if (item.nested) {
    translatedItem.nested = item.nested.map((nestedItem) =>
      translateNavItem(nestedItem, t)
    );
  }

  return translatedItem;
};

export const useTranslatedNav = () => {
  const { t } = useLanguageContext();

  return {
    NAV_GIANT_HOME: {
      ...NAV_GIANT_HOME,
      list: NAV_GIANT_HOME.list.map((item) => translateNavItem(item, t)),
    },
    NAV_GIANT_PLANNING: {
      ...NAV_GIANT_PLANNING,
      list: NAV_GIANT_PLANNING.list.map((item) => translateNavItem(item, t)),
    },
    NAV_GIANT_ANALYTICS: {
      ...NAV_GIANT_ANALYTICS,
      list: NAV_GIANT_ANALYTICS.list.map((item) => translateNavItem(item, t)),
    },
    NAV_GIANT_OPEX: {
      ...NAV_GIANT_OPEX,
      list: NAV_GIANT_OPEX.list.map((item) => translateNavItem(item, t)),
    },
    NAV_GIANT_QUALITY: {
      ...NAV_GIANT_QUALITY,
      list: NAV_GIANT_QUALITY.list.map((item) => translateNavItem(item, t)),
    },
    NAV_GIANT_SUPPLY_CHAIN: {
      ...NAV_GIANT_SUPPLY_CHAIN,
      list: NAV_GIANT_SUPPLY_CHAIN.list.map((item) => translateNavItem(item, t)),
    },
    NAV_GIANT_TOOLBOX: {
      ...NAV_GIANT_TOOLBOX,
      list: NAV_GIANT_TOOLBOX.list.map((item) => translateNavItem(item, t)),
    },
  };
};
