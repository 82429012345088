import { GetUnitsOfMeasureSnippet } from "../../../Api/Excellence/apiExcellenceSnippets";

export type UnitOfMeasureMapValue = Record<
  string,
  {
    unitID: string;
    unitName: string;
    unitSymbol: string;
  }
>;

export const getUnitsOfMeasureMapping = (
  units: GetUnitsOfMeasureSnippet
): UnitOfMeasureMapValue => {
  const result: UnitOfMeasureMapValue = {};

  units.forEach((item) => {
    if (item.id) {
      result[item.id] = {
        unitID: item.id,
        unitName: item.name,
        unitSymbol: item.symbol,
      };
    }
  });

  return result;
};

export const extractUnitSymbol = (unitString: string, showDataInPercentage: boolean) => {
  if (showDataInPercentage) {
    return "%";
  }
  if (unitString) {
    if (!unitString.includes("(") || !unitString.includes(")")) {
      return unitString;
    }
    const parts = unitString.split("(");
    if (parts[1]) {
      return parts[1].split(")")[0];
    }
    return unitString;
  } else {
    return "";
  }
};
