import ContentBox from "../../MaterialUI/ContentBox";
import { SerializedStyles, css } from "@emotion/react";
import StaticExcellenceWidget from "../../ExcellenceWidgets/StaticExcellenceWidget";
import { DynamicGridItemData } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { ErrorBoundary } from "react-error-boundary";
import Alert from "../../MaterialUI/Alert";
import { Box } from "@mui/material";

const cssStyles = {
  staticWidget: css({
    height: "300px",
    width: "100%",
    overflow: "hidden",
  }),
};

interface ExcellenceDashboardCardProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  label: string;
  id: string;
  firstItem: DynamicGridItemData | null;
}

const ExcellenceDashboardCard: React.FC<ExcellenceDashboardCardProps> = ({
  className,
  label,
  id,
  firstItem,
}) => {
  const styles = { ...cssStyles };

  return (
    <ContentBox
      className={className}
      label={label}
      clickAction={{ to: `/GIANT-OpEx/dashboards/${id}` }}
    >
      <ErrorBoundary
        FallbackComponent={() => (
          <Box component="div" css={styles.staticWidget}>
            <Alert severity="error" message="Something went wrong" showAlert />
          </Box>
        )}
      >
        {!firstItem?.widget?.chart ? null : (
          <StaticExcellenceWidget
            css={styles.staticWidget}
            chart={firstItem.widget.chart}
            isStatic
          />
        )}
      </ErrorBoundary>
    </ContentBox>
  );
};

export default ExcellenceDashboardCard;
