import { useCallback, useMemo, useState } from "react";
import { ResponsiveNetwork } from "@nivo/network";
import { Box, Typography, useTheme, Theme, Grid } from "@mui/material";
import ContentBox from "../../MaterialUI/ContentBox";
import {
  LocationChartLink,
  LocationChartNode,
  getLocationNodeStyles,
  getLocationNodeColor,
  MAIN_LOCATION_OPTIONS,
} from "./locationNetworkChartUtils";
import cssComponentsStyles from "../../../Global/Styles/components";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { css } from "@emotion/react";
import LocationChartFilters from "./LocationChartFilters";
import NetworkChartLegend from "./NetworkChartLegend";
import NodeLabelsLayer from "./NodeLabelsLayer";
import { GetQueryLocationsSnippet } from "../../../Api/Locations/apiLocationSnippets";

const cssStyles = (theme: Theme) => ({
  tooltipStyle: css({
    borderRadius: theme.shape.borderRadius,
  }),
  networkContainerStyle: css({
    position: "relative",
    height: "650px",
    width: "100%",
  }),
  cardStyle: css({
    borderRadius: theme.shape.borderRadius,
  }),
});

interface LocationNetworkChartProps {
  data: GetQueryLocationsSnippet;
  selectedNodeId?: string | null;
}

const LocationNetworkChart: React.FC<LocationNetworkChartProps> = ({
  data,
  selectedNodeId,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
    ...cssStyles(theme),
  };
  const [filteredNodeIds, setFilteredNodeIds] = useState<string[]>([]);
  const [grayedNodes, setGrayedNodes] = useState<string[]>([]);
  const nodeStyles = getLocationNodeStyles();
  const backgroundStyle = theme.palette.mode === "light" ? {} : styles.card;
  const [visibleNodesLabels, setVisibleNodesLabels] = useState<string[]>([]);

  const getNodesAndLinks = (
    data: GetQueryLocationsSnippet
  ): { nodes: LocationChartNode[]; links: LocationChartLink[] } => {
    const nodesArray: LocationChartNode[] = [];
    const linksArray: LocationChartLink[] = [];

    data.forEach((item) => {
      const parts = item.location.split("/");
      let lastPath: string | null = null;

      parts.forEach((_, index) => {
        const path = parts.slice(0, index + 1).join("/");
        const style = nodeStyles[index];

        if (!nodesArray.some((n) => n.id === path)) {
          nodesArray.push({
            id: path,
            dbId: item.id,
            size: style.size,
            color: style.color,
          });
        }

        if (
          lastPath &&
          !linksArray.some((l) => l.source === lastPath && l.target === path)
        ) {
          linksArray.push({
            source: lastPath,
            target: path,
            height: Math.max(1, 6 - index),
          });
        }

        lastPath = path;
      });
    });

    return { nodes: nodesArray, links: linksArray };
  };

  const { nodes, links } = useMemo(
    () => getNodesAndLinks(data),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const toggleNodeLabelVisibility = (nodeIdSegment: string) => {
    setVisibleNodesLabels((prev) => {
      if (nodeIdSegment === "All") {
        return prev.includes("All")
          ? []
          : [...MAIN_LOCATION_OPTIONS.map((option) => option.value), "All"];
      }

      const updatedList = prev.includes(nodeIdSegment)
        ? prev.filter((id) => id !== nodeIdSegment)
        : [...prev, nodeIdSegment];

      const allChecked = MAIN_LOCATION_OPTIONS.every((option) =>
        updatedList.includes(option.value)
      );

      return allChecked
        ? [...updatedList, "All"]
        : updatedList.filter((id) => id !== "All");
    });
  };

  const toggleGray = useCallback(
    (nodeId: string) => {
      setGrayedNodes((prev) => {
        const directChildren = nodes.filter(
          (node) => node.id.startsWith(nodeId + "/") && node.id !== nodeId
        );
        const areAllChildrenGrayed = directChildren.every((child) =>
          prev.includes(child.id)
        );

        if (areAllChildrenGrayed) {
          return prev.filter(
            (id) => !directChildren.map((child) => child.id).includes(id)
          );
        } else {
          const childrenIdsToAdd = directChildren.map((child) => child.id);
          return [...prev, ...childrenIdsToAdd];
        }
      });
    },
    [nodes]
  );

  return (
    <>
      <ContentBox>
        <LocationChartFilters data={data} setFilteredNodeIds={setFilteredNodeIds} />
        <Grid
          container
          css={[styles.reverseLabelBreak, styles.cardStyle, backgroundStyle]}
        >
          <Grid item xs={9.5} css={styles.networkContainerStyle}>
            <ResponsiveNetwork
              data={{ nodes, links }}
              layers={[
                "links",
                "nodes",
                (props) => (
                  <NodeLabelsLayer {...props} visibleNodesLabels={visibleNodesLabels} />
                ),
              ]}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              linkDistance={65}
              centeringStrength={0.4}
              repulsivity={10}
              nodeSize={(n) => n.size}
              activeNodeSize={(n) => 1.5 * n.size}
              nodeColor={(node) =>
                getLocationNodeColor(node, filteredNodeIds, grayedNodes, selectedNodeId)
              }
              linkColor={(link) =>
                theme.palette.mode === "light" ? link.source.color : "black"
              }
              nodeBorderWidth={1.5}
              nodeBorderColor={{ from: "color", modifiers: [["darker", 0.8]] }}
              linkThickness={(n) => n.data.height}
              linkBlendMode="multiply"
              motionConfig="wobbly"
              onClick={(node) => toggleGray(node.id)}
              nodeTooltip={({ node }) => (
                <NodeTooltip nodeId={node.id} nodeColor={node.color} />
              )}
            />
          </Grid>

          <Grid
            item
            xs={2.5}
            container
            direction="column"
            justifyContent="flex-end"
            pb={2}
          >
            <NetworkChartLegend
              nodeStyles={nodeStyles}
              filteredNodeIds={filteredNodeIds}
              selectedNodeId={selectedNodeId}
              visibleNodesLabels={visibleNodesLabels}
              toggleNodeLabelVisibility={toggleNodeLabelVisibility}
            />
          </Grid>
        </Grid>
      </ContentBox>
    </>
  );
};

export default LocationNetworkChart;

interface NodeTooltipProps {
  nodeId: string;
  nodeColor: string;
}

const NodeTooltip: React.FC<NodeTooltipProps> = ({ nodeId, nodeColor }) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };

  return (
    <Box
      component="div"
      css={[
        styles.card,
        styles.flexCenter,
        styles.leftRightPadding2,
        styles.tooltipStyle,
      ]}
      gap={1}
    >
      <Box
        component="div"
        sx={{
          width: 14,
          height: 14,
          backgroundColor: nodeColor,
          borderRadius: "50%",
        }}
      />
      <Typography variant="caption">{nodeId}</Typography>
    </Box>
  );
};
