import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import {
  GaugeWidgetConfiguration,
  KeyOfConfigValue,
  SectionRange,
} from "./gaugeWidgetUtils";
import { css } from "@emotion/react";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { ChartConfigFormValuesWithTitle } from "../EditExcellenceChartForms/excellenceChartFormUtils";

const cssStyles = () => ({
  sectionsRangeBox: css({
    display: "flex",
  }),
  sectionsRangeTextField: css({
    flex: 1,
  }),
});

interface GaugeWidgetSectionsRangeConfigProps {
  config: ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<GaugeWidgetConfiguration>>
  >;
  handleUpdateFormValue: (
    key: KeyOfConfigValue,
    value: string | number | boolean | SectionRange[]
  ) => void;
  gaugeDataMaxValue: number;
  gaugeDataMinValue: number;
  valueErrors: { [key: string]: boolean };
  setValueErrors: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
  setAlertMessages: React.Dispatch<React.SetStateAction<string[]>>;
}

const GaugeWidgetSectionsRangeConfig: React.FC<GaugeWidgetSectionsRangeConfigProps> = ({
  config,
  setUpdatedConfig,
  handleUpdateFormValue,
  gaugeDataMaxValue,
  gaugeDataMinValue,
  valueErrors,
  setValueErrors,
  setAlertMessages,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(),
    ...cssSpacingStyles(theme),
  };

  const [sectionRanges, setSectionRanges] = useState<SectionRange[]>(
    config.sectionRanges || []
  );
  const [sectionTitles, setSectionTitles] = useState<string[]>(
    config.sectionTitles || []
  );
  const [currentSectionIndex, setCurrentSectionIndex] = useState<number>(0);

  useEffect(() => {
    const newSectionsCount = Number(config.sections) || 0;

    const newSectionRanges: SectionRange[] = Array.from(
      { length: newSectionsCount },
      (_, index) => ({
        min: index === 0 ? gaugeDataMinValue : null,
        max: null,
      })
    );

    newSectionRanges.forEach((range, index, arr) => {
      if (index > 0) {
        range.min = arr[index - 1].max;
      }
      range.max = index === arr.length - 1 ? config.gaugeMaxValue : null;
    });

    const newSectionTitles = Array(newSectionsCount).fill("");

    setSectionRanges(newSectionRanges);
    setSectionTitles(newSectionTitles);

    setUpdatedConfig((prev) => ({
      ...prev,
      sectionRanges: newSectionRanges as SectionRange[],
      sectionTitles: newSectionTitles,
    }));

    setValueErrors({});
    setAlertMessages([]);
    setCurrentSectionIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.sections]);

  useEffect(() => {
    const updatedRanges = [...sectionRanges];
    updatedRanges.forEach((range, index, arr) => {
      if (index === 0) {
        range.min = config.gaugeMinValue;
      }
      if (index === arr.length - 1) {
        range.max = config.gaugeMaxValue;
      } else {
        range.max = arr[index + 1]?.min || null;
      }
    });

    setSectionRanges(updatedRanges);
    setUpdatedConfig((prev) => ({
      ...prev,
      sectionRanges: updatedRanges as SectionRange[],
    }));

    updatedRanges.forEach((range, index) => {
      if (range.max !== null) {
        validateSectionRange(index, range.max);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.gaugeMinValue, config.gaugeMaxValue, gaugeDataMinValue, gaugeDataMaxValue]);

  const validateSectionRange = (index: number, value: number) => {
    if (sectionRanges.length === 1) {
      return true;
    }
    const updatedRanges = [...sectionRanges];
    const errorKey = `section${index}maxError`;
    let errorMessage = "";

    let isValid;

    if (index === sectionRanges.length - 1) {
      isValid =
        value > (updatedRanges[index].min ?? 0) &&
        value <= gaugeDataMaxValue &&
        value > (updatedRanges[index - 1].min ?? 0);
      if (!isValid) {
        errorMessage = `Section ${
          index + 1
        } maximum cannot exceed the gauge maximum value (${gaugeDataMaxValue}) and cannot be less than the previous section's maximum values.`;
      }
    } else {
      isValid =
        value > (updatedRanges[index].min ?? 0) &&
        value >= config.gaugeMinValue &&
        value <= config.gaugeMaxValue;

      if (!isValid) {
        errorMessage = `Section ${
          index + 1
        } maximum cannot be less than or equal to the minimum value, less than the gauge minimum value (${
          config.gaugeMinValue
        }), or exceed the gauge maximum value (${config.gaugeMaxValue}).`;
      }
    }

    if (!isValid) {
      setValueErrors((prev) => ({ ...prev, [errorKey]: true }));
      setAlertMessages((prev) => [...prev, errorMessage]);
      return false;
    } else {
      setValueErrors((prev) => ({ ...prev, [errorKey]: false }));
      setAlertMessages((prev) =>
        prev.filter((msg) => !msg.includes(`Section ${index + 1} `))
      );
      return true;
    }
  };

  const handleSectionsRangeChange = (
    index: number,
    value: number,
    type: "min" | "max"
  ) => {
    let updatedRanges = [...sectionRanges];

    if (type === "max") {
      updatedRanges[index].max = value;
      if (index < sectionRanges.length - 1) {
        updatedRanges[index + 1].min = value;
      }
    }

    setSectionRanges(updatedRanges);
    handleUpdateFormValue("sectionRanges", updatedRanges);

    if (validateSectionRange(index, value)) {
      if (index === currentSectionIndex && sectionTitles[index] !== "") {
        setCurrentSectionIndex(currentSectionIndex + 1);
      }
    }

    if (index === sectionRanges.length - 1) {
      updatedRanges[index].max = gaugeDataMaxValue;
      setSectionRanges(updatedRanges);
      handleUpdateFormValue("sectionRanges", updatedRanges);
    }

    if (
      index === sectionRanges.length - 1 &&
      type === "max" &&
      validateSectionRange(index, value)
    ) {
      handleUpdateFormValue("gaugeMaxValue", value);
    }
  };

  const handleSectionTitleChange = (index: number, value: string) => {
    const updatedTitles = [...sectionTitles];
    updatedTitles[index] = value;

    setSectionTitles(updatedTitles);
    setUpdatedConfig((prev) => ({
      ...prev,
      sectionTitles: updatedTitles,
    }));

    if (
      value !== "" &&
      !valueErrors[`section${index}maxError`] &&
      sectionRanges[index].max !== null &&
      sectionRanges[index].max !== 0
    ) {
      if (index === currentSectionIndex) {
        setCurrentSectionIndex(currentSectionIndex + 1);
      }
    }
  };

  return Number(config.sections) !== 1 ? (
    <Grid item xs={12} sm={12}>
      <Grid container spacing={2} justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={12} sm={12}>
          <Typography style={theme.customizedTextStyles.labelStyles}>
            Configure Gauge Sections
          </Typography>
        </Grid>
        {sectionRanges.slice(0, currentSectionIndex + 1).map((range, index) => (
          <Grid item xs={12} sm={12} key={`section-${index}`}>
            <Box component="div" css={[styles.sectionsRangeBox]}>
              <TextField
                label={`Section ${index + 1} Title`}
                value={sectionTitles[index] || ""}
                onChange={(e) => handleSectionTitleChange(index, e.target.value)}
                error={valueErrors[`section${index}titleError`]}
                css={[styles.sectionsRangeTextField, styles.rightSpacer1]}
              />
              <TextField
                label={`Section ${index + 1} Max Value`}
                value={
                  index === sectionRanges.length - 1
                    ? config.gaugeMaxValue
                    : range.max === config.gaugeMaxValue
                    ? ""
                    : range.max || ""
                  // index === sectionRanges.length - 1 ? config.gaugeMaxValue : ""
                }
                onChange={(e) =>
                  handleSectionsRangeChange(index, Number(e.target.value), "max")
                }
                numberField
                css={[styles.sectionsRangeTextField]}
                error={valueErrors[`section${index}maxError`]}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Grid>
  ) : null;
};

export default GaugeWidgetSectionsRangeConfig;
