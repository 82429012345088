import React from "react";
import { IconButton } from "@mui/material";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

interface PasswordToggleIconProps {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
}

const PasswordToggleIcon: React.FC<PasswordToggleIconProps> = ({
  showPassword,
  setShowPassword,
  style,
}) => {
  return (
    <IconButton onClick={() => setShowPassword((prev) => !prev)} style={{ ...style }}>
      {showPassword ? <VisibilityOffOutlinedIcon /> : <RemoveRedEyeOutlinedIcon />}
    </IconButton>
  );
};

export default PasswordToggleIcon;
