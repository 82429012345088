import { useEffect, useState } from "react";
import MultiStageForm from "../../../Components/SmallComponents/MultiStageForm/MultiStageForm";
import {
  aoiSchemeOptions,
  coverageReportSchemeOptions,
  crossSectionAnalysisSchemeOptions,
  orderNewStencilSchemeOptions,
  SampleOrderFormValues,
  spiReportSchemeOptions,
  spcReportSchemeOptions,
  stencilOrderSpeedSchemeOptions,
  thermoprofileMeasurementSchemeOptions,
  ORDER_STAGES,
  convertDateToString,
  ModalTitle,
  setPreferredDateToDefault,
} from "../../../Components/PageComponents/Order/ordersUtils";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../Global/Styles/components";
import { Grid, useTheme } from "@mui/material";
import { Formik } from "formik";
import {
  YUP_DATE_REQUIRED,
  YUP_NUMBER_REQUIRED_NUMBER,
  YUP_REQUIRED_STRING,
  YUP_STRING,
} from "../../../Global/Constants/yupConstants";
import { bool, object } from "yup";
import TextField from "../../../Components/MaterialUI/FormFields/TextFields";
import DatePicker from "../../../Components/MaterialUI/DateTimePickers/DatePicker";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import RadioGroup from "../../../Components/MaterialUI/FormFields/RadioGroup";
import { CheckedItem } from "../../../Components/PageComponents/Operations/MyForms/staticFormsUtils";
import OrderSummary from "../../../Components/PageComponents/Operations/MyForms/OrderSummary";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import Select from "../../../Components/MaterialUI/FormFields/Select";
import LabelWithBoldedPart from "../../../Components/MaterialUI/LabelWithBoldedPart";
import {
  GetQueryOrderNumberSnippet,
  GetQueryOrdersBusinessUnitsSnippet,
} from "./apiOrdersSnippets";
import {
  getQueryOrderNumber,
  getQueryOrdersBusinessUnits,
  postQueryOrder,
} from "./ordersApi";
import { PostQueryOrderInput } from "./apiOrdersInputs";
import { addMonths } from "date-fns";
import Button from "../../MaterialUI/Button";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

interface NewOrderFormProps {
  setModalTitle: React.Dispatch<React.SetStateAction<ModalTitle>>;
  fetchOrderData: () => void;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const fieldValidation = object({
  sample_name: YUP_REQUIRED_STRING,
  contact_person_development: YUP_REQUIRED_STRING,
  contact_person_ppm: YUP_REQUIRED_STRING,
  business_unit: YUP_REQUIRED_STRING,
  cost_center: YUP_REQUIRED_STRING,
  sap_order_number: YUP_REQUIRED_STRING,
  quantity_panels: YUP_NUMBER_REQUIRED_NUMBER,
  me_sampling_needed_panels: YUP_NUMBER_REQUIRED_NUMBER,
  quantity_pcbs: YUP_NUMBER_REQUIRED_NUMBER,
  preferred_date: YUP_DATE_REQUIRED,
  material_availability_date: YUP_DATE_REQUIRED,
  panels: YUP_REQUIRED_STRING,
  assembly_board: YUP_REQUIRED_STRING,
  number_of_board_bare_pcb: YUP_REQUIRED_STRING,
  number_of_board_bare_panel: YUP_REQUIRED_STRING,
  windchill_link: YUP_REQUIRED_STRING,
  order_new_stencil: YUP_STRING,
  stencil_order_speed: YUP_STRING,
  spi_report: YUP_STRING,
  thermoprofile_measurement: YUP_STRING,
  aoi: YUP_STRING,
  coverage_report: YUP_STRING,
  spc_report: YUP_STRING,
  cross_section_analisys: YUP_STRING,
  termsAccepted: bool().oneOf([true], "You must accept the terms and conditions."),
});

const initialValues: SampleOrderFormValues = {
  sample_name: "",
  contact_person_development: "",
  contact_person_ppm: "",
  business_unit: "",
  cost_center: "",
  sap_order_number: null,
  quantity_panels: null,
  me_sampling_needed_panels: null,
  quantity_pcbs: null,
  preferred_date: setPreferredDateToDefault(),
  material_availability_date: null,
  panels: "",
  assembly_board: "",
  number_of_board_bare_pcb: "",
  number_of_board_bare_panel: "",
  windchill_link: "",
  order_new_stencil: "No",
  stencil_order_speed: "Standart (up to 14 working days)",
  spi_report: "No",
  thermoprofile_measurement: "Use similar profile (no measurement)",
  aoi: "No",
  coverage_report: "No",
  spc_report: "No",
  cross_section_analisys: "No",
};

const NewOrderForm: React.FC<NewOrderFormProps> = ({
  setModalTitle,
  fetchOrderData,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);
  const [checkedItems, setCheckedItems] = useState<CheckedItem[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [delivery, setDelivery] = useState<string>(initialValues.stencil_order_speed);
  const [businessUnits, setBusinessUnits] = useState<GetQueryOrdersBusinessUnitsSnippet>(
    []
  );
  const [orderNumber, setOrderNumber] = useState<GetQueryOrderNumberSnippet>({
    order_number: "",
  });

  const fetchBussinesUnits = async () => {
    const units = await callApi<GetQueryOrdersBusinessUnitsSnippet>({
      query: getQueryOrdersBusinessUnits(),
      auth: { setAuthedUser },
    });

    const formattedUnits = units.map((unit) => ({
      value: unit.value,
      description: `${unit.description} (${unit.value})`,
    }));

    setBusinessUnits(formattedUnits);
  };

  const fetchOrderNumber = async () => {
    const generatedNumber = await callApi<GetQueryOrderNumberSnippet>({
      query: getQueryOrderNumber(),
      auth: { setAuthedUser },
    });

    setOrderNumber(generatedNumber);
  };

  useEffect(() => {
    const initializeCheckedItems = () => {
      const initialCheckedItems: CheckedItem[] = [];

      const options = [
        { label: "Order New Stencil", value: initialValues.order_new_stencil },
        { label: "Stencil Order Speed", value: initialValues.stencil_order_speed },
        { label: "SPI Report", value: initialValues.spi_report },
        {
          label: "Thermoprofile Measurement",
          value: initialValues.thermoprofile_measurement,
        },
        { label: "AOI", value: initialValues.aoi },
        { label: "Coverage Report", value: initialValues.coverage_report },
        { label: "SPC Report", value: initialValues.spc_report },
        { label: "Cross Section Analysis", value: initialValues.cross_section_analisys },
      ];

      options.forEach((option) => {
        if (option.value.includes("+") && option.value.includes("Euro")) {
          const priceMatch = option.value.match(/\+(\d+)\s*Euro/);
          const price = priceMatch ? parseFloat(priceMatch[1].trim()) : 0;
          if (price > 0) {
            initialCheckedItems.push({ label: option.label, price, total: price });
          }
        }
      });

      setCheckedItems(initialCheckedItems);
      setTotalPrice(initialCheckedItems.reduce((acc, item) => acc + item.price, 0));
    };

    initializeCheckedItems();
    fetchBussinesUnits();
    fetchOrderNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = async (values: SampleOrderFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      if (
        !values.sample_name ||
        !values.contact_person_development ||
        !values.contact_person_ppm ||
        !values.quantity_panels ||
        !values.me_sampling_needed_panels ||
        !values.quantity_pcbs ||
        !values.business_unit ||
        !values.preferred_date ||
        !values.material_availability_date ||
        !values.cost_center ||
        !values.sap_order_number ||
        !values.panels ||
        !values.assembly_board ||
        !values.number_of_board_bare_pcb ||
        !values.number_of_board_bare_panel ||
        !values.windchill_link
      ) {
        setFormStatus("warning");
        setAlertMessage("All required fields must be filled out");
        return;
      }

      const mapOptionValue = (optionValue: string) => {
        const lowerCaseValue = optionValue.toLowerCase();
        if (lowerCaseValue.includes("yes")) return "yes";
        if (lowerCaseValue.includes("no")) return "no";
        if (lowerCaseValue.includes("complete redesign")) return "complete redesign";
        if (lowerCaseValue.includes("standard")) return "standard";
        if (lowerCaseValue.includes("express")) return "express";
        if (lowerCaseValue.includes("urgent")) return "urgent";
        return lowerCaseValue;
      };

      const input: PostQueryOrderInput = {
        order_number: orderNumber?.order_number,
        sample_name: values.sample_name,
        contact_person_development: values.contact_person_development,
        contact_person_ppm: values.contact_person_ppm,
        quantity_panels: values.quantity_panels,
        me_sampling_needed_panels: values.me_sampling_needed_panels,
        quantity_pcbs: values.quantity_pcbs,
        business_unit: values.business_unit,
        preferred_date: values.preferred_date
          ? convertDateToString(values.preferred_date)
          : "",
        material_availability_date: values.material_availability_date
          ? convertDateToString(values.material_availability_date)
          : "",
        cost_center: values.cost_center,
        sap_order_number: values.sap_order_number,
        panels: values.panels,
        assembly_board: values.assembly_board,
        number_of_board_bare_pcb: values.number_of_board_bare_pcb,
        number_of_board_bare_panel: values.number_of_board_bare_panel,
        windchill_link: values.windchill_link,
        order_options: [
          {
            option_name: "order new stencil",
            option_value: mapOptionValue(values.order_new_stencil),
          },
          {
            option_name: "stencil order speed",
            option_value: mapOptionValue(values.stencil_order_speed),
          },
          { option_name: "spi report", option_value: mapOptionValue(values.spi_report) },
          {
            option_name: "thermoprofile measurement",
            option_value: mapOptionValue(values.thermoprofile_measurement),
          },
          { option_name: "aoi", option_value: mapOptionValue(values.aoi) },
          {
            option_name: "coverage report",
            option_value: mapOptionValue(values.coverage_report),
          },
          { option_name: "spc report", option_value: mapOptionValue(values.spc_report) },
          {
            option_name: "cross section analysis",
            option_value: mapOptionValue(values.cross_section_analisys),
          },
        ],
      };

      await callApi<PostQueryOrderInput>({
        query: postQueryOrder(input),
        auth: { setAuthedUser },
      });

      setFormStatus("success");
      setAlertMessage(
        "Thank you for your order! An email confirmation has been sent to your inbox with all the details."
      );
      fetchOrderData();
      setUnsavedChanges(false);
      setHasUnsavedChanges(false);
      setModalTitle(null);
    } catch (err) {
      console.error("OrdersManagementForm handleFormSubmit error:", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong during submission.");
    }
  };

  const handleOnNextStage = async (currentValues: SampleOrderFormValues) => {
    try {
      const isStep1Valid =
        currentValues.sample_name &&
        currentValues.contact_person_development &&
        currentValues.contact_person_ppm &&
        currentValues.business_unit &&
        currentValues.cost_center &&
        currentValues.sap_order_number;
      const isStep2Valid =
        currentValues.quantity_panels &&
        currentValues.me_sampling_needed_panels &&
        currentValues.quantity_pcbs &&
        currentValues.preferred_date &&
        currentValues.material_availability_date;
      const isStep3Valid =
        currentValues.panels &&
        currentValues.assembly_board &&
        currentValues.number_of_board_bare_pcb &&
        currentValues.number_of_board_bare_panel &&
        currentValues.windchill_link;

      if (activeStep === 0 && !isStep1Valid) {
        setAlertMessage("Please fill out all required fields in Sample Details.");
        setFormStatus("warning");
        return false;
      }

      if (activeStep === 1 && !isStep2Valid) {
        setAlertMessage("Please fill out all required fields in Panel and PCB Details.");
        setFormStatus("warning");
        return false;
      }

      if (activeStep === 3 && !isStep3Valid) {
        setAlertMessage(
          "Please fill out all required fields in PCB and Assembly Details."
        );
        setFormStatus("warning");
        return false;
      }

      if (activeStep === 4) {
        await handleFormSubmit(currentValues);
        return false;
      }

      setAlertMessage(null);
      return true;
    } catch (err) {
      setFormStatus("error");
      setAlertMessage("Something went wrong");
      return false;
    }
  };

  const handleRadioButtonChange = (label: string, value: string, isChecked: boolean) => {
    setHasUnsavedChanges(true);
    if (label === "Stencil Order Speed") {
      setDelivery(value);
    }

    if (value.includes("+") && value.includes("€")) {
      const priceMatch = value.match(/\+(\d+)\s*€/);
      const price = priceMatch ? parseFloat(priceMatch[1].trim()) : 0;

      setCheckedItems((prevItems) => {
        const newItems = prevItems.filter((item) => item.label !== label);

        if (isChecked && price > 0) {
          newItems.push({ label, price });
        }
        return newItems;
      });

      setTotalPrice((prevPrice) => {
        const prevItem = checkedItems.find((item) => item.label === label);
        const prevPriceValue = prevItem ? prevItem.price : 0;

        return isChecked
          ? prevPrice - prevPriceValue + price
          : prevPrice - prevPriceValue;
      });
    } else {
      setCheckedItems((prevItems) => prevItems.filter((item) => item.label !== label));
      setTotalPrice((prevPrice) => {
        const prevItem = checkedItems.find((item) => item.label === label);
        const prevPriceValue = prevItem ? prevItem.price : 0;

        return prevPrice - prevPriceValue;
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleChange, touched, errors, values, setFieldValue }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <MultiStageForm
            steps={ORDER_STAGES}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            handleOnNextStage={() => handleOnNextStage(values)}
            alertMessage={alertMessage}
            alertStatus={formStatus}
            disableNextButton={formStatus === "success"}
            disablePrevButton={formStatus === "success"}
            lastNextButtonLabel="Submit"
            hasUnsavedChanges={hasUnsavedChanges}
            title={`Order Number ${orderNumber?.order_number}`}
          >
            <>
              {activeStep === 0 ? (
                <Grid
                  container
                  css={[styles.textBreak, styles.flexCenter, styles.fullPadding1]}
                >
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          name="sample_name"
                          label="Sample Name"
                          error={touched["sample_name"] && !!errors["sample_name"]}
                          helperText={touched["sample_name"] && errors["sample_name"]}
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.sample_name}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="contact_person_development"
                          label="Contact Person Development"
                          error={
                            touched["contact_person_development"] &&
                            !!errors["contact_person_development"]
                          }
                          helperText={
                            touched["contact_person_development"] &&
                            errors["contact_person_development"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.contact_person_development}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="contact_person_ppm"
                          label="Contact Person PPM"
                          error={
                            touched["contact_person_ppm"] &&
                            !!errors["contact_person_ppm"]
                          }
                          helperText={
                            touched["contact_person_ppm"] && errors["contact_person_ppm"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.contact_person_ppm}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          selectOptions={businessUnits}
                          name="business_unit"
                          label="Business Unit"
                          error={touched["business_unit"] && !!errors["business_unit"]}
                          helperText={touched["business_unit"] && errors["business_unit"]}
                          onChange={handleChange}
                          value={values.business_unit}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="cost_center"
                          label="Cost Center"
                          error={touched["cost_center"] && !!errors["cost_center"]}
                          helperText={touched["cost_center"] && errors["cost_center"]}
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.cost_center}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="sap_order_number"
                          label="SAP Order Number"
                          error={
                            touched["sap_order_number"] && !!errors["sap_order_number"]
                          }
                          helperText={
                            touched["sap_order_number"] && errors["sap_order_number"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.sap_order_number}
                          numberField={true}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              {activeStep === 1 ? (
                <Grid
                  container
                  spacing={4}
                  css={[styles.textBreak, styles.flexCenter, styles.fullPadding1]}
                >
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          name="quantity_panels"
                          label="Quantity Panels"
                          error={
                            touched["quantity_panels"] && !!errors["quantity_panels"]
                          }
                          helperText={
                            touched["quantity_panels"] && errors["quantity_panels"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.quantity_panels}
                          numberField={true}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          name="me_sampling_needed_panels"
                          label="ME Sampling Needed Panels"
                          error={
                            touched["me_sampling_needed_panels"] &&
                            !!errors["me_sampling_needed_panels"]
                          }
                          helperText={
                            touched["me_sampling_needed_panels"] &&
                            errors["me_sampling_needed_panels"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.me_sampling_needed_panels}
                          numberField={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          name="quantity_pcbs"
                          label="Quantity PCBs"
                          error={touched["quantity_pcbs"] && !!errors["quantity_pcbs"]}
                          helperText={touched["quantity_pcbs"] && errors["quantity_pcbs"]}
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.quantity_pcbs}
                          numberField={true}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          name="preferred_date"
                          label="Preferred Date"
                          error={touched["preferred_date"] && !!errors["preferred_date"]}
                          helperText={
                            touched["preferred_date"] && errors["preferred_date"]
                          }
                          onChange={(value) => {
                            if (value && value < addMonths(new Date(), 6)) {
                              setAlertMessage(
                                "Preferred date must be at least 6 months from today."
                              );
                              setFormStatus("warning");
                            } else {
                              setFieldValue("preferred_date", value);
                              setHasUnsavedChanges(true);
                              setAlertMessage(null);
                              setFormStatus(null);
                            }
                          }}
                          value={values.preferred_date}
                          css={styles.width100}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <DatePicker
                          name="material_availability_date"
                          label="Material Availability Date"
                          error={
                            touched["material_availability_date"] &&
                            !!errors["material_availability_date"]
                          }
                          helperText={
                            touched["material_availability_date"] &&
                            errors["material_availability_date"]
                          }
                          onChange={(value) => {
                            setFieldValue("material_availability_date", value);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.material_availability_date}
                          css={styles.width100}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          variant="outlined"
                          color="secondary"
                          to={"https://www.digikey.com/"}
                          external={true}
                          css={[
                            styles.flexCenter,
                            styles.reverseTextBreak,
                            styles.widthLimit15,
                          ]}
                        >
                          Check Availability
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              {activeStep === 2 ? (
                <Grid
                  container
                  spacing={4}
                  css={[styles.textBreak, styles.flexCenter, styles.fullPadding1]}
                >
                  <Grid item xs={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          name="panels"
                          label="Panels"
                          error={touched["panels"] && !!errors["panels"]}
                          helperText={touched["panels"] && errors["panels"]}
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.panels}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="assembly_board"
                          label="Assembly board"
                          error={touched["assembly_board"] && !!errors["assembly_board"]}
                          helperText={
                            touched["assembly_board"] && errors["assembly_board"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.assembly_board}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="number_of_board_bare_pcb"
                          label="Number of board (bare PCB)"
                          error={
                            touched["number_of_board_bare_pcb"] &&
                            !!errors["number_of_board_bare_pcb"]
                          }
                          helperText={
                            touched["number_of_board_bare_pcb"] &&
                            errors["number_of_board_bare_pcb"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.number_of_board_bare_pcb}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="number_of_board_bare_panel"
                          label="Number of board (bare Panel)"
                          error={
                            touched["number_of_board_bare_panel"] &&
                            !!errors["number_of_board_bare_panel"]
                          }
                          helperText={
                            touched["number_of_board_bare_panel"] &&
                            errors["number_of_board_bare_panel"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.number_of_board_bare_panel}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="windchill_link"
                          label="Windchill Link"
                          error={touched["windchill_link"] && !!errors["windchill_link"]}
                          helperText={
                            touched["windchill_link"] && errors["windchill_link"]
                          }
                          onChange={(e) => {
                            handleChange(e);
                            setHasUnsavedChanges(true);
                          }}
                          value={values.windchill_link}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              {activeStep === 3 ? (
                <Grid
                  container
                  spacing={4}
                  css={[styles.textBreak, styles.flexCenter, styles.fullPadding1]}
                >
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <RadioGroup
                          radioOptions={orderNewStencilSchemeOptions}
                          label="Order New Stencil"
                          value={values.order_new_stencil}
                          onChange={(e) => {
                            handleRadioButtonChange(
                              "Order New Stencil",
                              e.target.value,
                              e.target.checked
                            );
                            handleChange(e);
                            setFieldValue("order_new_stencil", e.target.value);
                            setHasUnsavedChanges(true);
                          }}
                          row
                        />
                      </Grid>

                      {values.order_new_stencil === "Complete Redesign (+1500 €)" ? (
                        <Grid item xs={12}>
                          <RadioGroup
                            radioOptions={stencilOrderSpeedSchemeOptions}
                            label="Stencil Order Speed"
                            value={values.stencil_order_speed}
                            onChange={(e) => {
                              handleRadioButtonChange(
                                "Stencil Order Speed",
                                e.target.value,
                                e.target.checked
                              );
                              handleChange(e);
                              setFieldValue("stencil_order_speed", e.target.value);
                              setHasUnsavedChanges(true);
                            }}
                            row
                          />
                        </Grid>
                      ) : null}

                      <Grid item xs={4}>
                        <RadioGroup
                          radioOptions={spiReportSchemeOptions}
                          label="SPI Report"
                          value={values.spi_report}
                          onChange={(e) => {
                            handleRadioButtonChange(
                              "SPI Report",
                              e.target.value,
                              e.target.checked
                            );
                            handleChange(e);
                            setFieldValue("spi_report", e.target.value);
                            setHasUnsavedChanges(true);
                          }}
                          row
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <RadioGroup
                          radioOptions={thermoprofileMeasurementSchemeOptions}
                          label="Thermoprofile Measurement"
                          value={values.thermoprofile_measurement}
                          onChange={(e) => {
                            handleRadioButtonChange(
                              "Thermoprofile Measurement",
                              e.target.value,
                              e.target.checked
                            );
                            handleChange(e);
                            setFieldValue("thermoprofile_measurement", e.target.value);
                            setHasUnsavedChanges(true);
                          }}
                          row
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <RadioGroup
                          radioOptions={aoiSchemeOptions}
                          label="AOI"
                          value={values.aoi}
                          onChange={(e) => {
                            handleRadioButtonChange(
                              "AOI",
                              e.target.value,
                              e.target.checked
                            );
                            handleChange(e);
                            setFieldValue("aoi", e.target.value);
                            setHasUnsavedChanges(true);
                          }}
                          row
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <RadioGroup
                          radioOptions={coverageReportSchemeOptions}
                          label="Coverage Report"
                          value={values.coverage_report}
                          onChange={(e) => {
                            handleRadioButtonChange(
                              "Coverage Report",
                              e.target.value,
                              e.target.checked
                            );
                            handleChange(e);
                            setFieldValue("coverage_report", e.target.value);
                            setHasUnsavedChanges(true);
                          }}
                          row
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <RadioGroup
                          radioOptions={spcReportSchemeOptions}
                          label="SPC Report"
                          value={values.spc_report}
                          onChange={(e) => {
                            handleRadioButtonChange(
                              "SPC Report",
                              e.target.value,
                              e.target.checked
                            );
                            handleChange(e);
                            setFieldValue("spc_report", e.target.value);
                            setHasUnsavedChanges(true);
                          }}
                          row
                        />
                      </Grid>

                      <Grid item xs={8}>
                        <RadioGroup
                          radioOptions={crossSectionAnalysisSchemeOptions}
                          label="Cross Section Analysis"
                          value={values.cross_section_analisys}
                          onChange={(e) => {
                            handleRadioButtonChange(
                              "Cross Section Analysis",
                              e.target.value,
                              e.target.checked
                            );
                            handleChange(e);
                            setFieldValue("cross_section_analisys", e.target.value);
                            setHasUnsavedChanges(true);
                          }}
                          row
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}

              {activeStep === 4 ? (
                <Grid container spacing={2} css={[styles.fullPadding1]}>
                  <Grid item xs={5} mt={3.5}>
                    <LabelWithBoldedPart
                      text={"Sample Name"}
                      bolded={values.sample_name}
                    />
                    <LabelWithBoldedPart
                      text={"Contact Person Development"}
                      bolded={values.contact_person_development}
                    />
                    <LabelWithBoldedPart
                      text={"Contact Person PPM"}
                      bolded={values.contact_person_ppm}
                    />
                    <LabelWithBoldedPart
                      text={"Quantity Panels"}
                      bolded={values.quantity_panels ? values.quantity_panels : ""}
                    />
                    <LabelWithBoldedPart
                      text={"ME Sampling Needed Panels"}
                      bolded={
                        values.me_sampling_needed_panels
                          ? values.me_sampling_needed_panels
                          : ""
                      }
                    />
                    <LabelWithBoldedPart
                      text={"Quantity PCBs"}
                      bolded={values.quantity_pcbs ? values.quantity_pcbs : ""}
                    />
                    <LabelWithBoldedPart
                      text={"Business Unit"}
                      bolded={values.business_unit}
                    />
                    <LabelWithBoldedPart
                      text={"Preferred Date"}
                      bolded={
                        values.preferred_date
                          ? convertDateToString(values.preferred_date)
                          : ""
                      }
                    />
                    <LabelWithBoldedPart
                      text={"Material Availability Date"}
                      bolded={
                        values.material_availability_date
                          ? convertDateToString(values.material_availability_date)
                          : ""
                      }
                    />
                    <LabelWithBoldedPart text={"Panels"} bolded={values.panels} />
                    <LabelWithBoldedPart
                      text={"Assembly Board"}
                      bolded={values.assembly_board}
                    />
                    <LabelWithBoldedPart
                      text={"Number of board (bare PCB)"}
                      bolded={values.number_of_board_bare_pcb}
                    />
                    <LabelWithBoldedPart
                      text={"Number of board (bare Panel)"}
                      bolded={values.number_of_board_bare_panel}
                    />
                    <LabelWithBoldedPart
                      text={"Windchill Link"}
                      bolded={values.windchill_link}
                    />
                    <LabelWithBoldedPart
                      text={"Cost Center"}
                      bolded={values.cost_center}
                    />
                    <LabelWithBoldedPart
                      text={"SAP Order Number"}
                      bolded={values.sap_order_number ? values.sap_order_number : ""}
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <OrderSummary
                      checkedItems={checkedItems}
                      totalPrice={totalPrice}
                      isWithDiscount={false}
                      delivery={
                        values.order_new_stencil === "Complete Redesign (+1500 €)"
                          ? delivery
                          : undefined
                      }
                    />
                  </Grid>
                </Grid>
              ) : null}
            </>
          </MultiStageForm>
        );
      }}
    </Formik>
  );
};

export default NewOrderForm;
