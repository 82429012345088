import { gql } from "@apollo/client";

export const graphQlQueryExcellenceParameters = gql`
  query ExcellenceParameters($dashboardId: String = null) {
    parameters(dashboardId: $dashboardId) {
      container
      containerName
      id
      name
      type
      unitId
      unitName
      unitSymbol
    }
  }
`;

export const graphQlQueryExcellencePieData = gql`
  query ExcellencePieChartData($input: PieChartDataInput!) {
    pieData(input: $input) {
      chartData {
        id
        label
        value
      }
    }
  }
`;

export const graphQlQueryExcellenceTimeSeriesData = gql`
  query ExcellenceTimeSeriesData($input: ChartDataInput!) {
    timeSeriesData(input: $input) {
      chartData {
        id: parameterId
        name
        data {
          x: timestamp
          y: value
          highAlarmLimit
          highWarningLimit
          lowAlarmLimit
          lowWarningLimit
        }
      }
    }
  }
`;
export const graphQlQueryExcellenceBarData = gql`
  query ExcellenceBarChartData($input: BarChartDataInput!) {
    barchartData(input: $input) {
      chartData
    }
  }
`;
export const graphQlQueryExcellenceBoxPlotData = gql`
  query ExcellenceBoxplotDataData($input: BoxplotDataInput!) {
    boxplotData(input: $input) {
      chartData {
        group
        subgroup
        value
      }
    }
  }
`;

export const graphQlQueryExcellenceHeatMapData = gql`
  query ExcellenceHeatMapDataData($input: HeatMapDataInput!) {
    heatMapData(input: $input) {
      chartData {
        id
        data {
          x
          y
        }
      }
    }
  }
`;

export const graphQlQueryExcellenceIndicatorData = gql`
  query ExcellenceIndicatorData($input: IndicatorDataInput!) {
    indicatorData(input: $input) {
      data {
        latest
        average
        trendAverage
        trendLatest
        chart
      }
    }
  }
`;
export const graphQlQueryExcellenceTableData = gql`
  query ExcellenceTableChartData($input: TableDataInput!) {
    tableData(input: $input) {
      chartData {
        columns
        rows
      }
    }
  }
`;

export const graphQlQueryExcellenceLineChartData = gql`
  query ExcellenceLineChartDataData($input: LineChartDataInput!) {
    lineChartData(input: $input) {
      chartData {
        id
        data {
          x
          y
        }
      }
    }
  }
`;

export const graphQlQueryExcellenceAreaChartData = gql`
  query ExcellenceAreaChartDataData($input: AreaChartDataInput!) {
    areaChartData(input: $input) {
      chartData {
        id
        data {
          x
          y
        }
      }
    }
  }
`;
