import { FunnelData } from "../Funnel/funnelTypes";

const funnelDefaultData: FunnelData = [
  {
    id: "step_sent",
    value: 90427,
    label: "Sent",
  },
  {
    id: "step_viewed",
    value: 80199,
    label: "Viewed",
  },
  {
    id: "step_processed",
    value: 56080,
    label: "Processed",
  },
  {
    id: "step_counted",
    value: 44641,
    label: "Counted",
  },
  {
    id: "step_shipped",
    value: 34776,
    label: "Shipped",
  },
];

export default funnelDefaultData;
