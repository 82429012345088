import { useState } from "react";
import ResponsiveTableGrid from "../../SmallComponents/TableGrid/ResponsiveTableGrid";
import { TableGridColumnSchema } from "../../SmallComponents/TableGrid/constructTableGrid";
import CellActionButton, {
  ModalLayoutData,
} from "../../SmallComponents/TableGrid/CellActionButton";
import Modal from "../../MaterialUI/Modal";
import HistoryTrackingModalLayout from "./HistoryTrackingModalLayout";
import CommentModalLayout from "./CommentModalLayout";
import { GetQueryTTObjectsAvailabilitySnippet } from "../../../Api/TrackAndTrace/apiTTSnippets";
import VisibilityIcon from "@mui/icons-material/Visibility";
import HistoryIcon from "@mui/icons-material/History";
import { Tooltip, Typography } from "@mui/material";

const colSchema: TableGridColumnSchema[] = [
  { id: "objectCode", label: "Object Code", type: "string" },
  { id: "objectType", label: "Object Type", type: "string" },
  { id: "lastScanned", label: "Last Scanned", type: "dateTime" },
  { id: "lastLocation", label: "Last Location", type: "string" },
  { id: "currentLocation", label: "Current Location", type: "string" },
  {
    id: "status",
    label: "Status",
    type: "number",
  },
  {
    id: "comment",
    label: "Comment",
    type: "string",
  },
  { id: "history", label: "History Track", type: "button" },
];

interface ObjectTrackingTableProps {
  objectsAvailabilityData: GetQueryTTObjectsAvailabilitySnippet;
}

const ObjectTrackingTable: React.FC<ObjectTrackingTableProps> = ({
  objectsAvailabilityData,
}) => {
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalLayoutData | null>(null);

  return (
    <>
      <ResponsiveTableGrid
        rows={handleFilterData(setOpenInfo, setModalData, objectsAvailabilityData)}
        colSchema={colSchema}
        editMode={false}
        responsive="responsive"
        configuration={{
          density: "compact",
        }}
      />

      {modalData ? (
        <Modal
          open={openInfo}
          fullWidth
          label={modalData.type === "history" ? "History Tracking" : "Comments"}
          onClose={() => setOpenInfo(false)}
        >
          {modalData.type === "history" ? (
            <HistoryTrackingModalLayout projectId={modalData.data} />
          ) : null}
          {modalData.type === "comments" ? <CommentModalLayout /> : null}
        </Modal>
      ) : null}
    </>
  );
};

export default ObjectTrackingTable;

const handleFilterData = (
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>,
  setModalData: React.Dispatch<React.SetStateAction<ModalLayoutData | null>>,
  objectsAvailabilityData: GetQueryTTObjectsAvailabilitySnippet | undefined
): Object[] => {
  if (!objectsAvailabilityData) {
    return [];
  }

  return objectsAvailabilityData.map((availability) => ({
    objectCode: availability.object_code,
    objectType: availability.object_type,
    lastScanned: availability.last_scan,
    lastLocation: (
      <Tooltip title={availability.last_location}>
        <Typography>
          {availability.last_location.includes("/") &&
          availability.last_location.split("/").length > 2
            ? `.../${availability.last_location.split("/").slice(2).join("/")}`
            : availability.last_location}
        </Typography>
      </Tooltip>
    ),
    currentLocation: (
      <Tooltip title={availability.current_location}>
        <Typography>
          {availability.current_location.includes("/") &&
          availability.current_location.split("/").length > 2
            ? `.../${availability.current_location.split("/").slice(2).join("/")}`
            : availability.current_location}
        </Typography>
      </Tooltip>
    ),
    status: availability.status,
    comment: (
      <CellActionButton
        text="View All"
        icon={<VisibilityIcon />}
        handleOnClick={() => {
          setOpenInfo(true);
          setModalData({ type: "comments", data: "" });
        }}
      />
    ),
    history: (
      <CellActionButton
        text="View"
        icon={<HistoryIcon />}
        handleOnClick={() => {
          setOpenInfo(true);
          setModalData({ type: "history", data: "" });
        }}
      />
    ),
  }));
};
