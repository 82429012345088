import { Query } from "../../../Api/callApi";
import { TableGridColumnSchema } from "../../../Components/SmallComponents/TableGrid/constructTableGrid";
import { LongPlanningMachineRow, LongPlanningProductRow } from "./longTermPlanningUtils";

export const getQueryNeoperlData: Query = {
  endpoint: "neoperl/get_data",
  method: "GET",
};

export type GetQueryNeoperlDataSnippet = {
  machine_columns: TableGridColumnSchema[];
  product_columns: TableGridColumnSchema[];
  machine_rows: LongPlanningMachineRow[];
  product_rows: LongPlanningProductRow[];
  total_free_capacity: number;
  total_occupied_capacity: number;
};

export const putQueryNeoperlProduct = (
  productID: string,
  input: PutQueryNeoperlProduct
): Query => ({
  endpoint: `neoperl/product/${productID}`,
  method: "PUT",
  variables: input,
});

export const postQueryNeoperlProduct = (input: PutQueryNeoperlProduct): Query => ({
  endpoint: `neoperl/product`,
  method: "POST",
  variables: input,
});

export type PutQueryNeoperlProduct = {
  product_name: string;
  quantity: number;
};

export const putQueryNeoperlMachine = (
  machineID: string,
  input: PutQueryNeoperlMachine
): Query => ({
  endpoint: `neoperl/machine/${machineID}`,
  method: "PUT",
  variables: input,
});

export const postQueryNeoperlMachine = (input: PutQueryNeoperlMachine): Query => ({
  endpoint: `neoperl/machine`,
  method: "POST",
  variables: input,
});

export type PutQueryNeoperlMachine = {
  machine_type: string;
  production_plant: string;
  products: string[];
  maximum_speed: number;
  average_output_production_per_hour: number;
};

export const deleteQueryNeoperlMachine = (machineID: string): Query => ({
  endpoint: `neoperl/machine/${machineID}`,
  method: "DELETE",
});

export const deleteQueryNeoperlProduct = (productID: string): Query => ({
  endpoint: `neoperl/product/${productID}`,
  method: "DELETE",
});
