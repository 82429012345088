import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { GetQueryMaintenanceTasksSnippet } from "../../../../../Api/Maintenance/apiMaintenanceSnippets";
import { ResponsiveTimeRange, TimeRangeDayData } from "@nivo/calendar";
import { getChartColors } from "../../../../ExcellenceWidgets/nivoTheme";
import ContentBox from "../../../../MaterialUI/ContentBox";
import {
  addMinutes,
  eachDayOfInterval,
  endOfDay,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import { useRef, useState } from "react";
import { formatDateAndTime } from "../../../../../Global/Utils/commonFunctions";
import MaintenanceCalendarFilters from "./MaintenancePlanCalendarFilters";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import MaintenanceCalendarPeriod from "./MaintenancePlanCalendarPeriod";
import {
  MaintenancePlanModalType,
  MaintenancePlanTableTaskRow,
  handleGetMaintenancePlanTableRows,
} from "../maintenancePlanUtils";
import MaintenanceTable from "../MaintenancePlanTable/MaintenancePlanTable";
import useContainerDimensions from "../../../../../Global/Hooks/useContainerDimensions";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import { css } from "@emotion/react";

const cssStyles = {
  calendarCont: css({
    "& text, & text *": {
      fontSize: "13px !important",
      fontFamily: "Montserrat !important",
    },
  }),
};

type CalendarData = {
  day: string;
  value: number;
  taskIDs: string[];
}[];

type CalendarStartEnd = {
  from: string;
  to: string;
};
type TableData = {
  tasks: MaintenancePlanTableTaskRow[];
  date: string;
};
type PeriodOption = "month" | "quarter" | "year";

interface MaintenanceCalendarProps {
  data: GetQueryMaintenanceTasksSnippet;
  setModalType: React.Dispatch<React.SetStateAction<MaintenancePlanModalType>>;
}

const MaintenancePlanCalendar: React.FC<MaintenanceCalendarProps> = ({
  data,
  setModalType,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles, ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const calendarRef = useRef<HTMLDivElement>(null);
  const { width: calendarWidth } = useContainerDimensions(calendarRef);

  const [tableData, setTableData] = useState<TableData | null>(null);
  const [displayData, setDisplayData] = useState<CalendarData>(
    transformToCalendarData(data)
  );
  const [calendarFromTo, setCalendarFromTo] = useState<CalendarStartEnd>({
    from: startOfMonth(new Date()).toISOString(),
    to: endOfMonth(new Date()).toISOString(),
  });
  const [period, setPeriod] = useState<PeriodOption>("month");

  const calendarHeight: number = calendarWidth
    ? period === "month"
      ? calendarWidth / 1.1
      : period === "year"
      ? calendarWidth / 4.5
      : calendarWidth / 1.5
    : 400;

  const leftRightMargin = period === "year" ? 40 : 10;

  const handleOnClick = (datum: TimeRangeDayData) => {
    // @ts-ignore
    if (datum?.value) {
      console.log("datum ", datum);
      // @ts-ignore
      const taskIDs: string[] = datum.taskIDs;
      const tableTasks: GetQueryMaintenanceTasksSnippet = [];

      // 1. get all tasks for the clicked date
      taskIDs.forEach((id) => {
        data.forEach((task) => {
          if (task.id === id) {
            tableTasks.push(task);
          }
        });
      });

      // 2. parse the tasks into expected format
      const tableRows = handleGetMaintenancePlanTableRows(setModalType, tableTasks);

      setTableData({
        date: formatDateAndTime(datum.date, "date"),
        tasks: tableRows,
      });
    }
  };

  const handleChangeDisplayData = (filteredData: GetQueryMaintenanceTasksSnippet) => {
    setDisplayData(transformToCalendarData(filteredData));
  };

  return (
    <Box component="div" css={styles.width100}>
      <ContentBox css={styles.width100}>
        <MaintenanceCalendarFilters
          css={styles.sectionBreak}
          allData={data}
          changeDisplayData={handleChangeDisplayData}
        />

        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            lg={period === "year" ? 12 : 6}
            xl={period === "year" ? 12 : 4}
          >
            <MaintenanceCalendarPeriod
              css={styles.contentBreak}
              calendarFromTo={calendarFromTo}
              setCalendarFromTo={setCalendarFromTo}
              setPeriod={setPeriod}
              period={period}
            />

            <Box
              component="div"
              css={[
                styles.sectionBreak,
                period !== "year" && styles.widthLimit30,
                styles.marginHorizontalAuto,
                styles.calendarCont,
              ]}
              ref={calendarRef}
              style={{ height: calendarHeight, minHeight: "250px" }}
            >
              <ResponsiveTimeRange
                data={displayData}
                from={calendarFromTo.from}
                to={calendarFromTo.to}
                emptyColor={theme.palette.background.paper}
                colors={getChartColors()}
                theme={{
                  text: {
                    fill: theme.palette.common.black,
                  },
                  tooltip: {
                    container: {
                      background: theme.palette.common.white,
                    },
                  },
                }}
                dayBorderColor={theme.palette.grey[600]}
                onClick={handleOnClick}
                margin={{
                  top: 30,
                  right: leftRightMargin,
                  bottom: 100,
                  left: leftRightMargin,
                }}
                dayBorderWidth={2}
                firstWeekday="monday"
                weekdayTicks={[0, 1, 2, 3, 4, 5, 6]}
                weekdayLegendOffset={85}
                dayRadius={4}
                minValue={1}
                legendFormat={(value) => `${value} Tasks`}
                legends={[
                  {
                    anchor: "bottom-right",
                    direction: "row",
                    justify: false,
                    itemCount: 2,
                    itemWidth: 33,
                    itemHeight: 36,
                    itemsSpacing: 60,
                    itemDirection: "left-to-right",
                    translateX: -200,
                    translateY: -60,
                    symbolSize: 20,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={period === "year" ? 12 : 6}
            xl={period === "year" ? 12 : 8}
          >
            {!tableData ? (
              <Stack
                css={styles.height100}
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Typography variant="h4" textAlign="center" color="textSecondary">
                  Click on a calendar cell which has at least one task to view all tasks
                  for this particular day in a table format.
                </Typography>
                <Typography variant="h4" textAlign="center" color="textSecondary">
                  Clicking on an empty cell won't have any effect.
                </Typography>
                <Typography variant="h4" textAlign="center" color="textSecondary">
                  If you are unable to find any days with tasks, try changing the period
                  to quarter or year.
                </Typography>
              </Stack>
            ) : (
              <>
                <Typography css={styles.contentBreak} textAlign="center" variant="h3">
                  {tableData?.date ? `Tasks for ${tableData.date}` : ""}
                </Typography>
                <MaintenanceTable
                  rows={tableData?.tasks || []}
                  hideColumns={[
                    "comments",
                    "documents",
                    "responsible",
                    "repetitive",
                    "plannedDuration",
                    "executionDuration",
                    "workHours",
                    "extraDescription",
                  ]}
                />
              </>
            )}
          </Grid>
        </Grid>
      </ContentBox>
    </Box>
  );
};

export default MaintenancePlanCalendar;

const transformToCalendarData = (
  machineData: GetQueryMaintenanceTasksSnippet
): CalendarData => {
  if (!machineData.length) {
    return [];
  }

  const datesToAdd: Record<
    string,
    {
      value: number;
      taskIDs: string[];
    }
  > = {};

  // 1. Mapping for the data
  console.log("machineData ", machineData);
  machineData.forEach((task) => {
    const allDaysBetween = eachDayOfInterval({
      start: new Date(task.start_date),
      end: task.planned_duration
        ? addMinutes(new Date(task.start_date), task.planned_duration)
        : endOfDay(new Date(task.start_date)),
    });

    allDaysBetween.forEach((item) => {
      const day = item.toISOString();
      if (datesToAdd[day]) {
        datesToAdd[day] = {
          value: datesToAdd[day].value + 1,
          taskIDs: [...datesToAdd[day].taskIDs, task.id],
        };
      } else {
        datesToAdd[day] = {
          value: 1,
          taskIDs: [task.id],
        };
      }
    });
  });

  // 2. Get the data in format used by the calendar
  const data: CalendarData = Object.entries(datesToAdd).map(([key, value]) => ({
    day: format(new Date(key), "yyyy-MM-dd"),
    value: value.value,
    taskIDs: value.taskIDs,
  }));

  return data;
};
