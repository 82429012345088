import { SerializedStyles } from "@emotion/react";
import Funnel from "../../../../ExcellenceWidgets/AdvancedWidgets/Funnel/Funnel";
import { FunnelData } from "../../../../ExcellenceWidgets/AdvancedWidgets/Funnel/funnelTypes";
import funnelDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/funnelDefaultData";

interface ExcellenceFunnelProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: FunnelData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceFunnel: React.FC<ExcellenceFunnelProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <Funnel
      className={className}
      configuration={configuration}
      data={data || funnelDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceFunnel;
