import { useEffect, useState } from "react";
import { GetQueryErpClassificationsSnippet } from "../../Api/SupplyChain/apiSupplyChainSnippets";
import { Box, Divider, Grid, Stack } from "@mui/material";
import CollapsibleSelectTree from "../../Components/SmallComponents/CollapsibleSelectTree/CollapsibleSelectTree";
import { CollapsibleTreeItemData } from "../../Components/SmallComponents/CollapsibleSelectTree/collapsibleTreeTypes";
import { SupplyChainTreeLeaf } from "./materialsUtils";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import ContentBox from "../../Components/MaterialUI/ContentBox";

interface MaterialTreeViewProps {
  treeData: GetQueryErpClassificationsSnippet;
}

const MaterialTreeView: React.FC<MaterialTreeViewProps> = ({ treeData }) => {
  const [erpTree, setErpTree] = useState<CollapsibleTreeItemData[]>([]);
  const [treeMapping, setTreeMapping] = useState<TreeMapping>({});
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [lastSelectedItem, setLastSelectedItem] = useState<Omit<
    SupplyChainTreeLeaf,
    "children"
  > | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setErpTree(getTreeData(treeData));
        setTreeMapping(getTreeMapping(treeData));
      } catch (error) {
        console.log("There was an error: ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  useEffect(() => {
    if (selectedItems.length) {
      setLastSelectedItem((prev) => {
        const last = selectedItems[selectedItems.length - 1];
        if (prev?.id !== last) {
          return treeMapping[last];
        }
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems, treeMapping]);

  return (
    <Box component="div">
      <ContentBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={5.5}>
            <CollapsibleSelectTree
              data={erpTree}
              selected={selectedItems}
              setSelected={setSelectedItems}
            />
          </Grid>

          <Grid item xs={12} sm={1}>
            <Divider style={{ margin: "auto", width: "1px" }} orientation="vertical" />
          </Grid>

          <Grid item xs={12} sm={5.5}>
            {lastSelectedItem ? (
              <>
                <Stack spacing={1} mb={2}>
                  <LabelWithBoldedPart text="ID" bolded={lastSelectedItem.id} />
                  <LabelWithBoldedPart text="Name" bolded={lastSelectedItem.code} />
                  <LabelWithBoldedPart text="PDM Card" bolded={lastSelectedItem.name} />
                  <LabelWithBoldedPart
                    text="Type"
                    bolded={lastSelectedItem.parent_id || ""}
                  />
                </Stack>
              </>
            ) : null}
          </Grid>
        </Grid>
      </ContentBox>
    </Box>
  );
};

export default MaterialTreeView;

const getTreeData = (leaves: SupplyChainTreeLeaf[]): CollapsibleTreeItemData[] => {
  return leaves.map((leaf) => ({
    id: leaf.id,
    uniqueID: leaf.id,
    label: leaf.name,
    code: leaf.code,
    children: leaf.children.length > 0 ? getTreeData(leaf.children) : undefined,
  }));
};

type TreeMapping = Record<string, Omit<SupplyChainTreeLeaf, "children">>;

const getTreeMapping = (leaves: SupplyChainTreeLeaf[]): TreeMapping => {
  const mapping: Record<string, Omit<SupplyChainTreeLeaf, "children">> = {};

  function addLeafToMapping(leaf: SupplyChainTreeLeaf) {
    const { children, ...leafWithoutChildren } = leaf;
    mapping[leaf.id] = leafWithoutChildren;

    // Recursively add children to the mapping
    if (children && children.length > 0) {
      children.forEach(addLeafToMapping);
    }
  }

  leaves.forEach(addLeafToMapping);

  return mapping;
};
