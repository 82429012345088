import { array, string, number, date } from "yup";

const REGEX_PASSWORD = /[!@#$%^&*(),.?:{}|<>]/g;

export const YUP_STRING = string();
export const YUP_REQUIRED_STRING = string().trim().required("Required");
export const YUP_EMAIL = string().email("Must be a valid email").required("Required");
export const YUP_PASSWORD_STRING = string()
  .trim()
  .min(8, "Must be at least 8 characters long")
  .matches(
    REGEX_PASSWORD,
    "Must have at least one character, digit and special character"
  )
  .required("Required");
export const YUP_STRING_ARRAY = array().of(string().trim());
export const YUP_REQUIRED_STRING_ARRAY = array()
  .of(string().trim())
  .min(1, "At least 1 required")
  .required("Required");
export const YUP_NUMBER_REQUIRED_NUMBER = number().required("Required");
export const YUP_NUMBER_BETWEEN_0_100 = number()
  .min(0, "Must be at least 0")
  .max(100, "Must be at most 100")
  .required("Required");
export const YUP_NUMBER_REQUIRED_BETWEEN_0_100 = number()
  .min(0)
  .max(100)
  .required("Required");
export const YUP_DATE_REQUIRED = date().required("Required");
export const YUP_DATE = date();
