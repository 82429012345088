import { Box, Checkbox, Stack, Typography, Theme } from "@mui/material";
import {
  TreeItemContentProps,
  useTreeItem,
  TreeItemProps,
  TreeItem,
} from "@mui/x-tree-view";
import React from "react";
import clsx from "clsx";
import { useSpring, animated } from "@react-spring/web";
import { TransitionProps } from "@mui/material/transitions";
import Collapse from "@mui/material/Collapse";
import { CollapsibleTreeItemExtraProps } from "./collapsibleTreeTypes";
import { css } from "@emotion/react";
import useTheme from "@mui/material/styles/useTheme";

const cssStyles = (theme: Theme) => ({
  highlighted: css({
    "& > div": {
      color: `${theme.palette.primary.main} !important`,
      fontWeight: "bold !important",
    },
  }),
});
const CustomContent = React.forwardRef(function CustomContent(
  props: TreeItemContentProps & CollapsibleTreeItemExtraProps,
  ref
) {
  const theme = useTheme();
  const styles = { ...cssStyles(theme) };
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    disabled,
    uiValues,
  } = props;

  const {
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const isHighlighted = Boolean(uiValues?.[nodeId]?.highlighted);
  const isIndeterminate = Boolean(uiValues?.[nodeId]?.indeterminate);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      return;
    }
    preventSelection(event);
  };

  const handleExpansionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      return;
    }
    handleExpansion(event);
  };

  const handleSelectionClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      return;
    }
    handleSelection(event);
  };

  return (
    <Box
      component="div"
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      sx={{ cursor: disabled ? "default !important" : "pointer" }}
      onMouseDown={handleMouseDown}
      ref={ref as React.Ref<HTMLDivElement>}
    >
      <Box
        component="div"
        onClick={handleExpansionClick}
        className={classes.iconContainer}
      >
        {icon}
      </Box>

      <Stack
        css={isHighlighted ? styles.highlighted : undefined}
        direction="row"
        alignItems="center"
      >
        <Checkbox
          checked={selected}
          indeterminate={isIndeterminate && !selected}
          size="small"
          onClick={(e: any) => handleSelectionClick(e)}
          disabled={disabled}
        />

        <Typography
          onClick={handleSelectionClick}
          component="div"
          className={classes.label}
          color={disabled ? "textSecondary" : "textPrimary"}
        >
          {label}
        </Typography>
      </Stack>
    </Box>
  );
});

const CustomTreeItem = React.forwardRef(function CustomTreeItem(
  props: TreeItemProps & CollapsibleTreeItemExtraProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { disabled, uiValues, ...otherProps } = props;

  return (
    <TreeItem
      ContentComponent={(contentProps: TreeItemContentProps) => (
        <CustomContent {...contentProps} disabled={disabled} uiValues={uiValues} />
      )}
      {...otherProps}
      ref={ref}
      TransitionComponent={TransitionComponent}
    />
  );
});

export default CustomTreeItem;

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}
