const timeChartDefaultData = [
  {
    data: [
      {
        x: "2023-10-17T08:29:05.402",
        y: 20,
      },
      {
        x: "2023-10-17T11:05:48.002",
        y: 29,
      },
      {
        x: "2023-10-18T14:20:30.123",
        y: 15,
      },
      {
        x: "2023-10-19T09:45:12.789",
        y: 35,
      },
      // Add more data points as needed
    ],
    id: "Signal A",
    name: "Signal A",
  },
  {
    data: [
      {
        x: "2023-10-17T08:29:05.402",
        y: 12,
      },
      {
        x: "2023-10-17T11:05:48.002",
        y: 25,
      },
      {
        x: "2023-10-18T14:20:30.123",
        y: 18,
      },
      {
        x: "2023-10-19T09:45:12.789",
        y: 30,
      },
      // Add more data points as needed
    ],
    id: "Signal B",
    name: "Signal B",
  },
  {
    data: [
      {
        x: "2023-10-17T08:29:05.402",
        y: 30,
      },
      {
        x: "2023-10-17T11:05:48.002",
        y: 22,
      },
      {
        x: "2023-10-18T14:20:30.123",
        y: 20,
      },
      {
        x: "2023-10-19T09:45:12.789",
        y: 16,
      },
      // Add more data points as needed
    ],
    id: "Signal C",
    name: "Signal C",
  },
  // Add more datasets as required
];

export default timeChartDefaultData;
