import { Query } from "../callApi";
import { GetQueryModbusRegisterInput } from "./apiDSInputs";

// ------------- ALL -------------
export const getQueryAllConnections: Query = {
  endpoint: "data/integrator/subscriptions",
  method: "GET",
};

// ------------- OPC DA -------------
export const getQueryDAServers: Query = {
  endpoint: "opc_da/servers",
  method: "GET",
};
export const getQueryDAAttributes: Query = {
  endpoint: "opc_da/attributes",
  method: "GET",
};
export const getQueryDACanonicalDataTypes: Query = {
  endpoint: "opc_da/canonical_datatypes",
  method: "GET",
};
export const getQueryDaSubscriptions: Query = {
  endpoint: "opc_da/subscriptions",
  method: "GET",
};
export const getQueryDaSubscriptionId = (id: string): Query => ({
  endpoint: `opc_da/subscription/${id}`,
  method: "GET",
});
export const getQueryDASubscriptionHistory = (id: string): Query => ({
  endpoint: `opc_da/subscription/history/${id}`,
  method: "GET",
});
export const getQueryDATemplate: Query = {
  endpoint: `/opc_da/bulk-config/template`,
  method: "GET",
};

// ------------- OPC UA -------------
export const getQueryUaSubscriptions: Query = {
  endpoint: "opc_ua/subscriptions",
  method: "GET",
};
export const getQueryUaSubscriptionId = (id: string): Query => ({
  endpoint: `opc_ua/subscription/${id}`,
  method: "GET",
});
export const getQueryUASubscriptionHistory = (id: string): Query => ({
  endpoint: `opc_ua/subscription/history/${id}`,
  method: "GET",
});
export const getQueryUATemplate: Query = {
  endpoint: `/opc_ua/bulk-config/template`,
  method: "GET",
};

// ------------- MQTT -------------
export const getQueryMQTTSubscriptions: Query = {
  endpoint: "mqtt/subscriptions",
  method: "GET",
};
export const getQueryMQTTSubscriptionId = (id: string): Query => ({
  endpoint: `mqtt/subscription/${id}`,
  method: "GET",
});
export const getQueryMQTTBrokerConnect = (
  brokerHost: string,
  brokerPort: string | number
): Query => ({
  endpoint: `mqtt/${brokerHost}/${brokerPort}/connect`,
  method: "GET",
});
export const getQueryMQTTTopicMessage = (
  brokerHost: string,
  brokerPort: string | number,
  topicName: string
): Query => ({
  endpoint: `mqtt/${brokerHost}/${brokerPort}/topic/message`,
  method: "GET",
  variables: { topic_name: topicName },
});
export const getQueryMQTTSubscriptionHistory = (id: string): Query => ({
  endpoint: `mqtt/subscription/history/${id}`,
  method: "GET",
});

// ------------- Modbus -------------
export const getQueryModbusSubscriptions: Query = {
  endpoint: "modbus/connections",
  method: "GET",
};
export const getQueryModbusConnectionId = (id: string): Query => ({
  endpoint: `modbus/connection/${id}`,
  method: "GET",
});
export const getQueryModbusHistory = (id: string): Query => ({
  endpoint: `modbus/connection/${id}/history`,
  method: "GET",
});
export const getQueryModbusRegister = (input: GetQueryModbusRegisterInput): Query => ({
  endpoint: "modbus/register",
  method: "GET",
  variables: input,
});
