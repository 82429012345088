import { Stack, Typography } from "@mui/material";
import cssLayoutStyles from "../Global/Styles/layout";
import cssSpacingStyles from "../Global/Styles/spacing";
import useTheme from "@mui/material/styles/useTheme";
import LegalPageLayout from "../Components/AuthPageComponents/LegalPageLayout";
import cssFontStyles from "../Global/Styles/font";

const PrivacyPolicy: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
  };

  return (
    <LegalPageLayout pageName="Privacy Policy">
      <Stack direction="column" spacing={2} p="2rem 0">
        <Typography component="h4" variant="h1" css={styles.boldText}>
          Terms & Conditions
        </Typography>

        <Typography component="h5">
          Please read these Terms and Conditions carefully before using our services.
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          Acceptance of Terms
        </Typography>

        <Typography component="h5">
          By using our services, you agree to be bound by these Terms and Conditions. If
          you do not agree with these Terms, please refrain from using our services.
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          Service Description
        </Typography>

        <Typography component="h5">
          Our services may include but are not limited to: Product sales and delivery
          Information sharing User accounts and profiles Communication tools Support and
          assistance
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          User Registration
        </Typography>

        <Typography component="h5">
          To access certain features, you may be required to create an account. You agree
          to provide accurate and complete information when creating your account and to
          keep your login credentials secure.
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          Acceptance of Terms
        </Typography>

        <Typography component="h5">
          By using our services, you agree to be bound by these Terms and Conditions. If
          you do not agree with these Terms, please refrain from using our services.
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          Service Description
        </Typography>

        <Typography component="h5">
          Our services may include but are not limited to: Product sales and delivery
          Information sharing User accounts and profiles Communication tools Support and
          assistance
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          User Registration
        </Typography>

        <Typography component="h5">
          To access certain features, you may be required to create an account. You agree
          to provide accurate and complete information when creating your account and to
          keep your login credentials secure.
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          Acceptance of Terms
        </Typography>

        <Typography component="h5">
          By using our services, you agree to be bound by these Terms and Conditions. If
          you do not agree with these Terms, please refrain from using our services.
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          Service Description
        </Typography>

        <Typography component="h5">
          Our services may include but are not limited to: Product sales and delivery
          Information sharing User accounts and profiles Communication tools Support and
          assistance
        </Typography>

        <Typography component="h4" variant="h2" css={styles.boldText}>
          User Registration
        </Typography>

        <Typography component="h5">
          To access certain features, you may be required to create an account. You agree
          to provide accurate and complete information when creating your account and to
          keep your login credentials secure.
        </Typography>

        <Typography component="h5">
          By using our services, you acknowledge that you have read, understood, and
          agreed to these Terms and Conditions. If you have any questions or concerns,
          please contact us at [Your Contact Information].
        </Typography>
      </Stack>
    </LegalPageLayout>
  );
};

export default PrivacyPolicy;
