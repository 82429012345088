import { Edge, Node, XYPosition } from "reactflow";

// ----- General Stuff START -----
export const KANBAN_WORKFLOW_DEFAULT_NODE_HEIGHT = 130;
export const KANBAN_WORKFLOW_DEFAULT_NODE_WIDTH = 300;
const KANBAN_WORKFLOW_CORE_NODE_CONFIG = {
  style: {
    background: "#fff",
    color: "#000",
    fontSize: 12,
    borderRadius: 15,
    width: KANBAN_WORKFLOW_DEFAULT_NODE_WIDTH,
    cursor: "default",
    height: KANBAN_WORKFLOW_DEFAULT_NODE_HEIGHT,
    padding: 7,
  },
  dragHandle: ".custom-drag-handle",
};

export type KanbanWorkflowType = {
  id: string;
  name: string;
  createdOn: string;
  updatedOn: string;
  nodes: KanbanWorkflowAllNodes;
  edges: Edge<any>[];
};

export type KanbanWorkflowAllNodes = (
  | KanbanWorkflowsInitialNodeType
  | KanbanWorkflowsGeneralNodeType
)[];

export type KanFlowNewNodeModal = {
  position: XYPosition;
  id: string;
} | null;

export type NodeCondition = {
  comparison_operator: string | null;
  data_field: {
    type: string;
    name: string;
    id: string;
  };
  data_field_id: string;
  id: string;
  identifier: string | null;
  required_by: string | null;
  value: any;
};
// ----- General Stuff END -----

// ---------------- ALL NODE TYPES START ----------------=
export enum KAN_FLOW_NODE_TYPE {
  General = "General",
}
export type KanFlowAllNodesData = {
  [KAN_FLOW_NODE_TYPE.General]?: KanbanWorkflowsGeneralNodeData;
};
// ---------------- ALL NODE TYPES END ----------------=

// ----- Initial START -----
export type KanbanWorkflowInitialNodeData = {
  id: string;
  name: string;
  createdOn: string;
  updatedOn: string;
};
export type KanbanWorkflowsInitialNodeType = Node & {
  data: KanbanWorkflowInitialNodeData;
};
export const KANBAN_WORKFLOW_INITIAL_NODE_CONFIG = {
  ...KANBAN_WORKFLOW_CORE_NODE_CONFIG,
  style: {
    ...KANBAN_WORKFLOW_CORE_NODE_CONFIG.style,
    border: "3px solid #30AFB8",
  },
  type: "initial",
};
// ----- Initial END -----

// ----- General START -----
export type KanbanWorkflowsGeneralNodeType = Node & {
  data: KanbanWorkflowsGeneralNodeData;
};
export type KanbanWorkflowsGeneralNodeData = {
  id: string;
  type: string;
  name: string;
  template_id: string;
  template_name: string;
  template_attributes: string[];
  createdOn: string;
  updatedOn: string;
  preConditions?: NodeCondition[];
  postConditions?: NodeCondition[];
};
export const KANBAN_WORKFLOW_GENERAL_NODE_CONFIG = {
  ...KANBAN_WORKFLOW_CORE_NODE_CONFIG,
  type: KAN_FLOW_NODE_TYPE.General,
};
// ----- General END -----
