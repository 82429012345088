import { Avatar, Theme, useTheme } from "@mui/material";
import { css } from "@emotion/react";
import { useMemo } from "react";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import { MachineState } from "./conditionMonitoringUtils";

const cssStyles = (theme: Theme, isMainIcon: boolean) => ({
  avatarStyle: css({
    width: theme.spacing(6),
    height: theme.spacing(6),
    backgroundColor:
      theme.palette.mode === "light"
        ? isMainIcon
          ? theme.palette.grey[100]
          : theme.palette.common.white
        : theme.palette.grey[900],
  }),
});

interface ConditionMonitoringIconProps {
  state: MachineState;
  isMainIcon?: boolean;
}

const ConditionMonitoringIcon: React.FC<ConditionMonitoringIconProps> = ({
  state,
  isMainIcon = false,
}) => {
  const theme = useTheme();
  const styles = { ...cssStyles(theme, isMainIcon) };

  const icon = useMemo(() => {
    const stateMap = {
      normal: <ThumbUpIcon sx={{ color: theme.palette.success.main }} />,
      warning: <ThumbsUpDownIcon sx={{ color: theme.palette.warning.main }} />,
      critical: <ThumbDownIcon sx={{ color: theme.palette.error.main }} />,
    };
    return stateMap[state];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <Avatar variant="rounded" css={styles.avatarStyle}>
      {icon}
    </Avatar>
  );
};

export default ConditionMonitoringIcon;
