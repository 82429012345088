import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  MaintenanceFile,
  MaintenanceMachineTask,
} from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import {
  MaintenanceMachineTaskType,
  MaintenancePlanAddEditFormResult,
  MaintenancePlanTemplateApplyChangesOption,
  maintenanceMachineTaskTypeOptions,
  maintenancePlanTemplateApplyChangesOptions,
} from "../maintenancePlanUtils";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";
import FileDropzone from "../../../../SmallComponents/Dropzone/FileDropzone";
import Alert from "../../../../MaterialUI/Alert";
import Button from "../../../../MaterialUI/Button";
import { useState } from "react";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { FileWithPath } from "react-dropzone";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoIcon from "@mui/icons-material/Photo";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import Select from "../../../../MaterialUI/FormFields/Select";
import Collapse from "../../../../MaterialUI/Collapse";
import RadioGroup from "../../../../MaterialUI/FormFields/RadioGroup";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { useAuthedContext } from "../../../../../context/AuthContext";
import callApi from "../../../../../Api/callApi";
import { PostQueryUploadFileInput } from "../../../../../Api/Maintenance/apiMaintenanceInputs";
import {
  postQueryMaintenanceDeleteFile,
  postQueryMaintenanceUploadTaskFile,
  postQueryMaintenanceUploadTaskTemplateFile,
} from "../../../../../Api/Maintenance/apiMaintenancePostQueries";
import { PostQueryUploadFileSnippet } from "../../../../../Api/Maintenance/apiMaintenanceSnippets";
import {
  getQueryMaintenanceFileDisplay,
  getQueryMaintenanceFileDownload,
} from "../../../../../Api/Maintenance/apiMaintenanceGetQueries";

type DisplayedDocuments = {
  task: boolean;
  template: boolean;
};

interface MaintenancePlanDocumentsProps {
  task: MaintenanceMachineTask;
  handleChange: (value?: MaintenancePlanAddEditFormResult) => Promise<void>;
  isAddNew: boolean;
}

const MaintenancePlanDocuments: React.FC<MaintenancePlanDocumentsProps> = ({
  task,
  handleChange,
  isAddNew,
}) => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [alertStatus, setAlertStatus] = useState<FormStatuses | null>(null);
  const [file, setFile] = useState<FileWithPath | null>(null);

  const [displayedDocuments, setDisplayedDocuments] = useState<DisplayedDocuments>({
    task: true,
    template: true,
  });
  const [taskType, setTaskType] = useState<MaintenanceMachineTaskType | "">("");
  const [applyChangesTo, setApplyChangesTo] =
    useState<MaintenancePlanTemplateApplyChangesOption>("All Open Tasks");

  const { setAuthedUser } = useAuthedContext();

  console.log("task ", task);

  let filesData: MaintenanceFile[] = [];
  if (!task.task_template) {
    filesData = task.documents as MaintenanceFile[];
  } else {
    if (displayedDocuments.task && displayedDocuments.template) {
      filesData = [
        ...(task.documents as MaintenanceFile[]),
        ...(task.task_template.documents as MaintenanceFile[]),
      ];
    } else if (displayedDocuments.task) {
      filesData = task.documents as MaintenanceFile[];
    } else if (displayedDocuments.template) {
      filesData = task.task_template.documents as MaintenanceFile[];
    }
  }

  const handleFileUpload = async () => {
    try {
      if (!file) {
        setAlertMessage("You must upload a file");
        setAlertStatus("warning");
        return;
      }
      setAlertMessage("Uploading file...");
      setAlertStatus("loading");
      const input: PostQueryUploadFileInput = {
        file: file,
      };

      if (task.task_template_id && taskType === "repetitive") {
        await callApi<PostQueryUploadFileSnippet>({
          query: postQueryMaintenanceUploadTaskTemplateFile(task.task_template_id, input),
          auth: { setAuthedUser },
        });
      } else {
        await callApi<PostQueryUploadFileSnippet>({
          query: postQueryMaintenanceUploadTaskFile(task.id, input),
          auth: { setAuthedUser },
        });
      }

      await handleChange();
      setAlertMessage("Document uploaded successfully");
      setAlertStatus("success");
      setFile(null);
    } catch (err) {
      console.log("handleFileUpload err ", err);
      setAlertMessage("Something went wrong");
      setAlertStatus("error");
    }
  };
  const handleFileDelete = async (fileId: string) => {
    try {
      setAlertMessage("Deleting file...");
      setAlertStatus("loading");

      await callApi({
        query: postQueryMaintenanceDeleteFile(fileId),
        auth: { setAuthedUser },
      });
      await handleChange();

      setAlertMessage("File deleted successfully");
      setAlertStatus("success");
    } catch (error) {
      console.log("There was an error deleting the file ", error);
      setAlertMessage("There was an error deleting the file");
      setAlertStatus("error");
    }
  };

  const handleFileDownload = async (fileId: string, filename: string) => {
    try {
      setAlertMessage("Fetching file...");
      setAlertStatus("loading");

      const response: Response = await callApi({
        query: getQueryMaintenanceFileDownload(fileId),
        auth: { setAuthedUser },
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      setAlertMessage(null);
      setAlertStatus(null);
    } catch (e) {
      console.log("There was an error downloading the file ", e);
      setAlertMessage("There was an error downloading the file");
      setAlertStatus("error");
    }
  };

  const handleDisplayFile = async (fileId: string) => {
    try {
      setAlertMessage("Fetching file...");
      setAlertStatus("loading");

      const response: Response = await callApi({
        query: getQueryMaintenanceFileDisplay(fileId),
        auth: { setAuthedUser },
      });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      window.open(url);
      setAlertMessage(null);
      setAlertStatus(null);
    } catch (e) {
      console.log("There was an error displaying the file ", e);
      setAlertMessage("There was an error displaying the file");
      setAlertStatus("error");
    }
  };

  if (isAddNew) {
    return (
      <Stack spacing={3}>
        <Stack spacing={3} justifyContent="center" alignItems="center" width="100%">
          {!file ? (
            <FileDropzone
              setFiles={(files) => setFile(files[0])}
              setErrorMessage={setAlertMessage}
              accept={{ "application/pdf": [], "image/*": [] }}
            >
              <Button disabled={alertStatus === "success" || alertStatus === "loading"}>
                Select Document
              </Button>
            </FileDropzone>
          ) : (
            <Stack css={styles.width100} spacing={3}>
              {task.task_template_id ? (
                <>
                  <Typography variant="body1">
                    If you choose to upload file for a task template, the changes will be
                    propagated to all open tasks which are derived from this template.
                  </Typography>
                  <Select
                    label="Type"
                    value={taskType}
                    onChange={(e) =>
                      setTaskType(e.target.value as MaintenanceMachineTaskType)
                    }
                    selectOptions={maintenanceMachineTaskTypeOptions}
                  />

                  <Collapse in={taskType === "repetitive"}>
                    <RadioGroup
                      label="Apply Changes to"
                      value={applyChangesTo}
                      onChange={(e) =>
                        setApplyChangesTo(
                          e.target.value as MaintenancePlanTemplateApplyChangesOption
                        )
                      }
                      radioOptions={maintenancePlanTemplateApplyChangesOptions}
                    />
                  </Collapse>
                </>
              ) : null}

              <Stack
                css={styles.width100}
                spacing={3}
                direction="row"
                justifyContent="space-between"
              >
                <Button
                  css={[styles.width100, styles.widthLimit10]}
                  onClick={() => setFile(null)}
                  variant="outlined"
                  color="error"
                  disabled={alertStatus === "loading"}
                >
                  Cancel
                </Button>
                <Button
                  css={[styles.width100, styles.widthLimit15]}
                  onClick={handleFileUpload}
                  loading={alertStatus === "loading"}
                >
                  Upload Document
                </Button>
              </Stack>
            </Stack>
          )}

          <Box component="div">
            {file?.name ? (
              <LabelWithBoldedPart text={"Uploaded file"} bolded={file.name} />
            ) : (
              <Typography component="span" variant="body2" fontStyle="italic">
                * Supported file formats: JPG, JPEG, PNG, PDF
              </Typography>
            )}
          </Box>
        </Stack>
        {!!alertMessage ? (
          <Alert
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={alertStatus}
          />
        ) : null}
      </Stack>
    );
  }

  return (
    <Stack spacing={3}>
      {!!alertMessage ? (
        <Alert
          message={alertMessage || ""}
          showAlert={!!alertMessage}
          severity={alertStatus}
        />
      ) : null}

      <Divider flexItem />

      {task.task_template_id ? (
        <Stack css={styles.contentBreak} spacing={3} direction="row">
          <Checkbox
            label="Task Comments"
            checked={displayedDocuments.task}
            onChange={(e) =>
              setDisplayedDocuments((prev) => ({
                ...prev,
                task: e.target.checked,
              }))
            }
            disabled={alertStatus === "loading"}
          />
          <Checkbox
            label="Template Comments"
            checked={displayedDocuments.template}
            onChange={(e) =>
              setDisplayedDocuments((prev) => ({
                ...prev,
                template: e.target.checked,
              }))
            }
            disabled={alertStatus === "loading"}
          />
        </Stack>
      ) : null}

      {filesData?.length ? (
        <>
          <List>
            {filesData.map((fileData) => (
              <ListItem key={fileData.file_id}>
                <ListItemIcon>
                  {fileData.filename.endsWith(".pdf") ? (
                    <PictureAsPdfIcon fontSize="large" />
                  ) : (
                    <PhotoIcon fontSize="large" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <ListItemButton
                      css={{ padding: 0 }}
                      onClick={() => handleDisplayFile(fileData.file_id)}
                      disabled={alertStatus === "loading"}
                    >
                      <Typography
                        css={{
                          ":hover": { textDecoration: "underline", cursor: "pointer" },
                        }}
                      >
                        {fileData.filename}
                      </Typography>
                    </ListItemButton>
                  }
                  secondary={`${fileData.size} kB`}
                />
                <Box component="div">
                  <IconButton
                    onClick={() =>
                      handleFileDownload(fileData.file_id, fileData.filename)
                    }
                    disabled={alertStatus === "loading"}
                  >
                    <DownloadIcon css={{ color: theme.palette.info.main }} />
                  </IconButton>

                  <IconButton
                    onClick={() => handleFileDelete(fileData.file_id)}
                    disabled={alertStatus === "loading"}
                  >
                    <DeleteIcon css={{ color: theme.palette.error.main }} />
                  </IconButton>
                </Box>
              </ListItem>
            ))}
          </List>
        </>
      ) : (
        <Stack justifyContent="center" alignItems="center">
          <Typography>Task doesn't have any uploaded documents</Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default MaintenancePlanDocuments;
