const pieChartDefaultData = [
  {
    id: "make",
    label: "Make",
    value: 196,
    color: "hsl(199, 70%, 50%)",
  },
  {
    id: "plan",
    label: "Plan",
    value: 4700,
    color: "hsl(185, 70%, 50%)",
  },
  {
    id: "input",
    label: "Input",
    value: 24244,
    color: "hsl(302, 70%, 50%)",
  },
  {
    id: "output",
    label: "Output",
    value: 450,
    color: "hsl(276, 70%, 50%)",
  },
  {
    id: "total",
    label: "Total",
    value: 83,
    color: "hsl(250, 70%, 50%)",
  },
];

export default pieChartDefaultData;
