import { SerializedStyles } from "@emotion/react";
import { SankeyData } from "../../../../ExcellenceWidgets/AdvancedWidgets/Sankey/sankeyTypes";
import Sankey from "../../../../ExcellenceWidgets/AdvancedWidgets/Sankey/Sankey";
import sankeyDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/sankeyDefaultData";

interface ExcellenceSankeyProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: SankeyData | null;
  configuration: null;
  isStatic?: boolean;
}

const ExcellenceSankey: React.FC<ExcellenceSankeyProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <Sankey
      className={className}
      configuration={configuration}
      data={data || sankeyDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceSankey;
