import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { getCurrentEnv, getCookie } from "../Global/Utils/commonFunctions";
import {
  handleFetchUserAccessToken,
  handleUserSignOut,
} from "../context/authContextUtils";
import { COOKIE_REFRESH_TOKEN } from "../Global/Constants/commonConstants";

const currentEnv = getCurrentEnv();
let tokenRefreshPromise: Promise<any> = Promise.resolve();
// @ts-ignore
let isRefreshing: boolean = false;

const errorLink = onError(({ forward, graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    // if invalid access token
    if (networkError.name === "ServerError") {
      const refreshToken = getCookie(COOKIE_REFRESH_TOKEN);
      isRefreshing = true;
      tokenRefreshPromise = handleFetchUserAccessToken(refreshToken);
      tokenRefreshPromise
        .then(() => (isRefreshing = false))
        .catch(() => handleUserSignOut());
    }
    return fromPromise(tokenRefreshPromise).flatMap(() => forward(operation));
  }

  // Return undefined to indicate that the error is not handled
  return undefined;
});

const httpLink = new HttpLink({
  uri:
    currentEnv === "localHost"
      ? "http://localhost:3000/api/graphql/"
      : `https://${currentEnv}.evniko-giant.com/api/graphql/`,
});

interface GraphQlProviderProps {
  children: React.ReactNode;
}

const GraphQlProvider: React.FC<GraphQlProviderProps> = ({ children }) => {
  const client = new ApolloClient({
    link: from([errorLink, httpLink]),
    credentials: "include",
    cache: new InMemoryCache({
      addTypename: false,
    }),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default GraphQlProvider;
