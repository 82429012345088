import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Node } from "reactflow";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import { FormStatuses } from "../../../../../../Global/Types/commonTypes";
import { useDetectFormsUnsavedChanges } from "../../../../../../Global/Hooks/useDetectFormsUnsavedChanges";
import Button from "../../../../../../Components/MaterialUI/Button";
import Alert from "../../../../../../Components/MaterialUI/Alert";
import Modal from "../../../../../../Components/MaterialUI/Modal";
import { PID_FLOW_NODE_TYPE } from "../pidNodesUtils";
import { PidSvgSymbol, PidWorkflowsSvgNodeData } from "./pidSvgNodeUtils";
import callApi from "../../../../../../Api/callApi";
import { getQueryPidAllSvgs } from "../../../../../../Api/PidWorkflows/apiPidWorkflowsGetQueries";
import { useAuthedContext } from "../../../../../../context/AuthContext";
import { GetQueryPidAllSvgsSnippet } from "../../../../../../Api/PidWorkflows/apiPidWorkflowsSnippets";
import Accordion from "../../../../../../Components/MaterialUI/Accordion";
import PidSvgSymbolComponent from "./PidSvgSymbolComponent";
import TextField from "../../../../../../Components/MaterialUI/FormFields/TextFields";

type ModalType = "Add New Template" | "Condition Details" | null;

interface PidNodeSvgFormProps {
  handleCreateSubmit?: (data: PidWorkflowsSvgNodeData) => void;
  handleEditSubmit?: (data: PidWorkflowsSvgNodeData) => void;
  data?: PidWorkflowsSvgNodeData;
  nodes: Node<PidWorkflowsSvgNodeData>[];
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
  unsavedChanges: boolean;
}

const PidNodeSvgForm: React.FC<PidNodeSvgFormProps> = ({
  handleCreateSubmit,
  handleEditSubmit,
  data,
  handleSetUnsavedChanges,
  setUnsavedChanges,
  unsavedChanges,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [pidSymbol, setPidSymbol] = useState<PidSvgSymbol | null>(
    data?.pidSymbol || null
  );
  const [customLabel, setCustomLabel] = useState<string>(data?.customLabel || "");
  const [formStatus, setFormStatus] = useState<FormStatuses>("loading");
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [categories, setCategories] = useState<PidSvgSymbol[][]>([]);

  const [openModal, setOpenModal] = useState<ModalType>(null);

  const nodeType = PID_FLOW_NODE_TYPE.SVG;

  const initialFormState = useRef({
    pidSymbol,
    customLabel,
  }).current;

  useDetectFormsUnsavedChanges(
    initialFormState,
    { pidSymbol, customLabel },
    handleSetUnsavedChanges
  );
  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    (async () => {
      try {
        setFormStatus("loading");
        setAlertMessage("Fetching data...");
        const res = await callApi<GetQueryPidAllSvgsSnippet>({
          query: getQueryPidAllSvgs(),
          auth: { setAuthedUser },
        });

        setCategories(Object.values(res).map((val) => [...val]));
      } catch (err) {
        console.log("PidFlow err ", err);
        setAlertMessage("Something went wrong");
      }
      setFormStatus(null);
      setAlertMessage(null);
    })();
    // eslint-disable-next-line
  }, []);

  console.log(categories);

  const handleFormSubmit = () => {
    if (!pidSymbol) {
      setAlertMessage("Chart selection is required");
      return;
    }
    const current = new Date().toISOString();
    const result: PidWorkflowsSvgNodeData = {
      id: uuidv4().split("-")[0],
      type: nodeType,
      pidSymbol: pidSymbol,
      label: "",
      createdOn: current,
      updatedOn: current,
      mode: "Edit Mode",
      customLabel: customLabel,
    };

    if (handleCreateSubmit) {
      handleCreateSubmit(result);
    }
    if (handleEditSubmit) {
      handleEditSubmit(result);
    }
    setUnsavedChanges(false);
  };

  const handleSelectSymbol = (symbol: PidSvgSymbol) => {
    setPidSymbol(symbol);
    setCustomLabel(symbol.label);
  };

  return (
    <Stack css={[styles.textBreak, styles.width100]} spacing={4}>
      <Stack spacing={3}>
        {categories.map((cat) => (
          <Accordion key={cat?.[0]?.label || ""} label={cat?.[0]?.category || ""}>
            <Grid container spacing={2}>
              {cat.map((symbol) => (
                <Grid item xs={6} sm={4} key={symbol.id}>
                  <Box
                    sx={{
                      background: theme.palette.common.white,
                      cursor: "pointer",
                      border:
                        pidSymbol?.id === symbol.id
                          ? `3px solid ${theme.palette.primary.main}`
                          : "3px solid transparent",
                      borderRadius: "4px",
                      overflow: "hidden",
                    }}
                    component="div"
                    onClick={() => handleSelectSymbol(symbol)}
                  >
                    <PidSvgSymbolComponent
                      width={100}
                      height={100}
                      symbolText={symbol.svg}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    color={pidSymbol?.id === symbol.id ? "primary" : "textPrimary"}
                  >
                    {symbol.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Accordion>
        ))}
      </Stack>

      <TextField
        name="label"
        label="Custom Label"
        onChange={(e) => setCustomLabel(e.target.value)}
        value={customLabel}
        disabled={formStatus === "loading"}
      />
      <Box component="div" css={styles.flexCenter}>
        <Button
          css={[styles.width100, styles.widthLimit20]}
          onClick={handleFormSubmit}
          disabled={formStatus === "loading"}
        >
          Save Changes
        </Button>
      </Box>

      <Alert
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={formStatus || "warning"}
      />

      <Modal
        onClose={() => setOpenModal(null)}
        open={!!openModal}
        fullWidth
        label={openModal || ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      ></Modal>
    </Stack>
  );
};

export default PidNodeSvgForm;
