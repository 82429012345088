import React, { memo, useState } from "react";
import useTheme from "@mui/material/styles/useTheme";
import { Box, Divider, Typography } from "@mui/material";
import cssLayoutStyles from "../../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../../Global/Styles/spacing";
import { BOSCH_TASK_NODE_TYPE, BoschShippingNodeData } from "../../boschNodesUtils";
import BoschNode from "../BoschNode";
import Modal from "../../../../../MaterialUI/Modal";
import { useReactFlow } from "reactflow";
import Button from "../../../../../MaterialUI/Button";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BoschGeneralForm from "../../Forms/BoschGeneralForm";
import BoschNodeDataModal from "./BoschNodeDataModal";

type ModalType = "Edit shipping node" | "Node Data" | null;

interface FlowCustomNodeProps {
  id: string;
  data: BoschShippingNodeData;
}

const BoschShippingNode: React.FC<FlowCustomNodeProps> = ({ id, data }) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssLayoutStyles,
  };
  const [modalType, setModalType] = useState<ModalType>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { setNodes } = useReactFlow();

  const handleEditSubmit = (nodeData: BoschShippingNodeData) => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === id) {
          return {
            ...node,
            data: nodeData,
          };
        }
        return node;
      })
    );

    setModalType(null);
  };

  const handleOpenEditModal = () => {
    setModalType("Edit shipping node");
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <>
      <BoschNode
        id={id}
        handleOpenEditModal={handleOpenEditModal}
        icon={<LocalShippingIcon fontSize="small" />}
        nodeType={BOSCH_TASK_NODE_TYPE.Shipping}
      >
        <Typography css={styles.textBreak} variant="h3" align="center">
          {data.name}
        </Typography>
        <Divider css={styles.textBreak} color="lightgrey" />
        <Box component="div" css={styles.flexCenter}>
          <Button variant="outlined" onClick={() => setModalType("Node Data")}>
            View Data
          </Button>
        </Box>
      </BoschNode>

      <Modal
        onClose={() => setModalType(null)}
        open={!!modalType}
        fullWidth
        label={modalType || ""}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      >
        {modalType === "Edit shipping node" ? (
          <BoschGeneralForm
            handleEditSubmit={handleEditSubmit}
            data={data}
            nodeType={BOSCH_TASK_NODE_TYPE.Shipping}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        ) : null}

        {modalType === "Node Data" ? <BoschNodeDataModal data={data} /> : null}
      </Modal>
    </>
  );
};

export default memo(BoschShippingNode);
