import { useEffect, useState } from "react";
import { MQTTSubscription } from "../../../../../Api/DataSources/apiDSDataTypes";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import useTheme from "@mui/material/styles/useTheme";
import Alert from "../../../../MaterialUI/Alert";
import JSONTreeView from "../../../../SmallComponents/JSONTreeView/JSONTreeView";
import { handleConnectionOldNewString } from "../opcConnectionUtils";
import { Grid, Stack, Divider, Box, Typography } from "@mui/material";
import LabelWithBoldedPart from "../../../../MaterialUI/LabelWithBoldedPart";

type OldNew = {
  old: string | number;
  new: string | number;
};
type PreviousVersionDiff = {
  mapping?: {
    added: Record<string, any>[];
    removed: Record<string, any>[];
  };
  topics?: {
    added: string[];
    removed: string[];
  };
  name?: OldNew;
  brokerHost?: OldNew;
  brokerPort?: OldNew;
};

interface OpcMqttHistoryModalContentProps {
  sub: MQTTSubscription;
  isShowDifference: boolean;
  previousSub: MQTTSubscription | null;
}

const OpcMqttHistoryModalContent: React.FC<OpcMqttHistoryModalContentProps> = ({
  sub,
  isShowDifference,
  previousSub,
}) => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [previousVersionDiff, setPreviousVersionDiff] =
    useState<PreviousVersionDiff | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setFetchStatus("loading");
        setAlertMessage("Loading...");
        if (isShowDifference) {
          handleDiffFromPreviousVersion();
        }
        setFetchStatus("success");
        setAlertMessage(null);
      } catch (err) {
        console.log("OpcMQTTHistoryModalContent, useEffect on mount err ", err);
        setFetchStatus("error");
        setAlertMessage("Something went wrong");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDiffFromPreviousVersion = () => {
    if (previousSub) {
      const addedTopics = sub.topics.filter(
        (newItem) => !previousSub.topics.some((oldItem) => newItem === oldItem)
      );
      const removedTopics = previousSub.topics.filter(
        (oldItem) => !sub.topics.some((newItem) => oldItem === newItem)
      );

      const nameDiff = handleConnectionOldNewString(
        sub.client_name,
        previousSub.client_name
      );
      const brokerHostDiff = handleConnectionOldNewString(
        sub.broker_host,
        previousSub.broker_host
      );
      const brokerPortDiff = handleConnectionOldNewString(
        sub.broker_port,
        previousSub.broker_port
      );

      setPreviousVersionDiff({
        topics: {
          added: addedTopics,
          removed: removedTopics,
        },
        name: nameDiff,
        brokerHost: brokerHostDiff,
        brokerPort: brokerPortDiff,
      });
    }
  };

  if (fetchStatus !== "success") {
    return (
      <Alert
        css={styles.widthLimit25}
        message={alertMessage}
        showAlert={!!alertMessage}
        severity={fetchStatus}
      />
    );
  }

  if (!isShowDifference) {
    return <JSONTreeView css={styles.sectionBreak} data={sub.mapping} hideRoot />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Stack
          css={styles.height100}
          spacing={2}
          justifyContent="space-between"
          direction="row"
        >
          <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="new" />
          <Divider flexItem orientation="vertical" />
        </Stack>
      </Grid>
      <Grid item xs={12} sm={6}>
        <DifferenceList previousVersionDiff={previousVersionDiff} keyValue="old" />
      </Grid>
    </Grid>
  );
};

export default OpcMqttHistoryModalContent;

const DifferenceList: React.FC<{
  previousVersionDiff: PreviousVersionDiff | null;
  keyValue: keyof OldNew;
}> = ({ previousVersionDiff, keyValue }) => {
  const topicsValue =
    keyValue === "new"
      ? previousVersionDiff?.topics?.added.join(", ")
      : previousVersionDiff?.topics?.removed.join(", ");

  const topicsText = keyValue === "new" ? "New topics added" : "Old topics removed";

  return (
    <Box component="div" sx={{ width: "100%" }}>
      <Typography sx={{ marginBottom: "1rem" }} variant="h4">
        {keyValue === "new" ? "Current version" : "Previous version"}
      </Typography>
      <Stack spacing={2}>
        <LabelWithBoldedPart
          text={"Name"}
          bolded={
            previousVersionDiff?.name ? previousVersionDiff.name[keyValue] : "No change"
          }
        />
        <LabelWithBoldedPart
          text={"Broker host"}
          bolded={
            previousVersionDiff?.brokerHost
              ? previousVersionDiff.brokerHost[keyValue]
              : "No change"
          }
        />
        <LabelWithBoldedPart
          text={"Broker port"}
          bolded={
            previousVersionDiff?.brokerPort
              ? previousVersionDiff.brokerPort[keyValue]
              : "No change"
          }
        />
        <LabelWithBoldedPart text={topicsText} bolded={topicsValue || "No change"} />
      </Stack>
    </Box>
  );
};
