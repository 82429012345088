import { Stack, useTheme } from "@mui/material";
import { Node, Edge, useReactFlow } from "reactflow";
import { useState } from "react";
import {
  KAN_FLOW_NODE_TYPE,
  KANBAN_WORKFLOW_GENERAL_NODE_CONFIG,
  KanFlowAllNodesData,
  KanFlowNewNodeModal,
} from "../../../kanbanWorkflowUtils";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import Modal from "../../../../../Components/MaterialUI/Modal";
import Select from "../../../../../Components/MaterialUI/FormFields/Select";
import NodeGeneralForm from "./Nodes/General/NodeGeneralForm";

interface KanFlowCreateNewNodeModalProps {
  newNodeModal: KanFlowNewNodeModal;
  setNewNodeModal: React.Dispatch<React.SetStateAction<KanFlowNewNodeModal>>;
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>;
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>;
  connectingNodeId: React.MutableRefObject<any>;
}

const KanFlowCreateNewNodeModal: React.FC<KanFlowCreateNewNodeModalProps> = ({
  newNodeModal,
  setNewNodeModal,
  setNodes,
  setEdges,
  connectingNodeId,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };
  const [selectedType, setSelectedType] = useState<KAN_FLOW_NODE_TYPE | "">(
    KAN_FLOW_NODE_TYPE.General
  );
  const [typeConfig, setTypeConfig] = useState<any>(KANBAN_WORKFLOW_GENERAL_NODE_CONFIG);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
  const { getNodes } = useReactFlow();

  const handleOnTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as KAN_FLOW_NODE_TYPE;
    switch (val) {
      case KAN_FLOW_NODE_TYPE.General: {
        setTypeConfig(KANBAN_WORKFLOW_GENERAL_NODE_CONFIG);
        break;
      }
    }
    setSelectedType(val);
  };

  const handleCreateSubmit = (nodeData: KanFlowAllNodesData) => {
    if (newNodeModal && typeConfig) {
      const newNode = {
        id: newNodeModal.id,
        position: newNodeModal.position,
        data: getNodeData(nodeData),
        ...typeConfig,
      };

      setNodes((nds) => nds.concat(newNode));
      setEdges((eds) =>
        eds.concat({
          id: newNodeModal.id,
          source: connectingNodeId.current,
          target: newNodeModal.id,
          type: "step",
        })
      );
      setNewNodeModal(null);
    }
  };

  const handleSetUnsavedChanges = (unsavedChanges: boolean) => {
    if (unsavedChanges) {
      setUnsavedChanges(true);
    }
  };

  return (
    <Modal
      onClose={() => setNewNodeModal(null)}
      open={!!newNodeModal}
      fullWidth
      label="Creating New Node"
      unsavedChanges={unsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    >
      <Stack
        css={styles.width100}
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <Select
          style={{ display: "none" }}
          selectOptions={[]}
          fullWidth
          value={selectedType}
          onChange={handleOnTypeChange}
        />

        {selectedType === KAN_FLOW_NODE_TYPE.General ? (
          <NodeGeneralForm
            handleCreateSubmit={(data) =>
              handleCreateSubmit({
                General: data,
              })
            }
            nodes={getNodes()}
            handleSetUnsavedChanges={handleSetUnsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            unsavedChanges={unsavedChanges}
          />
        ) : null}
      </Stack>
    </Modal>
  );
};

export default KanFlowCreateNewNodeModal;

const getNodeData = (nodeData: KanFlowAllNodesData) => {
  const foundData = Object.values(nodeData).find((val) => !!val);
  return foundData;
};
