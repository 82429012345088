import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../Global/Styles/layout";
import cssComponentsStyles from "../../../../Global/Styles/components";
import { Box, Typography } from "@mui/material";
import ValueDisplayCard from "./ValueDisplayCard";
import { useEffect, useState } from "react";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import { ResponsiveBullet } from "@nivo/bullet";
import { getChartColors } from "../../../ExcellenceWidgets/nivoTheme";

interface ParametersInfoProps {
  measurementUnit: string;
  parameterValue: number | null;
  isParameterInRange: boolean | null;
  setIsParameterInRange: React.Dispatch<React.SetStateAction<boolean | null>>;
}

const ParametersInfo: React.FC<ParametersInfoProps> = ({
  measurementUnit,
  parameterValue,
  isParameterInRange,
  setIsParameterInRange,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [lowestValue] = useState<number>(6.4);
  const [highestValue] = useState<number>(9.2);
  const showRanges = !!parameterValue && measurementUnit;
  const outOfRangeColor = theme.palette.error.main;
  const measureAndMarkerColors =
    theme.palette.mode === "light" ? "greys" : theme.palette.grey[300];

  useEffect(() => {
    if (parameterValue === null) {
      setIsParameterInRange(null);
    } else {
      const numericValue = Number(parameterValue);
      setIsParameterInRange(numericValue >= lowestValue && numericValue <= highestValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameterValue, lowestValue, highestValue]);

  const bulletChartData = [
    {
      id: "range",
      ranges: [16],
      measures: [parameterValue || 0],
      markers: [showRanges ? lowestValue : 0, showRanges ? highestValue : 0],
    },
  ];

  const bulletChartColors = getChartColors();

  return (
    <>
      <Typography ml={1} variant="h4" style={theme.customizedTextStyles.labelStyles}>
        Specification Range
      </Typography>

      <Box component="div" css={styles.flex} height="150px">
        <ResponsiveBullet
          data={bulletChartData}
          margin={{ top: 60, right: 20, bottom: 60, left: 10 }}
          titleOffsetX={-70}
          measureSize={0.2}
          markerSize={1}
          rangeColors={
            bulletChartColors[0] === "#b12739"
              ? bulletChartColors[2]
              : bulletChartColors[0]
          }
          measureColors={
            !measurementUnit
              ? measureAndMarkerColors
              : isParameterInRange
              ? measureAndMarkerColors
              : outOfRangeColor
          }
          markerColors={measureAndMarkerColors}
          theme={{
            text: {
              fill: theme.palette.common.black,
            },
            tooltip: {
              container: {
                background: theme.palette.common.white,
              },
            },
          }}
        />
      </Box>

      <Box component="div" css={styles.flex}>
        <ValueDisplayCard
          label="Parameter Value"
          unit={measurementUnit}
          parameterValue={parameterValue}
          isParameterInRange={isParameterInRange}
        />
      </Box>
    </>
  );
};

export default ParametersInfo;
