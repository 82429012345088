import { Formik } from "formik";
import { Stack } from "@mui/material";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../../Global/Constants/yupConstants";
import { useState } from "react";
import { FormStatuses } from "../../../../Global/Types/commonTypes";
import { SerializedStyles } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import ROUTES_MAPPING from "../../../../Layout/Router/routesMapping";
import callApi from "../../../../Api/callApi";
import { useAuthedContext } from "../../../../context/AuthContext";
import { v4 as uuidv4 } from "uuid";
import TextField from "../../../../Components/MaterialUI/FormFields/TextFields";
import Button from "../../../../Components/MaterialUI/Button";
import Alert from "../../../../Components/MaterialUI/Alert";
import {
  KANBAN_WORKFLOW_INITIAL_NODE_CONFIG,
  KanbanWorkflowsInitialNodeType,
} from "../../kanbanWorkflowUtils";
import {
  postQueryKanbanWorkflowCreate,
  PostQueryKanbanWorkflowCreateInput,
  PostQueryKanbanWorkflowCreateSnippet,
  putQueryKanbanWorkflow,
} from "../../api/mutations";
import { useDetectFormsUnsavedChanges } from "../../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
};

interface CreateKanbanWorkflowFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateKanbanWorkflowForm: React.FC<CreateKanbanWorkflowFormProps> = ({
  className,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const navigate = useNavigate();

  const initialValues: FormValues = {
    name: "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const initialNodeId = uuidv4().split("-")[0];

      const initialNode: KanbanWorkflowsInitialNodeType = {
        id: initialNodeId,
        data: {
          id: initialNodeId,
          name: values.name,
          createdOn: new Date().toISOString(),
          updatedOn: new Date().toISOString(),
        },
        position: { x: 0, y: 1 },
        ...KANBAN_WORKFLOW_INITIAL_NODE_CONFIG,
      };

      const INITIAL_FLOW = {
        id: uuidv4().split("-")[0],
        name: values.name,
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
        nodes: [initialNode],
        edges: [],
      };

      const input: PostQueryKanbanWorkflowCreateInput = {
        data: INITIAL_FLOW,
      };

      const res: PostQueryKanbanWorkflowCreateSnippet = await callApi({
        query: postQueryKanbanWorkflowCreate(input),
        auth: { setAuthedUser },
      });

      // Update DB entry
      await callApi({
        query: putQueryKanbanWorkflow({ data: { ...INITIAL_FLOW, id: res.id } }, res.id),
        auth: { setAuthedUser },
      });

      setFormStatus("success");
      setUnsavedChanges(false);
      navigate({
        pathname: `${ROUTES_MAPPING["Workflows-Workflow Modeler"]}/${res.id}`,
      });
    } catch (err) {
      console.log("CreateKanbanWorkflowForm err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              className={className}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                name="name"
                label="Workflow Name"
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />

              <Button type="submit" loading={formStatus === "loading"}>
                Create New Workflow
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateKanbanWorkflowForm;
