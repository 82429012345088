import { Box } from "@mui/material";
import {
  PostQueryUAConnectSnippet,
  PostQueryUANodeSnippet,
} from "../../../../../Api/DataSources/apiDSSnippets";
import { memo, useEffect, useState } from "react";
import { UAConnectionTreeNode } from "../../../../../Api/DataSources/apiDSDataTypes";
import CollapsibleSelectTree from "../../../../SmallComponents/CollapsibleSelectTree/CollapsibleSelectTree";
import { CollapsibleTreeItemData } from "../../../../SmallComponents/CollapsibleSelectTree/collapsibleTreeTypes";
import callApi from "../../../../../Api/callApi";
import { postQueryUANode } from "../../../../../Api/DataSources/apiDSPostQueries";
import { useAuthedContext } from "../../../../../context/AuthContext";

const transformUAData = (node: UAConnectionTreeNode): CollapsibleTreeItemData => {
  const { id, name, children, ...rest } = node;
  const transformedNode: CollapsibleTreeItemData = {
    id,
    uniqueID: id,
    label: name,
    ...(children && {
      children: children.map((child) => (child ? transformUAData(child) : null)),
    }),
    ...rest,
  };

  return transformedNode;
};

interface UaNodesProps {
  nodeList: PostQueryUAConnectSnippet;
  selectedNodes: string[];
  setSelectedNodes: React.Dispatch<React.SetStateAction<string[]>>;
  disabled?: boolean;
  setLastSelectedNode: React.Dispatch<React.SetStateAction<string | null>>;
  serverURL: string;
  loading?: boolean;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
}

const UaNodes: React.FC<UaNodesProps> = ({
  nodeList,
  setSelectedNodes,
  selectedNodes,
  setLastSelectedNode,
  disabled,
  serverURL,
}) => {
  const [treeData, setTreeData] = useState<(CollapsibleTreeItemData | null)[]>(
    () =>
      transformUAData(nodeList.tree_structure)
        .children as (CollapsibleTreeItemData | null)[]
  );

  console.log("nodeList ", nodeList);

  const { setAuthedUser } = useAuthedContext();

  useEffect(() => {
    if (selectedNodes.length) {
      setLastSelectedNode((prev) => {
        const last = selectedNodes[selectedNodes.length - 1];
        if (prev !== last) {
          return last;
        }
        return prev;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNodes]);

  // useEffect(() => {
  //   (async () => {
  //     setLoading(true);
  //     await recursiveFetch(treeData);
  //     setLoading(false);
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const recursiveFetch = async (tree: (CollapsibleTreeItemData | null)[]) => {
    const notFetched = getNodesWithNullChildren(tree);

    for (const nodeID of notFetched) {
      const newTree = await fetchNodeData(nodeID, tree);
      if (newTree) {
        await recursiveFetch(newTree);
      }
    }
  };

  function getNodesWithNullChildren(tree: (CollapsibleTreeItemData | null)[]): string[] {
    const result: string[] = [];

    function processNode(node: CollapsibleTreeItemData | null) {
      if (node && node.children?.some((child) => child === null)) {
        result.push(node.id);
      }

      if (node?.children) {
        node.children.forEach((child) => {
          processNode(child);
        });
      }
    }

    tree.forEach((node) => {
      processNode(node);
    });

    return result;
  }

  const fetchNodeData = async (
    nodeID: string,
    tree: (CollapsibleTreeItemData | null)[]
  ) => {
    try {
      const input = {
        url: serverURL,
        nodes: [nodeID],
        depth: 100,
      };
      const nodeData = await callApi<PostQueryUANodeSnippet>({
        query: postQueryUANode(input),
        auth: { setAuthedUser },
      });

      const formattedData = transformConnectionTreeToCollapsibleTree(
        nodeData[0].children
      );

      const updatedTree = updateTreeItem(tree, formattedData, nodeID);
      setTreeData(updatedTree);
      return updatedTree;
    } catch (error) {
      console.error("fetchNodeData err", error);
    }
  };

  return (
    <Box component="div">
      <CollapsibleSelectTree
        selected={selectedNodes}
        setSelected={setSelectedNodes}
        data={treeData}
        disabled={disabled}
      />
    </Box>
  );
};

export default memo(UaNodes);

const updateTreeItem = (
  existingData: (CollapsibleTreeItemData | null)[],
  newData: (CollapsibleTreeItemData | null)[],
  nodeID: string
): (CollapsibleTreeItemData | null)[] => {
  return existingData.map((item) => {
    if (item && item.id === nodeID) {
      // If the IDs match, update the children of the found element with newData
      return { ...item, children: newData };
    } else if (item && item.children) {
      // If the item has children, recursively update them
      return { ...item, children: updateTreeItem(item.children, newData, nodeID) };
    }
    return item;
  });
};

function transformConnectionTreeToCollapsibleTree(
  connectionTree: (UAConnectionTreeNode | null)[]
): (CollapsibleTreeItemData | null)[] {
  return connectionTree.map((node) => {
    if (node) {
      const { id, name, children } = node;
      const transformedNode: CollapsibleTreeItemData = {
        id,
        uniqueID: id,
        label: name,
        children: transformConnectionTreeToCollapsibleTree(children),
      };
      return transformedNode;
    }
    return null;
  });
}
