import { MRT_ColumnDef } from "material-react-table";
import { formatDateAndTime } from "../../../../Global/Utils/commonFunctions";
import { TableGridColumnDataTypes, TableGridProps } from "../tableGridUtils";

export interface MobileTableGridProps<T extends Record<string, any>>
  extends Omit<TableGridProps<T>, "rows"> {
  mobileRows: MobileTableGridFormattedRow[];
  editedRowIndex: number | null;
  loading?: boolean;
}

export type MobileTableGridFormattedRow = {
  [key: string]: {
    value: any;
    formattedValue: any;
    type: TableGridColumnDataTypes;
    rowIndex: number;
  };
};

export const mobileTableGridHandleFormatRows = <T extends Record<string, any>>(
  rows: T[],
  columns: MRT_ColumnDef<T>[]
): MobileTableGridFormattedRow[] => {
  const result = rows.map((row, index) => {
    const newRow = {} as { [key: string]: any };
    for (const key in row) {
      const foundColumn = tableGridFindInnerMostColumn(columns, key);
      const columnType: TableGridColumnDataTypes =
        // @ts-ignore
        foundColumn?.meta?.type || "string";
      const value = row[key];

      switch (columnType) {
        case "date": {
          newRow[key] = {
            key: key,
            value: value,
            formattedValue: value ? formatDateAndTime(value, "date") : "",
            type: columnType as TableGridColumnDataTypes,
            rowIndex: index,
          };
          break;
        }
        case "time": {
          newRow[key] = {
            key: key,
            value: value,
            formattedValue: value ? formatDateAndTime(value, "time") : "",
            type: columnType as TableGridColumnDataTypes,
            rowIndex: index,
          };
          break;
        }
        default: {
          newRow[key] = {
            key: key,
            value: value,
            formattedValue: value,
            type: columnType as TableGridColumnDataTypes,
            rowIndex: index,
          };
        }
      }
    }
    return newRow;
  });
  return result;
};

/**
 * Recursively searches through the nested structure of MRT_ColumnDef
 * arrays to find the inner-most column with the specified accessorKey
 */
function tableGridFindInnerMostColumn<T extends Record<string, any>>(
  columns: MRT_ColumnDef<T>[],
  accessorKey: string
): MRT_ColumnDef<T> | undefined {
  for (const col of columns) {
    if (col.columns?.length) {
      const innerCol = tableGridFindInnerMostColumn(col.columns, accessorKey);
      if (innerCol) {
        return innerCol;
      }
    } else if (col.accessorKey === accessorKey) {
      return col;
    }
  }
  return undefined;
}
