import { useEffect, useState } from "react";
import { TableGridColumnSchema } from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { Stack, IconButton, Box, Typography } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from "../../Components/MaterialUI/Modal";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import { SupplySemiProduct, SupplySemiProductRow } from "./productsUtils";

type ColActionType = {
  type: "View Semi Product";
  semiProduct: SupplySemiProduct;
} | null;

const colSchema: TableGridColumnSchema[] = [
  { id: "name", label: "Name", type: "string" },
  { id: "id", label: "ID", type: "string" },
  { id: "actions", label: "Actions", type: "button" },
];

interface SemiProductsTableProps {
  tableData: SupplySemiProduct[];
  loading: boolean;
}

const SemiProductsTable: React.FC<SemiProductsTableProps> = ({ tableData, loading }) => {
  const [productRows, setProductRows] = useState<SupplySemiProductRow[]>([]);
  const [openModal, setOpenModal] = useState<ColActionType>(null);

  useEffect(() => {
    (async () => {
      try {
        setProductRows(
          tableData.map((item) => {
            return {
              id: item.id,
              name: item.name,
              actions: (
                <Stack direction="row" alignItems="center" spacing={1}>
                  <IconButton
                    onClick={() =>
                      onActionClick({
                        semiProduct: item,
                        type: "View Semi Product",
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Stack>
              ),
            };
          })
        );
      } catch (error) {
        console.log("There was an error: ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const onActionClick = (data: ColActionType) => {
    setOpenModal(data);
  };

  return (
    <>
      <ResponsiveTableGrid
        rows={productRows}
        colSchema={colSchema}
        loading={loading}
        responsive="responsive"
        configuration={{
          density: "compact",
          initialRowsPerPage: 15,
          //   columnVisibility: { id: false },
        }}
      />

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        label={openModal?.type || ""}
      >
        {openModal?.type === "View Semi Product" ? (
          <ViewSemiProduct semiProduct={openModal.semiProduct} />
        ) : null}
      </Modal>
    </>
  );
};

export default SemiProductsTable;

interface ViewSemiProductProps {
  semiProduct: SupplySemiProduct;
}

const ViewSemiProduct: React.FC<ViewSemiProductProps> = ({ semiProduct }) => {
  return (
    <Box component="div">
      <Stack spacing={1} mb={2}>
        <LabelWithBoldedPart text="ID" bolded={semiProduct.id} />
        <LabelWithBoldedPart text="Name" bolded={semiProduct.name} />
        <Stack spacing={1} mb={2}>
          <Typography>BOM Details</Typography>
          {semiProduct.bom_details.map((item) => (
            <Stack spacing={1}>
              <LabelWithBoldedPart text="Material ID" bolded={item.material_id || ""} />
              <LabelWithBoldedPart text="Quantity" bolded={item.quantity || ""} />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
