import { useState } from "react";
import { object } from "yup";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { Formik } from "formik";
import { Stack } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Button from "../../MaterialUI/Button";
import Alert from "../../MaterialUI/Alert";
import {
  YUP_NUMBER_REQUIRED_BETWEEN_0_100,
  YUP_REQUIRED_STRING,
  YUP_STRING,
} from "../../../Global/Constants/yupConstants";
import { PostQueryCreateMachineInput } from "../../../Api/Maintenance/apiMaintenanceInputs";
import callApi from "../../../Api/callApi";
import {
  postQueryCreateMachine,
  postQueryUpdateMachine,
} from "../../../Api/Maintenance/apiMaintenancePostQueries";
import {
  GetQueryMaintenanceMachineSnippet,
  GetQueryMaintenanceMachinesSnippet,
  PostQueryCreateMachineSnippet,
  PostQueryUpdateMachineSnippet,
} from "../../../Api/Maintenance/apiMaintenanceSnippets";
import { v4 as uuidv4 } from "uuid";
import { useAuthedContext } from "../../../context/AuthContext";
import cssLayoutStyles from "../../../Global/Styles/layout";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  machineType: YUP_REQUIRED_STRING,
  machineName: YUP_REQUIRED_STRING,
  machineConfiguration: YUP_STRING,
  machineCostPerHour: YUP_NUMBER_REQUIRED_BETWEEN_0_100,
});

type MachineFormValues = {
  machineType: string;
  machineName: string;
  machineConfiguration: string;
  machineCostPerHour: number;
};

interface MaintenanceParkFormProps {
  maintenanceMachineData: GetQueryMaintenanceMachineSnippet;
  setMachineData: React.Dispatch<
    React.SetStateAction<GetQueryMaintenanceMachinesSnippet | undefined>
  >;
  setOpenInfo: React.Dispatch<React.SetStateAction<boolean>>;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const MaintenanceParkForm: React.FC<MaintenanceParkFormProps> = ({
  maintenanceMachineData,
  setMachineData,
  setOpenInfo,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const styles = { ...cssLayoutStyles };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const initialValues: MachineFormValues = {
    machineType: maintenanceMachineData.machine_type
      ? maintenanceMachineData.machine_type
      : "",
    machineName: maintenanceMachineData.machine ? maintenanceMachineData.machine : "",
    machineConfiguration: maintenanceMachineData.configuration
      ? maintenanceMachineData.configuration
      : "",
    machineCostPerHour: maintenanceMachineData.cost_per_hour
      ? maintenanceMachineData.cost_per_hour
      : 0,
  };

  const handleFormSubmit = async (values: MachineFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage("Loading...");

      const body: PostQueryCreateMachineInput = {
        machine_id: maintenanceMachineData.machine_id
          ? maintenanceMachineData.machine_id
          : uuidv4().split("-")[0],
        machine_type: values.machineType,
        machine: values.machineName,
        configuration: values.machineConfiguration,
        machine_status: maintenanceMachineData.machine_status
          ? maintenanceMachineData.machine_status
          : "Running",
        last_maintenance: maintenanceMachineData.last_maintenance
          ? maintenanceMachineData.last_maintenance
          : new Date().toISOString(),
        next_maintenance: maintenanceMachineData.next_maintenance
          ? maintenanceMachineData.next_maintenance
          : new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString(),
        measurement_status: maintenanceMachineData.measurement_status
          ? maintenanceMachineData.measurement_status
          : "Running",
        cost_per_hour: values.machineCostPerHour,
        responsible: maintenanceMachineData.responsible
          ? maintenanceMachineData.responsible
          : [],
        documents: maintenanceMachineData.documents
          ? maintenanceMachineData.documents
          : [],
      };
      console.log(maintenanceMachineData);

      if (maintenanceMachineData) {
        const updatedMachine = await callApi<PostQueryUpdateMachineSnippet>({
          query: postQueryUpdateMachine(maintenanceMachineData.id, {
            updated_machine: body,
          }),
          auth: { setAuthedUser },
        });

        if (updatedMachine) {
          setMachineData((prevData) => {
            if (prevData) {
              const updatedData = prevData.map((machine) => {
                if (machine.id === maintenanceMachineData.id) {
                  return {
                    ...updatedMachine,
                    id: maintenanceMachineData.id,
                  };
                }
                return machine;
              });
              return updatedData;
            }
            return prevData;
          });
          setFormStatus("success");
          setUnsavedChanges(false);
          setAlertMessage("Machine updated successfully");
          setOpenInfo(false);
        }
      } else {
        const newMachine = await callApi<PostQueryCreateMachineSnippet>({
          query: postQueryCreateMachine(body),
          auth: { setAuthedUser },
        });

        if (newMachine.id) {
          setMachineData((prevData) => {
            if (prevData) {
              return [...prevData, newMachine];
            }
            return prevData;
          });
          setFormStatus("success");
          setUnsavedChanges(false);
          setAlertMessage("Machine created successfully");
          setOpenInfo(false);
        }
      }
    } catch (err) {
      console.log("MaintenanceMachineForm, handleFormSubmit() err: ", err.message);
      setFormStatus("error");
      setAlertMessage(err.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack gap={3}>
              <TextField
                name="machineType"
                label="Machine Type"
                value={values.machineType}
                onChange={handleChange}
                error={touched.machineType && !!errors.machineType}
                helperText={touched.machineType && errors.machineType}
              />

              <TextField
                name="machineName"
                label="Machine Name"
                value={values.machineName}
                onChange={handleChange}
                error={touched.machineName && !!errors.machineName}
                helperText={touched.machineName && errors.machineName}
              />

              <TextField
                name="machineConfiguration"
                label="Machine Configuration"
                value={values.machineConfiguration}
                onChange={handleChange}
                error={touched.machineConfiguration && !!errors.machineConfiguration}
                helperText={touched.machineConfiguration && errors.machineConfiguration}
              />

              <TextField
                name="machineCostPerHour"
                label="Machine Cost Per Hour"
                value={values.machineCostPerHour}
                onChange={handleChange}
                error={touched.machineCostPerHour && !!errors.machineCostPerHour}
                helperText={touched.machineCostPerHour && errors.machineCostPerHour}
              />

              <Stack spacing={1} justifyContent={"center"} alignItems={"center"}>
                <Button
                  type="submit"
                  loading={formStatus === "loading"}
                  css={[styles.width100, styles.widthLimit10]}
                >
                  {maintenanceMachineData ? "Update" : "Create"}
                </Button>

                <Alert
                  message={alertMessage || ""}
                  showAlert={!!alertMessage}
                  severity={formStatus}
                />
              </Stack>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default MaintenanceParkForm;
