import { IndicatorWidgetData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { TrendDirection } from "../IndicatorWidget/indicatorWidgetUtils";

const indicatorWidgetDefaultData: IndicatorWidgetData = {
  latest: 837,
  average: 214.73,
  trendAverage: TrendDirection.UP,
  trendLatest: TrendDirection.UP,
  chart: [92, 285, 125, 279, 176, 146, 250, 103, 206, 30, 143, 3, 345, 201, 837],
};

export default indicatorWidgetDefaultData;
