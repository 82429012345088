import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Box,
  Theme,
} from "@mui/material";
import { useState } from "react";
import { SerializedStyles, css } from "@emotion/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import { LeftNavList, LeftNavSingleItem } from "../layoutVariables";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import Collapse from "../../Components/MaterialUI/Collapse";
import cssComponentsStyles from "../../Global/Styles/components";

const cssStyles = (theme: Theme) => ({
  linkItem: css({
    width: `calc(100% - ${theme.spacing(2)})`,
    position: "relative",
  }),
  itemDivider: css({
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(6),
    height: `calc(100% - ${theme.spacing(7)})`,
  }),
  openedParent: css({
    background:
      theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[900],
    borderRadius: theme.shape.borderRadius,
  }),
  openedChild: css({
    color: theme.palette.primary.main,
  }),
  activeItem: css({
    background: theme.palette.mode === "light" ? "#E8E8E8" : "#313131",
    borderRadius: theme.shape.borderRadius,
  }),
});

interface LeftNavListMenuProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  navList: LeftNavList;
  listTitle: string;
}

const LeftNavListMenu: React.FC<LeftNavListMenuProps> = ({
  className,
  navList,
  listTitle,
}) => {
  return (
    <List className={className} component="nav">
      <ListSubheader component="div">{listTitle}</ListSubheader>
      {navList.map((item, index) => (
        <NavItem
          key={`parent-item${index}`}
          text={item.text}
          url={item.url}
          nested={item.nested}
          Icon={item.Icon}
          disabled={item.disabled}
          isParent
        />
      ))}
    </List>
  );
};

export default LeftNavListMenu;

interface NavItemProps extends LeftNavSingleItem {
  isParent?: boolean;
}

const NavItem: React.FC<NavItemProps> = ({
  text,
  url,
  nested,
  isParent,
  Icon,
  disabled,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const isActive = url && location.pathname === url;

  const handleClick = () => {
    setOpen(!open);
  };

  const buttonProps = {
    ...(url ? { component: Link, to: url } : { onClick: handleClick }),
  };

  const isNestedActive =
    nested && nested.some((nestedItem) => nestedItem.url === location.pathname);

  return (
    <Box
      component="div"
      css={[styles.linkItem, styles.leftSpacer2, isActive && styles.activeItem]}
    >
      {open && isParent ? (
        <Divider css={styles.itemDivider} orientation="vertical" />
      ) : null}
      <Box component="div" css={styles.width100}>
        <ListItemButton
          css={[
            styles.width100,
            styles.hoverItem,
            isParent && open && isNestedActive && styles.openedParent,
          ]}
          {...buttonProps}
          disabled={disabled}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText css={[!isParent && open && styles.openedChild]} primary={text} />
          {nested ? <>{open ? <ExpandLess /> : <ExpandMore />}</> : null}
        </ListItemButton>

        {nested ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            {nested.map((nestedItem, index) => (
              <NavItem
                key={`parent-item-${nestedItem.url}-${index}`}
                text={nestedItem.text}
                url={nestedItem.url}
                nested={nestedItem.nested}
                Icon={nestedItem.Icon}
                disabled={nestedItem.disabled}
              />
            ))}
          </Collapse>
        ) : null}
      </Box>
    </Box>
  );
};
