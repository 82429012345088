import { object } from "yup";
import { YUP_EMAIL } from "../../../Global/Constants/yupConstants";
import { Formik } from "formik";
import { Stack, Typography } from "@mui/material";
import TextField from "../../../Components/MaterialUI/FormFields/TextFields";
import Alert from "../../../Components/MaterialUI/Alert";
import Button from "../../../Components/MaterialUI/Button";
import AuthPagesLayout from "../../../Components/AuthPageComponents/AuthPageLayout";
import { Link } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import cssFontStyles from "../../../Global/Styles/font";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { PostQueryResetPasswordInput } from "../../../Api/Auth/apiAuthInputs";
import { PostQueryForgottenPasswordSnippet } from "../../../Api/Auth/apiAuthSnippets";
import { postQueryResetPassword } from "../../../Api/Auth/apiAuthPostQueries";
import callApi from "../../../Api/callApi";

const fieldValidation = object({
  email: YUP_EMAIL,
});

type ForgottenPasswordFormValues = {
  email: string;
};

const ForgotPassword: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
  };

  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const initialValues: ForgottenPasswordFormValues = {
    email: "",
  };

  const handleFormSubmit = async (values: ForgottenPasswordFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage("Loading...");

      const input: PostQueryResetPasswordInput = {
        email: values.email,
      };
      await callApi<PostQueryForgottenPasswordSnippet>({
        query: postQueryResetPassword(input),
        auth: null,
      });

      setFormStatus("success");
      setAlertMessage(
        "An email has been sent for confirmation. Please check your inbox."
      );
    } catch (err) {
      console.log("ForgotPassword form, handleFormSubmit() err: ", err.message);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => (
        <form css={styles.marginHorizontalAuto} onSubmit={handleSubmit}>
          <AuthPagesLayout>
            <Typography textAlign="center" mb={2} component="h4" variant="h1">
              Forgot password?
            </Typography>
            <Typography textAlign="center" mb={2} component="h4" variant="body1">
              Please enter your email address to reset your password.
            </Typography>
            <TextField
              name="email"
              label="Email Address*"
              error={touched["email"] && !!errors["email"]}
              helperText={touched["email"] && errors["email"]}
              onChange={handleChange}
              value={values.email}
            />
            <Button
              css={[styles.width100, styles.widthLimit10]}
              type="submit"
              loading={formStatus === "loading"}
            >
              Continue
            </Button>
            <Alert
              message={alertMessage || ""}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
            <Stack direction="column" alignItems="center">
              <Link
                to={"/"}
                css={styles.boldText}
                style={{ color: theme.palette.primary.main }}
              >
                Remember the password? Sign In
              </Link>
            </Stack>
          </AuthPagesLayout>
        </form>
      )}
    </Formik>
  );
};

export default ForgotPassword;
