import {
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  MaintenanceComment,
  MaintenanceMachineTask,
} from "../../../../../Api/Maintenance/apiMaintenanceDataTypes";
import {
  MaintenanceMachineTaskType,
  MaintenancePlanAddEditFormResult,
  MaintenancePlanTemplateApplyChangesOption,
  maintenanceMachineTaskTypeOptions,
  maintenancePlanTemplateApplyChangesOptions,
} from "../maintenancePlanUtils";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import TextField from "../../../../MaterialUI/FormFields/TextFields";
import { useState } from "react";
import Modal from "../../../../MaterialUI/Modal";
import RadioGroup from "../../../../MaterialUI/FormFields/RadioGroup";
import Button from "../../../../MaterialUI/Button";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import Select from "../../../../MaterialUI/FormFields/Select";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Collapse from "../../../../MaterialUI/Collapse";
import Alert from "../../../../MaterialUI/Alert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { formatDateAndTime } from "../../../../../Global/Utils/commonFunctions";

type ModalType = "Delete Comment" | "Edit Comment" | null;
type AddNewData = {
  text: string;
  taskType: MaintenanceMachineTaskType | "";
};
type DisplayedComments = {
  task: boolean;
  template: boolean;
};

interface MaintenancePlanCommentsProps {
  task: MaintenanceMachineTask;
  handleChange: (value?: MaintenancePlanAddEditFormResult) => Promise<void>;
  isAddComment: boolean;
}

const MaintenancePlanComments: React.FC<MaintenancePlanCommentsProps> = ({
  task,
  isAddComment,
}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles };

  const [displayedComments, setDisplayedComments] = useState<DisplayedComments>({
    task: true,
    template: true,
  });
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [_, setModalCommentID] = useState<string | null>(null);

  const [addNewData, setAddNewData] = useState<AddNewData>({
    text: "",
    taskType: "",
  });
  const [editText, setEditText] = useState<string>("");
  const [alertStatus, setAlertStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [applyChangesTo, setApplyChangesTo] =
    useState<MaintenancePlanTemplateApplyChangesOption>("All Open Tasks");

  const handleAddComment = async () => {
    try {
      setAlertStatus(null);
      setAlertMessage(null);

      if (task.task_template_id) {
        if (!addNewData.taskType) {
          setAlertStatus("warning");
          setAlertMessage("You must specify task type");
          return;
        }
      }

      if (!addNewData.text) {
        setAlertStatus("warning");
        setAlertMessage("Comment text is required");
        return;
      }

      // request to add new comment
    } catch (err) {
      console.log("handleAddComment err ", err);
    }
  };

  const handleEditComment = async () => {};
  const handleDeleteComment = async () => {};

  const TEMP_COMMENTS: MaintenanceComment[] = [
    {
      author: "Comment Author",
      date: formatDateAndTime(new Date()),
      id: "id-here",
      text: "Comment text here",
    },
  ];

  if (isAddComment) {
    return (
      <Stack spacing={3}>
        {task.task_template_id ? (
          <>
            <Typography variant="body1">
              If comment is added to the template, it will be displayed on every
              corresponding repetitive task derived from this template.
            </Typography>

            <Select
              label="Comment Type"
              value={addNewData.taskType}
              onChange={(e) =>
                setAddNewData((prev) => ({
                  ...prev,
                  taskType: e.target.value as MaintenanceMachineTaskType,
                }))
              }
              selectOptions={maintenanceMachineTaskTypeOptions}
            />

            {addNewData.taskType === "repetitive" ? (
              <Collapse in={addNewData.taskType === "repetitive"}>
                <RadioGroup
                  label="Apply Changes to"
                  value={applyChangesTo}
                  onChange={(e) =>
                    setApplyChangesTo(
                      e.target.value as MaintenancePlanTemplateApplyChangesOption
                    )
                  }
                  radioOptions={maintenancePlanTemplateApplyChangesOptions}
                />
              </Collapse>
            ) : null}
          </>
        ) : null}

        <TextField
          value={addNewData.text}
          onChange={(e) =>
            setAddNewData((prev) => ({
              ...prev,
              text: e.target.value,
            }))
          }
          label="Add New Comment"
        />

        <Stack css={styles.width100} alignItems="center" justifyContent="center">
          <Button css={styles.widthLimit20} onClick={handleAddComment}>
            Add Comment
          </Button>
        </Stack>
      </Stack>
    );
  }

  return (
    <Box component="div">
      {task.task_template_id ? (
        <Stack css={styles.contentBreak} spacing={3} direction="row">
          <Checkbox
            label="Task Comments"
            checked={displayedComments.task}
            onChange={(e) =>
              setDisplayedComments((prev) => ({
                ...prev,
                task: e.target.checked,
              }))
            }
          />
          <Checkbox
            label="Template Comments"
            checked={displayedComments.template}
            onChange={(e) =>
              setDisplayedComments((prev) => ({
                ...prev,
                template: e.target.checked,
              }))
            }
          />
        </Stack>
      ) : null}

      {task?.comments?.length ? (
        <>
          {TEMP_COMMENTS.map((comment, index) => (
            <Box component="div" key={`${index}-${comment.date}`}>
              <Divider variant="middle" />
              <ListItem
                secondaryAction={
                  <IconButton
                    onClick={() => {
                      setModalCommentID(comment.id);
                      setOpenModal("Edit Comment");
                    }}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                }
              >
                <ListItemText
                  primary={comment.text}
                  secondary={`${comment.author} - ${comment.date}`}
                />
              </ListItem>
              <Divider variant="middle" />
            </Box>
          ))}
        </>
      ) : (
        <Typography textAlign="center" variant="h4">
          Task doesn't have any comments
        </Typography>
      )}

      <Modal
        open={openModal !== null}
        fullWidth
        label={openModal || ""}
        onClose={() => setOpenModal(null)}
      >
        {openModal === "Delete Comment" ? (
          <Stack spacing={3} alignItems="center" justifyContent="center">
            <Typography variant="body1">
              Are you sure you want to delete this comment?
            </Typography>

            <Stack css={styles.width100} alignItems="center" justifyContent="center">
              <Button
                css={styles.widthLimit20}
                onClick={handleDeleteComment}
                color="error"
              >
                Delete Comment
              </Button>
            </Stack>
          </Stack>
        ) : null}

        {openModal === "Edit Comment" ? (
          <Stack spacing={3}>
            <Stack css={styles.width100} alignItems="flex-end">
              <Button
                css={styles.widthLimit20}
                onClick={() => setOpenModal("Delete Comment")}
                variant="text"
                color="error"
              >
                Delete Comment
              </Button>
            </Stack>

            <TextField
              value={editText}
              onChange={(e) => setEditText(() => e.target.value)}
              label="Edit Comment"
            />

            <Stack css={styles.width100} alignItems="center" justifyContent="center">
              <Button css={styles.widthLimit20} onClick={handleEditComment}>
                Edit Comment
              </Button>
            </Stack>
          </Stack>
        ) : null}

        <Stack
          css={styles.reverseContentBreak}
          alignItems="center"
          justifyContent="center"
        >
          <Alert
            message={alertMessage}
            showAlert={!!alertMessage}
            severity={alertStatus}
          />
        </Stack>
      </Modal>
    </Box>
  );
};

export default MaintenancePlanComments;
