import { ChartConfigFormValuesWithTitle } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { Box, Grid } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Checkbox from "../../MaterialUI/FormFields/Checkbox";
import {
  BarChartConfiguration,
  BarChartGroupMode,
  BarChartValueScale,
} from "./barChartTypes";
import Select from "../../MaterialUI/FormFields/Select";
import { SelectOption } from "../../../Global/Types/commonTypes";
import { handleGetSelectOption } from "../../../Global/Utils/commonFunctions";
import { useLanguageContext } from "../../../context/LanguageContext";

type KeyOfConfigValue = keyof ChartConfigFormValuesWithTitle<BarChartConfiguration>;

interface EditBarChartConfigProps {
  config: ChartConfigFormValuesWithTitle<BarChartConfiguration>;
  setUpdatedConfig: React.Dispatch<
    React.SetStateAction<ChartConfigFormValuesWithTitle<BarChartConfiguration>>
  >;
  isColumn?: boolean;
}

const EditBarChartConfig: React.FC<EditBarChartConfigProps> = ({
  config,
  setUpdatedConfig,
  isColumn,
}) => {
  const { t } = useLanguageContext();
  const groupModeArray: BarChartGroupMode[] = ["grouped", "stacked"];
  const groupModeOptions: SelectOption[] = handleGetSelectOption(groupModeArray, true);

  const scaleArray: BarChartValueScale[] = ["linear", "symlog"];
  const scaleOptions: SelectOption[] = handleGetSelectOption(scaleArray, true);

  const handleUpdateFormValue = (key: KeyOfConfigValue, value: string | boolean) => {
    setUpdatedConfig((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Box component="div">
      <Grid spacing={2} container>
        <Grid item xs={12}>
          <TextField
            name="title"
            label={t("Title")}
            onChange={(e) => handleUpdateFormValue("title", e.target.value)}
            value={config.title}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            selectOptions={groupModeOptions}
            name="groupMode"
            label={t("Group Mode")}
            onChange={(e) => handleUpdateFormValue("groupMode", e.target.value)}
            value={config.groupMode}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            selectOptions={scaleOptions}
            name="valueScale"
            label={t("Scale")}
            onChange={(e) => handleUpdateFormValue("valueScale", e.target.value)}
            value={config.valueScale}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={`${t("Manual")} ${isColumn ? t("Left") : t("Bottom")} ${t("Label")}`}
            name="manualBottomLabel"
            defaultChecked={!!config.manualBottomLabel}
            onChange={(e) => handleUpdateFormValue("manualBottomLabel", e.target.checked)}
            value={!!config.manualBottomLabel}
          />
        </Grid>
        {config.manualBottomLabel ? (
          <Grid item xs={12} sm={6}>
            <TextField
              name="bottomLegend"
              label={`${isColumn ? t("Left") : t("Bottom")} ${t("Label")}`}
              onChange={(e) => handleUpdateFormValue("bottomLegend", e.target.value)}
              value={config.bottomLegend}
            />
          </Grid>
        ) : null}

        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable Labels")}
            name="enableLabel"
            defaultChecked={config.enableLabel}
            onChange={(e) => handleUpdateFormValue("enableLabel", e.target.checked)}
            value={config.enableLabel}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable Grid X")}
            name="enableGridX"
            defaultChecked={config.enableGridX}
            onChange={(e) => handleUpdateFormValue("enableGridX", e.target.checked)}
            value={config.enableGridX}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Enable Grid Y")}
            name="enableGridY"
            defaultChecked={config.enableGridY}
            onChange={(e) => handleUpdateFormValue("enableGridY", e.target.checked)}
            value={config.enableGridY}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Checkbox
            label={t("Fixed Decimal Value")}
            defaultChecked={!!config.fixedDecimalValue}
            onChange={(e) => handleUpdateFormValue("fixedDecimalValue", e.target.checked)}
            value={!!config.fixedDecimalValue}
          />
        </Grid>
        {config.fixedDecimalValue ? (
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("Decimal Points")}
              onChange={(e) => handleUpdateFormValue("decimalPoints", e.target.value)}
              value={config.decimalPoints === undefined ? 0 : config.decimalPoints}
              numberField
              noThousandSeparator
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default EditBarChartConfig;
