import { Formik } from "formik";
import { Stack } from "@mui/material";
import TextField from "../../MaterialUI/FormFields/TextFields";
import Button from "../../MaterialUI/Button";
import { object } from "yup";
import { YUP_REQUIRED_STRING } from "../../../Global/Constants/yupConstants";
import Alert from "../../MaterialUI/Alert";
import { useState } from "react";
import { FormStatuses } from "../../../Global/Types/commonTypes";
import { SerializedStyles } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import ROUTES_MAPPING from "../../../Layout/Router/routesMapping";
import callApi from "../../../Api/callApi";
import { useAuthedContext } from "../../../context/AuthContext";
import {
  postQueryBoschProcessCreate,
  putQueryBoschProcess,
} from "../../../Api/BoschWorkflows/apiBoschWorkflowsPostQueries";
import { PostQueryBoschProcessCreateInput } from "../../../Api/BoschWorkflows/apiBoschWorkflowsInputs";
import { BOSCH_INITIAL_NODE_CONFIG } from "../TestPlans/BoschNodes/boschNodesUtils";
import { FlowInitialNodeType } from "../../SmallComponents/ReactFlow/FlowNodes/flowNodesTypes";
import { v4 as uuidv4 } from "uuid";
import { PostQueryBoschProcessCreateSnippet } from "../../../Api/BoschWorkflows/apiBoschWorkflowsSnippets";
import { useDetectFormsUnsavedChanges } from "../../../Global/Hooks/useDetectFormsUnsavedChanges";

const fieldValidation = object({
  name: YUP_REQUIRED_STRING,
});

type FormValues = {
  name: string;
};

interface CreateWorkflowFormProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  isProcess?: boolean;
  handleSetUnsavedChanges: (unsavedChanges: boolean) => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateWorkflowForm: React.FC<CreateWorkflowFormProps> = ({
  className,
  isProcess,
  handleSetUnsavedChanges,
  setUnsavedChanges,
}) => {
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const navigate = useNavigate();

  const initialValues: FormValues = {
    name: "",
  };

  const handleFormSubmit = async (values: FormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage(null);

      const initialNodeId = uuidv4().split("-")[0];

      const initialNode: FlowInitialNodeType = {
        id: initialNodeId,
        data: {
          id: initialNodeId,
          name: values.name,
          createdOn: new Date().toISOString(),
          updatedOn: new Date().toISOString(),
        },
        position: { x: 0, y: 1 },
        ...BOSCH_INITIAL_NODE_CONFIG,
      };

      const INITIAL_FLOW = {
        id: uuidv4().split("-")[0],
        name: values.name,
        createdOn: new Date().toISOString(),
        updatedOn: new Date().toISOString(),
        nodes: [initialNode],
        edges: [],
      };

      const input: PostQueryBoschProcessCreateInput = {
        data: INITIAL_FLOW,
      };

      const res: PostQueryBoschProcessCreateSnippet = await callApi({
        query: postQueryBoschProcessCreate(input),
        auth: { setAuthedUser },
      });

      // Update DB entry
      await callApi({
        query: putQueryBoschProcess({ data: { ...INITIAL_FLOW, id: res.id } }, res.id),
        auth: { setAuthedUser },
      });

      setFormStatus("success");
      setUnsavedChanges(false);
      navigate({
        pathname: `${
          ROUTES_MAPPING[isProcess ? "Processes" : "Workflows-Workflow Modeler"]
        }/${res.id}`,
      });
    } catch (err) {
      console.log("CreateWorkflowForm err ", err);
      setFormStatus("error");
      setAlertMessage("Something went wrong");
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => {
        useDetectFormsUnsavedChanges(initialValues, values, handleSetUnsavedChanges);

        return (
          <form onSubmit={handleSubmit}>
            <Stack
              className={className}
              spacing={2}
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                name="name"
                label="Workflow Name"
                error={touched["name"] && !!errors["name"]}
                helperText={touched["name"] && errors["name"]}
                onChange={handleChange}
                value={values.name}
              />

              <Button type="submit" loading={formStatus === "loading"}>
                Create New Workflow
              </Button>
              <Alert
                message={alertMessage || ""}
                showAlert={!!alertMessage}
                severity={formStatus}
              />
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

export default CreateWorkflowForm;
