import { TimeChartConfiguration } from "../TimeChart/timeChartTypes";

export const lineChartDefaultConfiguration: TimeChartConfiguration = {
  axisLeftLegend: "Y-axis label",
  axisBottomLegend: "Time",
  unitsOfMeasure: "",
  scale: "",
  interpolate: "linear",
  colors: "paired",
  enablePoints: false,
  enableGridX: true,
  enableGridY: true,
};
