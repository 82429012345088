import { OpcSubscriptionStatus } from "../../../../Api/DataSources/apiDSDataTypes";
import {
  getQueryDaSubscriptionId,
  getQueryMQTTSubscriptionId,
  getQueryModbusConnectionId,
  getQueryUaSubscriptionId,
} from "../../../../Api/DataSources/apiDSGetQueries";
import {
  PostQueryDAStartSubscriptionInput,
  PostQueryMQTTStartSubscriptionInput,
  PostQueryModbusConnectionStartInput,
  PostQueryUAStartSubscriptionInput,
} from "../../../../Api/DataSources/apiDSInputs";
import {
  postQueryDADeleteSubscription,
  postQueryDAStartSubscription,
  postQueryMQTTDeleteSubscription,
  postQueryMQTTStartSubscription,
  postQueryModbusConnectionStart,
  postQueryModbusDeleteConnection,
  postQueryUADeleteSubscription,
  postQueryUAStartSubscription,
} from "../../../../Api/DataSources/apiDSPostQueries";
import {
  GetQueryDaSubscriptionIdSnippet,
  PostQueryDAStartSubscriptionSnippet,
  GetQueryUaSubscriptionIdSnippet,
  GetQueryMQTTSubscriptionIdSnippet,
  GetQueryModbusConnectionIdSnippet,
} from "../../../../Api/DataSources/apiDSSnippets";
import callApi from "../../../../Api/callApi";
import { AuthedUser } from "../../../../context/authContextTypes";

export type IntegratorConnectionType = "mqtt" | "modbus" | "opc_da" | "opc_ua";
export type IntegratorConnectionTypeReadable = "MQTT" | "Modbus" | "OPC DA" | "OPC UA";

export type IntegratorConnectionBase = {
  status: OpcSubscriptionStatus;
  id: string;
  name: string;
  client_name?: string;
  type: IntegratorConnectionType;
};

// ------------------ Connection Card Click ------------------
export const handleConnectionClickOpcDa = async (
  isActive: boolean,
  subID: string,
  setAuthedUser: (value: React.SetStateAction<AuthedUser | null>) => void
): Promise<OpcSubscriptionStatus> => {
  if (isActive) {
    // stop the sub
    await callApi({
      query: postQueryDADeleteSubscription(subID),
      auth: { setAuthedUser },
    });
    return "inactive";
  } else {
    // start the sub
    const data = await callApi<GetQueryDaSubscriptionIdSnippet>({
      query: getQueryDaSubscriptionId(subID),
      auth: { setAuthedUser },
    });
    const groupAttributes = data.attributes.map((prop) => prop.opcda_attribute_id);

    const input: PostQueryDAStartSubscriptionInput = {
      server_name: data.server_name,
      name: data.name,
      update_rate: +data.update_rate,
      nodes: data.nodes,
      attributes: groupAttributes,
      subscription_id: subID,
    };
    const updatedSub = await callApi<PostQueryDAStartSubscriptionSnippet>({
      query: postQueryDAStartSubscription(input),
      auth: { setAuthedUser },
    });
    return updatedSub.status;
  }
};

export const handleConnectionClickOpcUa = async (
  isActive: boolean,
  subID: string,
  setAuthedUser: (value: React.SetStateAction<AuthedUser | null>) => void
): Promise<OpcSubscriptionStatus> => {
  if (isActive) {
    // stop the sub
    const input = {
      subscription_id: subID,
    };
    await callApi({
      query: postQueryUADeleteSubscription(input),
      auth: { setAuthedUser },
    });
    return "inactive";
  } else {
    // start the sub
    const data = await callApi<GetQueryUaSubscriptionIdSnippet>({
      query: getQueryUaSubscriptionId(subID),
      auth: { setAuthedUser },
    });
    const nodes = data.nodes.map((node) => node.node_id);

    const input: PostQueryUAStartSubscriptionInput = {
      url: data.server_url,
      name: data.name,
      update_rate: data.update_rate,
      nodes: nodes,
      subscription_id: subID,
    };
    await callApi({
      query: postQueryUAStartSubscription(input),
      auth: { setAuthedUser },
    });
    return "active";
  }
};

export const handleConnectionClickOpcMqtt = async (
  isActive: boolean,
  subID: string,
  setAuthedUser: (value: React.SetStateAction<AuthedUser | null>) => void
): Promise<OpcSubscriptionStatus> => {
  if (isActive) {
    // stop the sub
    await callApi({
      query: postQueryMQTTDeleteSubscription(subID),
      auth: { setAuthedUser },
    });
    return "inactive";
  }

  // start the sub
  const data = await callApi<GetQueryMQTTSubscriptionIdSnippet>({
    query: getQueryMQTTSubscriptionId(subID),
    auth: { setAuthedUser },
  });

  const input: PostQueryMQTTStartSubscriptionInput = {
    client_name: data.client_name,
    broker_host: data.broker_host,
    broker_port: data.broker_port,
    topics: data.topics,
    id: subID,
  };
  await callApi({
    query: postQueryMQTTStartSubscription(input),
    auth: { setAuthedUser },
  });
  return "active";
};

export const handleConnectionClickOpcModbus = async (
  isActive: boolean,
  subID: string,
  setAuthedUser: (value: React.SetStateAction<AuthedUser | null>) => void
): Promise<{ status: OpcSubscriptionStatus; alert: null | string }> => {
  if (isActive) {
    // stop the sub
    await callApi({
      query: postQueryModbusDeleteConnection(subID),
      auth: { setAuthedUser },
    });
    return { status: "inactive", alert: null };
  }

  // start the sub
  const data = await callApi<GetQueryModbusConnectionIdSnippet>({
    query: getQueryModbusConnectionId(subID),
    auth: { setAuthedUser },
  });

  if (!data.values_addresses_map.length) {
    return { status: "inactive", alert: "You cannot start connection without mapping" };
  }

  const input: PostQueryModbusConnectionStartInput = {
    name: data.name,
    id: subID,
    polling_time: data.polling_time,
  };
  await callApi({
    query: postQueryModbusConnectionStart(input),
    auth: { setAuthedUser },
  });
  return { status: "active", alert: null };
};
