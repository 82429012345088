import {
  ExcellenceChartProps,
  LineAreaHeatMapScatterPlotData,
} from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import Heatmap from "../../../../ExcellenceWidgets/AdvancedWidgets/HeatMap/HeatMap";
import heatMapDefaultData from "../../../../ExcellenceWidgets/AdvancedWidgets/AdvancedDefaultConfig/heatMapDefaultData";
import {
  HeatMapConfig,
  HeatMapDataSchema,
} from "../../../../ExcellenceWidgets/AdvancedWidgets/HeatMap/heatMapTypes";
import { useTheme } from "@mui/material";
import { useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLanguageContext } from "../../../../../context/LanguageContext";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import { graphQlQueryExcellenceHeatMapData } from "../../../../../GraphQL/Excellence/graphQLQueriesExcellence";
import LoadingBackdrop from "../../../../MaterialUI/LoadingBackdrop";
import Alert from "../../../../MaterialUI/Alert";

interface ExcellenceHeatmapProps extends ExcellenceChartProps {
  data: LineAreaHeatMapScatterPlotData | null;
  configuration: HeatMapConfig;
  schema: HeatMapDataSchema | null;
}
const ExcellenceHeatmap: React.FC<ExcellenceHeatmapProps> = ({
  className,
  data,
  configuration,
  isStatic,
  schema,
  resetChartFetch,
  dataFetchFromParent,
  // parameters,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();
  const [updatedData, setUpdatedData] = useState<LineAreaHeatMapScatterPlotData | null>(
    data
  );
  const [fetchStatus, setFetchStatus] = useState<FormStatuses>(null);
  const [chartToUse, setChartToUse] = useState<boolean>(true);

  const [getHeatData, { loading: loadingData }] = useLazyQuery(
    graphQlQueryExcellenceHeatMapData
  );

  // const paramMapping: Record<string, string> | undefined = parameters?.reduce(
  //   (acc, curr) => {
  //     return {
  //       ...acc,
  //       [curr.id]: curr.name,
  //     };
  //   },
  //   {}
  // );

  useEffect(() => {
    setChartToUse((prev) => !prev);
  }, [updatedData]);

  useEffect(() => {
    (async () => {
      if (!dataFetchFromParent && schema) {
        try {
          setFetchStatus("loading");
          const result = await getHeatData({
            variables: {
              input: schema,
            },
          });
          if (!result.data || result.error) {
            throw new Error(JSON.stringify(result));
          }
          const resultData = result.data.heatMapData.chartData;

          setUpdatedData(resultData);
          setFetchStatus(null);
        } catch (err) {
          console.log("err ", err);
          setFetchStatus("error");
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFetchFromParent, resetChartFetch]);

  useEffect(() => {
    if (dataFetchFromParent) {
      setUpdatedData(data);
    }
  }, [dataFetchFromParent, data]);

  return (
    <>
      {chartToUse ? (
        <Heatmap
          className={className}
          configuration={configuration}
          data={data || heatMapDefaultData}
          isStatic={isStatic}
          schema={schema}
        />
      ) : null}

      {!chartToUse ? (
        <Heatmap
          className={className}
          configuration={configuration}
          data={data || heatMapDefaultData}
          isStatic={isStatic}
          schema={schema}
        />
      ) : null}

      {isStatic ? null : <LoadingBackdrop loading={loadingData} />}

      <Alert
        css={styles.reverseContentBreak}
        message={t("Something went wrong")}
        showAlert={fetchStatus === "error"}
        severity={"error"}
      />
    </>
  );
};

export default ExcellenceHeatmap;
