import { Query } from "../callApi";

export const getQueryUsersGetCurrentUser = (): Query => ({
  endpoint: "users/get_current_user",
  method: "GET",
});

export const getQueryUserGetProfilePicture = (): Query => ({
  endpoint: "users/user/profile-picture",
  method: "GET",
});
