import { Box, IconButton, Stack, Typography } from "@mui/material";
import { SerializedStyles } from "@emotion/react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Select from "../../../../MaterialUI/FormFields/Select";
import {
  formatDateAndTime,
  handleGetSelectOption,
} from "../../../../../Global/Utils/commonFunctions";
import { useEffect, useState } from "react";
import {
  startOfMonth,
  subMonths,
  endOfMonth,
  startOfQuarter,
  subQuarters,
  endOfQuarter,
  startOfYear,
  subYears,
  endOfYear,
} from "date-fns";

type CalendarStartEnd = {
  from: string;
  to: string;
};

type PeriodOption = "month" | "quarter" | "year";
const PERIOD_OPTIONS = handleGetSelectOption(["month", "quarter", "year"], true);

interface MaintenanceCalendarPeriodProps {
  css?: (SerializedStyles | false)[] | SerializedStyles;
  className?: string;
  calendarFromTo: CalendarStartEnd;
  setCalendarFromTo: React.Dispatch<React.SetStateAction<CalendarStartEnd>>;
  period: PeriodOption;
  setPeriod: React.Dispatch<React.SetStateAction<PeriodOption>>;
}

const MaintenancePlanCalendarPeriod: React.FC<MaintenanceCalendarPeriodProps> = ({
  className,
  setCalendarFromTo,
  calendarFromTo,
  period,
  setPeriod,
}) => {
  const [interval, setInterval] = useState<number>(0);

  useEffect(() => {
    const currentDate = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (period) {
      case "month": {
        startDate = startOfMonth(subMonths(currentDate, interval));
        endDate = endOfMonth(subMonths(currentDate, interval));
        break;
      }
      case "quarter": {
        startDate = startOfQuarter(subQuarters(currentDate, interval));
        endDate = endOfQuarter(subQuarters(currentDate, interval));
        break;
      }
      case "year": {
        startDate = startOfYear(subYears(currentDate, interval));
        endDate = endOfYear(subYears(currentDate, interval));
        break;
      }
    }

    setCalendarFromTo({ from: startDate.toISOString(), to: endDate.toISOString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, interval]);

  const handleChangeInterval = (direction: "back" | "next") => {
    if (direction === "back") {
      setInterval((prev) => prev + 1);
    }
    if (direction === "next") {
      setInterval((prev) => prev - 1);
    }
  };

  const handleChangePeriod = (value: string) => {
    setPeriod(value as PeriodOption);
    setInterval(0);
  };

  return (
    <Box component="div" className={className}>
      <Stack
        spacing={3}
        direction={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          {`${formatDateAndTime(calendarFromTo.from, "date")} - ${formatDateAndTime(
            calendarFromTo.to,
            "date"
          )}`}
        </Typography>

        <Stack spacing={1.25} direction="row" alignItems="center" justifyContent="center">
          <IconButton
            aria-label="previous period"
            onClick={() => handleChangeInterval("back")}
          >
            <ArrowBackIcon />
          </IconButton>

          <Box component="div" style={{ minWidth: "150px" }}>
            <Select
              fullWidth
              selectOptions={PERIOD_OPTIONS}
              value={period}
              onChange={(e) => handleChangePeriod(e.target.value)}
            />
          </Box>

          <IconButton
            aria-label="next period"
            onClick={() => handleChangeInterval("next")}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};

export default MaintenancePlanCalendarPeriod;
