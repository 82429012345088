import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import useTheme from "@mui/material/styles/useTheme";
import cssLayoutStyles from "../../../../../Global/Styles/layout";
import cssSpacingStyles from "../../../../../Global/Styles/spacing";
import Checkbox from "../../../../MaterialUI/FormFields/Checkbox";
import { useState } from "react";
import { useAuthedContext } from "../../../../../context/AuthContext";
import { FormStatuses } from "../../../../../Global/Types/commonTypes";
import Alert from "../../../../MaterialUI/Alert";
import { getQueryMQTTTopicMessage } from "../../../../../Api/DataSources/apiDSGetQueries";
import { GetQueryMQTTTopicMessageSnippet } from "../../../../../Api/DataSources/apiDSSnippets";
import callApi from "../../../../../Api/callApi";
import JSONTreeView from "../../../../SmallComponents/JSONTreeView/JSONTreeView";

interface MqttTopicsProps {
  allTopics: string[];
  selectedTopics: string[];
  setSelectedTopics: React.Dispatch<React.SetStateAction<string[]>>;
  broker: string;
  port: string | number;
}

const MqttTopics: React.FC<MqttTopicsProps> = ({
  allTopics,
  selectedTopics,
  setSelectedTopics,
  broker,
  port,
}) => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const [topicStatus, setTopicStatus] = useState<FormStatuses>(null);
  const [topicAlert, setTopicAlert] = useState<string | null>(null);

  const [lastSelectedTopic, setLastSelectedTopic] =
    useState<GetQueryMQTTTopicMessageSnippet | null>(null);
  const { setAuthedUser } = useAuthedContext();

  const handleOnSelectTopic = async (topic: string) => {
    try {
      setTopicStatus("loading");
      setTopicAlert("Loading...");
      const topicInArray = selectedTopics.some((item) => item === topic);

      if (!topicInArray) {
        const message = await callApi<GetQueryMQTTTopicMessageSnippet>({
          query: getQueryMQTTTopicMessage(broker, port, topic),
          auth: {
            setAuthedUser,
          },
        });
        setLastSelectedTopic(message);
      }

      if (topicInArray) {
        const updatedTopics = selectedTopics.filter((item) => item !== topic);
        setSelectedTopics(updatedTopics);
      } else {
        setSelectedTopics((prev) => [topic, ...prev]);
      }

      setTopicStatus(null);
      setTopicAlert(null);
    } catch (err) {
      console.log("err - handleOnSelectTopic()", err);
      setTopicStatus("error");
      setTopicAlert("Something went wrong");
    }
  };

  return (
    <Box component="div" p={3}>
      <Alert
        css={styles.contentBreak}
        severity={topicStatus}
        showAlert={topicStatus === "error"}
        message={topicAlert}
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={5.5}>
          <Stack css={styles.width100} spacing={0}>
            {allTopics.map((item, index) => (
              <Box component="div" key={`${item}-${index}`}>
                <Checkbox
                  label={item}
                  onChange={(e) => handleOnSelectTopic(e.target.value)}
                  checked={selectedTopics.some((topic) => topic === item)}
                  value={item}
                  disabled={topicStatus === "loading"}
                />
              </Box>
            ))}
          </Stack>
        </Grid>

        <Grid item xs={12} sm={1}>
          <Divider style={{ margin: "auto", width: "1px" }} orientation="vertical" />
        </Grid>

        <Grid item xs={12} sm={5.5}>
          <Typography css={styles.contentBreak} variant="h3">
            Select a topic to see details about it
          </Typography>

          {lastSelectedTopic ? (
            <Box
              component="div"
              style={{
                maxHeight: "700px",
                overflowY: "auto",
              }}
            >
              <JSONTreeView data={lastSelectedTopic} hideRoot />
            </Box>
          ) : (
            <Typography variant="h4">
              When you select a topic, its details will be displayed here
            </Typography>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MqttTopics;
