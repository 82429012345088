const barChartDefaultData = [
  {
    group: "Zone A",
    Input: 91,
    Output: 27,
  },
  {
    group: "Zone B",
    Input: 52,
    Output: 26,
  },
  {
    group: "Zone C",
    Input: 85,
    Output: 74,
  },
  {
    group: "Zone D",
    Input: 62,
    Output: 115,
  },
  {
    group: "Zone E",
    Input: 56,
    Output: 76,
  },
];
export default barChartDefaultData;
