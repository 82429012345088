import { Box, Grid, IconButton, Menu, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import {
  getAssetsOptions,
  // LossesChartType,
  OEEModalTitle,
  OEESingleAssetDataType,
} from "../oEEUtils";
import { oEESingleAssetData } from "./oEEChartsData";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import cssSpacingStyles from "../../../../Global/Styles/spacing";
import cssComponentsStyles from "../../../../Global/Styles/components";
import Modal from "../../../MaterialUI/Modal";
import Button from "../../../MaterialUI/Button";
import EditOEEBarChartForm from "./EditOEEBarChartForm";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DifferenceOutlinedIcon from "@mui/icons-material/DifferenceOutlined";
import { format, startOfMonth } from "date-fns";
import { ResponsiveLine } from "@nivo/line";
import { PeriodModeDates } from "../../../SmallComponents/DynamicGridLayout.tsx/dynamicGridExcellenceUtils";
import ChartPeriodViewer from "../../../SmallComponents/DynamicGridLayout.tsx/ChartPeriodViewer";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Select from "../../../MaterialUI/FormFields/Select";
import { AutocompleteOption } from "../../../../Global/Types/commonTypes";
import { GetQueryOEEAseetsFunctionalLocationsSnippet } from "../../../../Api/OEE/apiOEESnippets";
import callApi from "../../../../Api/callApi";
import { getQueryAssetsFunctionalLocations } from "../../../../Api/OEE/apiOEEGetQueries";
import { useAuthedContext } from "../../../../context/AuthContext";

interface OEELineChartForSingleAssetProps {}

const OEELineChartForSingleAsset: React.FC<OEELineChartForSingleAssetProps> = ({}) => {
  const theme = useTheme();
  const styles = {
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const { setAuthedUser } = useAuthedContext();
  const [barChartData, setBarChartData] = useState<OEESingleAssetDataType[]>([]);
  const [modalTitle, setModalTitle] = useState<OEEModalTitle | null>(null);
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [dates, setDates] = useState<PeriodModeDates | null>({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  });
  const [isChartPeriodViewerVisible, setIsChartPeriodViewerVisible] =
    useState<boolean>(false);
  const [assetTypeOptions, setAssetTypeOptions] = useState<AutocompleteOption[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>("667020b8aafe32a08a125f2a");
  const openMenu = Boolean(menuAnchor);

  const gridLineColor =
    theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[900];

  useEffect(() => {
    setBarChartData(oEESingleAssetData.chartData);
  }, []);

  const fetchOEEData = async () => {
    try {
      const oEEAssets = await callApi<GetQueryOEEAseetsFunctionalLocationsSnippet>({
        query: getQueryAssetsFunctionalLocations,
        auth: { setAuthedUser },
      });
      setAssetTypeOptions(getAssetsOptions(oEEAssets));
    } catch (error) {
      console.log("There was an error fetching assets ", error);
    }
  };

  useEffect(() => {
    fetchOEEData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const toggleChartPeriodViewer = () => {
    setIsChartPeriodViewerVisible((prev) => !prev);
  };

  const handleAssetChange = (e: React.ChangeEvent<{ value: string }>) => {
    const selectedValue = e.target.value as string;
    const selectedOption = assetTypeOptions.find(
      (option) => option.value === selectedValue
    );
    if (selectedOption) {
      setSelectedAsset(selectedOption.value);
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Select
            selectOptions={assetTypeOptions}
            label="Select Asset"
            onChange={(e) => handleAssetChange(e)}
            value={selectedAsset}
          />
        </Grid>

        <Grid item mr={2}>
          <Grid container alignItems="center" spacing={2}>
            {isChartPeriodViewerVisible && dates ? (
              <Grid item>
                <Stack alignItems="flex-end">
                  <ChartPeriodViewer
                    dates={dates}
                    setDates={setDates}
                    isOEEComponent={true}
                  />
                </Stack>
              </Grid>
            ) : dates ? (
              <Grid item>
                <Typography variant="body2" color={theme.palette.grey[700]}>
                  {`${format(dates.startDate, "dd.MM.yyyy")} - ${format(
                    dates.endDate,
                    "dd.MM.yyyy"
                  )}`}
                </Typography>
              </Grid>
            ) : null}

            <Grid item>
              <IconButton
                aria-label="toggle period viewer"
                onClick={toggleChartPeriodViewer}
                size="small"
              >
                <SwapHorizIcon
                  css={styles.greyIcon}
                  style={{
                    color: isChartPeriodViewerVisible ? theme.palette.primary.main : "",
                  }}
                />
              </IconButton>
            </Grid>

            <Grid item>
              <IconButton
                aria-label="widget setting menu"
                onClick={handleOpenMenu}
                size="small"
              >
                <TuneOutlinedIcon css={styles.greyIcon} fontSize="medium" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Stack alignItems="flex-end" mr={1} mt={1}>
        <Typography variant="h2">{oEESingleAssetData.percentage}%</Typography>
      </Stack>

      {barChartData.length > 0 ? (
        <Box component="div" height="300px">
          <ResponsiveLine
            data={barChartData}
            margin={{ top: 20, right: 50, bottom: 30, left: 40 }}
            enablePoints={false}
            enableGridX={false}
            colors={"#387CB8"}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
              grid: {
                line: {
                  stroke: gridLineColor,
                  strokeWidth: 1,
                },
              },
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              format: (value) => `${value}s`,
            }}
            lineWidth={2.5}
            markers={[
              {
                axis: "y",
                legend: "Low",
                legendPosition: "right",
                lineStyle: {
                  stroke: "#BD4F4F",
                  strokeWidth: 1,
                },
                textStyle: {
                  fill: "#BD4F4F",
                  fontSize: "13px",
                },
                value: 14,
              },
              {
                axis: "y",
                legend: "High",
                legendPosition: "right",
                lineStyle: {
                  stroke: "#33A161",
                  strokeWidth: 1,
                },
                textStyle: {
                  fill: "#33A161",
                  fontSize: "13px",
                },
                value: 19,
              },
            ]}
          />
        </Box>
      ) : (
        <Typography variant="h5" ml={1} mb={3}>
          Loading...
        </Typography>
      )}

      <Menu
        anchorEl={menuAnchor}
        open={openMenu}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <Stack css={styles.leftRightPadding1} direction="row" spacing={1}>
          <Stack alignItems="center">
            <IconButton
              aria-label="edit loss"
              onClick={() => setModalTitle("Edit OEE Chart")}
            >
              <EditOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Edit
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="copy widget"
              onClick={() => setModalTitle("Copy OEE Chart")}
            >
              <DifferenceOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center">
              Copy
            </Typography>
          </Stack>

          <Stack alignItems="center">
            <IconButton
              aria-label="delete loss"
              onClick={() => setModalTitle("Delete OEE Chart")}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
            <Typography variant="caption" align="center" color="textPrimary">
              Delete
            </Typography>
          </Stack>
        </Stack>
      </Menu>

      <Modal
        open={!!modalTitle}
        onClose={() => setModalTitle(null)}
        fullWidth
        maxWidth={modalTitle === "Delete OEE Chart" ? "sm" : "md"}
        label={modalTitle}
      >
        {modalTitle === "Edit OEE Chart" ? (
          <EditOEEBarChartForm isForSingleAsset={true} />
        ) : null}
        {modalTitle === "Delete OEE Chart" ? (
          <Stack spacing={2} justifyContent="center" alignItems="center">
            <Typography>Clicking the below button will delete this chart</Typography>
            <Button>Confirm Delete</Button>
          </Stack>
        ) : null}
      </Modal>
    </>
  );
};

export default OEELineChartForSingleAsset;
