import { Query } from "../callApi";

export const getQueryProjects = (): Query => ({
  endpoint: "project_management/projects",
  method: "GET",
});

export const getQueryProject = (id: string): Query => ({
  endpoint: `/project_management/project/${id}`,
  method: "GET",
});
export const getQueryProducts = (): Query => ({
  endpoint: `project_management/products`,
  method: "GET",
});
