import { LegendDatum, ResponsivePie } from "@nivo/pie";
import { SerializedStyles } from "@emotion/react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  PieChartConfiguration,
  PieChartDataSchema,
  getPieChartLegendText,
  getPieCustomLegendParamName,
} from "./pieChartTypes";
import { PieDonutChartData } from "../EditExcellenceChartForms/excellenceChartFormUtils";
import {
  DynamicGridChartType,
  getChartColors,
  getCurrentColorScheme,
} from "../nivoTheme";
import pieChartDefaultData from "../ExcellenceDefaultConfig/pieChartDefaultData";
import { memo, useRef, useState } from "react";
import { formatNumber } from "../../../Global/Utils/commonFunctions";

interface PieChartProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  isDonut?: boolean;
  configuration: PieChartConfiguration;
  data: PieDonutChartData | null;
  isStatic?: boolean;
  noAnimation?: boolean;
  schema: PieChartDataSchema | null;
  paramMapping?: Record<string, string>;
}

const PieChart: React.FC<PieChartProps> = ({
  className,
  isDonut,
  data,
  configuration,
  isStatic,
  noAnimation,
  schema,
  paramMapping,
}) => {
  const theme = useTheme();
  const { enableArcLabels, enableArcLinkLabels } = configuration;
  const [customLegends, setCustomLegends] = useState<LegendDatum<PieDonutChartData>[]>(
    []
  );
  const chartContainerRef = useRef<HTMLDivElement>(null);
  // const { width: chartWidth } = useContainerDimensions(chartContainerRef);

  const dataToUse: PieDonutChartData = data
    ? data
    : isDonut
    ? pieChartDefaultData
    : pieChartDefaultData;
  // const dynamicData = downSampleGenericArrayData(
  //   dataToUse || [],
  //   Math.round(chartWidth / 30)
  // );

  const chartMargin = {
    top: isStatic ? 10 : 40,
    right: isStatic ? 10 : 80,
    bottom: isStatic ? 10 : 30,
    left: isStatic ? 10 : 80,
  };

  return (
    <Box className={className} ref={chartContainerRef} component="div">
      <Stack style={{ width: "100%", height: "100%" }} spacing={3}>
        <Box
          component="div"
          style={{
            maxHeight: "400px",
            width: "100%",
            height: schema ? "calc(100% - 60px)" : "100%",
          }}
        >
          <ResponsivePie
            // @ts-ignore
            data={dataToUse}
            margin={chartMargin}
            enableArcLabels={isStatic ? false : enableArcLabels}
            enableArcLinkLabels={isStatic ? false : enableArcLinkLabels}
            colors={getChartColors()}
            theme={{
              text: {
                fill: theme.palette.common.black,
              },
              tooltip: {
                container: {
                  background: theme.palette.common.white,
                },
              },
            }}
            animate={!noAnimation}
            startAngle={-180}
            padAngle={0.7}
            cornerRadius={3}
            innerRadius={isDonut ? 0.5 : 0}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            borderColor={{
              from: "color",
              modifiers: [["darker", 0.2]],
            }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: "color" }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={
              getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_ONE ||
              getCurrentColorScheme() === DynamicGridChartType.GIANT_PALETTE_TWO
                ? {
                    from: "color",
                    modifiers: [["darker", 10]],
                  }
                : undefined
            }
            // arcLinkLabel={(d) => `(${d.label})`}
            arcLabel={(d) => {
              const result = formatNumber(
                d.value,
                configuration.fixedDecimalValue,
                configuration.decimalPoints
              );
              if (!result) {
                return `${d.value}`;
              }
              return `${result}`;
            }}
            defs={[
              {
                id: "dots",
                type: "patternDots",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: "lines",
                type: "patternLines",
                background: "inherit",
                color: "rgba(255, 255, 255, 0.3)",
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            isInteractive={isStatic ? false : true}
            fill={[
              {
                match: {
                  id: "ruby",
                },
                id: "dots",
              },
              {
                match: {
                  id: "c",
                },
                id: "dots",
              },
              {
                match: {
                  id: "go",
                },
                id: "dots",
              },
              {
                match: {
                  id: "python",
                },
                id: "dots",
              },
              {
                match: {
                  id: "scala",
                },
                id: "lines",
              },
              {
                match: {
                  id: "lisp",
                },
                id: "lines",
              },
              {
                match: {
                  id: "elixir",
                },
                id: "lines",
              },
              {
                match: {
                  id: "javascript",
                },
                id: "lines",
              },
            ]}
            forwardLegendData={setCustomLegends}
            legends={
              isStatic || (!!schema && !!paramMapping)
                ? undefined
                : [
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 0,
                      translateY: 65,
                      itemsSpacing: 0,
                      itemWidth: 80,
                      itemHeight: 18,
                      itemDirection: "left-to-right",
                      itemOpacity: 1,
                      symbolSize: 18,
                      symbolShape: "circle",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemTextColor: "#000",
                          },
                        },
                      ],
                    },
                  ]
            }
          />
        </Box>

        {schema && paramMapping ? (
          <Box
            component="div"
            style={{ height: "250px", overflowY: "auto", marginRight: "1rem" }}
          >
            <Typography mb={2} variant="body1" textAlign="center">
              {getPieChartLegendText(schema, paramMapping)}
            </Typography>

            <Stack spacing={3} flexWrap="wrap" direction="row">
              {customLegends.map((item, index) => (
                <Box component="div" key={item.id}>
                  <Stack
                    ml={index === 0 ? 3 : 0}
                    mb={1.5}
                    spacing={1}
                    alignItems="center"
                    direction="row"
                  >
                    <Box
                      component="div"
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "100%",
                        backgroundColor: item.color,
                        maxWidth: "30%",
                      }}
                    />

                    <Typography variant="body2">
                      {getPieCustomLegendParamName(item.label, paramMapping)}
                    </Typography>
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Stack>
    </Box>
  );
};

export default memo(PieChart);
