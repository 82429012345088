import { SerializedStyles } from "@emotion/react";
import GaugeWidget from "../../../../ExcellenceWidgets/GaugeWidget/GaugeWidget";
import { GaugeWidgetData } from "../../../../ExcellenceWidgets/EditExcellenceChartForms/excellenceChartFormUtils";
import gaugeWidgetDefaultData from "../../../../ExcellenceWidgets/ExcellenceDefaultConfig/gaugeWidgetDefaultData";
import { GaugeWidgetConfiguration } from "../../../../ExcellenceWidgets/GaugeWidget/gaugeWidgetUtils";

interface ExcellenceGaugeProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  data: GaugeWidgetData | null;
  configuration: GaugeWidgetConfiguration;
  isStatic?: boolean;
}

const ExcellenceGauge: React.FC<ExcellenceGaugeProps> = ({
  className,
  data,
  configuration,
  isStatic,
}) => {
  return (
    <GaugeWidget
      className={className}
      configuration={configuration}
      data={data || gaugeWidgetDefaultData}
      isStatic={isStatic}
    />
  );
};

export default ExcellenceGauge;
