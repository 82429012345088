import ExcellenceChartFormWrapper from "../EditExcellenceChartForms/ExcellenceChartFormWrapper";
import {
  ChartConfigFormValuesWithTitle,
  ExcellenceChartFormProps,
} from "../EditExcellenceChartForms/excellenceChartFormUtils";
import { useState } from "react";
import { DynamicGridChartOptions } from "../../SmallComponents/DynamicGridLayout.tsx/dynamicGridUtils";
import { TextWidgetConfiguration, TextWidgetDataSchema } from "./textWidgetTypes";
import { useLanguageContext } from "../../../context/LanguageContext";
import TextField from "../../MaterialUI/FormFields/TextFields";
import { useTheme } from "@mui/material";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import useExcellenceChartUnsavedChanges from "../../../Global/Hooks/useExcellenceChartUnsavedChanges";
import QuillEditor from "../../SmallComponents/QuillEditor/QuillEditor";

interface EditTextWidgetFormProps extends ExcellenceChartFormProps {
  config: TextWidgetConfiguration;
  schema: TextWidgetDataSchema | null;
  isDonut?: boolean;
}

const EditTextWidgetForm: React.FC<EditTextWidgetFormProps> = ({
  chartClass,
  config,
  schema,
  widgetTitle,
  handleSaveChanges,
  handleSetUnsavedChanges,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
  };
  const { t } = useLanguageContext();

  const { updatedConfig, setUpdatedConfig, updatedSchema, setUpdatedSchema } =
    useExcellenceChartUnsavedChanges<
      ChartConfigFormValuesWithTitle<TextWidgetConfiguration>,
      TextWidgetDataSchema | null
    >({
      initialConfig: {
        title: widgetTitle,
        ...config,
      },
      initialSchema: schema as TextWidgetDataSchema,
      handleSetUnsavedChanges,
    });

  const [fieldAlert, setFieldAlert] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState(0);

  const handleChangeContent = (val: string) => {
    setUpdatedSchema((prev) => {
      const old = prev || {};
      return {
        ...old,
        content: val,
      };
    });
  };

  const handleChangeTitle = (val: string) => {
    setUpdatedConfig((prev) => {
      const old = prev || {};
      return {
        ...old,
        title: val,
      };
    });
  };

  const handleSubmit = () => {
    const { title, ...otherConfig } = updatedConfig;
    if (!title) {
      setFieldAlert(t("Title is required"));
      return;
    }
    if (!updatedSchema?.content) {
      setFieldAlert(t("Text content is required"));
      return;
    }
    const updatedChart: DynamicGridChartOptions = {
      textWidget: {
        config: otherConfig,
        data: null,
        dataSchema: updatedSchema,
      },
    };
    handleSaveChanges(updatedChart, title);
  };

  return (
    <ExcellenceChartFormWrapper
      alertMessage={fieldAlert}
      handleSubmit={handleSubmit}
      tabValue={tabValue}
      setTabValue={setTabValue}
      disabledDataTab
      noTabs
    >
      <TextField
        css={[styles.contentBreak, styles.widthLimit25]}
        name="title"
        label={t("Title")}
        onChange={(e) => handleChangeTitle(e.target.value)}
        value={updatedConfig.title}
      />

      <QuillEditor
        css={[chartClass, styles.contentBreak]}
        editorState={updatedSchema?.content || ""}
        onEditorStateChange={handleChangeContent}
      />
    </ExcellenceChartFormWrapper>
  );
};

export default EditTextWidgetForm;
